import {ZodError} from 'zod';

import {ParseZodSchemaQueryInfo} from '../types/ParseZodSchemaQueryInfo';

export const ZOD_PARSING_ERROR_PREFIX = 'Zod parsing error:';

export const handleSchemaParsingError = (error: ZodError, queryInfo: ParseZodSchemaQueryInfo) => {
  console.warn(
    ZOD_PARSING_ERROR_PREFIX,
    queryInfo.method,
    queryInfo.endpointName,
    queryInfo.schemaType,
    error.message
  );
};

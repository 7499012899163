import styled from 'styled-components';

import {useCallback, useEffect, useState} from 'react';

import {TestIdProps} from 'shared';

import {useGridApiEventListener} from '../hooks/useGridApiEventListener';
import {GridApi} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {DefaultPaginationRenderer} from './DefaultPaginationRenderer';

export interface PaginationWrapperProps extends TestIdProps {
  gridApi: GridApi;
  gridProps: DataGridProps;
  footerTheme: any | undefined;
  initialPage: number;
  count: number;
}

const Wrapper = styled.div``;

export function PaginationWrapper(props: PaginationWrapperProps) {
  const {gridApi} = props;
  const [totalPages, setTotalPages] = useState<number>(gridApi.paginationGetTotalPages());
  const [currentPage, setCurrentPage] = useState<number>(gridApi.paginationGetCurrentPage() + 1);

  const eventHandler = useCallback(() => {
    setTotalPages(gridApi.paginationGetTotalPages());
    setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
  }, [gridApi]);

  const {addListeners} = useGridApiEventListener(
    gridApi,
    ['paginationChanged', 'filterChanged'],
    eventHandler
  );

  useEffect(addListeners, [gridApi]);

  const onPageChange = (page: number) => {
    gridApi.paginationGoToPage(page - 1);
  };

  return (
    <Wrapper css={props.footerTheme}>
      <DefaultPaginationRenderer
        data-testid={props['data-testid']}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </Wrapper>
  );
}

import {
  Card,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  openConfirmDialog,
} from 'platform/components';
import {Box, Center, HStack, Right, Space, VStack} from 'platform/foundation';
import {date, object, string} from 'yup';

import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  PostEmployeeApiArg,
  useLazyGetEmployeeSimilarSearchQuery,
  usePostEmployeeMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {employeeRoutes, testIds} from '@dms/routes';
import {EntityHeader, handleApiError, Page, Section} from '@dms/shared';

import {composePath, getApiDateString} from 'shared';

type FormType = {
  firstName: string;
  lastName: string;
  startDate: Date;
};

export function CreateEmployee() {
  const navigate = useNavigate();

  const [postEmployee] = usePostEmployeeMutation();
  const [getEmployeeSimilar] = useLazyGetEmployeeSimilarSearchQuery();

  const onSubmit: FormSubmitHandler<FormType> = async (data) => {
    const {startDate, ...personalData} = data;
    const employeeArg: PostEmployeeApiArg = {
      body: {
        employmentData: {startDate: getApiDateString(startDate)},
        personalData,
      },
    };

    const createEmployee = () =>
      postEmployee(employeeArg)
        .unwrap()
        .then((response) =>
          navigate(composePath(employeeRoutes.detail, {params: {id: response?.employeeId}}))
        )
        .catch(handleApiError);

    const similarEmployees = await getEmployeeSimilar(personalData).unwrap().catch(handleApiError);

    if (isNilOrEmpty(similarEmployees?.employees)) {
      return createEmployee();
    }

    openConfirmDialog({
      text: i18n.t('page.createEmployee.labels.userAlreadyExists'),
      onConfirm: createEmployee,
    });
  };

  return (
    <>
      <Helmet title={i18n.t('page.createEmployee.labels.title')} />
      <Page
        isFullHeight
        header={
          <EntityHeader
            data-testid={testIds.employee.createEmployee('createEmployee')}
            title={i18n.t('page.createEmployee.labels.newEmployee')}
            actions={[
              {
                type: 'button',
                variant: 'secondary',
                title: i18n.t('general.actions.discard'),
                onClick: () => navigate(employeeRoutes.overview),
              },
            ]}
            flags={[{label: i18n.t('page.createEmployee.labels.draft'), colorScheme: 'neutral'}]}
            icon="custom/employee"
          />
        }
        data-testid={testIds.employee.createEmployee('createEmployee')}
      >
        <Section>
          <Center>
            <Box maxWidth={144} width="100%">
              <Card title={i18n.t('page.createEmployee.labels.employeeInfromation')}>
                <Form<FormType> onSubmit={onSubmit} schema={schema}>
                  {(control) => (
                    <VStack spacing={4}>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            control={control}
                            name="firstName"
                            type="text"
                            isRequired
                            label={i18n.t('page.createEmployee.labels.firstName')}
                            data-testid={testIds.employee.createEmployee('firstName')}
                          />
                        </Box>
                        <Box flex={1}>
                          <FormField
                            control={control}
                            name="lastName"
                            type="text"
                            isRequired
                            label={i18n.t('page.createEmployee.labels.lastName')}
                            data-testid={testIds.employee.createEmployee('lastName')}
                          />
                        </Box>
                      </HStack>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            control={control}
                            name="startDate"
                            type="date"
                            isRequired
                            isRelativeDatesHidden
                            label={i18n.t('page.createEmployee.labels.startDate')}
                            data-testid={testIds.employee.createEmployee('startDate')}
                          />
                        </Box>
                        <Space fillAvailable />
                      </HStack>
                      <Right>
                        <FormButton
                          control={control}
                          type="submit"
                          title={i18n.t('page.createEmployee.actions.createNewEmployee')}
                          data-testid={testIds.employee.createEmployee('createNewEmployee')}
                        />
                      </Right>
                    </VStack>
                  )}
                </Form>
              </Card>
            </Box>
          </Center>
        </Section>
      </Page>
    </>
  );
}

const schema = object({
  firstName: string().required(),
  lastName: string().required(),
  startDate: date().required(),
});

import {GetSignableDocumentListResponseBody} from '@dms/api';

import {DocumentItemWithIsSelected} from '../types/DocumentItemWithIsSelected';
import {isDocumentItemWithFileId} from './isDocumentItemWithFileId';

export const formatDocumentStructure = (
  document: DocumentItemWithIsSelected | GetSignableDocumentListResponseBody
) => {
  const formattedDocument = isDocumentItemWithFileId(document)
    ? document
    : ({
        createdAt: document.createdAt,
        createdBy: document.createdBy,
        name: document.filename,
        fileId: document.sourceFileId,
      } as DocumentItemWithIsSelected);

  return formattedDocument;
};

import {Box} from 'platform/foundation';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import {useGetDocumentDatagridActions} from '@dms/document-context';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

interface VehicleWarehouseOperationsCardProps {
  vehicleId: string;
  saleVehicleId: string;
}

export function VehicleWarehouseOperationsCard(props: VehicleWarehouseOperationsCardProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [actionCallback] = useGetDocumentDatagridActions({
    contextId: props.vehicleId,
    contextTarget: 'vehicle',
    refreshData: refreshDataGrid,
  });

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {saleVehicle: [props.saleVehicleId]}]),
    [props.saleVehicleId]
  );

  return (
    <Box minHeight={40}>
      <DataGrid
        ref={dataGridRef}
        gridCode="sale_vehicle_warehouse_movement"
        autoHeight
        actionCallback={actionCallback}
        queryModifier={queryModifier}
        data-testid="warehouse-movement-datagrid"
      />
    </Box>
  );
}

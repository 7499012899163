import {CustomFilterProps} from 'ag-grid-react';

import {Dispatch, useCallback} from 'react';

import {isNil} from 'ramda';
import {isEmptyArray} from 'ramda-adjunct';

import {Nullish} from 'shared';

export const useChoiceFilter = ({
  model,
  onModelChange,
}: CustomFilterProps): [string[] | string, Dispatch<string[] | string | Nullish>] => {
  const setModel = useCallback(
    (valuesToSet: string[] | string | Nullish) => {
      const newModel = isEmptyArray(valuesToSet) || isNil(valuesToSet) ? null : valuesToSet;
      onModelChange(newModel);
    },
    [onModelChange]
  );

  return [model ?? [], setModel];
};

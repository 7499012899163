import {Module} from '@dms/api';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {quickPurchaseRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {AuctionsList} from './pages/AuctionsList/AuctionsList';

export const QuickPurchaseModule: Module = {
  requiredFeatureFlag: featureFlags.QUICK_PURCHASE_MODULE,
  requiredPermission: permissions.quickPurchaseRead,
  id: moduleKeys.quickPurchase,
  layoutProps: {
    title: 'module.quickPurchase.title',
    icon: 'action/shopping_cart',
    iconV2: 'sidebar/quick_purchase',
  },
  routerProps: {
    path: quickPurchaseRoutes.auctionsList,
    element: <AuctionsList />,
  },
  content: [],
};

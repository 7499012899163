import {FetchBaseQueryError} from '@reduxjs/toolkit/query';

import {not, pluck, uniq} from 'ramda';

import {isApiException} from './isApiException';

const NO_CODE = '???';

/**
 * @about Parses catched error from RTK Query
 */
export function getApiErrorMessage(error: FetchBaseQueryError, defaultMessage: string) {
  if (!isApiException(error?.data)) {
    return defaultMessage;
  }

  const errors = error?.data?.errors.filter((error) =>
    not(!error?.message || !error?.code || NO_CODE === error?.code)
  );

  /**
   * Get unique error messsages
   */
  const messages = uniq(pluck('message', errors));

  return !!messages.length ? messages.join(', ') : defaultMessage;
}

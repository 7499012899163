import {Tooltip} from 'platform/components';

import {VehicleCatalogueColorProperties} from '../../types/VehicleCatalogueColorProperties';
import {VehicleCatalogueEnumOption} from '../../types/VehicleCatalogueEnumOption';
import {noop} from '../../utils/someTeasUtils';
import {ColorBorder, StyledColorCircle} from './styles';

type ColorCircleProps = {
  className?: string;
  color: VehicleCatalogueEnumOption<VehicleCatalogueColorProperties>;
  needBorder?: boolean;
  large?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
};

export function ColorCircle({
  color,
  onClick = noop,
  isSelected,
  needBorder,
  ...rest
}: ColorCircleProps) {
  const colorCode = color.properties?.find((property) => property.name === 'color_code');

  return (
    <Tooltip label={color?.label} placement="top">
      <ColorBorder {...rest} $isSelected={isSelected}>
        <StyledColorCircle
          $color={colorCode?.value || 'transparent'}
          onClick={onClick}
          $needBorder={needBorder}
        />
      </ColorBorder>
    </Tooltip>
  );
}

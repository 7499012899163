import {format} from 'date-fns';
import {Card, CardVariantType, Form, FormField} from 'platform/components';
import {HStack, Show, Spinner, Text, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useRef} from 'react';

import {isNil, reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetUserQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {
  DOT_CHARACTER,
  EMPTY_PLACEHOLDER,
  parseDate,
  suffixTestId,
  TestIdProps,
  useDebouncedValue,
} from 'shared';

export type NoteCardForm = {
  note: string | null;
};

export interface NoteCardProps extends TestIdProps {
  cardVariant?: CardVariantType;
  authorName?: string;
  datetime?: string;
  authorId?: string;
  note?: string | null;
  isLoading?: boolean;
  isDisabled?: boolean;
  title?: string;
  onChange: (values?: NoteCardForm) => void;
}

const ONCHANGE_TIMEOUT = 500;
const NOTE_DEBOUNCE_TIMEOUT = 2000;

export function NoteCard(props: NoteCardProps) {
  const changeDebounce = useRef<ReturnType<typeof setTimeout>>(null);
  const debouncedNote = useDebouncedValue(props.note ?? null, NOTE_DEBOUNCE_TIMEOUT);

  const {data: user} = useGetUserQuery({id: props.authorId ?? ''}, {skip: isNil(props.authorId)});

  const authorName =
    props.authorName ?? (user && reject(isNilOrEmpty, [user.firstName, user.lastName]).join(' '));

  const getInfoText =
    authorName &&
    props.datetime &&
    `${authorName} ${DOT_CHARACTER} ${format(parseDate(props.datetime), 'dd/MM/yyyy p')}`;

  const onChange = (values: NoteCardForm) => {
    if (changeDebounce.current) {
      clearTimeout(changeDebounce.current);
    }

    changeDebounce.current = setTimeout(() => {
      props.onChange(values);
    }, ONCHANGE_TIMEOUT);
  };

  const defaultValues: NoteCardForm = {note: debouncedNote};

  return (
    <Card
      title={props.title ?? i18n.t('general.labels.note')}
      variant={props.cardVariant}
      data-testid={suffixTestId('note', props)}
    >
      <Form<NoteCardForm>
        schema={schema}
        defaultValues={defaultValues}
        onChange={onChange}
        mode="onBlur"
      >
        {(control) => (
          <VStack spacing={4}>
            <FormField
              name="note"
              type="textarea"
              control={control}
              minRows={4}
              data-testid={suffixTestId('note', props)}
              isDisabled={props.isDisabled}
            />
            <HStack spacing={1} justify="flex-end" align="center">
              <Show when={props.isLoading}>
                <Spinner size="small" data-testid={suffixTestId('dataStatus-spinner', props)} />
              </Show>
              <Text size="xSmall" color="secondary" data-testid={suffixTestId('info', props)}>
                {getInfoText ?? EMPTY_PLACEHOLDER}
              </Text>
            </HStack>
          </VStack>
        )}
      </Form>
    </Card>
  );
}

const schema = Yup.object().shape({
  note: Yup.string().nullable(),
});

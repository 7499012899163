import {
  Icon,
  Heading,
  ThemeIconKey,
  HStack,
  Text,
  Box,
  useDevice,
  getValueByDevice,
} from 'platform/foundation';

import {DOT_CHARACTER, Nullish} from 'shared';

interface ContractBillingInformationProps {
  name: string | Nullish;
  regNumber: string | Nullish;
  icon: ThemeIconKey;
}

export function ContractBillingInformationTitle(props: ContractBillingInformationProps) {
  const device = useDevice();
  return (
    <HStack spacing={1} align="center">
      <Icon value={props.icon} />
      <Box maxWidth={getValueByDevice(device, 32, 32, 32, 52)}>
        <Heading size={5} isSingleLine>
          {props.name}
        </Heading>
      </Box>
      <Text noWrap size="xSmall" color="secondary">{`${DOT_CHARACTER} ${props.regNumber}`}</Text>
    </HStack>
  );
}

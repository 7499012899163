import styled from 'styled-components';

import * as React from 'react';
import {ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

export interface DropdownGroupProps extends TestIdProps {
  label?: string;
  children: ReactNode;
}

export function DropdownGroup(props: DropdownGroupProps) {
  return (
    <div data-testid={suffixTestId('dropdownGroup', props)}>
      {props.label && <StyledP>{props.label}</StyledP>}
      {props.children}
    </div>
  );
}

const StyledP = styled.p`
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  font-weight: ${({theme}) => theme.fontWeights.regular};
  line-height: ${({theme}) => theme.lineHeights.text.xSmall};
  letter-spacing: 0;
  margin: 0;
  padding: 4px 8px;
  color: ${({theme}) => theme.colors.text.secondary};
`;

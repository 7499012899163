import {useGridFilter} from 'ag-grid-react';
import {match, Pattern} from 'ts-pattern';

import {useState} from 'react';

import {always, isNil} from 'ramda';

import {useDebouncedCallback} from 'shared';

type SliderValueType = [number | null, number | null] | string | null;

type SliderFilterProps = {
  model: any;
  onModelChange: (model: any) => void;
  min: number;
  max: number;
};

export const useSliderFilter = (props: SliderFilterProps) => {
  const defaultValue: [number | null, number | null] = [
    props.model?.from ?? null,
    props.model?.to ?? null,
  ];

  const [sliderValue, setSliderValue] = useState<SliderValueType>(defaultValue);

  useGridFilter({
    doesFilterPass: () => true,
    afterGuiAttached: () => setSliderValue(defaultValue),
  });

  const updateGridFilter = useDebouncedCallback((value: SliderValueType) => {
    const newModel = match(value)
      .with(Pattern.array(), (arrayValue) => {
        const from = arrayValue[0] === 0 ? null : (arrayValue[0] ?? props.min);
        const to = arrayValue[1] === 0 ? null : (arrayValue[1] ?? props.max);

        const isFromNilOrLowestPossible = isNil(from) || from === props.min;
        const isToNilOrLowestPossible = isNil(to) || to === props.max;

        if (isFromNilOrLowestPossible && isToNilOrLowestPossible) {
          return null;
        }

        return {from, to};
      })
      .with(Pattern.string, (stringValue) => stringValue)
      .otherwise(always(null));

    props.onModelChange(newModel);
  }, 200);

  const handleUpdateSliderValue = (value: SliderValueType) => {
    setSliderValue(value);
    updateGridFilter(value);
  };

  return [sliderValue, handleUpdateSliderValue] as const;
};

import {DataStatus} from 'platform/components';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isNull, isNumber} from 'ramda-adjunct';

import {usePrevious} from 'shared';

import {useHttpCalls} from '../hooks/useHttpCalls';
import {getFormattedSourceSearchParams} from '../utils/url/getFormattedSourceSearchParams';
import {RowControl} from './RowControl';

export type RowControlWrapperProps = {
  onChange: (entityIndex: string, sourceSearchParams: string) => void;
};
export const RowControlWrapper = (props: RowControlWrapperProps) => {
  const http = useHttpCalls({gridCode: getFormattedSourceSearchParams().gridCode});
  const [count, setCount] = useState<number | null>(null);

  const prevValue = usePrevious(getFormattedSourceSearchParams());

  useEffect(() => {
    if (JSON.stringify(getFormattedSourceSearchParams()) === JSON.stringify(prevValue)) {
      return;
    }

    if (
      isNil(getFormattedSourceSearchParams().gridCode) ||
      isNil(getFormattedSourceSearchParams().queryId)
    ) {
      return;
    }

    http
      .getCountByQuery(getFormattedSourceSearchParams().queryId, false)
      .then((rowCountResponse) => {
        isNumber(rowCountResponse) && setCount(rowCountResponse);
      });
  }, [getFormattedSourceSearchParams().gridCode]);

  if (
    isNil(getFormattedSourceSearchParams().gridCode) ||
    isNil(getFormattedSourceSearchParams().queryId)
  ) {
    return null;
  }

  if (isNull(count)) {
    return <DataStatus isLoading></DataStatus>;
  }

  return (
    <RowControl
      onChange={props.onChange}
      gridCode={getFormattedSourceSearchParams().gridCode}
      queryId={getFormattedSourceSearchParams().queryId}
      rowIndex={getFormattedSourceSearchParams().rowIndex}
      count={count ?? 0}
    />
  );
};

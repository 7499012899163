import styled from 'styled-components';

// export const GridTheme = styled(AgGridReact)`
export const GridTheme = styled.div`
  height: 100%;
  display: flex;
  width: 100%;

  .ag-filter-toolpanel-group-instance-header-icon {
    display: none;
  },

  // Three classes for storybook purpose
  .ag-storybook-oldCar {
    background-color: #ffa500 !important;
  }

  .ag-storybook-veteran {
    background-color: #ff0000 !important;
  }

  .ag-storybook-model {
    background-color: #00ff00 !important;
  }

  .ag-react-container {
    width: 100%;
  }

  .ag-cell > span {
    inline-size: max-content;
    min-width: 0;
    max-width: 100%;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    height: 100%;

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      color: #0065ff;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .ag-pinned-right-header .ag-header-cell,
  .ag-pinned-right-cols-container .ag-cell {
    border-right-color: transparent;
  }

  .ag-root-wrapper {
    margin-right: 0px !important;
  }

  [class^='ag-'],
  [class^='ag-']:focus,
  [class^='ag-']:after,
  [class^='ag-']:before {
    box-sizing: border-box;
    outline: none;
  }

  [class^='ag-']::-ms-clear {
    display: none;
  }

  .ag-checkbox .ag-input-wrapper,
  .ag-radio-button .ag-input-wrapper {
    overflow: visible;
  }

  .ag-range-field .ag-input-wrapper {
    height: 100%;
  }

  .ag-toggle-button {
    flex: none;
    width: unset;
    min-width: unset;
  }

  .ag-ltr .ag-label-align-right .ag-label {
    margin-left: 12px;
  }

  .ag-rtl .ag-label-align-right .ag-label {
    margin-right: 2px;
  }

  input[class^='ag-'] {
    margin: 0;
    background-color: #fff;
  }

  textarea[class^='ag-'],
  select[class^='ag-'] {
    background-color: #fff;
  }

  input[class^='ag-']:not([type]),
  input[class^='ag-'][type='text'],
  input[class^='ag-'][type='number'],
  input[class^='ag-'][type='tel'],
  input[class^='ag-'][type='date'],
  input[class^='ag-'][type='datetime-local'],
  textarea[class^='ag-'] {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    border-width: 1px;
    border-style: solid;
    border-color: #babfc7;
    min-height: 24px;
    border-radius: 3px;
  }

  input[class^='ag-']:not([type]):disabled,
  input[class^='ag-'][type='text']:disabled,
  input[class^='ag-'][type='number']:disabled,
  input[class^='ag-'][type='tel']:disabled,
  input[class^='ag-'][type='date']:disabled,
  input[class^='ag-'][type='datetime-local']:disabled,
  textarea[class^='ag-']:disabled {
    color: rgba(24, 29, 31, 0.5);
    background-color: #f1f2f4;
    border-color: rgba(186, 191, 199, 0.3);
  }

  input[class^='ag-']:not([type]):focus,
  input[class^='ag-'][type='text']:focus,
  input[class^='ag-'][type='number']:focus,
  input[class^='ag-'][type='tel']:focus,
  input[class^='ag-'][type='date']:focus,
  input[class^='ag-'][type='datetime-local']:focus,
  textarea[class^='ag-']:focus {
    outline: none;
    box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
    border-color: rgba(33, 150, 243, 0.4);
  }

  input[class^='ag-'][type='number'] {
    -moz-appearance: textfield;
  }

  input[class^='ag-'][type='number']::-webkit-outer-spin-button,
  input[class^='ag-'][type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[class^='ag-'][type='range'] {
    padding: 0;
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    background: none;
    overflow: visible;
  }

  input[class^='ag-'][type='button']:focus,
  button[class^='ag-']:focus {
    box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  }

  .ag-drag-handle {
    color: gray;
  }

  .ag-list-item,
  .ag-virtual-list-item {
    height: 24px;
  }

  .ag-keyboard-focus .ag-virtual-list-item:focus {
    outline: none;
  }

  .ag-keyboard-focus .ag-virtual-list-item:focus::after {
    content: '';
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    top: 4px;
    left: 4px;
    display: block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border: 1px solid;
    border-color: rgba(33, 150, 243, 0.4);
  }

  .ag-select-list {
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ag-list-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ag-list-item.ag-active-item {
    background-color: rgba(33, 150, 243, 0.1);
  }

  .ag-select-list-item {
    padding-left: 4px;
    padding-right: 4px;
    cursor: default;
    user-select: none;
  }

  .ag-select-list-item span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ag-select .ag-picker-field-wrapper {
    background-color: #fff;
    min-height: 24px;
    cursor: default;
  }

  .ag-select.ag-disabled .ag-picker-field-wrapper:focus {
    box-shadow: none;
  }

  .ag-select .ag-picker-field-display {
    margin: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  //
  .ag-select .ag-picker-field-icon {
    display: flex;
    align-items: center;
  }

  .ag-select.ag-disabled {
    opacity: 0.5;
  }

  .ag-rich-select {
    background-color: #f8f8f8;
  }

  .ag-rich-select-list {
    width: 100%;
    min-width: 200px;
    height: 273px;
  }

  .ag-rich-select-value {
    padding: 0 6px 0 18px;
    border-bottom: solid 1px;
    border-bottom-color: #dde2eb;
  }

  .ag-rich-select-virtual-list-item {
    cursor: default;
    height: 24px;
  }

  .ag-rich-select-virtual-list-item:hover {
    background-color: rgba(33, 150, 243, 0.1);
  }

  .ag-rich-select-row {
    padding-left: 18px;
  }

  .ag-rich-select-row-selected {
    background-color: rgba(33, 150, 243, 0.3);
  }

  .ag-row-drag,
  .ag-selection-checkbox,
  .ag-group-expanded,
  .ag-group-contracted {
    color: #181d1f;
  }

  .ag-ltr .ag-row-drag,
  .ag-ltr .ag-selection-checkbox,
  .ag-ltr .ag-group-expanded,
  .ag-ltr .ag-group-contracted {
    margin-right: 12px;
  }

  .ag-rtl .ag-row-drag,
  .ag-rtl .ag-selection-checkbox,
  .ag-rtl .ag-group-expanded,
  .ag-rtl .ag-group-contracted {
    margin-left: 12px;
  }

  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    display: flex;
    align-items: center;
    flex: none;
    max-width: 100%;
  }

  .ag-group-expanded,
  .ag-group-contracted {
    cursor: pointer;
  }

  .ag-group-title-bar-icon {
    cursor: pointer;
    flex: none;
    color: #181d1f;
  }

  .ag-ltr .ag-group-child-count {
    margin-left: 2px;
  }

  .ag-rtl .ag-group-child-count {
    margin-right: 2px;
  }

  .ag-group-title-bar {
    background-color: #fff;
    padding: 12px 0 12px 16px;
  }

  .ag-group-toolbar {
    padding: 6px;
  }

  .ag-disabled-group-title-bar,
  .ag-disabled-group-container {
    opacity: 0.5;
  }

  .group-item {
    margin: 3px 0;
  }

  .ag-label {
    white-space: nowrap;
  }

  .ag-rtl .ag-label {
    margin-left: 6px;
  }

  .ag-label-align-top .ag-label {
    margin-bottom: 3px;
  }

  .ag-ltr .ag-slider-field,
  .ag-ltr .ag-angle-select-field {
    margin-right: 12px;
  }

  .ag-rtl .ag-slider-field,
  .ag-rtl .ag-angle-select-field {
    margin-left: 12px;
  }

  .ag-angle-select-parent-circle {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: solid 1px;
    border-color: #babfc7;
    background-color: #fff;
  }

  .ag-angle-select-child-circle {
    top: 4px;
    left: 12px;
    width: 6px;
    height: 6px;
    margin-left: -3px;
    margin-top: -4px;
    border-radius: 3px;
    background-color: #181d1f;
  }

  .ag-picker-field-wrapper {
    border: 1px solid;
    border-color: #babfc7;
    border-radius: 5px;
  }

  .ag-picker-field-wrapper:focus {
    box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  }

  .ag-picker-field-button {
    background-color: #fff;
    color: #181d1f;
  }

  .ag-dialog.ag-color-dialog {
    border-radius: 5px;
  }

  .ag-color-picker .ag-picker-field-display {
    height: 16px;
  }

  .ag-color-panel {
    padding: 6px;
  }

  .ag-spectrum-color {
    background-color: red;
    border-radius: 2px;
  }

  .ag-spectrum-tools {
    padding: 10px;
  }

  .ag-spectrum-sat {
    background-image: linear-gradient(to right, white, rgba(204, 154, 129, 0));
  }

  .ag-spectrum-val {
    background-image: linear-gradient(to top, black, rgba(204, 154, 129, 0));
  }

  .ag-spectrum-dragger {
    border-radius: 12px;
    height: 12px;
    width: 12px;
    border: 1px solid white;
    background: black;
    box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.24);
  }

  .ag-spectrum-hue-background {
    border-radius: 2px;
  }

  .ag-spectrum-alpha-background {
    border-radius: 2px;
  }

  .ag-spectrum-tool {
    margin-bottom: 10px;
    height: 11px;
    border-radius: 2px;
  }
  //

  .ag-spectrum-slider {
    margin-top: -12px;
    width: 13px;
    height: 13px;
    border-radius: 13px;
    background-color: #f8f8f8;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  }

  .ag-recent-color {
    margin: 0 3px;
  }

  .ag-recent-color:first-of-type {
    margin-left: 0;
  }

  .ag-recent-color:last-of-type {
    margin-right: 0;
  }

  .ag-dragging-range-handle .ag-dialog,
  .ag-dragging-fill-handle .ag-dialog {
    opacity: 0.7;
    pointer-events: none;
  }

  .ag-dialog {
    border-radius: 3px;
    border: solid 1px;
    border-color: #babfc7;
  }

  .ag-panel {
    background-color: #fff;
  }

  .ag-panel-title-bar.ag-default-panel-title-bar.ag-unselectable {
    background-color: #fff;
    border-color: #dfe1e6;
  }

  .ag-column-select-column-drag-handle,
  .ag-column-select-column-group-drag-handle {
    visibility: hidden;
  }

  .ag-panel-title-bar {
    background-color: #f8f8f8;
    color: #181d1f;
    height: 48px;
    padding: 6px 18px;
    border-bottom: solid 1px;
    border-bottom-color: #babfc7;
  }

  .ag-ltr .ag-panel-title-bar-button {
    margin-left: 12px;
    margin-right: 6px;
  }

  .ag-rtl .ag-panel-title-bar-button {
    margin-right: 12px;
    margin-left: 6px;
  }

  .ag-popup .ag-tooltip {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.875rem;
    box-shadow: 0 4px 6px -1px;
    max-width: 200px;
    z-index: 2000;
    background-color: #091e42;
    border-radius: 4px;
    transition: opacity 0.2s;
  }

  .ag-tooltip.ag-tooltip-hiding {
    opacity: 0;
  }

  /* Column select indentation styles for LTR and RTL */
  ${[...Array(9).keys()]
    .map(
      (i) => `
      .ag-ltr .ag-column-select-indent-${i + 1} {
        padding-left: ${24 + 8 * i}px;
      }
      .ag-rtl .ag-column-select-indent-${i + 1} {
        padding-right: ${24 + 8 * i}px;
      }
    `
    )
    .join('')}

  .ag-ltr .ag-column-select-add-group-indent {
    margin-left: 40px;
  }

  .ag-rtl .ag-column-select-add-group-indent {
    margin-right: 40px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-1 {
    padding-left: 46px;
  }

  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-1 {
    padding-right: 46px;
  }

  .ag-ltr .ag-row-group-indent-1 {
    padding-left: 28px;
  }

  .ag-rtl .ag-row-group-indent-1 {
    padding-right: 28px;
  }

  .ag-ltr .ag-row-level-1 .ag-pivot-leaf-group {
    margin-left: 28px;
  }

  .ag-rtl .ag-row-level-1 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-2 {
    padding-left: 74px;
  }

  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-2 {
    padding-right: 74px;
  }

  .ag-ltr .ag-row-group-indent-2 {
    padding-left: 56px;
  }

  .ag-rtl .ag-row-group-indent-2 {
    padding-right: 56px;
  }

  .ag-column-select-virtual-list-viewport {
    padding: 6px 0px;
  }

  .ag-column-select-virtual-list-item {
    margin: 0 12px;
  }

  .ag-rtl {
    text-align: right;
  }

  .ag-ltr .ag-row-level-2 .ag-pivot-leaf-group {
    margin-left: 28px;
  }

  .ag-rtl .ag-row-level-2 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-3 {
    padding-left: 102px;
  }

  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-3 {
    padding-right: 102px;
  }

  .ag-ltr .ag-row-group-indent-3 {
    padding-left: 84px;
  }
  .ag-rtl .ag-row-group-indent-3 {
    padding-right: 84px;
  }

  .ag-ltr .ag-row-group-indent-4 {
    padding-left: 112px;
  }
  .ag-rtl .ag-row-group-indent-4 {
    padding-right: 112px;
  }

  .ag-ltr .ag-row-group-indent-5 {
    padding-left: 140px;
  }
  .ag-rtl .ag-row-group-indent-5 {
    padding-right: 140px;
  }

  .ag-ltr .ag-row-group-indent-6 {
    padding-left: 168px;
  }
  .ag-rtl .ag-row-group-indent-6 {
    padding-right: 168px;
  }

  .ag-ltr .ag-row-group-indent-7 {
    padding-left: 196px;
  }
  .ag-rtl .ag-row-group-indent-7 {
    padding-right: 196px;
  }

  .ag-ltr .ag-row-group-indent-8 {
    padding-left: 224px;
  }
  .ag-rtl .ag-row-group-indent-8 {
    padding-right: 224px;
  }

  .ag-ltr .ag-row-group-indent-9 {
    padding-left: 252px;
  }
  .ag-rtl .ag-row-group-indent-9 {
    padding-right: 252px;
  }

  .ag-ltr .ag-row-group-indent-10 {
    padding-left: 280px;
  }
  .ag-rtl .ag-row-group-indent-10 {
    padding-right: 280px;
  }

  .ag-ltr .ag-row-group-indent-11 {
    padding-left: 326px;
  }
  .ag-rtl .ag-row-group-indent-11 {
    padding-right: 326px;
  }

  /* Pivot leaf group margin for LTR and RTL */
  .ag-ltr .ag-row-level-3 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-3 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row-level-4 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-4 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row-level-5 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-5 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row-level-6 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-6 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row-level-7 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-7 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row-level-8 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-8 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row-level-9 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-9 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row-level-10 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-10 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  /* Cell wrapper indentation for row group levels in LTR and RTL */
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-4 {
    padding-left: 130px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-4 {
    padding-right: 130px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-5 {
    padding-left: 158px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-5 {
    padding-right: 158px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-6 {
    padding-left: 186px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-6 {
    padding-right: 186px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-7 {
    padding-left: 214px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-7 {
    padding-right: 214px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-8 {
    padding-left: 242px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-8 {
    padding-right: 242px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-9 {
    padding-left: 270px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-9 {
    padding-right: 270px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-10 {
    padding-left: 298px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-10 {
    padding-right: 298px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-11 {
    padding-left: 326px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-11 {
    padding-right: 326px;
  }
  .ag-ltr .ag-row-group-indent-11 {
    padding-left: 308px;
  }
  .ag-rtl .ag-row-group-indent-11 {
    padding-right: 308px;
  }
  .ag-ltr .ag-row-level-11 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-11 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-12 {
    padding-left: 354px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-12 {
    padding-right: 354px;
  }
  .ag-ltr .ag-row-group-indent-12 {
    padding-left: 336px;
  }
  .ag-rtl .ag-row-group-indent-12 {
    padding-right: 336px;
  }
  .ag-ltr .ag-row-level-12 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-12 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-13 {
    padding-left: 382px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-13 {
    padding-right: 382px;
  }
  .ag-ltr .ag-row-group-indent-13 {
    padding-left: 364px;
  }
  .ag-rtl .ag-row-group-indent-13 {
    padding-right: 364px;
  }
  .ag-ltr .ag-row-level-13 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-13 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-14 {
    padding-left: 410px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-14 {
    padding-right: 410px;
  }
  .ag-ltr .ag-row-group-indent-14 {
    padding-left: 392px;
  }
  .ag-rtl .ag-row-group-indent-14 {
    padding-right: 392px;
  }
  .ag-ltr .ag-row-level-14 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-14 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-15 {
    padding-left: 438px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-15 {
    padding-right: 438px;
  }
  .ag-ltr .ag-row-group-indent-15 {
    padding-left: 420px;
  }
  .ag-rtl .ag-row-group-indent-15 {
    padding-right: 420px;
  }
  .ag-ltr .ag-row-level-15 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-15 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-16 {
    padding-left: 466px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-16 {
    padding-right: 466px;
  }
  .ag-ltr .ag-row-group-indent-16 {
    padding-left: 448px;
  }
  .ag-rtl .ag-row-group-indent-16 {
    padding-right: 448px;
  }
  .ag-ltr .ag-row-level-16 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-16 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-17 {
    padding-left: 494px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-17 {
    padding-right: 494px;
  }
  .ag-ltr .ag-row-group-indent-17 {
    padding-left: 476px;
  }
  .ag-rtl .ag-row-group-indent-17 {
    padding-right: 476px;
  }
  .ag-ltr .ag-row-level-17 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-17 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-18 {
    padding-left: 522px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-18 {
    padding-right: 522px;
  }
  .ag-ltr .ag-row-group-indent-18 {
    padding-left: 504px;
  }
  .ag-rtl .ag-row-group-indent-18 {
    padding-right: 504px;
  }
  .ag-ltr .ag-row-level-18 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-18 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-19 {
    padding-left: 550px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-19 {
    padding-right: 550px;
  }
  .ag-ltr .ag-row-group-indent-19 {
    padding-left: 532px;
  }
  .ag-rtl .ag-row-group-indent-19 {
    padding-right: 532px;
  }
  .ag-ltr .ag-row-level-19 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-19 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-20 {
    padding-left: 578px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-20 {
    padding-right: 578px;
  }
  .ag-ltr .ag-row-group-indent-20 {
    padding-left: 560px;
  }
  .ag-rtl .ag-row-group-indent-20 {
    padding-right: 560px;
  }
  .ag-ltr .ag-row-level-20 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-20 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-21 {
    padding-left: 606px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-21 {
    padding-right: 606px;
  }
  .ag-ltr .ag-row-group-indent-21 {
    padding-left: 588px;
  }
  .ag-rtl .ag-row-group-indent-21 {
    padding-right: 588px;
  }
  .ag-ltr .ag-row-level-21 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-21 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-22 {
    padding-left: 634px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-22 {
    padding-right: 634px;
  }
  .ag-ltr .ag-row-group-indent-22 {
    padding-left: 616px;
  }
  .ag-rtl .ag-row-group-indent-22 {
    padding-right: 616px;
  }
  .ag-ltr .ag-row-level-22 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-22 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-23 {
    padding-left: 662px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-23 {
    padding-right: 662px;
  }
  .ag-ltr .ag-row-group-indent-23 {
    padding-left: 644px;
  }
  .ag-rtl .ag-row-group-indent-23 {
    padding-right: 644px;
  }
  .ag-ltr .ag-row-level-23 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-23 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-24 {
    padding-left: 690px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-24 {
    padding-right: 690px;
  }
  .ag-ltr .ag-row-group-indent-24 {
    padding-left: 672px;
  }
  .ag-rtl .ag-row-group-indent-24 {
    padding-right: 672px;
  }
  .ag-ltr .ag-row-level-24 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-24 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-25 {
    padding-left: 718px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-25 {
    padding-right: 718px;
  }
  .ag-ltr .ag-row-group-indent-25 {
    padding-left: 700px;
  }
  .ag-rtl .ag-row-group-indent-25 {
    padding-right: 700px;
  }
  .ag-ltr .ag-row-level-25 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-25 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-26 {
    padding-left: 746px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-26 {
    padding-right: 746px;
  }
  .ag-ltr .ag-row-group-indent-26 {
    padding-left: 728px;
  }
  .ag-rtl .ag-row-group-indent-26 {
    padding-right: 728px;
  }
  .ag-ltr .ag-row-level-26 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-26 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-27 {
    padding-left: 774px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-27 {
    padding-right: 774px;
  }
  .ag-ltr .ag-row-group-indent-27 {
    padding-left: 756px;
  }
  .ag-rtl .ag-row-group-indent-27 {
    padding-right: 756px;
  }
  .ag-ltr .ag-row-level-27 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-27 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-28 {
    padding-left: 802px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-28 {
    padding-right: 802px;
  }
  .ag-ltr .ag-row-group-indent-28 {
    padding-left: 784px;
  }
  .ag-rtl .ag-row-group-indent-28 {
    padding-right: 784px;
  }
  .ag-ltr .ag-row-level-28 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-28 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-29 {
    padding-left: 830px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-29 {
    padding-right: 830px;
  }
  .ag-ltr .ag-row-group-indent-29 {
    padding-left: 812px;
  }
  .ag-rtl .ag-row-group-indent-29 {
    padding-right: 812px;
  }
  .ag-ltr .ag-row-level-29 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-29 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-30 {
    padding-left: 858px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-30 {
    padding-right: 858px;
  }
  .ag-ltr .ag-row-group-indent-30 {
    padding-left: 840px;
  }
  .ag-rtl .ag-row-group-indent-30 {
    padding-right: 840px;
  }
  .ag-ltr .ag-row-level-30 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-30 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-31 {
    padding-left: 886px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-31 {
    padding-right: 886px;
  }
  .ag-ltr .ag-row-group-indent-31 {
    padding-left: 868px;
  }
  .ag-rtl .ag-row-group-indent-31 {
    padding-right: 868px;
  }
  .ag-ltr .ag-row-level-31 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-31 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-32 {
    padding-left: 914px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-32 {
    padding-right: 914px;
  }
  .ag-ltr .ag-row-group-indent-32 {
    padding-left: 896px;
  }
  .ag-rtl .ag-row-group-indent-32 {
    padding-right: 896px;
  }
  .ag-ltr .ag-row-level-32 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-32 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-33 {
    padding-left: 942px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-33 {
    padding-right: 942px;
  }
  .ag-ltr .ag-row-group-indent-33 {
    padding-left: 924px;
  }
  .ag-rtl .ag-row-group-indent-33 {
    padding-right: 924px;
  }
  .ag-ltr .ag-row-level-33 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-33 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-34 {
    padding-left: 970px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-34 {
    padding-right: 970px;
  }
  .ag-ltr .ag-row-group-indent-34 {
    padding-left: 952px;
  }
  .ag-rtl .ag-row-group-indent-34 {
    padding-right: 952px;
  }
  .ag-ltr .ag-row-level-34 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-34 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-35 {
    padding-left: 998px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-35 {
    padding-right: 998px;
  }
  .ag-ltr .ag-row-group-indent-35 {
    padding-left: 980px;
  }
  .ag-rtl .ag-row-group-indent-35 {
    padding-right: 980px;
  }
  .ag-ltr .ag-row-level-35 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-35 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-36 {
    padding-left: 1026px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-36 {
    padding-right: 1026px;
  }

  //

  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-36 {
    padding-right: 1026px;
  }
  .ag-ltr .ag-row-group-indent-36 {
    padding-left: 1008px;
  }
  .ag-rtl .ag-row-group-indent-36 {
    padding-right: 1008px;
  }
  .ag-ltr .ag-row-level-36 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-36 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-37 {
    padding-left: 1054px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-37 {
    padding-right: 1054px;
  }
  .ag-ltr .ag-row-group-indent-37 {
    padding-left: 1036px;
  }
  .ag-rtl .ag-row-group-indent-37 {
    padding-right: 1036px;
  }
  .ag-ltr .ag-row-level-37 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-37 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-38 {
    padding-left: 1082px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-38 {
    padding-right: 1082px;
  }
  .ag-ltr .ag-row-group-indent-38 {
    padding-left: 1064px;
  }
  .ag-rtl .ag-row-group-indent-38 {
    padding-right: 1064px;
  }
  .ag-ltr .ag-row-level-38 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-38 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-39 {
    padding-left: 1110px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-39 {
    padding-right: 1110px;
  }
  .ag-ltr .ag-row-group-indent-39 {
    padding-left: 1092px;
  }
  .ag-rtl .ag-row-group-indent-39 {
    padding-right: 1092px;
  }
  .ag-ltr .ag-row-level-39 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-39 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-40 {
    padding-left: 1138px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-40 {
    padding-right: 1138px;
  }
  .ag-ltr .ag-row-group-indent-40 {
    padding-left: 1120px;
  }
  .ag-rtl .ag-row-group-indent-40 {
    padding-right: 1120px;
  }
  .ag-ltr .ag-row-level-40 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-40 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-41 {
    padding-left: 1166px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-41 {
    padding-right: 1166px;
  }
  .ag-ltr .ag-row-group-indent-41 {
    padding-left: 1148px;
  }
  .ag-rtl .ag-row-group-indent-41 {
    padding-right: 1148px;
  }
  .ag-ltr .ag-row-level-41 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-41 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-42 {
    padding-left: 1194px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-42 {
    padding-right: 1194px;
  }
  .ag-ltr .ag-row-group-indent-42 {
    padding-left: 1176px;
  }
  .ag-rtl .ag-row-group-indent-42 {
    padding-right: 1176px;
  }
  .ag-ltr .ag-row-level-42 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-42 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }
  .ag-rtl .ag-row-level-42 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-43 {
    padding-left: 1222px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-43 {
    padding-right: 1222px;
  }
  .ag-ltr .ag-row-group-indent-43 {
    padding-left: 1204px;
  }
  .ag-rtl .ag-row-group-indent-43 {
    padding-right: 1204px;
  }
  .ag-ltr .ag-row-level-43 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-43 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-44 {
    padding-left: 1250px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-44 {
    padding-right: 1250px;
  }
  .ag-ltr .ag-row-group-indent-44 {
    padding-left: 1232px;
  }
  .ag-rtl .ag-row-group-indent-44 {
    padding-right: 1232px;
  }
  .ag-ltr .ag-row-level-44 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-44 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-45 {
    padding-left: 1278px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-45 {
    padding-right: 1278px;
  }
  .ag-ltr .ag-row-group-indent-45 {
    padding-left: 1260px;
  }
  .ag-rtl .ag-row-group-indent-45 {
    padding-right: 1260px;
  }
  .ag-ltr .ag-row-level-45 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-45 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-46 {
    padding-left: 1306px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-46 {
    padding-right: 1306px;
  }
  .ag-ltr .ag-row-group-indent-46 {
    padding-left: 1288px;
  }
  .ag-rtl .ag-row-group-indent-46 {
    padding-right: 1288px;
  }
  .ag-ltr .ag-row-level-46 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-46 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-47 {
    padding-left: 1334px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-47 {
    padding-right: 1334px;
  }
  .ag-ltr .ag-row-group-indent-47 {
    padding-left: 1316px;
  }
  .ag-rtl .ag-row-group-indent-47 {
    padding-right: 1316px;
  }
  .ag-ltr .ag-row-level-47 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-47 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-48 {
    padding-left: 1362px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-48 {
    padding-right: 1362px;
  }
  .ag-ltr .ag-row-group-indent-48 {
    padding-left: 1344px;
  }
  .ag-rtl .ag-row-group-indent-48 {
    padding-right: 1344px;
  }
  .ag-ltr .ag-row-level-48 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-48 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-49 {
    padding-left: 1390px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-49 {
    padding-right: 1390px;
  }
  .ag-ltr .ag-row-group-indent-49 {
    padding-left: 1372px;
  }
  .ag-rtl .ag-row-group-indent-49 {
    padding-right: 1372px;
  }
  .ag-ltr .ag-row-level-49 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-49 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-50 {
    padding-left: 1418px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-50 {
    padding-right: 1418px;
  }
  .ag-ltr .ag-row-group-indent-50 {
    padding-left: 1400px;
  }
  .ag-rtl .ag-row-group-indent-50 {
    padding-right: 1400px;
  }
  .ag-ltr .ag-row-level-50 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-50 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-51 {
    padding-left: 1446px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-51 {
    padding-right: 1446px;
  }
  .ag-ltr .ag-row-group-indent-51 {
    padding-left: 1428px;
  }
  .ag-rtl .ag-row-group-indent-51 {
    padding-right: 1428px;
  }
  .ag-ltr .ag-row-level-51 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-51 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-52 {
    padding-left: 1474px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-52 {
    padding-right: 1474px;
  }
  .ag-ltr .ag-row-group-indent-52 {
    padding-left: 1456px;
  }
  .ag-rtl .ag-row-group-indent-52 {
    padding-right: 1456px;
  }
  .ag-ltr .ag-row-level-52 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-52 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-53 {
    padding-left: 1502px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-53 {
    padding-right: 1502px;
  }
  .ag-ltr .ag-row-group-indent-53 {
    padding-left: 1484px;
  }
  .ag-rtl .ag-row-group-indent-53 {
    padding-right: 1484px;
  }
  .ag-ltr .ag-row-level-53 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-53 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-54 {
    padding-left: 1530px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-54 {
    padding-right: 1530px;
  }
  .ag-ltr .ag-row-group-indent-54 {
    padding-left: 1512px;
  }
  .ag-rtl .ag-row-group-indent-54 {
    padding-right: 1512px;
  }
  .ag-ltr .ag-row-level-54 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-54 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-55 {
    padding-left: 1558px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-55 {
    padding-right: 1558px;
  }

  //

  .ag-ltr .ag-row-group-indent-55 {
    padding-left: 1540px;
  }
  .ag-rtl .ag-row-group-indent-55 {
    padding-right: 1540px;
  }
  .ag-ltr .ag-row-level-55 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-55 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-56 {
    padding-left: 1586px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-56 {
    padding-right: 1586px;
  }
  .ag-ltr .ag-row-group-indent-56 {
    padding-left: 1568px;
  }
  .ag-rtl .ag-row-group-indent-56 {
    padding-right: 1568px;
  }
  .ag-ltr .ag-row-level-56 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-56 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-57 {
    padding-left: 1614px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-57 {
    padding-right: 1614px;
  }
  .ag-ltr .ag-row-group-indent-57 {
    padding-left: 1596px;
  }
  .ag-rtl .ag-row-group-indent-57 {
    padding-right: 1596px;
  }
  .ag-ltr .ag-row-level-57 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-57 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-58 {
    padding-left: 1642px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-58 {
    padding-right: 1642px;
  }
  .ag-ltr .ag-row-group-indent-58 {
    padding-left: 1624px;
  }
  .ag-rtl .ag-row-group-indent-58 {
    padding-right: 1624px;
  }
  .ag-ltr .ag-row-level-58 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-58 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-59 {
    padding-left: 1670px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-59 {
    padding-right: 1670px;
  }
  .ag-ltr .ag-row-group-indent-59 {
    padding-left: 1652px;
  }
  .ag-rtl .ag-row-group-indent-59 {
    padding-right: 1652px;
  }
  .ag-ltr .ag-row-level-59 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-59 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-60 {
    padding-left: 1698px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-60 {
    padding-right: 1698px;
  }
  .ag-ltr .ag-row-group-indent-60 {
    padding-left: 1680px;
  }
  .ag-rtl .ag-row-group-indent-60 {
    padding-right: 1680px;
  }
  .ag-ltr .ag-row-level-60 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-60 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-61 {
    padding-left: 1726px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-61 {
    padding-right: 1726px;
  }
  .ag-ltr .ag-row-group-indent-61 {
    padding-left: 1708px;
  }
  .ag-rtl .ag-row-group-indent-61 {
    padding-right: 1708px;
  }
  .ag-ltr .ag-row-level-61 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-61 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-62 {
    padding-left: 1754px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-62 {
    padding-right: 1754px;
  }
  .ag-ltr .ag-row-group-indent-62 {
    padding-left: 1736px;
  }
  .ag-rtl .ag-row-group-indent-62 {
    padding-right: 1736px;
  }
  .ag-ltr .ag-row-level-62 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-62 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }
  .ag-ltr .ag-row-level-62 .ag-pivot-leaf-group {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-level-62 .ag-pivot-leaf-group {
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-63 {
    padding-left: 1782px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-63 {
    padding-right: 1782px;
  }
  .ag-ltr .ag-row-group-indent-63 {
    padding-left: 1764px;
  }
  .ag-rtl .ag-row-group-indent-63 {
    padding-right: 1764px;
  }
  .ag-ltr .ag-row-level-63 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-63 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-64 {
    padding-left: 1810px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-64 {
    padding-right: 1810px;
  }
  .ag-ltr .ag-row-group-indent-64 {
    padding-left: 1792px;
  }
  .ag-rtl .ag-row-group-indent-64 {
    padding-right: 1792px;
  }
  .ag-ltr .ag-row-level-64 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-64 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-65 {
    padding-left: 1838px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-65 {
    padding-right: 1838px;
  }
  .ag-ltr .ag-row-group-indent-65 {
    padding-left: 1820px;
  }
  .ag-rtl .ag-row-group-indent-65 {
    padding-right: 1820px;
  }
  .ag-ltr .ag-row-level-65 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-65 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-66 {
    padding-left: 1866px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-66 {
    padding-right: 1866px;
  }
  .ag-ltr .ag-row-group-indent-66 {
    padding-left: 1848px;
  }
  .ag-rtl .ag-row-group-indent-66 {
    padding-right: 1848px;
  }
  .ag-ltr .ag-row-level-66 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-66 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-67 {
    padding-left: 1894px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-67 {
    padding-right: 1894px;
  }
  .ag-ltr .ag-row-group-indent-67 {
    padding-left: 1876px;
  }
  .ag-rtl .ag-row-group-indent-67 {
    padding-right: 1876px;
  }
  .ag-ltr .ag-row-level-67 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-67 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-68 {
    padding-left: 1922px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-68 {
    padding-right: 1922px;
  }
  .ag-ltr .ag-row-group-indent-68 {
    padding-left: 1904px;
  }
  .ag-rtl .ag-row-group-indent-68 {
    padding-right: 1904px;
  }
  .ag-ltr .ag-row-level-68 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-68 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-69 {
    padding-left: 1950px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-69 {
    padding-right: 1950px;
  }
  .ag-ltr .ag-row-group-indent-69 {
    padding-left: 1932px;
  }
  .ag-rtl .ag-row-group-indent-69 {
    padding-right: 1932px;
  }
  .ag-ltr .ag-row-level-69 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-69 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-70 {
    padding-left: 1978px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-70 {
    padding-right: 1978px;
  }
  .ag-ltr .ag-row-group-indent-70 {
    padding-left: 1960px;
  }
  .ag-rtl .ag-row-group-indent-70 {
    padding-right: 1960px;
  }
  .ag-ltr .ag-row-level-70 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-70 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  //

  .ag-rtl .ag-row-level-70 .ag-pivot-leaf-group {
    margin-right: 28px;
  }
  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-71 {
    padding-left: 2006px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-71 {
    padding-right: 2006px;
  }
  .ag-ltr .ag-row-group-indent-71 {
    padding-left: 1988px;
  }
  .ag-rtl .ag-row-group-indent-71 {
    padding-right: 1988px;
  }
  .ag-ltr .ag-row-level-71 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-71 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-72 {
    padding-left: 2034px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-72 {
    padding-right: 2034px;
  }
  .ag-ltr .ag-row-group-indent-72 {
    padding-left: 2016px;
  }
  .ag-rtl .ag-row-group-indent-72 {
    padding-right: 2016px;
  }
  .ag-ltr .ag-row-level-72 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-72 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-73 {
    padding-left: 2062px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-73 {
    padding-right: 2062px;
  }
  .ag-ltr .ag-row-group-indent-73 {
    padding-left: 2044px;
  }
  .ag-rtl .ag-row-group-indent-73 {
    padding-right: 2044px;
  }
  .ag-ltr .ag-row-level-73 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-73 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-74 {
    padding-left: 2090px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-74 {
    padding-right: 2090px;
  }
  .ag-ltr .ag-row-group-indent-74 {
    padding-left: 2072px;
  }
  .ag-rtl .ag-row-group-indent-74 {
    padding-right: 2072px;
  }
  .ag-ltr .ag-row-level-74 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-74 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-75 {
    padding-left: 2118px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-75 {
    padding-right: 2118px;
  }
  .ag-ltr .ag-row-group-indent-75 {
    padding-left: 2100px;
  }
  .ag-rtl .ag-row-group-indent-75 {
    padding-right: 2100px;
  }
  .ag-ltr .ag-row-level-75 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-75 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-76 {
    padding-left: 2146px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-76 {
    padding-right: 2146px;
  }
  .ag-ltr .ag-row-group-indent-76 {
    padding-left: 2128px;
  }
  .ag-rtl .ag-row-group-indent-76 {
    padding-right: 2128px;
  }
  .ag-ltr .ag-row-level-76 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-76 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-77 {
    padding-left: 2174px;
  }

  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-77 {
    padding-right: 2174px;
  }
  .ag-ltr .ag-row-group-indent-77 {
    padding-left: 2156px;
  }
  .ag-rtl .ag-row-group-indent-77 {
    padding-right: 2156px;
  }
  .ag-ltr .ag-row-level-77 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-77 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-78 {
    padding-left: 2202px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-78 {
    padding-right: 2202px;
  }
  .ag-ltr .ag-row-group-indent-78 {
    padding-left: 2184px;
  }
  .ag-rtl .ag-row-group-indent-78 {
    padding-right: 2184px;
  }
  .ag-ltr .ag-row-level-78 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-78 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-79 {
    padding-left: 2230px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-79 {
    padding-right: 2230px;
  }
  .ag-ltr .ag-row-group-indent-79 {
    padding-left: 2212px;
  }
  .ag-rtl .ag-row-group-indent-79 {
    padding-right: 2212px;
  }
  .ag-ltr .ag-row-level-79 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-79 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-80 {
    padding-left: 2258px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-80 {
    padding-right: 2258px;
  }
  .ag-ltr .ag-row-group-indent-80 {
    padding-left: 2240px;
  }
  .ag-rtl .ag-row-group-indent-80 {
    padding-right: 2240px;
  }
  .ag-ltr .ag-row-level-80 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-80 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-81 {
    padding-left: 2286px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-81 {
    padding-right: 2286px;
  }
  .ag-ltr .ag-row-group-indent-81 {
    padding-left: 2268px;
  }
  .ag-rtl .ag-row-group-indent-81 {
    padding-right: 2268px;
  }
  .ag-ltr .ag-row-level-81 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-81 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-82 {
    padding-left: 2314px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-82 {
    padding-right: 2314px;
  }
  .ag-ltr .ag-row-group-indent-82 {
    padding-left: 2296px;
  }
  .ag-rtl .ag-row-group-indent-82 {
    padding-right: 2296px;
  }
  .ag-ltr .ag-row-level-82 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-82 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-83 {
    padding-left: 2342px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-83 {
    padding-right: 2342px;
  }
  .ag-ltr .ag-row-group-indent-83 {
    padding-left: 2324px;
  }
  .ag-rtl .ag-row-group-indent-83 {
    padding-right: 2324px;
  }
  .ag-ltr .ag-row-level-83 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-83 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-84 {
    padding-left: 2370px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-84 {
    padding-right: 2370px;
  }
  .ag-ltr .ag-row-group-indent-84 {
    padding-left: 2352px;
  }
  .ag-rtl .ag-row-group-indent-84 {
    padding-right: 2352px;
  }
  .ag-ltr .ag-row-level-84 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-84 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-85 {
    padding-left: 2398px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-85 {
    padding-right: 2398px;
  }

  .ag-ltr .ag-row-group-indent-85 {
    padding-left: 2380px;
  }
  .ag-rtl .ag-row-group-indent-85 {
    padding-right: 2380px;
  }
  .ag-ltr .ag-row-level-85 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-85 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-86 {
    padding-left: 2426px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-86 {
    padding-right: 2426px;
  }
  .ag-ltr .ag-row-group-indent-86 {
    padding-left: 2408px;
  }
  .ag-rtl .ag-row-group-indent-86 {
    padding-right: 2408px;
  }
  .ag-ltr .ag-row-level-86 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-86 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-87 {
    padding-left: 2454px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-87 {
    padding-right: 2454px;
  }
  .ag-ltr .ag-row-group-indent-87 {
    padding-left: 2436px;
  }
  .ag-rtl .ag-row-group-indent-87 {
    padding-right: 2436px;
  }
  .ag-ltr .ag-row-level-87 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-87 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-88 {
    padding-left: 2482px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-88 {
    padding-right: 2482px;
  }
  .ag-ltr .ag-row-group-indent-88 {
    padding-left: 2464px;
  }
  .ag-rtl .ag-row-group-indent-88 {
    padding-right: 2464px;
  }
  .ag-ltr .ag-row-level-88 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-88 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-89 {
    padding-left: 2510px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-89 {
    padding-right: 2510px;
  }
  .ag-ltr .ag-row-group-indent-89 {
    padding-left: 2492px;
  }
  .ag-rtl .ag-row-group-indent-89 {
    padding-right: 2492px;
  }
  .ag-ltr .ag-row-level-89 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-89 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-90 {
    padding-left: 2538px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-90 {
    padding-right: 2538px;
  }
  .ag-ltr .ag-row-group-indent-90 {
    padding-left: 2520px;
  }
  .ag-rtl .ag-row-group-indent-90 {
    padding-right: 2520px;
  }
  .ag-ltr .ag-row-level-90 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-90 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-91 {
    padding-left: 2566px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-91 {
    padding-right: 2566px;
  }
  .ag-ltr .ag-row-group-indent-91 {
    padding-left: 2548px;
  }
  .ag-rtl .ag-row-group-indent-91 {
    padding-right: 2548px;
  }
  .ag-ltr .ag-row-level-91 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-91 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-92 {
    padding-left: 2594px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-92 {
    padding-right: 2594px;
  }
  .ag-ltr .ag-row-group-indent-92 {
    padding-left: 2576px;
  }
  .ag-rtl .ag-row-group-indent-92 {
    padding-right: 2576px;
  }
  .ag-ltr .ag-row-level-92 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-92 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-93 {
    padding-left: 2622px;
  }

  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-93 {
    padding-right: 2622px;
  }
  .ag-ltr .ag-row-group-indent-93 {
    padding-left: 2604px;
  }
  .ag-rtl .ag-row-group-indent-93 {
    padding-right: 2604px;
  }
  .ag-ltr .ag-row-level-93 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-93 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-94 {
    padding-left: 2650px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-94 {
    padding-right: 2650px;
  }
  .ag-ltr .ag-row-group-indent-94 {
    padding-left: 2632px;
  }
  .ag-rtl .ag-row-group-indent-94 {
    padding-right: 2632px;
  }
  .ag-ltr .ag-row-level-94 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-94 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-95 {
    padding-left: 2678px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-95 {
    padding-right: 2678px;
  }
  .ag-ltr .ag-row-group-indent-95 {
    padding-left: 2660px;
  }
  .ag-rtl .ag-row-group-indent-95 {
    padding-right: 2660px;
  }
  .ag-ltr .ag-row-level-95 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-95 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-96 {
    padding-left: 2706px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-96 {
    padding-right: 2706px;
  }
  .ag-ltr .ag-row-group-indent-96 {
    padding-left: 2688px;
  }
  .ag-rtl .ag-row-group-indent-96 {
    padding-right: 2688px;
  }
  .ag-ltr .ag-row-level-96 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-96 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-97 {
    padding-left: 2734px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-97 {
    padding-right: 2734px;
  }
  .ag-ltr .ag-row-group-indent-97 {
    padding-left: 2716px;
  }
  .ag-rtl .ag-row-group-indent-97 {
    padding-right: 2716px;
  }
  .ag-ltr .ag-row-level-97 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-97 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-98 {
    padding-left: 2762px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-98 {
    padding-right: 2762px;
  }
  .ag-ltr .ag-row-group-indent-98 {
    padding-left: 2744px;
  }
  .ag-rtl .ag-row-group-indent-98 {
    padding-right: 2744px;
  }
  .ag-ltr .ag-row-level-98 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-98 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-99 {
    padding-left: 2790px;
  }
  .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-99 {
    padding-right: 2790px;
  }
  .ag-ltr .ag-row-group-indent-99 {
    padding-left: 2772px;
  }
  .ag-rtl .ag-row-group-indent-99 {
    padding-right: 2772px;
  }
  .ag-ltr .ag-row-level-99 .ag-pivot-leaf-group,
  .ag-rtl .ag-row-level-99 .ag-pivot-leaf-group {
    margin-left: 28px;
    margin-right: 28px;
  }

  .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 28px;
  }
  .ag-rtl .ag-row-group-leaf-indent {
    margin-right: 28px;
  }

  .ag-column-select-header-icon {
    cursor: pointer;
  }
  .ag-keyboard-focus .ag-column-select-header-icon:focus {
    outline: none;
  }

  .ag-keyboard-focus .ag-column-select-header-icon:focus::after {
    content: '';
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    top: 0px;
    left: 0px;
    display: block;
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    border: 1px solid;
    border-color: rgba(33, 150, 243, 0.4);
  }

  .ag-ltr .ag-column-group-icons:not(:last-of-type),
  .ag-ltr .ag-column-select-header-checkbox:not(:last-of-type),
  .ag-ltr .ag-column-select-header-filter-wrapper:not(:last-of-type),
  .ag-ltr .ag-column-select-checkbox:not(:last-of-type),
  .ag-ltr .ag-column-select-column-label:not(:last-of-type) {
    margin-right: 16px;
  }

  .ag-rtl .ag-column-group-icons:not(:last-of-type),
  .ag-rtl .ag-column-select-header-icon:not(:last-of-type),
  .ag-rtl .ag-column-select-header-checkbox:not(:last-of-type),
  .ag-rtl .ag-column-select-header-filter-wrapper:not(:last-of-type),
  .ag-rtl .ag-column-select-checkbox:not(:last-of-type),
  .ag-rtl .ag-column-select-column-label:not(:last-of-type) {
    margin-left: 12px;
  }

  .ag-keyboard-focus .ag-column-select-virtual-list-item:focus {
    outline: none;
  }

  .ag-keyboard-focus .ag-column-select-virtual-list-item:focus::after {
    content: '';
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    top: 1px;
    left: 1px;
    display: block;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 1px solid;
    border-color: rgba(33, 150, 243, 0.4);
  }

  .ag-column-select-column-group:not(:last-of-type),
  .ag-column-select-column:not(:last-of-type) {
    margin-bottom: 9px;
  }

  .ag-column-select-column-readonly,
  .ag-column-select-column-group-readonly {
    color: rgba(24, 29, 31, 0.5);
    pointer-events: none;
  }

  .ag-value-change-delta {
    padding-right: 2px;
  }

  .ag-value-change-delta-up {
    color: #43a047;
  }

  .ag-value-change-delta-down {
    color: #e53935;
  }

  .ag-value-change-value {
    background-color: transparent;
    border-radius: 1px;
    padding-left: 1px;
    padding-right: 1px;
    transition: background-color 1s;
  }

  .ag-value-change-value-highlight {
    background-color: rgba(22, 160, 133, 0.5);
    transition: background-color 0.1s;
  }

  .ag-cell-data-changed {
    background-color:
      rgba(22, 160, 133, 0.5) !important;
      var(--ag-value-change-value-highlight-backgroundColor, rgba(22, 160, 133, 0.5)) !important;
  }

  .ag-cell-data-changed-animation {
    background-color: transparent;
  }

  .ag-cell-highlight {
    background-color:
      #2196f3 !important;
      var(
        --ag-range-selection-highlight-color,
        var(--ag-range-selection-border-color, #2196f3)
      ) !important;
  }

  .ag-row {
    border-color: #CBD5E0;
    border-bottom-style: solid;
    background-color: white;
  }

  .ag-row[row-id*='detail_'] .ag-react-container {
    height: auto;
  }

  .ag-row-highlight-above::after,
  .ag-row-highlight-below::after {
    content: '';
    position: absolute;
    width: calc(100% - 1px);
    height: 1px;
    background-color: #2196f3;
    left: 1px;
  }

  //

  .ag-row-highlight-above::after {
    top: -1px;
  }

  .ag-row-highlight-above.ag-row-first::after {
    top: 0;
  }

  .ag-row-highlight-below::after {
    bottom: 0px;
  }

  .ag-row-hover {
    background-color: rgba(33, 150, 243, 0.1);
  }

  .ag-ltr .ag-right-aligned-cell {
    text-align: right;
  }

  .ag-rtl .ag-right-aligned-cell {
    text-align: left;
  }

  .ag-right-aligned-cell {
    justify-content: flex-end;
  }

  .ag-ltr .ag-right-aligned-cell .ag-cell-value,
  .ag-ltr .ag-right-aligned-cell .ag-group-value {
    margin-left: auto;
  }

  .ag-rtl .ag-right-aligned-cell .ag-cell-value,
  .ag-rtl .ag-right-aligned-cell .ag-group-value {
    margin-right: auto;
  }

  .ag-cell-wrapper {
    height: auto;
  }

  .ag-cell-wrapper.ag-row-group {
    align-items: center;
  }

  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    border: 1px solid transparent;
    padding-left: 8px;
    padding-right: 8px;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  .ag-row > .ag-cell-wrapper {
    padding-left: 17px;
    padding-right: 17px;
  }

  .ag-row-dragging {
    cursor: move;
    opacity: 0.5;
  }

  .ag-large-text-input {
    height: auto;
    padding: 18px;
  }

  .ag-details-row {
    padding: 30px;
    background-color: #fff;
  }

  .ag-layout-auto-height .ag-center-cols-clipper,
  .ag-layout-auto-height .ag-center-cols-container,
  .ag-layout-print .ag-center-cols-clipper,
  .ag-layout-print .ag-center-cols-container {
    min-height: 150px;
  }

  .ag-floating-bottom-container,
  .ag-center-cols-container {
    min-width: 100%;
  }

  .ag-overlay-loading-wrapper {
    background-color: rgba(255, 255, 255, 0.66);
  }

  .ag-overlay-loading-center {
    border: 1px solid;
    border-color: #babfc7;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
    padding: 6px;
  }

  .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
    padding-top: 60px;
  }

  .ag-loading {
    padding-left: 18px;
    display: flex;
    height: 100%;
    align-items: center;
  }

  .ag-loading-icon {
    padding-right: 12px;
  }

  .ag-icon-loading {
    -webkit-animation-name: spin;
    animation-name: spin;
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  //

  .ag-floating-top {
    border-bottom: solid 1px;
    border-bottom-color: #babfc7;
  }

  .ag-floating-bottom {
    border-top: solid 2px;
    border-bottom: 0px;
    border-top-color: #e2e8f0;
  }

  .ag-row-selected {
    background-color: rgba(33, 150, 243, 0.3);
  }

  .ag-cell-range-selected:not(.ag-cell-focus),
  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
    background-color: rgba(33, 150, 243, 0.2);
  }

  .ag-cell-range-selected:not(.ag-cell-focus).ag-cell-range-chart,
  .ag-body-viewport:not(.ag-has-focus)
    .ag-cell-range-single-cell:not(.ag-cell-inline-editing).ag-cell-range-chart {
    background-color:
      rgba(0, 88, 255, 0.1) !important;
      var(--ag-range-selection-chart-backgroundColor, rgba(0, 88, 255, 0.1)) !important;
  }

  .ag-cell-range-selected:not(.ag-cell-focus).ag-cell-range-chart.ag-cell-range-chart-category,
  .ag-body-viewport:not(.ag-has-focus)
    .ag-cell-range-single-cell:not(
      .ag-cell-inline-editing
    ).ag-cell-range-chart.ag-cell-range-chart-category {
    background-color:
      rgba(0, 255, 132, 0.1) !important;
      var(--ag-range-selection-chart-category-backgroundColor, rgba(0, 255, 132, 0.1)) !important;
  }

  .ag-cell-range-selected-1:not(.ag-cell-focus),
  .ag-root:not(.ag-context-menu-open)
    .ag-body-viewport:not(.ag-has-focus)
    .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
    background-color: rgba(33, 150, 243, 0.2);
  }

  .ag-cell-range-selected-2:not(.ag-cell-focus),
  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-2 {
    background-color: rgba(33, 150, 243, 0.36);
  }

  .ag-cell-range-selected-3:not(.ag-cell-focus),
  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-3 {
    background-color: rgba(33, 150, 243, 0.488);
  }

  .ag-cell-range-selected-4:not(.ag-cell-focus),
  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-4 {
    background-color: rgba(33, 150, 243, 0.5904);
  }

  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-top {
    border-color: transparent;
    border-top-color: #2196f3;
    border-image: none;
    border-top: 1px solid;
  }

  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right {
    border-color: transparent;
    border-right-color: #2196f3;
    border-image: none;
    border-right: 1px solid;
  }

  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom {
    border-color: transparent;
    border-bottom-color: #2196f3;
    border-image: none;
    border-bottom: 1px solid;
  }

  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left {
    border-color: transparent;
    border-left-color: #2196f3;
    border-image: none;
    border-left: 1px solid;
  }

  .ag-cell.ag-cell-range-selected:not(
      .ag-cell-range-single-cell
    ).ag-cell-range-left.ag-cell-range-top {
    border-left-color: #2196f3;
    border-top-color: #2196f3;
  }

  .ag-cell.ag-cell-range-selected:not(
      .ag-cell-range-single-cell
    ).ag-cell-range-right.ag-cell-range-top {
    border-right-color: #2196f3;
    border-top-color: #2196f3;
  }

  .ag-cell.ag-cell-range-selected:not(
      .ag-cell-range-single-cell
    ).ag-cell-range-left.ag-cell-range-bottom {
    border-left-color: #2196f3;
    border-bottom-color: #2196f3;
  }

  .ag-cell.ag-cell-range-selected:not(
      .ag-cell-range-single-cell
    ).ag-cell-range-right.ag-cell-range-bottom {
    border-right-color: #2196f3;
    border-bottom-color: #2196f3;
  }

  .ag-cell.ag-cell-range-selected:not(
      .ag-cell-range-single-cell
    ).ag-cell-range-right.ag-cell-range-left {
    border-right-color: #2196f3;
    border-left-color: #2196f3;
  }

  //

  .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
  .ag-ltr .ag-cell-range-single-cell,
  .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
  .ag-rtl .ag-cell-range-single-cell,
  .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
    border: 1px solid;
    border-color: #2196f3;
    outline: initial;
  }

  .ag-cell.ag-selection-fill-top,
  .ag-cell.ag-selection-fill-top.ag-cell-range-selected {
    border-top: 1px dashed;
    border-top-color: #2196f3;
  }

  .ag-ltr .ag-cell.ag-selection-fill-right,
  .ag-ltr .ag-cell.ag-selection-fill-right.ag-cell-range-selected {
    border-right: 1px dashed;
    border-right-color: #2196f3;
  }

  .ag-rtl .ag-cell.ag-selection-fill-right,
  .ag-rtl .ag-cell.ag-selection-fill-right.ag-cell-range-selected {
    border-left: 1px dashed;
    border-left-color: #2196f3;
  }

  .ag-cell.ag-selection-fill-bottom,
  .ag-cell.ag-selection-fill-bottom.ag-cell-range-selected {
    border-bottom: 1px dashed;
    border-bottom-color: #2196f3;
  }

  .ag-ltr .ag-cell.ag-selection-fill-left,
  .ag-ltr .ag-cell.ag-selection-fill-left.ag-cell-range-selected {
    border-left: 1px dashed;
    border-left-color: #2196f3;
  }

  .ag-rtl .ag-cell.ag-selection-fill-left,
  .ag-rtl .ag-cell.ag-selection-fill-left.ag-cell-range-selected {
    border-right: 1px dashed;
    border-right-color: #2196f3;
  }

  .ag-fill-handle,
  .ag-range-handle {
    position: absolute;
    width: 6px;
    height: 6px;
    bottom: -1px;
    background-color: #2196f3;
  }

  .ag-ltr .ag-fill-handle,
  .ag-ltr .ag-range-handle {
    right: -1px;
  }

  .ag-rtl .ag-fill-handle,
  .ag-rtl .ag-range-handle {
    left: -1px;
  }

  .ag-fill-handle {
    cursor: cell;
  }

  .ag-range-handle {
    cursor: nwse-resize;
  }

  .ag-menu .ag-menu-body .ag-filter {
    padding: 16px;
  }

  .ag-menu {
    border: solid 1px #E2E8F0;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
    overflow: visible;
    width: 400px;
  }

  .ag-menu-separator {
    height: 13px;
    display: flex;
    align-items: center;
  }

  .ag-menu-separator-part {
    width: 100%;
  }

  .ag-menu-separator-part::after {
    content: '';
    display: block;
    border-top: solid 1px #E2E8F0;
    width: 100%;
  }

  .ag-menu-option-part,
  .ag-compact-menu-option-part {
    line-height: 16px;
    padding: 8px 0;
  }

  .ag-menu-option-disabled,
  .ag-compact-menu-option-disabled {
    opacity: 0.5;
  }

  .ag-menu-option-icon,
  .ag-compact-menu-option-icon {
    width: 30px;
  }

  .ag-ltr .ag-menu-option-icon,
  .ag-ltr .ag-compact-menu-option-icon {
    padding-left: 12px;
  }

  .ag-rtl .ag-menu-option-icon,
  .ag-rtl .ag-compact-menu-option-icon {
    padding-right: 12px;
  }

  .ag-menu-option-text,
  .ag-compact-menu-option-text {
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
  }

  .ag-ltr .ag-menu-option-shortcut,
  .ag-ltr .ag-compact-menu-option-shortcut {
    padding-right: 6px;
  }

  .ag-rtl .ag-menu-option-shortcut,
  .ag-rtl .ag-compact-menu-option-shortcut {
    padding-left: 6px;
  }

  .ag-menu-option-popup-pointer,
  .ag-compact-menu-option-popup-pointer {
    padding-right: 6px;
  }

  .ag-tabs {
    transform: translate(10px, 25px);
    border-radius: 8px;
    border-color: #E2E8F0;
  }

  .ag-tabs-header {
    min-width: 220px;
    width: 100%;
    display: flex;
    border-bottom:
      solid 1px #babfc7;
  }

  .ag-tab {
    border-bottom: 2px solid transparent;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: color 0.4s;
    padding: 9px;
  }

  .ag-keyboard-focus .ag-tab:focus {
    outline: none;
  }

  .ag-keyboard-focus .ag-tab:focus::after {
    content: '';
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    top: 4px;
    left: 4px;
    display: block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border: 1px solid;
    border-color: rgba(33, 150, 243, 0.4);
  }

  .ag-tab-selected {
    border-bottom-color: #0065ff;
    color: #2196f3;
  }

  .ag-tab-selected .ag-icon {
    color: #0065ff;
  }

  .ag-menu-header {
    color: #181d1f;
    background-color: #f8f8f8;
    padding-top: 1px;
    min-width: 240px;
  }

  .ag-filter-separator {
    border-top: solid 1px;
    border-top-color: #babfc7;
  }

  .ag-filter-condition-operator {
    height: 17px;
  }

  .ag-ltr .ag-filter-condition-operator-or {
    margin-left: 12px;
  }

  .ag-rtl .ag-filter-condition-operator-or {
    margin-right: 12px;
  }

  .ag-set-filter-select-all {
    padding-top: 12px;
  }

  .ag-set-filter-filter {
    margin-top: 12px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .ag-filter-to {
    margin-top: 9px;
  }

  .ag-mini-filter {
    margin: 12px 12px;
  }

  .ag-set-filter-item {
    margin: 0px 12px;
  }

  .ag-ltr .ag-set-filter-item-value {
    margin-left: 12px;
  }

  .ag-rtl .ag-set-filter-item-value {
    margin-right: 12px;
  }

  .ag-filter-apply-panel {
    padding-top: 16px;
    border-top: solid 1px;
    border-top-color: #dde2eb;
    padding-bottom: 5px;
    margin-bottom: -5px;
  }

  .ag-filter-apply-panel-button {
    line-height: 1.5;
  }

  .ag-ltr .ag-filter-apply-panel-button {
    margin-left: 12px;
  }

  .ag-rtl .ag-filter-apply-panel-button {
    margin-right: 12px;
  }

  .ag-standard-button.ag-filter-apply-panel-button {
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }

  .ag-standard-button.ag-filter-apply-panel-button:first-of-type {
    background-color: #ebecf0;
    border-color: transparent;
    color: #091e42;
    font-weight: normal;
  }

  .ag-standard-button.ag-filter-apply-panel-button:last-of-type {
    background-color: #0065ff;
    color: white;
  }

  .ag-group-container.ag-group-container-vertical.ag-filter-toolpanel-group-container {
    padding-left: 0px;
    background-color: #F7FAFC;
  }

  .ag-simple-filter-body-wrapper {
    padding-bottom: 7px;
  }

  .ag-simple-filter-body-wrapper > * {
    margin-bottom: 16px;
  }

  .ag-filter-no-matches {
    padding: 12px 12px;
  }

  .ag-multi-filter-menu-item {
    margin: 6px 0;
  }

  .ag-multi-filter-group-title-bar {
    padding: 12px 6px;
    background-color: transparent;
  }

  .ag-keyboard-focus .ag-multi-filter-group-title-bar:focus {
    outline: none;
  }

  .ag-keyboard-focus .ag-multi-filter-group-title-bar:focus::after {
    content: '';
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    top: 4px;
    left: 4px;
    display: block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border: 1px solid;
    border-color: rgba(33, 150, 243, 0.4);
  }

  .ag-side-bar {
    position: relative;
  }

  .ag-tool-panel-wrapper {
    position: absolute;
    width: 400px;
    background-color: white !important;
    border: 0;
    border-left: 1px solid #dfe1e6;
    z-index: 1;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.16);
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }

  .ag-side-buttons {
    display: none;
  }

  button.ag-side-button-button {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    background: transparent;
    padding: 12px 0;
    width: 100%;
    margin: 0;
    min-height: 108px;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
  }

  button.ag-side-button-button:focus {
    box-shadow: none;
  }

  .ag-keyboard-focus .ag-side-button-button:focus {
    outline: none;
  }

  .ag-keyboard-focus .ag-side-button-button:focus::after {
    content: '';
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    top: 4px;
    left: 4px;
    display: block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border: 1px solid;
    border-color: rgba(33, 150, 243, 0.4);
  }

  .ag-side-button-icon-wrapper {
    margin-bottom: 3px;
  }

  .ag-ltr .ag-side-bar-left,
  .ag-rtl .ag-side-bar-right {
    border-right: solid 1px;
    border-right-color: #babfc7;
  }

  .ag-ltr .ag-side-bar-left .ag-tool-panel-wrapper,
  .ag-rtl .ag-side-bar-right .ag-tool-panel-wrapper {
    border-left: solid 1px;
    border-left-color: #babfc7;
  }

  .ag-ltr .ag-side-bar-left .ag-side-button-button,
  .ag-rtl .ag-side-bar-right .ag-side-button-button {
    border-right: 2px solid transparent;
    transition: border-right 0.3s;
  }

  .ag-ltr .ag-side-bar-left .ag-selected .ag-side-button-button,
  .ag-rtl .ag-side-bar-right .ag-selected .ag-side-button-button {
    border-right-color: #2196f3;
  }

  .ag-rtl .ag-side-bar-left .ag-side-button-button,
  .ag-ltr .ag-side-bar-right .ag-side-button-button {
    border-left: 2px solid transparent;
    transition: border-left 0.3s;
  }

  .ag-rtl .ag-side-bar-left .ag-selected .ag-side-button-button,
  .ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
    border-left-color: #2196f3;
  }

  .ag-filter-toolpanel-header {
    height: 36px;
  }

  .ag-rtl .ag-filter-toolpanel-header,
  .ag-rtl .ag-filter-toolpanel-search {
    padding-right: 6px;
  }

  .ag-keyboard-focus .ag-filter-toolpanel-header:focus {
    outline: none;
  }

  .ag-keyboard-focus .ag-filter-toolpanel-header:focus::after {
    content: '';
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    top: 4px;
    left: 4px;
    display: block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border: 1px solid;
    border-color: rgba(33, 150, 243, 0.4);
  }

  .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after {
    font-family: 'agGridIcons';
    font-size: 16px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\\\\f114';
    position: absolute;
  }

  .ag-ltr .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after {
    padding-left: 6px;
  }

  .ag-rtl .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after {
    padding-right: 6px;
  }

  .ag-filter-toolpanel-group-level-0-header {
    height: 48px;
  }

  .ag-filter-toolpanel-group-item {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .ag-filter-toolpanel-search {
    height: 57px;
  }

  .ag-filter-toolpanel-search-input {
    flex-grow: 1;
    height: 24px;
  }

  .ag-ltr .ag-filter-toolpanel-search-input {
    margin-right: 6px;
  }

  .ag-rtl .ag-filter-toolpanel-search-input {
    margin-left: 6px;
  }

  .ag-filter-toolpanel-group-level-0 {
    border-top: solid 1px;
    border-top-color: #dde2eb;
  }

  .ag-rtl .ag-filter-toolpanel-expand,
  .ag-rtl .ag-filter-toolpanel-group-title-bar-icon {
    margin-left: 6px;
  }

  .ag-filter-toolpanel-group-level-1
    .ag-filter-toolpanel-group-level-1-header.ag-filter-toolpanel-group-title-bar,
  .ag-filter-toolpanel-group-level-2
    .ag-filter-toolpanel-group-level-2-header.ag-filter-toolpanel-group-title-bar,
  .ag-filter-toolpanel-group-level-3
    .ag-filter-toolpanel-group-level-3-header.ag-filter-toolpanel-group-title-bar,
  .ag-filter-toolpanel-group-level-4
    .ag-filter-toolpanel-group-level-4-header.ag-filter-toolpanel-group-title-bar,
  .ag-filter-toolpanel-group-level-5
    .ag-filter-toolpanel-group-level-5-header.ag-filter-toolpanel-group-title-bar,
  .ag-filter-toolpanel-group-level-6
    .ag-filter-toolpanel-group-level-6-header.ag-filter-toolpanel-group-title-bar {
    background-color: transparent;
  }

  .ag-ltr .ag-filter-toolpanel-group-level-1 .ag-filter-toolpanel-group-level-2-header {
    padding-left: 22px;
  }

  .ag-rtl .ag-filter-toolpanel-group-level-1 .ag-filter-toolpanel-group-level-2-header {
    padding-right: 22px;
  }

  .ag-ltr .ag-filter-toolpanel-group-level-2 .ag-filter-toolpanel-group-level-3-header {
    padding-left: 38px;
  }

  .ag-rtl .ag-filter-toolpanel-group-level-2 .ag-filter-toolpanel-group-level-3-header {
    padding-right: 38px;
  }

  .ag-ltr .ag-filter-toolpanel-group-level-3 .ag-filter-toolpanel-group-level-4-header {
    padding-left: 54px;
  }

  .ag-rtl .ag-filter-toolpanel-group-level-3 .ag-filter-toolpanel-group-level-4-header {
    padding-right: 54px;
  }

  .ag-ltr .ag-filter-toolpanel-group-level-4 .ag-filter-toolpanel-group-level-5-header {
    padding-left: 70px;
  }

  .ag-rtl .ag-filter-toolpanel-group-level-4 .ag-filter-toolpanel-group-level-5-header {
    padding-right: 70px;
  }

  .ag-ltr .ag-filter-toolpanel-group-level-5 .ag-filter-toolpanel-group-level-6-header {
    padding-left: 86px;
  }

  .ag-rtl .ag-filter-toolpanel-group-level-5 .ag-filter-toolpanel-group-level-6-header {
    padding-right: 86px;
  }

  .ag-ltr .ag-filter-toolpanel-group-level-6 .ag-filter-toolpanel-group-level-7-header {
    padding-left: 102px;
  }

  //

  .ag-rtl .ag-filter-toolpanel-group-level-6 .ag-filter-toolpanel-group-level-7-header {
    padding-right: 102px;
  }

  .ag-filter-toolpanel-group-level-7
    .ag-filter-toolpanel-group-level-7-header.ag-filter-toolpanel-group-title-bar {
    background-color: transparent;
  }

  .ag-ltr .ag-filter-toolpanel-group-level-7 .ag-filter-toolpanel-group-level-8-header {
    padding-left: 118px;
  }

  .ag-rtl .ag-filter-toolpanel-group-level-7 .ag-filter-toolpanel-group-level-8-header {
    padding-right: 118px;
  }

  .ag-filter-toolpanel-group-level-8
    .ag-filter-toolpanel-group-level-8-header.ag-filter-toolpanel-group-title-bar {
    background-color: transparent;
  }

  .ag-ltr .ag-filter-toolpanel-group-level-8 .ag-filter-toolpanel-group-level-9-header {
    padding-left: 134px;
  }

  .ag-rtl .ag-filter-toolpanel-group-level-8 .ag-filter-toolpanel-group-level-9-header {
    padding-right: 134px;
  }

  .ag-filter-toolpanel-group-level-9
    .ag-filter-toolpanel-group-level-9-header.ag-filter-toolpanel-group-title-bar {
    background-color: transparent;
  }

  .ag-ltr .ag-filter-toolpanel-group-level-9 .ag-filter-toolpanel-group-level-10-header {
    padding-left: 150px;
  }

  .ag-rtl .ag-filter-toolpanel-group-level-9 .ag-filter-toolpanel-group-level-10-header {
    padding-right: 150px;
  }

  .ag-filter-toolpanel-group-level-10
    .ag-filter-toolpanel-group-level-10-header.ag-filter-toolpanel-group-title-bar {
    background-color: transparent;
  }

  .ag-ltr .ag-filter-toolpanel-group-level-10 .ag-filter-toolpanel-group-level-11-header {
    padding-left: 166px;
  }

  .ag-rtl .ag-filter-toolpanel-group-level-10 .ag-filter-toolpanel-group-level-11-header {
    padding-right: 166px;
  }

  .ag-filter-toolpanel-instance-filter {
    padding: 16px 16px 16px 56px;
    background-color: #fafbfc;
    border-top:
      solid 1px #babfc7;
    border-bottom:
      solid 1px #babfc7;
    border-left-color: #babfc7;
  }

  .ag-ltr .ag-filter-toolpanel-instance-header-icon {
    margin-left: 6px;
  }

  .ag-rtl .ag-filter-toolpanel-instance-header-icon {
    margin-right: 6px;
  }

  .ag-pivot-mode-panel {
    height: 44px;
    display: flex;
  }

  .ag-pivot-mode-select {
    display: flex;
    align-items: center;
  }

  .ag-ltr .ag-pivot-mode-select {
    margin-left: 16px;
  }

  .ag-rtl .ag-pivot-mode-select {
    margin-right: 12px;
  }

  .ag-keyboard-focus .ag-column-select-header:focus {
    outline: none;
  }

  .ag-keyboard-focus .ag-column-select-header:focus::after {
    content: '';
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    top: 4px;
    left: 4px;
    display: block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border:
      1px solid rgba(33, 150, 243, 0.4);
  }

  .ag-column-select-header {
    height: 56px;
    align-items: center;
    padding: 0 16px;
    border-bottom:
      solid 1px #dde2eb;
  }

  .ag-column-panel-column-select {
    border-bottom: solid 1px #E2E8F0;
  }

  .ag-column-group-icons,
  .ag-column-select-header-icon {
    color: #181d1f;
  }

  .ag-header {
    border-bottom: solid 1px #E2E8F0;
    background-color: #fafbfc;
  }

  .ag-header-group-text,
  .ag-header-cell-text {
    color: #42526e;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
  }

  .ag-ltr .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon {
    margin-left: 6px;
  }

  .ag-rtl .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon {
    margin-right: 6px;
  }

  .ag-ltr .ag-header-cell.ag-right-aligned-header .ag-header-label-icon {
    margin-right: 6px;
  }

  .ag-rtl .ag-header-cell.ag-right-aligned-header .ag-header-label-icon {
    margin-left: 6px;
  }

  .ag-right-aligned-header .ag-header-cell-text {
    text-align: right;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding-left: 2px;
    padding-right: 2px;
  }

  .ag-header-cell.ag-header-cell-moving,
  .ag-header-group-cell.ag-header-cell-moving {
    background-color: white;
  }

  .ag-keyboard-focus .ag-header-cell:focus,
  .ag-keyboard-focus .ag-header-group-cell:focus {
    outline: none;
  }

  .ag-keyboard-focus .ag-header-cell:focus::after,
  .ag-keyboard-focus .ag-header-group-cell:focus::after {
    content: '';
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    top: 4px;
    left: 4px;
    display: block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border: 1px solid rgba(33, 150, 243, 0.4);
    border-color:rgba(33, 150, 243, 0.4);
  }

  .ag-header-icon {
    color: #181d1f;
    min-width: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ag-header-expand-icon {
    cursor: pointer;
  }

  .ag-ltr .ag-header-expand-icon {
    padding-left: 4px;
  }

  .ag-rtl .ag-header-expand-icon {
    padding-right: 4px;
  }

  .ag-header-row .ag-header-row-column-group {
    box-sizing: content-box;
    border-bottom: solid 1px #E2E8F0;
    overflow: hidden;
  }

  .ag-header-cell-resize {
    display: flex;
    right: -8px;
  }

  .ag-header-cell .ag-header-cell-resize::after,
  .ag-header-group-cell .ag-header-cell-resize::after {
    display: block;
    content: '';
    height: 24px;
    width: 1px;
    background-color: #E2E8F0;
    position: absolute;
    right: 8px;
    transform: translate(0, -50%);
    top: 50%;
  }

  .ag-header-cell.ag-column-resizing .ag-header-cell-resize::after,
  .ag-header-group-cell.ag-column-resizing .ag-header-cell-resize::after {
    background-color: #90cdf4;
  }

  .ag-ltr .ag-header-select-all {
    margin-right: 18px;
  }

  .ag-rtl .ag-header-select-all {
    margin-left: 18px;
  }

  .ag-ltr .ag-floating-filter-button {
    margin-left: 18px;
  }

  .ag-rtl .ag-floating-filter-button {
    margin-right: 18px;
  }

  .ag-floating-filter-button-button {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    appearance: none;
    background: transparent;
    border: none;
    height: 16px;
    padding: 0;
    width: 16px;
  }

  .ag-filter-loading {
    background-color: #f8f8f8;
    height: 100%;
    padding: 12px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .ag-paging-panel {
    display: none;
  }

  .ag-status-bar {
    border-top: solid 1px #e2e8f0;
    color: rgba(24, 29, 31, 0.5);
    padding: 0 24px;
    line-height: 20px;
    font-weight: normal;
  }

  .ag-status-name-value-value {
    color: #181d1f;
    font-weight: 700;
  }

  .ag-status-bar-center {
    text-align: center;
  }

  .ag-status-name-value {
    margin: 0 8px;
    padding: 12px 0;
  }

  .ag-column-drop-cell {
    background: rgba(24, 29, 31, 0.07);
    border-radius: 24px;
    height: 24px;
    padding: 0 3px;
  }

  .ag-column-drop-cell-text {
    margin: 0 6px;
  }

  .ag-column-drop-cell-button {
    min-width: 24px;
    margin: 0 3px;
    color: #181d1f;
    opacity: 0.5;
  }

  .ag-column-drop-cell-drag-handle {
    margin-left: 12px;
  }

  .ag-column-drop-horizontal {
    background-color: #f8f8f8;
    color: #181d1f;
    border-bottom:
      solid 1px #babfc7;
  }

  .ag-ltr .ag-column-drop-horizontal {
    padding-left: 18px;
  }

  .ag-rtl .ag-column-drop-horizontal {
    padding-right: 18px;
  }

  .ag-ltr .ag-column-drop-horizontal-half-width:not(:last-of-type) {
    border-right:
      solid 1px #babfc7;
  }

  .ag-rtl .ag-column-drop-horizontal-half-width:not(:last-of-type) {
    border-left:
      solid 1px #babfc7;
  }

  .ag-column-drop-horizontal-cell-separator {
    margin: 0 6px;
    color: #181d1f;
  }

  .ag-column-drop-horizontal-empty-message {
    color: rgba(24, 29, 31, 0.5);
  }

  .ag-ltr .ag-column-drop-horizontal-icon {
    margin-right: 18px;
  }

  //

  .ag-rtl .ag-column-drop-horizontal-icon {
    margin-left: 18px;
  }

  .ag-column-drop-list.ag-column-drop-vertical-list {
    height: 44px;
    padding: 8px 16px 16px;
  }

  .ag-column-drop-vertical-cell {
    margin-top: 6px;
  }

  .ag-column-drop-vertical {
    min-height: 75px;
    max-height: 175px;
    border-bottom: solid 1px;
    border-bottom-color: #dde2eb;
  }

  .ag-column-drop-vertical.ag-last-column-drop {
    border-bottom: none;
  }

  .ag-column-drop-vertical-icon {
    display: none;
  }

  .ag-column-drop-vertical-empty-message {
    background-color: #fafbfc;
    overflow: hidden;
    color: rgba(24, 29, 31, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border: dashed 1px;
    border-color: #babfc7;
    padding: 12px;
  }

  .ag-select-agg-func-popup {
    border: solid 1px;
    border-color: #babfc7;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
    padding: 6px 0;
    height: 105px;
  }

  .ag-select-agg-func-virtual-list-item {
    cursor: default;
    line-height: 30px;
    padding-left: 12px;
  }

  .ag-select-agg-func-virtual-list-item:hover {
    background-color: rgba(33, 150, 243, 0.3);
  }

  .ag-chart-menu {
    border-radius: 3px;
    background: #fff;
  }

  .ag-chart-menu-icon {
    opacity: 0.5;
    line-height: 24px;
    font-size: 24px;
    width: 24px;
    height: 24px;
    margin: 2px 0;
    cursor: pointer;
    border-radius: 3px;
    color: #181d1f;
  }

  .ag-chart-menu-icon:hover {
    opacity: 1;
  }

  .ag-chart-mini-thumbnail {
    border: 1px solid;
    border-color: #dde2eb;
    border-radius: 5px;
    margin: 5px;
    background-color: #fff;
  }

  .ag-chart-mini-thumbnail:nth-last-of-type(3),
  .ag-chart-mini-thumbnail:nth-last-of-type(3) ~ .ag-chart-mini-thumbnail {
    margin-left: auto;
    margin-right: auto;
  }

  .ag-ltr .ag-chart-mini-thumbnail:first-of-type {
    margin-left: 0;
  }

  .ag-rtl .ag-chart-mini-thumbnail:first-of-type {
    margin-right: 0;
  }

  .ag-ltr .ag-chart-mini-thumbnail:last-of-type {
    margin-right: 0;
  }

  .ag-rtl .ag-chart-mini-thumbnail:last-of-type {
    margin-left: 0;
  }

  .ag-chart-mini-thumbnail.ag-selected {
    border-color: #2196f3;
  }

  .ag-chart-settings-card-item {
    background: #181d1f;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  .ag-chart-settings-card-item.ag-selected {
    background-color: #2196f3;
  }

  .ag-chart-data-column-drag-handle {
    margin-left: 6px;
  }

  .ag-charts-settings-group-title-bar,
  .ag-charts-data-group-title-bar,
  .ag-charts-format-top-level-group-title-bar {
    border-top: solid 1px;
    border-top-color: #dde2eb;
    padding: 6px 12px;
    line-height: 20px;
  }

  .ag-charts-settings-group-container {
    padding: 6px;
  }

  .ag-charts-data-group-container {
    padding: 12px;
    padding-bottom: 3px;
  }

  .ag-charts-data-group-container > * {
    margin-bottom: 9px;
  }

  .ag-charts-format-top-level-group-container {
    margin-left: 12px;
    padding: 6px;
  }

  .ag-charts-format-top-level-group-item {
    margin: 6px 0;
  }

  .ag-charts-format-sub-level-group-container {
    padding: 12px 12px;
    padding-bottom: 0;
  }

  .ag-charts-format-sub-level-group-container > * {
    margin-bottom: 9px;
  }

  .ag-charts-group-container.ag-group-container-horizontal {
    padding: 6px;
  }

  .ag-chart-data-section,
  .ag-chart-format-section {
    display: flex;
    margin: 0;
  }

  .ag-chart-menu-panel {
    background-color: #f8f8f8;
  }

  .ag-ltr .ag-chart-menu-panel {
    border-left: solid 1px;
    border-left-color: #babfc7;
  }

  .ag-rtl .ag-chart-menu-panel {
    border-right: solid 1px;
    border-right-color: #babfc7;
  }

  .ag-date-time-list-page-title {
    flex-grow: 1;
    text-align: center;
  }

  .ag-date-time-list-page-column-label,
  .ag-date-time-list-page-entry {
    text-align: center;
  }

  .ag-checkbox-input-wrapper {
    font-family: 'agGridIcons';
    font-size: 16px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    flex: none;
  }

  .ag-checkbox-input-wrapper input {
    -webkit-appearance: none;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  .ag-checkbox-input-wrapper:focus-within,
  .ag-checkbox-input-wrapper:active {
    outline: none;
    box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  }

  .ag-checkbox-input-wrapper.ag-disabled {
    opacity: 0.5;
  }

  .ag-checkbox-input-wrapper::after {
    content: '\\\\f108';
    color: #999;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    content: '\\\\f106';
    color: #2196f3;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    content: '\\\\f107';
    color: #999;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  //

  .ag-toggle-button-input-wrapper {
    box-sizing: border-box;
    width: 28px;
    height: 18px;
    background-color: #999;
    border-radius: 9px;
    position: relative;
    flex: none;
    border: 1px solid;
    border-color: #999;
  }

  .ag-toggle-button-input-wrapper input {
    opacity: 0;
    height: 100%;
    width: 100%;
  }

  .ag-toggle-button-input-wrapper:focus-within {
    outline: none;
    box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  }

  .ag-toggle-button-input-wrapper.ag-disabled {
    opacity: 0.5;
  }

  .ag-toggle-button-input-wrapper.ag-checked {
    background-color: #2196f3;
    border-color: #2196f3;
  }

  .ag-toggle-button-input-wrapper::before {
    content: ' ';
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    box-sizing: border-box;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 9px;
    transition: left 100ms;
    border: 1px solid;
    border-color: #999;
  }

  .ag-toggle-button-input-wrapper.ag-checked::before {
    left: calc(100% - 18px);
    border-color: #2196f3;
  }

  .ag-radio-button-input-wrapper {
    font-family: 'agGridIcons';
    font-size: 16px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 3px 16px;
    display: inline-block;
    vertical-align: middle;
    flex: none;
  }

  .ag-radio-button-input-wrapper input {
    -webkit-appearance: none;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  .ag-radio-button-input-wrapper:focus-within,
  .ag-radio-button-input-wrapper:active {
    outline: none;
    box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  }

  .ag-radio-button-input-wrapper.ag-disabled {
    opacity: 0.5;
  }

  .ag-radio-button-input-wrapper::after {
    content: '\\\\f126';
    color: #999;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .ag-radio-button-input-wrapper.ag-checked::after {
    content: '\\\\f127';
    color: #2196f3;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  input[class^='ag-'][type='range'] {
    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.5;
    }

    &::-webkit-slider-runnable-track,
    &::-moz-range-track,
    &::-ms-track {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 3px;
      background-color: #babfc7;
      border-radius: 3px;
    }

    &::-webkit-slider-thumb,
    &::-ms-thumb,
    &::-moz-range-thumb {
      margin: 0;
      padding: 0;
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      background-color: #fff;
      border: 1px solid #999;
      border-radius: 16px;
      transform: translateY(-6.5px);
    }

    &:focus::-webkit-slider-thumb,
    &:focus::-ms-thumb,
    &:focus::-moz-range-thumb {
      box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
      border-color: #2196f3;
    }

    &:active::-webkit-slider-runnable-track,
    &:active::-moz-range-track,
    &:active::-ms-track {
      background-color: rgba(33, 150, 243, 0.4);
    }
  }

  .ag-filter-toolpanel-header,
  .ag-filter-toolpanel-search,
  .ag-status-bar,
  .ag-header-row,
  .ag-panel-title-bar-title,
  .ag-multi-filter-group-title-bar {
    font-weight: 700;
    color: #181d1f;
  }

  .ag-rtl .ag-pinned-left-header .ag-header-row::before {
    left: 0;
  }

  .ag-ltr input[class^='ag-']:not([type]),
  .ag-ltr input[class^='ag-'][type='text'],
  .ag-ltr input[class^='ag-'][type='number'],
  .ag-ltr input[class^='ag-'][type='tel'],
  .ag-ltr input[class^='ag-'][type='date'],
  .ag-ltr input[class^='ag-'][type='datetime-local'],
  .ag-ltr textarea[class^='ag-'] {
    padding-left: 6px;
  }

  .ag-rtl input[class^='ag-']:not([type]),
  .ag-rtl input[class^='ag-'][type='text'],
  .ag-rtl input[class^='ag-'][type='number'],
  .ag-rtl input[class^='ag-'][type='tel'],
  .ag-rtl input[class^='ag-'][type='date'],
  .ag-rtl input[class^='ag-'][type='datetime-local'],
  .ag-rtl textarea[class^='ag-'] {
    padding-right: 6px;
  }

  .ag-chart-settings-nav-bar {
    border-top: 1px solid #dde2eb;
  }

  .ag-ltr .ag-group-title-bar-icon,
  .ag-rtl .ag-group-title-bar-icon {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-ltr .ag-group-title-bar-icon {
    margin: 0 16px;
  }

  .ag-rtl .ag-group-title-bar-icon {
    margin-left: 6px;
  }

  .ag-charts-format-top-level-group-toolbar {
    margin-top: 6px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .ag-charts-format-sub-level-group {
    border-left: 1px dashed #babfc7;
    padding-left: 6px;
    margin-bottom: 12px;
  }

  .ag-charts-format-sub-level-group-title-bar {
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    font-weight: 700;
  }

  .ag-charts-format-sub-level-group-item:last-of-type {
    margin-bottom: 0;
  }

  .ag-standard-button {
    appearance: none;
    border-radius: 3px;
    border: 1px solid #2196f3;
    color:#2196f3;
    background-color:#fff;
    font-weight: 600;
    padding: 6px 12px;
  }

  .ag-standard-button:hover {
    background-color: rgba(33, 150, 243, 0.1);
  }

  .ag-standard-button:active {
    background-color: #2196f3;
    color:#fff;
  }

  .ag-standard-button:disabled {
    color: rgba(24, 29, 31, 0.5);
    background-color: #f1f2f4;
    border-color: rgba(186, 191, 199, 0.3);
  }

  .ag-column-drop-vertical-title-bar {
    padding: 12px 0;
  }

  .ag-column-drop-empty-message {
    color:#181d1f;
    opacity: 0.75;
  }

  .ag-column-drop-cell-button:hover,
  .ag-header-cell-menu-button:hover,
  .ag-side-button-button:hover,
  .ag-tab:hover,
  .ag-panel-title-bar-button:hover,
  .ag-header-expand-icon:hover,
  .ag-group-expanded .ag-icon:hover,
  .ag-group-contracted .ag-icon:hover,
  .ag-chart-settings-prev:hover,
  .ag-chart-settings-next:hover,
  .ag-floating-filter-button-button:hover,
  .ag-chart-menu-icon:hover {
    color: #2196f3;
  }

  .ag-chart-settings-card-item.ag-not-selected:hover {
    opacity: 0.35;
  }

  .ag-filter-toolpanel-group-container {
    padding-left: 6px;
  }

  .ag-set-filter-list {
    padding: 3px 0;
  }

  .ag-date-time-list-page-entry-is-current {
    background-color: #2196f3;
  }

  .ag-pinned-left-header,
  .ag-pinned-left-cols-container,
  .ag-pinned-right-header,
  .ag-pinned-right-cols-container {
    border-color: #E2E8F0;
    border-style: solid;
    z-index: 1;
  }

  .ag-pinned-left-header,
  .ag-pinned-left-cols-container {
    border-right-width: 1px;
  }

  .ag-pinned-right-header,
  .ag-pinned-right-cols-container {
    border-left-width: 1px;
  }

  //

  .ag-pinned-right-cols-container,
  .ag-pinned-left-cols-container {
    background-color: white;
    min-height: 100%;
  }

  .ag-filter-toolpanel,
  .ag-custom-settings-toolpanel {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ag-panel-header {
    order: -1;
    width: 100%;
  }

  .ag-default-panel-header {
    height: 57px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    color: #091e42;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    padding: 0 16px;
    border-bottom: 1px solid #E2E8F0;
  }

  .ag-panel-content {
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid #E2E8F0;
  }

  .ag-custom-overlay-wrapper {
    height: 100%;
  }

  .ag-header-group-cell-label {
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 500;
  }

  .ag-header-cell {
    overflow: visible;
  }

  .ag-pinned-right-header .ag-header-cell-resize {
    left: -15px;
  }

  .ag-pinned-left-cols-container .ag-row-loading .ag-loading,
  .ag-pinned-right-cols-container .ag-row-loading .ag-loading {
    display: none;
  }

  .ag-suppress-movable-col {
    background-color: #fffde8;
  }

  .ag-locked-col {
    background-color: #f4f5f7;
  }

  .ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child,
  .ag-menu.ag-ltr.ag-popup-child {
    background-color: #fff;
    margin-left: 10px;
    margin-top: -8px;
  }

  .ag-menu-list {
    display: flex;
    flex-direction: column;
  }

  .ag-menu-list.ag-focus-managed {
    padding: 8px;
  }

  .ag-menu-option {
    display: flex;
    justify-content: space-between;
  }

  .ag-menu-option.ag-menu-option-active {
    background-color: #ebf8ff;
    color: #2a4365;
    border-radius: 4px;
  }

  .ag-filter-toolpanel-search .ag-input-field-input.ag-text-field-input,
  .ag-column-select-header .ag-input-field-input.ag-text-field-input {
    height: 32px;
    background-color: #f4f5f7;
    border-radius: 4px;
    border: none;
    padding-left: 8px !important;
  }

  .ag-column-select-virtual-list-viewport {
    padding: 14px 16px;
  }

  .ag-virtual-list-item.ag-column-select-virtual-list-item {
    margin: 0;
    display: flex;
    align-items: center;
  }

  .ag-column-drop-title-bar.ag-column-drop-vertical-title-bar {
    padding: 12px 16px 0 15px;
  }

  .ag-column-drop-title.ag-column-drop-vertical-title {
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #42526e;
  }

  .ag-input-field-input.ag-text-field-input {
    background-color: #fff;
    color: #7a869a !important;
    border-radius: 4px !important;
    height: 32px;
    padding: 6px 0px 6px 8px !important;
  }

  .ag-wrapper.ag-picker-field-wrapper {
    height: 32px;
  }

  .ag-picker-field-icon {
    padding: 8px;
  }

  .ag-input-field-input.ag-number-field-input {
    height: 32px;
    padding: 6px 0px 6px 8px;
  }

  .ag-picker-field-display {
    margin: 6px 0px 6px 8px;
  }

  .ag-filter-toolpanel-search-input.ag-labeled.ag-label-align-left.ag-text-field.ag-input-field {
    margin: 16px;
  }

  .ag-group-title-bar.ag-filter-toolpanel-group-title-bar.ag-unselectable.ag-filter-toolpanel-group-level-0-header.ag-filter-toolpanel-header {
    padding: 12px 0px;
  }

  .ag-column-group-icons,
  .ag-column-select-header-icon,
  .ag-group-title-bar-icon,
  .ag-filter-toolpanel-expand {
    height: 24px;
    width: 24px;
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    background-color: #EDF2F7;

    &:hover {
      background-color: #E2E8F0;
    }

    & > * {
      display: flex;
    }
  }

  .ag-column-select-header-icon,
  .ag-column-group-icons {
    margin-left: 0;
  }

  .ag-filter-toolpanel-search .ag-filter-toolpanel-expand {
    margin-right: 0;
  }

  .ag-row-action-hover {
    cursor: pointer;
  }

  .ag-tooltip-custom {
    position: absolute;
    width: auto;
  }

  .ag-root .ag-horizontal-left-spacer {
    border-right: 1px solid;
    border-color: #E2E8F0;
    overflow: hidden;
  }

  .ag-root .ag-horizontal-right-spacer {
    border-left: 1px solid;
    border-color: #E2E8F0;
    overflow: hidden;
  }

  .ag-cell[col-id='eag-col-actions'],
  .ag-cell[col-id='eag-col-expander'] {
    padding: 0px;
  }
`;

import {Attributes, AttributesRow, Button, Card, DataStatus} from 'platform/components';
import {Heading, HStack, Show, Space, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {defaultTo, isNotNil, mergeAll} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  GetIssueNoteCorrectionResponse,
  useGetCustomerV2Query,
  useGetVehicleQuery,
  useGetWarehouseQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {warehouseRoutes, workshopRoutes} from '@dms/routes';
import {DEFAULT_CURRENCY, getCustomerName, Section} from '@dms/shared';

import {
  composePath,
  EMPTY_PLACEHOLDER,
  Nullish,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface OverviewProps extends RequiredTestIdProps {
  issueNoteCorrection: GetIssueNoteCorrectionResponse | Nullish;
  isIssueNoteCorrectionLoading: boolean;
}

export function Overview(props: OverviewProps) {
  const {issueNoteCorrectionId} = useWarehouseParams();

  const formatDateTime = useDateTimeFormatter();

  const formatCurrency = useFormatCurrency();

  const issueNoteCorrectionReturnedById =
    props.issueNoteCorrection?.issueNoteCorrection?.returnedBy;

  const issueNoteCorrectionReceivedById =
    props.issueNoteCorrection?.issueNoteCorrection?.receivedBy;

  const issueNoteIssuedById = props.issueNoteCorrection?.issueNote?.issuedBy;

  const issueNoteReceivedById = props.issueNoteCorrection?.issueNote?.receivedBy;

  const directSaleCustomerId =
    props.issueNoteCorrection?.originDocTypeInformation?.directSale?.customerId;

  const serviceOrderCustomerId =
    props.issueNoteCorrection?.originDocTypeInformation?.serviceOrder?.customerId;

  const serviceOrderIssueCustomerId =
    props.issueNoteCorrection?.originDocTypeInformation?.serviceOrderIssue?.customerId;

  const directSaleVehicleId =
    props.issueNoteCorrection?.originDocTypeInformation?.directSale?.vehicleId;

  const serviceOrderVehicleId =
    props.issueNoteCorrection?.originDocTypeInformation?.serviceOrder?.vehicleId;

  const serviceOrderIssueVehicleId =
    props.issueNoteCorrection?.originDocTypeInformation?.serviceOrderIssue?.vehicleId;

  const {data: issueNoteCorrectionReturnedBy, isLoading: isIssueNoteCorrectionReturnedByLoading} =
    useGetCustomerV2Query(
      {customerId: issueNoteCorrectionReturnedById as string},
      {skip: isNilOrEmpty(issueNoteCorrectionReturnedById)}
    );

  const {data: issueNoteCorrectionReceivedBy, isLoading: isIssueNoteCorrectionReceivedByLoading} =
    useGetCustomerV2Query(
      {customerId: issueNoteCorrectionReceivedById as string},
      {skip: isNilOrEmpty(issueNoteCorrectionReceivedById)}
    );

  const {data: issueNoteIssuedBy, isLoading: isIssueNoteIssuedByLoading} = useGetCustomerV2Query(
    {customerId: issueNoteIssuedById as string},
    {skip: isNilOrEmpty(issueNoteIssuedById)}
  );

  const {data: issueNoteReceivedBy, isLoading: isIssueNoteReceivedByLoading} =
    useGetCustomerV2Query(
      {customerId: issueNoteReceivedById as string},
      {skip: isNilOrEmpty(issueNoteReceivedById)}
    );

  const {data: directSaleCustomer, isLoading: isDirectSaleCustomerLoading} = useGetCustomerV2Query(
    {customerId: directSaleCustomerId as string},
    {
      skip: isNilOrEmpty(directSaleCustomerId),
    }
  );

  const {data: serviceOrderCustomer, isLoading: isServiceOrderCustomerLoading} =
    useGetCustomerV2Query(
      {customerId: serviceOrderCustomerId as string},
      {skip: isNilOrEmpty(serviceOrderCustomerId)}
    );

  const {data: serviceOrderIssueCustomer, isLoading: isServiceOrderIssueCustomerLoading} =
    useGetCustomerV2Query(
      {
        customerId: serviceOrderIssueCustomerId as string,
      },
      {skip: isNilOrEmpty(serviceOrderIssueCustomerId)}
    );

  const {data: directSaleVehicle, isLoading: isDirectSaleVehicleLoading} = useGetVehicleQuery(
    {vehicleId: directSaleVehicleId as string},
    {skip: isNilOrEmpty(directSaleVehicleId)}
  );

  const {data: serviceOrderVehicle, isLoading: isServiceOrderVehicleLoading} = useGetVehicleQuery(
    {vehicleId: serviceOrderVehicleId as string},
    {skip: isNilOrEmpty(serviceOrderVehicleId)}
  );

  const {data: serviceOrderIssueVehicle, isLoading: isServiceOrderIssueVehicleLoading} =
    useGetVehicleQuery(
      {vehicleId: serviceOrderIssueVehicleId as string},
      {skip: isNilOrEmpty(serviceOrderIssueVehicleId)}
    );

  const {data: issueNoteCorrectionWarehouse, isLoading: isIssueNoteCorrectionWarehouseLoading} =
    useGetWarehouseQuery(
      {warehouseId: props.issueNoteCorrection?.issueNoteCorrection.warehouseId as string},
      {skip: isNilOrEmpty(props.issueNoteCorrection?.issueNoteCorrection.warehouseId)}
    );

  const {data: issueNoteWarehouse, isLoading: isIssueNoteWarehouseLoading} = useGetWarehouseQuery(
    {warehouseId: props.issueNoteCorrection?.issueNote.warehouseId as string},
    {skip: isNilOrEmpty(props.issueNoteCorrection?.issueNote.warehouseId)}
  );

  const isLoading =
    props.isIssueNoteCorrectionLoading ||
    isIssueNoteCorrectionReturnedByLoading ||
    isIssueNoteCorrectionReceivedByLoading ||
    isIssueNoteIssuedByLoading ||
    isIssueNoteReceivedByLoading ||
    isDirectSaleCustomerLoading ||
    isServiceOrderCustomerLoading ||
    isServiceOrderIssueCustomerLoading ||
    isDirectSaleVehicleLoading ||
    isServiceOrderVehicleLoading ||
    isServiceOrderIssueVehicleLoading ||
    isIssueNoteCorrectionWarehouseLoading ||
    isIssueNoteWarehouseLoading;

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {issueNoteCorrectionId}]),
    [issueNoteCorrectionId]
  );

  const formatToDateTimeShort = (date: string | Nullish) => {
    if (isNilOrEmpty(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDateTime('dateTimeShort', parseDate(date));
  };

  const openInNewTab = (
    entity: 'issueNote' | 'directSale' | 'serviceOrder' | 'serviceOrderIssue',
    id: string | Nullish
  ) => {
    if (isNilOrEmpty(id)) {
      return;
    }

    match(entity)
      .with('issueNote', () =>
        window.open(composePath(warehouseRoutes.issueNoteDetailOverview, {params: {id}}), '_blank')
      )
      .with('directSale', () =>
        window.open(
          composePath(warehouseRoutes.directSalesDetailOverview, {params: {id}}),
          '_blank'
        )
      )
      .with('serviceOrder', () =>
        window.open(composePath(workshopRoutes.serviceCaseDetail, {params: {id}}), '_blank')
      )
      .with('serviceOrderIssue', () =>
        window.open(
          composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
            params: {id},
          }),
          '_blank'
        )
      )
      .exhaustive();
  };

  const issueNoteCorrectionReturnedByName = getCustomerName(issueNoteCorrectionReturnedBy);
  const issueNoteCorrectionReceivedByName = getCustomerName(issueNoteCorrectionReceivedBy);
  const issueNoteIssuedByName = getCustomerName(issueNoteIssuedBy);
  const issueNoteReceivedByName = getCustomerName(issueNoteReceivedBy);
  const directSaleCustomerName = getCustomerName(directSaleCustomer);
  const serviceOrderCustomerName = getCustomerName(serviceOrderCustomer);
  const serviceOrderIssueCustomerName = getCustomerName(serviceOrderIssueCustomer);

  const issueNoteCorrectionAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.returnedBy'),
      value: issueNoteCorrectionReturnedByName,
    },
    {
      label: i18n.t('general.labels.receivedBy'),
      value: issueNoteCorrectionReceivedByName,
    },
    {
      label: i18n.t('entity.warehouse.labels.warehouse'),
      value: issueNoteCorrectionWarehouse?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfCreation'),
      value: formatToDateTimeShort(props.issueNoteCorrection?.issueNoteCorrection.createdAt),
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfReturn'),
      value: formatToDateTimeShort(props.issueNoteCorrection?.issueNoteCorrection.returnedAt),
    },
  ];

  const issueNoteAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.number'),
      content: (
        <Button
          variant="link"
          size="small"
          title={props.issueNoteCorrection?.issueNote?.number}
          rightIcon="action/launch"
          onClick={() => openInNewTab('issueNote', props.issueNoteCorrection?.issueNote?.id)}
          data-testid={suffixTestId('linkToIssueNote', props)}
        />
      ),
    },
    {
      label: i18n.t('general.labels.issuedBy'),
      value: issueNoteIssuedByName,
    },
    {
      label: i18n.t('general.labels.receivedBy'),
      value: issueNoteReceivedByName,
    },
    {
      label: i18n.t('entity.warehouse.labels.warehouse'),
      value: issueNoteWarehouse?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfCreation'),
      value: formatToDateTimeShort(props.issueNoteCorrection?.issueNote.createdAt),
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfIssue'),
      value: formatToDateTimeShort(props.issueNoteCorrection?.issueNote?.issuedAt),
    },
  ];

  const directSaleAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.number'),
      content: (
        <Button
          variant="link"
          size="small"
          title={props.issueNoteCorrection?.originDocTypeInformation?.directSale?.number}
          rightIcon="action/launch"
          onClick={() =>
            openInNewTab(
              'directSale',
              props.issueNoteCorrection?.originDocTypeInformation?.directSale?.id
            )
          }
          data-testid={suffixTestId('linkToDirectSale', props)}
        />
      ),
    },
    {
      label: i18n.t('general.labels.type'),
      value: props.issueNoteCorrection?.originDocTypeInformation?.directSale?.type,
    },
    {
      label: i18n.t('general.labels.variant'),
      value: props.issueNoteCorrection?.originDocTypeInformation?.directSale?.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: directSaleCustomerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: directSaleVehicle?.title,
    },
  ];

  const serviceOrderAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.number'),
      content: (
        <Button
          variant="link"
          size="small"
          title={props.issueNoteCorrection?.originDocTypeInformation?.serviceOrder?.number}
          rightIcon="action/launch"
          onClick={() =>
            openInNewTab(
              'serviceOrder',
              props.issueNoteCorrection?.originDocTypeInformation?.serviceOrder?.id
            )
          }
          data-testid={suffixTestId('linkToServiceOrder', props)}
        />
      ),
    },
    {
      label: i18n.t('general.labels.type'),
      value: props.issueNoteCorrection?.originDocTypeInformation?.serviceOrder?.type,
    },
    {
      label: i18n.t('general.labels.variant'),
      value: props.issueNoteCorrection?.originDocTypeInformation?.serviceOrder?.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: serviceOrderCustomerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: serviceOrderVehicle?.title,
    },
  ];

  const serviceOrderIssueAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.number'),
      content: (
        <Button
          variant="link"
          size="small"
          title={props.issueNoteCorrection?.originDocTypeInformation?.serviceOrderIssue?.number}
          rightIcon="action/launch"
          onClick={() =>
            openInNewTab(
              'serviceOrderIssue',
              props.issueNoteCorrection?.originDocTypeInformation?.serviceOrderIssue?.id
            )
          }
          data-testid={suffixTestId('linkToServiceOrderIssue', props)}
        />
      ),
    },
    {
      label: i18n.t('general.labels.type'),
      value: props.issueNoteCorrection?.originDocTypeInformation?.serviceOrderIssue?.type,
    },
    {
      label: i18n.t('general.labels.variant'),
      value: props.issueNoteCorrection?.originDocTypeInformation?.serviceOrderIssue?.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: serviceOrderIssueCustomerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: serviceOrderIssueVehicle?.title,
    },
  ];

  const totalValue = formatCurrency(
    defaultTo(0, props.issueNoteCorrection?.summaryInformation.totalValue.amount),
    defaultTo(DEFAULT_CURRENCY, props.issueNoteCorrection?.summaryInformation.totalValue.currency),
    2
  );

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={isLoading} minHeight={60}>
        <Card title={i18n.t('entity.warehouse.labels.issueNoteCorrectionInformation')}>
          <Attributes
            rows={issueNoteCorrectionAttributes}
            size="quarter"
            data-testid={suffixTestId('issueNoteCorrectionAttributes', props)}
          />

          <Space vertical={4} />

          <VStack spacing={4}>
            <Show when={isNotNil(props.issueNoteCorrection?.originDocTypeInformation?.directSale)}>
              <VStack spacing={4}>
                <Card title={i18n.t('entity.warehouse.labels.directSale')} variant="inlineGrey">
                  <Attributes
                    rows={directSaleAttributes}
                    size="quarter"
                    data-testid={suffixTestId('directSaleAttributes', props)}
                  />
                </Card>
                <Card title={i18n.t('page.warehouse.labels.issueNote')} variant="inlineGrey">
                  <Attributes
                    rows={issueNoteAttributes}
                    size="quarter"
                    data-testid={suffixTestId('issueNoteAttributes', props)}
                  />
                </Card>
              </VStack>
            </Show>
            <Show
              when={isNotNil(props.issueNoteCorrection?.originDocTypeInformation?.serviceOrder)}
            >
              <VStack spacing={4}>
                <Card title={i18n.t('entity.warehouse.labels.serviceOrder')} variant="inlineGrey">
                  <Attributes
                    rows={serviceOrderAttributes}
                    size="quarter"
                    data-testid={suffixTestId('serviceOrderAttributes', props)}
                  />
                </Card>
                <Card title={i18n.t('page.warehouse.labels.issueNote')} variant="inlineGrey">
                  <Attributes
                    rows={issueNoteAttributes}
                    size="quarter"
                    data-testid={suffixTestId('issueNoteAttributes', props)}
                  />
                </Card>
              </VStack>
            </Show>
            <Show
              when={isNotNil(
                props.issueNoteCorrection?.originDocTypeInformation?.serviceOrderIssue
              )}
            >
              <VStack spacing={4}>
                <Card title={i18n.t('entity.warehouse.labels.serviceOrder')} variant="inlineGrey">
                  <Attributes
                    rows={serviceOrderIssueAttributes}
                    size="quarter"
                    data-testid={suffixTestId('serviceOrderIssueAttributes', props)}
                  />
                </Card>
                <Card title={i18n.t('page.warehouse.labels.issueNote')} variant="inlineGrey">
                  <Attributes
                    rows={issueNoteAttributes}
                    size="quarter"
                    data-testid={suffixTestId('issueNoteAttributes', props)}
                  />
                </Card>
              </VStack>
            </Show>
          </VStack>
        </Card>

        <Space vertical={4} />

        <Card title={i18n.t('entity.warehouse.labels.issuedItems')}>
          <DataGrid
            gridCode="warehouse-issue-note-correction-items"
            queryModifier={queryModifier}
            autoHeight
            data-testid={suffixTestId('issuedItems', props)}
          />

          <HStack minHeight={15} align="center" justify="space-between">
            <Heading size={5}>{i18n.t('general.labels.totalValue')}</Heading>
            <Heading size={5}>{totalValue}</Heading>
          </HStack>
        </Card>
      </DataStatus>
    </Section>
  );
}

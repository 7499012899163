import {AutoLinkNode, LinkNode} from '@lexical/link';
import {LexicalEditor, LexicalNode, TextNode} from 'lexical';
import {
  BeautifulMentionComponentProps,
  BeautifulMentionNode,
  BeautifulMentionsMenuItemProps,
  createBeautifulMentionNode,
} from 'lexical-beautiful-mentions';
import {DOMExportOutputMap} from 'lexical/LexicalNode';
import {
  Button,
  ButtonGroup,
  CustomMenuItem,
  FormButton,
  FormControl,
  FormField,
  showNotification,
  StyledContentEditable,
  Tooltip,
  useDialog,
} from 'platform/components';
import {Box, Show, VStack} from 'platform/foundation';
import styled from 'styled-components';
import {match} from 'ts-pattern';

import {forwardRef} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {i18n} from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {getVariables, replaceVariables, usePermissions, VARIABLE_REGEX} from '@dms/shared';

import {useNavigate, usePrevious} from 'shared';

import {contextOptions} from '../constants/contextOptions';
import {useContextParameters} from '../hooks/useContextParameters';
import {MessageTemplateFormValues} from '../types/MessageTemplateFormValues';
import {MessagePreviewDialog} from './MessagePreviewDialog';

type Props = {
  formApi: UseFormReturn<MessageTemplateFormValues>;
  control: FormControl<MessageTemplateFormValues>;
  templateType: 'sms' | 'email';
  isMutationInProgress: boolean;
};

const ContentEditableComponent = styled(StyledContentEditable)<{$isMessage: boolean}>`
  .mentions {
    display: inline-block;
    background-color: ${({theme}) => theme.colors.palettes.blue[30][100]};
    border-radius: ${({theme}) => theme.radii.large};
    padding: ${({theme}) => theme.getSize(1)} ${({theme}) => theme.getSize(2)};
    transition: all 250ms;

    &:hover {
      background-color: ${({theme}) => theme.colors.palettes.blue[40][100]};
    }
  }
`;

const ContentEditableComponentMessage = styled(ContentEditableComponent)`
  min-height: ${({theme}) => theme.getSize(25)};
`;

// This is needed because Tooltip component wrap itself into <div>
const CustomMentionWrapper = styled.span`
  & > div {
    display: inline-block;
  }
`;

interface MenuItemComponentProps extends BeautifulMentionsMenuItemProps {
  ref?: React.Ref<HTMLLIElement>;
}

const MenuItemComponent = ({selected, ref, item, ...props}: MenuItemComponentProps) => {
  const component = <CustomMenuItem ref={ref} item={item} selected={selected} {...props} />;

  if (selected) {
    return (
      <Tooltip label={item.data?.description} isOpen placement="right" offset={14}>
        {component}
      </Tooltip>
    );
  }

  return component;
};

const CustomBeautifulMentionComponent = forwardRef<
  HTMLSpanElement,
  BeautifulMentionComponentProps<{id: string; description: string}>
>(({data, value, ...other}, ref) => (
  <CustomMentionWrapper ref={ref} {...other}>
    <Tooltip label={data?.description} placement="top">
      {value}
    </Tooltip>
  </CustomMentionWrapper>
));

const CustomMention = createBeautifulMentionNode(CustomBeautifulMentionComponent);

const linkFormatter = (_editor: LexicalEditor, node: LexicalNode) => {
  const convertedNode = node as AutoLinkNode;
  const element = document.createTextNode(convertedNode.getURL());
  return {element};
};

const exportHtmlNodeTransforms = (nodes: DOMExportOutputMap) => {
  nodes.set(CustomMention[0], (_editor, node) => {
    const convertedNode = node as BeautifulMentionNode;
    const element = document.createTextNode(`{{ ${convertedNode.getData?.()?.code} }}`);
    return {element};
  });
  nodes.set(AutoLinkNode, linkFormatter);
  nodes.set(LinkNode, linkFormatter);

  return nodes;
};

export const MessageTemplateFields = (props: Props) => {
  const navigate = useNavigate();
  const [canPatchMessageTemplate] = usePermissions({
    permissionKeys: ['patchMessageTemplate'],
  });
  const [isOpen, onOpen, onClose] = useDialog(false);
  const context = props.formApi.watch('context');
  const previousContext = usePrevious(context);

  const {contextParameters, isLoading} = useContextParameters(context);

  const redirectToMessageTemplatesOverview = () => navigate(settingsRoutes.messageTemplates);
  const transformStringToNodes = (value: string | null) => {
    if (!value) {
      return [];
    }
    return getVariables(value).map((val) =>
      match(val.type)
        .with('variable', () => {
          const contextParameter = contextParameters.find((param) => param.code === val.text);
          return new CustomMention[0]('/', contextParameter?.label || '', contextParameter);
        })
        .with('text', () => new TextNode(val.text || ''))
        .exhaustive()
    );
  };

  const suggestionInputProps = {
    placeholder: i18n.t('page.messageTemplates.labels.placeholderHint'),
    prefix: '/',
    options: contextParameters,
    MenuItemComponent,
    exportHtmlNodeTransforms,
    transformStringToNodes,
    CustomMention,
  };

  const onOpenPreview = () => {
    onOpen();
  };
  const onContextChange = (context: string | number | null) => {
    if (previousContext !== context) {
      const formValues = props.formApi.getValues();
      props.formApi.setValue('subject', replaceVariables(formValues.subject || '', ''));
      props.formApi.setValue('body', replaceVariables(formValues.body || '', ''));

      if (VARIABLE_REGEX.test(formValues.subject) || VARIABLE_REGEX.test(formValues.body)) {
        showNotification.info(i18n.t('page.messageTemplates.notification.placeholdersCleared'));
      }
    }
  };

  return (
    <VStack spacing={6}>
      <FormField
        name="name"
        type="text"
        control={props.control}
        label={i18n.t('page.messageTemplates.labels.templateName')}
        data-testid={testIds.settings.messageTemplates('templateName')}
        isRequired
      />
      <FormField
        name="context"
        type="choice"
        onChange={onContextChange}
        options={contextOptions}
        control={props.control}
        label={i18n.t('page.messageTemplates.labels.context')}
        data-testid={testIds.settings.messageTemplates('context')}
        isRequired
      />
      <Show when={props.templateType === 'email'}>
        <FormField
          name="subject"
          type="suggestionInput"
          control={props.control}
          label={i18n.t('page.messageTemplates.labels.subject')}
          data-testid={testIds.settings.messageTemplates('subject')}
          ContentEditableComponent={ContentEditableComponent}
          isLoadingOptions={isLoading}
          {...suggestionInputProps}
          isRequired
        />
      </Show>
      <FormField
        name="body"
        type="suggestionInput"
        control={props.control}
        label={i18n.t('page.messageTemplates.labels.message')}
        data-testid={testIds.settings.messageTemplates('message')}
        ContentEditableComponent={ContentEditableComponentMessage}
        isLoadingOptions={isLoading}
        {...suggestionInputProps}
        isRequired
      />
      <Box>
        <Button
          variant="secondary"
          title={i18n.t('page.messageTemplates.labels.previewButton')}
          isDisabled={!props.formApi.formState.isValid}
          data-testid={testIds.settings.messageTemplates('previewButton')}
          onClick={onOpenPreview}
        />
      </Box>
      <Show when={canPatchMessageTemplate}>
        <ButtonGroup align="right">
          <Button
            title={i18n.t('general.actions.discard')}
            onClick={redirectToMessageTemplatesOverview}
            variant="secondary"
            data-testid={testIds.settings.messageTemplates('discard')}
          />
          <FormButton
            control={props.control}
            type="submit"
            isLoading={props.isMutationInProgress}
            title={i18n.t('general.actions.save')}
            data-testid={testIds.settings.messageTemplates('save')}
          />
        </ButtonGroup>
      </Show>
      <MessagePreviewDialog
        isOpen={isOpen}
        templateType={props.templateType}
        formApi={props.formApi}
        onClose={onClose}
      />
    </VStack>
  );
};

import {Card, DataStatus, FieldValues, FormControl} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {Path} from 'react-hook-form';

import {isNotEmpty} from 'ramda';

import {SimilarVehicleResponseBody} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {VehicleRecommendationType} from '../../../types/VehicleRecommendationType';
import {useSimilarVehicles} from '../hooks/useSimilarVehicles';
import {VehiclePreview, VehicleSelectionType} from './VehiclePreview';

type SimilarVehiclesProps<TFieldValues extends FieldValues> = RequiredTestIdProps & {
  control: FormControl<TFieldValues>;
  vinField: {
    name: Path<TFieldValues>;
    label?: string;
  };
  registrationPlateField: {
    name: Path<TFieldValues>;
    label?: string;
  };
  shouldRecommendVehiclesFor?: VehicleRecommendationType;
  onVehicleSelect: (
    vehicle: SimilarVehicleResponseBody,
    selectionType: VehicleSelectionType
  ) => void;
};

export function SimilarVehicles<TFieldValues extends FieldValues = FieldValues>(
  props: SimilarVehiclesProps<TFieldValues>
) {
  const {
    data: similarVehicles,
    isError,
    isFetching,
    isLoading,
  } = useSimilarVehicles({
    control: props.control,
    vinField: props.vinField,
    registrationPlateField: props.registrationPlateField,
  });

  return (
    <Show when={isNotEmpty(similarVehicles) || isFetching || isLoading}>
      <Card
        variant="inlineGrey"
        data-testid={suffixTestId('similarVehicles', props)}
        title={!isFetching ? i18n.t('entity.vehicle.labels.matchingVehicles') : undefined}
      >
        <DataStatus isLoading={isFetching} isError={isError}>
          <VStack spacing={2}>
            {similarVehicles.map((vehicle, index) => (
              <VehiclePreview
                onSelect={(selectionType) => props.onVehicleSelect(vehicle, selectionType)}
                vehicle={vehicle}
                key={vehicle.id}
                shouldRecommendVehiclesFor={props.shouldRecommendVehiclesFor}
                data-testid={suffixTestId(`[${index}]`, props)}
              />
            ))}
          </VStack>
        </DataStatus>
      </Card>
    </Show>
  );
}

import {useEffect, useRef} from 'react';

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>(null);

  /*
  Omitting the dependency array is intentional and necessary for the usePrevious
  hook to work correctly. The purpose of this hook is to provide the previous
  value of its argument. To ensure it always has the latest value, it needs to
  run the effect after every render, so it can update the ref.current value.

  If a dependency array was added to this useEffect, it would only update the
  ref.current value when the value prop changes, not after every render. This
  would not give the correct behavior for a usePrevious hook, which needs to
  keep track of the value from the previous render, regardless of whether it
  changed or not.
   */
  // eslint-disable-next-line no-restricted-syntax
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

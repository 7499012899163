import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {CodeFormValue, codeFormSchema} from './CodeFormSchema';

export interface CodeFormProps extends RequiredTestIdProps {
  defaultValues?: CodeFormValue;
  onSubmit: FormSubmitHandler<CodeFormValue>;
}

const DEFAULT_VALUES: CodeFormValue = {name: ''};

export function CodeForm(props: CodeFormProps) {
  return (
    <Form<CodeFormValue>
      onSubmit={props.onSubmit}
      experimentalZodSchema={codeFormSchema}
      defaultValues={props.defaultValues ?? DEFAULT_VALUES}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            label={i18n.t('entity.codelist.labels.codeName')}
            control={control}
            name="name"
            type="text"
            isRequired
            isAutoFocused
            data-testid={suffixTestId('code-name', props)}
          />
          <ButtonGroup align="right">
            <Button
              variant="secondary"
              onClick={closeCurrentDialog}
              title={i18n.t('general.actions.cancel')}
              data-testid={suffixTestId('cancel', props)}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.save')}
              data-testid={suffixTestId('save', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
} from 'platform/components';
import {Text, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useDeleteSalePresetSettingsV2Mutation, useGetSalePresetSettingsListQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {yupString} from 'shared';

import {openRepublishInfoDialog} from '../../../components/RepublishInfoDialogContent/openRepublishInfoDialog';

interface SalePresetDeleteDialogProps {
  salePresetId: string;
  inAdvertisementCount?: number;
}

export function SalePresetDeleteDialog(props: SalePresetDeleteDialogProps) {
  const {data: salePresetList, isLoading: isSalePresetQueryLoading} =
    useGetSalePresetSettingsListQuery();
  const [deleteSalePreset, {isLoading: isDeleting}] = useDeleteSalePresetSettingsV2Mutation();

  const correctSalePresetOptions = salePresetList?.filter(
    (salePreset) => salePreset.id !== props.salePresetId
  );
  const salePresetOptionsShape = correctSalePresetOptions?.map((salePreset) => ({
    label: salePreset.name,
    value: salePreset.id,
  }));

  // Choice option value cannot be null or empty string, so I used "null" and check it later onSubmit
  const salePresetOptions = [
    {label: i18n.t('entity.salePreset.deleteDialog.none'), value: 'null'},
    ...(salePresetOptionsShape ?? []),
  ];

  const onSubmit = (data: {salePreset: string}) =>
    deleteSalePreset({
      salePresetId: props.salePresetId,
      deleteSalePresetSettingsV2RequestBody: {
        replacementId: data.salePreset === 'null' ? null : data.salePreset,
      },
    })
      .unwrap()
      .then(() => {
        closeCurrentDialog();
        if (data.salePreset !== 'null') {
          openRepublishInfoDialog();
        }
      })
      .catch(handleApiError);

  return (
    <VStack spacing={4}>
      <Text>{i18n.t('entity.salePreset.deleteDialog.description')}</Text>
      <Form<{salePreset: string}>
        onSubmit={onSubmit}
        schema={schema}
        defaultValues={{
          salePreset: salePresetOptions?.[0].value,
        }}
      >
        {(control) => (
          <VStack spacing={4}>
            <FormField
              name="salePreset"
              control={control}
              type="choice"
              options={salePresetOptions}
              label={i18n.t('entity.salePreset.deleteDialog.newSalePreset')}
              isLoading={isSalePresetQueryLoading}
              data-testid="salePreset-deleteDialog-selectSalePreset"
              menuInPortal
            />
            <ButtonGroup align="right" data-testid="salePreset-deleteDialog-buttons">
              <Button
                data-testid="salePreset-deleteDialog-cancel"
                isDisabled={isDeleting}
                variant="secondary"
                onClick={closeCurrentDialog}
                title={i18n.t('general.actions.discard')}
              />
              <FormButton
                data-testid="salePreset-deleteDialog-submit"
                control={control}
                type="submit"
                isLoading={isDeleting}
                isDisabled={isDeleting}
                title={i18n.t('general.labels.delete')}
                variant="danger"
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </VStack>
  );
}

const schema = object().shape({
  salePreset: yupString.required(),
});

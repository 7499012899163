import {captureMessage} from '@sentry/browser';
import {diff} from 'deep-object-diff';
import {nanoid} from 'nanoid';
import {showNotification} from 'platform/components';

import {useCallback, useRef, useState} from 'react';

import {isNil, map, move, not, pipe} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty, notEqual} from 'ramda-adjunct';

import {Nullish, sanitizeObject, useOnMount, useThrottledCallback} from 'shared';

import {SMART_SEARCH_KEY} from '../constants/smartSearchKey';
import {useDataGridContext} from '../context/useDataGridContext';
import {AgGridEvent, ColumnResizedEvent, GridApi} from '../types/AgGridTypes';
import {
  ActivePreset,
  ColumnsSetting,
  FeGridSettings,
  GetDataQueryResponse,
  Order,
  OrderType,
  PostPresetRequest,
  Preset,
  PresetDetail,
  PutPresetRenameRequest,
  PutPresetRequest,
  VirtualPreset,
} from '../types/Api';
import {PresetsPanelRendererProps} from '../types/PresetsPanelRendererType';
import {extractColumnStateProperties} from '../utils/extractColumnStateProperties';
import {getColumnState} from '../utils/getColumnState';
import {getPresetStates} from '../utils/getPresetStates';
import {isPresetColumnsSettingsInvalid} from '../utils/isPresetColumnsSettingsInvalid';
import {isPresetCustom} from '../utils/isPresetCustom';
import {getSearchParamsByGridCode} from '../utils/url/getSearchParamsByGridCode';
import {setSearchParamsByGridCode} from '../utils/url/setSearchParamsByGridCode';
import {useGridApiEventListener} from './useGridApiEventListener';
import {useHttpCalls} from './useHttpCalls';

const filterTechnicalArray = (columnSettings: ColumnsSetting[]): ColumnsSetting[] =>
  columnSettings.filter(
    (columnSetting) =>
      columnSetting.columnKey !== 'smartSearch' && columnSetting.columnKey !== 'eag-col-actions'
  );

export const usePresets = (
  gridApi: GridApi,
  dataGridSettings: FeGridSettings,
  dataQuery: GetDataQueryResponse | Nullish,
  setSettings: (settings: FeGridSettings) => void
): Omit<PresetsPanelRendererProps, 'gridApi'> => {
  const httpCalls = useHttpCalls();
  const {
    gridCode,
    virtualPreset,
    customPresets: initialCustomPresets,
    activePreset: initialActivePreset,
    onActivePresetChange,
    setDataQuery,
  } = useDataGridContext();

  const [activePreset, setActivePreset] = useState<ActivePreset>(() => initialActivePreset);
  const [customPresets, setCustomPresets] = useState<Preset[]>(() => initialCustomPresets);
  const [activePresetDataQuery, setActivePresetDataQuery] = useState<
    GetDataQueryResponse | Nullish
  >(dataQuery);
  const isDataGridLoaded = useRef<boolean>(false);

  // We need to keep last virtual preset state to compare it with current state and update virtual preset only if it was changed
  const lastVirtualPresetState = useRef<VirtualPreset>({
    ...virtualPreset,
    columnsSettings: filterTechnicalArray(virtualPreset.columnsSettings),
  });

  // Indicate that custom preset has unsaved change
  const [isPresetChanged, setIsPresetChanged] = useState<boolean>(false);

  const updatePreset = useCallback(
    async (presetId: string) => {
      const modifiedPreset: PutPresetRequest = {
        gridSettings: dataGridSettings,
        columnsSettings: getPresetStates(gridApi),
        dataQueryId: getSearchParamsByGridCode(gridCode).queryId,
      };

      if (isPresetColumnsSettingsInvalid(modifiedPreset)) {
        captureMessage('updatePreset', (scope) =>
          scope.setLevel('error').setExtras({
            userDescription: 'update custom preset from usePresets - updatePreset',
            state: modifiedPreset,
          })
        );
        return;
      }

      await httpCalls.updatePreset(presetId, modifiedPreset).then(() => {
        const newDataQuery = {
          id: presetId,
          order: getColumnSortFromGridApi(gridApi),
          filters: gridApi.getFilterModel(),
          smartSearch: gridApi.getFilterModel()[SMART_SEARCH_KEY],
        };

        setActivePresetDataQuery(newDataQuery);
        setDataQuery(newDataQuery);
        onActivePresetChange((prevState) => ({...prevState, ...modifiedPreset}));
        setIsPresetChanged(false);
      });

      await httpCalls.getPresets().then((presets) => presets && setCustomPresets(presets));
    },
    [dataGridSettings, gridApi, gridCode, httpCalls, setDataQuery, onActivePresetChange]
  );

  const updateVirtualPreset = (settings: FeGridSettings | null) => {
    const dataQueryId = getSearchParamsByGridCode(gridCode).queryId;

    if (isNilOrEmpty(dataQueryId)) {
      return;
    }

    const modifiedPreset: VirtualPreset = {
      columnsSettings: filterTechnicalArray(getPresetStates(gridApi)),
      gridSettings: settings ?? dataGridSettings,
      dataQueryId,
    };

    const isPresetModified = isNilOrEmpty(lastVirtualPresetState.current)
      ? null
      : diff(lastVirtualPresetState.current, modifiedPreset);

    if (isPresetColumnsSettingsInvalid(modifiedPreset)) {
      captureMessage('updatePreset', (scope) =>
        scope.setLevel('error').setExtras({
          userDescription: 'update virtual preset from usePresets - updateVirtualPreset',
          state: modifiedPreset,
        })
      );
      return;
    }

    if (isNilOrEmpty(isPresetModified)) {
      return;
    }

    return httpCalls.updateVirtualPreset(modifiedPreset).then(() => {
      lastVirtualPresetState.current = modifiedPreset;
    });
  };

  const throttledUpdateVirtualPreset = useThrottledCallback(updateVirtualPreset, 1000);

  const presetChangeHandler = useCallback(
    (event: AgGridEvent) => {
      if (
        isColumnResizeEvent(event) &&
        event.column &&
        event.column.getId() === 'eag-col-actions'
      ) {
        return;
      }

      // DestroyCalled is true on datgrid unmount (route change) and we don't want update virtual preset  in such situation
      // @ts-ignore
      if (event.api.destroyCalled) {
        return;
      }

      if (event.type === 'firstDataRendered') {
        isDataGridLoaded.current = true;
      }

      if (!isPresetCustom(activePreset)) {
        throttledUpdateVirtualPreset(null);
      } else {
        if (isNil(activePresetDataQuery) || not(isDataGridLoaded.current)) {
          return;
        }
        const sanitizedFilters = sanitizeObject(
          isArray(activePresetDataQuery.filters) ? {} : activePresetDataQuery.filters
        );

        const presetOrder = activePresetDataQuery.order;
        const currentOrder = getColumnSortFromGridApi(event.api);

        const didSortingChanged = notEqual(presetOrder, currentOrder);
        const didFiltersChange = notEqual(sanitizedFilters, event.api.getFilterModel());
        const didGridSettingsChange = notEqual(dataGridSettings, activePreset.gridSettings);
        const didColumnsSettingsChange = notEqual(
          extractColumnStateProperties(event.api.getColumnState()),
          extractColumnStateProperties(getColumnState(activePreset, activePresetDataQuery))
        );

        const isPresetLocallyChanged =
          didFiltersChange ||
          didColumnsSettingsChange ||
          didGridSettingsChange ||
          didSortingChanged;

        setIsPresetChanged(isPresetLocallyChanged);
      }
    },
    [virtualPreset, updatePreset, activePreset, activePresetDataQuery, throttledUpdateVirtualPreset]
  );

  const {addListeners, removeListeners} = useGridApiEventListener(
    gridApi,
    [
      'sortChanged',
      'filterChanged',
      'columnVisible',
      'columnPinned',
      'columnResized',
      'columnMoved',
      'columnGroupOpened',
      'firstDataRendered',
      'stateUpdated',
    ],
    presetChangeHandler
  );

  useOnMount(addListeners);

  const activatePreset = async (preset: Preset) => {
    const presetDetail = await httpCalls.getPreset(preset.id);
    const dataQuery = await httpCalls.getDataQuery(preset.dataQueryId);

    if (!presetDetail || !dataQuery) {
      return;
    }

    setSearchParamsByGridCode(gridCode, preset.dataQueryId);
    setActivePreset(presetDetail);
    setActivePresetDataQuery(dataQuery);

    removeListeners();

    const newFilters = {
      ...dataQuery.filters,
      [SMART_SEARCH_KEY]: dataQuery.smartSearch,
    };

    await httpCalls.activatePreset(preset.id).then(() => {
      setDataQuery(dataQuery);
      onActivePresetChange(presetDetail);
      setSearchParamsByGridCode(gridCode, preset.dataQueryId);
      addListeners();
      setIsPresetChanged(false);
      gridApi?.setFilterModel(newFilters);
      gridApi?.applyColumnState({
        defaultState: {sort: null},
        state: dataQuery.order.map((value) => ({
          colId: value.columnKey,
          sort: value.order === 'ASC' ? 'asc' : 'desc',
        })),
      });
      setSettings(presetDetail.gridSettings);
    });
  };

  const deactivatePreset = async () => {
    const newVirtualPreset = await httpCalls.getVirtualPreset();

    if (!newVirtualPreset) {
      return;
    }

    const dataQuery = await httpCalls.getDataQuery(newVirtualPreset.dataQueryId);

    if (!dataQuery) {
      return;
    }

    setSearchParamsByGridCode(gridCode, newVirtualPreset.dataQueryId);
    setActivePreset(newVirtualPreset);
    setActivePresetDataQuery(dataQuery);
    removeListeners();

    const newFilters = {
      ...dataQuery.filters,
      [SMART_SEARCH_KEY]: dataQuery.smartSearch,
    };

    await httpCalls.activatePreset(null).then(() => {
      setDataQuery(dataQuery);
      setActivePreset(newVirtualPreset);
      onActivePresetChange(newVirtualPreset);
      setSearchParamsByGridCode(gridCode, newVirtualPreset.dataQueryId);
      setActivePresetDataQuery(dataQuery);
      addListeners();
      setIsPresetChanged(false);
      gridApi?.setFilterModel(newFilters);
      gridApi?.applyColumnState({
        defaultState: {sort: null},
        state: dataQuery.order.map((value) => ({
          colId: value.columnKey,
          sort: value.order === 'ASC' ? 'asc' : 'desc',
        })),
      });
      setSettings(newVirtualPreset.gridSettings);
    });
  };

  const deletePreset = (presetId: string) => {
    const shouldDeactivatePreset = isPresetCustom(activePreset) && activePreset.id === presetId;
    return httpCalls.deletePreset(presetId).then(() => {
      httpCalls.getPresets().then((response) => {
        if (shouldDeactivatePreset) {
          deactivatePreset();
        }

        if (response) {
          setCustomPresets(response);
        }
      });
    });
  };

  const duplicatePreset = async (presetId: string) => {
    const presetDetail = await httpCalls.getPreset(presetId);

    if (!presetDetail) {
      return;
    }

    const preset: PresetDetail = {
      ...presetDetail,
      id: nanoid(),
      title: `${presetDetail.title} (copy)`,
    };

    return httpCalls.createPreset(preset).then(() => {
      httpCalls.getPresets().then((response) => response && setCustomPresets(response));
    });
  };

  const createPreset = (title: string, description: string) => {
    const columns = getPresetStates(gridApi);
    const dataQueryId = getSearchParamsByGridCode(gridCode).queryId;

    const preset: PostPresetRequest = {
      id: nanoid(),
      title,
      description,
      dataQueryId,
      gridSettings: dataGridSettings,
      columnsSettings: columns,
      isActive: true,
    };

    return httpCalls.createPreset(preset).then((newPreset) => {
      httpCalls.getPresets().then((response) => {
        if (!response) {
          return;
        }

        setCustomPresets(response);
        const createdPreset = response.find((res) => res.id === newPreset?.presetId);

        if (isNil(createdPreset)) {
          showNotification.error('Preset was not found. Unable to activate');
          captureMessage('createPreset', (scope) =>
            scope.setLevel('error').setExtras({
              userDescription: 'create custom preset from usePresets - createPreset',
              state: createdPreset,
            })
          );
          return;
        }

        activatePreset(createdPreset!);
      });
    });
  };

  const renamePreset = (presetId: string, title: string, description: string) => {
    const renamedPreset: PutPresetRenameRequest = {
      title,
      description,
    };

    return httpCalls.updateRenamePreset(presetId, renamedPreset).then(() => {
      httpCalls.getPresets().then((response) => response && setCustomPresets(response));
    });
  };

  const updatePresetOrder = (fromIdx: number, toIdx: number) => {
    const newCustomPresetsOrder = pipe<[Preset[]], string[], string[]>(
      map((preset) => preset.id),
      move(fromIdx)(toIdx)
    )(customPresets);

    return httpCalls.presetOrder(newCustomPresetsOrder).then(() => {
      httpCalls.getPresets().then((response) => response && setCustomPresets(response));
    });
  };

  const resetOverrides = () => {
    setIsPresetChanged(false);
    if (isNil(activePresetDataQuery)) {
      return;
    }

    const newFilters = {
      ...activePresetDataQuery.filters,
      [SMART_SEARCH_KEY]: activePresetDataQuery.smartSearch,
    };
    gridApi.applyColumnState({
      state: getColumnState(activePreset, activePresetDataQuery, gridApi.getColumnState()),
      // Whether column order should be applied
      applyOrder: true,
      // State to apply to columns where state is missing for those columns
      defaultState: {
        sort: null,
        pinned: null,
      },
    });
    setSettings(activePreset.gridSettings);
    gridApi?.setFilterModel(newFilters);
  };

  const rowCountGetter = (dataQueryId: string) =>
    httpCalls
      .getCountByQuery(dataQueryId, !!gridApi.getFilterModel()[SMART_SEARCH_KEY])
      .then((response) => response ?? 0);

  return {
    presets: customPresets,
    activePreset,
    activatePreset,
    isPresetChanged,
    deactivatePreset,
    resetOverrides,
    updatePreset,
    deletePreset,
    duplicatePreset,
    createPreset,
    renamePreset,
    updatePresetPosition: updatePresetOrder,
    rowCountGetter,
  };
};

function isColumnResizeEvent(e: AgGridEvent): e is ColumnResizedEvent {
  return 'finished' in e && e.type === 'columnResized';
}

const getColumnSortFromGridApi = (api: GridApi): Order[] =>
  api
    .getColumnState()
    .filter((column) => isNotNilOrEmpty(column.sort))
    .map((column) => ({columnKey: column.colId, order: column.sort?.toUpperCase() as OrderType}));

import {Label, Search, useAnimatedPopper} from 'platform/components';
import {Box, Clickable, getSize, Integer, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {isNil} from 'ramda';
import {floor, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  AddrDetailApiResponse,
  AddrSuggestionsApiResponse,
  CommonAddressResponseBody,
  useAddrSuggestionsQuery,
  useLazyAddrDetailQuery,
} from '@dms/api';
import i18n from '@dms/i18n';

import {useAddress} from '../../hooks/useAddress';
import {handleApiError} from '../../utils/handleApiError';
import {useHandleKeyDownEvent} from './hooks/useHandleKeydownEvent';

interface AddressSuggestionChoiceProps {
  onSelect: (value: AddrDetailApiResponse) => void;
}

export function AddressSearch(props: AddressSuggestionChoiceProps) {
  const {
    i18n: {language},
  } = useTranslation();
  const {composeAddress} = useAddress();
  const widthRef = useRef<HTMLDivElement>(null);

  const [getAddressDetail] = useLazyAddrDetailQuery();

  const [searchText, setSearchText] = useState<string | null>(null);
  const [isOptSelected, setIsOptSelected] = useState<boolean>(false);

  const {data: suggestions} = useAddrSuggestionsQuery(
    {
      term: searchText,
      country: language,
      language,
    },
    {skip: isNil(searchText)}
  );
  const {Popper, closePopper, openPopper, referenceRef, popperProps, isOpen} = useAnimatedPopper({
    placement: 'bottom-start',
  });

  const suggestionOptions = suggestions && suggestionsToOptions(suggestions);

  const handleSubmitAddress = useCallback(
    (placeId: string) => {
      getAddressDetail({placeId})
        .unwrap()
        .then((addressResponse) => {
          props.onSelect(addressResponse);
          const address: CommonAddressResponseBody = {
            street: addressResponse?.street ?? '',
            descriptiveNumber: addressResponse?.descriptiveNumber ?? null,
            orientationNumber: addressResponse?.orientationNumber ?? null,
            city: addressResponse?.city ?? '',
            zip: addressResponse?.zipCode ?? '',
            addressComplement: null,
            country: addressResponse?.country ?? '',
            prefix: addressResponse?.prefix ?? null,
            district: null,
            state: addressResponse?.state ?? null,
          };
          setSearchText(composeAddress(address));
        })
        .then(() => setIsOptSelected(true))
        .then(closePopper)
        .catch(handleApiError);
    },
    [getAddressDetail, props, composeAddress, closePopper, setSearchText]
  );

  const [focusedOptionIndex] = useHandleKeyDownEvent({
    handleSubmitAddress,
    isOpen,
    suggestions: suggestionOptions,
  });

  useEffect(() => {
    if (isNotNilOrEmpty(searchText) && !isOptSelected) {
      openPopper();
    }
  }, [searchText, openPopper, isOptSelected]);

  return (
    <>
      <Box ref={widthRef}>
        <VStack>
          <Label>{i18n.t('entity.customer.actions.searchAddress')}</Label>
          <div
            ref={referenceRef}
            onClick={openPopper}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          >
            <Search
              value={searchText}
              onFocus={() => {
                openPopper();
                setIsOptSelected(false);
              }}
              onClear={closePopper}
              onChange={setSearchText}
            />
          </div>
        </VStack>
      </Box>

      <Popper
        {...popperProps}
        isOpen={isOpen && isNotNilOrEmpty(suggestionOptions) && isNotNilOrEmpty(searchText)}
      >
        <Box
          paddingTop={2}
          width={(floor((widthRef.current?.clientWidth ?? 0) / 4) as Integer) ?? '100%'}
        >
          <Box
            backgroundColor="general.white"
            borderRadius="small"
            boxShadow="elevation_2"
            padding={2}
          >
            <VStack>
              {suggestionOptions?.map((item, index) => (
                <Clickable
                  key={item.value}
                  onClick={() => {
                    setIsOptSelected(true);
                    handleSubmitAddress(item.value);
                  }}
                >
                  <BoxWithHover isFocused={focusedOptionIndex === index}>
                    <Text size="small">{item.label}</Text>
                  </BoxWithHover>
                </Clickable>
              ))}
            </VStack>
          </Box>
        </Box>
      </Popper>
    </>
  );
}

const BoxWithHover = styled.div<{isFocused: boolean}>`
  padding: ${getSize(2)};
  border-radius: ${({theme}) => theme.radii.xSmall};
  ${({isFocused, theme}) =>
    isFocused ? ` background-color: ${theme.colors.palettes.blue[10][100]};` : ``}

  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.blue[20][100]};
  }
`;

const suggestionsToOptions = (val: AddrSuggestionsApiResponse) =>
  val.map((item) => ({
    value: item?.placeId ?? '',
    label: item?.formattedAddress ?? i18n.t('entity.customer.labels.noAddresses'),
  }));

import {DataStatus} from 'platform/components';

import {useEffect, useState} from 'react';

import {isNil, isNotNil, map, pipe, sort} from 'ramda';

import {TransactionResponseBody, useGetSignableDocumentsQuery} from '@dms/api';

import {createContext} from 'shared';

import {STEPPER_MIN_HEIGHT} from '../constants/STEPPER_MIN_HEIGHT';
import {DocumentItemWithIsSelected} from '../types/DocumentItemWithIsSelected';
import {SignDocumentStepperProps} from '../types/SignDocumentStepperProps';

type DocumentSelectionContextType = {
  selectDocument: (fileId: string) => void;
  deselectDocument: (fileId: string) => void;
  documents: DocumentItemWithIsSelected[];
  signatureDocumentFileIds: string[];
};

export const [Provider, useDocumentSelection] = createContext<DocumentSelectionContextType>();

type DocumentSelectionProps = SignDocumentStepperProps & {
  transaction: TransactionResponseBody | undefined;
  isTransactionError: boolean;
  isLoading: boolean;
  children: React.ReactNode;
};

export const DocumentSelectionProvider = (props: DocumentSelectionProps) => {
  const [documents, setDocuments] = useState<DocumentItemWithIsSelected[]>([]);

  const {
    data: documentsData,
    isLoading,
    isError,
  } = useGetSignableDocumentsQuery(
    {
      contextTarget: props.contextTarget,
      contextId: props.contextId,
    },
    {skip: isNotNil(props.transaction) && !props.isTransactionError}
  );

  const signatureDocumentFileIds =
    props.transaction?.tasks?.map((task) => task.signedDocumentId) ??
    documents.filter((item) => item.isSelected).map((item) => item.fileId);

  const selectDocument = (fileId: string) =>
    setDocuments(
      pipe(
        map((item: DocumentItemWithIsSelected) => ({
          ...item,
          isSelected: item.fileId === fileId ? true : item.isSelected,
        })),
        sortByIsSelected
      )
    );

  const deselectDocument = (fileId: string) =>
    setDocuments(
      pipe(
        map((item: DocumentItemWithIsSelected) => ({
          ...item,
          isSelected: item.fileId === fileId ? false : item.isSelected,
        })),
        sortByIsSelected
      )
    );

  useEffect(() => {
    if (isNil(documentsData)) {
      return;
    }

    const defaultSelectedDocuments = pipe(
      map((item: DocumentItemWithIsSelected) => ({
        ...item,
        isSelected: props.fileId ? props.fileId === item.fileId : true,
      })),
      sortByIsSelected
    )(documentsData);

    setDocuments(defaultSelectedDocuments);
  }, [documentsData, props.fileId]);

  return (
    <Provider
      value={{
        selectDocument,
        deselectDocument,
        documents,
        signatureDocumentFileIds,
      }}
    >
      <DataStatus
        isLoading={isLoading || props.isLoading}
        isError={isError}
        minHeight={STEPPER_MIN_HEIGHT}
      >
        {props.children}
      </DataStatus>
    </Provider>
  );
};

const sortByIsSelected = sort<DocumentItemWithIsSelected>((a, b) => {
  if (a.isSelected === b.isSelected) {
    return 0;
  }

  return a.isSelected ? -1 : 1;
});

import {z} from 'zod';

import i18n from '@dms/i18n';

export const codeFormSchema = z.object({
  name: z
    .string()
    .trim()
    .nonempty({message: i18n.t('errors.field_is_required')})
    .max(255),
});

export type CodeFormValue = z.infer<typeof codeFormSchema>;

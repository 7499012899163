import styled from 'styled-components';
import styledMap from 'styled-map';

import {FC, ReactElement} from 'react';
import {Droppable, Draggable, Direction} from 'react-beautiful-dnd';

type DraggableWrapperProps = {
  horizontal: boolean;
};

const flexDirection = styledMap`
	default: column;
	horizontal: row;
`;

const DraggableWrapper = styled.div<DraggableWrapperProps>`
  display: flex;
  flex-direction: ${flexDirection};
`;

type ItemType = {
  id: string | number;
  component: ReactElement;
};

type DragAndDropProps = {
  droppableId: string | number;
  direction?: Direction;
  items: ItemType[];
};

export const DragAndDrop: FC<DragAndDropProps> = ({
  droppableId,
  items,
  direction = 'vertical',
  ...rest
}) => (
  <div {...rest}>
    <Droppable direction={direction} droppableId={droppableId.toString()}>
      {(provided) => (
        <DraggableWrapper
          horizontal={direction === 'horizontal'}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {items.map((item, i) => (
            <Draggable key={item.id} draggableId={item.id.toString()} index={i}>
              {(providedDrag) => (
                <div
                  ref={providedDrag.innerRef}
                  {...providedDrag.draggableProps}
                  {...providedDrag.dragHandleProps}
                >
                  {item.component}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </DraggableWrapper>
      )}
    </Droppable>
  </div>
);

import {Choice, Switch, useTranslationContext} from 'platform/components';

import {useCallback, useMemo} from 'react';

import {PageSizeSettingsOptions} from '../constants/PageSizeSettingsOptions';
import {FeGridSettings} from '../types/Api';
import {SettingsToolpanelRendererProps} from '../types/SettingsToolpanelRenderer';
import {LineHeight} from '../utils/lineHeightToValue';

const DEFAULT_PAGINATION_SIZE = 25;

export type RowHeightOption = {
  value: LineHeight;
  label: string;
  cyId: string;
};

export function SettingsToolpanelRenderer({
  settings,
  gridApi,
  setSettings,
}: SettingsToolpanelRendererProps) {
  const t = useTranslationContext();

  const updateSettings = useCallback(
    (key: keyof FeGridSettings, value: any) => {
      const newSettings = {...settings, [key]: value};
      gridApi?.dispatchEvent({type: 'stateUpdated'});
      setSettings(newSettings);
    },
    [settings]
  );

  const ValueWrappingOptions = [
    {value: 'TRUNCATE', label: t('page.datagrid.labels.valueWrappingTruncate')},
    {value: 'WRAP', label: t('page.datagrid.labels.valueWrappingWrap')},
  ];

  const rowHeightOptions: RowHeightOption[] = useMemo(
    () => [
      {
        value: 'COMPACT',
        label: t('page.datagrid.labels.rowHeightCompact'),
        cyId: 'selectItem-settings.rowHeightCompact',
      },
      {
        value: 'SHORT',
        label: t('page.datagrid.labels.rowHeightSmall'),
        cyId: 'selectItem-settings.rowHeightSmall',
      },
      {
        value: 'DEFAULT',
        label: t('page.datagrid.labels.rowHeightDefault'),
        cyId: 'selectItem-settings.rowHeightDefault',
      },
      {
        value: 'TALL',
        label: t('page.datagrid.labels.rowHeightTall'),
        cyId: 'selectItem-settings.rowHeighttall',
      },
      {
        value: 'THUMBNAIL',
        label: t('page.datagrid.labels.rowHeightLargeThumbnail'),
        cyId: 'selectItem-settings.largeThumbnail',
      },
    ],
    []
  );

  const onHeaderHeightChange = useCallback(
    (value: string | number | string[] | null) => updateSettings('headerHeight', value),
    [updateSettings]
  );
  const onRowHeightChange = useCallback(
    (value: string | number | string[] | null) => updateSettings('rowHeight', value),
    [updateSettings]
  );
  const onPageSizeChange = useCallback(
    (value: string | number | string[] | null) =>
      updateSettings('itemsPerPage', value ?? DEFAULT_PAGINATION_SIZE),
    [updateSettings]
  );
  const onRowZebraChange = useCallback(
    (value: boolean) => updateSettings('stripedRows', value),
    [updateSettings]
  );
  const onVerticalSeparatorChange = useCallback(
    (value: boolean) => updateSettings('verticalSeparatorsVisible', value),
    [updateSettings]
  );
  const onHorizontalSeparatorChange = useCallback(
    (value: boolean) => updateSettings('horizontalSeparatorsVisible', value),
    [updateSettings]
  );
  const onValieWrappingChange = useCallback(
    (value: string | number | string[] | null) => updateSettings('valueWrapMode', value),
    [updateSettings]
  );
  const onKeyboardNavigationChange = useCallback(
    (value: boolean) => updateSettings('keyboardNavigationEnabled', value),
    [updateSettings]
  );
  const onColumnGroupsInHeaderChange = useCallback(
    (value: boolean) => updateSettings('columnGroupsVisible', value),
    [updateSettings]
  );

  return (
    <>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="ag-panel-content">
        <Choice
          label={t('page.datagrid.labels.headerHeight')}
          options={rowHeightOptions}
          value={settings.headerHeight}
          onChange={onHeaderHeightChange}
          name="headerHeight"
          data-testid="select-settings.headerHeight"
          isNotClearable
        />
      </div>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="ag-panel-content">
        <Choice
          label={t('page.datagrid.labels.rowHeight')}
          options={rowHeightOptions}
          value={settings.rowHeight}
          onChange={onRowHeightChange}
          name="rowHeight"
          data-testid="select-settings.rowHeight"
          isNotClearable
        />
      </div>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="ag-panel-content">
        <Choice
          label={t('page.datagrid.labels.pageSize')}
          onChange={onPageSizeChange}
          options={PageSizeSettingsOptions}
          value={settings.itemsPerPage === null ? DEFAULT_PAGINATION_SIZE : settings.itemsPerPage}
          name="itemsPerPage"
          data-testid="select-settings.pageSize"
          isNotClearable
        />
      </div>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="ag-panel-content">
        <Choice
          label={t('page.datagrid.labels.valueWrapping')}
          onChange={onValieWrappingChange}
          options={ValueWrappingOptions}
          value={settings.valueWrapMode}
          name="itemsPerPage"
          data-testid="select-settings.valueWrapping"
          isNotClearable
        />
      </div>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="ag-panel-content">
        <Switch
          label={t('page.datagrid.labels.rowZebra')}
          value={settings.stripedRows}
          onChange={onRowZebraChange}
          data-testid="checkbox-settings.rowZebra"
        />
      </div>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="ag-panel-content">
        <Switch
          label={t('page.datagrid.labels.rowBorder')}
          value={settings.horizontalSeparatorsVisible}
          onChange={onHorizontalSeparatorChange}
          data-testid="checkbox-settings.rowBorder"
        />
      </div>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="ag-panel-content">
        <Switch
          label={t('page.datagrid.labels.colBorder')}
          value={settings.verticalSeparatorsVisible}
          onChange={onVerticalSeparatorChange}
          data-testid="checkbox-settings.colBorder"
        />
      </div>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="ag-panel-content">
        <Switch
          label={t('page.datagrid.labels.keyboardNav')}
          value={settings.keyboardNavigationEnabled}
          onChange={onKeyboardNavigationChange}
          data-testid="checkbox-settings.keyboardNav"
        />
      </div>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="ag-panel-content">
        <Switch
          label={t('page.datagrid.labels.showColGroups')}
          value={settings.columnGroupsVisible}
          onChange={onColumnGroupsInHeaderChange}
          data-testid="checkbox-settings.showColGroups"
        />
      </div>
    </>
  );
}

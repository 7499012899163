import {motion} from 'framer-motion';
import {Card, DataStatus, Segment, SegmentProps, Separator} from 'platform/components';
import {Box, Heading, HStack, Show, Space, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {Fragment, useState} from 'react';

import {always} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetPurchaseFunnelQuery, useGetSaleFunnelQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {usePermissions} from '@dms/shared';

import {TestIdProps} from 'shared';

import {SALES_FUNNEL_GRAPH_BAR_HEIGHT} from '../consts/salesFunnelGraphBarHeight';
import {SEGMENT_MIN_WIDTH} from '../consts/segmentMinWidth';
import {createOpportunitiesFunnelColumns} from '../utils/createOpportunitiesFunnelColumns';
import {AnimatedFunnelValue} from './AnimatedFunnelValue';

interface OpportunitiesFunnelProps extends TestIdProps {
  branchId: string | undefined;
  isFunnelInterestsEnabled: boolean;
  isFunnelBusinessCasesEnabled: boolean;
}

export function OpportunitiesFunnel(props: OpportunitiesFunnelProps) {
  const [ownerSegment, setOwnerSegment] = useState<OwnerSegment>('total');
  const [typeSegment, setTypeSegment] = useState<TypeSegment>('selling');

  const [canReadInterest] = usePermissions({
    permissionKeys: ['interestRead'],
    scopes: {
      interestRead: {
        // BE should handle participation scope regarding opportunities funnel
        participation: 'SKIP',
        // BE should handle branch scope regarding opportunities funnel
        branchId: 'SKIP',
      },
    },
  });

  const {
    data: purchaseFunnelData,
    isLoading: isPurchaseFunnelLoading,
    isError: isPurchaseFunnelError,
  } = useGetPurchaseFunnelQuery(
    {
      branchId: props.branchId,
      onlyMine: ownerSegment === 'my',
    },
    {skip: typeSegment === 'selling', refetchOnMountOrArgChange: true}
  );

  const {
    data: saleFunnelData,
    isLoading: isSaleFunnelLoading,
    isError: isSaleFunnelError,
  } = useGetSaleFunnelQuery(
    {
      branchId: props.branchId,
      onlyMine: ownerSegment === 'my',
    },
    {skip: typeSegment === 'buying', refetchOnMountOrArgChange: true}
  );

  const isLoading = match(typeSegment)
    .with('selling', always(isSaleFunnelLoading))
    .with('buying', always(isPurchaseFunnelLoading))
    .otherwise(always(false));

  const isError = match(typeSegment)
    .with('selling', always(isSaleFunnelError))
    .with('buying', always(isPurchaseFunnelError))
    .otherwise(always(false));

  const columns = match(typeSegment)
    .with(
      'selling',
      always(
        createOpportunitiesFunnelColumns(
          saleFunnelData,
          props.isFunnelInterestsEnabled,
          props.isFunnelBusinessCasesEnabled,
          canReadInterest
        )
      )
    )
    .with(
      'buying',
      always(
        createOpportunitiesFunnelColumns(
          purchaseFunnelData,
          props.isFunnelInterestsEnabled,
          props.isFunnelBusinessCasesEnabled,
          canReadInterest
        )
      )
    )
    .otherwise(always([]));

  return (
    <Card>
      <VStack spacing={4}>
        <HStack>
          <Heading size={4}>{i18n.t('page.homepage.labels.currentOpportunities')}</Heading>
          <Space fillAvailable />
          <HStack spacing={2}>
            <Segment
              value={ownerSegment}
              options={ownerSegments}
              onChange={(segment) => setOwnerSegment(segment)}
              minWidth={SEGMENT_MIN_WIDTH}
            />
            <Segment
              value={typeSegment}
              options={typeSegments}
              onChange={(segment) => setTypeSegment(segment)}
              minWidth={SEGMENT_MIN_WIDTH}
            />
          </HStack>
        </HStack>

        <DataStatus
          isError={isError}
          isEmpty={isNilOrEmpty(columns)}
          isLoading={isLoading}
          minHeight={56}
        >
          <HStack>
            {columns.map<React.ReactNode>((column, index) => {
              const isLast = index !== columns.length - 1;
              return (
                <Fragment key={column.id}>
                  <VStack basis="100%" align="stretch" spacing={4}>
                    <VStack spacing={4} justify="space-between" grow={1}>
                      <Text color="secondary" size="xSmall">
                        {column.title}
                      </Text>
                      <AnimatedFunnelValue value={column.value} />
                    </VStack>
                    <HStack height={SALES_FUNNEL_GRAPH_BAR_HEIGHT} grow={0} align="flex-end">
                      <motion.div
                        style={{width: '100%'}}
                        animate={{height: column.height}}
                        transition={{type: 'tween'}}
                      >
                        <Box flex={1} height="100%" backgroundColor={column.color} />
                      </motion.div>
                    </HStack>
                  </VStack>
                  <Show when={isLast}>
                    <Separator spacing={4} orientation="vertical" />
                  </Show>
                </Fragment>
              );
            })}
          </HStack>
        </DataStatus>
      </VStack>
    </Card>
  );
}

type OwnerSegment = 'total' | 'my';
type TypeSegment = 'selling' | 'buying';

const ownerSegments: NonNullable<SegmentProps<OwnerSegment>['options']> = [
  {
    label: i18n.t('page.homepage.labels.total'),
    value: 'total',
  },
  {
    label: i18n.t('page.homepage.labels.my'),
    value: 'my',
  },
];

const typeSegments: NonNullable<SegmentProps<TypeSegment>['options']> = [
  {
    label: i18n.t('page.homepage.labels.selling'),
    value: 'selling',
  },
  {
    label: i18n.t('page.homepage.labels.buying'),
    value: 'buying',
  },
];

import {Image, Text, VStack} from 'platform/foundation';

import {ReactNode} from 'react';

import {Nullish} from 'shared';

interface SourcingDataStatusProps {
  iconUrl: string;
  text: string;
  textSecondLine?: string;
  noResultsContent?: ReactNode;
  children?: ReactNode;
  isEmpty: boolean | Nullish;
}

export function SourcingDataStatus(props: SourcingDataStatusProps) {
  if (props.isEmpty) {
    return (
      <VStack height="100%" align="center" justify="center" spacing={2}>
        <Image src={props.iconUrl} width="100%" height={38} />
        <Text color="tertiary" size="small">
          {props.text}
        </Text>
        <Text color="tertiary" size="small">
          {props.textSecondLine}
        </Text>
        {props.noResultsContent}
      </VStack>
    );
  }

  return props.children;
}

import {DataStatus, showNotification} from 'platform/components';

import {useNavigate, useParams} from 'react-router-dom';

import {useGetChecklistDefinitionQuery, useUpdateChecklistDefinitionMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {SettingsTemplate} from '../../../../components/SettingsTemplate/SettingsTemplate';
import {ChecklistForm} from '../../components/ChecklistForm';

export function Detail() {
  const {id: checklistId} = useParams();
  const navigate = useNavigate();

  const checklistDetailQuery = useGetChecklistDefinitionQuery(
    {definitionId: checklistId || ''},
    {skip: !checklistId}
  );

  const [updateChecklistDefinition, updateChecklistDefinitionQuery] =
    useUpdateChecklistDefinitionMutation();

  return (
    <DataStatus isLoading={checklistDetailQuery.isLoading} isError={checklistDetailQuery.isError}>
      <SettingsTemplate
        header={{
          title: checklistDetailQuery.data?.name || '',
          breadcrumbs: [
            {
              label: i18n.t('page.generalSettings.labels.title'),
              isCurrentPage: true,
            },
            {
              label: i18n.t('page.settings.generalChecklist.title'),
              href: settingsRoutes.generalChecklist,
            },
          ],
        }}
        data-testid={testIds.settings.generalChecklistDetail()}
      >
        <ChecklistForm
          defaultValues={checklistDetailQuery.data}
          onSubmit={(formData) => {
            updateChecklistDefinition({
              definitionId: checklistId || '',
              body: formData,
            })
              .unwrap()
              .then(() => {
                navigate(settingsRoutes.generalChecklist);
                showNotification.success();
              })
              .catch(handleApiError);
          }}
          isLoading={updateChecklistDefinitionQuery.isLoading}
          isEditing
        />
      </SettingsTemplate>
    </DataStatus>
  );
}

import {GridApi} from 'ag-grid-community';
import {IconButton, Tooltip, useDialog, useTranslationContext} from 'platform/components';

import {RequiredTestIdProps} from 'shared';

import {ExportDialog} from './ExportDialog';

type ExportButtonProps = RequiredTestIdProps & {
  gridApi?: GridApi;
};

export const ExportButton = (props: ExportButtonProps) => {
  const t = useTranslationContext();
  const [isOpen, onOpenExportDialog, onClose] = useDialog();

  return (
    <>
      <Tooltip label={t('page.datagrid.button.export')}>
        <IconButton
          icon="file/download"
          onClick={onOpenExportDialog}
          aria-label={t('page.datagrid.button.export')}
          data-testid={props['data-testid']}
        />
      </Tooltip>
      <ExportDialog
        isOpen={isOpen}
        onClose={onClose}
        gridApi={props.gridApi}
        data-testid={props['data-testid']}
      />
    </>
  );
};

import {z} from 'zod';

import {AvailableDiscountSchema, DiscountSourceTypeSchema} from '@dms/api';

export const LabourMechanicSchema = z.object({
  id: z.string().nullish(),
  amount: z.number().nullish(),
  ratio: z.number().nullish(),
  costCenterId: z.string().nullish(),
});

export type LabourMechanic = z.infer<typeof LabourMechanicSchema>;

export const EditLabourItemFormSchema = z.object({
  name: z.string(),
  number: z.string(),
  workType: z.string(),
  assignMechanics: z.array(LabourMechanicSchema),
  quantity: z.number().positive(),
  purchasePricePerUnit: z.number().nullish(),
  sellingPricePerUnit: z.number(),
  vatType: z.string(),
  vatFreeCode: z.string().nullish(),
  isUnitPriceWithVat: z.boolean().default(false),
  isCustomPrice: z.boolean().default(false),
  discount: z.object({
    isDoNotApplyDiscount: z.boolean().default(false),
    isDiscountEnable: z.boolean().default(false),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: z.number().nullish(),
    availableDiscounts: z.array(
      AvailableDiscountSchema.extend({discountRate: z.number().min(0).max(100).nullish()})
    ),
  }),
  // Used only locally to be able to control the getCurrentPrice action recalculations
  shouldGetCurrentPrice: z.boolean().default(false),
});

export type EditLabourItemForm = z.infer<typeof EditLabourItemFormSchema>;

import {BaseFlagProps} from 'platform/components';

import {isNil} from 'ramda';

import {DocumentSourceResponseBody} from '@dms/api';
import {i18n} from '@dms/i18n';

import {buildArray} from 'shared';

export function getDocumentSourceFlags(
  documentSource: DocumentSourceResponseBody | undefined
): BaseFlagProps[] {
  if (!documentSource) {
    return [];
  }

  return buildArray<BaseFlagProps>()
    .when(documentSource?.documentSource === 'internal', {
      colorScheme: 'green',
      label: i18n.t('entity.accounting.labels.documentSource.internal'),
    })
    .when(documentSource?.documentSource === 'external', {
      colorScheme: 'yellow',
      label: i18n.t('entity.accounting.labels.documentSource.external'),
    })
    .whenNot(isNil(documentSource?.serviceSource), {
      colorScheme: 'neutral',
      label: i18n.t(`entity.accounting.labels.serviceSource.${documentSource.serviceSource!}`),
    })
    .whenNot(isNil(documentSource?.moduleSource), {
      colorScheme: 'neutral',
      label: i18n.t(`entity.accounting.labels.moduleSource.${documentSource.moduleSource!}`),
    });
}

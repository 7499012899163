import {v4 as uuid} from 'uuid';

import {
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import {Nullish, TestIdProps} from 'shared';

import {noop} from '../../utils/someTeasUtils';
import {DroppableDropContext} from './droppableDropContext';
import {
  DragAndDropContextType,
  DragAndDropProviderProps,
  DraggableDataWithContextId,
  HoverPositions,
} from './types';

type DnDStoreType = {
  draggedItem: {
    dragId?: string | null;
    dropId?: string;
  };
  setDraggedItem: Dispatch<
    SetStateAction<{
      dragId?: string | null;
      dropId?: string;
    }>
  >;
  activeDroppable?: string;
  setActiveDroppable: (dropId: string | null) => void;
  isHovered: boolean;
  setHoveredItem: (draggableId?: string, direction?: HoverPositions) => void;
};

// State for currently dragged item
function DnDStore<TDnDData>(
  activeDroppableRef: MutableRefObject<string | Nullish>,
  onDragStart: DragAndDropProviderProps['onDragStart'],
  setHoveredItem: DragAndDropContextType<TDnDData>['setHoveredItem']
): DnDStoreType {
  const isHoveredRef = useRef(false);
  const [draggedItem, setDraggedItem] = useState<{dragId?: string | null; dropId?: string}>({});
  const [activeDroppable, setActiveDroppable] = useState<string>();
  const [isHovered, setIsHovered] = useState(false);

  const handleSetActive = (dropId: string | null) => {
    if (dropId === null) {
      return;
    }
    setActiveDroppable(dropId);
    activeDroppableRef.current = dropId;
  };

  const handleHoveredItem: DragAndDropContextType<TDnDData>['setHoveredItem'] = (
    dragId,
    position
  ) => {
    setHoveredItem(dragId, position);

    if (dragId) {
      isHoveredRef.current = true;
      setIsHovered(true);
    } else {
      isHoveredRef.current = false;
      setTimeout(() => {
        if (!isHoveredRef.current) {
          setIsHovered(false);
        }
      }, 75);
    }
  };

  useEffect(() => {
    if (Object.getOwnPropertyNames(draggedItem).length >= 1) {
      onDragStart?.(draggedItem?.dragId || '');
    }
  }, [draggedItem]);

  return {
    draggedItem,
    setDraggedItem,
    activeDroppable,
    setActiveDroppable: handleSetActive,
    isHovered,
    setHoveredItem: handleHoveredItem,
  };
}

export const DragAndDropProvider = <
  TDnDData extends Record<string, unknown> = Record<string, unknown>,
>(
  props: PropsWithChildren<DragAndDropProviderProps<TDnDData>> & TestIdProps
): ReactElement => {
  const {
    children,
    selectedItems,
    multipleSelectionComponent,
    onDragStart = noop,
    onDragEnd = noop,
    onDragFail = noop,
    ...rest
  } = props;

  /**
   * id for differentiating providers, each provider has different one
   * used for searching draggables in dom
   */
  const providerId = useRef<string>(uuid());
  /**
   * When draggable is created, it updates this object with its data to keep track of existing draggables
   */
  const registeredDraggables = useRef<{
    [key: string]: DraggableDataWithContextId<Record<string, unknown>> | null;
  }>({});
  /**
   * Keeps track of last hovered item when drag is in progress
   */
  const lastHoveredItem = useRef<[string, HoverPositions] | undefined>(undefined);
  const activeDroppable = useRef<string>(null);
  const [globalDragging, setGlobalDragging] = useState(false);

  const handleDragStart = () => {
    setGlobalDragging(true);
  };

  const onRegister: DragAndDropContextType<Record<string, unknown>>['register'] = (
    draggable,
    data,
    droppableId
  ) => {
    registeredDraggables.current[draggable] = {
      data: {...data, id: draggable},
      droppableId,
    };
  };
  const onUnregister: DragAndDropContextType<TDnDData>['unregister'] = (draggable) => {
    registeredDraggables.current[draggable] = null;
  };

  const handleHoveredItem: DragAndDropContextType<TDnDData>['setHoveredItem'] = (
    draggable,
    direction
  ) => {
    if (!draggable || !direction) {
      lastHoveredItem.current = undefined;
      return;
    }

    lastHoveredItem.current = [draggable, direction];
  };

  const handleDragEnd: DragAndDropContextType<TDnDData>['onDragEnd'] = (draggable) => {
    if (!lastHoveredItem.current && !activeDroppable.current) {
      onDragFail();
      return;
    }

    setGlobalDragging(false);

    const _selectedItems = [...selectedItems, draggable]
      .map((drag) => ({id: drag, data: registeredDraggables.current[drag]}))
      .filter((i) => !!i);

    const fullElList = [...document.querySelectorAll(`[data-draggable="${providerId.current}"]`)];

    const sortedItems: Array<DraggableDataWithContextId<Record<string, unknown>>> = [];

    fullElList.forEach((el) => {
      const found = _selectedItems.find((i) => i.id === el.id);

      if (found && found.data) {
        sortedItems.push(found.data);
      }
    });

    if (!lastHoveredItem.current) {
      onDragEnd(sortedItems, null, {droppableId: activeDroppable.current, data: null});
      return;
    }

    // with lastHoveredItem
    const [id, direction] = lastHoveredItem.current;
    const currentDraggable = registeredDraggables.current[id];

    const elList = [
      ...document.querySelectorAll(
        `[data-draggable="${providerId.current}"]${
          currentDraggable?.droppableId
            ? `[data-draggable-context="${currentDraggable.droppableId}"]`
            : ''
        } `
      ),
    ];

    let dropIndex = elList.findIndex((el) => el.id === id);

    if (direction === HoverPositions.Right) {
      dropIndex++;
    }

    let dropDraggable = registeredDraggables.current[elList[dropIndex]?.id];

    if (!dropDraggable) {
      dropDraggable = registeredDraggables.current[elList[0]?.id];
    }

    onDragEnd(sortedItems, dropIndex, dropDraggable, direction);
    lastHoveredItem.current = undefined;
  };

  const dndStore = DnDStore<TDnDData>(activeDroppable, onDragStart, handleHoveredItem);

  return (
    <DroppableDropContext.Provider
      value={{
        globalDragging,
        providerId: providerId.current,
        selectedItems,
        multipleSelectionComponent,
        onDragEnd: handleDragEnd,
        onDragStart: handleDragStart,
        // NOTE Here type cast is used because we provide context type with data
        // type on creation moment. Now, context already exists
        // with type Record<string, unknown> and we can't change type of the context
        // It could be changed, if Context would be created simultaneously in place
        // where Provider is used
        register: onRegister as (
          draggableId: string,
          data?: Record<string, unknown>,
          droppableId?: string
        ) => void,
        unregister: onUnregister,
        lastHoveredItem,
        ...dndStore,
        ...{'data-testid': rest['data-testid']},
      }}
    >
      {children}
    </DroppableDropContext.Provider>
  );
};

import {isNil} from 'ramda';

import {
  EntityResourceIds,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
  useGetVehicleQuery,
} from '@dms/api';

import {Nullish} from 'shared';

import {usePermissions} from './usePermissions/usePermissions';

interface PricePermissionsProps {
  businessCaseRecordId: string | Nullish;
  vehicleRecordId: string | Nullish;
}

export const usePricePermissions = (props: PricePermissionsProps) => {
  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      recordId: props?.businessCaseRecordId ?? '',
      resourceId: EntityResourceIds.businessCase,
    },
    {
      skip: isNil(props?.businessCaseRecordId),
    }
  );

  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: props?.businessCaseRecordId ?? ''},
    {skip: isNil(props?.businessCaseRecordId)}
  );

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      recordId: props?.vehicleRecordId ?? '',
      resourceId: EntityResourceIds.vehicle,
    },
    {
      skip: isNil(props?.vehicleRecordId),
    }
  );

  const {data: vehicle} = useGetVehicleQuery(
    {
      vehicleId: props?.vehicleRecordId ?? '',
    },
    {skip: isNil(props?.vehicleRecordId)}
  );

  const [
    canReadVehicleProfit,
    canReadVehicleSellingPrice,
    canReadVehicleRealSellingPrice,
    canReadVehicleCosts,
    canReadVehicleEarnings,
    canReadVehicleTotalGrossMargin,
    canReadVehiclePurchasePrice,
    canEditVehicleSalePrice,
    canEditVehiclePurchasePrice,
    canReadBusinessCaseSaleVehiclePurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice,
    canReadBusinessCaseSaleProfit,
    canReadBusinessCaseSaleTotalPrice,
    canEditBusinessCasePurchaseBrokerageFees,
    canReadBusinessCasePurchaseVehiclePurchasePrice,
    canReadBusinessCasePurchaseVehicleSellingPrice,
    canReadBusinessCasePurchaseExpectedProfit,
    canReadBusinessCasePurchaseTotalPrice,
    canReadBusinessCaseBrokerageFees,
    canEditBusinessCaseMaxPurchasePrice,
  ] = usePermissions({
    permissionKeys: [
      // vehicle
      'vehicleProfit',
      'vehicleSellingPrice',
      'vehicleRealSellingPrice',
      'vehicleReadCosts',
      'vehicleReadEarnings',
      'vehicleReadTotalGrossMargin',
      'vehiclePurchasePrice',
      'vehicleEditSalePrice',
      'vehicleEditPurchasePrice',
      // businessCaseSale
      'businessCaseSaleVehiclePurchasePrice',
      'businessCaseSaleVehicleSalePrice',
      'businessCaseSaleProfit',
      'businessCaseSaleTotalPrice',
      'editBusinessCasePurchaseBrokerageFees',
      // businessCasePurchase
      'businessCasePurchaseVehiclePurchasePrice',
      'businessCasePurchaseVehicleSellingPrice',
      'businessCasePurchaseExpectedProfit',
      'businessCasePurchaseTotalPrice',
      'businessCaseViewBrokerageFees',
      'businessCaseEditMaxPurchasePrice',
    ],
    scopes: {
      businessCaseEditMaxPurchasePrice: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCaseViewBrokerageFees: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCaseSaleVehiclePurchasePrice: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCaseSaleVehicleSalePrice: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCaseSaleProfit: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCaseSaleTotalPrice: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      editBusinessCasePurchaseBrokerageFees: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCasePurchaseVehiclePurchasePrice: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCasePurchaseVehicleSellingPrice: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCasePurchaseExpectedProfit: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCasePurchaseTotalPrice: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      vehicleReadCosts: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleReadEarnings: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleReadTotalGrossMargin: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleProfit: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleSellingPrice: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleRealSellingPrice: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehiclePurchasePrice: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleEditSalePrice: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleEditPurchasePrice: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  const fieldAccessMap = {
    canReadVehicleProfit,
    canReadVehicleSellingPrice,
    canReadVehicleRealSellingPrice,
    canReadVehicleCosts,
    canReadVehicleEarnings,
    canReadVehicleTotalGrossMargin,
    canReadVehiclePurchasePrice,
    canReadBusinessCaseSaleVehiclePurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice,
    canReadBusinessCaseSaleProfit,
    canReadBusinessCaseSaleTotalPrice,
    canReadBusinessCasePurchaseVehiclePurchasePrice,
    canReadBusinessCasePurchaseVehicleSellingPrice,
    canReadBusinessCasePurchaseExpectedProfit,
    canReadBusinessCasePurchaseTotalPrice,
    canReadBusinessCaseBrokerageFees,
  } as const;

  const vehiclePriceFields = [
    canReadVehicleProfit,
    canReadVehicleSellingPrice,
    canReadVehicleRealSellingPrice,
    canReadVehicleCosts,
    canReadVehicleEarnings,
    canReadVehicleTotalGrossMargin,
    canReadVehiclePurchasePrice,
  ];

  const businessCaseSalePriceFields = [
    canReadBusinessCaseSaleVehiclePurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice,
    canReadBusinessCaseSaleProfit,
    canReadBusinessCaseSaleTotalPrice,
  ];

  const businessCasePurchasePriceFields = [
    canReadBusinessCasePurchaseVehiclePurchasePrice,
    canReadBusinessCasePurchaseVehicleSellingPrice,
    canReadBusinessCasePurchaseExpectedProfit,
    canReadBusinessCasePurchaseTotalPrice,
  ];

  return {
    canViewAnyOfVehiclePriceFields: vehiclePriceFields.some(Boolean),
    canViewAnyOfSalePriceFields:
      vehiclePriceFields.some(Boolean) || businessCaseSalePriceFields.some(Boolean),
    canViewAnyOfPurchasePriceFields:
      vehiclePriceFields.some(Boolean) || businessCasePurchasePriceFields.some(Boolean),
    ...fieldAccessMap,
    canEditVehicleSalePrice,
    canEditVehiclePurchasePrice,
    canEditBusinessCasePurchaseBrokerageFees,
    canEditBusinessCaseMaxPurchasePrice,
  };
};

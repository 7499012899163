import {showNotification} from 'platform/components';

import {FC, PropsWithChildren, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {carAuditApi} from '@dms/api';
import {settingsRoutes} from '@dms/routes';
import {
  Form,
  updateAuditStructureSettings,
  selectStructureSetting,
  selectVehicleFeatures,
  BasicCategoryOfStructure,
  CreateTypeOfControlSettingsRequestBody,
  useApiDispatch,
  AuditCategoryUniqueKey,
} from '@dms/teas';

import {AuditStructureSettingsForm} from '../types';
import {getFormSchema, getRequestBodyItemsFormValues} from '../utils/legacy_utils';
import {getEquipmentIdsFromCategories, getEquipmentUniqueKeyToIdDictionary} from '../utils/utils';

interface AuditSettingsFormProps {
  type?: CreateTypeOfControlSettingsRequestBody['inspectionType'];
  isCreate?: boolean;
  structureVersion: number;
  categories: BasicCategoryOfStructure[];
}

export const AuditSettingsForm: FC<PropsWithChildren<AuditSettingsFormProps>> = ({
  type,
  isCreate,
  structureVersion,
  children,
  categories,
}) => {
  const structureSettingData = useSelector(selectStructureSetting);
  const apiDispatch = useApiDispatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const features = useSelector(selectVehicleFeatures('VEHICLETYPE_PASSENGER_CAR'));

  const equipmentCategory = useMemo(
    () => categories?.find((cat) => cat.uniqueKey === AuditCategoryUniqueKey.EQUIPMENT),
    [categories]
  );

  const uniqueKeyToIdDictionary = useMemo(
    () => getEquipmentUniqueKeyToIdDictionary(equipmentCategory),
    [equipmentCategory]
  );

  const mainEquipmentIds = useMemo(
    () => features.map((feature) => uniqueKeyToIdDictionary[feature.value]),
    [features, uniqueKeyToIdDictionary]
  );

  const isVehicleCondition =
    type === CreateTypeOfControlSettingsRequestBody.inspectionType.VEHICLE_CONDITION;

  const equipmentIds = useMemo(() => getEquipmentIdsFromCategories(categories), [categories]);

  const handleSubmit = async (values: AuditStructureSettingsForm) => {
    await apiDispatch(updateAuditStructureSettings.action, {
      requestBody: {
        name: values.name,
        key: isCreate ? null : (structureSettingData?.key ?? null),
        showEmptyFields: !values.showEmptyFields,
        inspectionType: isVehicleCondition
          ? CreateTypeOfControlSettingsRequestBody.inspectionType.VEHICLE_CONDITION
          : values.validationInspection
            ? CreateTypeOfControlSettingsRequestBody.inspectionType.VALIDATION_INSPECTION
            : CreateTypeOfControlSettingsRequestBody.inspectionType.NON_VALIDATION_INSPECTION,
        validation: values.validation
          ? CreateTypeOfControlSettingsRequestBody.validation.VALIDATE
          : CreateTypeOfControlSettingsRequestBody.validation.NON_VALIDATE,
        items: getRequestBodyItemsFormValues(
          values,
          uniqueKeyToIdDictionary,
          features,
          equipmentIds
        ),
        version: structureVersion,
      },
    }).then(() => {
      dispatch(carAuditApi.util.invalidateTags(['InspectionTemplates']));

      navigate(
        isVehicleCondition ? settingsRoutes.vehicleCondition : settingsRoutes.vehicleInspections
      );
      showNotification.success();
    });
  };

  const initialValues = useMemo((): AuditStructureSettingsForm => {
    const initialValues: AuditStructureSettingsForm = {
      categories: {},
      paramDefinitions: {},
      validationInspection: type === 'validationInspection',
      name: '',
      showEmptyFields: !structureSettingData?.showEmptyFields,
      validation: false,
      equipmentValues: [],
    };

    const isEquipmentVisible = structureSettingData?.items.find(
      (data) => data.categoryId === equipmentCategory?.id
    )?.visible;

    if (isCreate) {
      return initialValues;
    }

    structureSettingData?.items?.forEach((item) => {
      const {categoryId, paramDefinitionId, visible, mandatory} = item;

      if (categoryId) {
        initialValues.categories[categoryId] = visible;
      }

      if (paramDefinitionId) {
        if (equipmentIds.includes(paramDefinitionId)) {
          if (visible && mainEquipmentIds.includes(paramDefinitionId)) {
            initialValues.equipmentValues?.push(paramDefinitionId);
          }
        } else {
          initialValues.paramDefinitions[paramDefinitionId] = {
            visible,
            mandatory,
          };
        }
      }
    });

    if (isNilOrEmpty(initialValues.equipmentValues) && isEquipmentVisible) {
      initialValues.equipmentValues = mainEquipmentIds;
    }
    const moreEquipment = equipmentCategory?.childCategories?.[0]?.childCategories?.find(
      (cat) => cat.uniqueKey === AuditCategoryUniqueKey.MORE_EQUIPMENT
    );

    // needed so user could add more equipment in inspection
    moreEquipment && initialValues.equipmentValues?.push(moreEquipment.id);

    return {
      ...initialValues,
      name: structureSettingData?.name ?? '',
      validation:
        structureSettingData?.validation ===
        CreateTypeOfControlSettingsRequestBody.validation.VALIDATE,
    };
  }, [structureSettingData, equipmentIds, isCreate, equipmentCategory, type, mainEquipmentIds]);

  return (
    <Form<AuditStructureSettingsForm>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isFormSaverDisabled
      schema={isVehicleCondition ? undefined : getFormSchema()}
      mutators={{
        setFieldValue: ([field, value], state, {changeValue}) => {
          changeValue(state, field, () => value);
        },
      }}
      render={() => <>{children}</>}
    />
  );
};

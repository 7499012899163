import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {isArray, noop} from 'ramda-adjunct';

import {
  useDeleteCustomerContractMutation,
  usePostCustomerContractSetActiveMutation,
  usePostCustomerContractSetInActiveMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, refreshDatagrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ConfirmCloneDialog} from './components/ConfirmCloneDialog';

export function CustomerContracts() {
  const navigate = useNavigate();
  const [cloneId, setCloneId] = useState<string | null>(null);

  const [deleteCustomerContract] = useDeleteCustomerContractMutation();
  const [customerContractSetActive] = usePostCustomerContractSetActiveMutation();
  const [customerContractSetInActive] = usePostCustomerContractSetInActiveMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('edit', 'redirectDetail', () =>
        navigate(composePath(settingsRoutes.customerContractsDetail, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteCustomerContract({customerContractId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('setAsActive', () =>
        customerContractSetActive({customerContractId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('setAsInactive', () =>
        customerContractSetInActive({customerContractId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('clone', () => setCloneId(id))
      .otherwise(noop);
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.customerContract.labels.customerContracts'),
        actions: [
          {
            title: i18n.t('entity.customerContract.actions.newCustomerContract'),
            type: 'button',
            onClick: () => navigate(settingsRoutes.customerContractsCreate),
          },
        ],
      }}
      data-testid={testIds.settings.customerContracts('template')}
    >
      <ConfirmCloneDialog
        id={cloneId}
        onClose={() => setCloneId(null)}
        onCloseComplete={() => {
          refreshDatagrid('customer-contract');
          setCloneId(null);
        }}
        data-testid={testIds.settings.customerContracts('confirmCloneDialog')}
      />
      <DataGrid gridCode="customer-contract" actionCallback={actionCallback} />
    </SettingsTemplate>
  );
}

import {Alert, Button, openDeleteDialog, showNotification, Tooltip} from 'platform/components';
import {Clickable, Heading, HStack, Icon, Show} from 'platform/foundation';
import {css} from 'styled-components';

import {
  ChangeEvent,
  DragEvent,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {indexBy, isNil, last} from 'ramda';
import {isNotNil, isNotNilOrEmpty, isPositive} from 'ramda-adjunct';

import {
  EntityResourceIds,
  PartialVehiclePhotosRoutes,
  useDeleteVehicleAlbumMutation,
  useGetParticipationQuery,
  useGetVehicleQuery,
  VehicleAlbums,
  vehicleApi,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';
import {handleApiError, NoPermissionTooltip, useBatchDownload, usePermissions} from '@dms/shared';

import {suffixTestId} from 'shared';

import {AppDispatch} from '../../hooks/useThunkDispatch';
import {loadCarDetailsVehicleDetailRequest} from '../../store/carDetails/actions';
import {noop} from '../../utils/someTeasUtils';
import {Droppable as DroppableComponent} from '../CustomDragAndDrop/Droppable';
import {HoverPositions} from '../CustomDragAndDrop/types';
import {DownloadDialog} from '../DownloadDialog/DownloadDialog';
import {ImageRenderer} from './ImageRenderer';
import {
  AddTile,
  CardStyled,
  ContentWrapper,
  Droppable,
  EmptyWrapper,
  ImagesWrapper,
  MuiGridStyled,
  SelectionBorder,
  TooltipNote,
  UploadButton,
  UploadSizeWrapper,
} from './styles';
import {ImagesCardProps, ImageType, UploadingFileType, UploadingImagesRefState} from './types';
import {UploadingImage} from './UploadingImage';

const ALLOWED_MIME_TYPES_TO_UPLOAD = ['image/jpeg', 'image/png'];
const MAXMIMUM_UPLOAD_SIZE_IN_MB = 90;

const renderTooltipFactory =
  (image: ImageType) =>
  (children: ReactElement): ReactElement => (
    <Tooltip
      label={
        <div data-testid="image-tooltip">
          <div>{image.name}</div>
          <TooltipNote>{image.note ? <div>{image.note}</div> : null}</TooltipNote>
        </div>
      }
      placement="top"
    >
      {children}
    </Tooltip>
  );

export const ImagesCard: FC<ImagesCardProps> = ({
  droppableId,
  disableDrag,
  cardProps,
  images,
  globalListener,
  onUpload = noop,
  infoText,
  withTooltip = false,
  selectionEnabled,
  onImageClick,
  onSelectionToggle = noop,
  onImageSelect = noop,
  contextMenuOptions = noop,
  onAllImagesUploaded = noop,
  enableGallery = false,
  onGalleryToggle = noop,
  selectedPhotos = [],
  promoPhotos = false,
  onAddPromoPhotos,
  onSetDefaultOrder,
  onDelete,
  differentPosition,
  selectedImageIds,
  album,
  vehicleId,
  isBackgroundRemovalAvailable,
  handleBackgroundRemoval,
  isAddForm,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const functionsRef = useRef<Pick<ImagesCardProps, 'onSelectionToggle' | 'onImageSelect'>>(null);
  const [isFileUploading, setIsFileUploading] = useState(false);

  // used ref for performance reasons
  const uploadingPhotosRef = useRef<UploadingImagesRefState>({photos: {}, updateCb: noop});
  const [isFileDragging, setIsFileDragging] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();

  const vehiclePhotoLocation = useMemo(() => {
    if (params?.tab === vehiclesRoutes.DownloadEventVehiclePhotos) {
      return PartialVehiclePhotosRoutes.vehicle;
    }
    if (params?.tab === vehiclesRoutes.DownloadEventInspections) {
      return PartialVehiclePhotosRoutes.inspections;
    }
    if (params?.tab === vehiclesRoutes.DownloadEventCondition) {
      return PartialVehiclePhotosRoutes.condition;
    }

    return PartialVehiclePhotosRoutes.vehicle;
  }, [params?.tab]);

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: vehicleId ?? '',
    },
    {skip: isNil(vehicleId)}
  );

  const {data: vehicle} = useGetVehicleQuery(
    {vehicleId: vehicleId ?? ''},
    {skip: isNil(vehicleId)}
  );

  const [hasRemoveVehiclePhotoBackgroundPermission] = usePermissions({
    permissionKeys: ['removeVehiclePhotoBackground'],
    scopes: {
      removeVehiclePhotoBackground: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  const [deleteAlbum] = useDeleteVehicleAlbumMutation();

  const {handleDownload, eventData, isLoading, isStatePending} = useBatchDownload();

  const filterFiles = useCallback((files: File[]): File[] => {
    const correctFiles = files
      .filter(
        (item) =>
          !!ALLOWED_MIME_TYPES_TO_UPLOAD.find((f) => {
            if (f === item.type) {
              return true;
            }
            return false;
          })
      )
      .filter((item) => MAXMIMUM_UPLOAD_SIZE_IN_MB * 1024 * 1024 > item.size);

    const filesOfNotAllowedMimeType = files.filter(
      (file) => !ALLOWED_MIME_TYPES_TO_UPLOAD.includes(file.type)
    );
    const tooBigFiles = files.filter(
      (file) => file.size > MAXMIMUM_UPLOAD_SIZE_IN_MB * 1024 * 1024
    );

    tooBigFiles.forEach((file) => {
      showNotification.error(
        i18n.t(`entity.photo.notifications.imageSizeIsTooBig`, {name: file.name})
      );
    });

    filesOfNotAllowedMimeType.forEach((file) => {
      showNotification.error(
        i18n.t('entity.photo.notifications.imageFormatIsNotAllowed', {
          name: file.name,
          // .split always returns string[], so it's not possible to last to return undefined.
          mimeFormat: (last(file.type.split('/')) as string).toUpperCase(),
        })
      );
    });

    return correctFiles;
  }, []);

  const handleUploadingStateUpdate = (id: string) => (upState?: UploadingFileType) => {
    const {photos, updateCb} = uploadingPhotosRef.current;

    if (upState) {
      photos[id] = upState;
    } else {
      delete photos[id];
    }

    updateCb();

    setIsFileUploading(!!Object.values(photos).length);
    if (!Object.keys(photos).length) {
      onAllImagesUploaded();
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    if (isNotNilOrEmpty(e?.dataTransfer?.files)) {
      const files = filterFiles([...e!.dataTransfer.files]);

      if (files.length) {
        onUpload(files, handleUploadingStateUpdate);
      }
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target;

    if (isNotNilOrEmpty(files)) {
      const viableFiles = filterFiles([...files!]);

      onUpload(viableFiles, handleUploadingStateUpdate);

      e.target.value = '';
    }
  };

  const handleFileSelect = () => {
    inputRef?.current?.click();
  };

  const handleEnableDroppable = () => {
    if (rest.isUploadDisabled) {
      return;
    }
    setIsFileDragging(true);
  };

  const handleDisableDroppable = () => {
    setIsFileDragging(false);
  };

  const handleImageSelection = useCallback(
    (id: string) => {
      if (selectionEnabled) {
        functionsRef?.current?.onImageSelect?.(id);
      } else {
        onImageClick?.(id);
      }
    },
    [selectionEnabled, onImageClick]
  );

  const getSelectedImages = useCallback(
    (ids: string[]): ImageType[] => {
      const idsSet = [...new Set(ids).values()];

      const _selectedImages = images.filter((image) => idsSet.find((id) => id === image.id));

      return _selectedImages;
    },
    [images]
  );

  const imageRenderer = useMemo(
    () =>
      images?.map((image, index) => (
        <ImageRenderer
          showPointer={onImageClick && !selectionEnabled}
          tooltipRenderer={withTooltip ? renderTooltipFactory(image) : undefined}
          getSelectedImages={getSelectedImages}
          disableDrag={!!disableDrag}
          selectionEnabled={!!selectionEnabled}
          contextMenuOptions={contextMenuOptions}
          image={image}
          handleImageSelection={handleImageSelection}
          key={image.fileId ?? image.id}
          index={index}
          selectedPhotos={selectedPhotos}
        />
      )),
    [
      images,
      disableDrag,
      withTooltip,
      selectedPhotos,
      contextMenuOptions,
      getSelectedImages,
      handleImageSelection,
      selectionEnabled,
      onImageClick,
    ]
  );

  useEffect(() => {
    if (globalListener) {
      document.addEventListener('dragenter', handleEnableDroppable);
      document.addEventListener('mouseleave', handleDisableDroppable);

      return () => {
        document.removeEventListener('dragenter', handleEnableDroppable);
        document.removeEventListener('mouseleave', handleDisableDroppable);
      };
    }
    return () => null;
  }, [globalListener]);

  useEffect(() => {
    functionsRef.current = {onImageSelect};
  }, [onImageSelect]);

  const selectALl = () => {
    images.forEach((image) => {
      if (selectedImageIds?.includes(image.id)) {
        return;
      }

      handleImageSelection(image.id);
    });
  };

  const onDeleteAll = () => {
    if (!onDelete) {
      return;
    }

    const imagesById = indexBy((item) => item.id, images);
    const imagesToDelete: ImageType[] = [];

    selectedImageIds?.forEach((imageId) => {
      const image = imagesById[imageId];
      isNotNil(image) && imagesToDelete.push(image);
    });

    openDeleteDialog({
      onConfirm: () => onDelete(imagesToDelete),
    });
  };

  const onDeleteAlbum = () => {
    if (isNil(album?.id)) {
      showNotification.error('No album ID.');

      return;
    }

    openDeleteDialog({
      text: i18n.t('entity.vehicle.albums.deleteQuestion'),
      onConfirm: () =>
        deleteAlbum({
          vehicleAlbumId: album!.id,
        })
          .unwrap()
          .then(() => showNotification.success())
          .then(() => {
            dispatch(loadCarDetailsVehicleDetailRequest({vehicleId: vehicleId ?? ''}));
            dispatch(vehicleApi.util.invalidateTags([{type: 'Vehicle', id: vehicleId ?? ''}]));
          })
          .catch(handleApiError),
    });
  };

  const onBackgroundRemoval = () => {
    if (!hasRemoveVehiclePhotoBackgroundPermission) {
      return;
    }

    const selectedImages = getSelectedImages(selectedImageIds ?? []);
    const isFromSelectedImages = isPositive(selectedImages.length);
    const imagesForBgRemoval = isFromSelectedImages ? selectedImages : images;

    const imageIds = imagesForBgRemoval
      .filter((photo) => !photo.isPromoPhoto)
      .map((photo) => photo.id);

    handleBackgroundRemoval?.(imageIds, isFromSelectedImages);
  };

  const cardHeaderContent = (
    <HStack spacing={4} align="center">
      <Show
        when={!isPositive(images.length) && album?.name === VehicleAlbums.PHOTOS_CUSTOM}
        whenFeatureEnabled={featureFlags.VEHICLE_MULTIPLE_ALBUMS}
      >
        <Button
          data-testid={suffixTestId('deleteImageButton', rest)}
          variant="dangerGhost"
          onClick={onDeleteAlbum}
          leftIcon="action/delete"
          title={i18n.t('entity.vehicle.albums.delete')}
        />
      </Show>
      <Show when={isPositive(images.length) && selectionEnabled}>
        <Button
          data-testid={suffixTestId('selectAllImageButton', rest)}
          variant="ghostLink"
          onClick={selectALl}
          leftIcon="action/done_all"
          title={i18n.t('entity.photo.actions.selectAll')}
        />
      </Show>
      <Show when={isPositive(images.length) && isBackgroundRemovalAvailable}>
        <Tooltip
          label={
            !hasRemoveVehiclePhotoBackgroundPermission
              ? i18n.t('general.labels.noPermission')
              : undefined
          }
        >
          <Button
            data-testid={suffixTestId('removeBackgroundButton', rest)}
            variant="ghostLink"
            leftIcon="action/magic_wand"
            title={i18n.t('entity.photo.actions.removeBackground')}
            onClick={onBackgroundRemoval}
            isDisabled={!hasRemoveVehiclePhotoBackgroundPermission}
          />
        </Tooltip>
      </Show>
      <Show when={isPositive(selectedImageIds?.length) && selectionEnabled}>
        <Button
          data-testid={suffixTestId('deleteImageButton', rest)}
          variant="dangerGhost"
          onClick={onDeleteAll}
          leftIcon="action/delete"
          title={i18n.t('general.actions.delete')}
        />
      </Show>
      <Show when={promoPhotos && !selectionEnabled}>
        <Button
          data-testid={suffixTestId('addPromoPhotosButton', rest)}
          variant="ghostLink"
          onClick={onAddPromoPhotos}
          leftIcon="image/add_photo_alternate"
          title={i18n.t('entity.photo.actions.addPromoPhotos')}
        />
      </Show>
      <Show when={!!images.length}>
        <Button
          data-testid={suffixTestId('bulkActions', rest)}
          onClick={onSelectionToggle}
          variant="ghostLink"
          leftIcon="navigation/menu"
          title={
            selectionEnabled
              ? i18n.t('general.actions.cancel')
              : i18n.t('general.actions.bulkActions')
          }
        />
      </Show>

      <Show when={isPositive(images.length) && isNotNil(album?.id)}>
        <Button
          data-testid={suffixTestId('downloadPhotoButton', rest)}
          onClick={() =>
            handleDownload({
              vehiclePhotoLocation,
              vehicleId: vehicleId ?? '',
              albumId: album?.id,
            })
          }
          variant="ghostLink"
          leftIcon="file/download"
          title={i18n.t('general.actions.downloadAll')}
        />
      </Show>

      <Show when={enableGallery && images.length > 0}>
        <Clickable data-testid={suffixTestId('fullscreen', rest)} onClick={onGalleryToggle}>
          <Icon value="maps/zoom_out_map" color="palettes.blue.60.100" />
        </Clickable>
      </Show>
    </HStack>
  );

  return (
    <CardStyled
      data-testid={rest['data-testid']}
      headerContent={!isAddForm ? cardHeaderContent : undefined}
      {...rest}
      {...cardProps}
    >
      <Show when={differentPosition}>
        <Alert
          type="inline"
          title={i18n.t('entity.photo.labels.promoPhotoDifferentOrder')}
          data-testid={suffixTestId('promoPhotoAlert', rest)}
          hyperlinks={[
            {
              size: 'small',
              title: i18n.t('entity.photo.actions.setDefaultOrder'),
              onClick: onSetDefaultOrder,
              leftIcon: 'navigation/refresh',
            },
          ]}
        />
      </Show>
      <input
        data-testid={suffixTestId('file-input', rest)}
        css={css`
          display: none;
        `}
        type="file"
        multiple
        accept={ALLOWED_MIME_TYPES_TO_UPLOAD.join(',')}
        ref={inputRef}
        onChange={handleFileChange}
      />

      {isFileDragging && (
        <Droppable
          data-testid={suffixTestId('images-card-droppable', rest)}
          onDragLeave={() => {
            if (!globalListener) {
              setIsFileDragging(false);
            }
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDrop={(e) => {
            if (rest.isUploadDisabled) {
              return;
            }
            e.preventDefault();
            e.stopPropagation();
            setIsFileDragging(false);
            handleDrop(e);
          }}
        >
          <Heading
            size={1}
            color="white"
            data-testid={suffixTestId('images-card-dropFilesFromYourComputer', rest)}
          >
            {i18n.t('entity.photo.labels.dropFilesFromYourComputer')}
          </Heading>
        </Droppable>
      )}
      <DroppableComponent droppableId={droppableId || ''}>
        {(elProps, {draggedItem, isHoveringOverEmpty}) => {
          const isActive =
            draggedItem?.currentlyIn === droppableId &&
            draggedItem?.currentlyIn !== draggedItem?.from;

          return (
            <ContentWrapper
              {...elProps}
              data-testid={suffixTestId('images-card-content', rest)}
              onDragEnter={() => {
                if (rest.isUploadDisabled) {
                  return;
                }
                if (!globalListener) {
                  setIsFileDragging(true);
                }
              }}
            >
              {Boolean(infoText) && (
                <Alert
                  type="inline"
                  variant="info"
                  message={infoText}
                  data-testid={suffixTestId('images-card-content-info', rest)}
                />
              )}
              {images.length || isFileUploading ? (
                <ImagesWrapper active={isActive}>
                  <HStack data-testid={suffixTestId('images-card-content-items', rest)} wrap>
                    {imageRenderer}
                    <UploadingImage refImages={uploadingPhotosRef} />
                    <MuiGridStyled
                      data-testid={suffixTestId('images-card-content-items-add', rest)}
                    >
                      <SelectionBorder
                        borderSide={isHoveringOverEmpty ? HoverPositions.Left : undefined}
                      >
                        {!draggedItem?.from && (
                          <AddTile
                            data-testid={suffixTestId('images-card-content-items-add-button', rest)}
                            onClick={handleFileSelect}
                          >
                            <Icon value="image/photo_camera" />
                          </AddTile>
                        )}
                      </SelectionBorder>
                    </MuiGridStyled>
                  </HStack>
                </ImagesWrapper>
              ) : (
                <EmptyWrapper
                  active={isActive}
                  infoText={Boolean(infoText)}
                  data-testid={suffixTestId('images-card-content-empty', rest)}
                >
                  <Heading
                    size={5}
                    data-testid={suffixTestId(
                      'images-card-content-empty-dropImagesHereOrUpload',
                      rest
                    )}
                  >
                    {i18n.t('entity.photo.labels.dropImagesHereOrUpload')}
                  </Heading>
                  <UploadSizeWrapper>
                    <Heading
                      alternative
                      size={6}
                      data-testid={suffixTestId(
                        'images-card-content-empty-maxUploadFileSize',
                        rest
                      )}
                    >
                      {`${i18n.t('general.labels.maxUploadFileSize')}: ${String(MAXMIMUM_UPLOAD_SIZE_IN_MB)} MB`}
                    </Heading>
                  </UploadSizeWrapper>
                  <NoPermissionTooltip shouldShowTooltip={rest.isUploadDisabled ?? false}>
                    <UploadButton
                      data-testid={suffixTestId('button-photo-album-uploadImages', rest)}
                      onClick={rest.isUploadDisabled ? undefined : handleFileSelect}
                      isDisabled={rest.isUploadDisabled}
                    >
                      <Icon value="action/backup" />
                      {i18n.t('entity.photo.actions.uploadImages')}
                    </UploadButton>
                  </NoPermissionTooltip>
                </EmptyWrapper>
              )}
            </ContentWrapper>
          );
        }}
      </DroppableComponent>
      <DownloadDialog
        withWrapper
        isOpen={isLoading || isStatePending}
        fileName={eventData?.data?.bulkName}
        fileCount={eventData?.data?.objects.length}
      />
    </CardStyled>
  );
};

import {CustomFilterProps} from 'ag-grid-react';
import {
  Chips,
  DatePicker,
  NumberInput,
  Separator,
  useTranslationContext,
} from 'platform/components';
import {HStack, Space, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {useCallback, useMemo} from 'react';

import {always, intersection, isNil, pathOr} from 'ramda';
import {isString} from 'ramda-adjunct';

import {getApiDateString, suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {QuickFilters} from '../../types/Api';

type DateRangeType = {from: string | null; to: string | null};
type RelativeInputType = {relativeInputValue: number; relativeInputKey: string};

export type DateRangeFilterProps = {
  min: string | null;
  max: string | null;
  relativeDates: Array<{value: string; label: string}>;
  relativeInputs: Array<{value: string; label: string}>;
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function DateRangeFilter(props: DateRangeFilterProps) {
  const t = useTranslationContext();

  const setFilterValue = useCallback((value: RelativeInputType | DateRangeType | string | null) => {
    const newModel = match(value)
      .with(Pattern.nullish, always(null))
      .with(Pattern.string, (stringValue) => stringValue)
      .with({from: Pattern.any, to: Pattern.any}, (objectValue) => {
        if (isNil(objectValue.from) && isNil(objectValue.to)) {
          return null;
        }
        return objectValue;
      })
      .otherwise(always(value));

    props.onModelChange(newModel);
  }, []);

  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue,
    defaultValue: null,
  });

  const [fromPickerValue, toPickerValue, inputValue, chipsValue] = useMemo(
    () => [
      pathOr(null, ['from'], props.model),
      pathOr(null, ['to'], props.model),
      pathOr(0, ['relativeInputValue'], props.model),
      isString(props.model) ? [props.model] : [],
    ],
    [props.model]
  );

  const onDateFromChange = useCallback(
    (value: Date | null) => {
      setFilterValue({
        from: value ? getApiDateString(value) : null,
        to: pathOr(null, ['to'], props.model),
      });
    },
    [props.model]
  );

  const onDateToChange = useCallback(
    (value: Date | null) => {
      setFilterValue({
        to: value ? getApiDateString(value) : null,
        from: pathOr(null, ['from'], props.model),
      });
    },
    [props.model]
  );
  const onInputChange = useCallback((relativeInputValue: number, relativeInputKey: string) => {
    const newModel =
      isNaN(relativeInputValue) || relativeInputValue === 0
        ? null
        : {relativeInputValue, relativeInputKey};

    setFilterValue(newModel);
  }, []);

  const isQuickFilterValue = intersection([props.model], quickFiltersConst) as string[];

  return (
    <VStack spacing={2}>
      {props.quickFilters && props.quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={props.isDisabled}
              value={isQuickFilterValue}
              options={props.quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('quickFilters-chips', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      {props.relativeDates && props.relativeDates.length > 0 && (
        <>
          <Chips
            isDisabled={props.isDisabled}
            value={chipsValue}
            options={props.relativeDates}
            onChange={onChipsChange}
            isMultiple={false}
            data-testid={suffixTestId('relativeDates-chips', props)}
            isDeselectable
          />
          <Separator />
        </>
      )}
      <DatePicker
        label={t('page.datagrid.filter.dateLabelFrom')}
        value={fromPickerValue}
        onChange={onDateFromChange}
        data-testid={suffixTestId('date-range-filter-from', props)}
      />
      <Space vertical={2} />
      <DatePicker
        label={t('page.datagrid.filter.dateLabelTo')}
        value={toPickerValue}
        onChange={onDateToChange}
        data-testid={suffixTestId('date-range-filter-to', props)}
      />
      {props.relativeInputs.map((relativeInput) => (
        <NumberInput
          key={relativeInput.value}
          label={relativeInput.label}
          isDisabled={props.isDisabled}
          onChange={(value) => onInputChange(value ?? 0, relativeInput.value)}
          value={inputValue}
          data-testid={suffixTestId(`relativeInput-${relativeInput.label}`, props)}
        />
      ))}
    </VStack>
  );
}

import {Button, Card, FormControl, Separator} from 'platform/components';
import {Align, Hide, Show, VStack} from 'platform/foundation';

import {useFieldArray, UseFormReturn} from 'react-hook-form';

import {VatCheckTotalsDefinitionDocumentTypeEnum} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId, useDebouncedCallback} from 'shared';

import {LIST_ITEM_FIELD_NAME} from '../../constants';
import {useInvoiceVatRatesContext} from '../../context/useInvoiceVatRatesContext';
import {AccountingDocumentFormValues} from '../../types';
import {DiscountItemRow} from './DiscountItemRow';
import {useRecalculateInvoiceItems} from './hooks/useRecalculateInvoiceItems';
import {InvoiceItemRow} from './InvoiceItemRow';
import {ListOfItemsSummary} from './ListOfItemsSummary';
import {MarginItemRow} from './MarginItemRow';
import {InvoiceItemRowProps} from './types/InvoiceItemRowProps';
import {getItemListActions} from './utils/getItemListActions';

interface CreateInvoiceListOfItems extends RequiredTestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
  formApi: UseFormReturn<AccountingDocumentFormValues>;
  listItemType: VatCheckTotalsDefinitionDocumentTypeEnum;
  isCorrectiveTaxDocument?: boolean;
  dateOfTaxableSupply?: string;
  isDisabled?: boolean;
}

export function ListOfItemsForm(props: CreateInvoiceListOfItems) {
  const isInvoicingDocument = props.listItemType === 'invoices';
  const fieldArray = useFieldArray({
    control: props.control,
    name: LIST_ITEM_FIELD_NAME,
  });

  const {rateOptions} = useInvoiceVatRatesContext();

  const listActions = getItemListActions(fieldArray, props.formApi, props.listItemType);

  const [recalculateItems] = useRecalculateInvoiceItems({
    control: props.control,
    formApi: props.formApi,
    listItemType: props.listItemType,
    isCorrectiveTaxDocument: props.isCorrectiveTaxDocument,
    shouldCallOnInitialization: true,
  });

  const handleRecalculateItems = useDebouncedCallback(
    (itemId: string) => recalculateItems(itemId),
    200
  );

  return (
    <Card title={i18n.t('general.labels.listOfItems')} data-testid={suffixTestId('section', props)}>
      <VStack spacing={2}>
        {fieldArray.fields.map((field, index) => {
          const rowProps: InvoiceItemRowProps = {
            fields: fieldArray.fields,
            isCorrectiveTaxDocument: props.isCorrectiveTaxDocument,
            isDisabled: props.isDisabled,
            callbacks: {
              ...listActions,
              onDeleteRow: (index: number) => {
                listActions.onDeleteRow(index);
                recalculateItems();
              },
              onRecalculate: handleRecalculateItems,
            },
            index,
            formApi: props.formApi,
            control: props.control,
            'data-testid': props['data-testid'],
            vatRatesOptions: rateOptions,
            listItemsType: props.listItemType,
          };

          const nextField = fieldArray.fields[index + 1];
          const prevField = fieldArray.fields[index - 1];

          const isPreviousFieldWithoutBottomSeparator = prevField?.type === 'standard';
          const isThisFieldWithMarginOrDiscount =
            nextField?.type === 'margin' || nextField?.type === 'discount';
          const shouldDisplayUpperSeparator =
            field.type === 'standard' &&
            isPreviousFieldWithoutBottomSeparator &&
            isThisFieldWithMarginOrDiscount &&
            isInvoicingDocument;

          const shouldDisplayBottomSeparator =
            nextField?.type === 'standard' && field.type !== 'standard' && isInvoicingDocument;

          return (
            <VStack spacing={2} key={field.id}>
              <Show when={shouldDisplayUpperSeparator}>
                <Separator />
              </Show>

              <Show when={field.type === 'standard'}>
                <InvoiceItemRow {...rowProps} />
              </Show>

              <Show when={isInvoicingDocument}>
                <Show when={field.type === 'margin'}>
                  <MarginItemRow {...rowProps} />
                </Show>

                <Show when={field.type === 'discount'}>
                  <DiscountItemRow {...rowProps} />
                </Show>
              </Show>

              <Show when={shouldDisplayBottomSeparator}>
                <Separator />
              </Show>
            </VStack>
          );
        })}
        <Hide when={props.isCorrectiveTaxDocument || props.isCorrectiveTaxDocument}>
          <Align left>
            <Button
              title={i18n.t('general.actions.addItem')}
              variant="link"
              leftIcon="content/add_circle"
              onClick={listActions.onCreateNewItem}
              data-testid={suffixTestId('addItem', props)}
            />
          </Align>
        </Hide>

        <ListOfItemsSummary control={props.control} data-testid={props['data-testid']} />
      </VStack>
    </Card>
  );
}

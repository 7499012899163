import {isFeatureEnabled} from 'feature-flags';
import {Parameter, Parameters, Flags, BaseFlagProps} from 'platform/components';
import {Box, HStack, VStack, Image, Icon, Text, Clickable, Hide, Show} from 'platform/foundation';
import {currencies, formatCurrencySymbol, useFormatCurrency} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  DashboardVehicleResponseBody,
  SaleVehicleBuyingState,
  SaleVehicleSellingState,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';
import {
  getVehicleAdvertisementTypeFlagProps,
  getVehicleBuyingStateFlagProps,
  getVehiclePhotographedFlagProps,
  getVehicleSaleTypeFlagProps,
  getVehicleSellingStateFlagProps,
  usePermissions,
  useTenant,
  useVehiclePhotosPermissions,
} from '@dms/shared';

import {
  CurrencyCodeType,
  EMPTY_PLACEHOLDER,
  TestIdProps,
  buildArray,
  composePath,
  suffixTestId,
  useNavigate,
} from 'shared';

import {usePriceFormatter} from '../../hooks/usePriceFormatter';
import {convertTagToFlag} from '../../utils/convertTagToFlag';
import {HoverableBackground} from '../HoverableBackground';

interface LatestVehiclesProps extends TestIdProps {
  vehicle: DashboardVehicleResponseBody;
}

export const LatestVehicleItem = (props: LatestVehiclesProps) => {
  const navigate = useNavigate();
  const {formatPrice} = usePriceFormatter();
  const {tenantCurrency} = useTenant();
  const formatCurrency = useFormatCurrency();

  const [canReadBusinessCase] = usePermissions({permissionKeys: ['businessCaseRead']});
  const {canReadVehicleAlbumPhotos} = useVehiclePhotosPermissions({
    vehicleId: props.vehicle.id,
  });

  const onClickOpenVehicleDetail = () => {
    navigate(composePath(vehiclesRoutes.detail, {params: {id: props.vehicle.id}}));
  };

  const flags = generateVehicleFlags(props.vehicle, canReadBusinessCase, canReadVehicleAlbumPhotos);
  const imgUrlWithHeight = `${props.vehicle?.imageUrl}&height=64`;

  return (
    <Clickable onClick={onClickOpenVehicleDetail}>
      <HoverableBackground>
        <HStack spacing={4} minWidth={100}>
          <HStack width={24}>
            <Box
              width={24}
              height={16}
              backgroundColor={
                !canReadVehicleAlbumPhotos || !props.vehicle.imageUrl
                  ? 'palettes.neutral.30.100'
                  : undefined
              }
              borderRadius="small"
              overflow="hidden"
            >
              <Show when={canReadVehicleAlbumPhotos && isNotNilOrEmpty(props.vehicle.imageUrl)}>
                <Box borderRadius="small" overflow="hidden">
                  <Image
                    fit="contain"
                    ratio="3 / 2"
                    src={imgUrlWithHeight}
                    data-testid={suffixTestId('latest-vehicle-item-img', props)}
                  />
                </Box>
              </Show>
              <Hide when={canReadVehicleAlbumPhotos && isNotNilOrEmpty(props.vehicle.imageUrl)}>
                <HStack height="100%" width="100%" align="center" justify="center">
                  <Icon value="maps/directions_car" size={10} color="palettes.neutral.800.100" />
                </HStack>
              </Hide>
            </Box>
          </HStack>
          <Box flex={3}>
            <VStack spacing={1} grow={1}>
              <HStack spacing={1}>
                <Flags
                  spacing={1}
                  flags={flags}
                  shouldWrap
                  data-testid={suffixTestId('latest-vehicle-item-flags', props)}
                />
              </HStack>
              <Text alternative>{props.vehicle.title}</Text>
              <Parameters
                size="xSmall"
                parameters={getVehicleParams(props.vehicle)}
                data-testid={suffixTestId('latest-vehicle-item-parameters', props)}
              />
            </VStack>
          </Box>
          <Box flex={2} paddingTop={4}>
            <VStack align="flex-end" justify="center" spacing={1}>
              <Box flexGrow={1} />
              <Show when={isNotNilOrEmpty(props.vehicle.priceWithVat)}>
                <Text alternative>{formatPrice(props.vehicle.priceWithVat)}</Text>
              </Show>
              <Hide when={isNotNilOrEmpty(props.vehicle.priceWithVat)}>
                <Text>
                  {`${EMPTY_PLACEHOLDER} ${formatCurrencySymbol(currencies, (props.vehicle?.priceWithVat?.currency as CurrencyCodeType) ?? tenantCurrency)}`}
                </Text>
              </Hide>
              <Show when={props.vehicle.isVatDeductible}>
                <Show when={isNotNilOrEmpty(props.vehicle?.priceWithoutVat?.amount)}>
                  <Text size="xSmall" color="secondary">
                    {`${formatCurrency(
                      Number(props.vehicle?.priceWithoutVat?.amount),
                      props.vehicle?.priceWithoutVat?.currency ?? tenantCurrency
                    )} ${i18n.t('general.labels.withoutVat')}`}
                  </Text>
                </Show>
                <Hide when={isNotNilOrEmpty(props.vehicle?.priceWithoutVat?.amount)}>
                  <Text size="xSmall" color="secondary">
                    {`${EMPTY_PLACEHOLDER} ${formatCurrencySymbol(currencies, (props.vehicle?.priceWithoutVat?.currency as CurrencyCodeType) ?? tenantCurrency)} ${i18n.t('general.labels.withoutVat')}`}
                  </Text>
                </Hide>
              </Show>
              <Hide when={props.vehicle.isVatDeductible}>
                <Text size="xSmall" color="secondary">
                  {i18n.t('entity.vehicle.labels.nondeductible')}
                </Text>
              </Hide>
            </VStack>
          </Box>
        </HStack>
      </HoverableBackground>
    </Clickable>
  );
};

function generateVehicleFlags(
  vehicle: DashboardVehicleResponseBody,
  canReadBusinessCase: boolean,
  canReadVehicleAlbumPhotos: boolean
): BaseFlagProps[] {
  const isSalesModuleEnabled = isFeatureEnabled(featureFlags.ACL_SALES) && canReadBusinessCase;

  const saleTypeFlagProps = getVehicleSaleTypeFlagProps(vehicle.saleType);
  const sellingStateFlagProps = getVehicleSellingStateFlagProps(
    vehicle.sellingState as SaleVehicleSellingState
  );
  const buyingStateFlagProps = getVehicleBuyingStateFlagProps(
    vehicle.buyingState as SaleVehicleBuyingState
  );
  const advertisementStateFlagProps = getVehicleAdvertisementTypeFlagProps(vehicle.isAdvertised);
  const photographedFlagProps = getVehiclePhotographedFlagProps(vehicle.isPhotographed);

  const shouldDisplaySellingStateFlag = isSalesModuleEnabled && isNotNil(sellingStateFlagProps);
  const shouldDisplayBuyingStateFlag = isSalesModuleEnabled && isNotNil(buyingStateFlagProps);
  const shouldDisplayAdvertisementFlag =
    isSalesModuleEnabled &&
    (sellingStateFlagProps || buyingStateFlagProps) &&
    isNotNil(advertisementStateFlagProps);
  const shouldDisplayPhotographedFlag =
    isSalesModuleEnabled && canReadVehicleAlbumPhotos && isNotNil(photographedFlagProps);
  const shouldDisplayIsReservedFlag = isSalesModuleEnabled && vehicle.isReserved;

  const flags = buildArray<BaseFlagProps>()
    .when(shouldDisplayIsReservedFlag, {
      label: i18n.t('entity.vehicle.labels.reserved'),
      colorScheme: 'yellow',
    })
    .when(vehicle.isArchived, {
      label: i18n.t('entity.vehicle.labels.archived'),
      colorScheme: 'neutral',
    })
    .when(
      isNotNil(saleTypeFlagProps) && !vehicle?.isArchived && isSalesModuleEnabled,
      saleTypeFlagProps!
    )
    .when(shouldDisplaySellingStateFlag, sellingStateFlagProps!)
    .when(shouldDisplayBuyingStateFlag, buyingStateFlagProps!)
    .when(shouldDisplayAdvertisementFlag, advertisementStateFlagProps!)
    .when(shouldDisplayPhotographedFlag, photographedFlagProps!);

  flags.forEach((flag) => (flag.size = 'small'));

  vehicle.tags.forEach((tag) => flags.add(convertTagToFlag(tag)));

  return flags;
}

function getVehicleParams(vehicle: DashboardVehicleResponseBody) {
  const {manufacturedOn, mileage, fuelType, transmission} = vehicle;
  return buildArray<Parameter>()
    .when(manufacturedOn, {
      title: manufacturedOn,
      tooltip: i18n.t('entity.vehicle.labels.manufactureYear'),
    })
    .when(mileage, {
      title: mileage,
      tooltip: i18n.t('entity.vehicle.labels.realMileage'),
    })
    .when(fuelType, {
      title: fuelType,
      tooltip: i18n.t('entity.vehicle.labels.fuelType'),
    })
    .when(transmission, {
      title: transmission,
      tooltip: i18n.t('entity.vehicle.labels.transmission'),
    });
}

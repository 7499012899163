import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Label,
  openConfirmDialog,
} from 'platform/components';
import {Text, Box, HStack, Space, VStack, Show} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useMemo} from 'react';

import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  TaskPriorityEnum,
  TaskPriorityEnumSchema,
  TaskStateEnumSchema,
  TaskTypeEnum,
  TaskTypeEnumSchema,
  useGetCurrentUserInfoQuery,
  useGetUsersQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {CommentsWidget, openPredefinedNotesDropdown} from '@dms/shared';

import {parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import {TASK_PRIORITY_LABEL_MAP, TASK_TYPE_LABEL_MAP} from '../../constants/task';
import type {Task, TaskForm as TaskFormType} from '../../types/TaskForm';
import {TaskRelationProps} from '../../types/TaskRelationProps';
import {TaskRelatesCard} from '../TaskRelatesCard/TaskRelatesCard';
import {TaskStateSwitcherField} from '../TaskStateSwitcherField/TaskStateSwitcherField';
import {getTaskFormDefaultValues} from './utils/getTaskFormDefaultValues';
import {mapUsersToSelectOptions} from './utils/mapUsersToSelectOptions';
import {moveToHeadById} from './utils/moveToHeadById';
import {yupTaskFormSchema} from './yupTaskFormSchema';

interface TaskFormProps extends RequiredTestIdProps, TaskRelationProps {
  onSubmit: FormSubmitHandler<TaskFormType>;
  values?: Task;
  onDelete?: () => Promise<void>;
  isLoading?: boolean;
  isReadOnly?: boolean;
}

export function TaskForm(props: TaskFormProps) {
  const dateFormatter = useDateTimeFormatter();
  const {data: currentUser} = useGetCurrentUserInfoQuery();
  const {data: users, isLoading: areUsersLoading} = useGetUsersQuery();

  const usersOptions = useMemo(
    () => mapUsersToSelectOptions(moveToHeadById(currentUser?.id ?? '', users ?? [])),
    [users, currentUser]
  );

  const handleDelete = () => {
    if (props.onDelete) {
      openConfirmDialog({
        onConfirm: () => {
          props.onDelete?.();
        },
      });
    }
  };

  const isProcessing = props.isLoading || areUsersLoading;

  return (
    <Form<TaskFormType>
      data-testid={suffixTestId('taskForm', props)}
      onSubmit={props.onSubmit}
      schema={yupTaskFormSchema}
      defaultValues={getTaskFormDefaultValues(dateFormatter, currentUser, {
        ...props.values,
        relatedRecordId:
          props.relatedRecordId && props.resourceId
            ? {
                id: props.relatedRecordId,
                resourceId: props.resourceId as string,
              }
            : null,
      })}
    >
      {(control, formApi) => (
        <>
          <HStack spacing={4}>
            <VStack grow={2} spacing={4} maxWidth="80%">
              <FormField
                data-testid={suffixTestId('title', props)}
                control={control}
                name="title"
                type="text"
                label={i18n.t('entity.task.title')}
                isRequired
                isDisabled={props.isReadOnly}
                labelActions={
                  props.isReadOnly
                    ? undefined
                    : [
                        {
                          title: i18n.t('general.labels.templates'),
                          leftIcon: 'content/add_circle',
                          onClick: (event) => {
                            openPredefinedNotesDropdown(event, {
                              note: formApi.watch('title') ?? null,
                              resource: EntityResourceIds.task,
                              context: 'title',
                              onPrefill: (text) => {
                                formApi.setValue('title', text);
                              },
                              'data-testid': suffixTestId('titleTemplates', props),
                            });
                          },
                        },
                      ]
                }
              />
              <FormField
                data-testid={suffixTestId('type', props)}
                control={control}
                name="type"
                type="chips"
                isDisabled={props.isReadOnly}
                label={i18n.t('entity.task.type')}
                isRequired
                options={TaskTypeEnumSchema.options
                  .filter((option) => !props.isReadOnly || option === formApi.getValues().type)
                  .map((option) => ({
                    value: option,
                    label: i18n.t(TASK_TYPE_LABEL_MAP[option as TaskTypeEnum]),
                  }))}
              />
              <HStack spacing={4}>
                <Box width="100%">
                  <FormField
                    data-testid={suffixTestId('dueDate', props)}
                    control={control}
                    isDisabled={props.isReadOnly}
                    name="dueDate"
                    type="date"
                    label={i18n.t('entity.task.dueDate')}
                    isRequired={
                      isNotNilOrEmpty(formApi.getValues().dueTo) ||
                      isNotNilOrEmpty(formApi.getValues().dueFrom)
                    }
                  />
                </Box>
                <Box width="100%">
                  <FormField
                    data-testid={suffixTestId('dueFrom', props)}
                    control={control}
                    isDisabled={props.isReadOnly}
                    name="dueFrom"
                    type="time"
                    label={i18n.t('entity.task.dueFrom')}
                  />
                </Box>
                <Box width="100%">
                  <FormField
                    data-testid={suffixTestId('dueTo', props)}
                    control={control}
                    isDisabled={props.isReadOnly}
                    name="dueTo"
                    type="time"
                    isRequired={isNotNilOrEmpty(formApi.getValues().dueFrom)}
                    label={i18n.t('entity.task.dueTo')}
                  />
                </Box>
              </HStack>
              <FormField
                data-testid={suffixTestId('description', props)}
                control={control}
                isDisabled={props.isReadOnly}
                name="description"
                type="textarea"
                isScrollable
                minRows={1}
                maxRows={10}
                label={i18n.t('entity.task.description')}
                labelActions={
                  props.isReadOnly
                    ? undefined
                    : [
                        {
                          title: i18n.t('general.labels.templates'),
                          leftIcon: 'content/add_circle',
                          onClick: (event) => {
                            openPredefinedNotesDropdown(event, {
                              note: formApi.watch('description') ?? null,
                              resource: EntityResourceIds.task,
                              context: 'description',
                              onPrefill: (text) => {
                                formApi.setValue('description', text);
                              },
                              'data-testid': suffixTestId('descriptionTemplates', props),
                            });
                          },
                        },
                      ]
                }
              />

              <Show when={isNotNilOrEmpty(formApi.getValues()?.relatedRecordId)}>
                <div>
                  <Label>{i18n.t('entity.task.labels.relatesTo')}</Label>
                  {(() => {
                    formApi.watch('relatedRecordId');
                    return (
                      <TaskRelatesCard
                        data-testid={suffixTestId('relatesTo', props)}
                        relatedRecordId={props.relatedRecordId ?? ''}
                        resourceId={props.resourceId!}
                        isReadOnly={props.isReadOnly}
                        onRelationRemove={() => {
                          formApi.setValue('relatedRecordId', null);
                        }}
                      />
                    );
                  })()}
                </div>
              </Show>

              <Show when={props.values?.id}>
                <CommentsWidget
                  title={i18n.t('page.comments.labels.comments')}
                  resourceId={EntityResourceIds.task}
                  recordId={props.values?.id ?? ''}
                  isAddDisabled={props.isReadOnly}
                  isDeleteDisabled={props.isReadOnly}
                />
              </Show>
            </VStack>
            <Box
              flexGrow={2}
              width="20%"
              backgroundColor="palettes.neutral.20.100"
              borderRadius="medium"
              padding={4}
            >
              <VStack spacing={4}>
                <HStack>
                  <TaskStateSwitcherField
                    data-testid={suffixTestId('state', props)}
                    control={control}
                    isDisabled={props.isReadOnly}
                    name="state"
                  />
                </HStack>
                <FormField
                  isLoading={isProcessing}
                  isDisabled={isProcessing || props.isReadOnly}
                  data-testid={suffixTestId('assignedTo', props)}
                  control={control}
                  name="assignedTo"
                  isRequired
                  type="choice"
                  options={usersOptions}
                  label={i18n.t('entity.task.assignedTo')}
                />
                <FormField
                  isLoading={isProcessing}
                  isDisabled={isProcessing || props.isReadOnly}
                  data-testid={suffixTestId('reportedBy', props)}
                  control={control}
                  name="reportedBy"
                  type="choice"
                  isRequired
                  options={usersOptions}
                  label={i18n.t('entity.task.reportedBy')}
                />
                <FormField
                  data-testid={suffixTestId('priority', props)}
                  control={control}
                  name="priority"
                  type="chips"
                  isDisabled={props.isReadOnly}
                  label={i18n.t('entity.task.priority')}
                  options={TaskPriorityEnumSchema.options
                    .filter(
                      (option) => !props.isReadOnly || option === formApi.getValues().priority
                    )
                    .map((option) => ({
                      value: option,
                      label: i18n.t(TASK_PRIORITY_LABEL_MAP[option as TaskPriorityEnum]),
                    }))}
                />
                <HStack justify="space-between">
                  <Show when={isNotNilOrEmpty(props.values?.createdAt)}>
                    <div>
                      <Label>{i18n.t('general.labels.createdAt')}</Label>
                      <Text size="small">
                        {props.values?.createdAt
                          ? dateFormatter('dateTimeShort', parseDate(props.values?.createdAt))
                          : null}
                      </Text>
                    </div>
                  </Show>
                  <Show
                    when={
                      props.values?.state === TaskStateEnumSchema.enum.DONE &&
                      isNotNilOrEmpty(props.values?.finishedAt)
                    }
                  >
                    <div>
                      <Label>{i18n.t('entity.task.finishedAt')}</Label>
                      <Text size="small">
                        {props.values?.finishedAt
                          ? dateFormatter('dateTimeShort', parseDate(props.values?.finishedAt))
                          : null}
                      </Text>
                    </div>
                  </Show>
                </HStack>
                <Show when={isNotNil(props.onDelete) && isNotNilOrEmpty(props.values?.id)}>
                  <div>
                    <Button
                      variant="dangerLink"
                      leftIcon="action/delete"
                      onClick={handleDelete}
                      isLoading={props.isLoading}
                      isDisabled={props.isLoading}
                      title={i18n.t('entity.task.actions.deleteTask')}
                    />
                  </div>
                </Show>
              </VStack>
            </Box>
          </HStack>
          <Space vertical={4} />
          <DialogFooter data-testid={props['data-testid']}>
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={closeCurrentDialog}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                variant="primary"
                isLoading={isProcessing}
                type="submit"
                control={control}
                title={
                  isNotNilOrEmpty(props.values)
                    ? i18n.t('general.actions.edit')
                    : i18n.t('general.actions.create')
                }
                isDisabled={isProcessing || props.isReadOnly}
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </DialogFooter>
        </>
      )}
    </Form>
  );
}

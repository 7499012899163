import {CostItem} from '@dms/api';

import {CostsData} from '../components/CostsRow';

export const getNewCostItem = (newData: CostsData): CostItem => ({
  ...newData,
  cost: (newData.cost ?? 0).toString(),
  note: newData.note ?? '',
  currency: 'CZK',
  type: 'other',
});

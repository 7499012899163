import validatePhone from 'phone';
import {PhoneNumber} from 'platform/components';
import {mixed, object, string} from 'yup';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const yupPersonalInformationSchema = object({
  firstName: string()
    .required()
    .nullable()
    .matches(/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF-\s.]+$/i, {
      message: i18n.t('general.notifications.invalidFirstName'),
    })
    .max(60, i18n.t('general.notifications.invalidFirstName')),
  lastName: string()
    .required()
    .nullable()
    .matches(/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF-\s.]+$/i, {
      message: i18n.t('general.notifications.invalidLastName'),
    })
    .max(60, i18n.t('general.notifications.invalidLastName')),
  phoneNumber: mixed()
    .test(
      'isPhone',
      i18n.t('general.validations.invalidPhoneNumber'),
      (phone: PhoneNumber | Nullish) => {
        if (isNilOrEmpty(phone?.number)) {
          return true;
        }

        return validatePhone(phone?.number || '', {
          country: phone?.countryCode,
          validateMobilePrefix: false,
        }).isValid;
      }
    )
    .nullable(),
});

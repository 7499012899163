import {z} from 'zod';

import {AvailableDiscountSchema, DiscountSourceTypeSchema} from '@dms/api';

export const EditMaterialItemFormSchema = z.object({
  neededAt: z.date().nullish(),
  quantity: z.number(),
  sellingPricePerUnit: z.number(),
  vatType: z.string(),
  vatFreeCode: z.string().nullish(),
  mechanic: z.string().nullish(),
  isUnitPriceWithVat: z.boolean().default(false),
  isCustomPrice: z.boolean().default(false),
  discount: z.object({
    isDoNotApplyDiscount: z.boolean().default(false),
    isDiscountEnable: z.boolean().default(false),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: z.number().nullish(),
    availableDiscounts: z.array(
      AvailableDiscountSchema.extend({discountRate: z.number().min(0).max(100).nullish()})
    ),
  }),
  // Used only locally to be able to control the getCurrentPrice action recalculations
  shouldGetCurrentPrice: z.boolean().default(false),
});

export type EditMaterialItemForm = z.infer<typeof EditMaterialItemFormSchema>;

import styled from 'styled-components';

import {ReactNode} from 'react';

interface HoverableBackgroundProps {
  children: ReactNode;
}

export function HoverableBackground(props: HoverableBackgroundProps) {
  return <Background>{props.children}</Background>;
}

const Background = styled.div`
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
  }
  border-radius: ${({theme}) => theme.radii.small};
`;

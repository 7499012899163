import {Card, Separator} from 'platform/components';
import {Box, HStack, Integer, Show} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {ReactNode} from 'react';

import {TestIdProps} from 'shared';

import {ExternalFilterWithFolderTree} from './ExternalFilterWithFolderTree';

const DEFAULT_EXTERNAL_FILTERS_HEIGHT = 100;

export type FullscreenDataGridWrapperProps = {
  externalFilterId?: string;
  externalFilterHeight?: Integer;
  folderTreeRef?: (node: unknown) => void;
  hideCardWrapper?: true;
  children: ReactNode;
} & TestIdProps;

export function FullscreenDataGridWrapper(props: FullscreenDataGridWrapperProps) {
  const content = match(props.externalFilterId)
    .with(Pattern.nullish, () => (
      <Box height="100%" width="100%">
        {props.children}
      </Box>
    ))
    .otherwise(() => (
      <HStack height="100%" data-testid={props['data-testid']}>
        <Show when={props.externalFilterId}>
          <ExternalFilterWithFolderTree
            externalFilterId={props.externalFilterId as string}
            folderTreeRef={props.folderTreeRef}
            width={100}
            externalFilterHeight={props.externalFilterHeight ?? DEFAULT_EXTERNAL_FILTERS_HEIGHT}
            data-testid={`${props['data-testid']}-externalFilterWithFolderTree`}
          />
        </Show>

        <Separator orientation="vertical" />

        <Box height="100%" width="100%">
          {props.children}
        </Box>
      </HStack>
    ));

  if (props.hideCardWrapper) {
    return content;
  }

  return (
    <Card isFullHeight variant="inlineWhite">
      {content}
    </Card>
  );
}

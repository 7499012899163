import {useCallback} from 'react';

import {RESET_FILTERS} from '../const/events';
import {GridApi} from '../types/AgGridTypes';

export const useResetFilterByUser = (gridApi?: GridApi) =>
  useCallback(() => {
    if (!gridApi) {
      return;
    }

    window.dispatchEvent(new CustomEvent(RESET_FILTERS));
    gridApi.setFilterModel({});
  }, [gridApi]);

import {
  Checkbox as PlatformCheckbox,
  NumberInput,
  TableRow,
  TableRowActionsData,
  TextInput,
} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {useRef, useState} from 'react';

import {isNil} from 'ramda';

import {i18n} from '@dms/i18n';

import {useOnMount} from 'shared';

import {CostsData} from './CostsRow';

interface NewCostsRowProps {
  isSaving?: boolean;
  onCreate: (data: CostsData) => void;
  onDelete: () => void;
}

export function NewCostsRow({isSaving, onCreate, onDelete}: NewCostsRowProps) {
  const [isEnabled, setIsEnabled] = useState(true);
  const [title, setTitle] = useState<string | null>(
    i18n.t('entity.vehicle.labels.costItemTitleOther')
  );
  const [cost, setCost] = useState<number | null>(null);
  const [note, setNote] = useState<string | null>(null);
  const titleInputRef = useRef<HTMLInputElement>(null);

  useOnMount(() => {
    titleInputRef.current?.focus();
  });

  const handleSaveChanges = () => {
    onCreate({
      title: title ?? '',
      cost,
      note,
      enabled: isEnabled,
    });
  };

  const tableActions: TableRowActionsData = {
    primary: [
      {
        title: i18n.t('general.labels.saveChanges'),
        icon: 'action/done',
        isDisabled: isSaving || isNil(title),
        onClick: handleSaveChanges,
      },
      {
        title: i18n.t('general.labels.delete'),
        icon: 'action/delete',
        isDisabled: isSaving,
        onClick: () => onDelete(),
      },
    ],
  };

  return (
    <TableRow actions={tableActions}>
      <Box padding={1} paddingLeft={2} minWidth={24} maxWidth={80}>
        <HStack spacing={2} align="center">
          <PlatformCheckbox value={isEnabled} onChange={setIsEnabled} />
          <Box flex={1}>
            <TextInput
              value={title}
              onChange={setTitle}
              isDisabled={isSaving}
              errorMessage={
                isNil(title)
                  ? i18n.t('general.errors.required', {
                      label: i18n.t('entity.vehicle.labels.item'),
                    })
                  : undefined
              }
              ref={titleInputRef}
            />
          </Box>
        </HStack>
      </Box>
      <Box padding={1}>
        <NumberInput value={cost} onChange={setCost} isDisabled={isSaving} />
      </Box>
      <Box padding={1} paddingRight={2} minWidth={24} maxWidth={80}>
        <TextInput value={note} onChange={setNote} isDisabled={isSaving} />
      </Box>
    </TableRow>
  );
}

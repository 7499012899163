import {CustomFilterProps} from 'ag-grid-react';
import {Chips, Separator, Slider, TextInput} from 'platform/components';
import {Grid, HStack, VStack} from 'platform/foundation';

import {useCallback} from 'react';

import {isNil, isNotNil} from 'ramda';
import {isArray, isString} from 'ramda-adjunct';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {useSliderFilter} from '../../hooks/useSliderFilter';
import {useSliderTicks} from '../../hooks/useSliderTicks';
import {QuickFilters} from '../../types/Api';

export type PlainSliderRangeProps = {
  min: number;
  max: number;
  minOpen: boolean;
  maxOpen: boolean;
  unit: string | null;
  stepSize: number | null;
  steps: number[];
  markerSize: number | null;
  markers: number[];
  decimalsAllowed: boolean;
  inputsVisible: boolean;
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters;

export function PlainSliderRangeFilter({
  model,
  onModelChange,
  min,
  max,
  unit,
  stepSize,
  steps,
  markerSize,
  markers,
  isDisabled,
  quickFilters,
}: PlainSliderRangeProps) {
  const ticks = useSliderTicks(min, max, markerSize, markers);
  const valueFormatter = useCallback(
    (value: number) => (unit ? `${value} [${unit}]` : `${value}`),
    [unit]
  );

  const [filterValue, setFilterValue] = useSliderFilter({model, onModelChange, min, max});

  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue,
    defaultValue: null,
  });

  const onChange = (value: [number, number]) => {
    setFilterValue(value);
  };
  const onFromInputChange = (textValue: string | null) => {
    const value = isNotNil(textValue) ? parseFloat(textValue) : null;

    if (isNil(filterValue)) {
      setFilterValue([value, 0]);
    }

    if (isArray(filterValue)) {
      setFilterValue([
        isNotNil(value) && isNaN(value) ? null : value,
        (filterValue?.[1] as number) ?? max,
      ]);
    }

    if (isString(filterValue)) {
      setFilterValue([isNotNil(value) && isNaN(value) ? null : value, max]);
    }
  };

  const onToInputChange = (textValue: string | null) => {
    const value = isNotNil(textValue) ? parseFloat(textValue) : null;

    if (isNil(filterValue)) {
      setFilterValue([0, value]);
    }

    if (isArray(filterValue)) {
      setFilterValue([
        (filterValue?.[0] as number) ?? min,
        isNotNil(value) && isNaN(value) ? null : value,
      ]);
    }

    if (isString(filterValue)) {
      setFilterValue([min, isNotNil(value) && isNaN(value) ? null : value]);
    }
  };

  const isQuickFilterValue = isString(filterValue) ? [filterValue] : null;

  const sliderValue: [number, number] = isArray(filterValue)
    ? [filterValue?.[0] ?? min, filterValue?.[1] ?? max]
    : [min, max];

  const isFrom = isArray(filterValue) ? filterValue?.[0] : min;
  const isTo = isArray(filterValue) ? filterValue?.[1] : max;

  const isFromValue = isFrom?.toString() ?? null;
  const isToValue = isTo?.toString() ?? null;

  return (
    <VStack spacing={5}>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Slider
        isDisabled={isDisabled}
        isRange
        min={min}
        max={max}
        step={stepSize ?? steps ?? 1}
        value={sliderValue}
        onChange={onChange}
        formatValue={valueFormatter}
        ticks={ticks}
        onlyEdges={true}
        displayValue="auto"
      />
      <Grid columns={2}>
        <TextInput
          value={isFromValue}
          isDisabled={isDisabled}
          onChange={onFromInputChange}
          type="number"
        />
        <TextInput
          value={isToValue}
          isDisabled={isDisabled}
          onChange={onToInputChange}
          type="number"
        />
      </Grid>
    </VStack>
  );
}

import {Grid} from 'platform/foundation';

import {EntityResourceIds, useGetActiveSaleVehicleQuery, useGetParticipationQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {usePermissions} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {SummaryCard} from './SummaryCard';

interface EarningsCostsSummaryProps extends TestIdProps {
  vehicleId: string;
}

export function EarningsCostsSummary(props: EarningsCostsSummaryProps) {
  const {
    data,
    isLoading: isLoadingFinancialIndicators,
    isError: hasFinancialIndicatorsError,
  } = useGetActiveSaleVehicleQuery({
    vehicleId: props.vehicleId,
  });

  const financialIndicators = data?.financialIndicators;

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const [canReadCosts, canReadEarnings, canReadTotalGrossMargin] = usePermissions({
    permissionKeys: ['vehicleReadCosts', 'vehicleReadEarnings', 'vehicleReadTotalGrossMargin'],
    scopes: {
      vehicleReadCosts: {
        participation: vehicleParticipation,
        branchId: data?.branchId,
      },
      vehicleReadEarnings: {
        participation: vehicleParticipation,
        branchId: data?.branchId,
      },
      vehicleReadTotalGrossMargin: {
        participation: vehicleParticipation,
        branchId: data?.branchId,
      },
    },
  });

  return (
    <Grid columns={[1, 2]}>
      <SummaryCard
        isError={hasFinancialIndicatorsError}
        isLoading={isLoadingFinancialIndicators}
        earnings={financialIndicators?.expectedEarnings}
        costs={financialIndicators?.expectedCosts}
        margin={financialIndicators?.expectedTotalGrossMargin}
        areCostsHidden={!canReadCosts}
        areEarningsHidden={!canReadEarnings}
        isTotalMarginHidden={!canReadTotalGrossMargin}
        title={i18n.t('entity.earningsCosts.labels.expectedState')}
        data-testid={suffixTestId('expectedState', props)}
      />
      <SummaryCard
        isError={hasFinancialIndicatorsError}
        isLoading={isLoadingFinancialIndicators}
        earnings={financialIndicators?.realEarnings}
        earningsDifference={financialIndicators?.expectedAndRealEarningsDifference}
        costs={financialIndicators?.realCosts}
        costsDifference={financialIndicators?.expectedAndRealCostsDifference}
        areCostsHidden={!canReadCosts}
        areEarningsHidden={!canReadEarnings}
        isTotalMarginHidden={!canReadTotalGrossMargin}
        margin={financialIndicators?.totalGrossMargin}
        title={i18n.t('entity.earningsCosts.labels.realState')}
        data-testid={suffixTestId('realState', props)}
      />
    </Grid>
  );
}

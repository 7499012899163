import {DefaultComponentsTheme, OptionTypeBase} from 'platform/components';
import {Icon, Spinner} from 'platform/foundation';
import styled, {css, useTheme} from 'styled-components';

import {
  ComponentType,
  FC,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  Ref,
  useRef,
  useState,
} from 'react';
import ReactSelect, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
  GroupHeadingProps,
  MenuListProps,
  MultiValueProps,
  OptionProps,
  OptionsOrGroups,
  Props as ReactSelectProps,
} from 'react-select';
import Creatable, {CreatableProps} from 'react-select/creatable';
import Select from 'react-select/dist/declarations/src/Select';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {Nullish} from 'shared';

import {FormError} from '../Field/components/FormError';
import {FormLabel} from '../Field/components/FormLabel';
import {Tag} from '../Tag/Tag';
import {customStyles} from './customStyles';

const StyledH6 = styled.div`
  font-size: ${({theme}) => theme.fontSizes.text.xxSmall};
  line-height: 1.34;
  font-weight: 400;
  text-align: left;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  color: ${({theme}) => theme.colors.palettes.neutral[100][100]};
`;

export const ClearIndicator = <T extends OptionTypeBase<number | string | Nullish>>(
  props: ClearIndicatorProps<T, boolean, GroupBase<T>>
): ReactElement => (
  <components.ClearIndicator {...props}>
    <Icon value="navigation/cancel" />
  </components.ClearIndicator>
);

export const DropdownIndicator = <
  T extends OptionTypeBase<number | string | Nullish>,
  isMulti extends boolean,
>(
  props: DropdownIndicatorProps<T, isMulti, GroupBase<T>>
): ReactElement => (
  <components.DropdownIndicator {...props}>
    <Icon value="navigation/arrow_drop_down" size={4} />
  </components.DropdownIndicator>
);

const GroupHeading = <T extends OptionTypeBase<number | string | Nullish>, isMulti extends boolean>(
  props: GroupHeadingProps<T, isMulti>
): ReactElement => (
  <components.GroupHeading {...props}>
    <StyledH6>
      <>{props.children}</>
    </StyledH6>
  </components.GroupHeading>
);

const SplitLabel: FC<OptionProps<any, boolean>> = ({children, data}) => (
  <SplitLabelWrapper>
    <div>{children}</div>
    <div>{String(data.label2)}</div>
  </SplitLabelWrapper>
);

const SplitLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DropdownOption =
  (name?: string): FC<OptionProps<any, boolean>> =>
  ({children, ...props}) => {
    const actualChildren = props.data?.label2 ? (
      <SplitLabel {...props}>{children}</SplitLabel>
    ) : (
      children
    );
    return (
      <div
        data-testid={`dropdown-${name}-option-${
          (
            props as PropsWithChildren<
              OptionProps<OptionTypeBase<number | string | Nullish>, boolean>
            > &
              Record<string, unknown>
          ).value ?? ''
        }`}
      >
        <components.Option {...props}>{actualChildren}</components.Option>
      </div>
    );
  };

export const MenuList =
  <DDOptionType extends OptionTypeBase<number | string | Nullish>>(
    name?: string
  ): FC<MenuListProps<DDOptionType, boolean>> =>
  (props) => (
    <components.MenuList {...props}>
      <div data-testid={`dropdown-${name}-options-wrapper`}>{props.children}</div>
    </components.MenuList>
  );

export const MultiValue = <DDOptionType extends OptionTypeBase<number | string | Nullish>>(
  props: MultiValueProps<DDOptionType>
): ReactElement => (
  <div
    css={css`
      margin-right: 4px;
    `}
  >
    <Tag
      onMouseDown={(e) => {
        e.stopPropagation();
        props.selectOption(props.data);
      }}
      icon={<Icon value="navigation/close" />}
      iconRight
      data-testid={`dropdown-${props.index}-tag`}
    >
      {props.children}
    </Tag>
  </div>
);

interface ValidationResult {
  valid: boolean;
  messages: ReactNode[];
}

export type DropdownComponentProps = {
  validation?: ValidationResult;
  // inline?: boolean;
};

const Topmost = styled.div`
  width: 100%;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
`;

export type DropdownComponentEnhancedProps<
  DDOptionType extends OptionTypeBase<number | string | Nullish> = OptionTypeBase<
    number | string | Nullish
  >,
  isMulti extends boolean = false,
> = ReactSelectProps<DDOptionType, isMulti> &
  DropdownComponentProps & {
    label?: ReactNode;
    required?: boolean;
    'data-testid'?: string;
  };

const DropdownBase = <
  DDOptionType extends OptionTypeBase<number | string | Nullish>,
  isMulti extends boolean = false,
>(
  props: DropdownComponentEnhancedProps<DDOptionType, isMulti>,
  ref: ForwardedRef<Select<DDOptionType, isMulti>>
): ReactElement | null => {
  const theme = useTheme();
  const [value, setValue] = useState(props.value);

  return (
    <div
      className={`${props.className ? props.className : ''} dropdownWrapper`}
      data-testid={`dropdown-${props.name ?? ''}`}
    >
      {isNotNilOrEmpty(props.label) && (
        <FormLabel data-testid={`dropdown-${props.name}-label`}>
          {props.required && '* '}
          {props.label}
        </FormLabel>
      )}
      <ReactSelect
        menuPlacement={props.menuPlacement || 'auto'}
        value={value}
        {...props}
        ref={ref}
        placeholder={props.placeholder ?? i18n.t('general.labels.select')}
        noOptionsMessage={
          typeof props.noOptionsMessage === 'function'
            ? props.noOptionsMessage
            : () => i18n.t('general.notifications.noResults')
        }
        // https://github.com/JedWatson/react-select/issues/2629
        instanceId={props.instanceId ?? props.id ?? props.name}
        options={
          props.options && props.options.length > 0
            ? props.options
            : // eslint-disable-next-line no-restricted-syntax
              ([
                {
                  key: -1,
                  label: (
                    <Topmost>
                      <Spinner />
                    </Topmost>
                  ),
                  options: [],
                },
              ] as unknown as OptionsOrGroups<DDOptionType, GroupBase<DDOptionType>>)
        }
        classNamePrefix="react-select"
        styles={{
          ...customStyles<DDOptionType, isMulti>(theme as DefaultComponentsTheme),
          ...props.styles,
        }}
        components={{
          GroupHeading,
          DropdownIndicator,
          MultiValue: MultiValue as ComponentType<MultiValueProps<DDOptionType>>,
          ClearIndicator: ClearIndicator as ComponentType<
            ClearIndicatorProps<DDOptionType, isMulti, GroupBase<DDOptionType>>
          >,
          Option: DropdownOption(props.name) as FC<OptionProps<DDOptionType, isMulti>>,
          MenuList: MenuList(props.name) as FC<MenuListProps<DDOptionType, isMulti>>,
          ...(props.components || {}),
        }}
        onChange={(val: any, action: any) => {
          if (val?.key !== -1) {
            setValue(val);
            if (props.onChange) {
              // OptionsType | OptionType passed
              props.onChange(val, action);
            }
          }
        }}
      />
      {props.validation && !props.validation.valid && (
        <FormError data-testid={`dropdown-${props.name}-error-text`}>
          {props.validation.messages.map((message: string, index: number) => (
            <span key={`${message}-${index}`}>{message}</span>
          ))}
        </FormError>
      )}
    </div>
  );
};

export const Dropdown = forwardRef(DropdownBase) as <
  DDOptionType extends OptionTypeBase<number | string | Nullish>,
  isMulti extends boolean = false,
>(
  props: DropdownComponentEnhancedProps<DDOptionType, isMulti> & {
    ref?: Ref<Select<DDOptionType, isMulti>>;
  }
) => ReactElement | null;

export type CreatableDropdownProps<
  DDOptionType extends OptionTypeBase<number | string | Nullish> = OptionTypeBase<
    number | string | Nullish
  >,
> = DropdownComponentProps &
  CreatableProps<DDOptionType, boolean, GroupBase<DDOptionType>> & {
    'data-testid'?: string;
  };

export const CreatableDropdown = <
  DDOptionType extends OptionTypeBase<number | string | Nullish> = OptionTypeBase<
    number | string | Nullish
  >,
>(
  props: CreatableDropdownProps<DDOptionType>
): ReactElement => {
  const theme = useTheme();
  const refCreatable = useRef<any>(null);

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 32) {
      e.preventDefault();

      refCreatable.current?.onInputChange?.(
        `${refCreatable.current?.state.inputValue} `,
        'set-value'
      );
    }
  };

  return (
    <div className={`${props.className} dropdownWrapper`} data-testid={props['data-testid']}>
      <Creatable<DDOptionType, boolean>
        {...props}
        styles={{
          ...customStyles<DDOptionType, boolean>(theme as DefaultComponentsTheme, props.isMulti),
          ...props.styles,
        }}
        instanceId={props.instanceId ?? props.id ?? props.name}
        components={{
          // eslint-disable-next-line no-restricted-syntax
          DropdownIndicator,
          MultiValue,
          ...(props.components || {}),
        }}
        ref={refCreatable}
        onKeyDown={handleKeyDown}
      />
      {props.validation && !props.validation.valid && (
        <FormError>
          {props.validation.messages.map((message: string, index: number) => (
            <span key={`${message}-${index}`}>{message}</span>
          ))}
        </FormError>
      )}
    </div>
  );
};

import {Dropdown, DropdownItem, IconButton, Tooltip} from 'platform/components';
import {Icon} from 'platform/foundation';
import styled from 'styled-components';

import {useMemo, useCallback, MouseEvent} from 'react';

import {ActionButtonsRendererProps} from '../types/ActionButtonsRenderer';
import {ActionDefinitionObject} from '../types/ActionDefinition';

const ActionsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin: 0 -4px;
  & > * {
    margin: 0 4px;
  }
`;

export function ActionButtonsRenderer({
  rowActionData,
  actions,
  actionCallback,
  isLoading,
}: ActionButtonsRendererProps) {
  const primaryActions = useMemo(
    () =>
      rowActionData.primary.reduce((acc, actionKey) => {
        const action = actions[actionKey];
        if (action) {
          acc[actionKey] = action;
        }
        return acc;
      }, {} as ActionDefinitionObject),
    [actions, rowActionData.primary]
  );

  const secondaryActions = useMemo(
    () =>
      rowActionData.secondary.reduce((acc, actionKey) => {
        const action = actions[actionKey];
        if (action) {
          acc[actionKey] = action;
        }
        return acc;
      }, {} as ActionDefinitionObject),
    [rowActionData.secondary, actions]
  );

  const rowClickAction = useMemo(() => {
    if (!rowActionData.rowClick) {
      return {};
    }
    return {
      [rowActionData.rowClick]: actions[rowActionData.rowClick],
    };
  }, [rowActionData.rowClick, actions]);

  // Stop event propagation from action buttons. Prevents the row from being selected.
  const handleOnClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return (
    <ActionsWrapper onClick={handleOnClick}>
      {Object.entries(primaryActions).map(([actionKey, action]) => (
        <Tooltip
          key={actionKey}
          label={action.title}
          description={action.description}
          placement="left"
        >
          {action.icon.value && (
            <IconButton
              size="small"
              aria-label={action.title}
              isDisabled={isLoading}
              onClick={() => actionCallback(actionKey)}
              data-testid={`button-action.${actionKey}`}
              icon={action.icon.value}
            />
          )}
        </Tooltip>
      ))}
      {Object.keys(secondaryActions).length > 0 && (
        <Dropdown
          data-testid="dropdown-secondaryActions"
          isLazy
          lazyBehavior="unmount"
          placement="bottom-start"
          strategy="fixed"
          boundary="scrollParent"
          dropdownControl={
            <IconButton
              icon="navigation/more_vert"
              size="small"
              isDisabled={isLoading}
              aria-label="more actions"
              data-testid="button-secondaryActions.more"
            />
          }
        >
          {Object.entries(secondaryActions).map(([actionKey, action]) => (
            <DropdownItem
              key={`$action-${actionKey}`}
              label={action.title}
              isDisabled={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                actionCallback(actionKey);
              }}
              prefix={action.icon && <Icon {...action.icon} />}
              data-testid={`menuItem-secondaryAction.${actionKey}`}
            />
          ))}
        </Dropdown>
      )}
      {Object.entries(rowClickAction).map(([actionKey, action]) => (
        <Tooltip
          key={actionKey}
          label={action?.title}
          description={action?.description}
          placement="left"
        >
          {action?.icon?.value && (
            <IconButton
              size="small"
              aria-label={action?.title ?? 'row action'}
              onClick={() => actionCallback(actionKey)}
              isDisabled={isLoading}
              data-testid={`button-rowAction.${actionKey}`}
              icon={action?.icon?.value}
            />
          )}
        </Tooltip>
      ))}
    </ActionsWrapper>
  );
}

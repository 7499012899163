import {captureException} from '@sentry/browser';
import {showNotification} from 'platform/components';

export const printPDF = (urlData: string) => {
  const url = new URL(urlData);
  const hasInlineTypeInUrl = url.searchParams.get('type') === 'inline';

  if (!hasInlineTypeInUrl) {
    url.searchParams.append('type', 'inline');
  }

  fetch(url)
    .then((res) => res.blob())
    .then((file) => {
      const fileUrl = URL.createObjectURL(file);
      const usedFrame = document.getElementById('printFrame');
      const printFrame = document.createElement('iframe');

      usedFrame?.parentNode?.removeChild(usedFrame);
      printFrame.setAttribute('id', 'printFrame');
      printFrame.setAttribute('src', fileUrl);

      if (isFirefox()) {
        printFrame.setAttribute(
          'style',
          'width: 1px; height: 100px; position: fixed; left: 0; top: 0; opacity: 0; border-width: 0; margin: 0; padding: 0'
        );
      } else {
        // Hide the iframe in other browsers
        printFrame.setAttribute(
          'style',
          'visibility: hidden; height: 0; width: 0; position: absolute; border: 0'
        );
      }

      document.body.appendChild(printFrame);
      const iframeElement = document.getElementById('printFrame') as HTMLIFrameElement;

      iframeElement.onload = () => {
        // Add a delay for Firefox. In my tests, 1000ms was sufficient but 100ms was not
        if (isFirefox() && getFirefoxMajorVersion() < 110) {
          setTimeout(() => performPrint(iframeElement, fileUrl), 1000);
        } else {
          performPrint(iframeElement, fileUrl);
        }
      };
    })
    .catch((error) => {
      console.error(error);
      showNotification.error();
      captureException(error);
    });
};

const performPrint = (iframeElement: HTMLIFrameElement, fileUrl: string) => {
  try {
    iframeElement.focus();

    if (isEdge()) {
      try {
        iframeElement.contentWindow?.document.execCommand('print', false);
      } catch {
        setTimeout(() => {
          iframeElement.contentWindow?.print();
        }, 1000);
      }
    } else {
      setTimeout(() => {
        iframeElement.contentWindow?.print();
      }, 1000);
    }
  } catch (error) {
    showNotification.error();
    captureException(error);
    console.error(error);
  } finally {
    if (isFirefox() && getFirefoxMajorVersion() < 110) {
      iframeElement.style.visibility = 'hidden';
      iframeElement.style.left = '-1px';
    }
    cleanUp(fileUrl);
  }
};

export function cleanUp(fileUrl: string) {
  const event = isChrome() || isFirefox() ? 'focus' : 'mouseover';

  const handler = () => {
    window.removeEventListener(event, handler);
    window.URL.revokeObjectURL(fileUrl);

    const iframe = document.getElementById('printFrame');
    const fileURL = iframe?.getAttribute('src');

    fileURL && window.URL.revokeObjectURL(fileURL);
    iframe &&
      setTimeout(() => {
        iframe?.remove?.();
      }, 250);
  };

  window.addEventListener(event, handler);
}

//@ts-ignore window.chrome is Chrome specific
const isChrome = () => !!window.chrome;

//@ts-ignore window.StyleMedia is Edge specific
const isEdge = () => !!window.StyleMedia;

//@ts-ignore InstallTrigger is firefox specific
const isFirefox = () => typeof InstallTrigger !== 'undefined';
const getFirefoxMajorVersion = () => {
  const userAgent = navigator.userAgent;
  const firefoxVersionRegex = /firefox\/(\S+)/;
  const match = userAgent.toLowerCase().match(firefoxVersionRegex);
  if (match) {
    //@ts-ignore
    return match[1].split('.').map((x) => parseInt(x))[0];
  }
  return 0;
};

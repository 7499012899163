import {GridApi} from 'ag-grid-community';
import {
  ButtonGroup,
  Dialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  useTranslationContext,
} from 'platform/components';
import {Box, Show, Space, Spinner, VStack} from 'platform/foundation';

import {head} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {ExportFileType} from '../../types/Api';
import {useExport} from './hooks/useExport';
import {ExportOptions} from './types/ExportOptions';

type ExportOptionsLocal = Omit<ExportOptions, 'fileType'> & {
  fileType: ExportFileType[];
};

const FILE_TYPE_OPTIONS = [
  {value: 'excel', label: 'Excel'},
  {value: 'csv', label: 'CSV'},
];

const FORM_DEFAULT_VALUES: ExportOptionsLocal = {
  fileType: ['csv'],
  columnDelimiter: 'comma',
  decimalDelimiter: 'dot',
};

export interface ExportOptionsRendererProps extends TestIdProps {
  gridApi?: GridApi;
  isOpen: boolean;
  onClose: () => void;
}

export function ExportDialog(props: ExportOptionsRendererProps) {
  const t = useTranslationContext();
  const {exportState, onExport} = useExport({
    gridApi: props.gridApi,
    onClose: props.onClose,
  });

  const DECIMAL_DELIMITERS = [
    {value: 'comma', label: t('page.dataGrid.export.decimalDelimiter.comma')},
    {value: 'dot', label: t('page.dataGrid.export.decimalDelimiter.dot')},
  ];

  const COLUMN_DELIMITERS = [
    {value: 'comma', label: t('page.dataGrid.export.columnDelimiter.comma')},
    {value: 'semicolon', label: t('page.dataGrid.export.columnDelimiter.semicolon')},
  ];
  const onHandleSubmit: FormSubmitHandler<ExportOptionsLocal> = async (values) => {
    await onExport({
      ...values,
      fileType: values.fileType[0],
    });

    return await Promise.resolve();
  };

  return (
    <Dialog
      title={t('page.datagrid.export.optionsTitle')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="small"
      data-testid={suffixTestId('exportOptionsDialog', props)}
    >
      <Form<ExportOptionsLocal> onSubmit={onHandleSubmit} defaultValues={FORM_DEFAULT_VALUES}>
        {(control, formApi) => {
          const fileType = formApi.watch('fileType');

          return (
            <>
              <VStack spacing={5}>
                <Box>
                  <VStack spacing={4}>
                    <FormField
                      control={control}
                      name="fileType"
                      type="chips"
                      options={FILE_TYPE_OPTIONS}
                      label={t('page.datagrid.exprt.fileType')}
                      data-testid={suffixTestId('exportOptionsDialog-fileType', props)}
                    />
                    <FormField
                      control={control}
                      name="decimalDelimiter"
                      isNotClearable
                      menuInPortal
                      type="choice"
                      options={DECIMAL_DELIMITERS}
                      label={t('page.datagrid.exprt.decimalSeparator')}
                      data-testid={suffixTestId('exportOptionsDialog-decimalSeparator', props)}
                    />
                    <Show when={head(fileType) === 'csv'}>
                      <FormField
                        control={control}
                        name="columnDelimiter"
                        isNotClearable
                        menuInPortal
                        type="choice"
                        options={COLUMN_DELIMITERS}
                        label={t('page.datagrid.exprt.columnSeparator')}
                        data-testid={suffixTestId('exportOptionsDialog-columnSeparator', props)}
                      />
                    </Show>
                  </VStack>
                </Box>
              </VStack>
              {exportState === 'exporting' && <Spinner variant="overlay" />}

              {exportState !== 'exporting' && (
                <>
                  <Space vertical={4} />
                  <ButtonGroup align="right">
                    <FormButton
                      control={control}
                      type="submit"
                      title={t('page.datagrid.button.export')}
                      data-testid={suffixTestId('exportOptionsDialog-submitButton', props)}
                    />
                  </ButtonGroup>
                </>
              )}
            </>
          );
        }}
      </Form>
    </Dialog>
  );
}

import {
  useAccountingGetCorrectiveTaxDocumentItemsQuery,
  useGetListCorrectiveTaxDocumentSettingQuery,
} from '@dms/api';
import {useGetCurrentBranch} from '@dms/shared';

import {useQueryState} from 'shared';

export const useGetBalanceInvoiceCorrectiveItems = (
  isBalanceInvoiceFromCheckout: boolean,
  id: string | undefined
) => {
  const [type] = useQueryState('type');
  const {activeBranchId} = useGetCurrentBranch();
  const {
    data: correctiveTaxDocumentSetting,
    isLoading: isLoadingCorrectiveTaxDocumentSetting,
    isError: isCorrectiveTaxDocumentSettingError,
  } = useGetListCorrectiveTaxDocumentSettingQuery({}, {skip: !isBalanceInvoiceFromCheckout});

  const {
    data: balanceInvoiceItemsData,
    isLoading: isLoadingBalanceInvoiceItems,
    isError: isBalanceInvoiceItemsError,
  } = useAccountingGetCorrectiveTaxDocumentItemsQuery(
    {balanceInvoiceId: id!},
    {skip: !isBalanceInvoiceFromCheckout}
  );

  const isLoadingBalanceItems =
    isLoadingBalanceInvoiceItems || isLoadingCorrectiveTaxDocumentSetting;
  const isBalanceItemsError = isBalanceInvoiceItemsError || isCorrectiveTaxDocumentSettingError;

  const activeSetting = correctiveTaxDocumentSetting?.find(
    (setting) => setting.branchId === activeBranchId
  );

  const systemSetting = correctiveTaxDocumentSetting?.find((setting) => setting.isSystem);

  const balanceInvoiceItems = balanceInvoiceItemsData?.items.filter((item) =>
    (activeSetting ?? systemSetting)?.createCorrectiveTaxDocumentType === 'without_proforma' ||
    type === 'partial'
      ? item.generatedItemType !== 'proforma_deduction'
      : true
  );

  return {
    balanceInvoiceItems,
    isLoadingBalanceItems,
    isBalanceItemsError,
  };
};

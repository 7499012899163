import {addDays, format, isBefore, parse} from 'date-fns';
import {Box, Icon} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {
  ForwardRefRenderFunction,
  KeyboardEvent,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {Nullish, parseDate} from 'shared';

import {noop} from '../../utils/someTeasUtils';
import {FormError} from '../Field/components/FormError';
import {FormLabel} from '../Field/components/FormLabel';
import {DatePickerInputContainer, Icon as DifferentIcon, StyledInput, Wrapper} from './baseStyles';
import {DEFAULT_DATE_PICKER_FORMAT} from './constants';
import {DatePickerBaseProps} from './types';

const DatePickerBaseComponent: ForwardRefRenderFunction<HTMLInputElement, DatePickerBaseProps> = (
  {
    name,
    value,
    dateFormat = DEFAULT_DATE_PICKER_FORMAT,
    inline,
    fullWidth,
    validation,
    required,
    label,
    onChange = noop,
    onBlur,
    onIconClick = noop,
    className,
    leaveOnEnter = false,
    disabled,
    disableTo,
  },
  ref
) => {
  const oldValueRef = useRef<string | Nullish>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentState, setCurrentState] = useState<string | null>('');
  const [isMounted, setIsMounted] = useState(false);

  const [invalidMessages, setInvalidMessages] = useState<ReactNode[]>([]);
  const formatDateTime = useDateTimeFormatter();

  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  useEffect(() => {
    const date = value ? parseDate(value).toISOString() : null;
    if (date !== currentState) {
      if (inputRef?.current) {
        inputRef.current.value = !value ? '' : format(parseDate(value), dateFormat);
      }
      setCurrentState(value || '');
      validateInput();
    }
  }, [value]);

  useEffect(() => {
    if (value !== currentState && isMounted) {
      onChange(isNil(currentState) ? null : parseDate(currentState));
    }
  }, [currentState]);

  useEffect(() => {
    validateInput();
  }, [validation]);

  // eslint-disable-next-line eag/no-effect-without-cleanup
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const validateInput = () => {
    const newErrors: ReactNode[] = [];

    try {
      parseInputDate().toISOString();

      if (!(parseInputDate().getFullYear() >= 1000)) {
        newErrors.push(i18n.t('general.notifications.invalidDate'));
      }

      if (disableTo && isBefore(parseInputDate(), disableTo)) {
        const to = addDays(disableTo, 1);
        newErrors.push(i18n.t(`general.labels.dateFrom ${formatDateTime('dateShort', to)}`));
      }
    } catch (error: any) {
      if (isNotNilOrEmpty(inputRef.current?.value)) {
        newErrors.push(i18n.t('general.notifications.invalidDate'));
      } else if (required) {
        newErrors.push(i18n.t('general.notifications.fieldIsRequired'));
      }
    }

    if (validation && !validation.valid) {
      newErrors.push(...validation.messages);
    }

    setInvalidMessages(newErrors);
  };

  const parseInputDate = (): Date => parse(inputRef.current?.value || '', dateFormat, new Date());

  const formatInput = (input: string, position: number) => {
    if (!inputRef.current) {
      return null;
    }
    const parts = dateFormat.split('/').map((v) => v.length);
    const inputParts = input.split('/').filter((p) => !!p);
    const newInputParts: string[] = [];
    let selection: number | undefined;

    if (input.length < (oldValueRef.current?.length ?? 0)) {
      return input;
    }

    inputParts.forEach((part, index) => {
      if (index >= parts.length) {
        return;
      }

      if (part.length === parts[index]) {
        newInputParts.push(index === parts.length - 1 ? part : `${part}/`);
      } else if (part.length > parts[index]) {
        const newPart = part.substr(part.length - parts[index], part.length);
        newInputParts.push(index === parts.length - 1 ? newPart : `${newPart}/`);
      } else {
        if (index !== parts.length - 1) {
          const leadingZeros = parts[index] - part.length;
          newInputParts.push(
            `${Array.from(Array(leadingZeros))
              .map(() => '0')
              .join('')}${part}/`
          );

          if (part.length <= parts[index]) {
            selection = position + leadingZeros;
          } else {
            selection = part.length + 1;
          }
          return;
        }
        newInputParts.push(part);
      }
    });

    const newInput = newInputParts
      .map((part, index) => {
        if (!part.endsWith('/') && index + 1 >= newInputParts.length) {
          return part;
        }

        if (!part.endsWith('/')) {
          return `${part}/`;
        }
        return part;
      })
      .join('');

    inputRef.current.value = newInput;
    if (selection !== undefined) {
      inputRef.current?.setSelectionRange(selection, selection);
    }
    return newInput;
  };

  const handleChange = () => {
    if (!inputRef.current) {
      return;
    }

    const {value, selectionStart} = inputRef.current;

    const inputValue = formatInput(value, selectionStart as number);
    oldValueRef.current = inputValue;

    validateInput();
    const parsedDate = parseInputDate();
    if (parsedDate && parsedDate.getFullYear() >= 1000) {
      setCurrentState(parsedDate.toISOString());
    } else if (value.length === 0) {
      setCurrentState(null);
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      const parsedDate = parseInputDate();

      if (parsedDate && parsedDate.getFullYear() >= 1000) {
        onBlur(parsedDate);
      } else if (inputRef.current?.value.length === 0) {
        onBlur(null);
      }
    }
  };

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  }, []);

  return (
    <DatePickerInputContainer
      data-testid={`datepicker-${name}-container`}
      className={className}
      fullWidth={!!fullWidth}
    >
      {Boolean(label) && (
        <FormLabel data-testid={`datepicker-${name}-label`}>
          {required && '* '}
          {label}
        </FormLabel>
      )}
      <Wrapper
        data-testid={`datepicker-${name}-wrapper`}
        inline={inline as boolean}
        disabled={disabled}
      >
        {!inline && (
          <DifferentIcon
            data-testid={`datepicker-${name}-icon`}
            disabled
            invalid={invalidMessages.length >= 1}
            onClick={onIconClick}
          >
            <Box paddingLeft={2} paddingRight={2}>
              <Icon value="action/calendar_today" size={4} />
            </Box>
          </DifferentIcon>
        )}
        <StyledInput
          name={name}
          data-testid={`datepicker-${name}-input`}
          fullWidth={fullWidth as boolean}
          defaultValue={!value ? '' : format(parseDate(value), dateFormat)}
          invalid={invalidMessages.length >= 1}
          inline={inline as boolean}
          ref={inputRef}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={leaveOnEnter ? handleKeyDown : noop}
          placeholder={dateFormat?.toUpperCase().replace(/\//g, '/')}
          disabled={disabled}
        />
      </Wrapper>
      {invalidMessages.length >= 1 && (
        <FormError data-testid={`datepicker-${name}-error-wrapper`}>
          {invalidMessages.map((message, index) => (
            <div key={`${message}-${index}`}>{message}</div>
          ))}
        </FormError>
      )}
    </DatePickerInputContainer>
  );
};

export const DatePickerBase = forwardRef(DatePickerBaseComponent);

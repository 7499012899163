import {useNavigate} from 'react-router-dom';

import {testIds, vehiclesRoutes} from '@dms/routes';
import {ServiceVehicleFormMode, ServiceVehicleForm, ServiceVehicleFormVariant} from '@dms/shared';

import {composePath} from 'shared';

export function NewServiceVehicle() {
  const navigate = useNavigate();

  return (
    <ServiceVehicleForm
      mode={ServiceVehicleFormMode.NEW}
      variant={ServiceVehicleFormVariant.EXTENDED}
      onDiscard={() => navigate(-1)}
      shouldRecommendVehiclesFor="service"
      onSuccess={(newVehicleId) =>
        navigate(composePath(vehiclesRoutes.detail, {params: {id: newVehicleId}}))
      }
      data-testid={testIds.vehicles.createServiceVehicle('create')}
    />
  );
}

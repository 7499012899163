import {Card, DataStatus, FormControl, Separator} from 'platform/components';
import {Box, Grid, GridItem, Show, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {VehicleRecommendationType} from '../../../../types/VehicleRecommendationType';
import {ServiceVehicleFormMode} from '../../types/ServiceVehicleFormMode';
import {ServiceVehicleFormType} from '../../types/ServiceVehicleFormType';
import {ServiceVehicleFormVariant} from '../../types/ServiceVehicleFormVariant';
import {Additional} from './components/Additional';
import {Main} from './components/Main';

interface BasicInformationProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
  variant: ServiceVehicleFormVariant;
  mode: ServiceVehicleFormMode;
  shouldRecommendVehiclesFor?: VehicleRecommendationType;
  isLoading?: boolean;
}

export function BasicInformation(props: BasicInformationProps) {
  return (
    <>
      <Show when={props.variant === ServiceVehicleFormVariant.SIMPLE}>
        <VStack spacing={4}>
          <Main
            control={props.control}
            formApi={props.formApi}
            mode={props.mode}
            data-testid={suffixTestId('main', props)}
          />
          <Separator spacing={0} />
          <Additional control={props.control} data-testid={suffixTestId('additional', props)} />
        </VStack>
      </Show>
      <Show when={props.variant === ServiceVehicleFormVariant.EXTENDED}>
        <Box padding={4}>
          <Grid columns={3}>
            <GridItem span={2}>
              <VStack spacing={4}>
                <Card>
                  <DataStatus isLoading={props.isLoading}>
                    <Main
                      control={props.control}
                      formApi={props.formApi}
                      mode={props.mode}
                      shouldRecommendVehiclesFor={props.shouldRecommendVehiclesFor}
                      data-testid={suffixTestId('main', props)}
                    />
                  </DataStatus>
                </Card>
                <Card title={i18n.t('general.labels.additionalInformation')}>
                  <DataStatus isLoading={props.isLoading}>
                    <Additional
                      control={props.control}
                      data-testid={suffixTestId('additional', props)}
                    />
                  </DataStatus>
                </Card>
              </VStack>
            </GridItem>
          </Grid>
        </Box>
      </Show>
    </>
  );
}

import {CustomFilterProps, useGridFilter} from 'ag-grid-react';
import {Chips, Separator, TextInput, useTranslationContext} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useState} from 'react';

import {intersection} from 'ramda';
import {isEmptyArray} from 'ramda-adjunct';

import {suffixTestId, TestIdProps, useDebouncedCallback} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {QuickFilters} from '../../types/Api';

export type TextFilterProps = {
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function TextFilter(props: TextFilterProps) {
  const t = useTranslationContext();

  const [filterValue, setFilterValue] = useState<string | null>(props.model);

  const setValue = (valueToSet: string | null) => {
    setFilterValue(valueToSet);
    updateModel(valueToSet);
  };

  const updateModel = useDebouncedCallback(
    (model: string | null) => props.onModelChange(model),
    300
  );

  useGridFilter({
    doesFilterPass: () => true,
    afterGuiAttached: () => setFilterValue(props.model),
  });

  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue: setValue,
    defaultValue: null,
  });

  const onChange = (changeValue: string | null) => {
    setValue(changeValue);
  };

  const isQuickFilterValue = intersection([filterValue], quickFiltersConst) as string[];

  return (
    <VStack>
      {props.quickFilters && props.quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={props.isDisabled}
              value={isQuickFilterValue}
              options={props.quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('text-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <TextInput
        placeholder={t('page.datagrid.filter.textPlaceholder', {
          field: props.column.getColDef().headerName,
        })}
        value={isEmptyArray(isQuickFilterValue) ? filterValue : null}
        onChange={onChange}
        isDisabled={props.isDisabled}
        data-testid={suffixTestId('text-filter', props)}
      />
    </VStack>
  );
}

import {
  Checkbox as PlatformCheckbox,
  NumberInput,
  TableRow,
  TableRowActionsData,
  TextInput,
} from 'platform/components';
import {Align, Box, Hide, HStack, Show, Text} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {useEffect, useRef, useState} from 'react';

import {isNil} from 'ramda';

import {CostDetailItemResponseBody} from '@dms/api';
import {i18n} from '@dms/i18n';

export type CostsData = {title: string; cost: number | null; note: string | null; enabled: boolean};

interface CostsRowProps {
  row: CostDetailItemResponseBody;
  isSaving?: boolean;
  onUpdate: (row: CostDetailItemResponseBody, data: CostsData) => void;
  onDelete: (row: CostDetailItemResponseBody) => void;
}

export function CostsRow({row, isSaving, onUpdate, onDelete}: CostsRowProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState<string | null>(row.title);
  const [cost, setCost] = useState<number | null>(row.cost);
  const [note, setNote] = useState<string | null>(row.note);
  const titleInputRef = useRef<HTMLInputElement>(null);
  const formatNumber = useFormatNumber();

  useEffect(() => {
    if (isEditing) {
      titleInputRef.current?.focus();
    }
  }, [isEditing]);

  const handleStartEditing = () => {
    setTitle(row.title);
    setCost(row.cost);
    setNote(row.note);
    setIsEditing(true);
  };

  const handleSaveChanges = () => {
    onUpdate(row, {
      title: title ?? '',
      cost,
      note,
      enabled: row.enabled,
    });
    setIsEditing(false);
  };

  const handleCheckboxChange = (value: boolean) => {
    onUpdate(row, {
      title: row.title,
      cost: row.cost,
      note: row.note,
      enabled: value,
    });
  };

  const tableActions: TableRowActionsData = {
    primary: [
      isEditing
        ? {
            title: i18n.t('general.labels.saveChanges'),
            icon: 'action/done',
            isDisabled: isSaving || isNil(title),
            onClick: handleSaveChanges,
          }
        : {
            title: i18n.t('general.labels.edit'),
            icon: 'content/create',
            isDisabled: isSaving,
            onClick: handleStartEditing,
          },
      {
        title: i18n.t('general.labels.delete'),
        icon: 'action/delete',
        isDisabled: row.type !== 'other' || isSaving,
        onClick: () => onDelete(row),
      },
    ],
  };

  return (
    <TableRow actions={tableActions}>
      <Box padding={1} paddingLeft={2} minWidth={24} maxWidth={80}>
        <HStack spacing={2} align="center">
          <PlatformCheckbox value={row.enabled} onChange={handleCheckboxChange} />
          <Show when={isEditing}>
            <Box flex={1}>
              <TextInput
                value={title}
                onChange={setTitle}
                isDisabled={isSaving}
                errorMessage={
                  isNil(title)
                    ? i18n.t('general.errors.required', {
                        label: i18n.t('entity.vehicle.labels.item'),
                      })
                    : undefined
                }
                ref={titleInputRef}
              />
            </Box>
          </Show>
          <Hide when={isEditing}>
            <Text color={row.enabled ? undefined : 'secondary'} noWrap>
              {row.title}
            </Text>
          </Hide>
        </HStack>
      </Box>
      <Box padding={1}>
        <Show when={isEditing}>
          <NumberInput value={cost} onChange={setCost} isDisabled={isSaving} />
        </Show>
        <Hide when={isEditing}>
          <Align right>
            <Text color={row.enabled ? undefined : 'secondary'} noWrap>
              {formatNumber(row.cost)}
            </Text>
          </Align>
        </Hide>
      </Box>
      <Box padding={1} paddingRight={2} minWidth={24} maxWidth={80}>
        <Show when={isEditing}>
          <TextInput value={note} onChange={setNote} isDisabled={isSaving} />
        </Show>
        <Hide when={isEditing}>
          <Text color={row.enabled ? undefined : 'secondary'} noWrap>
            {row.note}
          </Text>
        </Hide>
      </Box>
    </TableRow>
  );
}

import {showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import {useDeleteSupplierOrderMutation} from '@dms/api';
import {i18n} from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../components/FullscreenDataGridWrapper';

export function SupplierOrderList() {
  const navigate = useNavigate();

  const [deleteSupplierOrder] = useDeleteSupplierOrderMutation();

  const actionCallback: ActionCallback = async ({
    actionKey,
    rowId,
    sourceSearchParams,
    refreshData,
  }) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    await match(actionKey)
      .with('redirectDetail', 'edit', () => {
        navigate(
          composePath(warehouseRoutes.supplierOrderDetailOverview, {
            queryParams: sourceSearchParams,
            params: {id},
            isSearchParamsPreserved: false,
          })
        );
      })
      .with('delete', async () => {
        const orderId = isArray(rowId) ? rowId : [rowId];

        await deleteSupplierOrder({body: {orderId}})
          .unwrap()
          .then(() => showNotification.success(i18n.t('general.notifications.successfullyDeleted')))
          .then(refreshData)
          .catch(handleApiError);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        gridCode="warehouse-supplier-orders"
        actionCallback={actionCallback}
        data-testid={testIds.warehouse.supplierOrderList('supplierOrderList')}
      />
    </FullscreenDataGridWrapper>
  );
}

import {
  Alert,
  Attributes,
  AttributesRow,
  Button,
  ButtonGroup,
  ButtonProps,
  Card,
  getFilePreviewUrl,
  openDialog,
  Segment,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Hide, HStack, Link, Show, VStack} from 'platform/foundation';

import {useState} from 'react';

import {join, pipe, reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  OrderResponseBody,
  useCancelCorrectiveTaxDocumentMutation,
  useGetBusinessCaseQuery,
  useGetCorrectiveTaxDocumentV5Query,
  useGetSaleVehicleFromSaleVehicleIdQuery,
  useRenderCorrectiveTaxDocumentMutation,
  useShowPaymentPrescriptionQuery,
} from '@dms/api';
import {i18n} from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError, InvoicePaymentForm, usePermissions} from '@dms/shared';

import {buildArray, openFile, suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {useGenerateInternalInvoice} from '../PaymentList/hooks/useGenerateInternalInvoice';
import {getPaymentMethodOptions} from '../PaymentList/hooks/useGetPaymentDetailAttributes';
import {useIsStandardInvoiceDisabled} from '../PaymentList/hooks/useIsStandardInvoiceDisabled';
import {isSegmentValue} from '../PaymentList/utils/isSegmentValue';
import {PrescriptionIdPriceBox} from '../PrescriptionIdPriceBox';
import {CorrectiveTaxDocumentPaymentList} from './components/CorrectiveTaxDocumentPaymentList';

interface CorrectiveTaxDocumentPaymentProps extends TestIdProps {
  correctiveTaxDocumentId: string;
  order: OrderResponseBody;
  paymentId: string;
  checkoutId: string;
}

const segmentOptions = [
  {value: 'detail', label: i18n.t('general.labels.detail')},
  {value: 'payments', label: i18n.t('page.accounting.invoiceDetailPayments.title')},
];
type PaymentSegmentValue = 'payments' | 'detail';

export function CorrectiveTaxDocumentPayment(props: CorrectiveTaxDocumentPaymentProps) {
  const {id} = useRequiredParams();
  const {data: businessCaseData} = useGetBusinessCaseQuery({businessCaseId: id});
  const {data: saleVehicleData} = useGetSaleVehicleFromSaleVehicleIdQuery(
    {vehicleId: businessCaseData?.saleVehicleId ?? ''},
    {skip: isNilOrEmpty(businessCaseData?.saleVehicleId)}
  );
  const [renderCorrectiveTaxDocument] = useRenderCorrectiveTaxDocumentMutation();
  const [cancelCorrectiveTaxDocument, {isLoading: isCancelingCorrectiveTaxDocument}] =
    useCancelCorrectiveTaxDocumentMutation();
  const [segmentValue, setSegmentValue] = useState<PaymentSegmentValue>('detail');
  const {data: correctiveTaxDocument, refetch} = useGetCorrectiveTaxDocumentV5Query({
    correctiveTaxDocumentId: props.correctiveTaxDocumentId,
  });

  const {generateInternalCorrectiveTaxDocument, isLoading: isGeneratingInternalDocument} =
    useGenerateInternalInvoice(
      props.order,
      props.paymentId,
      props.checkoutId,
      props.correctiveTaxDocumentId
    );
  const [shouldHideStandardInvoice] = useIsStandardInvoiceDisabled(props.order, id);

  const [canReadDocuments] = usePermissions({
    permissionKeys: ['readDocuments'],
  });

  const {data: prescription} = useShowPaymentPrescriptionQuery(
    {paymentPrescriptionId: correctiveTaxDocument?.paymentPrescriptionId ?? ''},
    {skip: isNilOrEmpty(correctiveTaxDocument?.paymentPrescriptionId)}
  );

  const handleUpdateSegment = (val: string) => {
    if (!isSegmentValue(val)) {
      return;
    }

    setSegmentValue(val);
  };

  const isForTaxDocumentForPayment =
    correctiveTaxDocument?.invoicingDocumentType === 'tax_document_for_payment';
  const paymentMethod = getPaymentMethodOptions().find(
    (option) => option.value === correctiveTaxDocument?.paymentInfo.paymentMethod
  )?.label;

  const bankAccount = pipe(
    reject(isNilOrEmpty),
    join(', ')
  )([correctiveTaxDocument?.paymentInfo.bankAccount, correctiveTaxDocument?.paymentInfo.bankName]);

  const handleOpenCorrectiveTaxDocument = (isMarginPrint: boolean) =>
    renderCorrectiveTaxDocument({
      renderCorrectiveTaxDocumentRequestBody: {
        documentId: props.correctiveTaxDocumentId,
        isMarginPrint,
      },
    })
      .unwrap()
      .then((res) => res.pdfUrl)
      .then(openFile)
      .catch(handleApiError);

  const handleCancelCorrectiveTaxDocument = () =>
    cancelCorrectiveTaxDocument({correctiveTaxDocumentId: props.correctiveTaxDocumentId})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handlePayment = () =>
    openDialog(
      <InvoicePaymentForm
        correctiveTaxDocumentId={props.correctiveTaxDocumentId}
        onAfterSubmit={() => refetch()}
        data-testid={testIds.businessCase.checkout('paymentDialog')}
      />,
      {
        title: i18n.t('entity.invoice.labels.paymentOfCorrectiveTaxDocument', {
          number: correctiveTaxDocument?.number,
        }),
        'data-testid': testIds.businessCase.checkout('paymentDialog'),
        scrollBehavior: 'outside',
      }
    );

  const isWithoutTransaction = isNilOrEmpty(prescription?.transactions);
  const isCorrectiveTaxDocumentMargin = correctiveTaxDocument?.items.some(
    (item) => item.type === 'margin'
  );
  const correctPayment = props.order.payments.find((payment) => payment.id === props.paymentId);
  const internalCorrectiveTaxDocuments = correctPayment?.internalCorrectiveTaxDocuments;

  const detailRows = buildArray<AttributesRow>([
    {
      label: i18n.t('entity.checkout.labels.paymentMethod'),
      value: paymentMethod,
    },
    {
      label: i18n.t('entity.checkout.labels.issuedOn'),
      value: correctiveTaxDocument?.issueDate,
    },
    {
      label: i18n.t('entity.checkout.labels.dueDate'),
      value: correctiveTaxDocument?.dueDate,
    },
    {
      label: i18n.t('entity.customer.labels.bankAccount'),
      value: bankAccount,
    },
    {
      label: i18n.t('entity.checkout.labels.note'),
      value: correctiveTaxDocument?.behindItemsNote,
    },
    {
      label: i18n.t('entity.checkout.labels.correctiveTaxDocument'),
      content: (
        <HStack justify="space-between">
          <Link
            size="small"
            onClick={() => handleOpenCorrectiveTaxDocument(false)}
            title={correctiveTaxDocument?.number}
            isDisabled={shouldHideStandardInvoice || !canReadDocuments}
            data-testid={suffixTestId('invoice', props)}
          />

          <Hide
            when={
              !isWithoutTransaction || (isCorrectiveTaxDocumentMargin && shouldHideStandardInvoice)
            }
          >
            <Button
              size="small"
              isLoading={isCancelingCorrectiveTaxDocument}
              variant="dangerLink"
              title={i18n.t('entity.invoice.actions.cancelInvoice')}
              onClick={handleCancelCorrectiveTaxDocument}
              data-testid={suffixTestId('cancel-invoice', props)}
            />
          </Hide>
        </HStack>
      ),
    },
  ]).when(isCorrectiveTaxDocumentMargin, {
    label: i18n.t('entity.checkout.labels.correctiveTaxDocument'),
    content: (
      <HStack justify="space-between">
        <Link
          size="small"
          onClick={() => handleOpenCorrectiveTaxDocument(true)}
          isDisabled={!canReadDocuments}
          title={`m_${correctiveTaxDocument?.number}`}
          data-testid={suffixTestId('margin-invoice', props)}
        />

        <Show when={isWithoutTransaction && shouldHideStandardInvoice}>
          <Button
            size="small"
            isDisabled={!canReadDocuments}
            isLoading={isCancelingCorrectiveTaxDocument}
            variant="dangerLink"
            title={i18n.t('entity.invoice.actions.cancelInvoice')}
            onClick={handleCancelCorrectiveTaxDocument}
            data-testid={suffixTestId('cancel-invoice', props)}
          />
        </Show>
      </HStack>
    ),
  });

  const internalCorrectiveTaxDocumentsRows = internalCorrectiveTaxDocuments?.map(
    (document, index) => ({
      label: i18n.t('entity.checkout.labels.internalInvoiceDocument'),
      content: (
        <Link
          size="small"
          isDisabled={!canReadDocuments}
          onClick={() => openFile(getFilePreviewUrl(document.fileUri))}
          title={document.name}
          data-testid={testIds.businessCase.checkout(
            `paymentAttributes-internalInvoice-[${index}]`
          )}
        />
      ),
      testId: 'internalInvoiceDocument',
    })
  );

  if (correctiveTaxDocument?.paymentState === 'canceled' || isForTaxDocumentForPayment) {
    return null;
  }

  return (
    <Card variant="inlineGrey">
      <VStack spacing={4}>
        <Show when={prescription?.state === 'completed'}>
          <Alert
            message={i18n.t('entity.checkout.labels.allPaymentsPaid')}
            variant="success"
            data-testid={suffixTestId('alert-allPaymentsPaid', props)}
          />
        </Show>

        <PrescriptionIdPriceBox
          prescriptionId={correctiveTaxDocument?.paymentPrescriptionId}
          isCorrectiveTaxDocument
        />
        <Separator spacing={0} />
        <Box width={73}>
          <Segment value={segmentValue} options={segmentOptions} onChange={handleUpdateSegment} />
        </Box>

        <Show when={segmentValue === 'detail'}>
          <Attributes
            size="quarter"
            rows={[...detailRows, ...(internalCorrectiveTaxDocumentsRows || [])]}
            data-testid={suffixTestId('detail', props)}
          />
        </Show>

        <Show when={segmentValue === 'payments'}>
          <CorrectiveTaxDocumentPaymentList
            correctiveTaxDocumentId={props.correctiveTaxDocumentId}
          />
        </Show>

        <ButtonGroup
          align="right"
          buttons={buildArray<ButtonProps>()
            .whenNot(
              saleVehicleData?.isVatDeductible ||
                businessCaseData?.businessCaseInternalType === 'PURCHASE_BROKERAGE',
              {
                title: i18n.t('entity.checkout.actions.generateInternalInvoice'),
                isDisabled: !canReadDocuments,
                onClick: generateInternalCorrectiveTaxDocument,
                variant: 'outlined',
                isLoading: isGeneratingInternalDocument,
              }
            )
            .when(prescription?.state !== 'completed', {
              title: i18n.t('entity.order.actions.pay'),
              onClick: handlePayment,
            })}
        />
      </VStack>
    </Card>
  );
}

import {Tooltip, ColorSchemeType, Flag} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {useMemo, useState, forwardRef, Ref, useImperativeHandle} from 'react';

import {isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRenderer, ICellRendererParams} from '../../types/AgGridTypes';
import {TagPropsGetter} from '../../types/TagPropsGetter';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getItemTooltipProps} from './utils/getItemTooltipProps';

export interface TagCellRendererProps extends ICellRendererParams {
  getTagProps?: TagPropsGetter;
  getTooltipProps?: TooltipGetter;
}

export type ValueProps = {color: ColorSchemeType; label: string};

function TagCellRendererComponent(
  {getValue, value, data, node, getTagProps, getTooltipProps, context}: TagCellRendererProps,
  ref: Ref<ICellRenderer>
) {
  const [cellValue, setCellValue] = useState<ValueProps | ValueProps[]>(() =>
    getValue ? getValue() : value
  );

  const isWrapped = context.settings.valueWrapping === 'WRAP';
  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  useImperativeHandle(ref, () => ({
    refresh: ({getValue: getNewValue, value: newValue}) => {
      const newCellValue = getNewValue ? getNewValue() : newValue;
      if (newCellValue !== newValue) {
        setCellValue(newCellValue);
      }
      return true;
    },
  }));

  const flagRendererProps = useMemo(() => {
    if (getTagProps && data) {
      return getTagProps({data});
    }
    return {};
  }, [getTagProps, data]);

  if (isNil(cellValue)) {
    return null;
  }

  if (!isArray(cellValue)) {
    // @ts-ignore
    const flagColor = cellValue?.style?.flagColor;
    // @ts-ignore
    const flagIsSubtle = cellValue?.style?.flagSubtle;
    // @ts-ignore
    const flagLabel = cellValueItem?.label;

    return (
      <Tooltip {...tooltipProps}>
        <Flag
          {...flagRendererProps}
          colorScheme={flagColor}
          isSubtle={flagIsSubtle}
          label={flagLabel}
          data-testid={`tag-cell-renderer-${flagLabel}`}
        />
      </Tooltip>
    );
  }

  return (
    <Box paddingVertical={1} paddingRight={1}>
      <HStack spacing={1} wrap={isWrapped}>
        {cellValue.map((cellValueItem, index) => {
          // @ts-ignore
          const flagColor = cellValueItem?.style?.flagColor;
          // @ts-ignore
          const flagIsSubtle = cellValueItem?.style?.flagSubtle;
          const flagLabel = cellValueItem?.label;

          return (
            <Tooltip {...getItemTooltipProps(index, tooltipProps)} key={index}>
              <Flag
                {...flagRendererProps}
                colorScheme={flagColor}
                isSubtle={flagIsSubtle}
                label={flagLabel}
                data-testid={`tag-cell-renderer-${cellValueItem}`}
              />
            </Tooltip>
          );
        })}
      </HStack>
    </Box>
  );
}

export const TagCellRenderer = forwardRef<ICellRenderer, TagCellRendererProps>(
  TagCellRendererComponent
);

import {memo, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {equals} from 'ramda';

import {
  FeaturesPicker,
  permanentVehicleType,
  selectFeatureCategories,
  selectVehicleFeatures,
  VehicleCatalogueEnumOption,
} from '@dms/teas';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useFilters} from '../../../FiltersContext/FiltersContext';

export function FilterFeatures(props: TestIdProps) {
  const {filters, onUpdateFilters} = useFilters();
  const {features: selectedFeatures} = filters;

  const featureCategoriesSelector = useMemo(
    () => selectFeatureCategories(permanentVehicleType),
    []
  );
  const featuresSelector = useMemo(() => selectVehicleFeatures(permanentVehicleType), []);

  const featureCategories = useSelector(featureCategoriesSelector);
  const features = useSelector(featuresSelector);

  return (
    <MemoizedFeaturesPicker
      featureCategories={featureCategories}
      features={features}
      selectedFeatures={selectedFeatures || []}
      onFeatureSelect={(selected) => onUpdateFilters(['features'], selected)}
      data-testid={props['data-testid']}
    />
  );
}

interface MemoizedFeaturesPickerProps extends TestIdProps {
  featureCategories: VehicleCatalogueEnumOption[];
  features: VehicleCatalogueEnumOption[];
  selectedFeatures: string[] | Nullish;
  onFeatureSelect: (selected: string[]) => void;
}

const MemoizedFeaturesPicker = memo(
  (props: MemoizedFeaturesPickerProps) => (
    <FeaturesPicker
      categories={props.featureCategories}
      features={props.features}
      selected={props.selectedFeatures}
      onFeatureSelect={props.onFeatureSelect}
      allowSubItemMultiselect
      data-testid={suffixTestId('features', props)}
    />
  ),
  (prevProps, nextProps) =>
    prevProps.featureCategories === nextProps.featureCategories &&
    prevProps.features === nextProps.features &&
    equals(prevProps.selectedFeatures, nextProps.selectedFeatures)
);

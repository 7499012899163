import {motion} from 'framer-motion';
import {IconButton} from 'platform/components';

interface ValidationErrorIconProps {
  onClick: () => void;
}

export function ValidationErrorIcon(props: ValidationErrorIconProps) {
  return (
    <motion.div
      initial={{scale: 0.75}}
      animate={{scale: [0.75, 1.25, 1]}}
      transition={{duration: 0.5, ease: 'easeOut'}}
    >
      <IconButton icon="alert/warning" severity="danger" onClick={props.onClick} />
    </motion.div>
  );
}

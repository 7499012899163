import {CustomFilterProps} from 'ag-grid-react';
import {isDate} from 'date-fns';
import {Chips, DatePicker, Separator, useTranslationContext} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useCallback, useMemo, useRef} from 'react';

import {intersection} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {QuickFilters} from '../../types/Api';
import {parseDateFilter} from '../../utils/parseDateFilter';

type RelativeInputType = {relativeInputValue: number; relativeInputKey: string};

export type DateFilterProps = {
  min: string | null;
  max: string | null;
  relativeDates: Array<{value: string; label: string}>;
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function DateFilter(props: DateFilterProps) {
  const t = useTranslationContext();
  const minDate = useMemo(() => parseDateFilter(props.min), [props.min]);
  const maxDate = useMemo(() => parseDateFilter(props.max), [props.max]);

  const isActive = useRef<boolean>(false);

  const setFilterValue = useCallback((value: RelativeInputType | Date | string | null) => {
    isActive.current = value !== null;
    props.onModelChange(value);
  }, []);

  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue,
    defaultValue: null,
  });

  const [datePickerValue, chipsValue] = useMemo(
    () => [
      /**
       * @about filterValue as Date
       * isDate function is not typed correctly in date-fns
       * It's missing the `value is Date` type guard
       * https://github.com/date-fns/date-fns/issues/1907
       */
      isDate(props.model) ? (props.model as Date) : null,
      typeof props.model === 'string' ? [props.model] : [],
    ],
    [props.model]
  );

  const onDateChange = useCallback((value: Date | null) => {
    setFilterValue(value ?? null);
  }, []);

  const onInputChange = useCallback((relativeInputValue: number, relativeInputKey: string) => {
    if (isNaN(relativeInputValue)) {
      setFilterValue(null);
    } else {
      setFilterValue({relativeInputValue, relativeInputKey});
    }
  }, []);

  const isQuickFilterValue = intersection([props.model], quickFiltersConst) as string[];

  return (
    <VStack spacing={4}>
      {props.quickFilters && props.quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={props.isDisabled}
              value={isQuickFilterValue}
              options={props.quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('date-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator spacing={0} />
        </>
      )}
      {props.relativeDates && props.relativeDates.length > 0 && (
        <Chips
          isDisabled={props.isDisabled}
          value={chipsValue}
          options={props.relativeDates}
          onChange={onChipsChange}
          data-testid={suffixTestId('date-filter-relativeDates', props)}
          isDeselectable
        />
      )}
      <DatePicker
        isDisabled={props.isDisabled}
        placeholder={t('page.datagrid.filter.datePlaceholder', {
          field: props.column.getColDef().headerName,
        })}
        value={datePickerValue}
        onChange={onDateChange}
        minDate={minDate}
        maxDate={maxDate}
        data-testid={suffixTestId('date-filter', props)}
      />
    </VStack>
  );
}

import {useState} from 'react';

import {createContext} from 'shared';

type DocumentSelectionContextType = {
  activeDocumentId: string | null;
  setActiveDocumentId: (fileId: string | null) => void;
  documentValidationState: string[];
  clearDocumentValidationState: (fileId: string) => void;
  clearAllDocumentsValidationState: () => void;
  setDocumentsValidationState: (args: string[]) => void;
};

export const [Provider, useDocumentValidationState] = createContext<DocumentSelectionContextType>();

type DocumentStateProps = {
  children: React.ReactNode;
};

export const DocumentStateProvider = (props: DocumentStateProps) => {
  const [activeDocumentId, setActiveDocumentId] = useState<string | null>(null);
  const [documentValidationState, setDocumentsValidationState] = useState<
    DocumentSelectionContextType['documentValidationState']
  >([]);

  const clearDocumentValidationState: DocumentSelectionContextType['clearDocumentValidationState'] =
    (fileId) => setDocumentsValidationState((prevState) => prevState.filter((id) => id !== fileId));

  const clearAllDocumentsValidationState: DocumentSelectionContextType['clearAllDocumentsValidationState'] =
    () => setDocumentsValidationState([]);

  return (
    <Provider
      value={{
        activeDocumentId,
        setActiveDocumentId,
        documentValidationState,
        clearDocumentValidationState,
        setDocumentsValidationState,
        clearAllDocumentsValidationState,
      }}
    >
      {props.children}
    </Provider>
  );
};

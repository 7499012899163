import {GetMainMenuItems, GridApi} from '../types/AgGridTypes';
import {TransformedDefinitionApiResponse} from '../types/Api';
import {TranslationMap} from '../types/TranslationMap';

type GetMainMenuItemsProps = {
  onResetColumns: () => void;
  translationMap?: TranslationMap;
  gridApi?: GridApi;
  definition: TransformedDefinitionApiResponse;
};

/**
 * Returns function that is passed to AG-grid as getMainMenuItems property
 * RESOURCES:
 * - https://www.ag-grid.com/react-data-grid/grid-callbacks/#reference-menus-getMainMenuItems
 * - https://www.ag-grid.com/react-data-grid/column-menu/#customising-the-general-menu-tab
 */
export const getMainMenuItems =
  ({translationMap, gridApi, onResetColumns, definition}: GetMainMenuItemsProps) =>
  () => {
    const items: ReturnType<GetMainMenuItems> = [];
    if (definition.behavior.columnPinningAllowed) {
      items.push('pinSubMenu');
    }
    if (definition.behavior.columnAutoSizingAllowed) {
      items.push('autoSizeThis', 'autoSizeAll');
    }
    if (definition.behavior.fitToScreenAllowed) {
      items.push({
        name:
          translationMap && translationMap.fitToScreen
            ? translationMap.fitToScreen
            : 'Fit Columns to Screen',
        action: () => gridApi?.sizeColumnsToFit(),
      });
    }
    items.push(
      'separator',
      {
        name:
          translationMap && translationMap.resetColumns
            ? translationMap.resetColumns
            : 'Reset columns',
        action: onResetColumns,
      },
      {
        name:
          translationMap && translationMap.resetAllFilters
            ? translationMap.resetAllFilters
            : 'Reset filters',
        action: () => gridApi?.setFilterModel({}),
      }
    );
    return items;
  };

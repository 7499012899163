import {startOfToday} from 'date-fns';
import {z} from 'zod';

import {defaultTo} from 'ramda';

import i18n from '@dms/i18n';

import {precisionCalculation} from 'shared';

export const reservationFormSchema = (isNoteRequired: boolean, dispensingUnit: number) =>
  z.object({
    quantity: z
      .number()
      .min(0.0001)
      .refine((value) => precisionCalculation.modulo(defaultTo(0, value), dispensingUnit) === 0, {
        message: `${i18n.t(
          'entity.warehouse.labels.quantityMustBeMultipleOfDispensingUnit'
        )} (${dispensingUnit})`,
      }),
    requestExpiresAtDate: z
      .date()
      .min(startOfToday(), i18n.t('entity.warehouse.notifications.dateCantBeSetToPast'))
      .nullish(),
    requestExpiresAtTime: z.string().nullish(),
    note: isNoteRequired ? z.string() : z.string().nullish(),
  });

export type ReservationForm = z.infer<ReturnType<typeof reservationFormSchema>>;

import {Action, Actions, Attributes, AttributesRow, Card} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';
import {formatPhoneNumber} from 'platform/locale';

import {useLocation} from 'react-router-dom';

import {always, defaultTo, find, head, ifElse, isNil, pipe, startsWith} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {
  EntityResourceIds,
  PersonResponseBodyV2,
  PhoneNumberResponseBody,
  useGetBusinessCaseQuery,
  useGetCustomerV2Query,
  useGetParticipationQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {businessCaseRoutes, customerRoutes} from '@dms/routes';

import {buildArray, composePath, Nullish} from 'shared';

import {EMPTY_PLACEHOLDER} from '../../../constants/placeholders';
import {useAddress} from '../../../hooks/useAddress';
import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import {getCustomerName} from '../../../utils/getCustomerName';

interface ReservationCustomerDetailProps {
  customerId: string;
  businessCaseId?: string | Nullish;
}

export function ReservationCustomerDetail(props: ReservationCustomerDetailProps) {
  const {pathname} = useLocation();

  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: props.businessCaseId ?? ''},
    {skip: isNilOrEmpty(props.businessCaseId)}
  );
  const {data: customer} = useGetCustomerV2Query({customerId: props.customerId});

  const isBusinessCasePage = startsWith('/business-case/', pathname);

  const contactPerson = findContactPerson(businessCase?.contactPersonId)(customer?.contacts);

  const {composeAddress} = useAddress();

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: props.businessCaseId ?? '',
    },
    {skip: isNil(props.businessCaseId)}
  );

  const [canReadBusinessCaseSelling] = usePermissions({
    permissionKeys: ['viewBusinessCaseSelling'],
    scopes: {
      viewBusinessCaseSelling: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
    },
  });

  const actions = buildArray<Action>()
    .whenNot(isBusinessCasePage, {
      leftIcon: 'action/open_in_new',
      type: 'button',
      title: i18n.t('entity.customer.labels.customer'),
      variant: 'ghostLink',
      onClick: () =>
        window.open(composePath(customerRoutes.detail, {params: {id: props.customerId}}), '_blank'),
    })
    .when(!isBusinessCasePage && isNotNil(props.businessCaseId) && canReadBusinessCaseSelling, {
      leftIcon: 'action/open_in_new',
      type: 'button',
      title: i18n.t('page.businessCase.labels.businessCase'),
      variant: 'ghostLink',
      onClick: () =>
        window.open(
          composePath(businessCaseRoutes.overview, {params: {id: props.businessCaseId}}),
          '_blank'
        ),
    });

  const attributesRows: AttributesRow[] = [
    {
      label: i18n.t('entity.address.labels.address'),
      value: composeAddress(head(customer?.addresses ?? [])?.address) || EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('general.labels.phone'),
      value: pipe(
        (person: PersonResponseBodyV2 | Nullish) => person?.phoneNumbers?.[0],
        ifElse(
          (phoneNumber: PhoneNumberResponseBody | undefined) => isNilOrEmpty(phoneNumber?.number),
          always(i18n.t('entity.customer.labels.noPhoneNumber')),
          (phoneNumber) =>
            formatPhoneNumber(`${phoneNumber?.prefix}${phoneNumber?.number}`) ?? undefined
        )
      )(contactPerson ?? customer?.foundingPerson),
    },
    {
      label: i18n.t('general.labels.email'),
      value: contactPerson
        ? contactPerson?.emails?.[0]?.email
        : customer?.foundingPerson?.emails?.[0]?.email,
    },
  ];

  return (
    <Card variant="inlineGrey">
      <VStack spacing={4}>
        <Heading size={4}>{getCustomerName(customer)}</Heading>
        <Actions actions={actions} data-testid="reservationCustomerDetailsActions" />
        <Attributes rows={attributesRows} data-testid="reservationCustomerDetailsAttributes" />
      </VStack>
    </Card>
  );
}

const findContactPerson = (id: string | Nullish) =>
  pipe(
    defaultTo([]),
    find((contact: PersonResponseBodyV2) => contact.id === id)
  );

import {RowSelectionOptions} from 'ag-grid-community';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {TransformedDefinitionApiResponse} from '../types/Api';

export const getRowSelection = (definition: TransformedDefinitionApiResponse) =>
  match<
    TransformedDefinitionApiResponse['behavior']['rowSelectMode'],
    RowSelectionOptions | undefined
  >(definition.behavior.rowSelectMode)
    .with('SINGLE', always({mode: 'multiRow', checkboxes: false, headerCheckbox: false}))
    .with('MULTIPLE', always({mode: 'multiRow', checkboxes: false, headerCheckbox: false}))
    .otherwise(always(undefined));

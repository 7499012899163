import {BaseFlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {isNil} from 'ramda';

import {CorrectiveTaxDocumentTypeEnum, CorrectiveTaxDocumentV5ResponseBodyRead} from '@dms/api';
import {i18n} from '@dms/i18n';

import {getDocumentSourceFlags} from './getDocumentSourceFlags';

export const getCorrectiveTaxDocumentSubFlags = (
  correctiveTaxDocument: CorrectiveTaxDocumentV5ResponseBodyRead | undefined
) => {
  if (isNil(correctiveTaxDocument?.correctiveTaxDocumentType)) {
    return [];
  }

  return [
    ...getDocumentSourceFlags(correctiveTaxDocument?.documentSource),
    ...[
      match<CorrectiveTaxDocumentTypeEnum, BaseFlagProps>(
        correctiveTaxDocument.correctiveTaxDocumentType
      )
        .with('full-correction', () => ({
          colorScheme: 'blue',
          label: i18n.t('entity.correctiveTaxDocument.labels.full'),
        }))
        .with('partial-correction', () => ({
          colorScheme: 'blue',
          label: i18n.t('entity.correctiveTaxDocument.labels.partial'),
        }))
        .exhaustive(),
    ],
  ];
};

import {Button, ButtonGroup} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

type SetNewVehicleDialogProps = {
  isLoading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export function SetNewVehicleDialog({onCancel, onConfirm, isLoading}: SetNewVehicleDialogProps) {
  return (
    <VStack spacing={1}>
      <Text>{i18n.t('entity.salePreset.setNewVehicleDialog.description')}</Text>
      <ButtonGroup align="right">
        <Button
          data-testid="setVehicles-newWatermark-dialog-cancel"
          variant="secondary"
          onClick={() => {
            onCancel();
          }}
          isDisabled={isLoading}
          title={i18n.t('general.actions.cancel')}
        />
        <Button
          data-testid="setVehicles-newWatermark-dialog-confirm"
          variant="primary"
          onClick={() => {
            onConfirm();
          }}
          isLoading={isLoading}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>
    </VStack>
  );
}

import {ExcludeBranchesFromScope, RequestedValuesByScope, ScopeKeys} from '@dms/api';

import {Either, Nullish} from 'shared';

import {accountingPermissions} from './permissionsDefinitions/accountingPermissions';
import {businessCasePermissions} from './permissionsDefinitions/businessCasePermissions';
import {carAuditPermissions} from './permissionsDefinitions/carAuditPermissions';
import {corePermissions} from './permissionsDefinitions/corePermissions';
import {customerPermissions} from './permissionsDefinitions/customerPermissions';
import {dataGridPermissions} from './permissionsDefinitions/dataGridPermissions';
import {employeePermissions} from './permissionsDefinitions/employeePermissions';
import {interestPermissions} from './permissionsDefinitions/interestPermissions';
import {serviceCasePermissions} from './permissionsDefinitions/serviceCasePermissions';
import {settingsPermissions} from './permissionsDefinitions/settingsPermissions';
import {sourcingPermissions} from './permissionsDefinitions/sourcingPermissions';
import {taskManagementPermissions} from './permissionsDefinitions/taskManagementPermissions';
import {vehiclePermissions} from './permissionsDefinitions/vehiclePermissions';
import {warehousePermissions} from './permissionsDefinitions/warehousePermissions';
import {workshopPermissions} from './permissionsDefinitions/workshopPermissions';

/**
 * Converts union type to intersection:
 *
 * `{participation: string} | {paymentMethod: number}`
 *
 * to
 *
 * `{participation: string} & {paymentMethod: number}`
 */
type UnionToIntersection<U> = (U extends any ? (x: U) => void : never) extends (x: infer I) => void
  ? I
  : never;

type ValueOrNullish<T> = {[P in keyof T]: T[P] | Nullish};

type FilterNever<T, K> = T extends never ? never : K;

/**
 * Selects all `scopes` properties from all permissions without specific 'BRANCH' scopes.
 * Converts to following type:
 *
 * ```
 * {
 *   payDeposit: 'PARTICIPATION' | 'PAYMENT_METHOD'
 *   viewBusinessCasePurchase: 'PARTICIPATION'
 * }
 * ```
 */
type PermissionScopes<T extends PermissionsKeys[]> = Exclude<
  {
    [K in T[number]]: ExcludeBranchesFromScope<(typeof permissions)[K]['scopes'][number]>;
  },
  never
>;

type FilteredPermissionScopes<T> = {
  [K in keyof T as FilterNever<T[K], K>]: T[K];
};

/**
 * Extracts scopes from permissions and maps them to the corresponding object types
 * defined in `RequestedValuesByScope`. For example, if the `payBalance` permission
 * includes the following scopes:
 *
 * `scopes: ['PARTICIPATION', 'PAYMENT_METHOD', 'BRANCH']`
 *
 * The resulting type would be:
 *
 * ```
 * ValueOrNullish<{
 *   participation: GetParticipationApiResponse | undefined;
 * } & {
 *   paymentMethod: string;
 * }>
 * ```
 * Currently, specific branches are being filtered out, so only the intersection of objects containing
 * `participation`, `paymentMethod` and `branchId` is generated. This type ensures that all required permissions
 * are properly defined and populated.
 */
type PermissionScopesArguments<T extends Partial<Record<PermissionsKeys, ScopeKeys>>> = {
  [K in keyof T as FilterNever<T[K], K>]: ValueOrNullish<
    UnionToIntersection<RequestedValuesByScope[T[K] extends ScopeKeys ? T[K] : never]>
  >;
};

/**
 * Ensures that `scopes` usage in `usePermissions()` hook is required if any of the specified
 * `permissionKeys` include associated scopes.
 */
export type Scopes<T extends PermissionsKeys[]> =
  FilteredPermissionScopes<PermissionScopes<T>> extends Record<string, never>
    ? {scopes?: never}
    : {scopes: PermissionScopesArguments<FilteredPermissionScopes<PermissionScopes<T>>>};

export type PermissionsRecordType = Record<
  string,
  {resourceId: string; scopes: ScopeKeys[]} & Either<{actionId: string}, {fieldId: string}>
>;
export type PermissionsKeys = keyof typeof permissions;

export const permissions = {
  ...corePermissions,
  ...accountingPermissions,
  ...businessCasePermissions,
  ...customerPermissions,
  ...dataGridPermissions,
  ...interestPermissions,
  ...settingsPermissions,
  ...vehiclePermissions,
  ...carAuditPermissions,
  ...warehousePermissions,
  ...employeePermissions,
  ...serviceCasePermissions,
  ...sourcingPermissions,
  ...workshopPermissions,
  ...taskManagementPermissions,
};

import {FormControl, FormField} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {not} from 'ramda';

import i18n from '@dms/i18n';
import {useGetVatRatesOptions} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabLabourBulkEditItemForm} from './types/RequestTabLabourBulkEditItemForm';

interface RequestTabLabourBulkEditItemVatProps extends RequiredTestIdProps {
  control: FormControl<RequestTabLabourBulkEditItemForm>;
  formApi: UseFormReturn<RequestTabLabourBulkEditItemForm>;
}

export function RequestTabLabourBulkEditItemVat(props: RequestTabLabourBulkEditItemVatProps) {
  const [vatOptions] = useGetVatRatesOptions({fullLabel: true});

  const isVatDisabled = not(props.formApi.watch('isVatTypeEnabled'));

  return (
    <Box flex={1}>
      <HStack align="center" spacing={5}>
        <Box paddingTop={5}>
          <FormField
            control={props.control}
            type="switch"
            name="isVatTypeEnabled"
            data-testid={suffixTestId('isVatTypeEnabled', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            isDisabled={isVatDisabled}
            control={props.control}
            type="choice"
            name="vatType"
            label={i18n.t('entity.warehouse.labels.vat')}
            options={vatOptions}
            menuInPortal
            data-testid={suffixTestId('vatType', props)}
          />
        </Box>
      </HStack>
    </Box>
  );
}

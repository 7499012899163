import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function WarehousesManufacturers() {
  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.manufacturers'),
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.warehousesManufacturers('template')}
      header={header}
    >
      <DataGrid
        gridCode="warehouse-manufacturer"
        emptyState={{
          headline: i18n.t('page.warehouseDetail.labels.emptyManufacturers'),
        }}
        data-testid={testIds.settings.warehousesManufacturers('warehouse-manufacturer')}
      />
    </SettingsTemplate>
  );
}

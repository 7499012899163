import {useCallback} from 'react';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

interface ActivityLogDataGridProps extends RequiredTestIdProps {
  orderId: string | Nullish;
}

export function ActivityLogDataGrid(props: ActivityLogDataGridProps) {
  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      serviceOrderId: String(props.orderId),
    }),
    [props.orderId]
  );

  return (
    <DataGrid
      autoHeight
      gridCode="activity-log-service-order"
      data-testid={suffixTestId('activityLog', props)}
      queryModifier={queryModifier}
    />
  );
}

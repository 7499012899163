import {captureException} from '@sentry/browser';
import {Card, DataStatus, IconButton} from 'platform/components';
import {Text, HStack, Icon, VStack, Show, Link, Hide} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {always} from 'ramda';

import {EntityResourceIds, useGetCustomerV2Query, useGetVehicleV2Query} from '@dms/api';
import i18n from '@dms/i18n';
import {customerRoutes, vehiclesRoutes} from '@dms/routes';
import {getCustomerName, useGetVehicleTitle} from '@dms/shared';

import {composePath, RequiredTestIdProps, useNavigate} from 'shared';

import {TaskRelationProps} from '../../types/TaskRelationProps';
import {getIconKeyByTaskRelationType} from '../../utils/getIconKeyByTaskRelationType';
import {useTaskRelationBusinessCase} from './hooks/useTaskRelationBusinessCase';
import {useTaskRelationInterest} from './hooks/useTaskRelationInterest';

interface TaskRelatesCardProps extends RequiredTestIdProps, Required<TaskRelationProps> {
  onRelationRemove: () => void;
  isReadOnly?: boolean;
}

export function TaskRelatesCard(props: TaskRelatesCardProps) {
  const navigate = useNavigate();

  const {
    title: interestTitle,
    customerId: interestCustomerId,
    isLoading: isInterestLoading,
    isError: hasInterestError,
    pathname: interestPathname,
  } = useTaskRelationInterest(
    props.resourceId === EntityResourceIds.interest ? props.relatedRecordId : ''
  );
  const {
    vehicle: bcVehicle,
    customerId: bcCustomerId,
    isLoading: isBcLoading,
    isError: hasBcError,
    pathname: bcPathname,
  } = useTaskRelationBusinessCase(
    props.resourceId === EntityResourceIds.businessCase ? props.relatedRecordId : ''
  );

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: hasVehicleError,
  } = useGetVehicleV2Query(
    {
      vehicleId: props.relatedRecordId,
    },
    {
      skip: props.resourceId !== EntityResourceIds.vehicle,
    }
  );

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: isCustomerErrored,
  } = useGetCustomerV2Query(
    {customerId: interestCustomerId ?? bcCustomerId ?? props.relatedRecordId},
    {
      skip: props.resourceId === EntityResourceIds.vehicle,
    }
  );

  const [vehicleTitle, {isLoading: isVehicleTitleLoading, isError: isVehicleTitleError}] =
    useGetVehicleTitle(vehicle ?? bcVehicle);

  const title = match(props.resourceId)
    .returnType<string>()
    .with(
      EntityResourceIds.customer,
      () => getCustomerName(customer) ?? i18n.t('entity.customer.labels.customer')
    )
    .with(
      Pattern.union(EntityResourceIds.vehicle, EntityResourceIds.businessCase),
      () => vehicleTitle ?? vehicle?.title ?? i18n.t('entity.vehicle.labels.vehicle')
    )
    .with(
      EntityResourceIds.interest,
      () => interestTitle ?? i18n.t('entity.interest.labels.interest')
    )
    .otherwise(always(''));

  const entityPathname = match(props.resourceId)
    .with(EntityResourceIds.customer, () =>
      composePath(customerRoutes.detail, {params: {id: props.relatedRecordId}})
    )
    .with(EntityResourceIds.vehicle, () =>
      composePath(vehiclesRoutes.detail, {params: {id: props.relatedRecordId}})
    )
    .with(EntityResourceIds.businessCase, () => bcPathname)
    .with(EntityResourceIds.interest, () => interestPathname)
    .otherwise(always(new Error(`Task unknown resource id - ${props.resourceId}`)));

  if (entityPathname instanceof Error) {
    captureException(entityPathname, {
      extra: {
        resourceId: props.resourceId,
        relatedRecordId: props.relatedRecordId,
      },
    });
    return null;
  }

  const isLoading =
    isCustomerLoading ||
    isVehicleTitleLoading ||
    isInterestLoading ||
    isBcLoading ||
    isVehicleLoading;
  const isError =
    isCustomerErrored || isVehicleTitleError || hasInterestError || hasBcError || hasVehicleError;

  return (
    <Card variant="inlineGrey" data-testid={props['data-testid']}>
      <DataStatus isLoading={isLoading} isError={isError}>
        <HStack justify="space-between" align="center">
          <HStack spacing={4} align="center">
            <Icon value={getIconKeyByTaskRelationType(props.resourceId)} size={6} />
            <VStack>
              <Link
                onClick={() => {
                  navigate(entityPathname);
                }}
                size="small"
                title={title}
              />
              <Show when={customer && props.resourceId !== EntityResourceIds.customer}>
                <Text size="xSmall" color="secondary">
                  {`${i18n.t('entity.customer.labels.customer')}: ${getCustomerName(customer)}`}
                </Text>
              </Show>
            </VStack>
          </HStack>
          <Hide when={props.isReadOnly}>
            <IconButton icon="navigation/close" onClick={props.onRelationRemove} />
          </Hide>
        </HStack>
      </DataStatus>
    </Card>
  );
}

import {DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FC, PropsWithChildren, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useMatch, useNavigate} from 'react-router-dom';

import {always, dissocPath, isNil} from 'ramda';

import {vehicleApi} from '@dms/api';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {queryParams} from '@dms/shared';
import {
  getCebiaAutotraceAndReport,
  getCebiaEquipmentCredentials,
  getCustomTenantCatalogue,
  loadCarDetailsVehicleDetailRequest,
  selectSalesVehicleData,
  useThunkDispatch,
  VehicleCreateContext,
  VehicleCreateForm,
} from '@dms/teas';

import {
  composePath,
  suffixTestId,
  TestIdProps,
  useBoolean,
  useQueryState,
  useRequiredParams,
} from 'shared';

interface EditVehicleLocationState {
  makeSaleVehicleAvailableForSale?: boolean;
}

const EditVehicleComponent: FC = (props: TestIdProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const makeSaleVehicleAvailableForSale = (location?.state as EditVehicleLocationState)
    ?.makeSaleVehicleAvailableForSale;
  const vehicle = useSelector(selectSalesVehicleData);
  const [returnUrl] = useQueryState(queryParams.RETURN_URL);
  const editTabPath = useMatch(vehiclesRoutes.editDetailTab);

  const navigateToURL = () => {
    if (isNil(returnUrl)) {
      return;
    }

    dispatch(vehicleApi.util.invalidateTags([{type: 'Vehicle', id: vehicle?.id as string}]));
    navigate(returnUrl, {state: {ignoreUnmountEvents: true}});
  };

  const onClose = () => {
    if (returnUrl) {
      navigateToURL();
      return;
    }

    const route = match(editTabPath?.params.tab)
      .with('vehicle-data', always(vehiclesRoutes.vehicleData))
      .with('features', always(vehiclesRoutes.features))
      .otherwise(always(vehiclesRoutes.detail));

    navigate(composePath(route, {params: {id: vehicle?.id as string}}));
  };

  return (
    <VehicleCreateContext onClose={onClose} onCreate={returnUrl ? navigateToURL : undefined}>
      <VehicleCreateForm
        cyId={testIds.vehicles.edit()}
        vehicle={
          makeSaleVehicleAvailableForSale
            ? dissocPath(['state', 'mileage'], dissocPath(['state', 'realMileage'], vehicle))
            : vehicle
        }
        makeSaleVehicleAvailableForSale={makeSaleVehicleAvailableForSale}
        data-testid={suffixTestId('vehicleCreateForm', props)}
      />
    </VehicleCreateContext>
  );
};

export const EditVehicle: FC<PropsWithChildren<any>> = () => {
  const dispatch = useThunkDispatch();
  const {id: vehicleId} = useRequiredParams();
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  useEffect(() => {
    startLoading();

    Promise.all([
      dispatch(loadCarDetailsVehicleDetailRequest({vehicleId, shouldClearCacheEntry: true})),
      dispatch(getCustomTenantCatalogue.action({})),
      dispatch(getCebiaEquipmentCredentials()),
      dispatch(getCebiaAutotraceAndReport()),
    ]).finally(() => {
      stopLoading();
    });
  }, [dispatch, startLoading, stopLoading, vehicleId]);

  return (
    <VStack width="100%">
      <DataStatus isLoading={isLoading} minHeight={100}>
        <EditVehicleComponent />
      </DataStatus>
    </VStack>
  );
};

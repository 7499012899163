import {
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormSubmitHandler,
  isCurrency,
  showNotification,
} from 'platform/components';
import {Heading, Hide, Space, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';
import * as Yup from 'yup';

import {useState} from 'react';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  GetServiceOrderItemsDiscountApiResponse,
  selectTenant,
  useGetServiceOrderCustomerContractDetailsQuery,
  useGetServiceOrderItemsDiscountQuery,
  usePutServiceOrderItemsDiscountMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  DEFAULT_CURRENCY,
  getDecimalFromPercentage,
  getPercentageFromDecimal,
  handleApiError,
} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps, useToggle} from 'shared';

import {DiscountType} from '../../../../../types/discountType';
import {MaterialDiscountOption} from '../types/materialDiscountOption';
import {WorkDiscountOption} from '../types/workDiscountOption';
import {DiscountOnMaterialCard} from './DiscountOnMaterialCard';
import {DiscountOnWorkCard} from './DiscountOnWorkCard';

interface DiscountSectionProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  isReadOnly?: boolean;
}

export function DiscountSection(props: DiscountSectionProps) {
  const {data, isLoading, isError} = useGetServiceOrderItemsDiscountQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
  });
  const {
    data: contract,
    isFetching: isContractFetching,
    isError: isContractError,
  } = useGetServiceOrderCustomerContractDetailsQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
  });

  return (
    <DataStatus
      isLoading={isLoading || isContractFetching}
      isError={isError || isContractError}
      minHeight={45}
    >
      <Heading size={4}>{i18n.t('entity.order.labels.customDiscounts')}</Heading>
      <Space vertical={1} />
      <Text size="small" color="secondary">
        {i18n.t('entity.order.labels.discountInfo')}
      </Text>
      <Space vertical={4} />
      <InnerForm data={data} isDisabled={isNotNil(contract)} {...props} />
    </DataStatus>
  );
}

interface InnerFormProps extends DiscountSectionProps {
  data: GetServiceOrderItemsDiscountApiResponse | Nullish;
  isDisabled: boolean;
}

function InnerForm(props: InnerFormProps) {
  const {data: selectedTenant} = useSelector(selectTenant);

  const currency = isCurrency(selectedTenant?.currency)
    ? selectedTenant?.currency
    : DEFAULT_CURRENCY;

  const [putServiceOrderItemsDiscount] = usePutServiceOrderItemsDiscountMutation();

  const [isWorkExpanded, toggleWorkExpanded] = useToggle(isNotNil(props.data?.workDiscount));
  const [isMaterialExpanded, toggleMaterialExpanded] = useToggle(
    isNotNil(props.data?.materialDiscount)
  );
  const [selectedWorkOption, setSelectedWorkOption] = useState<WorkDiscountOption | null>(
    isNil(props.data?.workDiscount?.discountedWorkRate)
      ? WorkDiscountOption.PercentageDiscount
      : WorkDiscountOption.DiscountedWorkRate
  );
  const [selectedMaterialOption, setSelectedMaterialOption] =
    useState<MaterialDiscountOption | null>(
      isNil(props.data?.materialDiscount?.markupToPurchasePrice)
        ? MaterialDiscountOption.DiscountOnSalePrice
        : MaterialDiscountOption.MarginToPurchasePrice
    );

  const handleSubmit: FormSubmitHandler<DiscountType> = async (data) => {
    if (!props.isDisabled) {
      const serviceOrderDiscount = {
        workDiscount: match([selectedWorkOption, isWorkExpanded])
          .with([WorkDiscountOption.PercentageDiscount, true], () => ({
            percentageDiscount: getDecimalFromPercentage(data.work?.percentDiscount?.discountRate),
          }))
          .with([WorkDiscountOption.DiscountedWorkRate, true], () => ({
            discountedWorkRate:
              data.work?.discountedWorkRates?.discountedWorkRate?.map((rate) => ({
                workType: rate.workRateType ?? '',
                workRateWithoutVat: isNil(rate?.workRatePriceWithoutVat)
                  ? null
                  : {
                      amount: rate.workRatePriceWithoutVat,
                      currency: String(currency),
                    },
              })) ?? undefined,
          }))
          .otherwise(() => undefined),
        materialDiscount: match([selectedMaterialOption, isMaterialExpanded])
          .with([MaterialDiscountOption.DiscountOnSalePrice, true], () => ({
            discountToSalePrice: getDecimalFromPercentage(
              data.material?.percentDiscount?.discountOnSalePriceRate
            ),
          }))
          .with([MaterialDiscountOption.MarginToPurchasePrice, true], () => ({
            markupToPurchasePrice: getDecimalFromPercentage(
              data.material?.percentDiscount?.discountMarginToPurchasePriceRate
            ),
          }))
          .otherwise(() => undefined),
      };

      await putServiceOrderItemsDiscount({
        serviceCaseId: props.serviceCaseId,
        serviceOrderId: props.serviceOrderId,
        body: {serviceOrderDiscount},
      })
        .unwrap()
        .catch(handleApiError);
    }

    showNotification.success();
  };

  const defaultValues: DiscountType = {
    work: {
      percentDiscount: {
        discountRate:
          getPercentageFromDecimal(props.data?.workDiscount?.percentageDiscount) ?? undefined,
      },
      discountedWorkRates: {
        discountedWorkRate: props.data?.workDiscount?.discountedWorkRate?.map((workRate) => ({
          workRateType: workRate?.workType ?? undefined,
          workRatePriceWithoutVat: workRate?.workRateWithoutVat?.amount ?? undefined,
        })) ?? [{workRateType: undefined, workRatePriceWithoutVat: undefined}],
      },
    },
    material: {
      percentDiscount: {
        discountOnSalePriceRate:
          getPercentageFromDecimal(props.data?.materialDiscount?.discountToSalePrice) ?? undefined,
        discountMarginToPurchasePriceRate:
          getPercentageFromDecimal(props.data?.materialDiscount?.markupToPurchasePrice) ??
          undefined,
      },
    },
  };

  return (
    <Form<DiscountType>
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={schema(
        selectedWorkOption,
        selectedMaterialOption,
        isWorkExpanded,
        isMaterialExpanded
      )}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <DiscountOnWorkCard
            serviceCaseId={props.serviceCaseId}
            control={control}
            formApi={formApi}
            selectedOption={selectedWorkOption}
            onOptionChange={setSelectedWorkOption}
            isExpanded={isWorkExpanded}
            onExpandChange={toggleWorkExpanded}
            currency={currency}
            isReadOnly={props.isReadOnly || props.isDisabled}
            data-testid={suffixTestId('work', props)}
          />
          <DiscountOnMaterialCard
            control={control}
            selectedOption={selectedMaterialOption}
            onOptionChange={setSelectedMaterialOption}
            isExpanded={isMaterialExpanded}
            onExpandChange={toggleMaterialExpanded}
            isReadOnly={props.isReadOnly || props.isDisabled}
            data-testid={suffixTestId('material', props)}
          />
          <Hide when={props.isReadOnly}>
            <ButtonGroup align="right">
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.saveChanges')}
                data-testid={suffixTestId('save', props)}
              />
            </ButtonGroup>
          </Hide>
        </VStack>
      )}
    </Form>
  );
}

const schema = (
  selectedWorkOption: WorkDiscountOption | null,
  selectedMaterialOption: MaterialDiscountOption | null,
  isWorkExpanded: boolean,
  isMaterialExpanded: boolean
): Yup.SchemaOf<DiscountType> =>
  Yup.object({
    work: Yup.object({
      percentDiscount: Yup.object({
        discountRate:
          selectedWorkOption === WorkDiscountOption.PercentageDiscount && isWorkExpanded
            ? Yup.number().required().min(0).max(100)
            : Yup.number(),
      }),
      discountedWorkRates: Yup.object({
        discountedWorkRate: Yup.array().of(
          Yup.object({
            workRateType:
              selectedWorkOption === WorkDiscountOption.DiscountedWorkRate && isWorkExpanded
                ? Yup.string().required()
                : Yup.string(),
            workRatePriceWithoutVat:
              selectedWorkOption === WorkDiscountOption.DiscountedWorkRate && isWorkExpanded
                ? Yup.number().required().min(0)
                : Yup.number(),
          })
        ),
      }),
    }),
    material: Yup.object({
      percentDiscount: Yup.object({
        discountOnSalePriceRate:
          selectedMaterialOption === MaterialDiscountOption.DiscountOnSalePrice &&
          isMaterialExpanded
            ? Yup.number().required().min(0).max(100)
            : Yup.number(),
        discountMarginToPurchasePriceRate:
          selectedMaterialOption === MaterialDiscountOption.MarginToPurchasePrice &&
          isMaterialExpanded
            ? Yup.number().required().min(0).max(100)
            : Yup.number(),
      }),
    }),
  });

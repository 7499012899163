import {Alert, Card, DataStatus} from 'platform/components';
import {Heading, HStack, Icon, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  AftersalesPaymentMethod,
  useGetCheckoutByIdQuery,
  useGetCheckoutPaymentsQuery,
} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {DEFAULT_CURRENCY} from '../../constants/currency';
import {CheckoutPaymentForm} from './components/CheckoutPaymentForm';
import {DepositPaymentsCard} from './components/DepositPaymentsCard';
import {ForeginCurrencyPaymentCard} from './components/ForeginCurrencyPaymentCard';

interface AftersalesCheckoutPaymentProps extends RequiredTestIdProps {
  checkoutId: string;
  disallowedPaymentMethods?: AftersalesPaymentMethod[];
  defaultPaymentType?: AftersalesPaymentMethod;
}

export function AftersalesCheckoutPayment(props: AftersalesCheckoutPaymentProps) {
  const {
    data: checkoutData,
    isLoading: isCheckoutLoading,
    isError: isCheckoutError,
  } = useGetCheckoutByIdQuery({checkoutId: props.checkoutId});
  const {
    data: paymentsData,
    isLoading: isPaymentsLoading,
    isError: isPaymentsError,
  } = useGetCheckoutPaymentsQuery({checkoutId: props.checkoutId});

  const formatCurrency = useFormatCurrency();

  const isLoading = isCheckoutLoading || isPaymentsLoading;
  const isError = isCheckoutError || isPaymentsError;

  const areAllPaymentsConcept = !!paymentsData?.every(
    ({paymentState}) => paymentState === 'CONCEPT'
  );

  const balancePayment = paymentsData?.find((payment) => payment.discriminator === 'BALANCE');

  const hasDepositPayment = paymentsData?.some((payment) => payment.discriminator === 'DEPOSIT');

  const hasNotPaidDepositPayment = paymentsData?.some(
    (payment) => payment.discriminator === 'DEPOSIT' && payment.paymentState !== 'PAID'
  );

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        {checkoutData && (
          <Card variant="inlineGrey">
            <VStack spacing={1}>
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.order.labels.totalAmount')}
              </Text>
              <Heading size={3}>
                {formatCurrency(
                  checkoutData?.totalPrice?.withVat?.amount ?? 0,
                  checkoutData?.totalPrice?.withVat?.currency ?? DEFAULT_CURRENCY,
                  2
                )}
              </Heading>
              <Text size="xSmall" color="secondary">
                {`${formatCurrency(
                  checkoutData?.totalPrice?.withoutVat?.amount ?? 0,
                  checkoutData?.totalPrice?.withoutVat?.currency ?? DEFAULT_CURRENCY,
                  2
                )} ${i18n.t('entity.order.labels.withoutVat')}`}
              </Text>
            </VStack>
          </Card>
        )}
        <ForeginCurrencyPaymentCard
          checkoutId={props.checkoutId}
          areAllPaymentsConcept={areAllPaymentsConcept}
          data-testid={suffixTestId('foreginCurrency', props)}
        />
        <DepositPaymentsCard
          checkoutId={props.checkoutId}
          disallowedPaymentMethods={props.disallowedPaymentMethods}
          defaultPaymentType={props.defaultPaymentType}
          data-testid={suffixTestId('depositPayments', props)}
        />
        <HStack spacing={2}>
          <Icon value="action/payment" />
          <Heading size={4}>
            {hasDepositPayment
              ? i18n.t('entity.checkout.labels.supplementaryPayment')
              : i18n.t('entity.checkout.labels.totalPayment')}
          </Heading>
        </HStack>
        {balancePayment && (
          <Card variant="inlineGrey">
            <VStack spacing={4}>
              <Show when={hasNotPaidDepositPayment}>
                <Alert
                  title={i18n.t('general.notifications.paymentNotPossibleBeforeDepositsPaid.title')}
                  variant="warning"
                />
              </Show>
              <CheckoutPaymentForm
                paymentId={balancePayment.checkoutPaymentId}
                checkoutId={props.checkoutId}
                isSubmitButtonDisabled={
                  isNilOrEmpty(checkoutData?.billingInformation?.customerInformation.id) ||
                  isNilOrEmpty(checkoutData?.billingInformation?.contractInformation?.id)
                }
                customerId={checkoutData?.billingInformation?.customerInformation.id ?? ''}
                disallowedPaymentMethods={props.disallowedPaymentMethods}
                defaultPaymentType={props.defaultPaymentType}
                isForeignCurrencyAllowed={checkoutData?.isForeignCurrencyPayment}
                data-testid={suffixTestId('payment', props)}
              />
            </VStack>
          </Card>
        )}
      </VStack>
    </DataStatus>
  );
}

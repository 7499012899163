import {AxiosError} from 'axios';

import {ApiException} from '@dms/api';

const INTERNAL_SERVER_ERROR_STATUS_CODE = 500;

export class LogicalException extends Error {
  constructor(message?: string) {
    super(message || 'Logical exception');
  }
}

export class CustomError extends Error {
  statusCode: number;
  constructor({message, statusCode}: {message?: string; statusCode?: number}) {
    super(message);
    this.statusCode = statusCode ?? INTERNAL_SERVER_ERROR_STATUS_CODE;
  }
}

export class CancelError extends Error {
  isCancel: boolean;

  constructor() {
    super('Request cancelled');
    this.isCancel = true;
  }
}

export const isAxiosError = (
  err: CustomError | AxiosError<ApiException> | Error
): err is AxiosError<ApiException> => (err as AxiosError)?.isAxiosError;

export const getStatusCode = (err: CustomError | AxiosError<ApiException>) => {
  if (isAxiosError(err)) {
    return err?.response?.status ?? INTERNAL_SERVER_ERROR_STATUS_CODE;
  }
  return err?.statusCode ?? INTERNAL_SERVER_ERROR_STATUS_CODE;
};

import {FC, useState} from 'react';

import {TestIdProps} from 'shared';

import {SMART_SEARCH_KEY} from '../constants/smartSearchKey';
import {useDataGridFiltersModel} from '../context/useDataGridFiltersModel';
import {GridApi} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {QuickFilterRenderer} from './QuickFilterRenderer';

export interface QuickFilterWrapperProps extends TestIdProps {
  gridProps: DataGridProps;
  gridApi?: GridApi;
}

export const QuickFilterWrapper: FC<QuickFilterWrapperProps> = (props) => {
  const {filterValues} = useDataGridFiltersModel();
  const {gridProps, gridApi} = props;

  const [quickFilterValue, setQuickFilterValue] = useState<string>(
    (filterValues[SMART_SEARCH_KEY] as string) ?? ''
  );

  const submitQuickFilter = (quickFilter?: string) => {
    if (!gridApi) {
      return;
    }

    const filters = gridApi.getFilterModel();
    gridApi.setFilterModel({...filters, [SMART_SEARCH_KEY]: quickFilter});
    gridApi.onFilterChanged('api');
  };

  return (
    <QuickFilterRenderer
      submitQuickFilter={submitQuickFilter}
      cancelQuickFilter={submitQuickFilter}
      quickFilterValue={quickFilterValue}
      onQuickFilterValueChange={setQuickFilterValue}
      gridCode={gridProps.gridCode as string}
      data-testid={props['data-testid']}
      gridApi={gridApi}
    />
  );
};

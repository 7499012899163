import {Dropdown, IconButton, Tooltip} from 'platform/components';
import {Icon, Spinner} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {FC, useEffect, useMemo, useState} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {Preset} from '../../types/Api';
import {PresetButtonActions} from '../../types/PresetButtonActions';
import {PresetApiStateEnum} from './PresetApiStateEnum';

export interface PresetButtonProps extends TestIdProps {
  preset: Preset;
  active: boolean;
  unsaved: boolean;
  state: PresetApiStateEnum;
  rowCount: number | undefined;
  actions?: PresetButtonActions;
  isDisabled?: boolean;
  onClick: () => void;
}

const Wrapper = styled.div<{active: boolean; isDisabled: boolean}>`
  overflow: hidden;
  position: relative;
  display: inline-flex;
  height: 32px;
  border-radius: 32px;
  line-height: 32px;
  background-color: ${({active, theme}) => (active ? theme.colors.general.accent : '#EDF2F7')};
  cursor: pointer;
  user-select: none;
  max-width: 200px;

  &:hover {
    ${({isDisabled, active, theme}) =>
      isDisabled
        ? css`
            cursor: progress;
          `
        : css`
            background-color: ${active ? theme.colors.palettes.blue[70][100] : '#E2E8F0'};
          `}
  }
`;

const StyledIcon = styled.div<{active: boolean; color: any}>`
  left: 0;
  position: absolute;
  transform: translate(-24px, -50%);
  transition: 0.4s cubic-bezier(0.4, 0.14, 0.3, 1);
  transition-property: transform;
  top: 50%;
  width: 16px;
  height: 16px;
  padding-right: 6px;
  box-sizing: content-box;
  padding-left: 0;

  &:hover {
    background-color: #e2e8f0;
  }

  & > * {
    display: flex;
  }

  /* Active Icon Styles */
  ${({active}) =>
    active &&
    css`
      background-color: ${({theme}) => theme.colors.general.accent};

      &:hover {
        background-color: ${({theme}) => theme.colors.palettes.blue[70][100]};
      }
    `}

  /* Success and Error Styles */
  ${({color}) =>
    color &&
    css`
      ${color}
    `}
`;

const ButtonWrapper = styled.button<{active: boolean; isDisabled: boolean}>`
  padding: 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 32px;
  max-width: 200px;

  ${({active}) =>
    active &&
    css`
      color: ${({theme}) => theme.colors.palettes.white[10][100]};
    `}

  ${({isDisabled}) =>
    isDisabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;

const DropdownWrapper = styled.div<{dropdownOpen: boolean; hover: boolean; isDisabled: boolean}>`
  position: absolute;
  right: 0;
  transform: translateX(24px);
  transition: 0.4s cubic-bezier(0.4, 0.14, 0.3, 1);
  transition-delay: 0.3s;
  transition-property: transform;

  margin-top: 4px;
  width: 24px;
  min-width: 24px;
  padding: 0;
  height: 24px;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;

  ${({hover, dropdownOpen, isDisabled}) =>
    (hover || dropdownOpen) &&
    !isDisabled &&
    css`
      transform: translateX(-5px);
    `}
`;

export const PresetButton: FC<PresetButtonProps> = (props) => {
  const {rowCount, unsaved, state, preset, onClick, actions, active} = props;
  const [hover, setHover] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [animationState, setAnimationState] = useState<'open' | 'close'>('close');

  const successTheme = {
    color: 'severity.success',
  };
  const errorTheme = {
    color: 'severity.danger',
  };

  useEffect(() => {
    if (state !== PresetApiStateEnum.idle) {
      setAnimationState('open');
    }
    if (state !== PresetApiStateEnum.loading) {
      setTimeout(() => setAnimationState('close'), 800);
    }
  }, [state]);

  const [icon, color] = useMemo(() => {
    let icon;
    let color;
    if (state === PresetApiStateEnum.loading) {
      icon = <Spinner size="small" />;
    }
    if (state === PresetApiStateEnum.success) {
      icon = <Icon value="action/check_circle" />;
      color = successTheme;
    }
    if (state === PresetApiStateEnum.error) {
      icon = <Icon value="alert/error" />;
      color = errorTheme;
    }
    return [icon, color];
  }, [state, successTheme, errorTheme]);

  return (
    <Wrapper
      active={active}
      isDisabled={props.isDisabled ?? false}
      data-testid={props['data-testid']}
      role="group"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <StyledIcon active={active} color={color}>
        {icon}
      </StyledIcon>

      <Tooltip
        placement="top"
        label={rowCount !== undefined ? `${preset.title} (${rowCount})` : preset.title}
        description={preset.description}
      >
        <ButtonWrapper onClick={onClick} active={active} isDisabled={props.isDisabled ?? false}>
          {unsaved && '* '}
          {preset.title}
          {rowCount !== undefined ? ` (${rowCount})` : null}
        </ButtonWrapper>
      </Tooltip>

      {actions && (
        <DropdownWrapper
          hover={hover}
          dropdownOpen={dropdownOpen}
          isDisabled={props.isDisabled ?? false}
        >
          <Dropdown
            isLazy
            strategy="fixed"
            data-testid={suffixTestId('preset-actions-dropdown', props)}
            dropdownControl={
              <IconButton
                size="small"
                icon="navigation/more_vert"
                data-testid={suffixTestId('actions', props)}
              />
            }
            onClose={() => setDropdownOpen(false)}
            onOpen={() => setDropdownOpen(true)}
          >
            {actions}
          </Dropdown>
        </DropdownWrapper>
      )}
    </Wrapper>
  );
};

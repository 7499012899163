import {DataStatus, Table, TableRow} from 'platform/components';
import {Align, Box, Scroll, Text} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {useCallback} from 'react';

import {
  CostDetailItemResponseBody,
  useSourcingCreateUserVehicleCostMutation,
  useSourcingCreateUserVehicleCostsMutation,
  useSourcingDeleteUserVehicleCostMutation,
  useSourcingGetDefaultUserPresetCostQuery,
  useSourcingGetUserCostVehicleListQuery,
  useSourcingUpdateUserVehicleCostMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {getCostItemsFromPreset} from '../utils/getCostItemsFromPreset';
import {getNewCostItem} from '../utils/getNewCostItem';
import {getUpdatedCostItem} from '../utils/getUpdatedCostItem';
import {CostsData, CostsRow} from './CostsRow';
import {NewCostsRow} from './NewCostsRow';

interface CostsTableProps {
  id: string;
  newCostKeys: number[];
  onRemoveNewCost: (key: number) => void;
}

export function CostsTable({id, newCostKeys, onRemoveNewCost}: CostsTableProps) {
  const formatNumber = useFormatNumber();

  const {data: userPreset, isLoading: isUserPresetLoading} =
    useSourcingGetDefaultUserPresetCostQuery();
  const {data: vehicleCosts, isLoading: isVehicleCostsLoading} =
    useSourcingGetUserCostVehicleListQuery({sourcingVehicleId: id});
  const isUsingPresets = vehicleCosts?.length === 0;
  const costs = isUsingPresets ? userPreset?.presetItems : vehicleCosts;
  const totalPrice = costs
    ?.filter(({enabled}) => enabled)
    .reduce((prev, current) => Number(current.cost) + prev, 0);
  const isLoading = isUserPresetLoading || isVehicleCostsLoading;

  const [createCost, {isLoading: isCreating}] = useSourcingCreateUserVehicleCostMutation();
  const [bulkCreateCost, {isLoading: isBulkCreating}] = useSourcingCreateUserVehicleCostsMutation();
  const [updateCost, {isLoading: isUpdating}] = useSourcingUpdateUserVehicleCostMutation();
  const [deleteCost, {isLoading: isDeleting}] = useSourcingDeleteUserVehicleCostMutation();
  const isSaving = isCreating || isBulkCreating || isUpdating || isDeleting;

  const rows = (costs ?? []).filter(({type}) => type === 'fixed' || type === 'other');

  const handleSaveNewCost = useCallback(
    (key: number, data: CostsData) => {
      if (isUsingPresets) {
        bulkCreateCost({
          sourcingVehicleId: id,
          userVehicleCostsRequestBody: {
            costItems: [...getCostItemsFromPreset(userPreset?.presetItems), getNewCostItem(data)],
          },
        })
          .unwrap()
          .then(() => onRemoveNewCost(key))
          .catch(handleApiError);
      } else {
        createCost({
          sourcingVehicleId: id,
          userVehicleCostRequestBody: {costItem: getNewCostItem(data)},
        })
          .unwrap()
          .then(() => onRemoveNewCost(key))
          .catch(handleApiError);
      }
    },
    [bulkCreateCost, createCost, id, isUsingPresets, onRemoveNewCost, userPreset?.presetItems]
  );

  const handleUpdateCost = useCallback(
    (row: CostDetailItemResponseBody, data: CostsData) => {
      if (isUsingPresets) {
        bulkCreateCost({
          sourcingVehicleId: id,
          userVehicleCostsRequestBody: {
            costItems: [
              ...getCostItemsFromPreset(
                userPreset?.presetItems.filter((item) => item.uuid !== row.uuid)
              ),
              getUpdatedCostItem(row, data),
            ],
          },
        })
          .unwrap()
          .catch(handleApiError);
      } else {
        updateCost({
          sourcingVehicleId: id,
          itemUuid: row.uuid,
          userVehicleCostRequestBody: {costItem: getUpdatedCostItem(row, data)},
        })
          .unwrap()
          .catch(handleApiError);
      }
    },
    [isUsingPresets, updateCost, id, bulkCreateCost, userPreset?.presetItems]
  );

  const handleRemoveCost = useCallback(
    (row: CostDetailItemResponseBody) => {
      deleteCost({sourcingVehicleId: id, itemUuid: row.uuid}).unwrap().catch(handleApiError);
    },
    [deleteCost, id]
  );

  return (
    <DataStatus isLoading={isLoading}>
      <Scroll auto>
        <Table>
          <TableRow actions={{primary: []}}>
            <Box padding={1}>
              <Text noWrap>{i18n.t('entity.vehicle.labels.item')}</Text>
            </Box>
            <Box padding={1}>
              <Align right>
                <Text noWrap>{i18n.t('entity.vehicle.labels.expectedCostCzk')}</Text>
              </Align>
            </Box>
            <Box padding={1}>
              <Text noWrap>{i18n.t('general.labels.note')}</Text>
            </Box>
          </TableRow>
          {rows.map((row) => (
            <CostsRow
              key={row.uuid}
              row={row}
              isSaving={isSaving}
              onUpdate={handleUpdateCost}
              onDelete={handleRemoveCost}
            />
          ))}
          {newCostKeys.map((key) => (
            <NewCostsRow
              key={key}
              isSaving={isSaving}
              onCreate={(data) => handleSaveNewCost(key, data)}
              onDelete={() => onRemoveNewCost(key)}
            />
          ))}
          <TableRow actions={{primary: []}}>
            <Box padding={1}>
              <Text>{i18n.t('general.labels.total')}</Text>
            </Box>
            <Box padding={1}>
              <Align right>
                <Text>{formatNumber(totalPrice, 0)}</Text>
              </Align>
            </Box>
            <Box padding={1} />
          </TableRow>
        </Table>
      </Scroll>
    </DataStatus>
  );
}

import {Card} from 'platform/components';
import {GridItem, Grid, VStack} from 'platform/foundation';

import {ReactElement} from 'react';

import {Nullish} from 'shared';

import {UseScrollToSectionType} from '../types/CommonVehicleTypes';
import {Condition} from './Condition';
import {TechnicalInspection} from './TechnicalInspection';
import {Warranty} from './Warranty';

enum RefsKeys {
  ENGINE = 'engine',
  VIN = 'vin',
  INFO = 'info',
  MODEL = 'model',
  FUEL = 'fuel',
  ADD_INFO = 'add-info',
  DIMENSIONS = 'dimensions',
  TECHNICAL_INSPECTION = 'technical-inspection',
  WARRANTY = 'warranty',
}

type VehicleCreateFormProps = {
  refs: UseScrollToSectionType['refs'];
  vehicleId: string | Nullish;
};

export const VehicleDataSection = (props: VehicleCreateFormProps): ReactElement => {
  const {refs} = props;

  return (
    <Grid columns={12}>
      <GridItem span={8}>
        <Card data-testid="addVehicle-vehicleData-conditions">
          <VStack spacing={4}>
            <Condition vehicleId={props.vehicleId} />
            <div ref={refs[RefsKeys.TECHNICAL_INSPECTION]}>
              <Card variant="inlineGrey">
                <TechnicalInspection />
              </Card>
            </div>
            <div ref={refs[RefsKeys.WARRANTY]}>
              <Card variant="inlineGrey">
                <Warranty />
              </Card>
            </div>
          </VStack>
        </Card>
      </GridItem>
    </Grid>
  );
};

import {defaultTo, find, map, pathOr, pipe} from 'ramda';

import {useGetTenantQuery, useGetVatRatesQuery, VatRate} from '@dms/api';

import {Nullish} from 'shared';

type VatRateOption = {
  value: string;
  label: string;
  fieldLabel?: string;
  rate: string;
  type: string;
};

interface UseGetVatRatesOptionsProps {
  fullLabel?: boolean;
}

export function useGetVatRatesOptions(props?: UseGetVatRatesOptionsProps) {
  const {data: tenant} = useGetTenantQuery();
  const {vatOptions} = useGetVatRatesQuery(undefined, {
    selectFromResult: ({data}) => ({
      vatOptions: pipe<
        [VatRate[] | Nullish],
        VatRate[],
        VatRate | undefined,
        VatRate['rates'],
        VatRateOption[]
      >(
        defaultTo([]),
        find<VatRate>(({code}) => code === tenant?.country),
        pathOr([], ['rates']),
        map((vatRate) => ({
          value: vatRate.type,
          label: `${vatRate.rate} % ${vatRate.name}`,
          fieldLabel: !props?.fullLabel ? `${vatRate.rate} %` : undefined,
          rate: vatRate.rate,
          type: vatRate.type,
        }))
      )(data),
    }),
  });
  return [vatOptions];
}

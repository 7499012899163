import {Box} from 'platform/foundation';

import {useTimeout} from 'shared';

import {useActionCallbackProxy} from '../hooks/useActionCallbackProxy';
import {useRowActions} from '../hooks/useRowActions';
import {ICellRendererParams} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {ActionButtonsRenderer} from './ActionButtonsRenderer';

export interface ActionsCellRendererWrapperProps extends ICellRendererParams {
  gridProps: DataGridProps;
}

export function ActionsCellRendererWrapper({
  context,
  node,
  gridProps,
  api,
}: ActionsCellRendererWrapperProps) {
  const {actionCallback, gridCode} = gridProps;

  const actions = useRowActions(node.data, context.gridProps);
  const [actionCallbackProxy, isLoading] = useActionCallbackProxy(
    actionCallback,
    api,
    node.id!,
    node.data,
    gridCode
  );
  useTimeout(() => api.autoSizeColumns(['eag-col-actions'], true), 0);

  if (Object.keys(actions).length === 0) {
    return null;
  }

  return (
    <Box
      css={{
        display: 'inline-flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ActionButtonsRenderer
        rowActionData={node.data.actions}
        isLoading={isLoading}
        actions={actions}
        actionCallback={actionCallbackProxy}
      />
    </Box>
  );
}

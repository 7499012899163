import {CostDetailItemResponseBody, CostItem} from '@dms/api';

import {CostsData} from '../components/CostsRow';

export const getUpdatedCostItem = (
  row: CostDetailItemResponseBody,
  newData: CostsData
): CostItem => ({
  ...row,
  ...newData,
  cost: (newData.cost ?? 0).toString(),
  note: newData.note ?? '',
  currency: row.currency ?? 'CZK',
});

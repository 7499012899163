import {Reorder} from 'framer-motion';

import {noop} from 'shared';

import {useDocumentSelection} from '../../contexts/DocumentSelectionProvider';
import {DocumentItemWithIsSelected} from '../../types/DocumentItemWithIsSelected';
import {DocumentItem} from './DocumentItem';

export function DocumentSelectionList() {
  const {documents, selectDocument, deselectDocument} = useDocumentSelection();

  const handleOnClick = (document: DocumentItemWithIsSelected) => {
    const selectAction = document.isSelected ? deselectDocument : selectDocument;
    selectAction(document.fileId);
  };

  return (
    <Reorder.Group
      axis="y"
      values={documents}
      onReorder={noop}
      as="div"
      layoutScroll
      style={{overflowY: 'scroll'}}
    >
      {documents.map((document) => (
        <DocumentItem document={document} key={document.fileId} onClick={handleOnClick} />
      ))}
    </Reorder.Group>
  );
}

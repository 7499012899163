import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {forwardRef, useImperativeHandle} from 'react';

import {head, isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useBulkDeleteReceiveNoteMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

export type ReceiveNoteListHandle = {
  refreshDataGrid: () => void;
};

export const ReceiveNoteList = forwardRef<ReceiveNoteListHandle>((_, ref) => {
  const navigate = useNavigate();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  useImperativeHandle(
    ref,
    () => ({
      refreshDataGrid,
    }),
    []
  );

  const [bulkDeleteReceiveNote] = useBulkDeleteReceiveNoteMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, sourceSearchParams, refreshData}) => {
    match(actionKey)
      .with('redirectDetail', () => {
        const id = isArray(rowId) ? head(rowId) : rowId;

        if (isNil(id)) {
          throw new Error('Not able to redirect, receiveNoteId is not defined');
        }

        navigate(
          composePath(warehouseRoutes.receiveNoteDetailOverview, {
            queryParams: sourceSearchParams,
            params: {receiveNoteId: id},
            isSearchParamsPreserved: false,
          })
        );
      })
      .with('delete', () => {
        const ids = isArray(rowId) ? rowId : [rowId];

        openDeleteDialog({
          id: 'deleteReceiveNoteDialog',
          text: i18n.t('entity.warehouse.labels.deleteSelectedItemsPrompt'),
          onConfirm: async () =>
            await bulkDeleteReceiveNote({receiveNoteId: ids})
              .unwrap()
              .then(() => showNotification.success())
              .then(refreshData)
              .catch(handleApiError),
          'data-testid': testIds.warehouse.receiveNoteList('deleteReceiveNoteDialog'),
        });
      })
      .with('corrective', () => {
        const id = isArray(rowId) ? head(rowId) : rowId;

        navigate(
          composePath(warehouseRoutes.receiveNoteCorrectionCreate, {params: {receiveNoteId: id}})
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <DataGrid
      ref={dataGridRef}
      gridCode="receive-note"
      actionCallback={actionCallback}
      data-testid={testIds.warehouse.receiveNoteList('receiveNotePendingList')}
    />
  );
});

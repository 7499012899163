import {BaseFlagProps, openDialog} from 'platform/components';
import {ThemeIconKey} from 'platform/foundation';

import {isNil, isNotNil} from 'ramda';

import {
  BusinessCaseResponseBody,
  CustomerResponseBodyV2,
  EntityResourceIds,
  useGetParticipationQuery,
  useGetVehicleV2Query,
} from '@dms/api';
import i18n from '@dms/i18n';
import {businessCaseRoutes, customerRoutes, testIds, vehiclesRoutes} from '@dms/routes';
import {
  EMPTY_PLACEHOLDER,
  SnippetProps,
  getBusinessCaseStateFlag,
  getBusinessCaseTypeFlag,
  getNaturalPersonFullName,
  useAddress,
  useBusinessCaseActions,
  usePermissions,
} from '@dms/shared';

import {buildArray, buildObject, composePath, useRequiredParams} from 'shared';

import {useSelectVehicleContext} from '../../../hooks/useSelectVehicleContext';
import {getContactPersonData} from '../../../utils/getContactPersonData';
import {getPurchaseVehicleIdFromBusinessCase} from '../../../utils/getPurchaseVehicleIdFromBusinessCase';
import {getSaleVehicleIdFromBusinessCase} from '../../../utils/getSaleVehicleIdFromBusinessCase';
import {ContractBillingInformationTitle} from '../components/ContractBillingInformationTitle';
import {SelectPurchaseForm} from '../components/SelectPurchaseForm';

export function useGetSnippets(
  businessCase: BusinessCaseResponseBody | undefined,
  customer: CustomerResponseBodyV2 | undefined
) {
  const {id: businessCaseId} = useRequiredParams();
  const contactPersonData = getContactPersonData(businessCase, customer);
  const {isActionEnabled} = useBusinessCaseActions(businessCaseId);
  const {composeShortAddress} = useAddress();

  const saleVehicleId = getSaleVehicleIdFromBusinessCase(businessCase);
  const purchaseVehicleId = getPurchaseVehicleIdFromBusinessCase(businessCase);

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: businessCaseId,
    },
    {skip: isNil(businessCaseId)}
  );

  const [
    canReadPurchase,
    canReadSell,
    hasCreateBusinessCasePurchasePermission,
    hasCreateBusinessCaseSellingPermission,
    hasAddVehicleToBuyPermission,
    hasAddVehicleToSellPermission,
    canPutVehicleUpForSale,
    canPutVehicleUpToBuy,
  ] = usePermissions({
    permissionKeys: [
      'viewBusinessCasePurchase',
      'viewBusinessCaseSelling',
      'createBusinessCasePurchase',
      'createBusinessCaseSelling',
      'addVehicleToBuy',
      'addVehicleToSell',
      'vehiclePutUpForSale',
      'vehiclePutUpToBuy',
    ],
    scopes: {
      viewBusinessCasePurchase: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      viewBusinessCaseSelling: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
    },
  });

  const canCreatePurchase = hasCreateBusinessCasePurchasePermission && hasAddVehicleToBuyPermission;
  const canCreateSell = hasCreateBusinessCaseSellingPermission && hasAddVehicleToSellPermission;
  const canCreateSaleVehicle = canPutVehicleUpForSale && canPutVehicleUpToBuy;

  const person =
    customer?.contacts.find((person) => businessCase?.contactPersonId === person.id) ||
    customer?.foundingPerson;

  const {data: sellingVehicle, isLoading: isLoadingSellingVehicle} = useGetVehicleV2Query(
    {vehicleId: saleVehicleId ?? ''},
    {skip: isNil(saleVehicleId)}
  );
  const {data: purchaseVehicle, isLoading: isLoadingPurchaseVehicle} = useGetVehicleV2Query(
    {vehicleId: purchaseVehicleId ?? ''},
    {skip: isNil(purchaseVehicleId)}
  );

  const {setSelectSaleVehicleOpen, setCreateVehicleModalOpen} = useSelectVehicleContext();

  const getBillingInformation = () => {
    const contractInformation = customer?.contractInformation.find(
      (contract) => contract.id === businessCase?.vehicleOrderContractInformationId
    );

    if (!contractInformation) {
      return null;
    }

    const isCompany =
      contractInformation.legalForm === 'LEGAL_ENTITY' ||
      contractInformation.legalForm === 'SELF_EMPLOYED';

    const address = isCompany
      ? composeShortAddress(contractInformation.businessInfo?.address)
      : composeShortAddress(contractInformation.person?.permanentAddress);

    const icon: ThemeIconKey = isCompany ? 'social/domain' : 'social/person';

    return {
      name: isCompany
        ? (contractInformation.businessInfo?.businessInfo?.tradeName ?? EMPTY_PLACEHOLDER)
        : getNaturalPersonFullName(contractInformation.person),
      regNumber:
        (isCompany
          ? contractInformation.businessInfo?.businessInfo?.registrationNumber
          : contractInformation.person?.personalIdentifier) ?? EMPTY_PLACEHOLDER,
      address: address ?? EMPTY_PLACEHOLDER,
      icon,
    };
  };

  const contractBillingInformation = getBillingInformation();

  const contractBillingInformationSnippet = buildObject<SnippetProps>()
    .label(i18n.t('entity.businessCase.labels.billingInformation'))
    .placeholder({
      title: i18n.t('general.labels.selectedNothing'),
    })
    .title(
      <ContractBillingInformationTitle
        name={contractBillingInformation?.name}
        icon={contractBillingInformation?.icon ?? 'social/person'}
        regNumber={contractBillingInformation?.regNumber}
      />,
      isNotNil(contractBillingInformation)
    )
    .parameters([contractBillingInformation?.address], isNotNil(contractBillingInformation))
    .build();

  const snippets = buildArray<SnippetProps>([
    {
      label: i18n.t('entity.businessCase.labels.contactPerson'),
      placeholder: {
        title: i18n.t(`entity.businessInfo.labels.noContactPerson`),
      },
      title: contactPersonData.name ?? EMPTY_PLACEHOLDER,
      parameters: contactPersonData.info,
      href: businessCase?.customerId
        ? composePath(customerRoutes.detail, {
            params: {id: businessCase?.customerId},
            isSearchParamsPreserved: false,
          })
        : undefined,
      'data-testid': testIds.businessCase.detail('contactPerson'),
      customerId: businessCase?.customerId,
      selectedContactId: person?.id,
    },
  ])
    .add(contractBillingInformationSnippet)
    .when(canReadSell, {
      label: i18n.t('page.businessCase.labels.selling'),
      placeholder: {
        title: i18n.t('entity.vehicle.actions.addVehicleToSell'),
        onClick: isActionEnabled('ADD_VEHICLE_TO_SELL')
          ? () => setSelectSaleVehicleOpen(true)
          : undefined,
        isDisabled: !canCreateSell,
      },
      title: saleVehicleId ? sellingVehicle?.title : undefined,
      parameters: [
        sellingVehicle?.registrationPlate || i18n.t('entity.vehicle.notifications.noLicencePlate'),
        sellingVehicle?.vin || i18n.t('entity.vehicle.notifications.noVIN'),
      ],
      href: isNotNil(sellingVehicle)
        ? composePath(vehiclesRoutes.detail, {
            params: {id: sellingVehicle?.id},
            isSearchParamsPreserved: false,
          })
        : undefined,
      isDisabled: !sellingVehicle && !isActionEnabled('ADD_VEHICLE_TO_SELL'),
      'data-testid': testIds.businessCase.detail('saleVehicle'),
    })
    .when(canCreatePurchase && canReadPurchase, {
      label: i18n.t('entity.businessCase.labels.buying'),
      placeholder: {
        isDisabled: !canCreateSaleVehicle,
        title: i18n.t('entity.vehicle.actions.addVehicleToBuy'),
        onClick: isActionEnabled('ADD_VEHICLE_TO_BUY')
          ? () =>
              openDialog(
                <SelectPurchaseForm setCreateVehicleModalOpen={setCreateVehicleModalOpen} />,
                {
                  title: i18n.t('page.businessCase.labels.addPurchaseVehicle'),
                  size: 'small',
                }
              )
          : undefined,
      },
      title: isNotNil(purchaseVehicle) ? purchaseVehicle.title : undefined,
      parameters: [
        purchaseVehicle?.registrationPlate || i18n.t('entity.vehicle.notifications.noLicencePlate'),
        purchaseVehicle?.vin || i18n.t('entity.vehicle.notifications.noVIN'),
      ],
      href: purchaseVehicle
        ? composePath(vehiclesRoutes.detail, {
            params: {id: purchaseVehicle?.id},
            isSearchParamsPreserved: false,
          })
        : undefined,
      isDisabled: !purchaseVehicle && !isActionEnabled('ADD_VEHICLE_TO_BUY'),
      'data-testid': testIds.businessCase.detail('purchaseVehicle'),
    })
    .when(businessCase?.brokerageBusinessCase && canReadPurchase, {
      label: i18n.t('page.businessCase.labels.brokerageBusinessCase'),
      icon: 'places/business_center',
      title: businessCase?.brokerageBusinessCase?.customerName ?? EMPTY_PLACEHOLDER,
      flags: buildArray<BaseFlagProps>()
        .when(
          businessCase?.brokerageBusinessCase?.businessCaseType,
          getBusinessCaseTypeFlag(
            businessCase?.brokerageBusinessCase?.businessCaseType
          ) as BaseFlagProps
        )
        .when(
          businessCase?.brokerageBusinessCase?.businessCaseState,
          getBusinessCaseStateFlag(
            businessCase?.brokerageBusinessCase?.businessCaseState
          ) as BaseFlagProps
        ),
      parameters: [
        businessCase?.brokerageBusinessCase?.code,
        `${i18n.t('entity.vehicle.labels.seller')}: ${
          businessCase?.brokerageBusinessCase?.createdByFirstName ?? EMPTY_PLACEHOLDER
        } ${businessCase?.brokerageBusinessCase?.createdByLastName ?? EMPTY_PLACEHOLDER}`,
      ],
      href: businessCase?.brokerageBusinessCase?.id
        ? composePath(businessCaseRoutes.overview, {
            params: {id: businessCase?.brokerageBusinessCase?.id},
            isSearchParamsPreserved: false,
          })
        : undefined,
      'data-testid': testIds.businessCase.detail('brokerageBusinessCase'),
    });

  return {snippets, isLoading: isLoadingPurchaseVehicle || isLoadingSellingVehicle};
}

import {Reorder} from 'framer-motion';
import {IconButton} from 'platform/components';
import {Box, HStack, Space} from 'platform/foundation';

import {DocumentLabel} from '../../components/DocumentLabel';
import {PreviewFileIcon} from '../../components/PreviewFileIcon';
import {DocumentItemWithIsSelected} from '../../types/DocumentItemWithIsSelected';

interface DocumentItemProps {
  document: DocumentItemWithIsSelected;
  onClick: (document: DocumentItemWithIsSelected) => void;
}

export function DocumentItem(props: DocumentItemProps) {
  const isSelected = props.document.isSelected;

  return (
    <Reorder.Item
      value={props.document.fileId}
      key={props.document.fileId}
      drag={false}
      as="div"
      layout="position"
    >
      <Box width="100%" overflow="hidden">
        <HStack align="center">
          <IconButton
            icon={isSelected ? 'action/check_circle' : 'toggle/radio_button_unchecked'}
            severity={isSelected ? 'success' : undefined}
            onClick={() => props.onClick(props.document)}
          />

          <PreviewFileIcon fileId={props.document.fileId} />

          <Space horizontal={2} />

          <DocumentLabel document={props.document} />
        </HStack>
      </Box>
    </Reorder.Item>
  );
}

import {Action, Actions} from 'platform/components';
import {Icon, HStack, Link, Space} from 'platform/foundation';

import {isFalsy, isTrue} from 'ramda-adjunct';

import {FileResponseBody, useGetEmailConfigQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps, buildArray, openFile, suffixTestId} from 'shared';

import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import {downloadHostedFile} from '../../../utils/downloadHostedFile';
import {getIconKeyByFileName} from '../../../utils/getIconKeyByFileName';
import {printPDF} from '../../../utils/printPDF';

interface DocumentItemProps extends TestIdProps {
  document: FileResponseBody;
  onSendEmail: (document: FileResponseBody) => void;
  isDocxFormat?: boolean;
}

export function DocumentItem(props: DocumentItemProps) {
  const {data: emailConfig} = useGetEmailConfigQuery();

  const [canReadDocuments] = usePermissions({
    permissionKeys: ['readDocuments'],
  });

  const openDocument = () => {
    openFile(props.document.pdfUri);
  };

  const printDocument = () => {
    printPDF(props.document.pdfUri);
  };

  const downloadDocument = () => {
    if (props.isDocxFormat) {
      downloadHostedFile(props.document.fileUri, props.document.filename);
    } else {
      downloadHostedFile(props.document.pdfUri, props.document.pdfFilename);
    }
  };

  const emailDocument = () => {
    props.onSendEmail(props.document);
  };

  const actions = buildArray<Action>()
    .when(canReadDocuments, {
      type: 'iconButton',
      icon: 'action/print',
      onClick: printDocument,
    })
    .when(canReadDocuments, {
      type: 'iconButton',
      icon: 'file/download',
      'data-testid': suffixTestId('download', props),
      onClick: downloadDocument,
    })
    .when(canReadDocuments && isTrue(emailConfig?.enabled), {
      type: 'iconButton',
      icon: 'communication/email',
      onClick: emailDocument,
    })
    .when(canReadDocuments && isFalsy(emailConfig?.enabled), {
      type: 'iconButtonWithTooltip',
      icon: 'communication/email',
      tooltip: {label: i18n.t('general.notifications.setupEmailProvider')},
      onClick: emailDocument,
    });

  return (
    <HStack spacing={4} align="center">
      <Icon value={getIconKeyByFileName(props.document.filename)} />
      <Link
        title={props.document.filename}
        isDisabled={!canReadDocuments}
        size="xSmall"
        onClick={openDocument}
        data-testid={suffixTestId('documentItemLink', props)}
      />
      <Space fillAvailable />
      <Actions actions={actions} data-testid={suffixTestId('documentItemActions', props)} />
    </HStack>
  );
}

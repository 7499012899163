import {combineReducers} from 'redux';

import {advertisementReducer} from './advertisement/reducer';
import {AdvertisementSettingsReducer} from './advertisementSettings/reducer';
import {assignmentReducer} from './assignment/reducer';
import {billingInformationReducer} from './billingInformation/reducer';
import {carAuditReducer} from './carAudit/reducer';
import {carDetailsReducer} from './carDetails/reducer';
import {carDetailsV2Reducer} from './carDetailsV2/reducer';
import {catalogueReducer} from './catalogue/reducer';
import {commonReducer} from './common/reducer';
import {documentRoundingDefinitionsReducer} from './documentRoundingDefinitions/reducer';
import {DocumentsReducer} from './documents/reducer';
import {documentSeriesReducer} from './documentSeries/reducer';
import {TenantReducer} from './integrationsSettings/reducer';
import {interestSeriesDefinitionReducer} from './interestSeries/reducer';
import {listsReducer} from './lists/reducer';
import {UserManagementReducer} from './userManagement/reducer';
import {vehicleCatalogueReducer} from './vehicleCatalogue/reducer';
import {vehicleSettingsReducer} from './vehicleSettings/reducer';

export const teasReducers = {
  common: commonReducer,
  catalogue: catalogueReducer,
  vehicleCatalogue: vehicleCatalogueReducer,
  sales: combineReducers({
    carDetails: carDetailsReducer,
    carDetailsV2: carDetailsV2Reducer,
    advertisement: advertisementReducer,
    lists: listsReducer,
  }),
  generalSettings: combineReducers({
    userManagement: UserManagementReducer,
    advertisementSettings: AdvertisementSettingsReducer,
    documents: DocumentsReducer,
    integrationsSettings: TenantReducer,
    billingInformation: billingInformationReducer,
    documentSeries: documentSeriesReducer,
    documentRoundingDefinitions: documentRoundingDefinitionsReducer,
    vehicleSettings: vehicleSettingsReducer,
    interestSettings: combineReducers({
      interestSeriesDefinition: interestSeriesDefinitionReducer,
    }),
  }),
  assignment: assignmentReducer,
  carAudit: carAuditReducer,
};

const reducers = combineReducers({...teasReducers});
export type TeasReducers = typeof reducers;

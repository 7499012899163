import {Card, Action, Label, Separator, Tooltip} from 'platform/components';
import {HStack, Box, Text, VStack, Show, Icon, Space} from 'platform/foundation';
import {useDateTimeFormatter, LocaleDateTimeFormat, formatPhoneNumber} from 'platform/locale';

import {isNil, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {GetServiceCaseServiceRepairApiResponse} from '@dms/api';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {parseDate, Nullish, suffixTestId, TestIdProps} from 'shared';

interface ServiceRepairCardProps extends TestIdProps {
  data: GetServiceCaseServiceRepairApiResponse | Nullish;
  cardActions?: Action[];
}

interface StatusItem {
  id: string;
  isActive: boolean | undefined;
  label: string;
}

export function ServiceRepairCard(props: ServiceRepairCardProps) {
  const formatDateTime = useDateTimeFormatter();

  const statusItems: StatusItem[] = [
    {
      id: 'directReception',
      isActive: props.data?.isDirectReception,
      label: i18n.t('entity.serviceScheduler.lables.directReception'),
    },
    {
      id: 'customerWaiting',
      isActive: props.data?.isCustomerWaiting,
      label: i18n.t('entity.serviceScheduler.lables.customerWaiting'),
    },
    {
      id: 'selfServiceAllowed',
      isActive: props.data?.isSelfServiceAllowed,
      label: i18n.t('entity.serviceScheduler.lables.selfServiceAllowed'),
    },
  ];

  const activeStatusItems = statusItems.filter((item) => item.isActive);
  const emptyBoxesCount = 4 - activeStatusItems.length;

  const getFormatedDateString = (format: LocaleDateTimeFormat, value: string | Nullish) => {
    if (isNil(value)) {
      return EMPTY_PLACEHOLDER;
    }

    return formatDateTime(format, parseDate(value));
  };

  const checkinDateTime = getFormatedDateString('dateTimeShort', props.data?.checkin?.plannedAt);
  const handoverDateTime = getFormatedDateString('dateTimeShort', props.data?.handover?.plannedAt);

  const checkinAdvisor = props.data?.checkin?.employee?.name ?? EMPTY_PLACEHOLDER;
  const handoverAdvisor = props.data?.handover?.employee?.name ?? EMPTY_PLACEHOLDER;

  const doorToDoor = props.data?.doorToDoors?.map((door) => ({
    date: getFormatedDateString('dateShort', door?.startAt),
    start: getFormatedDateString('timeShort', door?.startAt),
    end: getFormatedDateString('timeShort', door?.endAt),
    driver: door?.employee?.name ?? EMPTY_PLACEHOLDER,
    address: `${door?.address?.street ?? EMPTY_PLACEHOLDER}, ${door?.address?.city ?? EMPTY_PLACEHOLDER}, ${door?.address?.country ?? EMPTY_PLACEHOLDER}`,
    contactPerson: door?.contactPerson?.name ?? EMPTY_PLACEHOLDER,
    contactPersonEmail: door?.contactPerson?.email ?? EMPTY_PLACEHOLDER,
    contactPersonPhoneNumber: isNotNil(door?.contactPerson?.phoneNumber?.number)
      ? formatPhoneNumber(
          `${door?.contactPerson?.phoneNumber?.prefix}${door?.contactPerson?.phoneNumber?.number}`,
          door?.contactPerson?.phoneNumber?.countryCode
        )
      : EMPTY_PLACEHOLDER,
  }));

  const activities = props.data?.activities?.map((activity) => ({
    name: activity?.name ?? EMPTY_PLACEHOLDER,
    date: getFormatedDateString('dateShort', activity?.startAt),
    start: getFormatedDateString('timeShort', activity?.startAt),
    end: getFormatedDateString('timeShort', activity?.endAt),
    mechanic: activity?.employee?.name ?? EMPTY_PLACEHOLDER,
  }));

  const carWashes = props.data?.carWashes?.map((wash) => ({
    date: getFormatedDateString('dateShort', wash?.startAt),
    start: getFormatedDateString('timeShort', wash?.startAt),
    end: getFormatedDateString('timeShort', wash?.endAt),
    programName: wash?.programName ?? EMPTY_PLACEHOLDER,
    estimatedTime: wash?.estimatedTime ?? EMPTY_PLACEHOLDER,
  }));

  const replacementVehicles = props.data?.replacementVehicles?.map((vehicle) => ({
    name: vehicle?.vehicle?.name ?? EMPTY_PLACEHOLDER,
    vin: vehicle?.vehicle?.vin ?? EMPTY_PLACEHOLDER,
    registrationNumber: vehicle?.vehicle?.registrationNumber ?? EMPTY_PLACEHOLDER,
    date: getFormatedDateString('dateShort', vehicle?.startAt),
    start: getFormatedDateString('timeShort', vehicle?.startAt),
    end: getFormatedDateString('timeShort', vehicle?.endAt),
  }));

  return (
    <Card
      title={i18n.t('entity.serviceScheduler.lables.repairSchedule')}
      actions={props.cardActions}
    >
      <Show when={isNotNilOrEmpty(activeStatusItems)}>
        <HStack spacing={4}>
          {activeStatusItems.map((item) => (
            <Box flex={1} key={item.id}>
              <Label>{item.label}</Label>
              <Text data-testid={suffixTestId(item.id, props)}>
                <Icon value="action/check_circle" color="severity.success" />
              </Text>
            </Box>
          ))}
          {[...Array(emptyBoxesCount)].map((_, index) => (
            // no other option to generate unique key
            // eslint-disable-next-line react/no-array-index-key
            <Box flex={1} key={index} />
          ))}
        </HStack>
        <Separator />
      </Show>
      <Show when={isNotNilOrEmpty(doorToDoor)}>
        <VStack spacing={2}>
          <HStack spacing={4}>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.doorToDoorDate')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.driver')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.address')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.contactPerson')}</Label>
            </Box>
          </HStack>
          {doorToDoor?.map((door, index) => (
            <HStack spacing={4} key={`${door.date}-${door.start}-${door.end}`}>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`doorToDoor-[${index}]-date`, props)}>
                  {`${door.date} ${door.start} - ${door.end}`}
                </Text>
              </Box>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`doorToDoor-[${index}]-driver`, props)}>
                  {door.driver}
                </Text>
              </Box>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`doorToDoor-[${index}]-address`, props)}>
                  {door.address}
                </Text>
              </Box>
              <Box flex={1}>
                <HStack align="center" spacing={1}>
                  <Text data-testid={suffixTestId(`doorToDoor-[${index}]-contactPerson`, props)}>
                    {door.contactPerson}
                  </Text>
                  <Tooltip
                    placement="top"
                    description={
                      <>
                        <Text color="white">{`${i18n.t('general.labels.phone')}: ${door.contactPersonPhoneNumber}`}</Text>
                        <Text color="white">{`${i18n.t('general.labels.email')}: ${door.contactPersonEmail}`}</Text>
                      </>
                    }
                  >
                    <Icon value="action/info" size={4} />
                  </Tooltip>
                </HStack>
              </Box>
            </HStack>
          ))}
        </VStack>
        <Separator />
      </Show>
      <HStack spacing={4}>
        <Box flex={1}>
          <Label>{i18n.t('entity.serviceScheduler.lables.checkinDate')}</Label>
          <Text data-testid={suffixTestId('checkinDateTime', props)}>{checkinDateTime}</Text>
        </Box>
        <Box flex={1}>
          <Label>{i18n.t('entity.serviceScheduler.lables.serviceAdvisor')}</Label>
          <Text data-testid={suffixTestId('checkinAdvisor', props)}>{checkinAdvisor}</Text>
        </Box>
        <Space fillAvailable />
        <Space fillAvailable />
      </HStack>
      <Separator />
      <Show when={isNotNilOrEmpty(activities)}>
        <VStack spacing={2}>
          <HStack spacing={4}>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.requestDate')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.responsibleMechanic')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.requestName')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.competence')}</Label>
            </Box>
          </HStack>
          {activities?.map((activity, index) => (
            <HStack spacing={4} key={`${activity.date}-${activity.start}-${activity.end}`}>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`activity-[${index}]-time`, props)}>
                  {`${activity.date} ${activity.start} - ${activity.end}`}
                </Text>
              </Box>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`activity-[${index}]-mechanic`, props)}>
                  {activity.mechanic}
                </Text>
              </Box>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`activity-[${index}]-name`, props)}>
                  {activity.name}
                </Text>
              </Box>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`activity-[${index}]-competence`, props)}>
                  Mechanic
                </Text>
              </Box>
            </HStack>
          ))}
        </VStack>
        <Separator />
      </Show>
      <Show when={isNotNilOrEmpty(carWashes)}>
        <VStack spacing={2}>
          <HStack spacing={4}>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.carwashDate')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.carwashProgram')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.carwashTime')}</Label>
            </Box>
            <Space fillAvailable />
          </HStack>
          {carWashes?.map((wash, index) => (
            <HStack spacing={4} key={`${wash.date}-${wash.start}-${wash.end}`}>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`wash-[${index}]-time`, props)}>
                  {`${wash.date} ${wash.start} - ${wash.end}`}
                </Text>
              </Box>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`wash-[${index}]-programName`, props)}>
                  {wash.programName}
                </Text>
              </Box>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`wash-[${index}]-estimatedTime`, props)}>
                  {wash.estimatedTime}
                </Text>
              </Box>
              <Space fillAvailable />
            </HStack>
          ))}
        </VStack>
        <Separator />
      </Show>
      <Show when={isNotNilOrEmpty(replacementVehicles)}>
        <VStack spacing={2}>
          <HStack spacing={4}>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.rentalDate')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.replacementVehicle')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.licensePlate')}</Label>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.serviceScheduler.lables.vin')}</Label>
            </Box>
          </HStack>
          {replacementVehicles?.map((vehicle, index) => (
            <HStack spacing={4} key={vehicle.vin}>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`vehicle-[${index}]-time`, props)}>
                  {`${vehicle.date} ${vehicle.start} - ${vehicle.end}`}
                </Text>
              </Box>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`vehicle-[${index}]-name`, props)}>
                  {vehicle.name}
                </Text>
              </Box>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`vehicle-[${index}]-registrationNumber`, props)}>
                  {vehicle.registrationNumber}
                </Text>
              </Box>
              <Box flex={1}>
                <Text data-testid={suffixTestId(`vehicle-[${index}]-vin`, props)}>
                  {vehicle.vin}
                </Text>
              </Box>
            </HStack>
          ))}
        </VStack>
        <Separator />
      </Show>
      <HStack spacing={4}>
        <Box flex={1}>
          <Label>{i18n.t('entity.serviceScheduler.lables.handoverDate')}</Label>
          <Text data-testid={suffixTestId('handoverDateTime', props)}>{handoverDateTime}</Text>
        </Box>
        <Box flex={1}>
          <Label>{i18n.t('entity.serviceScheduler.lables.serviceAdvisor')}</Label>
          <Text data-testid={suffixTestId('handoverAdvisor', props)}>{handoverAdvisor}</Text>
        </Box>
        <Space fillAvailable />
        <Space fillAvailable />
      </HStack>
    </Card>
  );
}

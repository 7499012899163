import styled from 'styled-components';

import {cloneElement, ReactElement, ReactNode, useMemo} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {flattenReactNode} from './flattenReactNode';

export interface BreadcrumbProps extends TestIdProps {
  children: ReactNode;
}

export function Breadcrumb(props: BreadcrumbProps) {
  const flatChildren = useMemo<ReactNode[]>(
    () => flattenReactNode(props.children),
    [props.children]
  );

  const clones = flatChildren.map((child) => cloneElement(child as ReactElement, {}));

  return (
    <StyledBreadcrumbList data-testid={suffixTestId('breadcrumb', props)}>
      {clones}
    </StyledBreadcrumbList>
  );
}

const StyledBreadcrumbList = styled.ol`
  display: flex;
`;

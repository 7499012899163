import {Alert, Card, DataStatus, Separator, showNotification} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  AftersalesPaymentMethod,
  useDeleteCheckoutDepositPaymentMutation,
  useGetCheckoutByIdQuery,
  useGetCheckoutPaymentsQuery,
  usePostCheckoutDepositPaymentCreateMutation,
  usePutCheckoutDepositPaymentAllowMutation,
  usePutCheckoutDepositPaymentDisallowMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {CheckoutPaymentForm} from './CheckoutPaymentForm';

interface DepositPaymentsCardProp extends RequiredTestIdProps {
  checkoutId: string;
  disallowedPaymentMethods?: AftersalesPaymentMethod[];
  defaultPaymentType?: AftersalesPaymentMethod;
}

export function DepositPaymentsCard(props: DepositPaymentsCardProp) {
  const {
    data: checkoutData,
    isLoading: isCheckoutLoading,
    isError: isCheckoutError,
  } = useGetCheckoutByIdQuery({checkoutId: props.checkoutId});

  const {
    data: paymentsData,
    isLoading: isPaymentsLoading,
    isError: isPaymentsError,
  } = useGetCheckoutPaymentsQuery({checkoutId: props.checkoutId});

  const [putCheckoutDepositPaymentAllow, {isLoading: isAllowingLoading}] =
    usePutCheckoutDepositPaymentAllowMutation();
  const [putCheckoutDepositPaymentDisallow, {isLoading: isDisallowingLoading}] =
    usePutCheckoutDepositPaymentDisallowMutation();
  const [postCheckoutDepositPaymentCreate] = usePostCheckoutDepositPaymentCreateMutation();
  const [deleteCheckoutDepositPayment] = useDeleteCheckoutDepositPaymentMutation();

  const handleSwitchDepositPayments = (shouldAllow: boolean) => {
    if (shouldAllow) {
      return putCheckoutDepositPaymentAllow({checkoutId: props.checkoutId})
        .unwrap()
        .then(() => showNotification.success())
        .catch(handleApiError);
    }

    return putCheckoutDepositPaymentDisallow({checkoutId: props.checkoutId})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleAddAnotherDeposit = () =>
    postCheckoutDepositPaymentCreate({checkoutId: props.checkoutId})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleRemoveDeposit = (paymentId: string) => () =>
    deleteCheckoutDepositPayment({checkoutId: props.checkoutId, paymentId})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const depositPayments = paymentsData?.filter((payment) => payment.discriminator === 'DEPOSIT');

  const allDepositPaymentsPaid =
    !!depositPayments?.length &&
    depositPayments.every((payment) => payment.paymentState === 'PAID');
  const hasSomePaidPayment =
    !!depositPayments?.length && depositPayments.some((payment) => payment.paymentState === 'PAID');

  const isLoading = isPaymentsLoading || isCheckoutLoading;

  const isError = isPaymentsError || isCheckoutError;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Card
        control={{
          type: 'switch',
          onChange: handleSwitchDepositPayments,
          value: !!checkoutData?.isDepositAllowed,
          isDisabled: isAllowingLoading || isDisallowingLoading || hasSomePaidPayment,
        }}
        title={i18n.t('entity.checkout.deposit')}
        variant="inlineGrey"
        isExpanded={checkoutData?.isDepositAllowed ?? false}
        isClosedByDefault
      >
        <VStack spacing={4}>
          <Show when={allDepositPaymentsPaid}>
            <Alert title={i18n.t('entity.checkout.labels.allPaymentsPaid')} variant="success" />
          </Show>
          {depositPayments?.map((payment, index) => {
            const isLastPayment = depositPayments.length - 1 === index;
            const canBeRemoved = depositPayments.length > 1;

            return (
              <>
                <Show when={index > 0}>
                  <Separator spacing={0} />
                </Show>
                <CheckoutPaymentForm
                  key={payment.checkoutPaymentId}
                  paymentId={payment.checkoutPaymentId}
                  checkoutId={props.checkoutId}
                  isSubmitButtonDisabled={
                    isNilOrEmpty(checkoutData?.billingInformation?.customerInformation.id) ||
                    isNilOrEmpty(checkoutData?.billingInformation?.contractInformation?.id)
                  }
                  customerId={checkoutData?.billingInformation?.customerInformation.id ?? ''}
                  disallowedPaymentMethods={props.disallowedPaymentMethods}
                  defaultPaymentType={props.defaultPaymentType}
                  isForeignCurrencyAllowed={checkoutData?.isForeignCurrencyPayment}
                  onAddAnotherDepositClick={isLastPayment ? handleAddAnotherDeposit : undefined}
                  onRemoveDepositClick={
                    canBeRemoved ? handleRemoveDeposit(payment.checkoutPaymentId) : undefined
                  }
                  data-testid={suffixTestId(`depositPayment-${index}`, props)}
                />
              </>
            );
          })}
        </VStack>
      </Card>
    </DataStatus>
  );
}

import {Card, FormControl, FormField} from 'platform/components';
import {Grid, GridItem, Heading, Show, VStack} from 'platform/foundation';

import {FieldName, UseFormReturn} from 'react-hook-form';

import {GetSignableDocumentListResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {CustomerSelectChoice, SaneCustomer, usePhoneNumbers} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {BASE_PATH_SEPARATOR} from '../../../constants/BASE_PATH_SEPARATOR';
import {FormValues} from '../types/FormValues';
import {SignatoryUserSelectType, UserSelectChoice} from './UserSelectChoice';

interface DocumentSignatoriesFormProps extends TestIdProps {
  control: FormControl<FormValues>;
  formApi: UseFormReturn<FormValues>;
  activeDocument: GetSignableDocumentListResponseBody;
}

export function DocumentSignatories(props: DocumentSignatoriesFormProps) {
  const {countriesOptions} = usePhoneNumbers();

  const updateFormValue = (signatoryId: string, path: FieldName<FormValues>, value: any) => {
    const basePath = `${props.activeDocument?.sourceFileId}${BASE_PATH_SEPARATOR}${signatoryId}`;
    const inputPath = `${basePath}.${path}` as FieldName<FormValues>;
    props.formApi.setValue(inputPath, value, {shouldValidate: true});
  };

  const handleSelectUser = (signatoryId: string) => (user: SignatoryUserSelectType) => {
    updateFormValue(signatoryId, `name`, user.name);
    updateFormValue(signatoryId, `id`, user.id);
    updateFormValue(signatoryId, `email`, user.email);
    updateFormValue(signatoryId, `phoneNumber.number`, user.phoneNumber.number);
    updateFormValue(signatoryId, `phoneNumber.prefix`, user.phoneNumber.prefix);
    updateFormValue(signatoryId, `phoneNumber.countryCode`, user.phoneNumber.countryCode);
  };

  const handleSelectCustomer = (signatoryId: string) => (customer: SaneCustomer) => {
    updateFormValue(signatoryId, `email`, customer.email);
    updateFormValue(signatoryId, `id`, customer.id);
    updateFormValue(signatoryId, `phoneNumber.number`, customer.phoneNumber);
    updateFormValue(signatoryId, `phoneNumber.prefix`, customer.prefix);
    updateFormValue(signatoryId, `phoneNumber.countryCode`, customer.countryCode);
    updateFormValue(signatoryId, `name`, customer.name);
  };

  return (
    <VStack spacing={4}>
      <Heading size={3}>{props.activeDocument.filename}</Heading>
      {props.activeDocument.metadata.map((signatory) => {
        const inputBasePath = `${props.activeDocument?.sourceFileId}${BASE_PATH_SEPARATOR}${signatory.id}`;
        const formSignatory = props.formApi.watch(inputBasePath);

        return (
          <Card variant="inlineGrey" title={signatory.title} key={signatory.id}>
            <Grid columns={3}>
              <GridItem span={3}>
                <Show when={signatory.signatoryPerson === 'customer'}>
                  <CustomerSelectChoice
                    defaultSearchString={formSignatory?.name}
                    onCustomerSelect={handleSelectCustomer(signatory.id)}
                    data-testid={suffixTestId('CustomerSelectChoice', props)}
                  />
                </Show>

                <Show when={signatory.signatoryPerson === 'tenant'}>
                  <UserSelectChoice
                    defaultUserId={formSignatory?.id}
                    onSelect={handleSelectUser(signatory.id)}
                    data-testid={suffixTestId('UserSelectChoice', props)}
                  />
                </Show>
              </GridItem>

              <FormField
                control={props.control}
                type="text"
                name={`${inputBasePath}.name`}
                label={i18n.t('entity.person.labels.firstName')}
                data-testid={suffixTestId('seller-name', props)}
                isRequired
              />

              <FormField
                control={props.control}
                type="email"
                name={`${inputBasePath}.email`}
                label={i18n.t('general.labels.email')}
                data-testid={suffixTestId('seller-email', props)}
                isRequired
              />

              <FormField
                control={props.control}
                type="phone"
                isNational
                name={`${inputBasePath}.phoneNumber`}
                label={i18n.t('general.labels.phone')}
                countries={countriesOptions}
                data-testid={suffixTestId('seller-phoneNumber', props)}
                isRequired
              />
            </Grid>
          </Card>
        );
      })}
    </VStack>
  );
}

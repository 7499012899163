import {CustomFilterProps} from 'ag-grid-react';
import {AsyncMultiChoice, Chips, Separator, useTranslationContext} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback, useRef} from 'react';

import {always} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty, isString} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {useHttpCalls} from '../../hooks/useHttpCalls';
import {QuickFilters} from '../../types/Api';

const EMPTY_VALUE = decodeURI('%00');

export type SuggestionFilterProps = {
  suggestId: string;
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function SuggestionFilter(props: SuggestionFilterProps) {
  const http = useHttpCalls();
  const t = useTranslationContext();
  const isActive = useRef<boolean>(false);

  const setSelectedIds = useCallback((values: typeof props.model) => {
    isActive.current = isNotNilOrEmpty(values);
    props.onModelChange(values);
  }, []);

  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue: setSelectedIds,
    defaultValue: [],
  });

  const fetchOptions = async (query: string) => {
    const getOptionsArgs = {
      suggestId: props.suggestId,
      inputValue: isNilOrEmpty(query) ? EMPTY_VALUE : query,
      limit: 20,
    };
    const result = await http.getSuggestionOptions(getOptionsArgs);
    return result ?? [];
  };

  const fetchLabels = async (values: Array<string>) => {
    const getLabelsArgs = {
      suggestId: props.suggestId,
      keys: values,
    };
    const result = await http.getSuggestionLabels(getLabelsArgs);
    return result ?? [];
  };

  const onChange = (values: string[] | null) => {
    const newSelectedIds = match(values)
      .when(isNotNilOrEmpty, (val) => val)
      .otherwise(always(null));

    setSelectedIds(newSelectedIds);
  };

  const isQuickFilterValue = isString(props.model) ? [props.model] : null;

  return (
    <VStack>
      {props.quickFilters && props.quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              data-testid="quick-filters-suggestion-filter"
              isDisabled={props.isDisabled}
              value={isQuickFilterValue}
              options={props.quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <AsyncMultiChoice<string>
        value={isArray(props.model) ? props.model : [props.model]}
        onChange={onChange}
        loadOptions={fetchOptions}
        loadLabels={(values) => fetchLabels(values as string[])}
        defaultOptions
        noOptionsMessage={() => t('page.datagrid.labels.noSuggestFilterOptions')}
        placeholder={t('page.datagrid.filter.suggestPlaceholder', {
          field: props.column.getColDef().headerName,
        })}
        isDisabled={props.isDisabled}
        isNotClearable
        data-testid={suffixTestId('multi-choice-suggestion-filter', props)}
      />
    </VStack>
  );
}

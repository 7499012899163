import {CustomFilterProps, useGridFilter} from 'ag-grid-react';
import {Chips, NumberInput, Separator, useTranslationContext} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNil, isNotNil, path} from 'ramda';
import {isString, toNumber} from 'ramda-adjunct';

import {suffixTestId, TestIdProps, useDebouncedCallback} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {QuickFilters} from '../../types/Api';

export type RangeFilterType = {
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

type FilterValue = {from: number | null; to: number | null} | string;
export function RangeFilter(props: RangeFilterType) {
  const t = useTranslationContext();
  const [filterValue, _setFilterValue] = useState<FilterValue>({from: null, to: null});

  const setFilterValue = (value: FilterValue) => {
    _setFilterValue(value);
    updateModel(value);
  };

  const updateModel = useDebouncedCallback((model: FilterValue) => props.onModelChange(model), 300);

  useGridFilter({
    doesFilterPass: () => true,
    afterGuiAttached: () => _setFilterValue(props.model),
  });

  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue,
    defaultValue: {from: null, to: null},
  });

  const onFromInputChange = (value: number | null) => {
    if (isString(filterValue) || isNil(filterValue)) {
      setFilterValue({from: toNumber(value), to: null});
    }

    if (!isString(filterValue)) {
      setFilterValue({
        from: isNil(value) ? value : toNumber(value),
        to: filterValue?.to === 0 ? null : (filterValue?.to ?? null),
      });
    }
  };

  const onToInputChange = (value: number | null) => {
    if (isString(filterValue) || isNil(filterValue)) {
      setFilterValue({to: toNumber(value), from: null});
    }

    if (!isString(filterValue)) {
      setFilterValue({
        from: filterValue?.from === 0 ? null : (filterValue?.from ?? null),
        to: isNil(value) ? value : toNumber(value),
      });
    }
  };

  const isQuickFilterValue = isString(filterValue) ? [filterValue] : null;

  return (
    <VStack>
      {props.quickFilters && props.quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={props.isDisabled}
              value={isQuickFilterValue}
              options={props.quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('range-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <HStack spacing={5}>
        <NumberInput
          label={t('general.labels.from')}
          value={hasValidKey(filterValue, 'from') ? toNumber(filterValue.from) : null}
          onChange={onFromInputChange}
          isDisabled={props.isDisabled}
          data-testid={suffixTestId('range-filter-from', props)}
        />
        <NumberInput
          label={t('general.labels.to')}
          value={hasValidKey(filterValue, 'to') ? toNumber(filterValue.to) : null}
          onChange={onToInputChange}
          isDisabled={props.isDisabled}
          data-testid={suffixTestId('range-filter-to', props)}
        />
      </HStack>
    </VStack>
  );
}

const hasValidKey = <T extends 'from' | 'to'>(val: unknown, key: T): val is Record<T, string> =>
  isNotNil(path([key], val));

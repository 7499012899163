import {ILoadingCellRendererParams} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {LoadingCellRenderer} from '../types/LoadingCellRenderer';

export interface LoadingCellRendererWrapperProps extends ILoadingCellRendererParams {
  component: LoadingCellRenderer;
  gridProps: DataGridProps;
}

export function LoadingCellRendererWrapper({
  gridProps,
  component: LoadingRenderer,
}: LoadingCellRendererWrapperProps) {
  return <LoadingRenderer gridProps={gridProps} />;
}

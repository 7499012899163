import {identity, negate} from 'ramda';

import {InvoicingDocumentItemResponseBody} from '@dms/api';

import {PERCENTAGE_SIGN} from '../../../constants';

const negateStringValue = (val: string) => negate(parseFloat(val))?.toString() ?? '';

export const mapInvoiceItems = (
  items: InvoicingDocumentItemResponseBody[] | undefined,
  shouldNegate: boolean
) => {
  if (!items) {
    return [];
  }

  const parsePrice = shouldNegate ? negateStringValue : identity;
  return items.map((item) => ({
    description: item.description,
    isUnitPriceWithVat: item.isUnitPriceWithVat,
    itemId: item.itemId,
    type: item.type,
    vatType: item.vatType,
    quantity:
      item.type === 'discount' && item.unit === PERCENTAGE_SIGN
        ? item.discount?.percent
        : item.quantity,
    pricePerUnit: parsePrice(item.pricePerUnit.amount),
    relatedItemId: item.relatedItemId,
    unit: item.unit,
    priceWithoutVat: parsePrice(item.totalPrice.base.amount),
    priceWithVat: parsePrice(item.totalPrice.total.amount),
  }));
};

import {Button, FormControl, FormField} from 'platform/components';
import {Align, Box, HStack, VStack} from 'platform/foundation';

import {useFieldArray, UseFormReturn} from 'react-hook-form';

import {any, isNil} from 'ramda';
import {isNilOrEmpty, lengthLt} from 'ramda-adjunct';

import {CustomerBankAccountRequestBody} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {COUNTRY_CZE} from '../../constants/country';
import {useBank} from '../../hooks/useBank';
import {useCurrencies} from '../../hooks/useCurrencies';
import {useTenant} from '../../hooks/useTenant';
import {ContractInformationFormType} from '../../types/ContractInformationFormType';
import {combineAccountNumberAndBankCode} from '../../utils/combineAccountNumberAndBankCode';
import {CountrySelect} from '../CountrySelect/CountrySelect';

const BANK_CODE_REGEX = /\/\d{4}$/;
interface BankAccountListProps extends TestIdProps {
  control: FormControl<ContractInformationFormType>;
  formApi: UseFormReturn<ContractInformationFormType>;
}

export function BankList(props: BankAccountListProps) {
  const {bankOptions} = useBank();
  const {currencyOptions} = useCurrencies();
  const {isTenantCountrySupported, tenantCountry} = useTenant();

  const {fields, append, update, remove} = useFieldArray({
    control: props.control,
    name: 'bankAccounts',
  });

  const emptyBank: CustomerBankAccountRequestBody = {
    id: null,
    bankAccountData: {
      name: null,
      countryCode: tenantCountry ?? null,
      ownerName: null,
      iban: null,
      swiftBic: null,
      currency: null,
      number: null,
      bankCode: null,
      isDeleted: false,
    },
  };

  const isAddAnotherDisabled = any(
    (item: CustomerBankAccountRequestBody) =>
      item?.bankAccountData?.isDeleted === false &&
      any(isNilOrEmpty, [
        item?.bankAccountData?.number,
        item?.bankAccountData?.bankCode,
        item?.bankAccountData?.countryCode,
      ])
  )(props.formApi.watch('bankAccounts'));

  const onChangeBankCode = (index: number) => (bankCode: string | number | Nullish) => {
    if (isNil(bankCode)) {
      return;
    }
    if (!isTenantCountrySupported(COUNTRY_CZE)) {
      return;
    }

    const accountNumber = props.formApi.getValues(`bankAccounts.${index}.bankAccountData.number`);
    if (isNil(accountNumber)) {
      return;
    }

    const composedBankAccount = combineAccountNumberAndBankCode(accountNumber, bankCode.toString());

    props.formApi.setValue(`bankAccounts.${index}.bankAccountData.number`, composedBankAccount);
  };

  const onChangeAccountNumber = (index: number) => (accountNumber: string | Nullish) => {
    if (isNil(accountNumber)) {
      return;
    }
    if (!isTenantCountrySupported(COUNTRY_CZE)) {
      return;
    }
    if (!BANK_CODE_REGEX.test(accountNumber)) {
      // If account number does not contain valid bank code, clear bank code
      props.formApi.setValue(`bankAccounts.${index}.bankAccountData.bankCode`, null);
      return;
    }

    // Extract bank code from account number
    const bankCode = accountNumber.slice(-4);
    const bankCodeOption = bankOptions?.find((option) => option.value === bankCode);

    if (bankCodeOption) {
      props.formApi.setValue(
        `bankAccounts.${index}.bankAccountData.bankCode`,
        bankCodeOption.value
      );
    }
  };

  const handleBankAccountDelete = (index: number) => {
    update(index, {
      ...fields[index],
      bankAccountData: {
        ...fields[index].bankAccountData,
        isDeleted: true,
      },
    });
    remove(index);
  };

  return (
    <>
      {fields
        .filter((field) => !field.bankAccountData.isDeleted)
        .map((field, index) => (
          <VStack key={field.id} spacing={4}>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={props.control}
                  type="text"
                  name={`bankAccounts.${index}.bankAccountData.ownerName`}
                  label={i18n.t('entity.bankAccount.labels.ownerName')}
                  data-testid={suffixTestId(`ownerName-[${index}]`, props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={props.control}
                  type="text"
                  name={`bankAccounts.${index}.bankAccountData.name`}
                  label={i18n.t('entity.bankAccount.labels.name')}
                  data-testid={suffixTestId(`name-[${index}]`, props)}
                />
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={props.control}
                  type="text"
                  name={`bankAccounts.${index}.bankAccountData.number`}
                  label={i18n.t('entity.bank.labels.accountNumber')}
                  data-testid={suffixTestId(`number-[${index}]`, props)}
                  tooltip={i18n.t('entity.bank.labels.accountNumberTooltip')}
                  onChange={onChangeAccountNumber(index)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={props.control}
                  type="choice"
                  menuInPortal
                  name={`bankAccounts.${index}.bankAccountData.bankCode`}
                  options={bankOptions}
                  label={i18n.t('entity.bank.labels.name')}
                  data-testid={suffixTestId(`bankCode-[${index}]`, props)}
                  onChange={onChangeBankCode(index)}
                />
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={props.control}
                  type="choice"
                  menuInPortal
                  name={`bankAccounts.${index}.bankAccountData.currency`}
                  menuPlacement="top"
                  options={currencyOptions}
                  label={i18n.t('entity.bankAccount.labels.currency')}
                  data-testid={suffixTestId(`currency-[${index}]`, props)}
                />
              </Box>
              <Box flex={1}>
                <CountrySelect<ContractInformationFormType>
                  control={props.control}
                  menuPlacement="top"
                  menuInPortal
                  name={`bankAccounts.${index}.bankAccountData.countryCode`}
                  label={i18n.t('entity.bankAccount.labels.countryCode')}
                  data-testid={suffixTestId(`countryCode-[${index}]`, props)}
                />
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={props.control}
                  type="text"
                  name={`bankAccounts.${index}.bankAccountData.swiftBic`}
                  label={i18n.t('entity.bankAccount.labels.swiftBic')}
                  data-testid={suffixTestId(`swiftBic-[${index}]`, props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={props.control}
                  type="text"
                  name={`bankAccounts.${index}.bankAccountData.iban`}
                  label={i18n.t('entity.bankAccount.labels.iban')}
                  data-testid={suffixTestId(`iban-[${index}]`, props)}
                />
              </Box>
            </HStack>
            <Align left>
              <Button
                title={i18n.t('general.actions.delete')}
                size="small"
                leftIcon="action/delete"
                variant="dangerLink"
                isDisabled={lengthLt(2, fields)}
                onClick={() => handleBankAccountDelete(index)}
                data-testid={suffixTestId(`identityCardRemove-[${index}]`, props)}
              />
            </Align>
          </VStack>
        ))}
      <Align left>
        <Button
          leftIcon="content/add_circle"
          title={i18n.t('general.actions.addAnother')}
          size="small"
          variant="link"
          isDisabled={isAddAnotherDisabled}
          onClick={() => append(emptyBank)}
          data-testid={suffixTestId('phoneNumberAddAnother', props)}
        />
      </Align>
    </>
  );
}

import {Form, FormField} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {EntityResourceIds} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {checklistFormSchema, ChecklistFormValue} from '../types/ChecklistFormValue';
import {checklistTaskFactory} from '../utils/checklistTaskFactory';
import {TasksControl} from './TasksControl';

export interface ChecklistFormProps {
  onSubmit: (data: ChecklistFormValue) => void;
  isEditing?: boolean;
  isLoading?: boolean;
  defaultValues?: ChecklistFormValue;
}

export function ChecklistForm(props: ChecklistFormProps) {
  const navigate = useNavigate();

  const getDefaultValues = (): ChecklistFormValue => {
    if (props.defaultValues) {
      return props.defaultValues;
    }

    return {
      name: '',
      resourceId: '',
      description: '',
      tasks: [checklistTaskFactory()],
    };
  };

  return (
    <Form<ChecklistFormValue>
      defaultValues={getDefaultValues()}
      experimentalZodSchema={checklistFormSchema}
    >
      {(control, formApi) => (
        <Box paddingBottom={20}>
          <VStack spacing={6} maxWidth={200}>
            <HStack spacing={4} width="100%">
              <Box width="100%">
                <FormField
                  type="text"
                  control={control}
                  name="name"
                  label={i18n.t('general.labels.name')}
                  isRequired
                />
              </Box>
              <Box width="100%">
                <FormField
                  type="choice"
                  control={control}
                  name="resourceId"
                  label={i18n.t('page.settings.generalChecklist.resources')}
                  options={[
                    {
                      value: EntityResourceIds.businessCase,
                      label: i18n.t('page.businessCase.labels.businessCase'),
                    },
                    {
                      value: EntityResourceIds.customer,
                      label: i18n.t('entity.customer.labels.customer'),
                    },
                    {
                      value: EntityResourceIds.interest,
                      label: i18n.t('entity.interest.labels.interest'),
                    },
                    {
                      value: EntityResourceIds.serviceCase,
                      label: i18n.t('page.workshop.labels.serviceCase'),
                    },
                    {
                      value: 'SALE_VEHICLE',
                      label: i18n.t('page.settings.generalChecklist.saleVehicle'),
                    },
                    {
                      value: 'SERVICE_VEHICLE',
                      label: i18n.t('page.settings.generalChecklist.serviceVehicle'),
                    },
                  ]}
                  isRequired
                />
              </Box>
            </HStack>
            <FormField
              type="textarea"
              control={control}
              name="description"
              minRows={4}
              label={i18n.t('general.labels.description')}
              isResizable
            />
            <TasksControl formApi={formApi} control={control} />
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  variant: 'secondary',
                  onClick: () =>
                    props.isEditing ? navigate(settingsRoutes.generalChecklist) : navigate(-1),
                  isDisabled: props.isLoading,
                },
                {
                  type: 'button',
                  title: i18n.t('general.actions.saveAndClose'),
                  onClick: () => formApi.handleSubmit(props.onSubmit)(),
                  isDisabled: props.isLoading,
                },
              ]}
            />
          </VStack>
        </Box>
      )}
    </Form>
  );
}

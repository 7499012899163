import {Label, Slider, SliderTick, TextInput} from 'platform/components';
import {HStack, Space, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

interface SourcingSliderProps extends TestIdProps {
  label?: string;
  labelTooltip?: string;
  min: number;
  max: number;
  marks?: SliderTick[];
  step?: number;
  value?: [number, number];
  hasMaxPlus?: boolean;
  onChange: (value: [number, number]) => void;
}

export function SourcingSlider(props: SourcingSliderProps) {
  const defaultMin = props.value?.[0] ?? props.min;
  const defaultMax = props.value?.[1] ?? props.max;
  const [inputMinValue, setInputMinValue] = useState<string | null>(defaultMin.toString());
  const [inputMaxValue, setInputMaxValue] = useState<string | null>(defaultMax.toString());
  const [isInputMinFocused, setIsInputMinFocused] = useState(false);
  const [isInputMaxFocused, setIsInputMaxFocused] = useState(false);

  const putValueInRange = (value: number) => Math.min(Math.max(value, props.min), props.max);

  const switchValuesIfNeeded = () => {
    if (
      isNotNil(inputMinValue) &&
      isNotNil(inputMaxValue) &&
      parseInt(inputMinValue) > parseInt(inputMaxValue)
    ) {
      setInputMinValue(inputMaxValue);
      setInputMaxValue(inputMinValue);
      props.onChange([defaultMax, defaultMin]);
    }
  };

  const formatMaxValue = (value: number) =>
    props.hasMaxPlus && value === props.max ? `${value}+` : `${value}`;

  const handleInputMinChange = (value: string | null) => {
    setInputMinValue(value);

    const parsedValue = isNotNil(value) ? parseInt(value) : NaN;
    props.onChange([isNaN(parsedValue) ? props.min : putValueInRange(parsedValue), defaultMax]);
  };

  const handleInputMinFocus = () => {
    setIsInputMinFocused(true);
    setInputMinValue(defaultMin.toString());
  };

  const handleInputMinBlur = () => {
    setIsInputMinFocused(false);
    switchValuesIfNeeded();
  };

  const handleInputMaxChange = (value: string | null) => {
    setInputMaxValue(value);

    const parsedValue = isNotNil(value) ? parseInt(value) : NaN;
    props.onChange([defaultMin, isNaN(parsedValue) ? props.max : putValueInRange(parsedValue)]);
  };

  const handleInputMaxFocus = () => {
    setIsInputMaxFocused(true);
    setInputMaxValue(defaultMax.toString());
  };

  const handleInputMaxBlur = () => {
    setIsInputMaxFocused(false);
    switchValuesIfNeeded();
  };

  return (
    <VStack>
      <Label tooltip={props.labelTooltip}>{props.label}</Label>
      <Slider
        isRange
        allowMoveStart
        ticks={false}
        step={props.step ?? 1}
        min={props.min}
        max={props.max}
        nonlinearTicks={props.marks}
        value={props.value || [props.min, props.max]}
        onChange={props.onChange}
        data-testid={suffixTestId('slider', props)}
      />
      <Space vertical={2} />
      <HStack spacing={2} align="center">
        <TextInput
          type="number"
          value={isInputMinFocused ? inputMinValue : defaultMin.toString()}
          onChange={handleInputMinChange}
          onFocus={handleInputMinFocus}
          onBlur={handleInputMinBlur}
        />
        –
        <TextInput
          type={isInputMaxFocused ? 'number' : 'text'}
          value={isInputMaxFocused ? inputMaxValue : formatMaxValue(defaultMax)}
          onChange={handleInputMaxChange}
          onFocus={handleInputMaxFocus}
          onBlur={handleInputMaxBlur}
        />
      </HStack>
    </VStack>
  );
}

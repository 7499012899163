import {ThemeColorPath} from 'platform/foundation';

const damageValueColorMap = {
  CRACKED: 'palettes.neutral.300.100',
  FADED_HEADLIGHTS: 'palettes.blue.70.100',
  MOISTURE_IN_THE_HEADLIGHT: 'palettes.green.60.100',
  LOOSE_IN_THE_HOLDER: 'palettes.red.60.100',
  FROM_STONES: 'palettes.orange.60.100',
  OTHER_DEFINE_IN_THE_NOTE: 'palettes.neutral.300.100',
  SCRATCHES: 'palettes.magenta.60.100',
  DENTS: 'palettes.purple.60.100',
  BAD_VARNISH: 'palettes.blue.30.100',
  CORROSION: 'palettes.teal.30.100',
  BAD_SAVING: 'palettes.green.30.100',
  AFTER_UNPROFESSIONAL_REPAIR: 'palettes.red.30.100',
  PEELED: 'palettes.yellow.60.100',
  SCRATCHED: 'palettes.teal.60.100',
  OTHER: 'palettes.magenta.30.100',
  EXCESSIVE_WEAR: 'palettes.neutral.300.100',
  EXCESSIVE_SOILING: 'palettes.blue.70.100',
  DAMAGE: 'palettes.green.60.100',
  SCRATCH: 'palettes.red.60.100',
  FIRE_DAMAGE: 'palettes.orange.60.100',
  FLUID_DAMAGE: 'palettes.yellow.60.100',
  OTHER_DEFINE_IN_NOTE: 'palettes.magenta.30.100',
} as const satisfies Record<string, ThemeColorPath>;

export const getDamageValueColor = (damageValue?: string) =>
  damageValue
    ? damageValueColorMap[damageValue as keyof typeof damageValueColorMap]
    : damageValueColorMap.OTHER;

import {isDefined} from 'shared';

import {MAX_CHUNK_PARTS} from '../constants/maxChunkParts';
import {SIZES} from '../constants/sizes';
import {RawFile} from '../types/RawFile';
import {RawFileWithCountParts} from '../types/RawFileWithCountParts';

function* availableSizesGenerator() {
  yield SIZES['5MB'];
  yield SIZES['10MB'];
  yield SIZES['15MB'];
  yield SIZES['20MB'];
  yield SIZES['25MB'];
  yield SIZES['50MB'];
}

function getChunkSize(size: number): number | null {
  const sizeIterator = availableSizesGenerator();

  if (size <= 0) {
    return null;
  }

  function findSize(): number | null {
    const {value, done} = sizeIterator.next();

    if (done) {
      return null;
    }

    return value * MAX_CHUNK_PARTS >= size ? value : findSize();
  }

  return findSize();
}

export function enhanceWithCountOfParts(file: RawFile): RawFileWithCountParts {
  const chunkSize = getChunkSize(file.size);
  const countOfParts = isDefined(chunkSize) ? Math.ceil(file.size / chunkSize) : 0;

  return {...file, chunkSize, countOfParts};
}

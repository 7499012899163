import {DataStatus} from 'platform/components';

import {Suspense, lazy} from 'react';

const OverviewV2 = lazy(() => import('./components/OverviewV2'));

export function AnalyticsCloud() {
  return (
    <Suspense fallback={<DataStatus minHeight={100} isLoading />}>
      <OverviewV2 />
    </Suspense>
  );
}

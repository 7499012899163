import {Button, ButtonGroup, DataStatus, DialogFooter} from 'platform/components';
import {Heading, HStack, Text, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isEmpty, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetUserPairedDevicesListQuery, useSignDocumentLocallyMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError, sortDevicesByState} from '@dms/shared';

import {suffixTestId} from 'shared';

import {DeviceBox} from '../../components/DeviceBox';
import {useDocumentSelection} from '../../contexts/DocumentSelectionProvider';
import {StepContentProps} from '../../types/StepContentProps';
import {getContextSessionName} from '../../utils/getContextSessionName';

export function DeviceSelectStep(props: StepContentProps) {
  const {signatureDocumentFileIds} = useDocumentSelection();
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);

  const {
    data: pairedDevices,
    refetch,
    isLoading,
    isError,
    isFetching,
  } = useGetUserPairedDevicesListQuery();

  const [sign, {isLoading: isSigning}] = useSignDocumentLocallyMutation({
    fixedCacheKey: 'singLocally',
  });

  useEffect(() => {
    if (isNilOrEmpty(pairedDevices)) {
      return;
    }

    setSelectedDeviceId(pairedDevices?.selectedDeviceId ?? null);
  }, [pairedDevices]);

  const handleSingLocally = () =>
    sign({
      files: signatureDocumentFileIds,
      pairedDeviceId: selectedDeviceId,
      sessionName: getContextSessionName(props.context, signatureDocumentFileIds.length),
    })
      .unwrap()
      .then(() => props.setSigningStep('OnSite_sentToDevice'))
      .catch((err) => {
        handleApiError(err, {silent: true});
        props.setSigningStep('OnSite_error');
      })
      .finally(props.refreshData);

  const handleSingByQrCode = () =>
    sign({
      files: signatureDocumentFileIds,
      pairedDeviceId: null,
      sessionName: getContextSessionName(props.context, signatureDocumentFileIds.length),
    })
      .unwrap()
      .then(() => props.setSigningStep('OnSite_qrCode'))
      .catch((err) => {
        handleApiError(err, {silent: true});

        props.setSigningStep('OnSite_error');
      })
      .finally(props.refreshData);

  const sortedDevices = sortDevicesByState(pairedDevices?.pairedDevices);
  const description = isEmpty(sortedDevices)
    ? i18n.t('entity.document.labels.choosePairedDeviceEmptyDescription')
    : i18n.t('entity.document.labels.choosePairedDeviceDescription');

  return (
    <VStack spacing={8}>
      <VStack spacing={4}>
        <Heading size={3}>{i18n.t('entity.document.labels.choosePairedDevice')}</Heading>
        <Text size="small" color="secondary">
          {description}
        </Text>
      </VStack>

      <VStack spacing={4}>
        <DataStatus isLoading={isLoading} isError={isError}>
          {sortedDevices?.map((device, index) => (
            <DeviceBox
              key={device.pairedDeviceId}
              device={device}
              data-testid={suffixTestId(`[${index}]`, props)}
              selectedDeviceId={selectedDeviceId}
              setSelectedDeviceId={setSelectedDeviceId}
            />
          ))}
        </DataStatus>
        <ButtonGroup>
          <Button
            variant="link"
            leftIcon="content/add_circle"
            to={settingsRoutes.signatures}
            title={i18n.t('entity.settings.labels.connectDevice')}
            data-testid={suffixTestId('connectDevice', props)}
          />

          <Button
            variant="link"
            leftIcon="navigation/refresh"
            onClick={refetch}
            title={i18n.t('general.actions.refresh')}
            data-testid={suffixTestId('refresh', props)}
            isLoading={isFetching && !isLoading}
          />
        </ButtonGroup>
      </VStack>

      <DialogFooter>
        <HStack justify="space-between">
          <Button
            title={i18n.t('entity.document.actions.goToModeSelect')}
            variant="dangerOutlined"
            onClick={() => props.setSigningStep('modeSelect')}
            isDisabled={isSigning}
            data-testid={suffixTestId('goToModeSelect', props)}
          />

          <ButtonGroup>
            <Button
              title={i18n.t('entity.document.actions.useQrCodeInstead')}
              variant="secondary"
              onClick={handleSingByQrCode}
              isLoading={isSigning}
              data-testid={suffixTestId('useQrCodeInstead', props)}
            />
            <Button
              title={i18n.t('general.actions.continue')}
              variant="primary"
              isDisabled={isNil(selectedDeviceId)}
              onClick={handleSingLocally}
              isLoading={isSigning}
              data-testid={suffixTestId('continue', props)}
            />
          </ButtonGroup>
        </HStack>
      </DialogFooter>
    </VStack>
  );
}

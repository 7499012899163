import {z} from 'zod';

export const SupplierOrderVariantFormSchema = z.object({
  name: z.string(),
  authorizationProfileId: z.string(),
  docSeriesId: z.string(),
  orderingWarehouseId: z.string(),
  defaultSupplierId: z.string().nullish(),
  additionalRequestingWarehouses: z.array(z.string()).nullish(),
  orderTemplateId: z.string().nullish(),
  isOrderUseIntegration: z.boolean().default(false),
});

export type SupplierOrderVariantForm = z.infer<typeof SupplierOrderVariantFormSchema>;

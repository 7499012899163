import {isNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {ScopeValues} from '@dms/api';

export const evaluateBranch = (
  scopes: string[],
  evaluatorParams: ScopeValues | undefined,
  activeBranchId: string
) => {
  if (isNil(evaluatorParams) || !('branchId' in evaluatorParams)) {
    return false;
  }
  if (evaluatorParams.branchId === 'SKIP') {
    return true;
  }

  // scopes is array at maximal size of 2, so this check is fine
  if (scopes.includes('ALL_BRANCHES')) {
    return true;
  }
  if (scopes.includes('MY_BRANCHES')) {
    return isTrue(evaluatorParams.branchId === activeBranchId);
  }

  return false;
};

import {Button, Flag, Parameters} from 'platform/components';
import {Box, Heading, HStack, Show, StorageImage, VStack} from 'platform/foundation';
import styled from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {SimilarVehicleResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';

import {composePath, RequiredTestIdProps, suffixTestId} from 'shared';

import {thumbnailUrl} from '../../../constants/thumbnailUrl';
import {useVehicleConditionEvaluation} from '../../../hooks/useVehicleConditionEvaluation';
import {useVehiclePhotosPermissions} from '../../../hooks/useVehiclePhotosPermissions';
import {VehicleRecommendationType} from '../../../types/VehicleRecommendationType';
import {getVehicleCoverPhoto} from '../../../utils/getVehicleCoverPhoto';
import {getVehicleTitle} from '../../../utils/getVehicleTitle';
import {VehicleConditionEvaluation} from '../../EntityHeader/components/VehicleConditionEvaluation';
import {useVehicleInformationParameters} from '../hooks/useVehicleInformationParameters';

export type VehicleSelectionType = 'createSaleProcess' | 'createServiceProcess' | 'choose';

interface VehiclePreviewProps extends RequiredTestIdProps {
  vehicle: SimilarVehicleResponseBody;
  shouldRecommendVehiclesFor?: VehicleRecommendationType;
  onSelect: (selectionType: VehicleSelectionType) => void;
}

export function VehiclePreview(props: VehiclePreviewProps) {
  const {canReadVehicleAlbumPhotos} = useVehiclePhotosPermissions({
    vehicleId: props.vehicle.id,
  });

  const coverPhoto = getVehicleCoverPhoto(props.vehicle.photos ?? []);
  const vehicleTitle = props.vehicle.title ?? getVehicleTitle(props.vehicle);

  const vehicleParameters = useVehicleInformationParameters(props.vehicle);
  const [vehicleConditionEvaluation] = useVehicleConditionEvaluation(props.vehicle.id);

  const chooseVehicleButtonProps = match([
    props.shouldRecommendVehiclesFor,
    isNil(props.vehicle.serviceVehicleId),
    isNil(props.vehicle.saleVehicleId),
  ])
    .with(
      ['service', true, Pattern.boolean],
      always({
        title: i18n.t('entity.vehicle.actions.createServiceVehicle'),
        onClick: () => props.onSelect('createServiceProcess'),
      })
    )
    .with(
      ['sale', Pattern.boolean, true],
      always({
        title: i18n.t('entity.vehicle.actions.createSaleVehicle'),
        onClick: () => props.onSelect('createSaleProcess'),
      })
    )
    .otherwise(always({title: 'choose', onClick: () => props.onSelect('choose')}));

  return (
    <HStack spacing={4}>
      <ImageFilterDiv $isArchived={props.vehicle.isArchived}>
        <Box
          borderRadius="small"
          overflow="hidden"
          width={25}
          height={18}
          data-testid={suffixTestId(`image`, props)}
        >
          <StorageImage
            shouldUseOriginal={!coverPhoto || !canReadVehicleAlbumPhotos}
            url={canReadVehicleAlbumPhotos ? (coverPhoto?.url ?? thumbnailUrl) : thumbnailUrl}
            width={25}
            height={18}
            alt={vehicleTitle}
            fit="cover"
          />
        </Box>
      </ImageFilterDiv>

      <VStack justify="center">
        <Show when={props.vehicle.isArchived}>
          <Flag colorScheme="neutral" label={i18n.t('entity.vehicle.labels.archived')} />
        </Show>

        <Heading size={4} data-testid={suffixTestId(`title`, props)}>
          {vehicleTitle}
        </Heading>

        <Parameters
          parameters={vehicleParameters}
          size="small"
          color="secondary"
          appendix={
            <VehicleConditionEvaluation
              size="small"
              color="secondary"
              vehicleCondition={vehicleConditionEvaluation}
              data-testid={suffixTestId(`overallConditions`, props)}
            />
          }
        />
      </VStack>

      <HStack justify="flex-end" spacing={2} grow={1}>
        <Button
          variant="outlined"
          title={i18n.t('entity.vehicle.labels.detail')}
          leftIcon="action/launch"
          to={composePath(vehiclesRoutes.editServiceVehicle, {params: {id: props.vehicle.id}})}
        />

        <Button {...chooseVehicleButtonProps} />
      </HStack>
    </HStack>
  );
}

const ImageFilterDiv = styled.div<{$isArchived: boolean | null}>`
  ${({$isArchived}) => $isArchived && `filter: grayscale(100%)`}
`;

import {openDialog, closeCurrentDialog, Label} from 'platform/components';
import {Box, Link, Show, Space} from 'platform/foundation';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetOccupiedSpaceQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {suffixTestId, TestIdProps} from 'shared';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {StorageSectorsDialog} from '../StorageSectorsDialog/StorageSectorsDialog';

export interface ParkingPlaceProps extends TestIdProps {
  recordId: string;
  withLabel?: boolean;
}

export function ParkingPlace(props: ParkingPlaceProps) {
  const {data: parkingPlace} = useGetOccupiedSpaceQuery(
    {recordId: props.recordId ?? '', spaceType: 'VEHICLE'},
    {skip: isNilOrEmpty(props.recordId)}
  );

  const [canChangeParkingPlace] = usePermissions({
    permissionKeys: ['vehicleChangeParkingPlace'],
  });

  const onParkingPlaceClick = () => {
    openDialog(
      <StorageSectorsDialog
        spaceType="VEHICLE"
        recordId={props.recordId}
        onClose={closeCurrentDialog}
        selectedSpace={
          isNotNil(parkingPlace?.number) && parkingPlace?.sectorId
            ? {number: parkingPlace.number, sectorId: parkingPlace.sectorId}
            : undefined
        }
        data-testid={testIds.vehicles.detail('parkingPlace')}
      />,
      {
        title: i18n.t('entity.vehicle.labels.assignParkingPlace'),
        size: 'large',
        scrollBehavior: 'inside',
        'data-testid': suffixTestId('assignParkingPlace', props),
      }
    );
  };

  const parkingPlaceName =
    `${parkingPlace?.prefix ?? ''}${parkingPlace?.number ?? ''}` || undefined;

  return (
    <Box>
      <Show when={props.withLabel}>
        <Label>{i18n.t('general.labels.parkingPlace')}</Label>
        <Space vertical={1} />
      </Show>
      <Link
        size="small"
        isDisabled={!canChangeParkingPlace}
        leftIcon={!props.withLabel ? 'maps/local_parking' : undefined}
        title={parkingPlaceName ?? i18n.t('entity.vehicle.labels.assignedParkingPlace')}
        onClick={onParkingPlaceClick}
        data-testid={suffixTestId('parking', props)}
      />
    </Box>
  );
}

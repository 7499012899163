import {Button, Dropdown, DropdownItem, Label} from 'platform/components';
import {Heading, HStack} from 'platform/foundation';

import {useDispatch} from 'react-redux';

import {
  useGetCurrentUserInfoQuery,
  useSetUserSettingsItemMutation,
  SetUserSettingsItemApiArg,
  setActiveBranchId,
} from '@dms/api';
import i18n from '@dms/i18n';
import {useGetCurrentBranch, handleApiError, EMPTY_PLACEHOLDER} from '@dms/shared';

import {useBoolean} from 'shared';

export function BranchSelect() {
  const dispatch = useDispatch();
  const [dropdownOpen, setOpen, setClosed] = useBoolean();

  const {data: currentUserInfo} = useGetCurrentUserInfoQuery();
  const {activeBranchId} = useGetCurrentBranch();
  const [updateUserSettings, {isLoading: isChangingBranch}] = useSetUserSettingsItemMutation();

  const getBranchByActiveId =
    currentUserInfo?.branches?.find((b) => b.id === activeBranchId) ?? null;

  const handleBranchChange = (value: string) => {
    const arg: SetUserSettingsItemApiArg = {
      settingKey: 'active_branch',
      userSettingRequestBody: {value},
    };

    updateUserSettings(arg)
      .unwrap()
      .then(() => {
        dispatch(setActiveBranchId(value));
      })
      .catch(handleApiError);
  };

  if (!currentUserInfo?.branches || currentUserInfo?.branches?.length === 0) {
    return (
      <Heading
        data-testid="layout-header-myProfileAndSettings-activeBranch"
        headingLevel={2}
        size={4}
      >
        {getBranchByActiveId?.name ?? EMPTY_PLACEHOLDER}
      </Heading>
    );
  }

  return (
    <HStack align="center">
      <Label>{`${i18n.t('entity.branch.labels.branch')}:`}</Label>
      <Dropdown
        closeOnSelect
        onOpen={() => setOpen()}
        onClose={() => setClosed()}
        dropdownControl={
          <Button
            isLoading={isChangingBranch}
            size="small"
            variant="ghost"
            rightIcon={dropdownOpen ? 'navigation/arrow_drop_up' : 'navigation/arrow_drop_down'}
            title={getBranchByActiveId?.name ?? EMPTY_PLACEHOLDER}
          />
        }
      >
        {currentUserInfo.branches.map((branch) => (
          <DropdownItem
            key={branch.id}
            label={branch.name}
            onClick={() => handleBranchChange(branch.id)}
          />
        ))}
      </Dropdown>
    </HStack>
  );
}

import {AnimatePresence} from 'framer-motion';
import {Separator} from 'platform/components';
import {Box, Hide, HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useEffect, useState} from 'react';

import {always, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetSignableDocumentTransactionQuery, useGetTransactionQuery} from '@dms/api';

import {suffixTestId} from 'shared';

import {DeviceSelectStep} from '../(Sections)/DeviceSelectStep/DeviceSelectStep';
import {DocumentSelectionList} from '../(Sections)/DocumentSelectionList/DocumentSelectionList';
import {ErrorStep} from '../(Sections)/ErrorStep/ErrorStep';
import {ModeSelectStep} from '../(Sections)/ModeSelectStep/ModeSelectStep';
import {OnSiteDocumentMenu} from '../(Sections)/OnSiteDocumentMenu/OnSiteDocumentMenu';
import {RemoteDocumentMenu} from '../(Sections)/RemoteDocumentMenu/RemoteDocumentMenu';
import {SendToDeviceStep} from '../(Sections)/SendToDeviceStep/SendToDeviceStep';
import {SendToSignatoriesStep} from '../(Sections)/SendToSignatoriesStep/SendToSignatoriesStep';
import {SignatoriesSelectStep} from '../(Sections)/SignatoriesSelectStep/SignatoriesSelectStep';
import {SignByQrCodeStep} from '../(Sections)/SignByQrCodeStep/SignByQrCodeStep';
import {STEPPER_MIN_HEIGHT} from '../constants/STEPPER_MIN_HEIGHT';
import {DocumentSelectionProvider} from '../contexts/DocumentSelectionProvider';
import {DocumentStateProvider} from '../contexts/DocumentStateProvider';
import {SignDocumentStep} from '../types/SignDocumentStep';
import {SignDocumentStepperProps} from '../types/SignDocumentStepperProps';
import {StepContentProps} from '../types/StepContentProps';
import {isErrorStep} from '../utils/isErrorStep';
import {isSignatureOnSite} from '../utils/isSignatureOnSite';
import {AnimateHeightObserver} from './AnimateHeightObserver';

const DEFAULT_SIGNING_STEP: SignDocumentStep = 'modeSelect';

export function SignDocumentStepper(props: SignDocumentStepperProps) {
  const [signingStep, setSigningStep] = useState<SignDocumentStep>(DEFAULT_SIGNING_STEP);

  const {currentData: signableDocument, isLoading: isLoadingDocument} =
    useGetSignableDocumentTransactionQuery(
      {fileId: props.fileId ?? ''},
      {skip: isNilOrEmpty(props.fileId), refetchOnMountOrArgChange: true}
    );

  const {
    currentData: transaction,
    isLoading: isLoadingTransaction,
    isError: isTransactionError,
  } = useGetTransactionQuery(
    {transactionId: signableDocument?.transactionId ?? ''},
    {skip: isNilOrEmpty(signableDocument?.transactionId), refetchOnMountOrArgChange: true}
  );

  const isLoading = isLoadingDocument || isLoadingTransaction;

  useEffect(() => {
    if (signingStep !== DEFAULT_SIGNING_STEP || isNil(transaction) || isTransactionError) {
      return;
    }

    const currentStep =
      transaction.tasks?.[0]?.transactionType === 'local'
        ? 'OnSite_qrCode'
        : 'Remote_sentToSignatories';

    setSigningStep(currentStep);
    // rules want [signingStep], we use it for checking initial state, and using it would cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const contentProps: StepContentProps = {
    customerId: props.customerId,
    context: props.contextTarget,
    transaction,
    signingStep,
    setSigningStep,
    fileId: props.fileId,
    refreshData: props.refreshData,
    'data-testid': suffixTestId(signingStep, props),
  };

  const documentList = match(signingStep)
    .with('modeSelect', always(<DocumentSelectionList key="DocumentSelectionList" />))
    .when(isSignatureOnSite, always(<OnSiteDocumentMenu key="OnSiteDocumentMenu" />))
    .otherwise(always(<RemoteDocumentMenu key="RemoteDocumentMenu" signingStep={signingStep} />));

  const content = match(signingStep)
    .with('modeSelect', always(<ModeSelectStep {...contentProps} />))
    .with('OnSite_deviceSelect', always(<DeviceSelectStep {...contentProps} />))
    .with('OnSite_qrCode', always(<SignByQrCodeStep {...contentProps} />))
    .with('OnSite_sentToDevice', always(<SendToDeviceStep {...contentProps} />))
    .with('OnSite_error', always(<ErrorStep {...contentProps} />))
    .with('Remote_signatoriesSelect', always(<SignatoriesSelectStep {...contentProps} />))
    .with('Remote_sentToSignatories', always(<SendToSignatoriesStep {...contentProps} />))
    .with('Remote_error', always(<ErrorStep {...contentProps} />))
    .otherwise(always(null));

  return (
    <Box minHeight={STEPPER_MIN_HEIGHT}>
      <DocumentSelectionProvider
        {...props}
        isLoading={isLoading}
        transaction={transaction}
        isTransactionError={isTransactionError}
      >
        <DocumentStateProvider>
          <AnimateHeightObserver>
            <HStack minHeight={STEPPER_MIN_HEIGHT}>
              <Hide when={isErrorStep(signingStep)}>
                <Box minWidth={80} maxWidth={80} maxHeight={200} overflowY="auto">
                  <AnimatePresence>{documentList}</AnimatePresence>
                </Box>

                <Separator orientation="vertical" />
              </Hide>

              <Box paddingVertical={4} width="100%">
                {content}
              </Box>
            </HStack>
          </AnimateHeightObserver>
        </DocumentStateProvider>
      </DocumentSelectionProvider>
    </Box>
  );
}

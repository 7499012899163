import {
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  isCurrency,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Grid, GridItem, Hide, HStack, Icon, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {z} from 'zod';

import {Fragment, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {defaultTo, isNil, isNotNil, join, not, omit, sum} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  AvailableDiscount,
  BaseDirectSalePriceType,
  DiscountSourceType,
  GetDirectSaleLabourItemResponse,
  useGetHandlingUnitQuery,
  usePatchDirectSaleLabourItemMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  DEFAULT_CURRENCY,
  getDecimalFromPercentage,
  getPercentageFromDecimal,
  handleApiError,
  useGetVatRatesOptions,
  useWorkTypeOptions,
} from '@dms/shared';

import {
  buildArray,
  generateHashFromObjects,
  Nullish,
  RequiredTestIdProps,
  suffixTestId,
} from 'shared';

import {EditLabourItemForm, EditLabourItemFormSchema} from '../../../../types/EditLabourItemForm';
import {RequestTabLabourItemMechanics} from './RequestTabLabourItemMechanics';
import {RequestTabLabourItemPrice} from './RequestTabLabourItemPrice';

const TOTAL_RATIO = 100;
const PERCENTAGE_BASE = 100;

export interface RequestTabLabourItemEditProps extends RequiredTestIdProps {
  directSaleId: string;
  item: GetDirectSaleLabourItemResponse;
  isEditingDisabled?: boolean;
  authorizationProfileId?: string;
  onAfterSubmit?: () => void;
  onClose: () => void;
}

export function RequestTabLabourItemEdit(props: RequestTabLabourItemEditProps) {
  const labourItem = props.item;
  const formatCurrency = useFormatCurrency();

  const [lastUpdatedPricePerUnit, setLastUpdatedPricePerUnit] = useState<number | Nullish>(null);

  const isTimeNormType = labourItem?.priceType === 'SVCPRICETYPE_TIME_NORM';
  const isCooperationType = labourItem?.priceType === 'SVCPRICETYPE_COOPERATION';
  const isDirectPriceType = labourItem?.priceType === 'SVCPRICETYPE_DIRECT_PRICE';

  const {data: handlingUnit, isLoading: isHandlingUnitLoading} = useGetHandlingUnitQuery(
    {
      id: defaultTo('', labourItem?.quantity?.unit),
    },
    {skip: isNil(labourItem?.quantity?.unit) || not(isCooperationType)}
  );

  const [patchLabourItem, {isLoading: isPatching}] = usePatchDirectSaleLabourItemMutation();

  const {getOptionsWithSelectedValue} = useWorkTypeOptions();

  const [vatOptions] = useGetVatRatesOptions({fullLabel: true});

  const isLoading = isHandlingUnitLoading;

  const isEditingDisabled = props.isEditingDisabled || not(labourItem.itemEditingAllowed);

  const handleSubmit: FormSubmitHandler<EditLabourItemForm> = async (data, setErrors) => {
    if (not(isMechanicsRatioEqualToTotalRatio)) {
      return setErrors(
        data.assignMechanics.map((_, index) => ({
          name: `assignMechanics.${index}.ratio`,
          message: i18n.t('entity.addWork.labels.ratioSumError'),
        }))
      );
    }

    await patchLabourItem({
      directSaleId: props.directSaleId,
      itemId: props.item.id,
      body: {
        ...data,
        basePriceType: defaultTo(null, labourItem?.priceType) as BaseDirectSalePriceType,
        assignMechanics: data.assignMechanics
          ?.filter((mechanic) => isNotNilOrEmpty(mechanic?.id))
          .map((mechanic, index) => ({
            ...omit(['ratio'], mechanic),
            id: defaultTo('', mechanic.id),
            isDefault: index === 0,
          })),
        discount: {
          isDiscountEnable: data.discount.isDiscountEnable,
          discountSourceType: data.discount.isDiscountEnable
            ? data.discount.discountSourceType
            : null,
          discountRate: data.discount.isDiscountEnable
            ? (getDecimalFromPercentage(data.discount.discountRate) ?? 0)
            : 0,
          isDoNotApplyDiscount: data.discount.isDoNotApplyDiscount,
        },
      },
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(props.onAfterSubmit)
      .then(props.onClose)
      .catch(handleApiError);
  };

  const handleQuantityChange = (
    quantity: number | Nullish,
    formApi: UseFormReturn<EditLabourItemForm>
  ) => {
    if (isNil(quantity)) {
      return;
    }

    validateQuantity(quantity, formApi);

    const mechanics = formApi.getValues('assignMechanics');

    if (mechanics.length === 1) {
      return formApi.setValue(`assignMechanics.0.amount`, quantity);
    }

    mechanics.forEach(
      (mechanic, index) =>
        isNotNil(mechanic.amount) &&
        formApi.setValue(
          `assignMechanics.${index}.ratio`,
          Math.round((mechanic.amount / quantity) * PERCENTAGE_BASE)
        )
    );
  };

  const handlePriceReset = (formApi: UseFormReturn<EditLabourItemForm>) => {
    const isCustomPrice = formApi.watch('isCustomPrice');

    if (isCustomPrice) {
      return;
    }

    formApi.setValue(
      'sellingPricePerUnit',
      defaultTo(0, labourItem?.baseSellingPricePerUnit?.amount)
    );
    formApi.setValue(
      'purchasePricePerUnit',
      defaultTo(0, labourItem?.basePurchasePricePerUnit?.amount)
    );
    formApi.setValue('vatType', defaultTo('', labourItem?.baseSellingPriceVat?.type));
  };

  const handleDiscountChange = (
    index: number,
    formApi: UseFormReturn<EditLabourItemForm>,
    discountSourceType: DiscountSourceType,
    discountRate: number | Nullish
  ) => {
    formApi.clearErrors(`discount.availableDiscounts.${index}.discountRate`);

    const validationResult = discountRateSchema.safeParse(discountRate);

    if (validationResult.success) {
      formApi.setValue('discount.discountSourceType', discountSourceType);
      formApi.setValue('discount.discountRate', discountRate);
      return;
    }

    const errorMessages = validationResult.error?.errors.map((error) => error.message).join(' ');
    formApi.setError(`discount.availableDiscounts.${index}.discountRate`, {message: errorMessages});
  };

  const handleDoNotApplyDiscount = (formApi: UseFormReturn<EditLabourItemForm>) => {
    const shouldNotApplyDiscount = formApi.watch('discount.isDoNotApplyDiscount');

    if (shouldNotApplyDiscount) {
      formApi.setValue('discount.isDiscountEnable', false);
      formApi.setValue('discount.discountRate', 0);

      // Get the current list of available discounts
      const availableDiscounts = formApi.getValues('discount.availableDiscounts') || [];

      availableDiscounts.forEach((_, index) => {
        formApi.setValue(`discount.availableDiscounts.${index}.discountRate`, 0);
      });
    }
  };

  const validateQuantity = (value: number, formApi: UseFormReturn<EditLabourItemForm>) => {
    const valueToValidate = defaultTo(0, value);

    formApi.clearErrors('quantity');

    const validationResult = quantitySchema.safeParse(valueToValidate);

    if (validationResult.success) {
      return;
    }

    const errorMessages = validationResult.error?.errors.map((error) => error.message).join(' ');
    formApi.setError('quantity', {message: errorMessages});
  };

  const defaultAssignedMechanics = [{id: null, costCenterId: null, amount: 0, ratio: 0}];

  const mappedAssignedMechanics = labourItem?.assignMechanics?.map((mechanic) => ({
    id: defaultTo(null, mechanic?.id),
    costCenterId: defaultTo(null, mechanic?.costCenterId),
    amount: defaultTo(0, mechanic?.amount),
    ratio: defaultTo(0, getRatioFromAmount(mechanic?.amount, labourItem.quantity?.amount)),
  }));

  const purchasePriceCurrency = labourItem?.purchasePricePerUnit?.currency;
  const sellingPriceCurrency = labourItem?.sellingPricePerUnit?.currency;

  const baseVat = vatOptions.find((vat) => vat.value === labourItem?.baseSellingPriceVat?.type);

  const basePrices = join(
    ', ',
    buildArray()
      .when(
        labourItem?.priceType === 'SVCPRICETYPE_TIME_NORM',
        `${i18n.t('general.labels.default')}: ${formatCurrency(
          defaultTo(0, labourItem?.baseSellingPricePerUnit?.amount),
          defaultTo(DEFAULT_CURRENCY, labourItem?.baseSellingPricePerUnit?.currency)
        )}`
      )
      .when(
        labourItem?.priceType !== 'SVCPRICETYPE_TIME_NORM',
        `${i18n.t('general.labels.default')}: ${formatCurrency(
          defaultTo(0, labourItem?.basePurchasePricePerUnit?.amount),
          defaultTo(DEFAULT_CURRENCY, labourItem?.basePurchasePricePerUnit?.currency)
        )}, ${formatCurrency(
          defaultTo(0, labourItem?.baseSellingPricePerUnit?.amount),
          defaultTo(DEFAULT_CURRENCY, labourItem?.baseSellingPricePerUnit?.currency)
        )}`
      )

      .add(`${i18n.t('general.labels.vat')}: ${baseVat?.fieldLabel}`)
  );

  const activeDiscount = labourItem?.itemsDiscounts.availableDiscounts?.find(
    (discount) => discount.isSet
  );

  const availableDiscounts =
    labourItem?.itemsDiscounts.availableDiscounts?.map((availableDiscount) => ({
      ...availableDiscount,
      discountRate: getPercentageFromDecimal(availableDiscount.discountRate) ?? 0,
    })) ?? [];

  const defaultValues: EditLabourItemForm = {
    name: labourItem?.name,
    number: labourItem?.number,
    workType: labourItem?.workType,
    assignMechanics: defaultTo(defaultAssignedMechanics, mappedAssignedMechanics),
    quantity: labourItem?.quantity?.amount,
    sellingPricePerUnit: defaultTo(0, labourItem?.sellingPricePerUnit?.amount),
    purchasePricePerUnit: defaultTo(0, labourItem?.purchasePricePerUnit?.amount),
    vatType: labourItem?.sellingPriceVat?.type,
    vatFreeCode: labourItem?.sellingPriceVat?.freeCode,
    isUnitPriceWithVat: defaultTo(false, labourItem?.isUnitPriceWithVat),
    isCustomPrice: defaultTo(false, labourItem?.isCustomPrice),
    discount: {
      isDoNotApplyDiscount: defaultTo(false, labourItem?.itemsDiscounts.isDoNotApplyDiscount),
      isDiscountEnable: defaultTo(false, labourItem?.itemsDiscounts.isDiscountEnable),
      discountSourceType: activeDiscount?.discountSourceType,
      discountRate: getPercentageFromDecimal(activeDiscount?.discountRate) ?? 0,
      availableDiscounts,
    },
    shouldGetCurrentPrice: false,
  };

  return (
    <DataStatus isLoading={isLoading} minHeight={140} key={props.item.id}>
      <Form<EditLabourItemForm>
        key={generateHashFromObjects(labourItem)}
        defaultValues={defaultValues}
        experimentalZodSchema={EditLabourItemFormSchema}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => {
          const isCustomPrice = formApi.watch('isCustomPrice');
          const discount = formApi.watch('discount');

          const handlePriceChange = (newPricePerUnit: number) => {
            if (newPricePerUnit === lastUpdatedPricePerUnit) {
              return formApi.setValue('shouldGetCurrentPrice', false);
            }

            formApi.setValue('sellingPricePerUnit', newPricePerUnit);
            formApi.setValue('shouldGetCurrentPrice', false);
            setLastUpdatedPricePerUnit(newPricePerUnit);
          };

          return (
            <VStack spacing={4}>
              <FormField
                name="name"
                type="text"
                control={control}
                label={i18n.t('general.labels.description')}
                isDisabled={isEditingDisabled}
                data-testid={suffixTestId('inputs.description', props)}
              />

              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    name="number"
                    type="text"
                    control={control}
                    label={i18n.t('general.labels.number')}
                    isDisabled={isEditingDisabled}
                    data-testid={suffixTestId('inputs.number', props)}
                  />
                </Box>

                <Box flex={1}>
                  <FormField
                    name="workType"
                    type="choice"
                    control={control}
                    label={i18n.t('entity.warehouse.labels.workCategory')}
                    options={getOptionsWithSelectedValue(labourItem?.workType)}
                    isDisabled={isEditingDisabled}
                    menuInPortal
                    data-testid={suffixTestId('inputs.workType', props)}
                  />
                </Box>
              </HStack>

              <Card variant="inlineGrey">
                <RequestTabLabourItemMechanics
                  control={control}
                  formApi={formApi}
                  authorizationProfileId={props.authorizationProfileId}
                  isEditingDisabled={isEditingDisabled}
                  data-testid={suffixTestId('mechanics', props)}
                />

                <Separator />

                <VStack spacing={4}>
                  <HStack spacing={4}>
                    <Hide when={isCooperationType}>
                      <Box flex={1}>
                        <FormField
                          name="quantity"
                          type="number"
                          control={control}
                          label={i18n.t('entity.warehouse.labels.quantity')}
                          onChange={(value) => handleQuantityChange(value, formApi)}
                          suffix={defaultTo('', handlingUnit?.name)}
                          isDisabled={isDirectPriceType || isEditingDisabled}
                          data-testid={suffixTestId('inputs.quantity', props)}
                        />
                      </Box>
                    </Hide>

                    <Show when={isTimeNormType}>
                      <Box flex={1}>
                        <FormField
                          name="sellingPricePerUnit"
                          type="currency"
                          control={control}
                          label={i18n.t('entity.warehouse.labels.pricePerUnit')}
                          currency={
                            isCurrency(sellingPriceCurrency) ? sellingPriceCurrency : undefined
                          }
                          isDisabled={not(isCustomPrice) || isEditingDisabled}
                          data-testid={suffixTestId('inputs.sellingPricePerUnit', props)}
                        />
                      </Box>
                    </Show>

                    <Hide when={isTimeNormType}>
                      <Box flex={1}>
                        <FormField
                          name="purchasePricePerUnit"
                          type="currency"
                          control={control}
                          label={
                            isCooperationType
                              ? i18n.t('general.labels.purchasePrice')
                              : i18n.t('entity.warehouse.labels.purchasePricePerUnit')
                          }
                          currency={
                            isCurrency(purchasePriceCurrency) ? purchasePriceCurrency : undefined
                          }
                          isDisabled
                          data-testid={suffixTestId('inputs.purchasePricePerUnit', props)}
                        />
                      </Box>

                      <Box flex={1}>
                        <FormField
                          name="sellingPricePerUnit"
                          type="currency"
                          control={control}
                          label={
                            isCooperationType
                              ? i18n.t('general.labels.sellingPrice')
                              : i18n.t('entity.warehouse.labels.sellingPricePerUnit')
                          }
                          currency={
                            isCurrency(sellingPriceCurrency) ? sellingPriceCurrency : undefined
                          }
                          isDisabled={not(isCustomPrice) || isEditingDisabled}
                          data-testid={suffixTestId('inputs.sellingPricePerUnit', props)}
                        />
                      </Box>
                    </Hide>

                    <Box flex={1}>
                      <FormField
                        name="vatType"
                        type="choice"
                        control={control}
                        label={i18n.t('entity.warehouse.labels.vat')}
                        options={vatOptions}
                        isDisabled={not(isCustomPrice) || isEditingDisabled}
                        isNotClearable
                        menuInPortal
                        data-testid={suffixTestId('inputs.vatType', props)}
                      />
                    </Box>

                    <Show when={isTimeNormType || isCooperationType}>
                      <Box flex={1} />
                    </Show>
                  </HStack>

                  <Show when={isCustomPrice}>
                    <HStack spacing={2}>
                      <Icon size={4} value="action/info" color="palettes.neutral.300.100" />
                      <Text
                        size="xSmall"
                        color="tertiary"
                        data-testid={suffixTestId('isCustomPriceHelper', props)}
                      >
                        {`${i18n.t(
                          'entity.warehouse.labels.customPriceDescription'
                        )} ${basePrices}.`}
                      </Text>
                    </HStack>
                  </Show>

                  <HStack>
                    <FormField
                      name="isCustomPrice"
                      type="switch"
                      control={control}
                      label={i18n.t('entity.warehouse.labels.customPrice')}
                      onChange={() => handlePriceReset(formApi)}
                      isDisabled={isEditingDisabled}
                      data-testid={suffixTestId('inputs.isCustomPrice', props)}
                    />
                  </HStack>

                  <HStack justify="space-between">
                    <FormField
                      name="discount.isDiscountEnable"
                      type="switch"
                      control={control}
                      label={i18n.t('general.labels.discount')}
                      isDisabled={isEditingDisabled || discount.isDoNotApplyDiscount}
                      data-testid={suffixTestId('inputs.isCustomDiscount', props)}
                    />
                    <FormField
                      control={control}
                      name="discount.isDoNotApplyDiscount"
                      label={i18n.t('entity.warehouse.labels.dontApplyDiscount')}
                      onChange={() => handleDoNotApplyDiscount(formApi)}
                      type="checkbox"
                      isDisabled={isEditingDisabled}
                      data-testid={suffixTestId('inputs.isDoNotApplyDiscount', props)}
                    />
                  </HStack>

                  <Show when={discount.isDiscountEnable}>
                    <Grid spacing={4} columns={4} align="center">
                      {discount.availableDiscounts?.map(
                        (discount: AvailableDiscount, index: number) => (
                          <Fragment key={discount.discountSourceType}>
                            <GridItem span={1}>
                              <FormField
                                name="discount.discountSourceType"
                                type="radio"
                                control={control}
                                options={[
                                  {
                                    label: discount.discountSourceTypeName,
                                    value: discount.discountSourceType,
                                    isDisabled: isEditingDisabled,
                                  },
                                ]}
                                onChange={() =>
                                  handleDiscountChange(
                                    index,
                                    formApi,
                                    discount.discountSourceType,
                                    discount.discountRate
                                  )
                                }
                                data-testid={suffixTestId('discountRate', props)}
                              />
                            </GridItem>

                            <GridItem span={1}>
                              <FormField
                                name={`discount.availableDiscounts.${index}.discountRate`}
                                type="number"
                                control={control}
                                suffix="%"
                                minStepperValue={0}
                                maxStepperValue={100}
                                decimalPlaces={2}
                                onChange={() =>
                                  handleDiscountChange(
                                    index,
                                    formApi,
                                    discount.discountSourceType,
                                    discount.discountRate
                                  )
                                }
                                isDisabled={not(discount.isChangeable) || isEditingDisabled}
                                data-testid={suffixTestId('discountRate', props)}
                              />
                            </GridItem>
                          </Fragment>
                        )
                      )}
                    </Grid>
                  </Show>
                </VStack>

                <Separator />

                <RequestTabLabourItemPrice
                  control={control}
                  directSaleId={props.directSaleId}
                  itemId={props.item.id}
                  onPriceChange={handlePriceChange}
                  data-testid={suffixTestId('prices', props)}
                />

                <HStack>
                  <Button
                    variant="link"
                    leftIcon="navigation/refresh"
                    title={i18n.t('entity.warehouse.actions.getCurrentPrice')}
                    onClick={() => formApi.setValue('shouldGetCurrentPrice', true)}
                    isDisabled={isEditingDisabled}
                    data-testid={suffixTestId('actions.getCurrentPrice', props)}
                  />
                </HStack>
              </Card>

              <Hide when={isEditingDisabled}>
                <DialogFooter>
                  <ButtonGroup align="right">
                    <Button
                      variant="secondary"
                      title={i18n.t('general.actions.discard')}
                      onClick={props.onClose}
                      data-testid={suffixTestId('actions.discard', props)}
                    />
                    <FormButton
                      type="submit"
                      control={control}
                      title={i18n.t('general.actions.saveChanges')}
                      isLoading={isPatching}
                      data-testid={suffixTestId('actions.submit', props)}
                    />
                  </ButtonGroup>
                </DialogFooter>
              </Hide>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const quantitySchema = z.number().positive();

const discountRateSchema = z.number().min(0).max(100).nullish();

const getRatioFromAmount = (amount: number | Nullish, totalAmount: number | Nullish) =>
  isNotNil(amount) && isNotNil(totalAmount)
    ? Math.round((amount / totalAmount) * PERCENTAGE_BASE)
    : null;

const isMechanicsRatioEqualToTotalRatio = (
  assignMechanics: EditLabourItemForm['assignMechanics']
) => {
  const mechanicsRatioSum = sum(assignMechanics.map((mechanic) => mechanic?.ratio ?? 0));
  return mechanicsRatioSum === TOTAL_RATIO;
};

import {BaseFlagProps, openDeleteDialog, showNotification} from 'platform/components';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {always, defaultTo, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useDeleteServiceOrderIssueNoteMutation,
  useGetServiceOrderIssueNoteQuery,
  useGetUserQuery,
  useGetWarehouseHeaderDynamicActionsQuery,
  usePutWarehouseHeaderDynamicActionsMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {
  DEFAULT_CURRENCY,
  DetailTemplate,
  DetailTemplateHeader,
  getUserName,
  handleApiError,
  NavigationItem,
} from '@dms/shared';

import {composePath, EMPTY_PLACEHOLDER, parseDate, useNavigate} from 'shared';

import {useRefreshDataGrid} from 'features/datagrid';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {createHeaderActions} from '../../utils/createHeaderActions';
import {Documents} from './(sections)/Documents/Documents';
import {IssueNotes} from './(sections)/IssueNotes/IssueNotes';
import {Overview} from './(sections)/Overview/Overview';

export function ServiceOrderIssueDetail() {
  const {serviceOrderIssueNoteId} = useWarehouseParams();
  const navigate = useNavigate();
  const currencyFormatter = useFormatCurrency();
  const formatDate = useDateTimeFormatter();

  const [workItemsDatagridRef, refreshWorkItemsDatagrid] = useRefreshDataGrid();

  const {
    data: serviceOrderIssueNote,
    isLoading: isServiceOrderIssueNoteLoading,
    isError: hasServiceOrderIssueNoteError,
  } = useGetServiceOrderIssueNoteQuery({serviceOrderIssueNoteId});

  const {data: userWhoCreated, isLoading: isUserWhoCreated} = useGetUserQuery(
    {
      id: serviceOrderIssueNote?.createdBy as string,
    },
    {skip: isNilOrEmpty(serviceOrderIssueNote?.createdBy)}
  );

  const {data: userWhoCompleted, isLoading: isUserWhoCompletedLoading} = useGetUserQuery(
    {
      id: serviceOrderIssueNote?.updatedBy as string,
    },
    {skip: isNilOrEmpty(serviceOrderIssueNote?.createdBy)}
  );

  const {
    data: serviceOrderIssueNoteAction,
    isLoading: isServiceOrderIssueNoteActionLoading,
    isError: isServiceOrderIssueNoteActionError,
  } = useGetWarehouseHeaderDynamicActionsQuery(
    {id: serviceOrderIssueNoteId, resource: 'service-order-issue-note'},
    {skip: isNilOrEmpty(serviceOrderIssueNoteId)}
  );

  const [putServiceOrderIssueNoteAction, {isLoading: isPutServiceOrderIssueNoteActionLoading}] =
    usePutWarehouseHeaderDynamicActionsMutation();

  const [deleteServiceOrderIssueNote] = useDeleteServiceOrderIssueNoteMutation();

  const isLoading =
    isServiceOrderIssueNoteLoading ||
    isUserWhoCreated ||
    isUserWhoCompletedLoading ||
    isServiceOrderIssueNoteActionLoading;

  const isError = hasServiceOrderIssueNoteError || isServiceOrderIssueNoteActionError;

  const serviceOrderIssueTitle = `${i18n.t('page.warehouse.labels.serviceOrderIssue')} ${defaultTo(
    '',
    serviceOrderIssueNote?.number
  )}`;

  const priceWithVat = currencyFormatter(
    defaultTo(0, serviceOrderIssueNote?.sparePartsTotalPrice?.totalPurchasePrice?.withVat?.amount),
    defaultTo(
      DEFAULT_CURRENCY,
      serviceOrderIssueNote?.sparePartsTotalPrice?.totalPurchasePrice?.withVat?.currency
    ),
    2
  );

  const priceWithoutVat = currencyFormatter(
    defaultTo(
      0,
      serviceOrderIssueNote?.sparePartsTotalPrice?.totalPurchasePrice?.withoutVat?.amount
    ),
    defaultTo(
      DEFAULT_CURRENCY,
      serviceOrderIssueNote?.sparePartsTotalPrice?.totalPurchasePrice?.withoutVat?.currency
    ),
    2
  );

  const getFormattedDate = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDate('dateShort', parseDate(date));
  };

  const createdAt = `${i18n.t('general.labels.dateCreated')}: ${getFormattedDate(
    serviceOrderIssueNote?.createdAt
  )}`;

  const createdBy = `${i18n.t('general.labels.createdBy')}: ${getUserName(userWhoCreated)}`;

  const completedAt = match(serviceOrderIssueNote?.state)
    .returnType<string | undefined>()
    .with(
      'COMPLETED',
      always(
        `${i18n.t('general.labels.dateCompleted')}: ${getFormattedDate(
          serviceOrderIssueNote?.updatedAt
        )}`
      )
    )
    .otherwise(always(undefined));

  const completedBy = match(serviceOrderIssueNote?.state)
    .returnType<string | undefined>()
    .with(
      'COMPLETED',
      always(`${i18n.t('general.labels.completedBy')}: ${getUserName(userWhoCompleted)}`)
    )
    .otherwise(always(undefined));

  const headerFlags = () => {
    const flags = match(serviceOrderIssueNote?.state)
      .returnType<Omit<BaseFlagProps, 'size'>[]>()
      .with(
        'COMPLETED',
        always([{colorScheme: 'green', label: i18n.t('general.labels.completed')}])
      )
      .otherwise(always([{colorScheme: 'blue', label: i18n.t('general.labels.pending')}]));

    if (serviceOrderIssueNote?.partsReady) {
      flags.push({colorScheme: 'blue', isSubtle: true, label: i18n.t('general.labels.ready')});
    }

    return flags;
  };

  const handleActionClick = (actionKey: string) => {
    match(actionKey)
      .with('delete', () => {
        openDeleteDialog({
          text: i18n.t('entity.warehouse.actions.deleteEntity'),
          onConfirm: () =>
            deleteServiceOrderIssueNote({serviceOrderIssueNoteId})
              .unwrap()
              .then(() => {
                showNotification.success();
              })
              .then(() => navigate(composePath(warehouseRoutes.serviceOrderIssues)))
              .catch(handleApiError),
        });
      })
      .otherwise(() => {
        putServiceOrderIssueNoteAction({
          id: serviceOrderIssueNoteId,
          resource: 'service-order-issue-note',
          body: {actionKey},
        })
          .unwrap()
          .then(() => showNotification.success())
          .then(refreshWorkItemsDatagrid)
          .catch(handleApiError);
      });
  };

  const headerActions = createHeaderActions({
    actions: serviceOrderIssueNoteAction?.actions,
    callback: handleActionClick,
    isLoading: isPutServiceOrderIssueNoteActionLoading,
  });

  const header: DetailTemplateHeader = {
    title: serviceOrderIssueTitle,
    icon: 'custom/warehouse',
    parameters: [
      defaultTo('', serviceOrderIssueNote?.number),
      serviceOrderIssueNote?.serviceOrder.variant,
      createdAt,
      createdBy,
      completedAt,
      completedBy,
    ],
    recordId: serviceOrderIssueNote?.serviceOrderIssueNoteId,
    resourceId: EntityResourceIds.serviceOrderIssues,
    controls: ['ASSIGNEE'],
    flags: headerFlags(),
    actions: headerActions,
    primaryParameter: defaultTo(undefined, priceWithVat),
    secondaryParameter: `${priceWithoutVat} ${i18n.t('general.labels.w/oVat')}`,
  };

  const navigation: NavigationItem[] = [
    {
      id: 'serviceOrderIssue',
      label: i18n.t('page.warehouse.labels.overview'),
      href: composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
        params: {id: serviceOrderIssueNoteId},
      }),
      content: (
        <Overview
          serviceOrderIssueNote={serviceOrderIssueNote}
          isServiceOrderIssueLoading={isServiceOrderIssueNoteLoading}
          datagridRef={workItemsDatagridRef}
          onRefreshDatagrid={refreshWorkItemsDatagrid}
          priceWithoutVat={priceWithoutVat}
          priceWithVat={priceWithVat}
          data-testid={testIds.warehouse.serviceOrderIssueDetailOverview('section.overview')}
        />
      ),
      'data-testid': testIds.warehouse.serviceOrderIssueDetailOverview('navigation.overview'),
    },
    {
      id: 'issueNotes',
      label: i18n.t('page.warehouse.labels.issueNotes'),
      href: composePath(warehouseRoutes.serviceOrderIssueDetailIssueNotes, {
        params: {id: serviceOrderIssueNoteId},
      }),
      content: (
        <IssueNotes
          data-testid={testIds.warehouse.serviceOrderIssueDetailIssueNotes('section.issueNotes')}
        />
      ),
      'data-testid': testIds.warehouse.serviceOrderIssueDetailIssueNotes('navigation.issueNotes'),
    },
    {
      id: 'documents',
      label: i18n.t('page.warehouse.labels.documents'),
      href: composePath(warehouseRoutes.serviceOrderIssueDetailDocuments, {
        params: {id: serviceOrderIssueNoteId},
      }),
      content: (
        <Documents
          serviceOrderId={defaultTo('', serviceOrderIssueNote?.serviceOrderId)}
          state={serviceOrderIssueNote?.state}
          data-testid={testIds.warehouse.serviceOrderIssueDetailDocuments('section.documents')}
        />
      ),
      'data-testid': testIds.warehouse.serviceOrderIssueDetailDocuments('navigation.documents'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.labels.serviceOrderIssueDetail')} />
      <DetailTemplate
        key={serviceOrderIssueNoteId}
        isLoading={isLoading}
        isError={isError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.serviceOrderIssueDetailOverview('page')}
      />
    </>
  );
}

import {isFeatureEnabled} from 'feature-flags';
import {openDeleteDialog, openDialog, showNotification} from 'platform/components';
import {Icon} from 'platform/foundation';
import {Lightbox, LightboxImage, useLightbox} from 'platform/lightbox';
import {v4 as uuid} from 'uuid';

import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import {any, head, isNil, propOr} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetParticipationQuery,
  useGetVehicleQuery,
  useRotateVehiclePhotoMutation,
  useUploadFileMutation,
  VehicleAlbum,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {BackgroundRemovalDialog, handleApiError, usePermissions} from '@dms/shared';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {UploadedFile} from '../../services/UploadFileService';
import {toggleImageSelection, toggleMultiSelectAlbum} from '../../store/carDetails/reducer';
import {ContextMenuProvider} from '../ContextMenu/ContextMenuProvider';
import {ContextMenuOption, MenuStateType} from '../ContextMenu/types';
import {ImagesCard} from '../ImagesCard/ImagesCard';
import {ImagesCardProps, ImageType, UploadingFileType} from '../ImagesCard/types';

type ImagesCardConnectedProps = Omit<ImagesCardProps, 'onUpload' | 'uploadingImages'> & {
  onUpload: (files: UploadedFile[], album?: VehicleAlbum) => Promise<void>;
  onDelete: (images: ImageType[]) => void;
  onCopy: (images: ImageType[], album: VehicleAlbum) => void;
  onMove: (images: ImageType[], album: VehicleAlbum) => void;
  onSetAsCover: (album: VehicleAlbum, photoId: string) => void;
  albums: readonly VehicleAlbum[];
  vehicleId?: string;
  promoPhotos?: boolean;
  withWatermark?: boolean;
  watermarkOnlyForFirstPhoto?: boolean;
  selectedImageIds?: string[];
  onWatermarkClick?: (image: ImageType) => void;
  isAddForm?: boolean;
};

export const ImagesCardConnected: FC<ImagesCardConnectedProps & TestIdProps> = ({
  images,
  onUpload,
  onDelete,
  onCopy,
  onMove,
  onSetAsCover,
  albums,
  album,
  vehicleId,
  withWatermark,
  watermarkOnlyForFirstPhoto,
  onWatermarkClick,
  selectedImageIds,
  isAddForm,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [rotatePhoto] = useRotateVehiclePhotoMutation();
  const [uploadFile] = useUploadFileMutation();

  const functionsRef =
    useRef<
      Pick<ImagesCardConnectedProps, 'onDelete' | 'onUpload' | 'onCopy' | 'onMove' | 'onSetAsCover'>
    >(null);
  const [menuState, setMenuState] = useState<MenuStateType | null>(null);

  const [lightboxControls, {onOpen}] = useLightbox(`${album?.id}-icc`);

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      recordId: vehicleId ?? '',
      resourceId: EntityResourceIds.vehicle,
    },
    {
      skip: isNil(vehicleId),
    }
  );

  const {data: vehicle} = useGetVehicleQuery(
    {vehicleId: vehicleId ?? ''},
    {skip: isNil(vehicleId)}
  );

  const [hasRemoveVehiclePhotoBackgroundPermission] = usePermissions({
    permissionKeys: ['removeVehiclePhotoBackground'],
    scopes: {
      removeVehiclePhotoBackground: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  const isBackgroundRemovalAvailable =
    isFeatureEnabled(featureFlags.SALES_BACKGROUND_REMOVAL) &&
    hasRemoveVehiclePhotoBackgroundPermission;

  const deselectPhotos = (vehiclePhotoIds: string[]) => {
    vehiclePhotoIds.forEach((photoId) => dispatch(toggleImageSelection(photoId)));

    if (!rest.selectionEnabled) {
      return;
    }

    dispatch(
      toggleMultiSelectAlbum({
        id: album?.id ?? '',
        reset: true,
      })
    );
  };

  const handleBackgroundRemoval = (vehiclePhotoIds: string[], canDeselect = true) => {
    if (isNil(vehicleId)) {
      throw new Error('No vehicleId for photos background removal.');
    }

    openDialog(
      <BackgroundRemovalDialog
        vehicleId={vehicleId}
        vehiclePhotoIds={vehiclePhotoIds}
        onConfirm={() => {
          if (canDeselect) {
            deselectPhotos(vehiclePhotoIds);
          }
        }}
      />,
      {
        title: i18n.t('entity.photo.actions.removeBackground'),
      }
    );
  };

  const getPhotoIds = (photos: ImageType[]) =>
    photos.filter((photo) => !photo.isPromoPhoto).map((photo) => photo.id);

  const handleRotateImage = (selectedPhotos: ImageType[], direction: 'left' | 'right') => {
    const angle = direction === 'left' ? 270 : 90;

    const vehiclePhotoIds = getPhotoIds(selectedPhotos);

    if (isNil(vehicleId) || isNil(album)) {
      showNotification.error();
      return;
    }

    rotatePhoto({angle, vehiclePhotoIds, vehicleId, vehicleAlbumId: album.id})
      .unwrap()
      .catch(handleApiError);
  };

  const {isDeleteDisabled} = rest;
  const handleContextMenu: Required<ImagesCardProps>['contextMenuOptions'] = useCallback(
    (selectedPhotos) => {
      const isContainingPromoPhotos = any(
        (selectedPhoto) => !!selectedPhoto.isPromoPhoto,
        selectedPhotos
      );

      const imagesWithoutPromoPhotos = images.filter((image) => !image.isPromoPhoto);
      const isFirst =
        imagesWithoutPromoPhotos.findIndex((image) => image.id === selectedPhotos[0].id) === 0;

      const isAllowToShowWatermark =
        !isContainingPromoPhotos &&
        withWatermark &&
        selectedPhotos.length < 2 &&
        (isFirst || !watermarkOnlyForFirstPhoto);

      const isAnySelectedFileProcessing = selectedPhotos.some(
        (selectedPhoto) => selectedPhoto.latestFileOperation?.state === 'IN_PROGRESS'
      );

      return buildArray<ContextMenuOption>()
        .when(!isAddForm && albums.length && !isContainingPromoPhotos, {
          content: i18n.t('general.actions.moveTo'),
          Icon: <Icon value="file/folder" />,
          children: [
            {
              content: i18n.t('entity.vehicle.labels.vehiclePhotos'),
              Icon: <Icon value="file/folder" />,
              children: albums.map((album) => ({
                content: album.translation ?? album.name,
                Icon: <Icon value="file/folder" />,
                action: () => functionsRef.current?.onMove(selectedPhotos, album),
              })),
            },
          ],
        })
        .when(!isAddForm && albums.length && !isContainingPromoPhotos, {
          content: i18n.t('general.actions.copyTo'),
          Icon: <Icon value="file/folder" />,
          children: [
            {
              content: i18n.t('entity.vehicle.labels.vehiclePhotos'),
              Icon: <Icon value="file/folder" />,
              children: albums.map((album) => ({
                content: album.translation ?? album.name,
                Icon: <Icon value="file/folder" />,
                action: () => functionsRef.current?.onCopy(selectedPhotos, album),
              })),
            },
          ],
        })
        .whenNot(isDeleteDisabled, {
          content: i18n.t('general.actions.delete'),
          Icon: <Icon value="action/delete_outline" />,
          action: () => {
            openDeleteDialog({
              text: i18n.t('general.actions.confirmPhotoDeleteQuestion'),
              onConfirm: () => functionsRef.current?.onDelete(selectedPhotos),
            });
          },
        })
        .when(
          !isAddForm && !any((image) => !!image.isSelected, images) && !isContainingPromoPhotos,
          {
            content: i18n.t('general.actions.setAsCoverPhoto'),
            Icon: <Icon value="image/photo" />,
            action: () =>
              isNotNil(album) &&
              functionsRef.current?.onSetAsCover(album, propOr('', 'id', head(selectedPhotos))),
          }
        )
        .when(!isAddForm && isAllowToShowWatermark, {
          content: i18n.t('entity.vehicle.labels.showWithWatermark'),
          Icon: <Icon value="AV/branding_watermark" />,
          action: () => {
            onWatermarkClick?.(selectedPhotos[0]);
          },
        })
        .when(
          !isAddForm &&
            isFeatureEnabled(featureFlags.SALES_PHOTO_ROTATION) &&
            !isAnySelectedFileProcessing,
          {
            content: i18n.t('general.actions.rotateLeft'),
            Icon: <Icon value="action/rotateLeft" />,
            action: () => {
              handleRotateImage(selectedPhotos, 'left');
            },
          }
        )
        .when(
          !isAddForm &&
            isFeatureEnabled(featureFlags.SALES_PHOTO_ROTATION) &&
            !isAnySelectedFileProcessing,
          {
            content: i18n.t('general.actions.rotateRight'),
            Icon: <Icon value="action/rotateRight" />,
            action: () => {
              handleRotateImage(selectedPhotos, 'right');
            },
          }
        )
        .when(
          !isAddForm &&
            !isAnySelectedFileProcessing &&
            isBackgroundRemovalAvailable &&
            (selectedPhotos.length > 1 || !isContainingPromoPhotos),
          {
            content: i18n.t('entity.photo.actions.removeBackground'),
            Icon: <Icon value="action/magic_wand" />,
            action: () => {
              handleBackgroundRemoval(getPhotoIds(selectedPhotos));
            },
          }
        );
    },
    [
      albums,
      menuState,
      images,
      album,
      withWatermark,
      onWatermarkClick,
      handleBackgroundRemoval,
      isDeleteDisabled,
    ]
  );

  const handleFileUpload: ImagesCardProps['onUpload'] = async (
    files,
    setUploadingState /* Will be used for progress and cancel */
  ) => {
    await Promise.allSettled(
      files.map(async (file) => {
        const _id = uuid();

        const stateBase: UploadingFileType = {
          name: file.name,
          progress: 0,
        };

        const uploadedResult = await uploadFile({
          file,
          onProgress: (percentage) => setUploadingState(_id)({...stateBase, progress: percentage}),
        })
          .unwrap()
          .catch(() => {
            setUploadingState(_id)();
            showNotification.error(
              i18n.t('general.validation.imageWasNotUploaded', {fileName: file.name}),
              {autoClose: false}
            );
            return Promise.reject();
          });

        const data: UploadedFile = {
          originalUri: uploadedResult.fileUri,
          fileId: uploadedResult.fileId,
          name: uploadedResult.file.name,
        };

        return {data, finnishUpload: setUploadingState(_id)};
      })
    ).then(async (results) => {
      const successfulUploadsData: UploadedFile[] = [];
      results.forEach((result) => {
        if (result.status !== 'fulfilled') {
          return;
        }
        if (result?.value?.data) {
          successfulUploadsData.push(result.value.data);
        }
        result.value.finnishUpload();
      });
      await functionsRef.current?.onUpload(successfulUploadsData, album);
    });
  };

  useEffect(() => {
    functionsRef.current = {onDelete, onUpload, onCopy, onMove, onSetAsCover};
  }, [onDelete, onUpload, onCopy, onMove, onSetAsCover]);

  const lightboxImages = images.reduce((lightboxImages: LightboxImage[], image) => {
    const imageUrl = image.url || image.originalUri || image.resizeUrl;
    if (isNotNil(imageUrl)) {
      return [
        ...lightboxImages,
        {
          id: image.id,
          url: imageUrl.replace('resize', 'get'),
          resizeUrl: image.resizeUrl,
          title: image.name,
          fileName: image.name,
        },
      ];
    }
    return lightboxImages;
  }, []);

  const handleImageClick = (id: string) => {
    const detailIndex = lightboxImages.findIndex((image) => image.id === id);
    rest.onImageClick?.(id);
    onOpen(detailIndex ?? 0);
  };

  return (
    <>
      <ContextMenuProvider menuState={menuState} setMenuState={setMenuState}>
        <ImagesCard
          {...rest}
          data-testid={rest['data-testid']}
          images={images}
          selectedImageIds={selectedImageIds}
          onUpload={handleFileUpload}
          onDelete={functionsRef.current?.onDelete}
          contextMenuOptions={handleContextMenu}
          onImageClick={handleImageClick}
          isBackgroundRemovalAvailable={isBackgroundRemovalAvailable}
          handleBackgroundRemoval={handleBackgroundRemoval}
          album={album}
          vehicleId={vehicleId}
          isAddForm={isAddForm}
        />
      </ContextMenuProvider>
      <Lightbox
        data-testid={suffixTestId('cardConnected', rest)}
        controls={lightboxControls}
        images={lightboxImages}
      />
    </>
  );
};

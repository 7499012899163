import {
  closeCurrentDialog,
  Dropdown,
  DropdownItem,
  openDeleteDialog,
  openDialog,
} from 'platform/components';
import {HStack, Link} from 'platform/foundation';

import {isNil} from 'ramda';

import {
  EntityResourceIds,
  useGetParticipationQuery,
  useGetVehicleQuery,
  useGetVehicleV2Query,
  useSetVehicleCustomIdMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import {handleApiError} from '../../../utils/handleApiError';
import {CustomIdForm} from './CustomIdForm';

export interface CustomIdProps extends TestIdProps {
  recordId: string;
}

export function VehicleCustomId(props: CustomIdProps) {
  const {data: vehicle} = useGetVehicleV2Query({vehicleId: props.recordId});
  const {data: vehicleV1} = useGetVehicleQuery({vehicleId: props.recordId});
  const [setVehicleCustomId] = useSetVehicleCustomIdMutation();

  const {data: vehicleParticipation} = useGetParticipationQuery({
    recordId: props.recordId,
    resourceId: EntityResourceIds.vehicle,
  });

  const [canUpdateVehicle, canSetCustomVehicleId] = usePermissions({
    permissionKeys: ['updateVehicle', 'setCustomIdVehicle'],
    scopes: {
      updateVehicle: {
        participation: vehicleParticipation,
        branchId: vehicleV1?.branchId,
      },
      setCustomIdVehicle: {
        participation: vehicleParticipation,
        branchId: vehicleV1?.branchId,
      },
    },
  });
  const canEditVehicle = canUpdateVehicle && canSetCustomVehicleId;

  const handleUpdateCustomId = async (customId: string | null) => {
    await setVehicleCustomId({
      vehicleId: props.recordId,
      setVehicleCustomIdRequestBody: {customId},
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const openCustomIdDialog = (defaultValue?: string | Nullish) =>
    openDialog(
      <CustomIdForm
        onClose={closeCurrentDialog}
        onSubmit={({customCode}) => handleUpdateCustomId(customCode ?? null)}
        customCode={defaultValue}
        text={i18n.t('page.vehicle.labels.customIdDescription')}
      />,
      {
        size: 'small',
        title: defaultValue
          ? i18n.t('entity.businessCase.actions.editCustomId')
          : i18n.t('entity.businessCase.actions.addCustomId'),
      }
    );

  const onCustomIdClick = () => {
    if (vehicle?.customId) {
      return;
    }
    openCustomIdDialog();
  };

  const handleDelete = () => {
    openDeleteDialog({onConfirm: () => handleUpdateCustomId(null)});
  };

  if (isNil(vehicle?.customId)) {
    return (
      <HStack align="center" spacing={1}>
        <Link
          size="small"
          leftIcon="action/code"
          title={i18n.t('entity.businessCase.labels.customId')}
          isDisabled={!canEditVehicle}
          onClick={onCustomIdClick}
          data-testid={suffixTestId('customId', props)}
        />
      </HStack>
    );
  }

  return (
    <Dropdown
      dropdownControl={
        <Link
          size="small"
          leftIcon="action/code"
          title={vehicle?.customId}
          onClick={onCustomIdClick}
          data-testid={suffixTestId('customId', props)}
        />
      }
      data-testid={suffixTestId('customId-dropdown', props)}
    >
      <DropdownItem
        label={i18n.t('general.actions.edit')}
        onClick={() => openCustomIdDialog(vehicle?.customId)}
        data-testid={suffixTestId('customId-dropdown-edit', props)}
      />
      <DropdownItem
        label={i18n.t('general.actions.delete')}
        onClick={handleDelete}
        data-testid={suffixTestId('customId-dropdown-delete', props)}
      />
    </Dropdown>
  );
}

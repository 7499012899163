import {Flag} from 'platform/components';
import {
  Box,
  Clickable,
  Heading,
  HStack,
  Icon,
  Show,
  Text,
  ThemeColorPath,
  ThemeIconKey,
} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {always} from 'ramda';

import {UserPairedDeviceResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {getDeviceStateFlagProps} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface DeviceBoxProps extends RequiredTestIdProps {
  device: UserPairedDeviceResponseBody;
  selectedDeviceId: string | null;
  setSelectedDeviceId: (deviceId: string) => void;
}

export function DeviceBox(props: DeviceBoxProps) {
  const isActive = props.device.pairedDeviceId === props.selectedDeviceId;
  const isDisabled = props.device.state !== 'paired';

  const backgroundColor = match<[boolean, boolean], ThemeColorPath>([isActive, isDisabled])
    .with([true, false], always('palettes.blue.10.100'))
    .otherwise(always('general.white'));

  const borderColor = match<[boolean, boolean], ThemeColorPath>([isActive, isDisabled])
    .with([true, false], always('palettes.blue.60.100'))
    .with([false, false], always('palettes.neutral.40.100'))
    .with([Pattern.boolean, true], always('palettes.neutral.20.100'))
    .exhaustive();

  const iconColor = match<[boolean, boolean], ThemeColorPath>([isActive, isDisabled])
    .with([true, false], always('palettes.blue.60.100'))
    .with([false, false], always('general.white'))
    .with([Pattern.boolean, true], always('palettes.neutral.70.100'))
    .exhaustive();

  const icon = match<[boolean, boolean], ThemeIconKey>([isActive, isDisabled])
    .with([true, false], always('action/icon_check_filled'))
    .with([false, false], always('action/icon_check_blank'))
    .with([Pattern.boolean, true], always('navigation/cancel'))
    .exhaustive();

  const flagProps = getDeviceStateFlagProps(props.device.state);

  return (
    <Clickable
      onClick={() => props.setSelectedDeviceId(props.device.pairedDeviceId)}
      isDisabled={isDisabled}
    >
      <Box
        border="1px solid"
        borderRadius="small"
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        padding={4}
      >
        <HStack justify="space-between" align="center">
          <HStack spacing={4} align="baseline">
            <Icon value={icon} color={iconColor} size={5} />
            <Heading size={4}>{props.device.name}</Heading>
            <Show when={props.device.state === 'inactive'}>
              <Text size="small" color="tertiary">
                {i18n.t('entity.document.labels.pairingNotAvailable')}
              </Text>
            </Show>
            <Show when={props.device.state === 'unpaired'}>
              <Text size="small" color="tertiary">
                {i18n.t('entity.document.labels.pairingInProgress')}
              </Text>
            </Show>
          </HStack>

          <Flag {...flagProps} isSubtle data-testid={suffixTestId('flag', props)} />
        </HStack>
      </Box>
    </Clickable>
  );
}

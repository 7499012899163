import {Dropdown, DropdownItem, FormField, IconButton, TextInput} from 'platform/components';
import {Grid, GridItem, Hide, Show, Space, VStack} from 'platform/foundation';

import {startTransition, useEffect} from 'react';
import {useWatch} from 'react-hook-form';

import {isEmpty, isNil, negate} from 'ramda';

import {useLazyCalculatePercentageQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {suffixTestId} from 'shared';

import {discountItemUnits, PERCENTAGE_SIGN} from '../../constants';
import {useGetInvoiceCurrency} from './hooks/useGetInvoiceCurrency';
import {InvoiceItemRowProps} from './types/InvoiceItemRowProps';

export function DiscountItemRow(props: InvoiceItemRowProps) {
  const field = props.fields[props.index];

  const relatedFieldIndex = props.fields.findIndex((item) => item.itemId === field.relatedItemId);

  const [getPercentage] = useLazyCalculatePercentageQuery();

  const [unitType, relatedVatType, isRelatedPriceWithVat] = useWatch({
    control: props.control,
    name: [
      `invoiceItems.${props.index}.unit`,
      `invoiceItems.${relatedFieldIndex}.vatType`,
      `invoiceItems.${relatedFieldIndex}.isUnitPriceWithVat`,
    ],
  });
  const [currency] = useGetInvoiceCurrency(props.control, 'invoices');

  const isPeacesUnitType = unitType !== PERCENTAGE_SIGN;

  const recalculateDiscount = () => props.callbacks.onRecalculate(field.itemId);

  const handleChangeUnitType = (val: string | number | null) =>
    startTransition(() => {
      const formValues = props.formApi.getValues();

      if (val === PERCENTAGE_SIGN) {
        const currentItem = formValues.invoiceItems.find((item) => item.itemId === field.itemId);
        const originalItem = formValues.invoiceItems.find(
          (item) => item.itemId === field.relatedItemId
        );

        if (isNil(originalItem)) {
          return;
        }

        const originalValue = originalItem?.isUnitPriceWithVat
          ? originalItem.priceWithVat
          : originalItem.priceWithoutVat;

        const discountValue = Math.abs(parseFloat(currentItem?.pricePerUnit ?? '0')).toString();

        if (isEmpty(originalValue) || isNil(originalValue) || isNil(discountValue)) {
          return;
        }

        getPercentage({originalValue, discountValue})
          .unwrap()
          .then((data) => {
            props.formApi.setValue(`invoiceItems.${props.index}.vatType`, relatedVatType);
            props.formApi.setValue(`invoiceItems.${props.index}.quantity`, data.percent);
            props.formApi.setValue(
              `invoiceItems.${props.index}.pricePerUnit`,
              negate(parseFloat(data.discountValue)).toString()
            );
          })
          .catch((err) => handleApiError(err, {silent: true}));
      } else {
        props.formApi.setValue(`invoiceItems.${props.index}.quantity`, '1');
      }
    });

  useEffect(() => {
    props.formApi.setValue(`invoiceItems.${props.index}.vatType`, relatedVatType);
    props.formApi.setValue(`invoiceItems.${props.index}.isUnitPriceWithVat`, isRelatedPriceWithVat);
  }, [relatedVatType, props.formApi, props.index, isRelatedPriceWithVat]);

  return (
    <Grid columns={35} align="flex-end">
      <GridItem span={8}>
        <TextInput isDisabled value={i18n.t('general.labels.discount')} />
      </GridItem>
      <GridItem span={3}>
        <FormField
          name={`invoiceItems.${props.index}.quantity`}
          control={props.control}
          onChange={recalculateDiscount}
          isDisabled={isPeacesUnitType || props.isDisabled}
          type="number"
          decimalPlaces={2}
          isRequired
          data-testid={suffixTestId(`quantity-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={3}>
        <FormField
          name={`invoiceItems.${props.index}.unit`}
          control={props.control}
          isNotClearable
          onChange={handleChangeUnitType}
          type="choice"
          options={discountItemUnits}
          data-testid={suffixTestId(`unitType-${props.index}`, props)}
          isDisabled={props.isDisabled}
        />
      </GridItem>
      <GridItem span={4}>
        <FormField
          name={`invoiceItems.${props.index}.pricePerUnit`}
          control={props.control}
          isDisabled={!isPeacesUnitType || props.isDisabled}
          onChange={recalculateDiscount}
          type="currency"
          decimalPlaces={2}
          isRequired
          currency={currency}
          data-testid={suffixTestId(`pricePerUnit-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={2}>
        <VStack>
          <FormField
            name={`invoiceItems.${props.index}.isUnitPriceWithVat`}
            control={props.control}
            type="checkbox"
            onChange={recalculateDiscount}
            isDisabled={!isPeacesUnitType || props.isDisabled}
            data-testid={suffixTestId(`isUnitPriceWithVat-${props.index}`, props)}
          />
          <Space vertical={2} />
        </VStack>
      </GridItem>
      <GridItem span={5}>
        <FormField
          name={`invoiceItems.${props.index}.priceWithoutVat`}
          control={props.control}
          type="currency"
          currency={currency}
          isDisabled
          data-testid={suffixTestId(`totalPriceWithoutVat-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={3}>
        <FormField
          name={`invoiceItems.${props.index}.vatType`}
          control={props.control}
          type="choice"
          isDisabled
          onChange={() => {
            props.formApi.clearErrors(`invoiceItems.${props.index}.vatType`);
            recalculateDiscount();
          }}
          options={props.vatRatesOptions}
          isNotClearable
          data-testid={suffixTestId(`vatType-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={5}>
        <FormField
          name={`invoiceItems.${props.index}.priceWithVat`}
          control={props.control}
          currency={currency}
          isDisabled={!isPeacesUnitType || props.isDisabled}
          type="currency"
          data-testid={suffixTestId(`totalPriceWithVat-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={2}>
        <Show when={props.isCorrectiveTaxDocument}>
          <IconButton
            data-testid={suffixTestId(`deleteItem-${props.index}`, props)}
            icon="action/delete"
            severity="danger"
            isDisabled={props.isDisabled}
            onClick={() => props.callbacks.onDeleteRow(props.index)}
          />
        </Show>
        <Hide when={props.isCorrectiveTaxDocument}>
          <Dropdown
            dropdownControl={
              <IconButton
                data-testid={suffixTestId(`deleteItem-${props.index}`, props)}
                icon="navigation/more_vert"
              />
            }
          >
            <DropdownItem
              label={i18n.t('page.accounting.labels.removeItem')}
              leftIcon="action/delete"
              severity="danger"
              onClick={() => props.callbacks.onDeleteRow(props.index)}
            />
          </Dropdown>
        </Hide>
      </GridItem>
    </Grid>
  );
}

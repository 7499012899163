import {useSubscription} from '@apollo/client';
import {isFeatureEnabled} from 'feature-flags';
import {
  getFilePreviewUrl,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {match} from 'ts-pattern';

import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

// using path is OK in this case
// eslint-disable-next-line no-restricted-imports
import {defaultTo, isNotNil, path, pipe} from 'ramda';
import {isFalse, isString, noop} from 'ramda-adjunct';

import {
  ContextTarget,
  documentContextApi,
  SEND_NOTIFICATION_SUBSCRIPTION,
  useDeleteDocumentContextMutation,
  useGetEmailConfigQuery,
  useLazyGetSentBackgroundNotificationQuery,
} from '@dms/api';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {downloadFile, handleApiError, printPDF, SendEmail} from '@dms/shared';

import {Nullish, openFile} from 'shared';

import {ActionCallback} from 'features/datagrid';

import {SignDocumentStepper} from '../components/SignDocumentStepper';
import {DOCUMENT_SIGNATURE_DIALOG_ID} from '../constants/DOCUMENT_SIGNATURE_DIALOG_ID';

export type GetDocumentDatagridActionsProps = {
  refreshData: () => void;
  contextTarget: ContextTarget;
  contextId: string;
  customerId?: string | Nullish;
};

export const useGetDocumentDatagridActions = (props: GetDocumentDatagridActionsProps) => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {data: emailConfig} = useGetEmailConfigQuery();

  const [deleteDocument] = useDeleteDocumentContextMutation();
  const [getBackgroundNotification] = useLazyGetSentBackgroundNotificationQuery();

  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data}) => {
      const isBackGroundNotification = isNotNil(
        data.data?.onSendNotification?.backgroundNotificationId
      );

      if (isBackGroundNotification) {
        getBackgroundNotification({id: data.data?.onSendNotification?.backgroundNotificationId})
          .unwrap()
          .then((data) => {
            if (data.type === 'file_storage-file_uploaded') {
              props.refreshData();
            }
          })
          .catch((err) => handleApiError(err, {silent: true}));
      }
    },
    fetchPolicy: 'no-cache',
    shouldResubscribe: false,
  });

  const documentActionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const url = path<string>(['fileName', 'value', 'remoteUrl'], rowData);
    const fileId = path<string>(['fileName', 'value', 'id'])(rowData);

    if (!(isString(url) && isString(rowId))) {
      showNotification.error();
      return;
    }

    const originalFileId = path<string>(['originalFileName', 'value', 'id'])(rowData);
    const originalFileUrl = path<string>(['originalFileName', 'value', 'remoteUrl'], rowData);

    const fileName = pipe(path<string>(['fileName', 'value', 'title']), defaultTo('file'))(rowData);
    const previewUrl = getFilePreviewUrl(url);

    match(actionKey)
      .with('preview', 'openOnNewTab', () => openFile(previewUrl))
      .with('download', () => downloadFile(url, {fileType: 'attachment'}))
      .with('downloadOriginal', () => {
        if (!originalFileUrl) {
          showNotification.error(i18n.t('entity.inspection.errors.invalidFileType'));
          return;
        }

        downloadFile(originalFileUrl, {fileType: 'attachment'});
      })
      .with('print', () => printPDF(previewUrl))
      .with('sign', () => {
        const signableFileId = originalFileId ?? fileId;

        if (!signableFileId) {
          showNotification.error();
          return;
        }

        if (!isFeatureEnabled(featureFlags.DIGITAL_SIGNATURE)) {
          showNotification.error(i18n.t('general.label.featureNotEnabled'));
          return;
        }

        openDialog(
          <SignDocumentStepper
            fileId={signableFileId}
            contextId={props.contextId}
            customerId={props.customerId}
            contextTarget={props.contextTarget}
            refreshData={props.refreshData}
            data-testid="documentSignature"
          />,
          {
            title: i18n.t('entity.document.actions.signDocument'),
            withAdditionalFooter: true,
            id: DOCUMENT_SIGNATURE_DIALOG_ID,
            size: 'large',
          }
        );
      })
      .with('send_email', () => {
        if (!fileId) {
          showNotification.error();
          return;
        }

        if (isFalse(emailConfig?.enabled)) {
          showNotification.error(i18n.t('general.notifications.setupEmailProvider'));
          return;
        }

        openDialog(
          <SendEmail
            data-testid="datagridDocumentAction"
            customerId={props.customerId}
            documents={[
              {
                fileUri: url,
                fileId,
                fileName,
                title: fileName,
              },
            ]}
          />,
          {
            scrollBehavior: 'outside',
            title: i18n.t('general.labels.email'),
          }
        );
      })
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () => {
            deleteDocument({documentContextId: rowId})
              .unwrap()
              .then(refreshData)
              .then(() => {
                dispatch(
                  documentContextApi.util.invalidateTags([{type: 'documentsCount', id: id ?? ''}])
                );
              })
              .catch(handleApiError);
          },
        })
      )
      .otherwise(noop);
  };

  const documentSignatureAction = () => {
    if (!isFeatureEnabled(featureFlags.DIGITAL_SIGNATURE)) {
      showNotification.error(i18n.t('general.label.featureNotEnabled'));
      return;
    }

    openDialog(
      <SignDocumentStepper
        contextId={props.contextId}
        customerId={props.customerId}
        contextTarget={props.contextTarget}
        refreshData={props.refreshData}
        data-testid="documentSignature"
      />,
      {
        title: i18n.t('entity.document.actions.signDocument'),
        withAdditionalFooter: true,
        id: DOCUMENT_SIGNATURE_DIALOG_ID,
        size: 'large',
      }
    );
  };

  return [documentActionCallback, documentSignatureAction] as const;
};

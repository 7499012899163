import {
  Action,
  Card,
  DataStatus,
  Form,
  FormField,
  openDeleteDialog,
  showNotification,
} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {isNil, reject} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {
  TaxDocumentCalculationResponseBodyRead,
  useCancelCashRegisterDocumentMutation,
  useGetCashReceiptV4Query,
  useGetCashRegisterQuery,
  useGetCustomerV2Query,
  useGetUserQuery,
  useRenderExpenseCashReceiptDocumentMutation,
  useRenderIncomeCashReceiptDocumentMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {downloadFile, handleApiError, useGetAccountingDocuments, usePermissions} from '@dms/shared';

import {buildArray, useRequiredParams} from 'shared';

import {BasicInformationDetail} from '../../components/BasicInformationDetail';
import {CustomerAndSupplierDetail} from '../../components/CustomerAndSupplierDetail';
import {InvoiceDetailHeader} from '../../components/InvoiceDetailHeader';
import {InvoiceDetailSubHeader} from '../../components/InvoiceDetailSubHeader';
import {InvoicePage} from '../../components/InvoicePage';
import {ListOfItemsDetail} from '../../components/ListOfItemsDetail';
import {PairedInvoice} from '../../components/PairedInvoice/PairedInvoice';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {AccountingDocumentFormValues} from '../../types';
import {getDocumentSourceFlags} from '../../utils/getDocumentSourceFlags';
import {
  convertCashRegisterItemToInvoiceItem,
  convertCustomerToAccountingCustomer,
  covertIssuerToSupplier,
  getCashReceiptStateFlagProps,
  useGetDefaultValuesFromCashReceipt,
} from './utils';

export function CashReceiptDetail() {
  const {id: cashReceiptId} = useRequiredParams();

  const [renderIncomeCashReceipt, {isLoading: isLoadingIncomeRender}] =
    useRenderIncomeCashReceiptDocumentMutation();
  const [renderExpenseCashReceipt, {isLoading: isLoadingExpenseRender}] =
    useRenderExpenseCashReceiptDocumentMutation();
  const [cancelCashReceiptDocument] = useCancelCashRegisterDocumentMutation();
  const [canReadInvoices, canDownloadDocuments] = usePermissions({
    permissionKeys: ['readInvoice', 'downloadDocuments'],
  });

  const {
    data: cashReceipt,
    isLoading: isLoadingCashReceipt,
    isError: isCashReceiptError,
  } = useGetCashReceiptV4Query({cashRegisterDocumentId: cashReceiptId});

  const {
    data: invoiceDocument,
    isLoading: isLoadingInvoice,
    isError: isInvoiceError,
  } = useGetAccountingDocuments(
    cashReceipt?.relatedInvoicingDocumentId,
    cashReceipt?.relatedInvoicingDocumentType
  );

  const downloadCashReceipt = async () => {
    const renderAction =
      cashReceipt?.type === 'expense'
        ? renderExpenseCashReceipt({
            renderExpenseCashReceiptDocumentRequestBody: {cashRegisterDocumentId: cashReceiptId},
          })
        : renderIncomeCashReceipt({
            renderIncomeCashReceiptDocumentRequestBody: {cashRegisterDocumentId: cashReceiptId},
          });

    await renderAction
      .unwrap()
      .then((data) => data.pdfUrl)
      .then(downloadFile)
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleCancelDocument = () => {
    const issuedAt = new Date().toISOString();
    openDeleteDialog({
      'data-testid': testIds.accounting.cashReceiptDetail('delete-dialog'),
      text:
        cashReceipt?.type === 'income'
          ? i18n.t('entity.cashRegister.notifications.cancelIncomeCashRegisterDocument', {
              documentNumber: cashReceipt?.number,
            })
          : i18n.t('entity.cashRegister.notifications.cancelExpenseCashRegisterDocument', {
              documentNumber: cashReceipt?.number,
            }),
      onConfirm: () =>
        cancelCashReceiptDocument({
          cashRegisterDocumentId: cashReceiptId,
          cancelCashRegisterDocumentRequestBody: {issuedAt},
        })
          .unwrap()
          .then(() => showNotification.success())
          .catch(handleApiError),
    });
  };

  const {
    data: userData,
    isLoading: isLoadingUser,
    isError: isUserError,
  } = useGetUserQuery(
    {id: cashReceipt?.createdById ?? ''},
    {skip: isNil(cashReceipt?.createdById)}
  );

  const {
    data: customerData,
    isLoading: isLoadingCustomer,
    isError: isCustomerError,
  } = useGetCustomerV2Query(
    {customerId: cashReceipt?.customerId ?? ''},
    {skip: isNil(cashReceipt?.customerId)}
  );

  const {isLoading: isLoadingCashRegister, isError: isCashRegisterError} = useGetCashRegisterQuery(
    {cashRegisterId: cashReceipt?.cashRegisterId ?? ''},
    {skip: isNil(cashReceipt)}
  );

  const authorName = reject(isNilOrEmpty, [userData?.firstName, userData?.lastName]).join(' ');

  const pageTitle = i18n.t(
    cashReceipt?.type === 'income'
      ? 'page.accounting.cashRegister.detailIncomeNumber'
      : 'page.accounting.cashRegister.detailExpenseNumber',
    {
      documentNumber: cashReceipt?.number,
    }
  );

  const buttons = buildArray<Action>()
    .when(canDownloadDocuments, {
      type: 'button',
      title: i18n.t('general.actions.generatePDF'),
      onClick: downloadCashReceipt,
      isLoading: isLoadingExpenseRender || isLoadingIncomeRender,
      leftIcon: 'editor/insert_drive_file',
      variant: 'link',
      size: 'small',
    })
    .when(cashReceipt?.state === 'taken', {
      type: 'button',
      title: i18n.t('entity.checkout.actions.createCorrectiveTaxDocument'),
      onClick: handleCancelDocument,
      leftIcon: 'action/delete',
      variant: 'dangerLink',
      size: 'small',
    });

  const stateFlags = cashReceipt ? [getCashReceiptStateFlagProps(cashReceipt.state)] : undefined;

  const defaultValues = useGetDefaultValuesFromCashReceipt(cashReceipt);

  const isLoading =
    isLoadingCashReceipt ||
    isLoadingCustomer ||
    isLoadingInvoice ||
    isLoadingUser ||
    isLoadingCashRegister;
  const isError =
    isCashReceiptError || isCustomerError || isInvoiceError || isUserError || isCashRegisterError;

  const summary: TaxDocumentCalculationResponseBodyRead | null = cashReceipt
    ? {
        ...cashReceipt.cashReceiptSummary,
        vatTypesSummary:
          cashReceipt.exchangeRateRatioCashReceiptSummary?.vatTypesSummary ??
          cashReceipt.cashReceiptSummary?.vatTypesSummary,
      }
    : null;

  return (
    <>
      <Helmet title={pageTitle} />
      <DataStatus minHeight="80vh" isLoading={isLoading} isError={isError}>
        <Form<AccountingDocumentFormValues> defaultValues={defaultValues}>
          {(control, formApi) => (
            <InvoiceVatRateProvider control={control} listItemType="cash-receipts">
              <VStack>
                <InvoiceDetailHeader
                  title={pageTitle}
                  data-testid={testIds.accounting.cashReceiptDetail('title')}
                />
                <InvoiceDetailSubHeader
                  author={authorName}
                  flags={stateFlags}
                  subFlags={getDocumentSourceFlags(cashReceipt?.documentSource)}
                  actions={buttons}
                  data-testid={testIds.accounting.cashReceiptDetail('invoice-detail-subHeader')}
                  createdAt={cashReceipt?.createdAt}
                />

                <InvoicePage>
                  {isNotNil(cashReceipt) && (
                    <Card>
                      <CustomerAndSupplierDetail
                        supplier={covertIssuerToSupplier(cashReceipt.issuer)}
                        customer={convertCustomerToAccountingCustomer(customerData)}
                        data-testid={testIds.accounting.cashReceiptDetail('customerAndSupplier')}
                      />
                    </Card>
                  )}

                  <Show when={canReadInvoices}>
                    <PairedInvoice
                      control={control}
                      formApi={formApi}
                      invoiceDocument={invoiceDocument}
                      invoiceType={cashReceipt?.relatedInvoicingDocumentType}
                      isDetail
                      data-testid={testIds.accounting.cashReceiptDetail('paired-invoice')}
                    />
                  </Show>

                  <BasicInformationDetail
                    control={control}
                    cashReceipt={cashReceipt}
                    data-testid={testIds.accounting.cashReceiptDetail('basic-information')}
                  />

                  {isNotNil(cashReceipt) && isNotNil(summary) && (
                    <ListOfItemsDetail
                      items={cashReceipt.items.map(convertCashRegisterItemToInvoiceItem)}
                      summary={summary}
                      exchangeRateRatioCalculation={
                        cashReceipt?.exchangeRateRatioCashReceiptSummary
                      }
                      cashRegisterId={cashReceipt.cashRegisterId}
                      control={control}
                      isCashReceipt
                      data-testid={testIds.accounting.cashReceiptDetail('list-of-items-detail')}
                    />
                  )}

                  <Card>
                    <FormField
                      control={control}
                      name="footerNote"
                      type="textarea"
                      isDisabled
                      label={i18n.t('general.labels.note')}
                      data-testid={testIds.accounting.cashReceiptDetail('footerNote')}
                    />
                  </Card>
                </InvoicePage>
              </VStack>
            </InvoiceVatRateProvider>
          )}
        </Form>
      </DataStatus>
    </>
  );
}

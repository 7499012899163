import {Dropdown, DropdownItem, IconButton, showNotification} from 'platform/components';
import {HStack} from 'platform/foundation';

import {
  SourcingVehicleDetailResponseBody,
  useSourcingBulkCreateUserFavouriteMutation,
  useSourcingBulkCreateUserToBuyMutation,
  useSourcingBulkDeleteUserComparisonMutation,
  useSourcingBulkDeleteUserFavouriteMutation,
  useSourcingBulkDeleteUserToBuyMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {sourcingRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {PREV_URL_COOKIE, useRouter} from '@dms/teas';

import {composePath, suffixTestId, TestIdProps} from 'shared';

import {useCreatePriceReport} from '../../../hooks/useCreatePriceReport';
import {createAdUrlButton} from '../utils/createAdUrlButton';

interface ComparisonTableActionButtonsProps extends TestIdProps {
  adId: string;
  vehicle: SourcingVehicleDetailResponseBody;
}

export function ComparisonTableActionButtons(props: ComparisonTableActionButtonsProps) {
  const router = useRouter();
  const vehicleData = props.vehicle.sourcingVehicle;

  const [createUserFavourite] = useSourcingBulkCreateUserFavouriteMutation();
  const [deleteUserFavourite] = useSourcingBulkDeleteUserFavouriteMutation();
  const [deleteUserComparison] = useSourcingBulkDeleteUserComparisonMutation();
  const [createUserToBuy] = useSourcingBulkCreateUserToBuyMutation();
  const [deleteUserToBuy] = useSourcingBulkDeleteUserToBuyMutation();
  const {createPriceReport, isPriceReportCreating} = useCreatePriceReport(props.adId);

  const server = (vehicleData?.server || '')
    .replace('_', '.')
    .replace(/^./, (c) => c.toUpperCase());
  const url = createAdUrlButton(vehicleData);
  const isDisabled = !vehicleData.isAvailable;

  return (
    <HStack align="center">
      {isDisabled ? (
        <IconButton
          size="small"
          severity="success"
          priority="secondary"
          icon="action/delete_outline"
          onClick={() => {
            deleteUserComparison({
              body: {externalIds: [props.adId]},
            })
              .unwrap()
              .then(() => showNotification.success())
              .catch(handleApiError);
          }}
          data-testid={suffixTestId('removeFromComparison', props)}
        />
      ) : null}

      {!isDisabled ? (
        <>
          <IconButton
            size="small"
            severity={props.vehicle.favourite ? 'warning' : undefined}
            icon="toggle/star"
            onClick={() => {
              (props.vehicle.favourite ? deleteUserFavourite : createUserFavourite)({
                body: {externalIds: [props.adId]},
              })
                .unwrap()
                .then(() => showNotification.success())
                .catch(handleApiError);
            }}
            data-testid={suffixTestId('favourite', props)}
          />
          <IconButton
            size="small"
            severity={props.vehicle.inCart ? 'informational' : undefined}
            icon="action/shopping_cart"
            onClick={() => {
              (props.vehicle.inCart ? deleteUserToBuy : createUserToBuy)({
                body: {externalIds: [props.adId]},
              })
                .unwrap()
                .then(() => showNotification.success())
                .catch(handleApiError);
            }}
            data-testid={suffixTestId('toBuy', props)}
          />
          <Dropdown
            dropdownControl={
              <IconButton
                priority="secondary"
                icon="navigation/more_horiz"
                size="small"
                data-testid={testIds.sourcing.classifieds('comparisonTableActionButtons-more')}
              />
            }
            data-testid={testIds.sourcing.classifieds('comparisonTableActionButtons-more')}
          >
            <DropdownItem
              onClick={() => {
                localStorage.setItem(PREV_URL_COOKIE, String(router.route));

                router.push(
                  composePath(sourcingRoutes.vehicleDetail, {
                    params: {adId: props.adId},
                  })
                );
              }}
              label={i18n.t('general.actions.showDetail')}
              leftIcon="action/search"
            />
            {url && (
              <DropdownItem
                onClick={url.action}
                label={`${i18n.t('general.actions.openResultOn')} ${server}`}
                leftIcon="action/open_in_new"
              />
            )}
            <DropdownItem
              onClick={createPriceReport}
              label={i18n.t('entity.sourcing.labels.getPriceReport')}
              isLoading={isPriceReportCreating}
              leftIcon="social/poll"
            />
            <DropdownItem
              onClick={() => {
                deleteUserComparison({
                  body: {externalIds: [props.adId]},
                })
                  .unwrap()
                  .then(() => showNotification.success())
                  .catch(handleApiError);
              }}
              label={i18n.t('general.actions.removeFromList')}
              leftIcon="action/delete_outline"
            />
          </Dropdown>
        </>
      ) : null}
    </HStack>
  );
}

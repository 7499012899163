import {suffixTestId, TestIdProps} from 'shared';

import {CheckboxFilter} from '../components/filters/CheckboxFilter';
import {ChipsFilter} from '../components/filters/ChipsFilter';
import {ChoiceFilter} from '../components/filters/ChoiceFilter';
import {DateFilter} from '../components/filters/DateFilter';
import {DateRangeFilter} from '../components/filters/DateRangeFilter';
import {DateTimeRangeFilter} from '../components/filters/DateTimeRangeFilter';
import {NumberSliderRangeFilter} from '../components/filters/NumberSliderRangeFilter';
import {PlainSliderRangeFilter} from '../components/filters/PlainSliderRangeFilter';
import {RangeFilter} from '../components/filters/RangeFilter';
import {SliderFilter} from '../components/filters/SliderFilter';
import {SuggestionFilter} from '../components/filters/SuggestionFilter';
import {SwitchFilter} from '../components/filters/SwitchFilter';
import {TextFilter} from '../components/filters/TextFilter';
import {ColDef} from '../types/AgGridTypes';
import {ColumnResponseBody} from '../types/Api';

type ColumnToFilterColDefsProps = TestIdProps & {
  column: ColumnResponseBody;
};

export const columnToFilterColDefs = ({
  column,
  ...props
}: ColumnToFilterColDefsProps): Partial<ColDef> => {
  const filter = column.filter;

  if (!filter) {
    return {
      filter: false,
    };
  }

  switch (filter.type) {
    case 'TEXT':
      return {
        filter: TextFilter,
        filterParams: {
          isDisabled: filter.isDisabled,
          quickFilters: filter.quickFilters,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
        floatingFilterComponent: TextFilter,
        floatingFilterComponentParams: {
          isDisabled: filter.isDisabled,
          quickFilters: filter.quickFilters,
        },

        columnChooserParams: {
          suppressColumnFilter: false,
        },
      };
    case 'DATE':
      return {
        filter: DateFilter,
        filterParams: {
          min: filter.min,
          max: filter.max,
          relativeDates: filter.relativeDates,
          isDisabled: filter.isDisabled,
          quickFilters: filter.quickFilters,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
    case 'DATE_RANGE':
      return {
        filter: DateRangeFilter,
        filterParams: {
          min: filter.min,
          max: filter.max,
          relativeDates: filter.relativeDates,
          isDisabled: filter.isDisabled,
          relativeInputs: filter.relativeInputs,
          quickFilters: filter.quickFilters,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
    case 'DATE_TIME_RANGE':
      return {
        filter: DateTimeRangeFilter,
        filterParams: {
          min: filter.min,
          max: filter.max,
          relativeDates: filter.relativeDates,
          isDisabled: filter.isDisabled,
          relativeInputs: filter.relativeInputs,
          quickFilters: filter.quickFilters,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
    case 'MULTI_CHOICE_ASYNC':
      return {
        filter: SuggestionFilter,
        filterParams: {
          ...filter,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
    case 'MULTI_CHOICE':
      return {
        filter: ChoiceFilter,
        filterParams: {
          options: filter.options.map((opt) => ({label: opt.label, value: opt.key})),
          isDisabled: filter.isDisabled,
          quickFilters: filter.quickFilters,
          isMulti: filter.multiple ?? true,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
    case 'CHIPS':
      return {
        filter: ChipsFilter,
        filterParams: {
          options: filter.options.map((opt) => ({label: opt.label, value: opt.key})),
          isDisabled: filter.isDisabled,
          quickFilters: filter.quickFilters,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
    case 'CHECKBOX':
      return {
        filter: CheckboxFilter,
        filterParams: {
          options: filter.options.map((opt) => ({label: opt.label, value: opt.key})),
          isDisabled: filter.isDisabled,
          quickFilters: filter.quickFilters,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
    case 'SLIDER':
      return {
        filter: SliderFilter,
        filterParams: {
          ...filter,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
    case 'SLIDER_RANGE': {
      if (column.renderer.type === 'PLAIN') {
        return {
          filter: PlainSliderRangeFilter,
          filterParams: {
            ...filter,
            'data-testid': suffixTestId(`filter-${column.key}`, props),
          },
        };
      }

      return {
        filter: NumberSliderRangeFilter,
        filterParams: {
          ...filter,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
    }
    case 'RANGE':
      return {
        filter: RangeFilter,
        filterParams: {
          ...filter,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
    case 'SWITCH':
      return {
        filter: SwitchFilter,
        filterParams: {
          isDisabled: filter.isDisabled,
          quickFilters: filter.quickFilters,
          'data-testid': suffixTestId(`filter-${column.key}`, props),
        },
      };
  }
};

import {isFeatureEnabled} from 'feature-flags';
import {css} from 'styled-components';

import {use, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {isNilOrEmpty} from 'ramda-adjunct';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {TestIdProps, appendSuffix} from 'shared';

import {useApiDispatch} from '../../hooks/useApiDispatch';
import {selectTopMakes} from '../../store/carDetailsV2/selectors';
import {getVehicleMakes} from '../../store/vehicleCatalogue/actions';
import {selectVehicleCatalogue, selectVehicleMakes} from '../../store/vehicleCatalogue/selectors';
import {PossibleObject} from '../../types/PossibleObject';
import {VehicleType} from '../../types/VehicleType';
import {makeModelInputFilter} from '../../utils/makeModelInputFilter';
import {FormFieldProps} from '../FinalForm/components/FormField';
import {FormContext} from '../FinalForm/FormContext';
import {useFormRenderer} from '../FinalForm/hooks/useFormRenderer';

type MakeSelectProps<
  T extends PossibleObject = undefined,
  K extends PossibleObject = undefined,
  M extends boolean = false,
> = Pick<
  FormFieldProps<T, K, M>,
  'as' | 'defaultValue' | 'disabled' | 'label' | 'name' | 'multiple' | 'onChange' | 'required'
> & {
  vehicleType: VehicleType;
  closeMenuOnSelect?: boolean;
  isNotClearable?: boolean;
  hasOptionCheckbox?: boolean;
  handleCreateOption?: (vehicleType: string, value: string) => void;
};

export const MakeSelect = <
  T extends PossibleObject = undefined,
  K extends PossibleObject = undefined,
  M extends boolean = false,
>({
  vehicleType,
  closeMenuOnSelect,
  isNotClearable,
  hasOptionCheckbox,
  handleCreateOption,
  ...props
}: MakeSelectProps<T, K, M> & TestIdProps) => {
  const {Field} = useFormRenderer();

  const {formId} = use(FormContext);

  // eslint-disable-next-line no-restricted-syntax
  const _name = props.name as unknown as string | string[];
  const fullName = typeof _name === 'string' ? _name : _name.join('.');

  const testId = props['data-testid'] ?? [formId, fullName.replace('.', '-')].join('-');

  const apiDispatch = useApiDispatch();
  const {loading} = getVehicleMakes.useThunkState(selectVehicleCatalogue);
  const makeOptions = useSelector(selectVehicleMakes(vehicleType));
  const topMakes = useSelector(selectTopMakes);

  const topMakeOptions = makeOptions.filter((make) =>
    topMakes?.find((topMake) => make.value === topMake.code)
  );
  const otherMakeOptions = makeOptions.filter(
    (make) => !topMakes?.find((topMake) => make.value === topMake.code)
  );

  useEffect(() => {
    apiDispatch(getVehicleMakes.action, {vehicleType}, {throwOff: true});
  }, [vehicleType]);

  return (
    <Field
      {...props}
      as="select"
      options={[
        {label: i18n.t('general.labels.topMakes'), options: topMakeOptions},
        {label: i18n.t('general.labels.allMakes'), options: otherMakeOptions},
      ]}
      isLoading={loading}
      selectProps={{
        creatable: isFeatureEnabled(featureFlags.SALES_CUSTOM_MAKE_MODEL),
        isNotClearable,
        hasOptionCheckbox,
        closeMenuOnSelect,
        handleCreate: (newOption) => {
          if (newOption) {
            handleCreateOption?.(vehicleType, newOption);
          }
        },
        formatCreateLabel: (inputValue: string) => (
          <div
            css={css`
              color: ${({theme}) => theme.colors.general.accent};
            `}
            data-testid={appendSuffix('add', testId)}
          >
            {i18n.t('general.actions.add')} {inputValue}
          </div>
        ),
        isValidNewOption: (inputValue) => {
          if (isNilOrEmpty(inputValue)) {
            return false;
          }

          return !makeOptions?.some(
            ({label}) => label?.toLowerCase() === inputValue?.toLowerCase()
          );
        },
        filterOption: makeModelInputFilter ?? undefined,
      }}
      data-testid={`${testId}-make`}
    />
  );
};

import {DataStatus, IconButton, Tooltip} from 'platform/components';
import {HStack, ThemeIconKey} from 'platform/foundation';

import {toPairs} from 'ramda';

import {
  getWorkspaceFromUri,
  useGetAvailableLanguagesListQuery,
  useSetUserSettingsItemMutation,
} from '@dms/api';
import {browserStorageKey} from '@dms/config';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

export function LanguagesButtons() {
  const {workspace} = getWorkspaceFromUri();
  const [updateUserSettings, {isLoading: isChangingLanguage}] = useSetUserSettingsItemMutation();
  const {data, isLoading, isError} = useGetAvailableLanguagesListQuery({
    workspace: workspace as string,
  });

  const handleLanguageChange = (language: string) => {
    updateUserSettings({
      settingKey: 'selected_language',
      userSettingRequestBody: {
        value: language,
      },
    })
      .unwrap()
      .then(() => {
        localStorage.setItem(browserStorageKey.LAST_KNOWN_LANGUAGE, language);
        window.location.reload();
      })
      .catch(handleApiError);
  };

  const language: string =
    i18n?.resolvedLanguage || localStorage[browserStorageKey.LAST_KNOWN_LANGUAGE] || data?.default;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <HStack align="center" spacing={2}>
        {toPairs(data?.languages ?? {}).map(([value, label]) => (
          <Tooltip key={value} description={label} openDelay={0}>
            <IconButton
              icon={iconValueByLanguage[value] ?? 'alert/error'}
              onClick={() => handleLanguageChange(value)}
              isDisabled={isChangingLanguage}
              priority={language === value ? 'primary' : 'secondary'}
            />
          </Tooltip>
        ))}
      </HStack>
    </DataStatus>
  );
}

const iconValueByLanguage: Record<string, ThemeIconKey> = {
  cs: 'flags/CZE',
  sk: 'flags/SVK',
  de: 'flags/DEU',
  en: 'flags/GBR',
  es: 'flags/ESP',
  fr: 'flags/FRA',
  it: 'flags/ITA',
  pl: 'flags/POL',
};

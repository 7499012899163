import {isEmpty, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {PermissionScopeResponseBody, ProtectedGroupResponseBody} from '@dms/api';

type ProtectedUnits = Record<string, {value: boolean; scopes: PermissionScopeResponseBody[]}>;

export const getAllProtectedUnitsDefaultValues = (
  resource: ProtectedGroupResponseBody[] | undefined
) => {
  if (isNil(resource) || isEmpty(resource)) {
    return {};
  }

  const getChildProtectedUnitsIds = (resource: ProtectedGroupResponseBody): ProtectedUnits => {
    if (isNilOrEmpty(resource.children)) {
      return resource.protectedUnits.reduce(
        (acc, protectedUnit) => ({
          ...acc,
          [protectedUnit.id]: {
            id: protectedUnit.id,
            value: false,
            scopes: [],
          },
        }),
        {}
      );
    }

    return resource.children.reduce(
      (acc, child: ProtectedGroupResponseBody) => ({
        ...acc,
        ...getChildProtectedUnitsIds(child),
      }),
      resource.protectedUnits.reduce(
        (acc, protectedUnit) => ({
          ...acc,
          [protectedUnit.id]: {
            id: protectedUnit.id,
            value: false,
            scopes: [],
          },
        }),
        {}
      )
    );
  };

  return resource.reduce(
    (acc, resource) => ({
      ...acc,
      ...getChildProtectedUnitsIds(resource),
    }),
    {} as ProtectedUnits
  );
};

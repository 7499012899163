import {Card, DataStatus, EmptyStatus, Separator} from 'platform/components';
import {Grid, GridItem, Hide, Show, Space} from 'platform/foundation';

import {head} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  NewOfferPurchaseBrokerageVehicleResponseBody,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
  useGetPurchaseBusinessCasePricesOverviewQuery,
  useGetSaleBusinessCasePricesOverviewQuery,
  useGetSaleVehicleQuery,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  CommentsWidget,
  Main,
  NoteWidget,
  useCodelistCodeName,
  usePricePermissions,
  usePermissions,
} from '@dms/shared';
import {PriceBox} from '@dms/teas';

import {useRequiredParams} from 'shared';

import {BusinessCaseOverviewVehicle} from './BusinessCaseOverviewVehicle';
import {CloseDialogCard} from './CloseDialogCard';

export function BusinessCaseOverview() {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const {data: saleVehicleData} = useGetSaleVehicleQuery(
    {vehicleId: businessCase?.offers?.[0]?.saleVehicles?.[0]?.vehicleId ?? ''},
    {skip: isNilOrEmpty(businessCase?.offers?.[0]?.saleVehicles?.[0]?.vehicleId)}
  );

  const businessCaseState = businessCase?.businessCaseState;
  const businessCaseType = businessCase?.businessCaseType;
  const offer = businessCase?.offers[0];
  const {
    data: purchaseVehiclePrices,
    isLoading: isLoadingPurchaseVehiclePrices,
    isError: isPurchaseVehiclePricesError,
  } = useGetPurchaseBusinessCasePricesOverviewQuery(
    {businessCaseId},
    {skip: businessCase?.businessCaseState === 'CONCEPT'}
  );
  const {
    data: saleVehiclePrices,
    isLoading: isLoadingSaleVehiclePrices,
    isError: isSaleVehiclePricesError,
  } = useGetSaleBusinessCasePricesOverviewQuery(
    {businessCaseId},
    {skip: businessCase?.businessCaseState === 'CONCEPT'}
  );

  const reasonCloseName = useCodelistCodeName(
    'unsuccessful_business_case_reason',
    businessCase?.reasonCloseCodeId
  );
  const isBrokerage = businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE';
  const isSaleBrokerage =
    businessCase?.businessCaseInternalType === 'SELLING' && saleVehicleData?.type === 'BROKERAGE';

  const saleVehicles = offer?.saleVehicles;
  const purchaseVehicles = isBrokerage ? offer?.purchaseBrokerageVehicles : offer?.purchaseVehicles;

  const purchaseVehicle = isBrokerage
    ? head((purchaseVehicles ?? []) as Array<NewOfferPurchaseBrokerageVehicleResponseBody>)
    : undefined;

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const {
    canReadVehicleCosts: hasReadCostsPermission,
    canReadVehicleEarnings: hasVehicleReadEarningsPermission,
    canReadVehicleSellingPrice,
    canReadVehiclePurchasePrice,
  } = usePricePermissions({
    vehicleRecordId: purchaseVehicle?.vehicleId,
    businessCaseRecordId: null,
  });

  const [hasSetBusinessCasePurchaseNotePermission] = usePermissions({
    permissionKeys: ['setBusinessCasePurchaseNote'],
    scopes: {
      setBusinessCasePurchaseNote: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
    },
  });

  const canReadCosts =
    businessCase?.businessCaseInternalType === 'BUYING' ||
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE' ||
    businessCase?.businessCaseInternalType === 'SELLING'
      ? hasReadCostsPermission
      : true;

  const isNoteEditable =
    businessCase?.businessCaseInternalType === 'SELLING'
      ? true
      : hasSetBusinessCasePurchaseNotePermission;

  const canReadVehicleEarnings =
    businessCase?.businessCaseInternalType === 'SELLING' ||
    businessCase?.businessCaseInternalType === 'BUYING' ||
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE'
      ? hasVehicleReadEarningsPermission
      : true;

  const getFinancesView = () => {
    if (isBrokerage) {
      return (
        <>
          <Show whenFeatureDisabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <Grid columns={4}>
              <PriceBox
                data-testid={testIds.businessCase.overview('totalAmount')}
                title={i18n.t('entity.order.labels.totalAmount')}
                price={offer?.offerTotalPrice?.withVat}
                priceSub={offer?.offerTotalPrice?.withoutVat}
              />
              <PriceBox
                data-testid={testIds.businessCase.overview('totalExpectedMargin')}
                title={i18n.t('page.businessCase.labels.totalExpectedMargin')}
                hasAmountSign
                price={purchaseVehicle?.brokerageFees?.profit?.withVat}
                percentTag={
                  purchaseVehicle?.brokerageFees?.profit?.percentage
                    ? Number(purchaseVehicle?.brokerageFees?.profit?.percentage)
                    : undefined
                }
                priceSub={purchaseVehicle?.brokerageFees?.profit?.withoutVat}
              />
            </Grid>
          </Show>
          <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <DataStatus
              isEmpty={isNilOrEmpty(purchaseVehiclePrices)}
              isLoading={isLoadingPurchaseVehiclePrices}
              isError={isPurchaseVehiclePricesError}
              minHeight={10}
            >
              <Grid columns={4}>
                <PriceBox
                  data-testid={testIds.businessCase.buying('summary-vehicleSellingPrice')}
                  align="left"
                  price={purchaseVehiclePrices?.vehicleSellingPrice?.withVat}
                  priceSub={purchaseVehiclePrices?.vehicleSellingPrice?.withoutVat}
                  isVatDeductible={purchaseVehicle?.pricing?.vatDeductible}
                  title={i18n.t('entity.businessCase.labels.vehicleSellingPrice')}
                />
                <Show when={canReadCosts}>
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-totalExpectedCosts')}
                    align="left"
                    price={purchaseVehiclePrices?.totalCosts}
                    title={i18n.t('entity.businessCase.labels.totalExpectedCosts')}
                  />
                </Show>
                <Show when={canReadVehicleEarnings}>
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-vehicleGrossMargin')}
                    colored
                    align="left"
                    percentTag={Number(purchaseVehiclePrices?.vehicleMargin?.percentage)}
                    price={purchaseVehiclePrices?.vehicleMargin?.amount}
                    title={i18n.t('entity.businessCase.labels.vehicleGrossMargin')}
                    tooltipText={i18n.t('entity.businessCase.labels.vehicleGrossMarginTooltip')}
                  />
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-totalGrossMargin')}
                    colored
                    align="left"
                    percentTag={Number(purchaseVehiclePrices?.totalMargin?.percentage)}
                    price={purchaseVehiclePrices?.totalMargin?.amount}
                    title={i18n.t('entity.businessCase.labels.totalGrossMargin')}
                    tooltipText={i18n.t('entity.businessCase.labels.totalGrossMarginTooltip')}
                  />
                </Show>
                <Hide when={canReadVehicleEarnings}>
                  <Space fillAvailable />
                  <Space fillAvailable />
                </Hide>
              </Grid>
            </DataStatus>
          </Show>
        </>
      );
    }

    if (businessCaseType === 'BUYING') {
      return (
        <>
          <Show whenFeatureDisabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <Grid columns={4}>
              <PriceBox
                data-testid={testIds.businessCase.overview('totalAmount')}
                title={i18n.t('entity.order.labels.totalAmount')}
                price={offer?.offerTotalPrice?.withVat}
                priceSub={offer?.offerTotalPrice?.withoutVat}
              />
              <PriceBox
                data-testid={testIds.businessCase.overview('totalExpectedMargin')}
                title={i18n.t('page.businessCase.labels.totalExpectedMargin')}
                hasAmountSign
                price={offer?.totalMarginPrice?.withVat}
                percentTag={
                  offer?.totalMarginPercentage ? Number(offer?.totalMarginPercentage) : undefined
                }
                priceSub={offer?.totalMarginPrice?.withoutVat}
              />
            </Grid>
          </Show>
          <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <DataStatus
              isEmpty={isNilOrEmpty(purchaseVehiclePrices)}
              isLoading={isLoadingPurchaseVehiclePrices}
              isError={isPurchaseVehiclePricesError}
              minHeight={10}
            >
              <Grid columns={4}>
                <Show when={canReadVehiclePurchasePrice}>
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-buyingPrice')}
                    align="left"
                    price={purchaseVehiclePrices?.vehiclePurchasePrice?.withVat}
                    priceSub={purchaseVehiclePrices?.vehiclePurchasePrice?.withoutVat}
                    isVatDeductible={saleVehicleData?.isVatDeductible}
                    title={i18n.t('entity.businessCase.labels.vehicleBuyingPrice')}
                  />
                </Show>
                <Show when={canReadCosts}>
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-costs')}
                    align="left"
                    price={purchaseVehiclePrices?.additionalCosts}
                    title={i18n.t('entity.businessCase.labels.additionalExpectedCosts')}
                    tooltipText={i18n.t(
                      'entity.businessCase.labels.additionalExpectedCostsTooltip'
                    )}
                  />
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-totalExpectedCosts')}
                    align="left"
                    price={purchaseVehiclePrices?.totalCosts}
                    title={i18n.t('entity.businessCase.labels.totalExpectedCosts')}
                  />
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-vehicleGrossMargin')}
                    colored
                    align="left"
                    percentTag={Number(purchaseVehiclePrices?.vehicleMargin?.percentage)}
                    price={purchaseVehiclePrices?.vehicleMargin?.amount}
                    title={i18n.t('entity.businessCase.labels.vehicleGrossMargin')}
                    tooltipText={i18n.t('entity.businessCase.labels.vehicleGrossMarginTooltip')}
                  />
                </Show>
                <Hide when={canReadCosts}>
                  <Space fillAvailable />
                  <Space fillAvailable />
                  <Space fillAvailable />
                </Hide>

                <Show when={canReadVehiclePurchasePrice}>
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-vehicleSellingPrice')}
                    align="left"
                    price={purchaseVehiclePrices?.vehicleSellingPrice?.withVat}
                    priceSub={purchaseVehiclePrices?.vehicleSellingPrice?.withoutVat}
                    isVatDeductible={isBrokerage}
                    title={i18n.t('entity.businessCase.labels.vehicleSellingPrice')}
                  />
                </Show>
                <Show when={canReadVehicleEarnings}>
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-expectedEarnings')}
                    align="left"
                    price={purchaseVehiclePrices?.additionalEarnings}
                    title={i18n.t('entity.businessCase.labels.expectedEarnings')}
                    tooltipText={i18n.t('entity.businessCase.labels.expectedEarningsTooltip')}
                  />
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-realEarnings')}
                    align="left"
                    price={purchaseVehiclePrices?.totalEarnings}
                    title={i18n.t('entity.businessCase.labels.totalExpectedEarnings')}
                  />
                  <PriceBox
                    data-testid={testIds.businessCase.buying('summary-totalGrossMargin')}
                    colored
                    align="left"
                    percentTag={Number(purchaseVehiclePrices?.totalMargin?.percentage)}
                    price={purchaseVehiclePrices?.totalMargin?.amount}
                    title={i18n.t('entity.businessCase.labels.totalGrossMargin')}
                    tooltipText={i18n.t('entity.businessCase.labels.totalGrossMarginTooltip')}
                  />
                </Show>
                <Hide when={canReadVehicleEarnings}>
                  <Space fillAvailable />
                  <Space fillAvailable />
                  <Space fillAvailable />
                </Hide>
              </Grid>
            </DataStatus>
          </Show>
        </>
      );
    }

    if (businessCaseType === 'SELLING') {
      return (
        <>
          <Show whenFeatureDisabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <Grid columns={4}>
              <PriceBox
                data-testid={testIds.businessCase.overview('totalAmount')}
                title={i18n.t('entity.order.labels.totalAmount')}
                price={offer?.offerTotalPrice?.withVat}
                priceSub={offer?.offerTotalPrice?.withoutVat}
              />
              <PriceBox
                data-testid={testIds.businessCase.overview('totalUpsell')}
                title={i18n.t('entity.order.labels.totalUpsell')}
                price={offer?.totalUpsell?.withVat}
                priceSub={offer?.totalUpsell?.withoutVat || undefined}
              />
              <Hide when={businessCase?.brokerageBusinessCaseId}>
                <PriceBox
                  data-testid={testIds.businessCase.overview('totalDiscount')}
                  title={i18n.t('entity.order.labels.totalDiscount')}
                  price={offer?.totalDiscountPrice?.withVat}
                  percentTag={Number(offer?.totalDiscountPercentage)}
                  priceSub={offer?.totalDiscountPrice?.withoutVat}
                  hasAmountSign
                  isDiscount
                />
              </Hide>
              <PriceBox
                data-testid={testIds.businessCase.overview('totalMargin')}
                title={i18n.t('entity.order.labels.totalMargin')}
                hasAmountSign
                colored
                price={offer?.totalMarginPrice?.withVat}
                percentTag={Number(offer?.totalMarginPercentage)}
                priceSub={offer?.totalMarginPrice?.withoutVat}
              />
            </Grid>
          </Show>
          <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <DataStatus
              isEmpty={isNilOrEmpty(saleVehiclePrices)}
              isLoading={isLoadingSaleVehiclePrices}
              isError={isSaleVehiclePricesError}
              minHeight={10}
            >
              <Grid columns={4}>
                <Show when={canReadVehicleSellingPrice}>
                  <PriceBox
                    data-testid={testIds.businessCase.overview('sellingPrice')}
                    align="left"
                    price={saleVehiclePrices?.vehicleSellingPrice?.withVat}
                    priceSub={saleVehiclePrices?.vehicleSellingPrice?.withoutVat}
                    isVatDeductible={saleVehicleData?.isVatDeductible}
                    title={i18n.t`entity.businessCase.labels.vehicleSellingPrice`}
                  />
                </Show>
                <PriceBox
                  data-testid={testIds.businessCase.overview('summary-upsell')}
                  align="left"
                  price={saleVehiclePrices?.upsell?.withVat}
                  priceSub={saleVehiclePrices?.upsell?.withoutVat}
                  title={i18n.t('entity.businessCase.labels.upsell')}
                />
                <Hide when={isBrokerage || saleVehicleData?.type === 'BROKERAGE'}>
                  <PriceBox
                    data-testid={testIds.businessCase.overview('summary-discount')}
                    align="left"
                    price={
                      isNotNilOrEmpty(saleVehiclePrices?.discount?.withVat?.amount)
                        ? {
                            amount: `-${saleVehiclePrices?.discount?.withVat?.amount}`,
                            currency: saleVehiclePrices?.discount?.withVat?.currency,
                          }
                        : undefined
                    }
                    priceSub={
                      isNotNilOrEmpty(saleVehiclePrices?.discount?.withoutVat?.amount)
                        ? {
                            amount: `-${saleVehiclePrices?.discount?.withoutVat?.amount}`,
                            currency: saleVehiclePrices?.discount?.withoutVat?.currency,
                          }
                        : undefined
                    }
                    isVatDeductible={saleVehicleData?.isVatDeductible}
                    title={i18n.t('general.labels.discount')}
                  />
                </Hide>
                <PriceBox
                  data-testid={testIds.businessCase.overview('totalPrice')}
                  align="left"
                  price={saleVehiclePrices?.totalPrice?.withVat}
                  priceSub={saleVehiclePrices?.totalPrice?.withoutVat}
                  title={i18n.t`entity.businessCase.labels.totalPrice`}
                />
                <Show when={saleVehicleData?.type === 'BROKERAGE' || isBrokerage}>
                  <Space fillAvailable />
                </Show>

                <Show when={canReadVehicleSellingPrice || canReadCosts}>
                  <PriceBox
                    data-testid={testIds.businessCase.overview('summary-realCosts')}
                    align="left"
                    price={saleVehiclePrices?.realCosts}
                    title={i18n.t('entity.businessCase.labels.realCosts')}
                    tooltipText={i18n.t('entity.businessCase.labels.realCostsTooltip')}
                  />
                </Show>
                <Hide when={canReadVehicleSellingPrice || canReadCosts}>
                  <Space fillAvailable />
                </Hide>
                <Show when={canReadVehicleEarnings}>
                  <PriceBox
                    data-testid={testIds.businessCase.overview('summary-totalExpectedEarnings')}
                    align="left"
                    price={saleVehiclePrices?.expectedEarnings}
                    title={i18n.t('entity.businessCase.labels.totalExpectedEarnings')}
                    tooltipText={i18n.t('entity.businessCase.labels.totalExpectedEarningsTooltip')}
                  />
                </Show>
                <Hide when={canReadVehicleEarnings}>
                  <Space fillAvailable />
                </Hide>
                <Show when={canReadCosts && isSaleBrokerage ? canReadVehicleEarnings : true}>
                  <PriceBox
                    data-testid={testIds.businessCase.overview('summary-totalGrossMargin')}
                    colored
                    align="left"
                    price={saleVehiclePrices?.vehicleMargin?.amount}
                    percentTag={Number(saleVehiclePrices?.vehicleMargin?.percentage)}
                    title={i18n.t('entity.businessCase.labels.grossMarginOnVehicle')}
                  />
                </Show>
                <Hide when={canReadCosts && isSaleBrokerage ? canReadVehicleEarnings : true}>
                  <Space fillAvailable />
                </Hide>
                <Show when={canReadCosts && canReadVehicleEarnings}>
                  <PriceBox
                    data-testid={testIds.businessCase.overview('summary-grossMarginTotal')}
                    title={i18n.t('entity.businessCase.labels.grossMarginTotal')}
                    hasAmountSign
                    colored
                    price={saleVehiclePrices?.totalMargin?.amount}
                    percentTag={Number(saleVehiclePrices?.totalMargin?.percentage)}
                  />
                </Show>
                <Hide when={canReadCosts && canReadVehicleEarnings}>
                  <Space fillAvailable />
                </Hide>
              </Grid>
            </DataStatus>
          </Show>
        </>
      );
    }

    return (
      <EmptyStatus
        headline={i18n.t('entity.vehicle.notifications.noVehicles')}
        subheadline={i18n.t('entity.businessCase.notifications.financesSub')}
      />
    );
  };

  return (
    <Main>
      <Grid columns={2} align="flex-start">
        {businessCaseState === 'UNSUCCESSFUL' && (
          <GridItem span={2}>
            <Card title={i18n.t('entity.order.labels.caseClosed')}>
              <CloseDialogCard
                closedBy={businessCase?.closedBy}
                closedAt={businessCase?.closedAt}
                reasonCloseNote={businessCase?.reasonCloseNote}
                reasonClose={reasonCloseName}
                data-testid="businessCse-overview-unsuccessful"
              />
            </Card>
          </GridItem>
        )}
        <Show when={canReadVehicleSellingPrice && canReadVehiclePurchasePrice}>
          <GridItem span={2}>
            <Card title={i18n.t('entity.order.labels.finances')}>{getFinancesView()}</Card>
          </GridItem>
        </Show>
        <GridItem span={2}>
          <Card
            title={i18n.t('entity.vehicle.labels.pageTitle')}
            data-testid={testIds.businessCase.overview('vehicles')}
          >
            {!!purchaseVehicles?.length || !!saleVehicles?.length ? (
              <Grid columns={1}>
                {saleVehicles?.map((vehicle, i) => (
                  <GridItem key={vehicle.id}>
                    <BusinessCaseOverviewVehicle
                      data-testid={testIds.businessCase.overview(`saleVehicle[${i}]`)}
                      vehicle={vehicle}
                    />
                  </GridItem>
                ))}

                {!!purchaseVehicles?.length && !!saleVehicles?.length && <Separator />}

                {purchaseVehicles?.map((vehicle, i) => (
                  <GridItem key={vehicle.id}>
                    <BusinessCaseOverviewVehicle
                      data-testid={testIds.businessCase.overview(`purchaseVehicle[${i}]`)}
                      vehicle={vehicle}
                    />
                  </GridItem>
                ))}
              </Grid>
            ) : (
              <EmptyStatus
                data-testid={testIds.businessCase.overview('no-vehicles')}
                headline={i18n.t('entity.vehicle.notifications.noVehicles')}
                subheadline={i18n.t('entity.vehicle.notifications.noVehiclesSub')}
              />
            )}
          </Card>
        </GridItem>
        <CommentsWidget
          title={i18n.t('entity.businessCase.labels.businessCaseComments')}
          resourceId={EntityResourceIds.businessCase}
          recordId={businessCase?.id ?? ''}
        />
        <NoteWidget
          resourceId={EntityResourceIds.businessCase}
          recordId={businessCase?.id ?? ''}
          context={EntityResourceIds.businessCase}
          isEditable={isNoteEditable}
        />
      </Grid>
    </Main>
  );
}

import {CustomFilterProps} from 'ag-grid-react';
import {Chips, Separator, Slider} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useCallback, useMemo} from 'react';

import {isNumber, isString} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {useSliderTicks} from '../../hooks/useSliderTicks';
import {QuickFilters} from '../../types/Api';

export type SliderFilterProps = {
  min: number;
  max: number;
  minOpen: boolean;
  maxOpen: boolean;
  unit: string | null;
  stepSize: number | null;
  steps: number[];
  markerSize: number | null;
  markers?: number[];
  decimalsAllowed: boolean;
  inputsVisible: boolean;
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function SliderFilter({
  model,
  onModelChange,
  min,
  max,
  unit,
  stepSize,
  steps,
  markerSize,
  markers,
  isDisabled,
  quickFilters,
  ...props
}: SliderFilterProps) {
  const ticks = useSliderTicks(min, max, markerSize, markers);
  const midValue = useMemo<number>(() => (max + min) / 2, [min, max]);
  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue: onModelChange,
    defaultValue: null,
  });

  const valueFormatter = useCallback(
    (value: number) => (unit ? `${value} [${unit}]` : `${value}`),
    [unit]
  );

  const isQuickFilterValue = isString(model) ? [model] : null;
  const isSliderValue = isNumber(model) ? model : midValue;

  return (
    <VStack>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('slider-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Slider
        isDisabled={isDisabled}
        min={min}
        max={max}
        step={stepSize ?? steps ?? 1}
        value={isSliderValue}
        onChange={onModelChange}
        formatValue={valueFormatter}
        ticks={ticks}
        onlyEdges={true}
        displayValue="auto"
        data-testid={suffixTestId('slider-filter', props)}
      />
    </VStack>
  );
}

import {
  Action,
  Card,
  closeCurrentDialog,
  closeDialog,
  openConfirmDialog,
  openDialog,
  TabProps,
  Tabs,
} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {always, head} from 'ramda';
import {isArray, isString} from 'ramda-adjunct';

import {
  GetWarehousesResponse,
  useGetItemsBasketActionsQuery,
  usePostMaterialBasketItemForPriceListMutation,
  usePostMaterialBasketItemForWarehouseMutation,
  usePutItemsBasketActionMutation,
  WarehouseAction,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {
  ArticleCreate,
  ArticleFolderTree,
  getSingleOrUndefined,
  handleApiError,
  RequestTabMaterialWarehouseTabExternalFilters,
  useWarehouseDataGridTreeFolder,
} from '@dms/shared';

import {buildArray, composePath, suffixTestId, TestIdProps, useNavigate} from 'shared';

import {ActionCallback, DataGrid, refreshDatagrid} from 'features/datagrid';

import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';
import {ExternalSystemStateType} from '../types/externalSystemStateType';
import {OpenXentryLink} from './OpenXentryLink';

const EXTERNAL_SYSTEM_ADD_KEY = 'xentry-xhpi-cart';
const EXTERNAL_SYSTEM_UPLOAD_KEY = 'xentry-xhpi-inquiry';
const DSMATERIAL_EXTERNAL_FILTER_ID = 'dsmaterial-external-filter';

const SPACE_AROUND_DG_FROM_VH_IN_REM = 16;

const TABS_IDS = {
  warehouseTab: 'serviceOrder-warehouseTab',
  priceListTab: 'serviceOrder-priceListTab',
};

const CREATE_ARTICLE_DIALOG = 'createArticleDialog';

interface MaterialListCardProps extends TestIdProps {
  onExternalSystemChange: (state: ExternalSystemStateType) => void;
  shouldResetSmartSearch: boolean;
  availableWarehouses: GetWarehousesResponse;
}

export function MaterialListCard(props: MaterialListCardProps) {
  const {serviceCaseId, orderId, addMaterialRequestJobId} = useWorkshopUrl();

  const navigate = useNavigate();

  const [activeTabId, setActiveTabId] = useState(TABS_IDS.warehouseTab);
  const [dataGridFilters, setDataGridFilters] = useState<Record<string, string>>({});

  const [folderTreeRef, dataGridModifier] = useWarehouseDataGridTreeFolder({
    availableWarehouses: props.availableWarehouses,
  });

  const {data} = useGetItemsBasketActionsQuery({
    serviceCaseId,
    serviceOrderId: orderId,
    serviceJobId: addMaterialRequestJobId,
    basketType: 'material-basket',
  });
  const [putItemsBasketAction, {isLoading: isPutItemsLoading}] = usePutItemsBasketActionMutation();
  const [addMaterialBasketItemForWarehouse] = usePostMaterialBasketItemForWarehouseMutation();
  const [addMaterialBasketItemForPriceList] = usePostMaterialBasketItemForPriceListMutation();

  const externalSystemAddOption = data?.itemsBasketActions?.options?.find(
    (option) => option?.key === EXTERNAL_SYSTEM_ADD_KEY
  );
  const externalSystemUploadOption = data?.itemsBasketActions?.options?.find(
    (option) => option?.key === EXTERNAL_SYSTEM_UPLOAD_KEY
  );

  const onAddClick = () => {
    putItemsBasketAction({
      basketType: 'material-basket',
      body: {actionKey: EXTERNAL_SYSTEM_ADD_KEY},
      serviceCaseId,
      serviceOrderId: orderId,
      serviceJobId: addMaterialRequestJobId,
    })
      .unwrap()
      .then((response) => {
        openDialog(
          <OpenXentryLink
            xentryLink={response?.xentry_xhpi_cart?.xhpiUrl}
            onClose={closeCurrentDialog}
          />,
          {size: 'small'}
        );
        props.onExternalSystemChange('PREPARED');
      })
      .catch(handleApiError);
  };

  const onUploadClick = () => {
    openConfirmDialog({
      text: i18n.t('entity.addMaterial.lables.uploadFromXentry'),
      onConfirm: () =>
        putItemsBasketAction({
          basketType: 'material-basket',
          body: {actionKey: EXTERNAL_SYSTEM_UPLOAD_KEY},
          serviceCaseId,
          serviceOrderId: orderId,
          serviceJobId: addMaterialRequestJobId,
        })
          .unwrap()
          .then(() => props.onExternalSystemChange('UPLOAD_SCCESS'))
          .catch((error) =>
            handleApiError(error, {callback: () => props.onExternalSystemChange('UPLOAD_ERROR')})
          ),
    });
  };

  const onCreateArticleClick = () => {
    openDialog(
      <ArticleCreate
        defaultValues={{
          catalogNumber:
            'manufacturerNumber' in dataGridFilters
              ? dataGridFilters.manufacturerNumber
              : undefined,
          manufacturerId:
            'manufacturerId' in dataGridFilters
              ? getSingleOrUndefined(dataGridFilters.manufacturerId)
              : undefined,
          warehouseId:
            'warehouseId' in dataGridFilters
              ? getSingleOrUndefined(dataGridFilters.warehouseId)
              : undefined,
          name: 'name' in dataGridFilters ? dataGridFilters.name : undefined,
        }}
        onArticleCreate={(createdArticleData) => {
          if (createdArticleData.submittedButton === 'CREATE_AND_EDIT') {
            return navigate(
              composePath(warehouseRoutes.articleDetailOverview, {
                params: {
                  warehouseId: createdArticleData.formValues.warehouseId,
                  id: createdArticleData.articleId,
                },
              })
            );
          }
          closeCurrentDialog();
          refreshDatagrid('warehouse-articles-cart');
        }}
        submitButtonTitle={i18n.t('general.actions.create')}
        onClose={() => closeDialog(CREATE_ARTICLE_DIALOG)}
        hasCreateAndEditButton
        data-testid={testIds.workshop.serviceOrders('dialogs.createArticle')}
      />,
      {
        id: CREATE_ARTICLE_DIALOG,
        title: i18n.t('entity.warehouse.labels.createNewArticle'),
        withAdditionalFooter: true,
      }
    );
  };

  const warehouseArticleActionCallback: ActionCallback = ({actionKey, rowId}) => {
    const warehouseArticleId = isArray(rowId) ? head(rowId) : rowId;

    if (!warehouseArticleId) {
      return;
    }

    const warehouseAction = match<string, WarehouseAction | null>(actionKey)
      .with('nonBinding', always('non-binding'))
      .with('dispatch', always('dispatch-request'))
      .with('request', always('request'))
      .otherwise(always(null));

    warehouseAction &&
      addMaterialBasketItemForWarehouse({
        serviceCaseId,
        serviceOrderId: orderId,
        serviceJobId: addMaterialRequestJobId,
        warehouseAction,
        body: {warehouseArticleId},
      })
        .unwrap()
        .catch(handleApiError);
  };

  const supplierPriceListActionCallback: ActionCallback = async ({actionKey, rowData}) => {
    switch (actionKey) {
      case 'priceList':
        if ('id' in rowData && isString(rowData.id)) {
          await addMaterialBasketItemForPriceList({
            serviceCaseId,
            serviceOrderId: orderId,
            serviceJobId: addMaterialRequestJobId,
            body: {warehouseArticleId: rowData.id},
          })
            .unwrap()
            .catch(handleApiError);
        }
        break;
    }
  };

  const tabs: TabProps[] = [
    {
      id: TABS_IDS.warehouseTab,
      title: i18n.t('entity.warehouse.labels.warehouse'),
      content: (
        <Box height={`calc(100vh - ${SPACE_AROUND_DG_FROM_VH_IN_REM}rem)`} width="100%">
          <HStack height="100%" spacing={4}>
            <Box flex={1} maxHeight="100%" overflow="auto">
              <VStack height="100%">
                <Box>
                  <div id={DSMATERIAL_EXTERNAL_FILTER_ID}></div>
                </Box>
                <Space vertical={8} />
                <ArticleFolderTree
                  ref={folderTreeRef}
                  data-testid={testIds.warehouse.articleList('articleFolderTree')}
                />
              </VStack>
            </Box>
            <Box flex={3}>
              <DataGrid
                // DG must be re-rendered on folder change to update the query modifier
                // eslint-disable-next-line no-restricted-syntax
                key={`${dataGridModifier?.key}_${props.shouldResetSmartSearch}`}
                gridCode="warehouse-articles-cart"
                externalFilterId={DSMATERIAL_EXTERNAL_FILTER_ID}
                onFilterChanged={setDataGridFilters}
                filterComponent={(datagridProps) => (
                  <RequestTabMaterialWarehouseTabExternalFilters
                    data-testid={suffixTestId('dg-external-filters', props)}
                    {...datagridProps}
                  />
                )}
                actionCallback={warehouseArticleActionCallback}
                emptyState={{
                  headline: i18n.t('page.warehouseDetail.labels.emptyAssortment'),
                }}
                queryModifier={dataGridModifier?.queryModifier}
                _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'CUSTOM'}}
                data-testid={testIds.workshop.serviceCaseDetail('warehouse-articles')}
              />
            </Box>
          </HStack>
        </Box>
      ),
    },
    {
      id: TABS_IDS.priceListTab,
      title: i18n.t('entity.warehouse.labels.priceList'),
      content: (
        <Box height={`calc(100vh - ${SPACE_AROUND_DG_FROM_VH_IN_REM}rem)`} width="100%">
          <DataGrid
            gridCode="supplier-price-list-cart"
            actionCallback={supplierPriceListActionCallback}
            queryModifier={dataGridModifier?.queryModifier}
            externalFilterId="work-catalogue-external-filter"
            data-testid={suffixTestId('workCatalogue', props)}
          />
        </Box>
      ),
    },
  ];

  return (
    <Card
      title={i18n.t('entity.addMaterial.lables.itemsList')}
      actions={buildArray<Action>()
        .when(externalSystemAddOption && externalSystemAddOption.style !== 'hidden', {
          type: 'button',
          variant: 'link',
          leftIcon: 'content/add_circle',
          title: i18n.t(`entity.addMaterial.actions.addFromExternalSystem`),
          isDisabled: externalSystemAddOption?.style === 'disabled',
          isLoading: externalSystemAddOption?.style === 'enable' && isPutItemsLoading,
          onClick: onAddClick,
        })
        .when(externalSystemUploadOption && externalSystemUploadOption.style !== 'hidden', {
          type: 'button',
          variant: 'link',
          leftIcon: 'file/upload',
          title: i18n.t(`entity.addMaterial.actions.uploadFromExternalSystem`),
          isDisabled: externalSystemUploadOption?.style === 'disabled',
          isLoading: externalSystemUploadOption?.style === 'enable' && isPutItemsLoading,
          onClick: onUploadClick,
        })
        .when(activeTabId === TABS_IDS.warehouseTab, {
          type: 'button',
          variant: 'secondary',
          leftIcon: 'content/add_circle',
          title: i18n.t('entity.warehouse.labels.createNewArticle'),
          onClick: onCreateArticleClick,
        })}
      data-testid={suffixTestId('header', props)}
      isFullHeight
    >
      <VStack height="100%" grow={1}>
        <Tabs
          tabs={tabs}
          activeTabId={activeTabId}
          onChange={(id) => setActiveTabId(id)}
          data-testid={testIds.workshop.serviceCaseDetail('tabs')}
          isLazy
          lazyBehavior="unmount"
        />
      </VStack>
    </Card>
  );
}

import {
  CheckboxTree,
  CheckboxTreeOptionType,
  DataStatus,
  EmptyStatus,
  Separator,
} from 'platform/components';
import {Box, Heading, Show, Space, VStack} from 'platform/foundation';

import {memo} from 'react';

import {isNil, isNotNil} from 'ramda';

import {Make} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useMakeModelFilter} from '../../../hooks/useMakeModelFilter';

export function ModelFamiliesCheckboxTree(props: TestIdProps) {
  const {
    allSelectedMakes,
    allSelectedModels,
    getMake,
    getModel,
    getModelFamily,
    simpleUpdateModels,
    isLoading,
  } = useMakeModelFilter();

  const getDataForCheckboxTree = (make?: Make | null): CheckboxTreeOptionType[] => {
    const withoutGroup =
      make?.models
        .map((modelKey) => getModel(modelKey))
        .filter((model) => isNotNil(model) && isNil(model.modelFamily))
        .map((model) => ({
          value: model?.name ?? '',
          label: model?.label ?? '',
          options: [],
        })) ?? [];

    const withGroup =
      make?.modelFamilies
        .map((familyKey) => getModelFamily(familyKey))
        .map((modelFamily) => ({
          value: modelFamily?.name ?? '',
          label: modelFamily?.label ?? '',
          options: modelFamily?.models.map((modelKey) => {
            const model = getModel(modelKey);
            return {
              value: model?.name ?? '',
              label: model?.label ?? '',
            };
          }),
        })) ?? [];

    return [...withGroup, ...withoutGroup];
  };

  return (
    <DataStatus
      isLoading={isLoading}
      isEmpty={allSelectedMakes.length === 0}
      emptyMessage={i18n.t('page.filters.notifications.firstSelectMakes')}
    >
      <MemoizedCheckboxTree
        allSelectedMakes={allSelectedMakes}
        getMake={getMake}
        getDataForCheckboxTree={getDataForCheckboxTree}
        simpleUpdateModels={simpleUpdateModels}
        allSelectedModels={allSelectedModels}
        data-testid={suffixTestId('modelFamilies', props)}
      />
    </DataStatus>
  );
}

interface MemoizedCheckboxListProps extends TestIdProps {
  allSelectedMakes: string[];
  allSelectedModels: string[];
  getMake: (makeKey?: string) => Make | null | undefined;
  getDataForCheckboxTree: (make?: Make | null) => CheckboxTreeOptionType[];
  simpleUpdateModels: (value: string[] | null) => void;
}

const MemoizedCheckboxTree = memo(
  (props: MemoizedCheckboxListProps) =>
    props.allSelectedMakes.map((makeKey, index) => {
      const make = props.getMake(makeKey);
      const nodes = props.getDataForCheckboxTree(make);
      return (
        <VStack key={makeKey}>
          <Show when={index > 0}>
            <Separator />
          </Show>
          <Box
            position="sticky"
            top={0}
            zIndex="CONTENT"
            backgroundColor="general.white"
            paddingBottom={2}
          >
            <Heading size={5}>{make?.label}</Heading>
          </Box>
          <CheckboxTree
            onChange={props.simpleUpdateModels}
            options={nodes}
            value={props.allSelectedModels}
            data-testid={suffixTestId('modelFamilies', props)}
          />

          <Show when={nodes.length === 0}>
            <EmptyStatus headline={i18n.t('page.filters.labels.noModels')} />
          </Show>

          <Space vertical={2} />
        </VStack>
      );
    }),
  (prevProps, nextProps) =>
    prevProps.allSelectedMakes === nextProps.allSelectedMakes &&
    prevProps.allSelectedModels === nextProps.allSelectedModels
);

import {ManagedSuccessDialogProps} from '../../SuccessDialog/ManagedSuccessDialogProps';
import {DIALOGS_EVENTS} from './dialogsEvents';

/**
 * @about Opens a success dialog. Dialog will be
 * closed automatically when confirmed / time passes.
 *
 * Can be closed programmatically with closeCurrentDialog
 * or closeDialog('dialog-id') utilities.
 *
 * @param options - Dialog options - ManagedSuccessDialogProps
 *
 * @example
 * openSuccessDialog({
 *    id: 'success-dialog',
 *    heading: 'Task completed!',
 *    subheading: 'Good job!',
 *    buttonLabel: 'OK',
 *    "data-testid": 'success-dialog'
 * })
 *
 *
 */
export function openSuccessDialog(options: ManagedSuccessDialogProps) {
  window.dispatchEvent(new CustomEvent(DIALOGS_EVENTS.openSuccess, {detail: {options}}));
}

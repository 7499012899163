import {FloatingTree} from '@floating-ui/react';
import {Text, TextProps} from 'platform/foundation';

import {ReactElement, ReactNode} from 'react';

import {TestIdProps} from 'shared';

import {Placement} from '../../../types/Placement';
import {DropdownContainer} from './DropdownContainer';
import {DropdownItem} from './DropdownItem';

export interface DropdownSubmenuProps extends TestIdProps {
  label: string | ReactElement<TextProps, typeof Text>;
  children: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactElement;
  onClick?(): void;
  placement?: Placement;
  strategy?: 'fixed' | 'absolute';
  isLazy?: boolean;
  lazyBehavior?: 'unmount' | 'keepMounted';
  boundary?: 'clippingParents' | 'scrollParent' | HTMLElement;
  isActive?: boolean;
}

export function DropdownSubmenu(props: DropdownSubmenuProps) {
  const dropdownControl = <DropdownItem label={props.label} suffix={props.suffix} />;
  return (
    <FloatingTree>
      <DropdownContainer dropdownControl={dropdownControl} placement="right-end">
        {props.children}
      </DropdownContainer>
    </FloatingTree>
  );
}

/* eslint-disable no-restricted-syntax */

/**
 * @deprecated - use platform Form instead
 */
import {Config, Decorator, FormSubscription, Mutator, SubmissionErrors} from 'final-form';
import arrayMutators from 'final-form-arrays';
import {DeepPartial} from 'utility-types';
import {AnyObjectSchema} from 'yup';

import {ReactElement, SyntheticEvent, use, useState} from 'react';
import {
  Form as FinalForm,
  FormProps as FinalFormFormProps,
  FormRenderProps as FinalFormRenderProps,
} from 'react-final-form';

import {equals} from 'ramda';

import {usePriceCalculation} from '../../components/FinalForm/hooks/usePriceCalculation';
import {AnyObject} from '../../types/AnyObject';
import {PossibleObject} from '../../types/PossibleObject';
import {Condition} from './components/Condition';
import {FormField} from './components/FormField';
import {FormFieldArray} from './components/FormFieldArray';
import {Subscribe} from './components/Subscribe';
import {FormContext, FormContextType} from './FormContext';
import {FormApi} from './types/FormApi';
import {FormRenderProps} from './types/FormRenderProps';
import {composeInitialValues} from './utils/composeInitialValues';
import {removeEmptyItems} from './utils/removeEmptyItems';
import {validateFormValues} from './utils/yupValidator';

/**
 * @deprecated - use platform Form instead
 */
export interface FormBaseProps<
  FormValues extends Record<string, unknown>,
  InitialFormValues extends PossibleObject = undefined,
> extends Omit<
    FinalFormFormProps<FormValues, Partial<FormValues>>,
    'initialValues' | 'render' | 'decorators' | 'form' | 'values' | 'getFormValues'
  > {
  subscription?: FormSubscription;
  mutators?: {[key: string]: Mutator<FormValues>};
  decorators?: Array<Decorator<FormValues, Partial<FormValues>>>;
  form?: FormApi<FormValues, InitialFormValues>;
  /**
   * Provides function with form API and Form components (Field, FieldArray, Condition)
   * @param props form API and Form components (Field, FieldArray, Condition)
   */
  render: (props: FormRenderProps<FormValues, InitialFormValues>) => ReactElement;
  /**
   * Initial values provided to the form *from server*
   * If not the same type as FormValues, get formValues function must be provided
   */
  initialValues?: InitialFormValues extends undefined ? FormValues : InitialFormValues;
  /**
   * Default values provided to the form *when entity doesn't exist*
   */
  defaultValues?: DeepPartial<FormValues>;
  /** Inline form variant */
  inline?: boolean;
  /** A predicate to determine whether or not the initialValues prop has changed */
  initialValuesEqual?: (a?: AnyObject, b?: AnyObject) => boolean;
  /** JSON schema used for validation and field type guessing */
  schema?: AnyObjectSchema;
  getFormValues?: (values: InitialFormValues) => DeepPartial<FormValues>;
  getRequestBody?: (values: FormValues) => PossibleObject;
  validateAfterSubmit?: boolean;
  noValidate?: boolean;
  keepDirtyOnReinitialize?: boolean;
  restartOnSubmit?: boolean;
  formId?: string;
}

/**
 * Ensures that getFormValues function is required when
 * initial values type differs from form state type
 * @deprecated - use platform Form instead
 */
interface WithPostLoadFormat<
  FormValues extends Record<string, unknown>,
  InitialFormValues extends PossibleObject = undefined,
> extends FormBaseProps<FormValues, InitialFormValues> {
  getFormValues: (values: InitialFormValues) => DeepPartial<FormValues>;
}

/**
 * Ensures that getRequestBody function is required when
 * form state type differs from desired output format
 * @deprecated - use platform Form instead
 */
interface WithPreSave<
  FormValues extends Record<string, unknown>,
  InitialFormValues extends PossibleObject = undefined,
  RequestBody extends PossibleObject = undefined,
> extends FormBaseProps<FormValues, InitialFormValues> {
  getRequestBody: (values: FormValues) => RequestBody;
}

/**
 * @deprecated - use platform Form instead
 */
export type FormProps<
  FormValues extends Record<string, unknown> = Record<string, unknown>,
  InitialFormValues extends PossibleObject = undefined,
  RequestBody extends PossibleObject = undefined,
> = InitialFormValues extends undefined
  ? // If not initial values type
    RequestBody extends undefined
    ? // Without pre submit
      FormBaseProps<FormValues, InitialFormValues>
    : // With pre submit
      WithPreSave<FormValues, InitialFormValues, RequestBody>
  : // With initial values type
    InitialFormValues extends FormValues
    ? // Initial values are same type as form values
      RequestBody extends undefined
      ? // Without pre submit
        WithPostLoadFormat<FormValues, InitialFormValues>
      : // With pre submit
        FormBaseProps<FormValues, InitialFormValues>
    : // Initial values are different from form values
      RequestBody extends undefined
      ? // Without pre submit
        WithPostLoadFormat<FormValues, InitialFormValues>
      : // With pre submit
        WithPostLoadFormat<FormValues, InitialFormValues> &
          WithPreSave<FormValues, InitialFormValues, RequestBody>;

/**
 * *Root form component*
 *
 * Provides components for composing form together - **Field**, **FieldArray**, **Condition** - **Do not import them separately**
 *
 * Takes initial(server side data of existing entity) or default (defined on FE when creating new entity as preset) values
 *
 * Requires onSubmit function that fires when sending the form, or by AutoSave component to fire onBlur/Change
 *
 * Expects passing types of object held in state, eventually type of provided initial values (if it differs from state) and RequestBody type (if the state should be converted before passing to submit function)
 *
 * @param {FormProps} param0
 * @deprecated - use platform Form instead
 */
export function Form<
  /** Type of object used for form state - sets the structure of fields you can use */
  FormValues extends Record<string, unknown> = Record<string, unknown>,
  /** Type of initial values provided to form - if set, getFormValues must be provided as well */
  InitialFormValues extends PossibleObject = undefined,
  /** Type of object for API call - if set, getRequestBody must be provided as well */
  RequestBody extends PossibleObject = undefined,
>({
  onSubmit,
  initialValues,
  defaultValues,
  inline,
  schema,
  validateAfterSubmit,
  noValidate,
  keepDirtyOnReinitialize = true,
  restartOnSubmit = true,
  initialValuesEqual,
  formId,
  ...props
}: FormProps<FormValues, InitialFormValues, RequestBody>) {
  const {getFormValues, getRequestBody} = props;
  const {submitCount: parentSubmitCount} = use(FormContext);
  const [submitCount, setSubmitCount] = useState(0);

  const composedInitialValues = composeInitialValues<FormValues, InitialFormValues>(
    schema,
    defaultValues,
    initialValues as InitialFormValues,
    getFormValues
  );

  const handleSubmit = async (
    values: FormValues,
    form: FormApi<FormValues, InitialFormValues>,
    callback?: (errors?: SubmissionErrors) => void
  ): Promise<void> => {
    const valuesToSave = getRequestBody ? getRequestBody(values) : values;

    try {
      await onSubmit(removeEmptyItems(valuesToSave) as RequestBody, form, callback); // TODO: Use Form values or RequestType type in typings of onSubmit
      if (!keepDirtyOnReinitialize && restartOnSubmit) {
        //@ts-expect-error invalid types
        form.reset(composedInitialValues);
        setSubmitCount(0);
      }
    } catch (e: any) {
      return e;
    }
  };

  const render = (p: FinalFormRenderProps<FormValues, FormValues>): ReactElement => (
    <>
      {props.render({
        ...p,
        schema,
        usePriceCalculation,
        validateAfterSubmit,
        handleSubmit: (
          event?: Partial<Pick<SyntheticEvent, 'preventDefault' | 'stopPropagation'>>
        ) => {
          setSubmitCount(submitCount + 1);
          return p.handleSubmit(event);
        },
        Field: FormField,
        FieldArray: FormFieldArray,
        Condition,
        Subscribe,
      } as unknown as FormRenderProps<FormValues, FormValues>)}
    </>
  );

  return (
    <FormContext.Provider
      value={
        {
          validateAfterSubmit,
          noValidate,
          schema,
          formId,
          submitCount: submitCount > 0 ? submitCount : parentSubmitCount,
          setSubmitCount,
          Field: FormField,
          FieldArray: FormFieldArray,
          Condition,
          Subscribe,
        } as unknown as FormContextType
      }
    >
      <FinalForm<FormValues, FormValues>
        {...props}
        mutators={{
          ...arrayMutators,
          ...props.mutators,
        }}
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        subscription={props.subscription || {initialValues: true, submitting: true}} // NOTE: Keep this here otherwise everything re-renders like crazy
        initialValues={composedInitialValues}
        initialValuesEqual={initialValuesEqual || equals}
        onSubmit={handleSubmit as unknown as Config<FormValues, FormValues>['onSubmit']}
        render={render}
        validate={
          schema && !noValidate
            ? async (values) => {
                const result = await validateFormValues(schema)(
                  removeEmptyItems(values) as Partial<FormValues>
                );

                return result;
              }
            : undefined
        }
      />
    </FormContext.Provider>
  );
}

import {Button, Dropdown, DropdownItem, showNotification} from 'platform/components';
import {Hide, Show} from 'platform/foundation';
import {Nullish} from 'utility-types';

import {useParams} from 'react-router-dom';

import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {InspectionType, useInspectionListQuery, useListThemeQuery} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {useGetOrCreateInspection} from './hooks/useGetOrCreateInspection';
import {useWorkspaceLanguage} from './hooks/useWorkspaceLanguage';
import {openDigitalCertificate} from './utils/openDigitalCertificate';

const DATA_TEST_ID = 'digital-cert-link';

interface DigitalCertificateLinkProps {
  inspectionId?: string | Nullish;
  isDisabled?: boolean;
}

export const DigitalCertificateLink = (props: DigitalCertificateLinkProps) => {
  const {id: vehicleId} = useParams();
  const workspaceLanguage = useWorkspaceLanguage();
  const [canShareCarAuditData] = usePermissions({permissionKeys: ['carAuditShareLink']});
  const getOrCreateInspection = useGetOrCreateInspection();
  const {data: themes, isLoading: isLoadingThemes} = useListThemeQuery();
  const {data: vehicleInspections} = useInspectionListQuery(
    {
      inspectionListRequestBody: {
        vehicleId: vehicleId!, // `vehicleId` is checked in second argument of `useInspectionListQuery`
        inspectionType: InspectionType.VEHICLE_CONDITION,
      },
    },
    {skip: isNotNilOrEmpty(props.inspectionId) || isNilOrEmpty(vehicleId)}
  );

  // Either specific inspection passed as a prop or the vehicle condition inspection
  const inspectionId = props.inspectionId || vehicleInspections?.[0]?.id;
  const hasMultipleThemes = isNotNil(themes) && themes.length > 1;

  const onShareLink = async (themeId?: string) => {
    const existingInspectionId = await getOrCreateInspection(vehicleId, inspectionId).catch(() => {
      showNotification.error();
      return undefined;
    });

    if (!existingInspectionId) {
      return;
    }

    openDigitalCertificate({
      inspectionId: existingInspectionId,
      language: workspaceLanguage,
      themeId,
    });
  };

  return (
    <Show
      whenFeatureEnabled={featureFlags.DC_VEHICLE_INSPECTION_SHARE_LINK}
      when={canShareCarAuditData}
    >
      <Hide when={hasMultipleThemes}>
        <Button
          title={i18n.t('page.advertisement.labels.digitalCertificate')}
          variant="outlined"
          isLoading={isLoadingThemes}
          isDisabled={isNilOrEmpty(inspectionId) || props.isDisabled}
          onClick={() => {
            const singleTheme = themes?.[0];
            if (singleTheme) {
              return onShareLink(singleTheme.themeId);
            }

            return onShareLink();
          }}
          data-testid={DATA_TEST_ID}
        />
      </Hide>
      <Show when={hasMultipleThemes}>
        <Dropdown
          dropdownControl={
            <Button
              title={i18n.t('page.advertisement.labels.digitalCertificate')}
              variant="outlined"
              rightIcon="hardware/keyboard_arrow_down"
              data-testid={DATA_TEST_ID}
            />
          }
        >
          {themes?.map((theme) => (
            <DropdownItem
              key={theme.themeId}
              label={theme.name}
              onClick={() => {
                onShareLink(theme.themeId);
              }}
            />
          ))}
        </Dropdown>
      </Show>
    </Show>
  );
};

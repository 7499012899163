import {use} from 'react';

import {textFieldCtx} from '../textFieldCtx';
import {TextFieldContext} from '../types';

export const useTextFieldContext = (): TextFieldContext => {
  const ctx = use(textFieldCtx);

  return ctx;
};

import {ForwardedRef} from 'react';

export enum HintTypeEnum {
  InfoLicense = 'InfoLicense',
  VinAlreadyExists = 'AlreadyExists',
  VinIsUsedInService = 'VinIsUsedInService',
  CreatingSaleForService = 'CreatingSaleForService',
  SomeResultsCanCreateSale = 'SomeResultsCanCreateSale',
}

export type UseScrollToSectionType = {
  refs: Record<string, ForwardedRef<HTMLDivElement>>;
  triggerScroll: () => void;
};

export type HintType = `${HintTypeEnum}` | null;

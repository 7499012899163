import {
  closeCurrentDialog,
  DataStatus,
  Form,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, HStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {useState} from 'react';

import {always, isEmpty, isNil} from 'ramda';
import {isFalse, isNotNil} from 'ramda-adjunct';

import {
  PatchReservationCustomerRequest,
  PostReservationCustomerRequest,
  useGetCustomerV2Query,
  useGetReservationCustomerQuery,
  useLazyGetCustomerV2Query,
  usePatchReservationCustomerMutation,
  usePostReservationCustomerMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {CustomerWidgetCard, handleApiError, useDuplicateErrorHandler} from '@dms/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {
  ReservationForm,
  reservationFormSchema,
} from '../../../../../constants/reservationFormSchema';
import {getDefaultRequestExpiresAtTime} from '../../../../../utils/getDefaultRequestExpiresAtTime';
import {getRequestExpiresAtDate} from '../../../../../utils/getRequestExpiresAtDate';
import {ReservationFooter} from './ReservationFooter';
import {ReservationInfo} from './ReservationInfo';

interface ReservationCustomerProps extends TestIdProps {
  articleId: string;
  dispensingUnit: number;
  onSubmit: () => void;
  reservationItemId?: string;
  onCancel?: (id: string) => void;
}

export function ReservationCustomer(props: ReservationCustomerProps) {
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [shouldCustomerChange, setShouldCustomerChange] = useState<boolean>(false);
  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  const {
    data: reservation,
    isLoading: isReservationLoading,
    isError: isReservationError,
  } = useGetReservationCustomerQuery(
    {articleId: props.articleId, requestId: props.reservationItemId},
    {skip: isNil(props.reservationItemId)}
  );
  const {
    data: defaultCustomer,
    isLoading: isDefaultCustomerLoading,
    isError: isDefaultCustomerError,
  } = useGetCustomerV2Query(
    {customerId: reservation?.customerId ?? ''},
    {skip: isNil(reservation?.customerId)}
  );
  const [getSelectedCustomer, {data: selectedCustomer}] = useLazyGetCustomerV2Query();
  const [postReservation] = usePostReservationCustomerMutation();
  const [patchReservation] = usePatchReservationCustomerMutation();

  const isLoading = isReservationLoading || isDefaultCustomerLoading;
  const isError = isReservationError || isDefaultCustomerError;

  const customer = match([isNotNil(customerId), shouldCustomerChange])
    .with([true, false], always(selectedCustomer))
    .with([false, true], always(null))
    .otherwise(always(defaultCustomer));

  const isCustomerSelected = match([
    isNil(defaultCustomer),
    isNil(selectedCustomer),
    isNil(customerId),
    shouldCustomerChange,
  ])
    .with([true, true, Pattern.any, Pattern.any], always(false))
    .with([Pattern.any, Pattern.any, true, true], always(false))
    .otherwise(always(true));

  const handleSubmit: FormSubmitHandler<ReservationForm> = async (data) => {
    if (isNotNil(props.reservationItemId)) {
      const reservationData: PatchReservationCustomerRequest['body'] = {
        customerId: customerId ? customerId : (defaultCustomer?.id ?? ''),
        quantity: data.quantity,
        requestExpiresAtDate: getRequestExpiresAtDate(
          data.requestExpiresAtDate,
          data.requestExpiresAtTime
        ),
        note: isEmpty(data.note) ? null : data.note,
      };
      return await patchReservation({
        articleId: props.articleId,
        requestId: props.reservationItemId,
        body: reservationData,
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('entity.warehouse.notifications.reservationUpdated'));
          setCustomerId(null);
        })
        .then(closeCurrentDialog)
        .then(props.onSubmit)
        .catch(handleApiError);
    }

    const reservationData: PostReservationCustomerRequest = {
      articleId: props.articleId,
      customerId: customerId ? customerId : (defaultCustomer?.id ?? ''),
      quantity: data.quantity,
      requestExpiresAtDate: getRequestExpiresAtDate(
        data.requestExpiresAtDate,
        data.requestExpiresAtTime
      ),
      note: isEmpty(data.note) ? null : data.note,
    };
    await postReservation(reservationData)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.notifications.reservationCreated'));
        setCustomerId(null);
      })
      .then(closeCurrentDialog)
      .then(props.onSubmit)
      .catch(duplicateErrorHandler);
  };

  const handleCustomerSelect = (newCustomerId: string) => {
    setCustomerId(newCustomerId);
    getSelectedCustomer({customerId: newCustomerId});
    setShouldCustomerChange(false);
  };

  const handleCustomerChange = () => {
    setShouldCustomerChange(true);
    setCustomerId(null);
  };

  const defaultValues: Partial<ReservationForm> = {
    quantity: reservation?.quantity ?? props.dispensingUnit,
    requestExpiresAtDate: reservation?.requestExpiresAtDate
      ? parseDate(reservation.requestExpiresAtDate)
      : null,
    requestExpiresAtTime: reservation?.requestExpiresAtDate
      ? getDefaultRequestExpiresAtTime(reservation.requestExpiresAtDate)
      : null,
    note: reservation?.note,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<ReservationForm>
        defaultValues={defaultValues}
        experimentalZodSchema={reservationFormSchema(false, props.dispensingUnit)}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <>
            <HStack>
              <Box flex={1}>
                <ReservationInfo
                  formApi={formApi}
                  control={control}
                  reservationItemId={props.reservationItemId}
                  dispensingUnit={props.dispensingUnit}
                  duplicateError={duplicateError}
                  data-testid={suffixTestId('info', props)}
                />
              </Box>
              <Separator orientation="vertical" />
              <Box flex={2}>
                <CustomerWidgetCard
                  customer={customer}
                  secondStepComponentType="BUSINESS_CASE"
                  onCustomer={({id}) => handleCustomerSelect(id)}
                  onChange={handleCustomerChange}
                  isDetailLinkVisible
                  data-testid={suffixTestId('customer', props)}
                />
              </Box>
            </HStack>
            <ReservationFooter
              isSubmitDisabled={isFalse(isCustomerSelected)}
              control={control}
              reservationItemId={props.reservationItemId}
              onCancel={props.onCancel}
              data-testid={suffixTestId('footer', props)}
            />
          </>
        )}
      </Form>
    </DataStatus>
  );
}

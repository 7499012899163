import {use, useMemo, useRef} from 'react';

import {droppableContext} from '../droppableContext';
import {ContextType, DragAndDropContextType, DroppableContext} from '../types';
import {useFullDnDContext} from './useFullDnDContext';

/**
 *	Context hook for Draggable component
 *
 * 	@todo Might use some memoization to get better performance
 */
export function useDnDContext<TDnDData>(draggableId: string): ContextType<TDnDData> {
  // NOTE Since we create context globally, we can't parametrize generic on the creation type properly
  const context = useFullDnDContext<TDnDData>();
  const contextRef = useRef<DragAndDropContextType<TDnDData>>(context);
  const contextDrop = use(droppableContext);
  const {draggedItem, selectedItems, providerId} = context;
  const {droppableId} = contextDrop as DroppableContext;

  contextRef.current = context;

  return useMemo(
    () => ({
      contextState: contextRef,
      droppableId,
      providerId: providerId as string,
      isDragged: draggedItem?.dragId === draggableId,
      globalDragging: context.globalDragging,
      draggedItem,
      selectedItems: {
        count: selectedItems.length,
        isSelected: !!selectedItems.find((i) => i === draggableId),
      },
    }),
    [draggableId, draggedItem, selectedItems, droppableId, providerId]
  );
}

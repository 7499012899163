import {openDialog} from 'platform/components';

import {RepublishInfoDialogContent} from './RepublishInfoDialogContent';

type OpenRepublishInfoDialogType = {
  onCloseComplete?: () => void;
};

export const openRepublishInfoDialog = (data?: OpenRepublishInfoDialogType) => {
  openDialog(<RepublishInfoDialogContent />, {
    id: 'republishDialog',
    'data-testid': 'republishDialog',
    onCloseComplete: data?.onCloseComplete,
  });
};

import {use, createContext} from 'react';

import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';

export type TConditionDamagesContext = {
  onAddDamage: (category: AuditCategoryOfStructure) => void;
  onEditDamage: (category: AuditCategoryOfStructure, damageId: string) => void;
  lastOrderNumber: number;
  setLastOrderNumber: (lastOrderNumber: number) => void;
};

export const ConditionDamagesContext = createContext<TConditionDamagesContext>(
  {} as TConditionDamagesContext
);

export const useConditionDamagesContext = (): TConditionDamagesContext =>
  use(ConditionDamagesContext);

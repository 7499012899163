import {Hide} from 'platform/foundation';

import {useRowSelection} from '../hooks/useRowSelections';
import {ICellRendererParams} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {DefaultRowSelectCheckboxRenderer} from './DefaultRowSelectCheckboxRenderer';

export interface RowSelectCellRendererProps extends ICellRendererParams {
  gridProps: DataGridProps;
}

export function RowSelectCellRenderer({node, api, context}: RowSelectCellRendererProps) {
  const selected = node.isSelected();

  const selectRow = useRowSelection(api, context.behavior.rowSelectMode);
  const selectRowCb = () => {
    selectRow(node, !selected);
    api.refreshCells({force: true, rowNodes: [node]});
  };

  const isRowPinned = node.isRowPinned();

  const handleRowClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    selectRowCb();
  };

  return (
    <div
      onClick={handleRowClick}
      css={{
        display: 'inline-flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Hide when={isRowPinned}>
        <DefaultRowSelectCheckboxRenderer
          selected={selected}
          data-testid={`row-${node.rowIndex}`}
        />
      </Hide>
    </div>
  );
}

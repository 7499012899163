import {
  GetIssueNoteCorrectionRequest,
  GetIssueNoteCorrectionRequestSchema,
  GetIssueNoteCorrectionResponse,
  GetIssueNoteCorrectionResponseSchema,
} from '../types/metadaWarehouseIssueNoteCorrection';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseIssueNoteCorrectionApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getIssueNoteCorrection: build.query<
      GetIssueNoteCorrectionResponse,
      GetIssueNoteCorrectionRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/issue-note-correction/${queryArg.issueNoteCorrectionId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'issueNoteCorrections', id: queryArg.issueNoteCorrectionId},
      ],
      extraOptions: {
        requestSchema: GetIssueNoteCorrectionRequestSchema,
        responseSchema: GetIssueNoteCorrectionResponseSchema,
      },
    }),
  }),
});

export const {useGetIssueNoteCorrectionQuery} = metadaWarehouseIssueNoteCorrectionApi;

import {Module} from '@dms/api';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {warehouseRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {ArticleDetail} from './pages/ArticleDetail/ArticleDetail';
import {ArticleList} from './pages/ArticleList/ArticleList';
import {CostCorrectionDetail} from './pages/CostCorrectionDetail/CostCorrectionDetail';
import {DeliveryNoteDetail} from './pages/DeliveryNoteDetail/DeliveryNoteDetail';
import {DirectSalesCreate} from './pages/DirectSalesCreate/DirectSalesCreate';
import {DirectSalesDetail} from './pages/DirectSalesDetail/DirectSalesDetail';
import {DirectSalesList} from './pages/DirectSalesList/DirectSalesList';
import {DirectSalesVariantChange} from './pages/DirectSalesVariantChange/DirectSalesVariantChange';
import {IssueNoteCorrectionDetail} from './pages/IssueNoteCorrectionDetail/IssueNoteCorrectionDetail';
import {IssueNoteDetail} from './pages/IssueNoteDetail/IssueNoteDetail';
import {PriceList} from './pages/PriceList/PriceList';
import {ReceiveNoteCorrectionDetail} from './pages/ReceiveNoteCorrectionDetail/ReceiveNoteCorrectionDetail';
import {ReceiveNoteDetail} from './pages/ReceiveNoteDetail/ReceiveNoteDetail';
import {ReceiveNoteLists} from './pages/ReceiveNoteList/ReceiveNoteLists';
import {ServiceOrderIssueCreate} from './pages/ServiceOrderIssueCreate/ServiceOrderIssueCreate';
import {ServiceOrderIssueDetail} from './pages/ServiceOrderIssueDetail/ServiceOrderIssueDetail';
import {ServiceOrderIssueList} from './pages/ServiceOrderIssueList/ServiceOrderIssueList';
import {ServiceOrderReturnsCreate} from './pages/ServiceOrderReturnsCreate/ServiceOrderReturnsCreate';
import {ServiceOrderReturnsDetail} from './pages/ServiceOrderReturnsDetail/ServiceOrderReturnsDetail';
import {SupplierOrderDetail} from './pages/SupplierOrderDetail/SupplierOrderDetail';
import {SupplierOrders} from './pages/SupplierOrders/SupplierOrders';

export const WarehouseModule: Module = {
  id: moduleKeys.warehouse,
  layoutProps: {
    icon: 'custom/warehouse',
    iconV2: 'sidebar/warehouse',
    title: 'module.warehouse.title',
  },
  routerProps: {
    path: warehouseRoutes.articleList,
  },
  requiredPermission: permissions.articleRead,
  requiredFeatureFlag: featureFlags.ACL_WAREHOUSE,
  content: [
    {
      id: 'warehouse-articleList',
      routerProps: {
        path: warehouseRoutes.articleList,
        element: <ArticleList />,
      },
      layoutProps: {
        title: 'page.warehouse.label.articleList',
        icon: 'sidebar/warehouse_articles',
      },
    },
    {
      id: 'warehouse-articleDetailOverview',
      routerProps: {
        path: warehouseRoutes.articleDetailOverview,
        element: <ArticleDetail />,
      },
    },
    {
      id: 'warehouse-articleDetailAvailability',
      routerProps: {
        path: warehouseRoutes.articleDetailAvailability,
        element: <ArticleDetail />,
      },
    },
    {
      id: 'warehouse-articleDetailInventories',
      routerProps: {
        path: warehouseRoutes.articleDetailInventories,
        element: <ArticleDetail />,
      },
    },
    {
      id: 'warehouse-articleDetailMovements',
      routerProps: {
        path: warehouseRoutes.articleDetailMovements,
        element: <ArticleDetail />,
      },
    },
    {
      id: 'warehouse-articleDetailReservations',
      routerProps: {
        path: warehouseRoutes.articleDetailReservations,
        element: <ArticleDetail />,
      },
    },
    {
      id: 'warehouse-articleDetailSupplierArticles',
      routerProps: {
        path: warehouseRoutes.articleDetailSupplierArticles,
        element: <ArticleDetail />,
      },
    },
    {
      id: 'warehouse-serviceOrderIssues',
      routerProps: {
        path: warehouseRoutes.serviceOrderIssues,
        element: <ServiceOrderIssueList />,
      },
      layoutProps: {
        title: 'page.warehouse.labels.serviceOrderIssues',
        icon: 'sidebar/warehouse_service_order_issues',
      },
    },
    {
      id: 'warehouse-issueNotes',
      routerProps: {
        path: warehouseRoutes.issueNotes,
        element: <ServiceOrderIssueList />,
      },
    },
    {
      id: 'warehouse-serviceOrderIssueCreate',
      routerProps: {
        path: warehouseRoutes.serviceOrderIssueCreate,
        element: <ServiceOrderIssueCreate />,
      },
    },
    {
      id: 'warehouse-serviceOrderIssueDetailOverview',
      routerProps: {
        path: warehouseRoutes.serviceOrderIssueDetailOverview,
        element: <ServiceOrderIssueDetail />,
      },
    },
    {
      id: 'warehouse-serviceOrderIssueDetailIssueNotes',
      routerProps: {
        path: warehouseRoutes.serviceOrderIssueDetailIssueNotes,
        element: <ServiceOrderIssueDetail />,
      },
    },
    {
      id: 'warehouse-serviceOrderIssueDetailDocuments',
      routerProps: {
        path: warehouseRoutes.serviceOrderIssueDetailDocuments,
        element: <ServiceOrderIssueDetail />,
      },
    },
    {
      id: 'warehouse-issueNoteDetailOverview',
      routerProps: {
        path: warehouseRoutes.issueNoteDetailOverview,
        element: <IssueNoteDetail />,
      },
    },
    {
      id: 'warehouse-issueNoteDetailDocuments',
      routerProps: {
        path: warehouseRoutes.issueNoteDetailDocuments,
        element: <IssueNoteDetail />,
      },
    },
    {
      id: 'warehouse-serviceOrderReturns',
      routerProps: {
        path: warehouseRoutes.serviceOrderReturns,
        element: <ServiceOrderIssueList />,
      },
    },
    {
      id: 'warehouse-costCorrections',
      routerProps: {
        path: warehouseRoutes.costCorrections,
        element: <ServiceOrderIssueList />,
      },
    },
    {
      id: 'warehouse-costCorrectionDetailOverview',
      routerProps: {
        path: warehouseRoutes.costCorrectionDetailOverview,
        element: <CostCorrectionDetail />,
      },
    },
    {
      id: 'warehouse-serviceOrderReturnsCreate',
      routerProps: {
        path: warehouseRoutes.serviceOrderReturnsCreate,
        element: <ServiceOrderReturnsCreate />,
      },
    },
    {
      id: 'warehouse-serviceOrderReturnsDetailOverview',
      routerProps: {
        path: warehouseRoutes.serviceOrderReturnsDetailOverview,
        element: <ServiceOrderReturnsDetail />,
      },
    },
    {
      id: 'warehouse-serviceOrderReturnsDetailDocuments',
      routerProps: {
        path: warehouseRoutes.serviceOrderReturnsDetailDocuments,
        element: <ServiceOrderReturnsDetail />,
      },
    },
    {
      id: 'warehouse-issueNoteCorrections',
      routerProps: {
        path: warehouseRoutes.issueNoteCorrections,
        element: <ServiceOrderIssueList />,
      },
    },
    {
      id: 'warehouse-issueNoteCorrectionDetail',
      routerProps: {
        path: warehouseRoutes.issueNoteCorrectionDetailOverview,
        element: <IssueNoteCorrectionDetail />,
      },
    },
    {
      id: 'warehouse-deliveryNoteList',
      routerProps: {
        path: warehouseRoutes.deliveryNoteList,
        element: <ReceiveNoteLists />,
      },
      layoutProps: {
        title: 'page.warehouse.labels.deliveryNotes',
        icon: 'sidebar/warehouse_delivery_notes',
      },
    },
    {
      id: 'warehouse-deliveryNoteDetailOverview',
      routerProps: {
        path: warehouseRoutes.deliveryNoteDetailOverview,
        element: <DeliveryNoteDetail />,
      },
    },
    {
      id: 'warehouse-receiveNoteList',
      routerProps: {
        path: warehouseRoutes.receiveNoteList,
        element: <ReceiveNoteLists />,
      },
      layoutProps: {
        title: 'page.warehouse.labels.receiveNotes',
        icon: 'sidebar/warehouse_receive_notes',
      },
    },
    {
      id: 'warehouse-receiveNoteCorrectionsList',
      routerProps: {
        path: warehouseRoutes.receiveNoteCorrectionsList,
        element: <ReceiveNoteLists />,
      },
      layoutProps: {
        title: 'page.warehouse.labels.receiveNoteCorrectionsList',
        icon: 'sidebar/warehouse_receive_note_corrections',
      },
    },
    {
      id: 'warehouse-receiveNoteCreate',
      routerProps: {
        path: warehouseRoutes.receiveNoteCreate,
        element: <ReceiveNoteDetail />,
      },
    },
    {
      id: 'warehouse-receiveNoteDetailOverview',
      routerProps: {
        path: warehouseRoutes.receiveNoteDetailOverview,
        element: <ReceiveNoteDetail />,
      },
    },
    {
      id: 'warehouse-receiveNoteDetailDocuments',
      routerProps: {
        path: warehouseRoutes.receiveNoteDetailDocuments,
        element: <ReceiveNoteDetail />,
      },
    },
    {
      id: 'warehouse-receiveNoteCorrectionCreate',
      routerProps: {
        path: warehouseRoutes.receiveNoteCorrectionCreate,
        element: <ReceiveNoteCorrectionDetail />,
      },
    },
    {
      id: 'warehouse-receiveNoteCorrectionDetailOverview',
      routerProps: {
        path: warehouseRoutes.receiveNoteCorrectionDetailOverview,
        element: <ReceiveNoteCorrectionDetail />,
      },
    },
    {
      id: 'warehouse-receiveNoteCorrectionDetailDocuments',
      routerProps: {
        path: warehouseRoutes.receiveNoteCorrectionDetailDocuments,
        element: <ReceiveNoteCorrectionDetail />,
      },
    },
    {
      id: 'warehouse-priceList',
      routerProps: {
        path: warehouseRoutes.priceList,
        element: <PriceList />,
      },
      layoutProps: {
        title: 'page.warehouse.labels.priceList',
        icon: 'sidebar/warehouse_price_list',
      },
    },
    {
      id: 'warehouse-directSales',
      routerProps: {
        path: warehouseRoutes.directSalesList,
        element: <DirectSalesList />,
      },
      layoutProps: {
        title: 'page.warehouse.labels.directSales',
        icon: 'sidebar/warehouse_direct_sales',
      },
    },
    {
      id: 'warehouse-directSalesCreate',
      routerProps: {
        path: warehouseRoutes.directSalesCreate,
        element: <DirectSalesCreate />,
      },
    },
    {
      id: 'warehouse-directSalesVariantChange',
      routerProps: {
        path: warehouseRoutes.directSalesVariantChange,
        element: <DirectSalesVariantChange />,
      },
    },
    {
      id: 'warehouse-directSalesDetailOverview',
      routerProps: {
        path: warehouseRoutes.directSalesDetailOverview,
        element: <DirectSalesDetail />,
      },
    },
    {
      id: 'warehouse-directSalesDetailDocuments',
      routerProps: {
        path: warehouseRoutes.directSalesDetailDocuments,
        element: <DirectSalesDetail />,
      },
    },
    {
      id: 'warehouse-directSalesDetailVehicle',
      routerProps: {
        path: warehouseRoutes.directSalesDetailVehicle,
        element: <DirectSalesDetail />,
      },
    },
    {
      id: 'warehouse-directSalesDetailCustomer',
      routerProps: {
        path: warehouseRoutes.directSalesDetailCustomer,
        element: <DirectSalesDetail />,
      },
    },
    {
      id: 'warehouse-supplierOrders',
      routerProps: {
        path: warehouseRoutes.supplierOrders,
        element: <SupplierOrders />,
      },
      layoutProps: {
        title: 'page.warehouse.labels.supplierOrders',
        icon: 'sidebar/warehouse_supplier_orders',
      },
    },
    {
      id: 'warehouse-supplierOrders',
      routerProps: {
        path: warehouseRoutes.purchaseList,
        element: <SupplierOrders />,
      },
    },
    {
      id: 'warehouse-supplierOrders',
      routerProps: {
        path: warehouseRoutes.supplierOrderList,
        element: <SupplierOrders hasNewOrderAction />,
      },
    },
    {
      id: 'warehouse-supplierOrderDetailOverview',
      routerProps: {
        path: warehouseRoutes.supplierOrderDetailOverview,
        element: <SupplierOrderDetail />,
      },
    },
    {
      id: 'warehouse-supplierOrderDetailDocuments',
      routerProps: {
        path: warehouseRoutes.supplierOrderDetailDocuments,
        element: <SupplierOrderDetail />,
      },
    },
  ],
};

import {getFilePreviewUrl, IconButton} from 'platform/components';

import {useLazyGetFileByIdQuery} from '@dms/api';
import {handleApiError} from '@dms/shared';

import {openFile} from 'shared';

interface PreviewFileIconProps {
  fileId: string;
}

export function PreviewFileIcon(props: PreviewFileIconProps) {
  const [getFileById, {isLoading}] = useLazyGetFileByIdQuery();

  const previewFile = () => {
    getFileById({fileId: props.fileId})
      .unwrap()
      .then((file) => {
        const url = getFilePreviewUrl(file.originalUri);
        openFile(url);
      })
      .catch(handleApiError);
  };

  return <IconButton icon="action/visibility" isDisabled={isLoading} onClick={previewFile} />;
}

import {FormControl} from 'platform/components';

import {FieldValues, Path, useWatch} from 'react-hook-form';

import {
  GetSimilarVehiclesV2ApiArg,
  SimilarVehicleResponseBody,
  useGetSimilarVehiclesV2Query,
} from '@dms/api';

import {buildObject, Nullish, useDebouncedValue} from 'shared';

type SimilarVehiclesProps<TFieldValues extends FieldValues> = {
  vinField: {
    name: Path<TFieldValues>;
    label?: string;
  };
  registrationPlateField: {
    name: Path<TFieldValues>;
    label?: string;
  };
  control: FormControl<TFieldValues>;
};

const NUMBER_OF_CHARS_IN_PLATE_TO_TRIGGER = 3;
const NUMBER_OF_CHARS_IN_VIN_TO_TRIGGER = 6;
const NUMBER_OF_CHARS_IN_FULL_VIN = 17;

export const useSimilarVehicles = <TFieldValues extends FieldValues = FieldValues>(
  props: SimilarVehiclesProps<TFieldValues>
) => {
  const [registrationPlate, vin] = useWatch({
    control: props.control,
    name: [props.registrationPlateField.name, props.vinField.name],
  }) as (string | Nullish)[];

  const debouncedRegistrationPlate = useDebouncedValue(registrationPlate, 300);
  const debouncedVin = useDebouncedValue(vin, 300);

  const isVinFull = debouncedVin && debouncedVin.length === NUMBER_OF_CHARS_IN_FULL_VIN;
  const isVinPartial = debouncedVin && debouncedVin.length >= NUMBER_OF_CHARS_IN_VIN_TO_TRIGGER;
  const isRegistrationPlatePartial =
    debouncedRegistrationPlate &&
    debouncedRegistrationPlate.length >= NUMBER_OF_CHARS_IN_PLATE_TO_TRIGGER;

  const getSimilarVehicleArgs = buildObject<GetSimilarVehiclesV2ApiArg>()
    .vin(debouncedVin, isVinFull)
    .vinContains(debouncedVin, isVinPartial && !isVinFull)
    .registrationPlateContains(debouncedRegistrationPlate)
    .build();

  const shouldFetchSimilarVehicles = isVinPartial || isRegistrationPlatePartial;

  const {data, isFetching, isError, isLoading} = useGetSimilarVehiclesV2Query(
    getSimilarVehicleArgs,
    {
      skip: !shouldFetchSimilarVehicles,
    }
  );

  const returnData: SimilarVehicleResponseBody[] = shouldFetchSimilarVehicles
    ? (data?.vehicles ?? [])
    : [];

  const hasExactVinMatchMatch = data?.vehicles.some((item) => item.vin === vin);

  return {data: returnData, isFetching, isError, isLoading, hasExactVinMatchMatch};
};

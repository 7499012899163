import {ICellRendererParams} from 'ag-grid-community';
import {Tooltip} from 'platform/components';
import {Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {parseDate} from 'shared';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getCellClassName} from '../../utils/getCellClassName';
import {getStylesToRender} from '../../utils/getStylesToRender';
import {getUnformattedValue} from '../../utils/getUnformattedValue';

export interface DateCellRendererProps extends ICellRendererParams {
  getTooltipProps?: TooltipGetter;
}

export function DateCellRenderer(props: DateCellRendererProps) {
  const {getTooltipProps, data, node, colDef} = props;
  const className = getCellClassName(colDef);
  const cellValue = getUnformattedValue(props);
  const cellStyles = getStylesToRender(props);
  const formatDateTime = useDateTimeFormatter();

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  if (!cellValue) {
    return null;
  }

  return (
    <Tooltip {...tooltipProps} label={formatDateTime('dateTimeLong', parseDate(cellValue))}>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className={className}>
        <Text
          color={cellStyles.textColor}
          noWrap={!colDef?.wrapText}
          size="small"
          overflowWrap={colDef?.wrapText ? 'anywhere' : undefined}
        >
          {formatDateTime('dateShort', parseDate(cellValue))}
        </Text>
      </div>
    </Tooltip>
  );
}

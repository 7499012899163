import {Button, TextInput, closeCurrentDialog, openDialog} from 'platform/components';
import {HStack, Hide, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useLazyGetTreeFolderPathQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, RequiredTestIdProps, Nullish} from 'shared';

import {handleApiError} from '../../../../utils/handleApiError';
import {ActiveFolder} from '../../types/ActiveFolder';
import {SelectFolder} from '../SelectFolder/SelectFolder';

interface ArticleCreateTreeFolderPathProps extends RequiredTestIdProps {
  leafId: string | Nullish;
  isRequired?: boolean;
  onChange?: (activeFolder: ActiveFolder) => void;
  invalidMessage?: string;
}

export function ArticleCreateTreeFolderPath(props: ArticleCreateTreeFolderPathProps) {
  const [pathName, setPathName] = useState<string | null>(null);
  const [getTreeFolderPath] = useLazyGetTreeFolderPathQuery();

  useEffect(() => {
    if (isNil(props.leafId)) {
      setPathName(null);
    }

    getTreeFolderPath({leafId: props.leafId ?? ''})
      .unwrap()
      .then((data) => setPathName(data.treeFolder?.pathName ?? null))
      .catch(handleApiError);
  }, [getTreeFolderPath, props.leafId]);

  const handleSelect = (activeFolder?: ActiveFolder) => {
    props.onChange?.(activeFolder);
    closeCurrentDialog();
  };

  const handleSelectFolder = () => {
    openDialog(
      <SelectFolder
        onSelect={handleSelect}
        onClose={closeCurrentDialog}
        data-testid={suffixTestId('articleCreateMoveToFolder', props)}
      />,
      {
        size: 'small',
        title: i18n.t('entity.addWork.labels.moveToFolder'),
      }
    );
  };

  return (
    <VStack spacing={2}>
      <TextInput
        isDisabled
        isRequired={props.isRequired}
        isInvalid={isNotNilOrEmpty(props.invalidMessage)}
        errorMessage={props.invalidMessage}
        value={pathName}
        label={i18n.t('general.labels.category')}
        data-testid={suffixTestId('treeFolderPathInput', props)}
      />
      <Hide when={isNil(props.onChange)}>
        <HStack>
          <Button
            variant="link"
            size="small"
            leftIcon="image/edit"
            onClick={handleSelectFolder}
            title={i18n.t('general.labels.editCategory')}
            data-testid={suffixTestId('editCategory', props)}
          />
        </HStack>
      </Hide>
    </VStack>
  );
}

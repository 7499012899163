import {TranslationInterpreter} from 'platform/components';

export const translationMap = (t: TranslationInterpreter) => ({
  // Filters
  selectAll: t(`page.datagrid.labels.selectAll`),
  selectAllSearchResults: t(`page.datagrid.labels.selectAllFoud`),
  searchOoo: t(`page.datagrid.labels.selectFor`),
  noMatches: t(`page.datagrid.labels.noMatch`),
  filterOoo: t(`page.datagrid.labels.filterFor`),
  equals: t(`page.datagrid.labels.equals`),
  empty: t(`page.datagrid.labels.empty`),
  inRange: t(`page.datagrid.labels.inRange`),
  inRangeStart: t(`page.datagrid.labels.rangeStart`),
  inRangeEnd: t(`page.datagrid.labels.rangeEnd`),
  contains: t(`page.datagrid.labels.contains`),
  applyFilter: t(`page.datagrid.labels.apply`),
  resetFilter: t(`page.datagrid.labels.reset`),
  resetAllFilters: t(`page.datagrid.labels.resetAllFilters`),
  // Other
  loadingOoo: t(`page.datagrid.labels.loading`),
  // Menu
  pinColumn: t(`page.datagrid.labels.pinColumn`),
  pinLeft: t(`page.datagrid.labels.pinLeft`),
  pinRight: t(`page.datagrid.labels.pinRight`),
  noPin: t(`page.datagrid.labels.noPin`),
  autosizeThisColumn: t(`page.datagrid.labels.autosizeThisCol`),
  autosizeAllColumns: t(`page.datagrid.labels.autosizeAllCols`),
  resetColumns: t(`page.datagrid.labels.resetCols`),
  cut: t(`page.datagrid.labels.cut`),
  copy: t(`page.datagrid.labels.copy`),
  copyWithHeaders: t(`page.datagrid.labels.copyWithHeaders`),
  copyWithGroupHeaders: t(`page.datagrid.labels.copyWithHeaderGroups`),
  paste: t(`page.datagrid.labels.paste`),
  export: t(`page.datagrid.labels.export`),
  csvExport: t(`page.datagrid.labels.exportCSV`),
  excelExport: t(`page.datagrid.labels.exportXLS`),
  fitToScreen: t(`page.datagrid.labels.fitToScreen`),
  exportToFile: t(`page.datagrid.labels.exportToFile`),
  // Status Bar
  sum: t(`page.datagrid.labels.sum`),
  min: t(`page.datagrid.labels.min`),
  max: t(`page.datagrid.labels.max`),
  none: t(`page.datagrid.labels.none`),
  count: t(`page.datagrid.labels.count`),
  avg: t(`page.datagrid.labels.average`),
  filteredRows: t(`page.datagrid.labels.filtered`),
  selectedRows: t(`page.datagrid.labels.selected`),
  totalRows: t(`page.datagrid.labels.totalRows`),
  totalAndFilteredRows: t(`page.datagrid.labels.rows`),
});

import {Card, DataStatus, Textarea} from 'platform/components';
import {Grid, Text, ThemeColorTextPath, VStack} from 'platform/foundation';
import {useFormatNumber, useFormatPercentage} from 'platform/locale';
import {match} from 'ts-pattern';

import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  SourcingVehicleDetailResponseBody,
  useSourcingGetDefaultUserPresetCostQuery,
  useSourcingGetUserCostVehicleListQuery,
  useSourcingGetUserCostVehicleNoteQuery,
  useSourcingSetUserVehicleCostNoteMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {CostsTable} from './components/CostsTable';

interface CostsTabProps {
  id: string;
  vehicle: SourcingVehicleDetailResponseBody;
}

export function CostsTab({id, vehicle}: CostsTabProps) {
  const formatNumber = useFormatNumber();
  const formatPercentage = useFormatPercentage();

  const [newCostKeys, setNewCostKeys] = useState<number[]>([]);
  const handleAddNewCost = useCallback(() => {
    setNewCostKeys((prev) => [...prev, Date.now()]);
  }, []);
  const handleRemoveNewCost = useCallback((key: number) => {
    setNewCostKeys((prev) => prev.filter((item) => item !== key));
  }, []);

  const [note, setNote] = useState<string | null>(null);
  const {data: savedNote, isLoading: isNoteLoading} = useSourcingGetUserCostVehicleNoteQuery({
    sourcingVehicleId: id,
  });
  const [submitNote] = useSourcingSetUserVehicleCostNoteMutation();
  const handleSubmitNote = () => {
    submitNote({
      sourcingVehicleId: id,
      updateCostVehicleNoteRequestBody: {note: note ?? ''},
    });
  };

  const {data: userPreset, isLoading: isUserPresetLoading} =
    useSourcingGetDefaultUserPresetCostQuery();
  const {data: vehicleCosts, isLoading: isVehicleCostsLoading} =
    useSourcingGetUserCostVehicleListQuery({sourcingVehicleId: id});
  const totalPrice = (vehicleCosts?.length === 0 ? userPreset?.presetItems : vehicleCosts)
    ?.filter(({enabled}) => enabled)
    .reduce((prev, current) => Number(current.cost) + prev, 0);
  const isLoading = isUserPresetLoading || isVehicleCostsLoading;

  useEffect(() => {
    setNote(savedNote?.note ?? null);
  }, [savedNote]);

  const price = vehicle.sourcingVehicle.price?.withVat;
  const sellingPrice = vehicle?.vehicleSummary?.sellingPrice ?? price;
  const margin = useMemo(
    () =>
      (sellingPrice ?? 0) -
      ((vehicle?.vehicleSummary?.purchasePrice ?? 0 ?? vehicle?.summary?.purchase ?? 0) +
        (totalPrice ?? 0)),
    [sellingPrice, totalPrice, vehicle]
  );

  const color = match<number, ThemeColorTextPath>(Math.sign(margin))
    .with(1, () => 'success')
    .with(-1, () => 'danger')
    .otherwise(() => 'primary');

  return (
    <VStack spacing={4}>
      <Grid columns={2} spacing={4}>
        <Card title={i18n.t('general.labels.summary')}>
          <DataStatus isLoading={isLoading}>
            <Grid columns={2} spacing={4}>
              <VStack align="center">
                <Text size="large" alternative>
                  {formatNumber(Math.round(totalPrice ?? 0))}
                </Text>
                <Text>{i18n.t('general.labels.totalCosts')}</Text>
              </VStack>
              <VStack align="center">
                <Text color={color} size="large" alternative>
                  {`${formatNumber(Math.round(margin ?? 0))} (${formatPercentage(margin / (sellingPrice ?? 0), 'nearest')})`}
                </Text>
                <Text>{i18n.t('entity.vehicle.labels.margin')}</Text>
              </VStack>
            </Grid>
          </DataStatus>
        </Card>

        <Card title={i18n.t('general.labels.notes')}>
          <DataStatus isLoading={isNoteLoading}>
            <Textarea
              name="cost"
              rows={2}
              value={note}
              onBlur={handleSubmitNote}
              onChange={setNote}
              data-testid={testIds.sourcing.classifieds('costs-note')}
            />
          </DataStatus>
        </Card>
      </Grid>

      <Card
        title={i18n.t('general.labels.costs')}
        actions={[
          {
            type: 'iconButton',
            onClick: handleAddNewCost,
            isDisabled: isLoading,
            icon: 'content/add',
          },
        ]}
      >
        <CostsTable id={id} newCostKeys={newCostKeys} onRemoveNewCost={handleRemoveNewCost} />
      </Card>
    </VStack>
  );
}

import {
  Attributes,
  AttributesRow,
  Button,
  closeCurrentDialog,
  openDialog,
  Segment,
  Separator,
} from 'platform/components';
import {Box, Heading, HStack, Link, Show, Space, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {AftersalesPaymentMethod, Payment, usePostCheckoutPaymentCancelMutation} from '@dms/api';
import i18n from '@dms/i18n';

import {
  buildArray,
  openFile,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
  useBoolean,
} from 'shared';

import {EMPTY_PLACEHOLDER} from '../../../constants/placeholders';
import {useBank} from '../../../hooks/useBank';
import {useCashRegister} from '../../../hooks/useCashRegister';
import {handleApiError} from '../../../utils/handleApiError';
import {PayDialog} from './PayDialog';
import {PaymentsList} from './PaymentsList';

interface PaymentOverviewProps extends RequiredTestIdProps {
  checkoutId: string;
  payment: Payment;
  disallowedPaymentMethods?: AftersalesPaymentMethod[];
  onAddAnotherDepositClick?: () => Promise<unknown>;
}

export function PaymentOverview(props: PaymentOverviewProps) {
  const [selectedSegment, seSegment] = useState('DETAILS');

  const [isAddLoading, startAddLoading, stopAddLoading] = useBoolean();

  const {getCashRegisterById} = useCashRegister();
  const {composeBankAccount} = useBank();
  const formatCurrency = useFormatCurrency();
  const formatDateTime = useDateTimeFormatter();

  const [postCheckoutPaymentCancel, {isLoading: isCancelingPayment}] =
    usePostCheckoutPaymentCancelMutation();

  const handleCancelPayment = () =>
    postCheckoutPaymentCancel({
      checkoutId: props.checkoutId,
      paymentId: props.payment.checkoutPaymentId,
    })
      .unwrap()
      .catch(handleApiError);

  const paymentMethodLabel = match(props.payment.method)
    .with('BANK_TRANSFER', () => i18n.t('entity.invoice.paymentMethod.bankTransfer'))
    .with('CARD', () => i18n.t('entity.invoice.paymentMethod.card'))
    .with('CASH', () => i18n.t('entity.invoice.paymentMethod.cash'))
    .with('INTERNAL', () => i18n.t('general.labels.internal'))
    .exhaustive();

  const invoices = [
    ...(props.payment.invoices ?? []),
    ...(props.payment.proformaInvoices ?? []),
    ...(props.payment.internalInvoices ?? []),
  ];

  const rows = buildArray<AttributesRow>([
    {
      label: i18n.t('entity.checkout.labels.paymentMethod'),
      value: paymentMethodLabel,
      testId: 'paymentMethod',
    },
    {
      label: i18n.t('entity.checkout.labels.issuedOn'),
      value: props.payment.issuedOn
        ? formatDateTime('dateShort', parseDate(props.payment.issuedOn))
        : EMPTY_PLACEHOLDER,
      testId: 'issuedOn',
    },
    {
      label: i18n.t('entity.checkout.labels.dueDate'),
      value: props.payment.dueDate
        ? formatDateTime('dateShort', parseDate(props.payment.dueDate))
        : EMPTY_PLACEHOLDER,
      testId: 'dueDate',
    },
    {
      label: i18n.t('entity.accounting.labels.dateOfTaxableSupply'),
      value: props.payment.dateOfTaxableSupply
        ? formatDateTime('dateShort', parseDate(props.payment.dateOfTaxableSupply))
        : EMPTY_PLACEHOLDER,
      testId: 'dateOfTaxableSupply',
    },
  ])
    .when(props.payment.method === 'CASH', {
      label: i18n.t('entity.checkout.labels.cashRegister'),
      value: props.payment.cashRegisterId
        ? getCashRegisterById(props.payment.cashRegisterId)?.name
        : EMPTY_PLACEHOLDER,
    })
    .when(props.payment.method === 'BANK_TRANSFER', {
      label: i18n.t('general.labels.bankAccount'),
      value: props.payment.bankAccount
        ? composeBankAccount(props.payment.bankAccount)
        : EMPTY_PLACEHOLDER,
    })
    .add({
      label: i18n.t('general.labels.note'),
      value: props.payment.note,
    });
  rows.push(
    ...(invoices?.map((invoice) => ({
      label: i18n.t('entity.checkout.labels.invoice'),
      content: (
        <HStack justify="space-between">
          <Link size="small" onClick={() => openFile(invoice.fileUrl)} title={invoice.name} />
          <Show when={props.payment.paymentState === 'PENDING'}>
            <Button
              size="small"
              isLoading={isCancelingPayment}
              variant="dangerLink"
              title={i18n.t('entity.invoice.actions.cancelInvoice')}
              onClick={handleCancelPayment}
            />
          </Show>
        </HStack>
      ),
      testId: 'invoice',
    })) ?? [])
  );

  const handleAddAnotherDeposit = () => {
    if (!props.onAddAnotherDepositClick) {
      return;
    }

    startAddLoading();
    props.onAddAnotherDepositClick().finally(stopAddLoading);
  };

  const paymentAmount = props.payment.foreignCurrencyPaymentAmount ?? props.payment.paymentAmount;
  const remainingPaymentAmount =
    props.payment.remainingForeignCurrencyPaymentAmount ?? props.payment.remainingPaymentAmount;

  return (
    <VStack spacing={4} align="flex-start">
      <HStack spacing={20} width="100%">
        <VStack spacing={1}>
          <Text size="xSmall" color="secondary">
            {i18n.t('general.labels.amount')}
          </Text>
          <Heading size={4} data-testid={suffixTestId('amount', props)}>
            {formatCurrency(paymentAmount.amount, paymentAmount.currency, 2)}
          </Heading>
        </VStack>
        <VStack spacing={1}>
          <Text size="xSmall" color="secondary">
            {i18n.t('entity.invoice.labels.remaining')}
          </Text>
          <Heading size={4} data-testid={suffixTestId('remainingAmount', props)} color="danger">
            {formatCurrency(remainingPaymentAmount.amount, remainingPaymentAmount.currency, 2)}
          </Heading>
        </VStack>
      </HStack>
      <Separator />
      <VStack spacing={4} justify="flex-start" width="100%">
        <Box width={75}>
          <Segment
            value={selectedSegment}
            onChange={seSegment}
            options={[
              {label: i18n.t('general.labels.details'), value: 'DETAILS'},
              {label: i18n.t('page.accounting.invoiceDetailPayments.title'), value: 'PAYMENTS'},
            ]}
            data-testid={props['data-testid']}
          />
        </Box>
        <Show when={selectedSegment === 'DETAILS'}>
          <Attributes size="quarter" rows={rows} data-testid={props['data-testid']} />
        </Show>
        <Show when={selectedSegment === 'PAYMENTS'}>
          <PaymentsList
            data-testid={suffixTestId('payments', props)}
            checkoutId={props.checkoutId}
            payment={props.payment}
          />
        </Show>
      </VStack>
      <Show when={props.payment.paymentState === 'PENDING' || props.onAddAnotherDepositClick}>
        <Separator spacing={0} />
        <HStack width="100%">
          {props.onAddAnotherDepositClick && (
            <Button
              variant="link"
              title={i18n.t('entity.checkout.addAnotherPayment')}
              leftIcon="content/add_circle"
              onClick={handleAddAnotherDeposit}
              isDisabled={isAddLoading}
              isLoading={isAddLoading}
            />
          )}
          <Show when={props.payment.paymentState === 'PENDING'}>
            <Space fillAvailable />
            <Button
              title={i18n.t('entity.order.actions.pay')}
              onClick={() =>
                openDialog(
                  <PayDialog
                    checkoutId={props.checkoutId}
                    payment={props.payment}
                    disallowedPaymentMethods={props.disallowedPaymentMethods}
                    onClose={closeCurrentDialog}
                    data-testid={props['data-testid']}
                  />,
                  {
                    title: i18n.t('entity.order.actions.pay'),
                  }
                )
              }
            />
          </Show>
        </HStack>
      </Show>
    </VStack>
  );
}

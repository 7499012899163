import {isFeatureEnabled} from 'feature-flags';
import {
  Action,
  closeCurrentDialog,
  closeDialog,
  openConfirmDialog,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Text} from 'platform/foundation';

import {equals, isNil, isNotNil, not} from 'ramda';
import {isFalse, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  BusinessCaseResponseBody,
  EntityResourceIds,
  SaleVehicleResponseBody,
  useBackToOfferMutation,
  useCloseBusinessCaseMutation,
  useCloseContractMutation,
  useDeleteBusinessCaseMutation,
  useGetBusinessCaseQuery,
  useGetBusinessCaseSettingsQuery,
  useGetCurrentUserInfoQuery,
  useGetParticipationQuery,
  useGetSaleVehicleQuery,
  useGetVehicleQuery,
  useGetVehicleWarehouseManagementSettingsQuery,
  useLazyGetOccupiedSpaceQuery,
  useLazyGetSalesPlatformsQuery,
  useLazyGetVehicleInsuranceQuery,
  useLazyGetVehicleV2Query,
  usePartialUpdateVehicleMutation,
  useReopenContractMutation,
  useSetVehicleInsuranceMutation,
  useUpdateOccupiedSpaceMutation,
  useUpdateVehicleIsInSaleMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {businessCaseRoutes, testIds} from '@dms/routes';
import {
  handleApiError,
  useBusinessCaseActions,
  useLazyGetAuthorizationProfilesAndCKKPermissions,
  usePermissions,
  useVehicleWarehouse,
} from '@dms/shared';

import {buildArray, composePath, useNavigate, useRequiredParams} from 'shared';

import {useInsuranceData} from '../../../hooks/useInsuranceData';
import {getIsMaxBuyingPriceLowerThanBuyingPrice} from '../../../utils/buyingPrice';
import {InsuranceReminder} from '../../InsuranceReminder/InsuranceReminder';
import {BusinessCaseCancellationDialog} from '../components/BusinessCaseCancellationDialog';
import {EndBusinessCaseForm} from '../components/EndBusinessCaseForm';

const REMINDER_DIALOG_ID = 'reminder-dialog';

export function useActions(
  businessCase: BusinessCaseResponseBody | undefined,
  saleVehicle: SaleVehicleResponseBody | undefined
) {
  const navigate = useNavigate();
  const {id: businessCaseId} = useRequiredParams();
  const {isActionEnabled} = useBusinessCaseActions(businessCaseId);
  const insuranceData = useInsuranceData(businessCaseId, true);

  const {refetch: refetchBusinessCase} = useGetBusinessCaseQuery({businessCaseId});
  const {data: userInfo} = useGetCurrentUserInfoQuery();
  const {refetch: refetchSaleVehicle} = useGetSaleVehicleQuery(
    {vehicleId: saleVehicle?.vehicleId ?? ''},
    {skip: isNil(saleVehicle?.vehicleId)}
  );
  const {data: businessCaseSettingsData} = useGetBusinessCaseSettingsQuery();
  const [getSalesPlatforms] = useLazyGetSalesPlatformsQuery();
  const [updateVehicleIsInSale, {isLoading: isUpdatingVehicleInSale}] =
    useUpdateVehicleIsInSaleMutation();
  const [getVehicleParkingSpace] = useLazyGetOccupiedSpaceQuery();
  const [closeBusinessCase] = useCloseBusinessCaseMutation();
  const [partialUpdateVehicle] = usePartialUpdateVehicleMutation();
  const [getVehicleV2] = useLazyGetVehicleV2Query();
  const [deleteBusinessCase, {isLoading: isDeletingBusinessCase}] = useDeleteBusinessCaseMutation();
  const [closeContract, {isLoading: isLoadingClosingContract}] = useCloseContractMutation();
  const [reopenContract, {isLoading: isLoadingReopeningContract}] = useReopenContractMutation();
  const [updateVehicleParkingSpace] = useUpdateOccupiedSpaceMutation();
  const [moveBackToOffer, {isLoading: isLoadingBackToOffer}] = useBackToOfferMutation();
  const [getVehicleInsurance] = useLazyGetVehicleInsuranceQuery();
  const [setVehicleInsurance] = useSetVehicleInsuranceMutation();
  const {handleSearchAuthorizationProfiles} = useLazyGetAuthorizationProfilesAndCKKPermissions();
  const {data: vehicleData} = useGetVehicleQuery(
    {vehicleId: saleVehicle?.vehicleId ?? ''},
    {skip: isNilOrEmpty(saleVehicle?.vehicleId)}
  );

  const {data: warehouseManagmentData} = useGetVehicleWarehouseManagementSettingsQuery();

  const hasWarehouseManagementEnabled = warehouseManagmentData?.isEnabled;

  const {
    isVehicleWarehouseEnabled,
    availableActions: availableVehicleMovementActions,
    openStockInDialog,
    openStockOutDialog,
  } = useVehicleWarehouse({
    vehicleId: saleVehicle?.vehicleId ?? '',
    vehicleWarehouseId: saleVehicle?.vehicleWarehouse?.id,
    saleVehicleId: saleVehicle?.id,
  });

  const price = businessCase?.offers?.[0]?.purchaseVehicles?.[0]?.buying;
  const maxBuyingPrice = price?.maxBuyingPrice?.amount;
  const hasPurchaseVehicle = isNotNil(businessCase?.offers?.[0]?.purchaseVehicles?.[0]);
  const shouldValidateMaximumBuyingPrice =
    businessCaseSettingsData?.maximalPurchasePriceRequired &&
    businessCase?.businessCaseInternalType === 'BUYING' &&
    hasPurchaseVehicle;

  const isMaxBuyingPriceMissing = shouldValidateMaximumBuyingPrice && isNil(maxBuyingPrice);
  const isMaxBuyingPriceLowerThanBuyingPrice =
    shouldValidateMaximumBuyingPrice && getIsMaxBuyingPriceLowerThanBuyingPrice(price);
  const isMaxBuyingPriceIncorrect = isMaxBuyingPriceMissing || isMaxBuyingPriceLowerThanBuyingPrice;

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: businessCase?.id ?? '',
    },
    {skip: isNil(businessCase?.id)}
  );

  const [
    canCancelPurchaseBrokerage,
    canClosePurchaseContract,
    canCloseSellingContract,
    canCancelPurchaseContract,
    canCancelSellingContract,
    canClosePurchase,
    canCloseSelling,
    canReopenPurchase,
    canReopenSelling,
    canIssueFromVehicleWarehouse,
    canReceiveToVehicleWarehouse,
  ] = usePermissions({
    permissionKeys: [
      'cancelBusinessCasePurchaseBrokerage',
      'closePurchaseContract',
      'closeSellingContract',
      'cancelPurchaseContract',
      'cancelSellingContract',
      'closePurchase',
      'closeSelling',
      'reopenPurchase',
      'reopenSelling',
      'vehicleIssueFromVehicleWarehouse',
      'vehicleReceiveToVehicleWarehouse',
    ],
    scopes: {
      cancelBusinessCasePurchaseBrokerage: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      closePurchaseContract: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      closeSellingContract: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      cancelPurchaseContract: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      cancelSellingContract: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      closePurchase: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      closeSelling: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      reopenPurchase: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      reopenSelling: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
    },
  });

  const isInsuranceEnabled = isFeatureEnabled(featureFlags.SALES_INSURANCE_COMPARISON_V1);

  const isClosePurchaseContractButtonDisabled =
    businessCase?.businessCaseType === 'BUYING' ||
    businessCase?.businessCaseType === 'PURCHASE_BROKERAGE'
      ? not(canClosePurchaseContract)
      : not(canCloseSellingContract);

  const isCancelPurchaseContractButtonDisabled =
    businessCase?.businessCaseType === 'BUYING' ||
    businessCase?.businessCaseType === 'PURCHASE_BROKERAGE'
      ? not(canCancelPurchaseContract)
      : not(canCancelSellingContract);

  const isClosePurchaseButtonDisabled =
    businessCase?.businessCaseType === 'BUYING' ||
    businessCase?.businessCaseType === 'PURCHASE_BROKERAGE'
      ? not(canClosePurchase)
      : not(canCloseSelling);

  const isReopenPurchaseButtonDisabled =
    businessCase?.businessCaseType === 'BUYING' ||
    businessCase?.businessCaseType === 'PURCHASE_BROKERAGE'
      ? not(canReopenPurchase)
      : not(canReopenSelling);

  const endBusinessCase = () =>
    openDialog(<EndBusinessCaseForm businessCase={businessCase} />, {
      scrollBehavior: 'outside',
      title: i18n.t('entity.order.labels.endCase'),
    });

  const unPublishVehicle = async () => {
    if (
      (businessCase?.businessCaseType !== 'SELLING' &&
        businessCase?.businessCaseInternalType !== 'PURCHASE_BROKERAGE') ||
      isNil(saleVehicle?.id)
    ) {
      return;
    }

    const hasAdvertisements = await getSalesPlatforms({vehicleId: saleVehicle.vehicleId})
      .unwrap()
      .then((advertisements) =>
        advertisements.some((ad) => ad.enabled && ad.status === 'published')
      )
      .catch(handleApiError);

    if (!hasAdvertisements) {
      return;
    }

    openConfirmDialog({
      text: i18n.t('page.businessCase.labels.unPublishAdvertisementsQuestion'),
      onConfirm: () =>
        updateVehicleIsInSale({vehicleId: saleVehicle.vehicleId, publish: false})
          .unwrap()
          .then(() => {
            showNotification.success(
              i18n.t('page.businessCase.labels.unPublishingAdvertisementsStarted')
            );
          })
          .catch(handleApiError),
    });
  };

  const parkingSpaceAndKeyCleanUp = () => {
    if (
      (businessCase?.businessCaseType !== 'SELLING' &&
        businessCase?.businessCaseInternalType !== 'PURCHASE_BROKERAGE') ||
      isNil(saleVehicle)
    ) {
      return;
    }

    getVehicleV2({vehicleId: saleVehicle.vehicleId})
      .unwrap()
      .then(({keyLocation}) => {
        const hasKeyLocation = isNotNilOrEmpty(keyLocation);

        getVehicleParkingSpace({recordId: saleVehicle.vehicleId, spaceType: 'VEHICLE'})
          .unwrap()
          .then(({prefix, number}) => {
            let hasParkingSpace = false;
            let parkingSpaceName = '';

            if (isNotNil(prefix) && isNotNil(number)) {
              hasParkingSpace = true;
              parkingSpaceName = `${prefix}${number}`;
            }

            if (!hasParkingSpace && !hasKeyLocation) {
              showContinueToBrokeragePurchaseModal();
              return;
            }

            openConfirmDialog({
              text: i18n.t('page.businessCase.labels.freeUpParkingSpace', {
                space: parkingSpaceName,
              }),
              onConfirm: () => {
                Promise.all([
                  hasKeyLocation &&
                    partialUpdateVehicle({
                      vehicleId: saleVehicle.vehicleId,
                      patchVehicleRequestBody: {
                        keyLocation: null,
                      },
                    })
                      .unwrap()
                      .catch(handleApiError),
                  hasParkingSpace &&
                    updateVehicleParkingSpace({
                      recordId: saleVehicle.vehicleId,
                      spaceType: 'VEHICLE',
                      updateOccupiedSpaceRequestBody: {space: null},
                    })
                      .unwrap()
                      .catch(handleApiError),
                ]).then(() => {
                  showNotification.success();
                });
                showContinueToBrokeragePurchaseModal();
              },
            });
          })
          .catch(handleApiError);
      })
      .catch(handleApiError);
  };

  const handleBrokerageCancellation = () => {
    if (
      equals(businessCase?.businessCaseInternalType, 'PURCHASE_BROKERAGE') &&
      isFeatureEnabled(featureFlags.SALES_BROKERAGE_CONTRACT_CANCELLATION)
    ) {
      openDialog(
        <BusinessCaseCancellationDialog
          businessCaseId={businessCaseId}
          customerId={businessCase?.customerId}
          onCancel={() => {
            unPublishVehicle();
            refetchBusinessCase();
            parkingSpaceAndKeyCleanUp();
          }}
        />,
        {
          title: i18n.t('general.labels.endCase'),
          scrollBehavior: 'outside',
        }
      );

      return;
    }

    openConfirmDialog({
      text: i18n.t('page.businessCase.labels.confirmEndUnsuccessfulBrokerage'),
      onConfirm: endBusinessCase,
    });
  };

  const handleBackToOffer = () => {
    if (isNil(businessCase)) {
      return;
    }

    openConfirmDialog({
      text: i18n.t('page.businessCase.labels.confirmBackToOffer'),
      onConfirm: () => {
        moveBackToOffer({businessCaseId}).unwrap().catch(handleApiError);
      },
    });
  };

  const showContinueToBrokeragePurchaseModal = () => {
    if (
      !businessCase?.brokerageBusinessCaseId ||
      businessCase?.businessCaseInternalType !== 'SELLING'
    ) {
      return;
    }

    openConfirmDialog({
      text: i18n.t('page.businessCase.labels.continueToBrokeragePurchase'),
      onConfirm: () =>
        navigate(
          composePath(businessCaseRoutes.checkout, {
            params: {id: businessCase?.brokerageBusinessCaseId},
          })
        ),
    });
  };

  const handleCloseContract = () => {
    if (isNil(businessCase)) {
      return;
    }

    openConfirmDialog({
      text: i18n.t('page.businessCase.labels.didCustomerSignContract'),
      'data-testid': testIds.businessCase.detail('didCustomerSignContract'),
      onConfirm: () => {
        closeContract({businessCaseId}).unwrap().catch(handleApiError);
        handleSearchAuthorizationProfiles(
          {
            vehicleMake: vehicleData?.make ?? '',
            vehicleType: vehicleData?.type ?? null,
            vehicleModelFamily: vehicleData?.vehicle?.modelFamily ?? null,
            vehicleModelFamilyGroup: vehicleData?.vehicle?.modelFamilyGroup ?? null,
          },
          businessCase?.customerId,
          {bcNumber: businessCase?.code, bcCreatedAt: businessCase?.createdAt}
        );
      },
    });
  };

  const promptCancelFleetInsurance = () =>
    new Promise<void>((resolve) => {
      if (!saleVehicle?.vehicleId) {
        resolve();
        return;
      }

      getVehicleInsurance({vehicleId: saleVehicle.vehicleId})
        .unwrap()
        .then((insurance) => {
          if (isNil(insurance.fleetInsurance)) {
            resolve();
            return;
          }

          openConfirmDialog({
            text: i18n.t('entity.vehicleInsurance.modal.cancelFleetInsuranceDescription'),
            onConfirm: () =>
              setVehicleInsurance({
                vehicleId: saleVehicle.vehicleId,
                data: {customerInsurance: null, fleetInsurance: null},
              })
                .unwrap()
                .then(() => {
                  showNotification.success(
                    i18n.t('entity.vehicleInsurance.notifications.fleetInsuranceCanceled')
                  );
                })
                .catch(handleApiError),
            onCloseComplete: resolve,
          });
        })
        .catch(resolve);
    });

  const promptVehicleStockMovement = () => {
    if (!isVehicleWarehouseEnabled) {
      return;
    }

    if (
      isNotNilOrEmpty(saleVehicle?.vehicleId) &&
      businessCase?.businessCaseInternalType === 'BUYING' &&
      availableVehicleMovementActions.receive &&
      canReceiveToVehicleWarehouse &&
      hasWarehouseManagementEnabled
    ) {
      openConfirmDialog({
        text: i18n.t('entity.vehicleWarehouse.modal.stockInDescription'),
        onConfirm: openStockInDialog,
      });

      return;
    }

    if (
      isNotNilOrEmpty(saleVehicle?.vehicleId) &&
      isNotNil(saleVehicle?.vehicleWarehouse?.id) &&
      businessCase?.businessCaseType === 'SELLING' &&
      availableVehicleMovementActions.issue &&
      canIssueFromVehicleWarehouse
    ) {
      openConfirmDialog({
        text: i18n.t('entity.vehicleWarehouse.modal.stockOutDescription'),
        onConfirm: openStockOutDialog,
      });
    }
  };

  const closeCase = () => {
    if (!businessCaseId) {
      return;
    }

    const shouldShowCancelFleetInsurance = businessCase?.businessCaseInternalType === 'SELLING';

    closeBusinessCase({businessCaseId})
      .unwrap()
      .then(() => (shouldShowCancelFleetInsurance ? promptCancelFleetInsurance() : {}))
      .then(promptVehicleStockMovement)
      .then(parkingSpaceAndKeyCleanUp)
      .then(unPublishVehicle)
      .catch(handleApiError);
    handleSearchAuthorizationProfiles(
      {
        vehicleMake: vehicleData?.make ?? '',
        vehicleType: vehicleData?.type ?? null,
        vehicleModelFamily: vehicleData?.vehicle?.modelFamily ?? null,
        vehicleModelFamilyGroup: vehicleData?.vehicle?.modelFamilyGroup ?? null,
      },
      businessCase?.customerId
    );
  };

  const confirmCloseCase = () => {
    openConfirmDialog({
      'data-testid': testIds.businessCase.detail('confirmCloseCaseBuying'),
      text:
        businessCase?.businessCaseType === 'BUYING'
          ? i18n.t('page.businessCase.labels.confirmCloseCaseBuying')
          : i18n.t('page.businessCase.labels.confirmCloseCase'),
      onConfirm: async () => {
        if (
          businessCase?.actions?.isPurchaseBusinessCaseOpen &&
          businessCase?.businessCaseInternalType === 'SELLING'
        ) {
          openDialog(<Text>{i18n.t('page.businessCase.labels.purchaseBcHaveToBeClosed')}</Text>, {
            size: 'small',
            buttons: [
              {
                title: i18n.t('general.actions.cancel'),
                onClick: closeCurrentDialog,
                variant: 'secondary',
              },
            ],
          });

          return;
        }

        await closeCase();
      },
      id: 'confirm',
    });
  };

  const handleCloseCase = () => {
    if (not(isInsuranceEnabled)) {
      return confirmCloseCase();
    }
    if (insuranceData.isError) {
      return confirmCloseCase();
    }

    if (
      !businessCase ||
      (businessCase.businessCaseType !== 'SELLING' && businessCase.businessCaseType !== 'SWAP')
    ) {
      return confirmCloseCase();
    }

    const isFinished = businessCase?.insuranceOffered || businessCase?.customerRecommended;
    if (isFinished || isNilOrEmpty(userInfo?.settings.insurerNumber)) {
      return confirmCloseCase();
    }

    const onInsuranceFinished = () => {
      confirmCloseCase();
      refetchSaleVehicle();
      closeDialog(REMINDER_DIALOG_ID);
    };

    isInsuranceEnabled &&
      businessCaseId &&
      openDialog(
        <InsuranceReminder
          businessCaseId={businessCaseId}
          onDone={onInsuranceFinished}
          onCancel={onInsuranceFinished}
        />,
        {
          id: REMINDER_DIALOG_ID,
          title: i18n.t('entity.insurance.labels.carInsurance'),
        }
      );
  };

  const handleDelete = () => {
    if (!businessCaseId) {
      return;
    }

    openDeleteDialog({
      text: i18n.t('page.businessCase.labels.confirmDelete'),
      onConfirm: () =>
        deleteBusinessCase({businessCaseId})
          .unwrap()
          .then(() => navigate(businessCaseRoutes.home))
          .catch(handleApiError),
    });
  };

  const handleReopen = () => {
    if (!businessCaseId) {
      return;
    }

    openConfirmDialog({
      text: i18n.t('page.businessCase.labels.confirmReopen'),
      'data-testid': testIds.businessCase.detail('reopen-business-case'),
      onConfirm: () => reopenContract({businessCaseId}).unwrap().catch(handleApiError),
    });
  };

  const actions = buildArray<Action>()
    .when(isActionEnabled('END_PURCHASE_BROKERAGE') && canCancelPurchaseBrokerage, {
      title: i18n.t('page.businessCase.actions.brokerageCancellation'), // Close unsuccessful - cancellation ("storno")
      onClick: handleBrokerageCancellation,
      type: 'button',
      variant: 'secondary',
      'data-testid': testIds.businessCase.detail('brokerageCancellation'),
    })
    .when(isActionEnabled('END'), {
      title: i18n.t('page.businessCase.actions.end'),
      onClick: endBusinessCase,
      isLoading: isUpdatingVehicleInSale,
      isDisabled: isFalse(businessCase?.canBeEnded) || isClosePurchaseButtonDisabled,
      // TODO: tooltip for disabled button cuz of perms
      //  https://carvago.atlassian.net/browse/T20-48223
      type: 'button',
      variant: 'secondary',
      'data-testid': testIds.businessCase.detail('end'),
    })
    .when(isActionEnabled('BACK_TO_OFFER'), {
      title: i18n.t('page.businessCase.actions.backToOffer'),
      onClick: handleBackToOffer,
      isLoading: isLoadingBackToOffer,
      isDisabled: isCancelPurchaseContractButtonDisabled,
      // TODO: tooltip for disabled button cuz of perms
      //  https://carvago.atlassian.net/browse/T20-48223
      type: 'button',
      variant: 'secondary',
      'data-testid': testIds.businessCase.detail('backToOffer'),
    })
    .when(isActionEnabled('CLOSE_CONTRACT'), {
      title: i18n.t('page.businessCase.actions.closeContract'),
      onClick: handleCloseContract,
      isDisabled:
        isLoadingClosingContract ||
        isClosePurchaseContractButtonDisabled ||
        isMaxBuyingPriceIncorrect,
      // TODO: tooltip for disabled button cuz of perms
      //  https://carvago.atlassian.net/browse/T20-48223
      type: 'button',
      variant: 'primary',
      'data-testid': testIds.businessCase.detail('closeContract'),
    })
    .when(isActionEnabled('CLOSE'), {
      title: i18n.t('page.businessCase.actions.closeCase'),
      onClick: handleCloseCase,
      isDisabled: isClosePurchaseButtonDisabled || isMaxBuyingPriceIncorrect,
      // TODO: tooltip for disabled button cuz of perms
      //  https://carvago.atlassian.net/browse/T20-48223
      type: 'button',
      variant: 'primary',
      'data-testid': testIds.businessCase.detail('closeCase'),
    })
    .when(isActionEnabled('DELETE'), {
      title: i18n.t('general.actions.delete'),
      onClick: handleDelete,
      isDisabled: isDeletingBusinessCase,
      type: 'button',
      variant: 'secondary',
      'data-testid': testIds.businessCase.detail('delete'),
    })
    .when(isActionEnabled('REOPEN'), {
      title: i18n.t('page.businessCase.actions.reopen'),
      onClick: handleReopen,
      isDisabled: isLoadingReopeningContract || isReopenPurchaseButtonDisabled,
      // TODO: tooltip for disabled button cuz of perms
      //  https://carvago.atlassian.net/browse/T20-48223
      type: 'button',
      variant: 'secondary',
      'data-testid': testIds.businessCase.detail('reopen'),
    });

  return [actions];
}

import {Action, DataStatus, Form, showNotification} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {indexBy, isNil, reject} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  PaymentInfoResponseBody,
  useGetInvoicePaymentListQuery,
  useGetInvoiceV4Query,
  useSendInvoiceMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError, useCurrencies} from '@dms/shared';

import {buildArray, useRequiredParams} from 'shared';

import {InvoiceDetailHeader} from '../../components/InvoiceDetailHeader';
import {InvoiceDetailSubHeader} from '../../components/InvoiceDetailSubHeader';
import {PaymentsListDetail} from '../../components/PaymentsListDetail';
import {SectionsWithCenteredContent} from '../../components/SectionsWithCenteredContent';
import {invoicePaymentOptions} from '../../constants';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {useDocumentActions} from '../../hooks/useDocumentActions';
import {AccountingDocumentFormValues} from '../../types';
import {getDocumentSourceFlags} from '../../utils/getDocumentSourceFlags';
import {getInvoiceCategoryFlagProps} from '../../utils/getInvoiceCategoryFlagProps';
import {getInvoiceStateFlagProps} from '../../utils/getInvoiceStateFlagProps';
import {getPaymentStateFlagProps} from '../../utils/getPaymentStateFlagProps';
import {InvoiceDetails} from './InvoiceDetails';

export function InvoiceDetail() {
  const {id: invoiceId} = useRequiredParams();

  const {getCurrencyName} = useCurrencies();
  const [sendInvoice, {isLoading: isSendingDocument}] = useSendInvoiceMutation();
  const [subheaderActions] = useDocumentActions('ISSUED', invoiceId);

  const {
    data: invoice,
    isLoading: isLoadingInvoice,
    isError: isInvoiceError,
  } = useGetInvoiceV4Query({invoiceId});

  const {
    data: paymentList,
    isLoading: isLoadingPaymentList,
    isError: isPaymentListError,
  } = useGetInvoicePaymentListQuery({invoiceId});

  const bankAccountValue = isNotNilOrEmpty(invoice?.paymentInfo?.bankAccount)
    ? reject(isNil, [
        invoice?.paymentInfo?.bankAccount,
        invoice?.paymentInfo?.bankName,
        invoice?.currency,
      ]).join(', ')
    : null;

  const shouldDisplaySendButton =
    isNil(invoice?.sentAt) && isNil(invoice?.cancelledAt) && invoice?.paymentState !== 'canceled';
  const paymentTypesByType = indexBy((item) => item.value, invoicePaymentOptions);
  const paymentMethodLabel = invoice
    ? (paymentTypesByType[invoice.paymentInfo.paymentMethod]
        .label as PaymentInfoResponseBody['paymentMethod'])
    : 'BANK_TRANSFER';

  const initialValues = invoice
    ? {
        ...invoice,
        paymentInfo: {
          ...invoice.paymentInfo,
          bankAccount: bankAccountValue,
          paymentMethod: paymentMethodLabel,
        },
        exchangeRateRatio: {
          ratio: invoice.exchangeRateRatio?.ratio?.toString() ?? null,
          amount: invoice.exchangeRateRatio?.amount?.toString() ?? null,
          currency: getCurrencyName(invoice.exchangeRateRatio?.currency),
          type: i18n.t('general.actions.recalculate'),
        },
      }
    : undefined;

  const isLoading = isLoadingInvoice || isLoadingPaymentList;
  const isError = isInvoiceError || isPaymentListError;

  const handleSendInvoice = () => {
    if (isNil(invoice)) {
      showNotification.error();
      return;
    }

    sendInvoice({invoiceId: invoice.id})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const invoiceHeaderActions = buildArray<Action>().when(shouldDisplaySendButton, {
    type: 'button',
    title: i18n.t('general.actions.send'),
    onClick: handleSendInvoice,
    isLoading: isSendingDocument,
    buttonType: 'submit',
  });

  const pageTitle = i18n.t('entity.invoice.labels.invoiceNumber', {
    invoiceNumber: invoice?.number,
  });

  return (
    <Box backgroundColor="palettes.neutral.20.100" minHeight="100%">
      <DataStatus isLoading={isLoading} isError={isError} minHeight="80vh">
        <Helmet title={pageTitle} />

        {isNotNil(invoice) && (
          <Form<AccountingDocumentFormValues> defaultValues={initialValues}>
            {(control) => (
              <InvoiceVatRateProvider control={control} listItemType="invoicing-documents">
                <VStack>
                  <Box backgroundColor="general.white">
                    <InvoiceDetailHeader
                      title={pageTitle}
                      actions={invoiceHeaderActions}
                      data-testid={testIds.accounting.invoiceDetail('header')}
                    />

                    <InvoiceDetailSubHeader
                      author={invoice.author}
                      actions={subheaderActions}
                      createdAt={invoice.createdAt}
                      data-testid={testIds.accounting.invoiceDetail('subHeader')}
                      flags={reject(isNil, [
                        getInvoiceStateFlagProps(invoice),
                        getPaymentStateFlagProps(invoice.paymentState),
                        getInvoiceCategoryFlagProps(invoice.invoiceCategory),
                      ])}
                      subFlags={getDocumentSourceFlags(invoice?.documentSource)}
                    />
                  </Box>

                  <SectionsWithCenteredContent
                    data-testid="accounting-sections"
                    sections={[
                      {
                        content: <InvoiceDetails control={control} invoice={invoice!} />,
                        id: 'detail',
                        label: i18n.t('general.labels.details'),
                      },
                      {
                        content: (
                          <PaymentsListDetail
                            paymentItems={paymentList}
                            invoiceType="invoice"
                            data-testid={testIds.accounting.invoiceDetail('paymentList')}
                          />
                        ),
                        id: 'payments',
                        label: i18n.t('page.accounting.invoiceDetailPayments.title'),
                      },
                    ]}
                  />
                </VStack>
              </InvoiceVatRateProvider>
            )}
          </Form>
        )}
      </DataStatus>
    </Box>
  );
}

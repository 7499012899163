import {Module} from '@dms/api';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {inspectionRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {Inspection} from './pages/Inspection/Inspection';

export const InspectionModule: Module = {
  id: moduleKeys.inspection,
  layoutProps: {
    title: 'module.inspection.title',
    icon: 'action/youtube_searched_for',
    iconV2: 'sidebar/inspection',
  },
  routerProps: {
    path: inspectionRoutes.inspection,
  },
  requiredPermission: permissions.readInspection,
  requiredFeatureFlag: featureFlags.INSPECTION_MODULE,
  content: [
    {
      id: 'inspection-module-page',
      routerProps: {
        path: inspectionRoutes.inspection,
        element: <Inspection />,
      },
      layoutProps: {
        title: 'module.inspection.title',
        icon: 'sidebar/inspection',
      },
    },
  ],
};

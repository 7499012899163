import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Nullish} from 'shared';

import {GeneralSettingsFormValues} from '../types/GeneralSettingsFormValues';

interface ChangeDigitalCertificateThemeDynamicallyProps {
  formApi: UseFormReturn<GeneralSettingsFormValues>;
  passedDigitalCertificateTheme: string | Nullish;
}

export function ChangeDigitalCertificateThemeDynamically(
  props: ChangeDigitalCertificateThemeDynamicallyProps
) {
  const digitalCertificateTheme = props.formApi.watch('digitalCertificateTheme');

  useEffect(() => {
    if (props.passedDigitalCertificateTheme !== digitalCertificateTheme) {
      props.formApi.setValue(
        'digitalCertificateTheme',
        props.passedDigitalCertificateTheme ?? null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.passedDigitalCertificateTheme]);

  return null;
}

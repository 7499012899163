import {useSubscription} from '@apollo/client';
import {isFeatureEnabled} from 'feature-flags';
import {Alert, DataStatus, ProgressBar, showNotification, useDialog} from 'platform/components';
import {Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {
  always,
  assoc,
  count,
  filter,
  find,
  head,
  includes,
  indexBy,
  isNil,
  isNotEmpty,
  last,
  pluck,
  reject,
  slice,
  sortBy,
} from 'ramda';
import {compact, isFalse, isNotArray, isNotNil, isPositive} from 'ramda-adjunct';

import {
  SEND_NOTIFICATION_SUBSCRIPTION,
  VehicleAlbum,
  VehicleAlbums,
  VehiclePhotoResponseBody,
  useAddPromoPhotosToVehicleMutation,
  useFileOperationAcknowledgeMutation,
  useGetPromoPhotoForVehicleQuery,
  useGetVehiclePhotosQuery,
  useGetVehicleQuery,
  useGetWatermarkQuery,
  useLazyGetSentBackgroundNotificationQuery,
  useLazyGetSentToastNotificationQuery,
  useRemovePromoPhotoInVehicleMutation,
  useResetPositionsInVehicleMutation,
  useUpdatePositionsInVehicleMutation,
  vehicleApi,
  useGetParticipationQuery,
  EntityResourceIds,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import {i18n} from '@dms/i18n';
import {testIds} from '@dms/routes';
import {notificationTypes, handleApiError, usePermissions} from '@dms/shared';
import {
  DefaultMultiSelectComponent,
  DragAndDropProvider,
  DragAndDropProviderProps,
  ImageType,
  ImagesCardConnected,
  UploadedFile,
  addPhotosToAlbumRequest,
  copyPhotosToAlbumRequest,
  deletePhotosRequest,
  filterSortImageByWeight,
  loadCarDetailsVehicleDetailRequest,
  movePhotosRequest,
  pickSelectedPhotos,
  selectPhotosSelectionActiveAlbum,
  setPhotoAsCover,
  toggleImageSelection,
  toggleMultiSelectAlbum,
  useThunkDispatch,
} from '@dms/teas';

import {useToggle} from 'shared';

import {BannerBgRemoval} from '../../../../../components/BannerBgRemoval/BannerBgRemoval';
import {getPromoPhotosPositions} from '../utils/getPromoPhotosPositions';
import {WatermarkPreview} from './WatermarkPreview';

type ImageTypeWithPosition = ImageType & {position?: number};

interface VehiclePhotosProps {
  setClassifiedPhotosHasChanged?: () => void;
  vehicleId: string;
}

const getVehiclePhotoNameAlbumById = (albums: VehicleAlbum[], albumId: string): string =>
  albums.find((album) => album.id === albumId)?.name ?? '';

const CLASSIFIEDS_ALBUM_NAME = VehicleAlbums.PHOTOS_CAR_SALES;
const POSITION_MULTIPLIER = 100;

export const VehiclePhotos: FC<VehiclePhotosProps> = (props) => {
  const dispatch = useThunkDispatch();
  const {data: vehicle, isLoading} = useGetVehicleQuery({vehicleId: props.vehicleId});

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const [hasRemoveVehiclePhotoBackgroundPermission] = usePermissions({
    permissionKeys: ['removeVehiclePhotoBackground'],
    scopes: {
      removeVehiclePhotoBackground: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  const selectionActiveAlbum = useSelector(selectPhotosSelectionActiveAlbum);
  const selectedPhotos = useSelector(pickSelectedPhotos);
  const {data: watermark} = useGetWatermarkQuery();
  const [updatePositionsInVehicle] = useUpdatePositionsInVehicleMutation();
  const [removePromoPhotoInVehicle] = useRemovePromoPhotoInVehicleMutation();
  const [addPromoPhotosToVehicle] = useAddPromoPhotosToVehicleMutation();
  const [resetPositionsInVehicle] = useResetPositionsInVehicleMutation();
  const [acknowledgeFileOperation, {isLoading: isAcknowledgeLoading}] =
    useFileOperationAcknowledgeMutation();
  const {data: vehiclePhotos, refetch: refetchVehiclePhotos} = useGetVehiclePhotosQuery({
    vehicleId: vehicle?.id ?? '',
  });
  const {data: vehiclePromoPhotos, isLoading: isPromoPhotosLoading} =
    useGetPromoPhotoForVehicleQuery({
      vehicleId: vehicle?.id ?? '',
    });

  const [getBackgroundNotification] = useLazyGetSentBackgroundNotificationQuery();
  const [getToastNotification] = useLazyGetSentToastNotificationQuery();

  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data: subscription}) => {
      const toastNotificationId: string | null =
        subscription.data?.onSendNotification?.toastNotificationId;
      const backgroundNotificationId: string | null =
        subscription.data?.onSendNotification?.backgroundNotificationId;

      if (isNotNil(toastNotificationId)) {
        getToastNotification({id: toastNotificationId})
          .unwrap()
          .then((notification) => {
            if (notification.type === notificationTypes.VEHICLE_BACKGROUND_REMOVAL_FINISHED) {
              // not RTK Query, ignoring
              // eslint-disable-next-line no-restricted-syntax
              dispatch(loadCarDetailsVehicleDetailRequest({vehicleId: vehicle?.id ?? ''})).then(
                () => {
                  refetchVehiclePhotos();
                }
              );
            }
          })
          .catch(handleApiError);
      }

      if (isNotNil(backgroundNotificationId)) {
        getBackgroundNotification({id: backgroundNotificationId})
          .unwrap()
          .then((notification) => {
            // eslint thinks we should use unwrap() here
            // eslint-disable-next-line no-restricted-syntax
            const notificationText = match<string, string | undefined>(notification.type)
              .with(
                notificationTypes.VEHICLE_PHOTO_ROTATE_FINISHED,
                always(i18n.t('entity.photo.notifications.rotationSuccess'))
              )
              .with(
                notificationTypes.VEHICLE_PHOTO_COPY_FINISHED,
                always(i18n.t('entity.photo.notifications.copySuccess'))
              )
              .otherwise(always(undefined));

            if (isNotNil(notificationText)) {
              showNotification.success(notificationText);
              refetchVehiclePhotos();
            }
          })
          .then(() =>
            dispatch(
              vehicleApi.util.invalidateTags([
                {type: 'SaleVehicle', id: vehicle?.id ?? ''},
                {type: 'Vehicle', id: props.vehicleId},
                {type: 'VehiclePhotos', id: vehicle?.id ?? ''},
              ])
            )
          )
          .catch(handleApiError);
      }
    },
  });

  const [isWatermarkOpen, openWatermark, closeWatermark, {storage: selectedWatermark}] =
    useDialog<string>();

  const [isAcknowledgeAlertAllowed, toggleAcknowledgeAlert] = useToggle(true);

  const hasPromoPhotos = (albumId?: string) =>
    find((album) => album.id === albumId, vehicle?.albums ?? [])?.name ===
    VehicleAlbums.PHOTOS_CAR_SALES;

  const vehiclePhotoById = indexBy((item) => item.id, vehiclePhotos ?? []);

  const extendPhotosWithFileOperation = (photos: ImageType[]) =>
    photos.map((photo) => ({
      ...photo,
      latestFileOperation: vehiclePhotoById[photo.id]?.latestFileOperation,
    }));

  const findAndSortPhotos = (id: string): ImageType[] => {
    const sortedPhotos = filterSortImageByWeight(vehiclePhotos ?? [], id);

    if (hasPromoPhotos(id)) {
      const sortedPromoPhotos = sortBy((photo) => photo.position, vehiclePromoPhotos ?? []);
      sortedPromoPhotos.forEach((promoPhoto) => {
        sortedPhotos.splice(promoPhoto.position - 1, 0, assoc('isPromoPhoto', true, promoPhoto));
      });
    }

    return extendPhotosWithFileOperation(sortedPhotos);
  };

  const getDifferentPosition = (id: string) => {
    const withPromoPhoto = hasPromoPhotos(id);

    if (!withPromoPhoto) {
      return false;
    }

    return !!vehiclePromoPhotos?.some((photo) => photo.position !== photo.positionInSettings);
  };

  const handleSetDefaultOrder = () => {
    resetPositionsInVehicle({
      resetPositionsInVehicleRequestBody: {vehicleId: vehicle?.id ?? ''},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleAddPromoPhotosToVehicle = () => {
    addPromoPhotosToVehicle({
      addToVehicleRequestBody: {vehicleId: vehicle?.id ?? ''},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handlePhotosDeletion = (photos: ImageType[]) => {
    const regularPhotosToRemove = filter((photo) => !photo.isPromoPhoto, photos);
    const promoPhotosToRemove = filter((photo) => Boolean(photo.isPromoPhoto), photos);

    if (regularPhotosToRemove.length) {
      dispatch(
        deletePhotosRequest({
          vehicleId: vehicle?.id ?? '',
          albumId: regularPhotosToRemove[0].albumId ?? '',
          photos: pluck('id', regularPhotosToRemove),
        })
      );
      dispatch(toggleMultiSelectAlbum({id: null, reset: true}));
    }
    if (promoPhotosToRemove.length) {
      promoPhotosToRemove.forEach((promoPhoto) => {
        removePromoPhotoInVehicle({
          vehicleId: vehicle?.id ?? '',
          removeInVehicleRequestBody: {vehiclePromoPhotoId: promoPhoto.id},
        })
          .unwrap()
          .catch(handleApiError);
      });
    }
    dispatch(
      vehicleApi.util.invalidateTags([
        {type: 'SaleVehicle', id: vehicle?.id ?? ''},
        {type: 'Vehicle', id: props.vehicleId},
      ])
    );
  };

  const handlePhotosCopy = async (photos: ImageType[], album: VehicleAlbum) => {
    await dispatch(
      copyPhotosToAlbumRequest({
        vehicleId: vehicle?.id ?? '',
        photos: photos?.map((i) => i.id),
        sourceAlbum: photos[0].albumId ?? '',
        destinationAlbum: album.id,
        position: null,
      })
    );
    dispatch(
      vehicleApi.util.invalidateTags([
        {type: 'SaleVehicle', id: vehicle?.id ?? ''},
        {type: 'SaleVehicleActions', id: vehicle?.id ?? ''},
        {type: 'Vehicle', id: props.vehicleId},
        {type: 'VehiclePhotos', id: vehicle?.id ?? ''},
      ])
    );
    dispatch(toggleMultiSelectAlbum({id: null, reset: true}));
    refetchVehiclePhotos();

    if (album.name === CLASSIFIEDS_ALBUM_NAME) {
      props.setClassifiedPhotosHasChanged?.();
    }
  };

  const handleFileUpload = async (files: UploadedFile[], album?: VehicleAlbum) => {
    if (isNil(album?.id) || !album) {
      return;
    } // !album for later TS check only

    const fileIds = compact(pluck('fileId', files));
    if (fileIds.length) {
      await dispatch(addPhotosToAlbumRequest({albumId: album.id, fileIds}));
      dispatch(
        vehicleApi.util.invalidateTags([
          {type: 'SaleVehicle', id: vehicle?.id ?? ''},
          {type: 'VehiclePhotos', id: vehicle?.id ?? ''},
          {type: 'VehicleAlbumPhotos', id: album.id},
          {type: 'Vehicle', id: props.vehicleId},
        ])
      );
    }

    if (album.name === CLASSIFIEDS_ALBUM_NAME) {
      props.setClassifiedPhotosHasChanged?.();
    }
  };

  const handleMovePhotos = (photos: ImageType[], album: VehicleAlbum) => {
    const sourceAlbumId = head(photos)?.albumId ?? '';
    const sourceAlbumName = getVehiclePhotoNameAlbumById(vehicle?.albums ?? [], sourceAlbumId);

    dispatch(
      movePhotosRequest({
        vehicleId: vehicle?.id ?? '',
        photos: photos?.map((p) => p.id),
        position: null,
        sourceAlbum: sourceAlbumId,
        destinationAlbum: album.id,
      })
    );
    dispatch(toggleMultiSelectAlbum({id: null, reset: true}));

    if (includes(CLASSIFIEDS_ALBUM_NAME, [album.name, sourceAlbumName])) {
      props.setClassifiedPhotosHasChanged?.();
    }
  };

  const handleSetAsCover = (album: VehicleAlbum, photoId: string) => {
    dispatch(setPhotoAsCover({albumId: album.id, photoId}));

    if (album.name === CLASSIFIEDS_ALBUM_NAME) {
      props.setClassifiedPhotosHasChanged?.();
    }
  };

  const handleDragEnd: DragAndDropProviderProps<ImageType>['onDragEnd'] = (im, index, drop) => {
    const photos = im.map((i: Record<string, unknown>) => i.data as ImageTypeWithPosition);

    const regularPhotos = filter((photo) => !photo.isPromoPhoto, photos);

    if (hasPromoPhotos(drop?.droppableId)) {
      const albumPhotos = findAndSortPhotos(drop?.droppableId ?? '');

      const promoPhotoPositions = getPromoPhotosPositions(albumPhotos, photos, index);

      if (promoPhotoPositions.length) {
        updatePositionsInVehicle({
          vehicleId: vehicle?.id ?? '',
          updatePositionsInVehicleRequestBody: {positions: promoPhotoPositions},
        })
          .unwrap()
          .catch(handleApiError);
      }
    }
    if (regularPhotos.length) {
      const sourceAlbumId = head(regularPhotos)?.albumId ?? '';
      const sourceAlbumName = getVehiclePhotoNameAlbumById(vehicle?.albums ?? [], sourceAlbumId);
      const destinationAlbumId = drop?.droppableId ?? '';
      const destinationAlbumName = getVehiclePhotoNameAlbumById(
        vehicle?.albums ?? [],
        destinationAlbumId
      );

      const albumPhotos = findAndSortPhotos(sourceAlbumId);

      const getPosition = () => {
        if (isNil(index)) {
          return albumPhotos.length * POSITION_MULTIPLIER;
        }
        if (isNil(drop) || isNil(drop.data.weight)) {
          const lastValidItemWeight =
            last(slice(0, index, albumPhotos).filter(({weight}) => isNotNil(weight)))?.weight ?? 0;
          return lastValidItemWeight + POSITION_MULTIPLIER;
        }
        if (sourceAlbumId === destinationAlbumId) {
          const offset = count((photo) => (photo.weight ?? 0) < (drop.data.weight ?? 0), photos);
          return drop.data.weight - POSITION_MULTIPLIER * offset;
        }

        return drop.data.weight;
      };

      dispatch(
        movePhotosRequest({
          vehicleId: vehicle?.id ?? '',
          photos: pluck('id', regularPhotos),
          position: getPosition(),
          sourceAlbum: sourceAlbumId,
          destinationAlbum: destinationAlbumId,
        })
      );

      if (includes(CLASSIFIEDS_ALBUM_NAME, [sourceAlbumName, destinationAlbumName])) {
        props.setClassifiedPhotosHasChanged?.();
      }
    }
    dispatch(toggleMultiSelectAlbum({id: null, reset: true}));
  };

  const handleOpenWatermark = (image: ImageType) => {
    openWatermark(image.id);
  };

  const onAcknowledgeOperationClick = (photos: VehiclePhotoResponseBody[]) => {
    const fileOperationIds = reject(
      isNil,
      photos.map((photo) => photo.latestFileOperation?.id)
    );

    if (isNotArray(fileOperationIds)) {
      return;
    }

    acknowledgeFileOperation({
      body: {
        fileOperationIds,
      },
    })
      .unwrap()
      .then(toggleAcknowledgeAlert)
      .then(() => {
        // both are RTK Query, but we don't want to invalidate vehicleApi tag inside universal fileStorageApi
        dispatch(vehicleApi.util.invalidateTags([{type: 'VehiclePhotos', id: vehicle?.id}]));
      })
      .catch(handleApiError);
  };

  const filteredAlbums = filter(
    (album) => album.name !== VehicleAlbums.PHOTOS_SERVICE_CASE,
    vehicle?.albums ?? []
  );

  const isBackgroundRemovalInProgress = isPositive(
    filter(
      (photo) =>
        photo.latestFileOperation?.state === 'IN_PROGRESS' &&
        photo.latestFileOperation?.type === 'REMOVE_BACKGROUND' &&
        isFalse(photo.latestFileOperation?.acknowledged),
      vehiclePhotos ?? []
    ).length
  );

  const photosWithFailedRemoveBackgroundOperation = filter(
    (photo) =>
      photo.latestFileOperation?.state === 'FAILED' &&
      photo.latestFileOperation?.type === 'REMOVE_BACKGROUND' &&
      isFalse(photo.latestFileOperation?.acknowledged),
    vehiclePhotos ?? []
  );

  return (
    <>
      <DragAndDropProvider<ImageType>
        data-testid={testIds.vehicles.photos('dnd')}
        selectedItems={selectedPhotos || []}
        multipleSelectionComponent={DefaultMultiSelectComponent}
        onDragStart={(draggable) => {
          const found = vehicle?.photos?.find((i) => draggable === i.id);

          if (found && selectionActiveAlbum === found.albumId) {
            dispatch(toggleMultiSelectAlbum({id: found.albumId}));
          }
        }}
        onDragEnd={handleDragEnd}
      >
        <DataStatus isLoading={isLoading || isPromoPhotosLoading}>
          <VStack spacing={4}>
            <Show
              when={
                (isFeatureEnabled(featureFlags.SALES_BACKGROUND_REMOVAL) ||
                  hasRemoveVehiclePhotoBackgroundPermission) &&
                isFeatureEnabled(featureFlags.SALES_BGR_BANNER)
              }
            >
              <BannerBgRemoval />
            </Show>
            <Show when={isBackgroundRemovalInProgress}>
              <VStack>
                <Alert
                  variant="info"
                  title={i18n.t('entity.photo.notifications.backgroundRemovalInProgress')}
                  message={i18n.t('entity.photo.notifications.backgroundRemovalCouldLast')}
                  data-testid={testIds.vehicles.photos(`bgRemovalInProgress-alert`)}
                />
                <ProgressBar indeterminate />
              </VStack>
            </Show>
            <Show
              when={
                isAcknowledgeAlertAllowed && isNotEmpty(photosWithFailedRemoveBackgroundOperation)
              }
            >
              <Alert
                variant="error"
                title={i18n.t('entity.photo.notifications.backgroundRemovalFailed')}
                data-testid={testIds.vehicles.photos(`bgRemovalFailed-alert`)}
                hyperlinks={[
                  {
                    title: isAcknowledgeLoading
                      ? i18n.t('general.labels.loading')
                      : i18n.t('general.actions.acknowledge'),
                    onClick: () =>
                      onAcknowledgeOperationClick(photosWithFailedRemoveBackgroundOperation),
                    size: 'small',
                    isDisabled: isAcknowledgeLoading,
                  },
                ]}
              />
            </Show>

            {filteredAlbums?.map((album) => {
              const testIdGalerryType = album.name === 'PHOTOS_CAR_PURCHASE' ? 'purchase' : 'sales';
              return (
                <ImagesCardConnected
                  data-testid={testIds.vehicles.photos(`${testIdGalerryType}-imagesCard`)}
                  onCopy={handlePhotosCopy}
                  onDelete={handlePhotosDeletion}
                  onUpload={handleFileUpload}
                  onMove={handleMovePhotos}
                  onSetAsCover={handleSetAsCover}
                  albums={filteredAlbums?.filter((vAlbum) => vAlbum.id !== album.id) || []}
                  key={album.id}
                  droppableId={album.id}
                  selectionEnabled={selectionActiveAlbum === album.id}
                  disableDrag={(selectionActiveAlbum && selectionActiveAlbum !== album.id) || false}
                  onSelectionToggle={() => {
                    if (selectionActiveAlbum === album.id) {
                      dispatch(
                        toggleMultiSelectAlbum({
                          id: album.id,
                          reset: true,
                        })
                      );
                    } else {
                      dispatch(
                        toggleMultiSelectAlbum({
                          id: album.id,
                          reset: false,
                        })
                      );
                    }
                  }}
                  onImageSelect={(id: string) => dispatch(toggleImageSelection(id))}
                  images={findAndSortPhotos(album.id)}
                  selectedImageIds={selectedPhotos}
                  cardProps={{title: album.translation ?? album.name}}
                  album={album}
                  vehicleId={vehicle?.id}
                  promoPhotos={album.name === VehicleAlbums.PHOTOS_CAR_SALES}
                  onAddPromoPhotos={handleAddPromoPhotosToVehicle}
                  onSetDefaultOrder={handleSetDefaultOrder}
                  differentPosition={getDifferentPosition(album.id)}
                  withWatermark={
                    !!watermark?.fileId && album.name === VehicleAlbums.PHOTOS_CAR_SALES
                  }
                  watermarkOnlyForFirstPhoto={!!watermark?.addOnlyForFirstPhoto}
                  onWatermarkClick={handleOpenWatermark}
                />
              );
            })}
          </VStack>
        </DataStatus>
      </DragAndDropProvider>

      <WatermarkPreview
        isOpen={isWatermarkOpen}
        onClose={closeWatermark}
        photoId={selectedWatermark ?? ''}
      />
    </>
  );
};

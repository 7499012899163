import {Action, Card, useDialog} from 'platform/components';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {isNotNil, mergeAll} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  BulkContextItem,
  documentContextApi,
  useGetBusinessCaseQuery,
  useGetCustomerV2Query,
} from '@dms/api';
import {useGetDocumentDatagridActions} from '@dms/document-context';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {Main, UploadDocumentsDialog} from '@dms/shared';

import {buildArray, useRequiredParams} from 'shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {getPurchaseVehicleIdFromBusinessCase} from './utils/getPurchaseVehicleIdFromBusinessCase';
import {getSaleVehicleIdFromBusinessCase} from './utils/getSaleVehicleIdFromBusinessCase';

export function BusinessCaseDocuments() {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});

  const {data: customer} = useGetCustomerV2Query(
    {customerId: businessCase?.customerId ?? ''},
    {skip: isNilOrEmpty(businessCase?.customerId ?? '')}
  );
  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const {id} = useRequiredParams();

  const dispatch = useDispatch();

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const onDocumentCreated = () => {
    refreshDataGrid();

    dispatch(documentContextApi.util.invalidateTags([{type: 'documentsCount', id}]));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {businessCaseId}]),
    [businessCaseId]
  );

  const [actions, openBulkSignatureStepper] = useGetDocumentDatagridActions({
    refreshData: refreshDataGrid,
    customerId: customer?.id,
    contextTarget: 'business-case',
    contextId: businessCaseId,
  });

  const saleVehicleId = getSaleVehicleIdFromBusinessCase(businessCase);
  const purchaseVehicleId = getPurchaseVehicleIdFromBusinessCase(businessCase);

  return (
    <Main>
      <Card
        title={i18n.t('page.businessCase.documents.title')}
        actions={buildArray<Action>([
          {
            type: 'button',
            variant: 'link',
            leftIcon: 'action/backup',
            title: i18n.t('entity.document.actions.uploadDocument'),
            onClick: openUploadDialog,
          },
        ]).whenFeatureEnabled(featureFlags.DIGITAL_SIGNATURE, {
          type: 'button',
          variant: 'link',
          leftIcon: 'editor/border_color',
          title: i18n.t('entity.document.actions.signMultipleDocument'),
          onClick: openBulkSignatureStepper,
        })}
      >
        <Box minHeight={40}>
          <DataGrid
            data-testid="businessCaseDocumentsDataGrid"
            ref={dataGridRef}
            gridCode="document-context-list-business-case"
            emptyState={{
              headline: i18n.t('page.businessCase.notifications.noDocuments'),
              subheadline: i18n.t('page.businessCase.notifications.documentListWillAppearHere'),
            }}
            actionCallback={actions}
            autoHeight
            queryModifier={queryModifier}
          />
        </Box>
      </Card>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={onDocumentCreated}
        contextTarget="business-case"
        contextId={businessCaseId}
        bulkContext={buildArray<BulkContextItem>([
          {
            target: 'business-case',
            targetId: businessCaseId,
          },
        ]).when(isNotNil(saleVehicleId) || isNotNil(purchaseVehicleId), {
          target: 'vehicle',
          targetId: saleVehicleId ?? purchaseVehicleId ?? '',
        })}
      />
    </Main>
  );
}

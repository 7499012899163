import type {PermissionsRecordType} from '../permissions';

export const vehiclePermissions = {
  vehicleRead: {
    actionId: 'READ',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  vehicleStockInDate: {
    actionId: 'SALE_VEHICLE_EDIT_STOCK_IN_DATE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  deleteVehicle: {
    actionId: 'DELETE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleCustomFields: {
    actionId: 'CUSTOM_FIELDS_PAYLOAD_UPDATE',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  // ACL_EDIT_VEHICLE
  updateVehicle: {
    actionId: 'UPDATE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  setCustomIdVehicle: {
    actionId: 'SET_CUSTOM_ID',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  // ACL_CREATE_SALE_VEHICLE
  vehiclePutUpToBuy: {
    actionId: 'PUT_UP_TO_BUY',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  vehiclePutUpForSale: {
    actionId: 'PUT_UP_FOR_SALE',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  // ACL_CHANGE_VEHICLE_BRANCH
  vehicleChangeBranch: {
    actionId: 'CHANGE_BRANCH',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION'],
  },
  // ACL_CHANGE_OWNER
  vehicleChangeOwner: {
    actionId: 'PARTICIPATION_TRANSFER_OWNERSHIP_TO_USER_COMMAND',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  // ACL_CHANGE_ASSIGNEE
  vehicleUnassignUser: {
    actionId: 'PARTICIPATION_UNASSIGN_USER_COMMAND',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  // ACL_CHANGE_ASSIGNEE
  vehicleAssignUser: {
    actionId: 'PARTICIPATION_ASSIGN_USER_COMMAND',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  // ACL_CHANGE_KEY_NUMBER
  vehicleChangeKeyNumber: {
    actionId: 'SET_KEY_LOCATION',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION'],
  },
  // ACL_EDIT_SALE_VEHICLE
  vehicleEnableLeasingAndCreditCompany: {
    actionId: 'ENABLE_LEASING_AND_CREDIT_COMPANY',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleDisableLeasingAndCreditCompany: {
    actionId: 'DISABLE_LEASING_AND_CREDIT_COMPANY',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleSetLeasingAndCreditCompany: {
    actionId: 'SET_LEASING_AND_CREDIT_COMPANY',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleSetFleetInsurance: {
    actionId: 'SET_FLEET_INSURANCE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleUpdateStorageSpace: {
    actionId: 'SET_CUSTOMER_INSURANCE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  // ACL_CHANGE_PARKING_PLACE
  vehicleChangeParkingPlace: {
    actionId: 'UPDATE_STORAGE_SPACE',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  // EOL
  vehicleCommentRead: {
    actionId: 'COMMENT_READ',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleCommentAdd: {
    actionId: 'COMMENT_ADD',
    resourceId: 'VEHICLE',
    scopes: ['BRANCH', 'PARTICIPATION'],
  },
  vehicleCommentDelete: {
    actionId: 'COMMENT_DELETE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleTagAssign: {
    actionId: 'RECORD_TAG_VALUES_ADD_TAG_VALUE_TO_RECORD',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleTagUnassign: {
    actionId: 'RECORD_TAG_VALUES_REMOVE_TAG_VALUE_FROM_RECORD',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleEditPublishedDate: {
    actionId: 'SALES_EDIT_PUBLISHED_DATE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleTagRead: {
    actionId: 'RECORD_TAG_VALUES_READ_RECORD_TAG_VALUES',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleReadCosts: {
    actionId: 'READ_COSTS',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleCreateCost: {
    actionId: 'ADD_COST',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleEditCost: {
    actionId: 'EDIT_COST',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleApplyPresetCost: {
    actionId: 'APPLY_COST_PRESETS',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleDeleteCost: {
    actionId: 'DELETE_COST',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleAddEarning: {
    actionId: 'ADD_EARNING',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleEditEarning: {
    actionId: 'EDIT_EARNING',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleDeleteEarning: {
    actionId: 'DELETE_EARNING',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleReadEarnings: {
    actionId: 'READ_EARNINGS',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleReadActiveReservation: {
    actionId: 'READ_ACTIVE_RESERVATION',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleReadReservationHistory: {
    actionId: 'READ_RESERVATION_HISTORY',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleReserve: {
    actionId: 'RESERVE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleUpdateReservation: {
    actionId: 'UPDATE_RESERVATION',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleCancelReservation: {
    actionId: 'CANCEL_RESERVATION',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleAddReservationDeposit: {
    actionId: 'ADD_RESERVATION_DEPOSIT',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleCancelReservationDeposit: {
    actionId: 'CANCEL_RESERVATION_DEPOSIT',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleRefundReservationDeposit: {
    actionId: 'REFUND_RESERVATION_DEPOSIT',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleIssueFromVehicleWarehouse: {
    actionId: 'ISSUE_FROM_VEHICLE_WAREHOUSE',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  vehicleReceiveToVehicleWarehouse: {
    actionId: 'RECEIVE_TO_VEHICLE_WAREHOUSE',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  vehicleCancelReceivalWarehouse: {
    actionId: 'CANCEL_RECEIVAL',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  vehicleCancelIssuingWarehouse: {
    actionId: 'CANCEL_ISSUING',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  vehicleReadWarehouseInformation: {
    actionId: 'READ_WAREHOUSE_INFORMATION',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  vehicleEditSalePrice: {
    actionId: 'EDIT_SALE_PRICE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleEditPurchasePrice: {
    actionId: 'EDIT_PURCHASE_PRICE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleReregisterForSale: {
    actionId: 'SALE_VEHICLE_RE_REGISTER_FOR_SALE',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  vehicleProfit: {
    fieldId: 'VEHICLE.PROFIT',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleSellingPrice: {
    fieldId: 'VEHICLE.SELLING_PRICE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleRealSellingPrice: {
    fieldId: 'VEHICLE.REAL_SELLING_PRICE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehiclePurchasePrice: {
    fieldId: 'VEHICLE.PURCHASE_PRICE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleTagCreate: {
    actionId: 'ADD_TAG_VALUE',
    resourceId: 'VEHICLE_TAG',
    scopes: [],
  },
  vehicleTagDelete: {
    actionId: 'REMOVE_TAG_VALUE',
    resourceId: 'VEHICLE_TAG',
    scopes: [],
  },
  vehicleTagUpdate: {
    actionId: 'UPDATE_TAG_VALUE',
    resourceId: 'VEHICLE_TAG',
    scopes: [],
  },
  removeVehiclePhotoBackground: {
    actionId: 'REMOVE_PHOTOS_BACKGROUND',
    resourceId: 'VEHICLE_PHOTO_ALBUM',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  rotateVehiclePhoto: {
    actionId: 'ROTATE_PHOTOS',
    resourceId: 'VEHICLE_PHOTO_ALBUM',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  moveVehiclePhoto: {
    actionId: 'MOVE_PHOTO',
    resourceId: 'VEHICLE_PHOTO_ALBUM',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  createVehicleAlbumPhotos: {
    actionId: 'CREATE_PHOTOS',
    resourceId: 'VEHICLE_PHOTO_ALBUM',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  readVehicleAlbumPhotos: {
    actionId: 'READ',
    resourceId: 'VEHICLE_PHOTO_ALBUM',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  deleteVehicleAlbumPhoto: {
    actionId: 'DELETE_PHOTO',
    resourceId: 'VEHICLE_PHOTO_ALBUM',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehiclePreviewPhoto: {
    fieldId: 'VEHICLE.PREVIEW_PHOTO',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  vehicleIsPhotographed: {
    fieldId: 'VEHICLE.IS_PHOTOGRAPHED',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  createVehiclePriceReport: {
    actionId: 'CREATE_VEHICLE_PRICE_REPORT',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  readAdvertisingSettings: {
    actionId: 'SALES_READ_ADVERTISING_SETTINGS',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  enablePlatformForVehicle: {
    actionId: 'SALES_ENABLE_PLATFORM_FOR_VEHICLE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  disablePlatformForVehicle: {
    actionId: 'SALES_DISABLE_PLATFORM_FOR_VEHICLE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  enableVehicleInAdvertisement: {
    actionId: 'SALES_ENABLE_VEHICLE_IN_ADVERTISEMENT',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  disableVehicleInAdvertisement: {
    actionId: 'SALES_DISABLE_VEHICLE_IN_ADVERTISEMENT',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  editGeneralSettings: {
    actionId: 'SALES_EDIT_GENERAL_SETTINGS',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  editPlatformSettings: {
    actionId: 'SALES_EDIT_PLATFORM_SETTINGS',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  editWindshieldSettings: {
    actionId: 'SALES_EDIT_WINDSHIELD_SETTINGS',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  repeatLastPublishingAction: {
    actionId: 'SALES_REPEAT_LAST_PUBLISHING_ACTION',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  updateVehicleOnAllPlatform: {
    actionId: 'SALES_UPDATE_VEHICLE_ON_ALL_PLATFORMS',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  updateVehiclePlatform: {
    actionId: 'SALES_UPDATE_VEHICLE_PLATFORM',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  generateWindshieldPaper: {
    actionId: 'SALES_GENERATE_WINDSHIELD_PAPER',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  updateFeature: {
    actionId: 'UPDATE_FEATURE',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  editHighlights: {
    actionId: 'EDIT_HIGHLIGHTS',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  editShortcomings: {
    actionId: 'EDIT_SHORTCOMINGS',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  // ACL_VEHICLE_READ_WAREHOUSE_INFORMATION
  vehicleWarehouseReadInformation: {
    actionId: 'READ_WAREHOUSE_INFORMATION',
    resourceId: 'VEHICLE',
    scopes: [],
  },
  vehicleReadTotalGrossMargin: {
    fieldId: 'VEHICLE.TOTAL_GROSS_MARGIN',
    resourceId: 'VEHICLE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
} satisfies PermissionsRecordType;

import {Tooltip} from 'platform/components';
import {Box, Image} from 'platform/foundation';

import {useState, forwardRef, Ref, useImperativeHandle} from 'react';

import {pipe, defaultTo} from 'ramda';
import {floor} from 'ramda-adjunct';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRenderer, ICellRendererParams} from '../../types/AgGridTypes';

export interface ThumbnailCellRendererProps extends ICellRendererParams {}

const DEFAULT_ROW_HEIGHT = 30;

function ThumbnailCellRendererComponent(
  {getValue, value, node, data}: ThumbnailCellRendererProps,
  ref: Ref<ICellRenderer>
) {
  const [imageSrc, setImageSrc] = useState<string>(getValue ? getValue() : value);
  const [rowHeight, setRowHeight] = useState<number>(getRowHeight(node.rowHeight));

  useImperativeHandle(ref, () => ({
    refresh: ({getValue: getNewValue, value: newValue, node: nodeParam}) => {
      const newCellValue = getNewValue ? getNewValue() : newValue;
      if (newCellValue !== newValue) {
        setImageSrc(newCellValue);
      }
      setRowHeight(getRowHeight(nodeParam.rowHeight));
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node);

  if (!imageSrc) {
    return null;
  }

  return (
    <Box
      css={{
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        height: '80%',
        '& > span': {
          height: '100%',
        },
        img: {
          height: '100%',
          borderRadius: 'base',
        },
      }}
    >
      <Tooltip
        {...tooltipProps}
        isDisabled={false}
        label={<Image fit="contain" src={`${imageSrc}&height=500`} />}
      >
        <Image fit="contain" src={`${imageSrc}&height=${rowHeight}`} />
      </Tooltip>
    </Box>
  );
}

const getRowHeight = pipe(defaultTo(DEFAULT_ROW_HEIGHT), floor);

export const ThumbnailCellRenderer = forwardRef<ICellRenderer, ThumbnailCellRendererProps>(
  ThumbnailCellRendererComponent
);

import {
  Alert,
  DataStatus,
  FormField,
  Tooltip,
  closeCurrentDialog,
  closeDialog,
  openDialog,
} from 'platform/components';
import {Grid, Heading, Show, Text, VStack} from 'platform/foundation';

import {isNil, not} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  CustomerResponseBodyV2,
  EntityResourceIds,
  FileResponseBody,
  VehicleActiveReservationApiResponse,
  VehicleReservationResponseBody,
  useGetCashRegisterListQuery,
  useGetParticipationQuery,
  useGetTenantQuery,
  CashReceiptV4ResponseBodyRead,
  useGetVehicleQuery,
} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId} from 'shared';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import {DocumentsDialog} from '../../DocumentsDialog/DocumentsDialog';
import {ActiveReservationBailDetail} from '../../VehicleReservation/components/ActiveReservationBailDetail';
import {FutureAgreementDialog} from '../../VehicleReservation/components/FutureAgreementDialog';
import {RefundedReservationDeposits} from '../../VehicleReservation/components/RefundedReservationDeposits';
import {ReservationStepProps} from '../types/ReservationStepProps';

interface DepositStepProps extends ReservationStepProps {
  customer: CustomerResponseBodyV2 | Nullish;
  reservation?: VehicleActiveReservationApiResponse | VehicleReservationResponseBody | Nullish;
  cashRegisterDocument?: CashReceiptV4ResponseBodyRead | Nullish;
  vehicleId: string;
}
const AGREEMENT_DIALOG_ID = 'futureAgreementDialog';

export function DepositStep(props: DepositStepProps) {
  const {data: tenant} = useGetTenantQuery();

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const {data: vehicle} = useGetVehicleQuery({vehicleId: props.vehicleId});

  const [canAddReservationDeposit] = usePermissions({
    permissionKeys: ['vehicleAddReservationDeposit'],
    scopes: {
      vehicleAddReservationDeposit: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  const currency = tenant?.currency || DEFAULT_CURRENCY;

  const {data: cashRegisters = [], isLoading: isCashRegistersLoading} = useGetCashRegisterListQuery(
    {
      inactive: false,
      withValidIncomeSeries: true,
      currency,
    }
  );

  const cashRegisterOptions = cashRegisters.map((cashRegister) => ({
    value: cashRegister.id,
    label: cashRegister.name,
  }));

  const isAdvanceDepositEnabled = props.formApi.watch('withAdvanceDeposit');

  const isReservationWithDeposit =
    isNotNilOrEmpty(props.cashRegisterDocument?.totalPriceWithVat.amount) &&
    isNotNil(props.reservation?.paidDeposit) &&
    isNotNil(props.cashRegisterDocument);

  const handleDocumentGenerated = (document: FileResponseBody) => {
    closeDialog(AGREEMENT_DIALOG_ID);

    openDialog(
      <DocumentsDialog
        customerId={props.reservation?.customer?.id ?? ''}
        documents={[document]}
        text={i18n.t('entity.vehicle.labels.futureAgreementCreatedText')}
        isDocxFormat
      />,
      {
        title: i18n.t('entity.vehicle.labels.futureAgreementCreatedTitle'),
        buttons: [
          {
            variant: 'secondary',
            title: i18n.t('general.actions.close'),
            onClick: closeCurrentDialog,
          },
        ],
      }
    );
  };
  const handleCreateFutureAgreement = () => {
    openDialog(
      <FutureAgreementDialog
        businessCaseId={props.reservation?.businessCase?.id ?? ''}
        customerId={props.reservation?.customer?.id ?? ''}
        onDocumentCreation={handleDocumentGenerated}
      />,
      {
        id: AGREEMENT_DIALOG_ID,
        title: i18n.t('entity.vehicle.labels.futureAgreementTitle'),
        scrollBehavior: 'outside',
      }
    );
  };

  return (
    <VStack spacing={4}>
      <Heading size={3}>{i18n.t('entity.vehicle.labels.advanceDeposit')}</Heading>
      <Text size="small" color="secondary">
        {i18n.t('entity.vehicle.labels.reservationDepositDescription')}
      </Text>
      <Show when={isNil(props.customer)}>
        <Alert
          title={i18n.t('entity.vehicle.labels.advanceDepositWarning')}
          type="inline"
          data-testid={suffixTestId('depositStep-alert', props)}
        />
      </Show>
      <Tooltip
        label={canAddReservationDeposit ? undefined : i18n.t('general.labels.noPermission')}
        placement="left"
      >
        <FormField
          label={i18n.t('entity.vehicle.labels.advanceDeposit')}
          name="withAdvanceDeposit"
          isDisabled={
            isReservationWithDeposit || isNil(props.customer) || not(canAddReservationDeposit)
          }
          type="switch"
          control={props.control}
          data-testid={suffixTestId('advanceDeposit', props)}
        />
      </Tooltip>
      <Show when={isAdvanceDepositEnabled && !isReservationWithDeposit}>
        <DataStatus isLoading={isCashRegistersLoading}>
          <Grid columns={2}>
            <FormField
              label={i18n.t('general.labels.amount')}
              name="amount"
              type="currency"
              currency={currency}
              isRequired
              control={props.control}
              data-testid={suffixTestId('amount', props)}
            />
            <FormField
              label={i18n.t('entity.accounting.labels.paymentMethod')}
              name="paymentMethod"
              type="text"
              isDisabled
              control={props.control}
              data-testid={suffixTestId('paymentMethod', props)}
            />
            <FormField
              label={i18n.t('general.labels.issuedOn')}
              name="issuedOn"
              type="date"
              isRequired
              control={props.control}
              data-testid={suffixTestId('issuedOn', props)}
            />
            <FormField
              label={i18n.t('general.labels.cashRegister')}
              data-testid={suffixTestId('cashRegister', props)}
              name="cashRegister"
              options={cashRegisterOptions}
              type="choice"
              control={props.control}
              isRequired
              menuInPortal
            />
          </Grid>
          <FormField
            label={i18n.t('general.labels.paymentNote')}
            name="cashReceiptNote"
            type="textarea"
            control={props.control}
            data-testid={suffixTestId('paymentNote', props)}
          />
        </DataStatus>
      </Show>
      <Show when={isReservationWithDeposit}>
        <ActiveReservationBailDetail
          reservation={props.reservation!}
          cashRegisterDocument={props.cashRegisterDocument}
          vehicleId={props.vehicleId}
          onCreateFutureAgreement={handleCreateFutureAgreement}
        />
      </Show>
      <Show when={isNotNilOrEmpty(props.reservation?.refundedDeposits)}>
        <RefundedReservationDeposits reservation={props.reservation!} vehicleId={props.vehicleId} />
      </Show>
    </VStack>
  );
}

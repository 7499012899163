import {Tooltip} from 'platform/components';
import {Icon} from 'platform/foundation';
import {css} from 'styled-components';

import {
  ForwardedRef,
  MutableRefObject,
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {FormError} from '../Field/components/FormError';
import {FormHelper} from '../Field/components/FormHelper';
import {FormLabel} from '../Field/components/FormLabel';
import {NumberFormat} from '../NumberFormat/NumberFormat';
import {Adornment} from './Adornment';
import {InputAdornment} from './InputAdornment';
import {
  HelpIconWrapper,
  Input,
  InputLabel,
  InputWrapper,
  LabelWrapper,
  Wrapper,
  WrapperFilled,
  WrapperInline,
  WrapperReadonly,
} from './styles';
import {TextFieldContextProvider} from './TextFieldContext';
import {MappedInputProps, TextFieldProps} from './types';

type FocusStates = 'none' | 'default' | 'extended';
/**
 * @deprecated - use platform instead
 */
const TextFieldComponent: ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (
  props,
  forwardedRef
) => {
  const {
    error,
    variant,
    helperText,
    label,
    useNumberFormat,
    numberFormatProps,
    startAdornment,
    endAdornment,
    inputStartAdornment,
    inputEndAdornment,
    counter,
    leaveOnEnter,
    clearable,
    helpIconText,
    tooltipText,
    className,
    style,
    autoComplete = 'off',
    ...inputAttributes
  } = props;

  const {onClick, onChange, onFocus, onBlur, onKeyDown, name, disabled, required} = inputAttributes;

  const inputRef = useRef<HTMLInputElement>(null);
  const [currentLength, setCurrentLength] = useState<number>();
  const [focusState, setFocusState] = useState<FocusStates>('none');

  const combinedRefs = useCombinedRefs(forwardedRef, inputRef);

  /**
   * Mainly for `styledMap`
   */
  const mappedProps = useMemo<MappedInputProps>(
    () => ({
      disabled,
      error,
      withEndAdornment: !!endAdornment,
      hasFocus: focusState !== 'none',
      defaultFocus: focusState === 'default',
      extendedFocus: focusState === 'extended',
    }),
    [disabled, error, endAdornment, focusState]
  );

  const HelperText = error ? FormError : FormHelper;
  const isInline = variant === 'inline';
  const showCounter = !isInline && counter?.show;
  const showLabelWrapper = Boolean(label) || showCounter;

  const showStartAdornment = !isInline && startAdornment;
  const startAdornmentList = Array.isArray(startAdornment) ? startAdornment : [startAdornment];

  const showEndAdornment = !isInline && endAdornment;
  const endAdornmentList = Array.isArray(endAdornment) ? endAdornment : [endAdornment];

  const handleSetFocusState = useCallback(
    (newState: FocusStates) => {
      if (disabled) {
        setFocusState('none');
        return;
      }

      setFocusState(newState);
    },
    [disabled]
  );

  /**
   * Need to dispatch `onChange` action on input itself
   *
   * [Comment](https://github.com/facebook/react/issues/10135#issuecomment-314441175)
   */
  const handleInputClear = useCallback(() => {
    const setNativeValue = (element: HTMLElement, value: string) => {
      const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
      const prototype = Object.getPrototypeOf(element);
      const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;

      if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter?.call(element, value);
      } else {
        valueSetter?.call(element, value);
      }
    };

    if (combinedRefs.current) {
      setNativeValue(combinedRefs.current, '');
    }

    combinedRefs.current?.dispatchEvent(new Event('change', {bubbles: true}));
    combinedRefs.current?.focus();
    handleSetFocusState('default');
  }, [combinedRefs, handleSetFocusState]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.currentTarget.blur();
      }

      onKeyDown?.(e);
    },
    [onKeyDown]
  );

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
    handleSetFocusState('default');
  }, [handleSetFocusState]);

  const isFirefox = () => navigator.userAgent.includes('Firefox');

  const commonProps: TextFieldProps = {
    ...inputAttributes,
    autoComplete: autoComplete === 'off' ? (isFirefox() ? 'off' : 'disabled') : autoComplete,
    className: 'text-field-input',
    onChange: (e) => {
      if (counter?.show) {
        setCurrentLength(e.target.value?.length);
      }

      onChange?.(e);
      if (focusState !== 'none') {
        handleSetFocusState('default');
      }
    },
    onClick: (e) => {
      onClick?.(e);
      handleSetFocusState('default');
    },
    onFocus: (e) => {
      onFocus?.(e);
      handleSetFocusState('extended');
    },
    onBlur: (e) => {
      onBlur?.(e);
      handleSetFocusState('none');
    },
    onKeyDown: leaveOnEnter ? handleKeyDown : onKeyDown,
  };

  const WrapperComponent = useMemo(() => {
    if (variant === 'inline') {
      return WrapperInline;
    }

    if (variant === 'filled') {
      return WrapperFilled;
    }

    if (variant === 'readonly') {
      return WrapperReadonly;
    }

    // "default" variant
    return Wrapper;
  }, [variant]);

  const tooltipContent = useMemo(() => {
    if (isNotNilOrEmpty(tooltipText)) {
      return tooltipText;
    }
    if (isInline && isNotNilOrEmpty(helperText)) {
      return helperText;
    }
    return undefined;
  }, [tooltipText, helperText, isInline]);

  useEffect(() => {
    if (counter?.show) {
      setCurrentLength(inputRef.current?.value?.length);
    }
  }, [counter?.show]);

  return (
    <TextFieldContextProvider focusInput={focusInput} disabled={disabled}>
      <WrapperComponent
        {...mappedProps}
        className={`form-field-wrapper ${className ?? ''}`}
        style={style}
        data-testid={`${`text-field-${name}`}-wrapper`}
      >
        {showLabelWrapper && (
          <LabelWrapper data-testid={`${`text-field-${name}`}-label-wrapper`}>
            {isNotNilOrEmpty(label) && (
              <InputLabel data-testid={`${`text-field-${name}`}-label`}>
                {required && '* '}
                {label}
                {isNotNilOrEmpty(helpIconText) && (
                  <Tooltip placement="top" label={helpIconText}>
                    <HelpIconWrapper
                      css={css`
                        margin-left: 4px;
                      `}
                      inherit
                    >
                      <Icon value="action/help" />
                    </HelpIconWrapper>
                  </Tooltip>
                )}
              </InputLabel>
            )}
            {showCounter && (
              <FormLabel data-testid={`${`text-field-${name}`}-label-counter`}>
                {currentLength}/{counter?.max}
              </FormLabel>
            )}
          </LabelWrapper>
        )}

        <Tooltip placement="top" label={tooltipContent} isDisabled={isNilOrEmpty(tooltipContent)}>
          <InputWrapper
            data-testid={`${`text-field-${name}`}-input-wrapper`}
            {...mappedProps}
            className="text-field-input-wrapper"
          >
            {showStartAdornment &&
              startAdornmentList.map((startAd, i) => (
                <Adornment
                  inputName={name}
                  key={startAd?.key ?? `startAdornmentKey-${i}`}
                  {...startAd}
                />
              ))}

            {inputStartAdornment && (
              <InputAdornment classNameSuffix="start" {...inputStartAdornment} />
            )}

            {useNumberFormat ? (
              <NumberFormat
                data-testid={`${`text-field-${name}`}-input`}
                {...commonProps}
                {...numberFormatProps}
                type={undefined}
                customInput={Input}
                ref={combinedRefs}
              />
            ) : (
              <Input
                data-testid={`${`text-field-${name}`}-input`}
                {...commonProps}
                ref={combinedRefs}
              />
            )}

            {inputEndAdornment && <InputAdornment classNameSuffix="end" {...inputEndAdornment} />}
            {clearable && (
              <InputAdornment
                clickable
                onClick={handleInputClear}
                classNameSuffix="end"
                Icon={<Icon value="navigation/cancel" />}
              />
            )}
            {error && !isInline && (
              <InputAdornment error classNameSuffix="end" Icon={<Icon value="alert/warning" />} />
            )}

            {showEndAdornment &&
              endAdornmentList.map((endAd, i) => (
                <Adornment inputName={name} key={endAd?.key ?? `endAdornment-${i}`} {...endAd} />
              ))}
          </InputWrapper>
        </Tooltip>

        {isNotNilOrEmpty(helperText) && !isInline && (
          <HelperText data-testid={`text-field-${name}-helper-text-wrapper`}>
            {helperText}
          </HelperText>
        )}
      </WrapperComponent>
    </TextFieldContextProvider>
  );
};

const useCombinedRefs = <T,>(
  ...refs: (ForwardedRef<T> | MutableRefObject<T | undefined>)[]
): MutableRefObject<T | null> => {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(targetRef.current ?? null);
      } else {
        ref.current = targetRef.current ?? null;
      }
    });
  }, [refs]);

  return targetRef;
};

export const TextField = forwardRef(TextFieldComponent);

import {ContextMenuContextType} from '../types';

export const openMenu = (
  event: MouseEvent,
  contextMenu: ContextMenuContextType,
  id: string | number
) => {
  if (contextMenu?.menuState) {
    return;
  }
  event.preventDefault();
  if (contextMenu?.setMenuState) {
    contextMenu.setMenuState({
      id: id?.toString(),
      top: event.offsetY,
      left: event.offsetX,
    });
  }
};

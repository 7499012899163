import {
  Alert,
  AlertVariants,
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Dialog,
  showNotification,
} from 'platform/components';
import {Box, Grid, GridItem, Hide, Show, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FormSpy} from 'react-final-form';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {always, isNil, isNotEmpty, isNotNil} from 'ramda';

import {
  BranchBusinessCaseResponseBody,
  CreateVehicleRequestBody,
  useDeleteBusinessCaseMutation,
  useLazyFindBusinessCasesByVehicleQuery,
  useLazyGetSaleVehicleQuery,
  useReRegisterVehicleForSaleMutation,
  useTakeoverBusinessCaseMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {businessCaseRoutes, testIds, vehiclesRoutes} from '@dms/routes';
import {handleApiError, useGetCurrentBranch} from '@dms/shared';

import {
  composePath,
  debounce,
  noop,
  suffixTestId,
  TestIdProps,
  useBoolean,
  useToggle,
} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {VehicleService} from '../../../services/VehicleService';
import {getVehicleOverallConditions} from '../../../store/carAudit/actions';
import {SaleVehicle} from '../../../types/SaleVehicle';
import {SimilarVehicle} from '../../../types/SimilarVehicle';
import {VinDecoder} from '../../../types/VinDecoder';
import {useCallApi} from '../../../utils/api';
import {openInNew} from '../../../utils/someTeasUtils';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {useVehicleCreateContext} from '../../VehicleCreateContext/hooks/useVehicleCreateContext';
import {VehicleWidget} from '../../VehicleWidget/VehicleWidget';
import {HintType, HintTypeEnum} from '../types/CommonVehicleTypes';
import {VehicleDuplicatesHints} from './VehicleDuplicatesHints';

const vinCharRegex = /[(A-H|J-N|P|R-Z|0-9)]{1,17}/;
const registrationPlateCharRegex = /[A-Z0-9]/g;

const parseVin = (value?: string) => {
  if (!value) {
    return '';
  }

  return value?.toUpperCase?.().match?.(vinCharRegex)?.slice(0, 17).join('');
};

const parsePlate = (value?: string) => {
  if (!value) {
    return '';
  }

  return value?.toUpperCase?.().match?.(registrationPlateCharRegex)?.slice(0, 17).join('');
};

type SimilarVehiclesRequestBody = {
  size?: number | null;
  offset?: number | null;
  vin?: string | null;
  registrationPlateContains?: string | null;
  authorization?: string;
};

export interface VinAndLicensePlateType extends TestIdProps {
  onDecode?: typeof noop;
  onVehicleSelect?: (vehicle: SimilarVehicle, makeSaleVehicleAvailableForSale?: boolean) => void;
  onSaleVehicleCreate: (vehicle: SimilarVehicle) => Promise<void>;
  excludeId?: string | null;
  /**
   * createMode - true only when new vehicle is creating
   */
  createMode?: boolean;
  selectVehicleToPurchase?: boolean;
  decoding?: boolean;
  setSameVehicleId: (vehicleId: string | null) => void;
}

export const VinAndLicensePlate: FC<VinAndLicensePlateType> = ({
  createMode = false,
  selectVehicleToPurchase,
  decoding,
  onDecode,
  excludeId,
  onVehicleSelect,
  setSameVehicleId,
  onSaleVehicleCreate,
  ...props
}) => {
  const vinAndRegistrationPlateCache = useRef<{registrationPlate?: string; vin?: string}>({});
  const location = useLocation();
  const isOnBusinessCase = location.pathname.startsWith('/business-case/');
  const {id: businessCaseId} = useParams();
  const {onClose: closeVehicleCreate} = useVehicleCreateContext();
  const {activeBranchId} = useGetCurrentBranch();
  const [takeoverBusinessCase, {isLoading: isLoadingTakeover}] = useTakeoverBusinessCaseMutation();
  const [deleteBusinessCase] = useDeleteBusinessCaseMutation();
  const [getSaleVehicle] = useLazyGetSaleVehicleQuery();

  const {Field, form} = useFormRenderer<CreateVehicleRequestBody>();
  const dispatch = useThunkDispatch();
  const navigate = useNavigate();

  // List of fetched similar vehicles
  const [similarVehicles, setSimilar] = useState<SimilarVehicle[]>([]);
  // List of fetched similar vehicles business cases
  const [businessCases, setBusinessCases] = useState<BranchBusinessCaseResponseBody[]>([]);
  // Set hint type
  const [hintType, setHintType] = useState<HintType>(null);
  // Set alert type
  const [alertType, setAlertType] = useState<AlertVariants | null>(null);
  // display button to re-register vehicle which has been already sold
  const [canBeReRegistered, setCanBeReRegistered] = useState(false);
  const [isVinEditDisabled, disableVinEdit, enableVinEdit] = useBoolean();
  const [isLicensePlateDisabled, disableLicensePlate, enableLicensePlate] = useBoolean();

  const [loading, startLoading, stopLoading] = useBoolean();
  const [confirmTakeoverOpen, toggleConfirmTakeoverOpen] = useToggle();
  const [confirmReRegisterOpen, toggleConfirmReRegisterOpen] = useToggle();

  const getSimilarVehicles = useCallApi(VehicleService.getSimilarVehicles);
  const [findBusinessCasesByVehicle] = useLazyFindBusinessCasesByVehicleQuery();

  const [reRegisterVehicleForSale, {isLoading: isLoadingReRegister}] =
    useReRegisterVehicleForSaleMutation();

  const handleChanges = useMemo(
    () =>
      debounce(
        (
          {vin, registrationPlateContains}: SimilarVehiclesRequestBody,
          callback?: (existingVehicleId: string | null) => void
        ) => {
          setSameVehicleId(null);

          if (vin || registrationPlateContains) {
            startLoading();
            const requestBody: SimilarVehiclesRequestBody = {
              size: 20,
            };
            if (vin?.length === 17) {
              requestBody.vin = vin;
            }
            if (registrationPlateContains) {
              requestBody.registrationPlateContains = registrationPlateContains;
            }

            getSimilarVehicles(requestBody)
              .then(async (result) => {
                if (excludeId) {
                  const excludeIdIdx = result?.findIndex((vehicle) => vehicle.id === excludeId);
                  if (excludeIdIdx !== -1) {
                    result.splice(excludeIdIdx, 1);
                  }
                }

                if (result.length) {
                  const {data: existingBusinessCases} = await findBusinessCasesByVehicle({
                    vehicles: result.map((similar) => similar.id),
                  });

                  setBusinessCases(existingBusinessCases ?? []);

                  await dispatch(
                    getVehicleOverallConditions.action({
                      vehicleIds: result.map((similar) => similar.id),
                    })
                  );
                }

                const sameVinVehicle = result?.find((vehicle) => vin && vehicle.vin === vin);
                const sameVinVehicleId = sameVinVehicle?.id;
                const sameVinVehicleIsWithoutSaleVehicle = isNil(sameVinVehicle?.saleVehicleId);

                const someResultCanCreateSaleVehicle = result.some((item) =>
                  isNil(item.saleVehicleId)
                );

                if (sameVinVehicleIsWithoutSaleVehicle && sameVinVehicleId) {
                  const {data: saleVehicle} = await getSaleVehicle({vehicleId: sameVinVehicleId});

                  if (saleVehicle?.id) {
                    // TODO - T20-39110 - SALE_VEHICLE_STATES - 35
                    // TODO - T20-39110 - SALE_VEHICLE_STATES - 36
                    setCanBeReRegistered(
                      match([saleVehicle.buyingState, saleVehicle.sellingState])
                        .with(
                          [null, SaleVehicle.sellingState.SOLD],
                          [SaleVehicle.buyingState.BOUGHT, Pattern.any],
                          [SaleVehicle.buyingState.RETURNED_TO_CUSTOMER, Pattern.any],
                          [SaleVehicle.buyingState.NOT_BOUGHT, Pattern.any],
                          () => true
                        )
                        .otherwise(() => false)
                    );
                  } else {
                    setCanBeReRegistered(false);
                  }
                }

                setSimilar(result || []);
                stopLoading();

                const newHintType = match([
                  isNotNil(sameVinVehicleId),
                  isNotEmpty(result),
                  createMode,
                  someResultCanCreateSaleVehicle,
                  sameVinVehicleIsWithoutSaleVehicle,
                ])
                  .with(
                    [true, true, Pattern.boolean, false, false],
                    always(HintTypeEnum.VinAlreadyExists)
                  )
                  .with([true, true, true, true, true], always(HintTypeEnum.VinIsUsedInService))
                  .with(
                    [Pattern.boolean, true, true, true, Pattern.boolean],
                    always(HintTypeEnum.SomeResultsCanCreateSale)
                  )
                  .with(
                    [false, true, Pattern.boolean, false, false],
                    always(HintTypeEnum.InfoLicense)
                  )
                  .otherwise(always(null));

                setHintType(newHintType);

                if (callback) {
                  callback(sameVinVehicleId || null);
                }
              })
              .catch((error) => {
                stopLoading();
                showNotification.error(
                  error.response?.data?.errors?.[0]?.message || error.toString()
                );
              });
          } else {
            setSimilar([]);
            stopLoading();
            setHintType(null);
          }
        },
        500
      ),
    [excludeId]
  );

  const handleTakeover = (takeoverBusinessCaseId: string) => async () => {
    await takeoverBusinessCase({
      businessCaseId: takeoverBusinessCaseId,
      takeoverBusinessCaseRequestBody: {branchId: activeBranchId},
    })
      .unwrap()
      .then(() => {
        if (isOnBusinessCase && businessCaseId) {
          closeVehicleCreate?.();
          deleteBusinessCase({businessCaseId}).unwrap().catch(handleApiError);
        }
        setTimeout(() => {
          navigate(composePath(businessCaseRoutes.buying, {params: {id: takeoverBusinessCaseId}}));
        }, 500);
      })
      .catch(handleApiError);
  };

  const getHandleReRegister = (vehicle: SimilarVehicle) => async () => {
    if (!selectVehicleToPurchase) {
      await reRegisterVehicleForSale({vehicleId: vehicle.id}).unwrap().catch(handleApiError);
    }

    handleSelectVehicle(vehicle, true);
  };

  const onVinOrPlateChange = useCallback(
    (vin?: string, registrationPlate?: string | null) => () => {
      if (
        vin === vinAndRegistrationPlateCache.current?.vin &&
        registrationPlate === vinAndRegistrationPlateCache.current.registrationPlate
      ) {
        return;
      }

      vinAndRegistrationPlateCache.current = {
        vin,
        registrationPlate: registrationPlate ?? undefined,
      };

      if (isVinEditDisabled) {
        return;
      }

      handleChanges({vin, registrationPlateContains: registrationPlate});
    },
    [handleChanges, isVinEditDisabled]
  );

  useEffect(() => {
    let latestVin = form.getState().values.vin;

    const unsub = form.subscribe(
      ({values}) => {
        if (latestVin !== values.vin) {
          latestVin = values.vin;

          if (values.vin?.length === 17) {
            onVinOrPlateChange(values.vin, values.state?.registrationPlate)();
          }
        }
      },
      {values: true}
    );

    return () => unsub();
  }, [form, onVinOrPlateChange]);

  const getDecodingStatus = (data: VinDecoder): AlertVariants => {
    if (data?.decodingStatus === VinDecoder.decodingStatus.FULLY) {
      return 'success';
    }
    if (data?.decodingStatus === VinDecoder.decodingStatus.PARTIALLY) {
      return 'warning';
    }

    return 'error';
  };

  const handleSelectVehicle = (
    vehicle: SimilarVehicle,
    makeSaleVehicleAvailableForSale?: boolean
  ) => {
    if (isNotNil(onVehicleSelect)) {
      setHintType(null);
      onVehicleSelect(vehicle, makeSaleVehicleAvailableForSale);
      return;
    }

    navigate(composePath(vehiclesRoutes.edit, {params: {id: vehicle.id}}), {
      state: {makeSaleVehicleAvailableForSale, ignoreUnmountEvents: true},
    });
  };

  const handleCreateSaleVehicleForServiceVehicle = (vehicle: SimilarVehicle) => {
    startLoading();
    setSameVehicleId(vehicle.id);

    onSaleVehicleCreate(vehicle).then(() => {
      if (vehicle.vin) {
        vinAndRegistrationPlateCache.current.vin = vehicle.vin;
        disableVinEdit();
      }
      if (vehicle.registrationPlate) {
        vinAndRegistrationPlateCache.current.registrationPlate = vehicle.registrationPlate;
        disableLicensePlate();
      }

      setAlertType(null);
      setSimilar([]);
      setHintType(HintTypeEnum.CreatingSaleForService);
      stopLoading();
    });
  };

  const pickAlert = (): ReactNode => {
    switch (alertType) {
      case 'success':
        return (
          <Alert
            data-testid={testIds.vehicles.create('vinDecodedSuccessfully')}
            variant="success"
            title={i18n.t('entity.vehicle.notifications.vinDecodedSuccessfully')}
            message={i18n.t('entity.vehicle.notifications.vinDecodedSuccessfullyDescription')}
            onClose={() => setAlertType(null)}
          />
        );
      case 'warning':
        return (
          <Alert
            data-testid={testIds.vehicles.create('vinDecodedPartially')}
            variant="warning"
            title={i18n.t('entity.vehicle.notifications.vinDecodedPartially')}
            message={i18n.t('entity.vehicle.notifications.vinDecodedPartiallyDescription')}
            onClose={() => setAlertType(null)}
          />
        );
      default:
        return (
          <Alert
            data-testid={testIds.vehicles.create('vinDecodedError')}
            variant="error"
            title={i18n.t('entity.vehicle.notifications.vinDecodedError')}
            message={i18n.t('entity.vehicle.notifications.vinDecodedErrorDescriptions')}
            onClose={() => setAlertType(null)}
          />
        );
    }
  };

  const isVinDecoderWidgetVisible = alertType || hintType || isNotEmpty(similarVehicles);

  return (
    <Box>
      <Grid columns={10} spacing={2} align="flex-end">
        <FormSpy<CreateVehicleRequestBody>
          subscription={{values: true, active: true}}
          render={({values}) => (
            <>
              <GridItem span={3}>
                <Field
                  as="text"
                  name="state.registrationPlate"
                  label={i18n.t('entity.vehicle.labels.licensePlate')}
                  parse={parsePlate}
                  disabled={isLicensePlateDisabled}
                  isLoading
                  onBlur={onVinOrPlateChange(
                    values?.vin ?? undefined,
                    values?.state?.registrationPlate
                  )}
                />
              </GridItem>
              <GridItem span={6}>
                <Field
                  as="text"
                  name="vin"
                  label={i18n.t('entity.vehicle.labels.vinCode')}
                  parse={parseVin}
                  maxLength={17}
                  disabled={isVinEditDisabled}
                  isCounterVisible
                  isLoading
                  onBlur={onVinOrPlateChange(
                    values?.vin ?? undefined,
                    values?.state?.registrationPlate
                  )}
                />
              </GridItem>
            </>
          )}
        />

        <FormSpy<CreateVehicleRequestBody>
          render={({values}) => (
            <Box paddingBottom={4}>
              <GridItem span={1}>
                <Button
                  isFullWidth
                  variant="primary"
                  isLoading={decoding}
                  isDisabled={isVinEditDisabled}
                  onClick={() => {
                    handleChanges(
                      {
                        vin: values.vin,
                        registrationPlateContains: values.state?.registrationPlate,
                      },
                      () => {
                        onDecode?.(values?.vin).then((result: VinDecoder) =>
                          setAlertType(getDecodingStatus(result))
                        );
                      }
                    );
                  }}
                  data-testid={testIds.vehicles.create('decodeVin')}
                  title={i18n.t('general.labels.decode')}
                />
              </GridItem>
            </Box>
          )}
        />
      </Grid>

      <Show when={loading && !isVinDecoderWidgetVisible}>
        <Card variant="inlineGrey" data-testid={testIds.vehicles.create('similarVehicles')}>
          <DataStatus isLoading />
        </Card>
      </Show>

      <Show when={isVinDecoderWidgetVisible}>
        <Box paddingBottom={4}>
          <Card variant="inlineGrey" data-testid={testIds.vehicles.create('similarVehicles')}>
            <VStack spacing={4}>
              <Show when={alertType}>
                <Box>{pickAlert()}</Box>
              </Show>

              <VehicleDuplicatesHints
                hintType={hintType}
                callback={() => {
                  if (hintType === HintTypeEnum.CreatingSaleForService) {
                    enableVinEdit();
                    enableLicensePlate();
                    setAlertType(null);
                    handleChanges(vinAndRegistrationPlateCache.current);
                  }

                  setHintType(null);
                }}
                createMode={createMode}
              />

              {isNotEmpty(similarVehicles) && (
                <>
                  {loading ? (
                    <DataStatus isLoading />
                  ) : (
                    <Grid columns={1}>
                      {similarVehicles.map((vehicle, i) => {
                        const flags = vehicle.isArchived
                          ? [
                              {
                                color: 'neutral' as const,
                                name: i18n.t('entity.vehicle.labels.archived'),
                              },
                            ]
                          : undefined;

                        const existingBusinessCase = businessCases.find(
                          (businessCase) => businessCase.vehicleId === vehicle.id
                        );
                        const existingBusinessCaseInSameBranch =
                          existingBusinessCase?.branchId === activeBranchId;

                        return (
                          <GridItem key={vehicle.id}>
                            <VehicleWidget
                              data-testid={testIds.vehicles.create(`similarVehicle[${i}]`)}
                              flags={flags}
                              vehicle={vehicle}
                              shouldShowDetailButton={false}
                              shouldShowPrice={false}
                              extraControls={
                                <ButtonGroup>
                                  <Button
                                    data-testid={testIds.vehicles.create(
                                      `similarVehicle[${i}]-open`
                                    )}
                                    variant="outlined"
                                    leftIcon="action/launch"
                                    onClick={() =>
                                      openInNew(
                                        composePath(vehiclesRoutes.detail, {
                                          params: {id: vehicle.id},
                                        })
                                      )
                                    }
                                    title={i18n.t('entity.vehicle.labels.detail')}
                                  />
                                  <Show when={existingBusinessCase}>
                                    <Button
                                      data-testid={testIds.vehicles.create(
                                        `similarVehicle[${i}]-view`
                                      )}
                                      leftIcon="action/launch"
                                      variant="link"
                                      onClick={() =>
                                        openInNew(
                                          composePath(businessCaseRoutes.overview, {
                                            params: {
                                              id: existingBusinessCase?.id,
                                            },
                                          })
                                        )
                                      }
                                      title={i18n.t('entity.businessCase.labels.view')}
                                    />
                                  </Show>

                                  <Show when={canBeReRegistered}>
                                    <>
                                      <Button
                                        data-testid={testIds.vehicles.create(
                                          `similarVehicle[${i}]-reregister`
                                        )}
                                        variant="secondary"
                                        onClick={toggleConfirmReRegisterOpen}
                                        title={i18n.t('entity.vehicle.actions.reregister')}
                                      />
                                      <Dialog
                                        data-testid={testIds.businessCase.buying(
                                          'reregister-confirm'
                                        )}
                                        isOpen={confirmReRegisterOpen}
                                        onClose={toggleConfirmReRegisterOpen}
                                        size="small"
                                        title={i18n.t`page.vehicle.labels.confirmReRegister`}
                                      >
                                        <ButtonGroup align="right">
                                          <Button
                                            variant="secondary"
                                            onClick={toggleConfirmReRegisterOpen}
                                            isDisabled={isLoadingReRegister}
                                            title={i18n.t('general.labels.no')}
                                            data-testid={suffixTestId('reregistration-no', props)}
                                          />
                                          <Button
                                            variant="primary"
                                            onClick={getHandleReRegister(vehicle)}
                                            isLoading={isLoadingReRegister}
                                            title={i18n.t('general.labels.yes')}
                                            data-testid={suffixTestId('reregistration-yes', props)}
                                          />
                                        </ButtonGroup>
                                      </Dialog>
                                    </>
                                  </Show>
                                  <Hide
                                    when={
                                      selectVehicleToPurchase ||
                                      existingBusinessCase ||
                                      canBeReRegistered
                                    }
                                  >
                                    <Show when={createMode && isNil(vehicle.saleVehicleId)}>
                                      <Button
                                        data-testid={testIds.vehicles.create(
                                          `similarVehicle[${i}]-select`
                                        )}
                                        variant="primary"
                                        onClick={() =>
                                          handleCreateSaleVehicleForServiceVehicle(vehicle)
                                        }
                                        title={i18n.t('entity.vehicle.actions.createSaleVehicle')}
                                      />
                                    </Show>
                                    <Hide when={createMode && isNil(vehicle.saleVehicleId)}>
                                      <Button
                                        data-testid={testIds.vehicles.create(
                                          `similarVehicle[${i}]-select`
                                        )}
                                        variant="primary"
                                        onClick={() => handleSelectVehicle(vehicle)}
                                        title={i18n.t('general.labels.select')}
                                      />
                                    </Hide>
                                  </Hide>
                                  {existingBusinessCase ? (
                                    <>
                                      <Show
                                        when={
                                          selectVehicleToPurchase &&
                                          !existingBusinessCaseInSameBranch
                                        }
                                      >
                                        <>
                                          <Button
                                            data-testid={testIds.vehicles.create(
                                              `similarVehicle[${i}]-takeover`
                                            )}
                                            variant="secondary"
                                            onClick={toggleConfirmTakeoverOpen}
                                            title={i18n.t('entity.businessCase.labels.takeover')}
                                          />
                                          <Dialog
                                            data-testid={testIds.businessCase.buying(
                                              'takeover-confirm'
                                            )}
                                            isOpen={confirmTakeoverOpen}
                                            onClose={toggleConfirmTakeoverOpen}
                                            size="small"
                                            title={i18n.t`page.businessCase.labels.confirmTakeover`}
                                          >
                                            <ButtonGroup align="right">
                                              <Button
                                                variant="secondary"
                                                onClick={toggleConfirmTakeoverOpen}
                                                isDisabled={isLoadingTakeover}
                                                title={i18n.t('general.labels.no')}
                                                data-testid={suffixTestId('takeover-no', props)}
                                              />
                                              <Button
                                                variant="primary"
                                                onClick={handleTakeover(existingBusinessCase.id)}
                                                isLoading={isLoadingTakeover}
                                                title={i18n.t('general.labels.yes')}
                                                data-testid={suffixTestId('takeover-no', props)}
                                              />
                                            </ButtonGroup>
                                          </Dialog>
                                        </>
                                      </Show>
                                    </>
                                  ) : null}
                                </ButtonGroup>
                              }
                            />
                          </GridItem>
                        );
                      })}
                    </Grid>
                  )}
                </>
              )}
            </VStack>
          </Card>
        </Box>
      </Show>
    </Box>
  );
};

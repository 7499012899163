import {ICellRenderer} from 'ag-grid-community';
import {Checkbox, CheckboxProps, Tooltip} from 'platform/components';
import {Box} from 'platform/foundation';

import {useState, forwardRef, Ref, useImperativeHandle} from 'react';

import {noop} from 'ramda-adjunct';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRendererParams} from '../../types/AgGridTypes';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getUnformattedValue} from '../../utils/getUnformattedValue';

export interface CheckboxCellRendererParams extends ICellRendererParams {
  checkboxProps?: Partial<CheckboxProps>;
  getTooltipProps?: TooltipGetter;
}

function CheckboxCellRendererComponent(props: CheckboxCellRendererParams, ref: Ref<ICellRenderer>) {
  const {checkboxProps, getTooltipProps, data, node} = props;

  const [checked, setChecked] = useState<boolean | unknown>(getUnformattedValue(props));

  useImperativeHandle(ref, () => ({
    refresh: ({getValue: getNewValue, value: newValue}) => {
      const newCellValue = getNewValue ? getNewValue() : newValue;
      if (newCellValue !== newValue) {
        setChecked(newCellValue);
      }
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  return (
    <Tooltip {...tooltipProps}>
      <Box css={{display: 'inline-flex'}}>
        <Checkbox {...checkboxProps} value={!!checked} onChange={noop} isDisabled />
      </Box>
    </Tooltip>
  );
}

export const CheckboxCellRenderer = forwardRef<ICellRenderer, CheckboxCellRendererParams>(
  CheckboxCellRendererComponent
);

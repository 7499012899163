import {Alert} from 'platform/components';
import {Show} from 'platform/foundation';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetBusinessCaseQuery,
  useGetCustomerV2Query,
  useGetParticipationQuery,
  useGetSaleVehicleFromSaleVehicleIdQuery,
  useGetSaleVehicleQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {businessCaseRoutes, testIds} from '@dms/routes';
import {
  EntityHeader,
  EntityHeaderControl,
  getCustomerName,
  useBusinessCaseActions,
  useGetFlags,
  usePermissions,
  usePricePermissions,
} from '@dms/shared';

import {buildArray, composePath, useNavigate, useRequiredParams, useToggle} from 'shared';

import {useSelectVehicleContext} from '../../hooks/useSelectVehicleContext';
import {getPurchaseVehicleIdFromBusinessCase} from '../../utils/getPurchaseVehicleIdFromBusinessCase';
import {getSaleVehicleIdFromBusinessCase} from '../../utils/getSaleVehicleIdFromBusinessCase';
import {ChangeVehicleDialog} from '../ChangeVehicleDialog';
import {useActions} from './hooks/useActions';
import {useGetParameters} from './hooks/useGetParameters';
import {useGetPrimaryAndSecondaryParameters} from './hooks/useGetPrimaryAndSecondaryParameters';
import {useGetSnippets} from './hooks/useGetSnippets';

export function BusinessCaseHeader() {
  const {id: businessCaseId} = useRequiredParams();
  const {isActionEnabled} = useBusinessCaseActions(businessCaseId);
  const navigate = useNavigate();
  const [isConfirmChangeVehicleOpen, toggleIsConfirmChangeVehicleOpen] = useToggle();
  const {setSelectSaleVehicleOpen} = useSelectVehicleContext();

  const {data: businessCase} = useGetBusinessCaseQuery({
    businessCaseId,
  });

  const saleVehicleId = getSaleVehicleIdFromBusinessCase(businessCase);
  const purchaseVehicleId = getPurchaseVehicleIdFromBusinessCase(businessCase);

  const vehicleId = saleVehicleId ?? purchaseVehicleId;

  const actualSaleVehicleId = businessCase?.saleVehicleId;

  const {data: vehicle} = useGetSaleVehicleQuery(
    {vehicleId: vehicleId ?? ''},
    {skip: isNilOrEmpty(vehicleId)}
  );

  const {data: saleVehicle} = useGetSaleVehicleFromSaleVehicleIdQuery(
    {vehicleId: actualSaleVehicleId ?? ''},
    {skip: isNilOrEmpty(actualSaleVehicleId)}
  );

  const {data: customer} = useGetCustomerV2Query(
    {customerId: businessCase?.customerId ?? ''},
    {skip: isNilOrEmpty(businessCase?.customerId)}
  );

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.businessCase,
    recordId: businessCaseId,
  });

  const [
    canReadTag,
    hasAddBusinessCaseTagPermission,
    hasUpdateBusinessCaseTagPermission,
    hasAddBusinessCaseTagToRecordPermission,
    hasRemoveBusinessCaseTagFromRecordPermission,
    hasRemoveBusinessCaseTagPermission,
    hasClearBusinessCaseTagExpirationPermission,
    hasSetBusinessCaseTagExpirationPermission,
  ] = usePermissions({
    permissionKeys: [
      'businessCaseTagRead',
      'businessCaseTagAdd',
      'businessCaseTagUpdate',
      'businessCaseTagAddToRecord',
      'businessCaseTagRemoveFromRecord',
      'businessCaseTagRemove',
      'businessCaseTagClearExpiration',
      'businessCaseTagSetExpiration',
    ],
    scopes: {
      businessCaseTagAddToRecord: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCaseTagRead: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCaseTagRemoveFromRecord: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
    },
  });

  const canCreateTag = hasAddBusinessCaseTagPermission && hasUpdateBusinessCaseTagPermission;
  const canAssignOrUnassignTag =
    hasAddBusinessCaseTagToRecordPermission && hasRemoveBusinessCaseTagFromRecordPermission;
  const canDeleteTag =
    hasRemoveBusinessCaseTagPermission &&
    hasClearBusinessCaseTagExpirationPermission &&
    hasSetBusinessCaseTagExpirationPermission;
  const {canReadBusinessCaseSaleVehicleSalePrice, canReadBusinessCasePurchaseVehiclePurchasePrice} =
    usePricePermissions({businessCaseRecordId: businessCaseId, vehicleRecordId: vehicleId});

  const title = i18n.t('page.businessCase.title', {name: getCustomerName(customer)});
  const [parameters] = useGetParameters(businessCase);
  const [flags] = useGetFlags(businessCase, saleVehicle);
  const [actions] = useActions(businessCase, saleVehicle);
  const {primaryParameter, secondaryParameter, tertiaryParameter} =
    useGetPrimaryAndSecondaryParameters(businessCase, vehicle);
  const {snippets, isLoading} = useGetSnippets(businessCase, customer);

  const onChangeVehicleConfirm = () => {
    toggleIsConfirmChangeVehicleOpen();
    setSelectSaleVehicleOpen(true);
  };

  const shouldShowReservationAlert =
    isNotNil(vehicle?.activeReservation) &&
    vehicle?.activeReservation?.businessCaseId !== businessCaseId &&
    businessCase?.businessCaseType === 'SELLING' &&
    businessCase?.businessCaseState === 'OFFER';

  const shouldShowVehicleSoldAlert =
    businessCase?.businessCaseType === 'SELLING' &&
    businessCase?.businessCaseState === 'OFFER' &&
    vehicle?.isAvailable === false;

  return (
    <>
      <EntityHeader
        icon="places/business_center"
        title={title}
        flags={flags}
        parameters={parameters}
        controls={buildArray<EntityHeaderControl>(['ASSIGNEE'])
          .when(isActionEnabled('CHANGE_CUSTOM_CODE'), 'BC_CUSTOM_ID')
          .when(isActionEnabled('CHANGE_BRANCH'), 'BC_BRANCH')}
        actions={actions}
        snippets={snippets}
        isLoadingSnippets={isLoading}
        recordId={businessCase?.id}
        resourceId={EntityResourceIds.businessCase}
        primaryParameter={
          canReadBusinessCaseSaleVehicleSalePrice && canReadBusinessCasePurchaseVehiclePurchasePrice
            ? primaryParameter
            : undefined
        }
        secondaryParameter={
          canReadBusinessCaseSaleVehicleSalePrice && canReadBusinessCasePurchaseVehiclePurchasePrice
            ? secondaryParameter
            : undefined
        }
        tertiaryParameter={
          canReadBusinessCaseSaleVehicleSalePrice &&
          canReadBusinessCasePurchaseVehiclePurchasePrice &&
          businessCase?.businessCaseInternalType !== 'PURCHASE_BROKERAGE'
            ? tertiaryParameter
            : undefined
        }
        data-testid={testIds.businessCase.detail('businessCaseDetailHeader')}
        permissions={{
          tag: {
            isDeletable: canDeleteTag,
            isUpdatable: canCreateTag,
            isReadable: canReadTag,
            isCreatable: canCreateTag,
            isAssignable: canAssignOrUnassignTag,
            isUnassignable: canAssignOrUnassignTag,
          },
        }}
      />
      <Show when={shouldShowReservationAlert}>
        <Alert
          title={i18n.t('general.notifications.reservedVehicle.title')}
          message={i18n.t('general.notifications.reservedVehicle.description')}
          variant="error"
          data-testid={testIds.businessCase.detail('businessCaseDetailHeader-alert')}
          hyperlinks={[
            {
              title: i18n.t('entity.vehicle.labels.showReservation'),
              ...(isNil(vehicle?.activeReservation?.businessCaseId)
                ? {
                    onClick: () => {
                      navigate(
                        composePath(businessCaseRoutes.reservations, {params: {id: businessCaseId}})
                      );
                    },
                  }
                : {
                    // composePath() doesn't work, I get redirected back to first BC
                    href: `/business-case/${vehicle.activeReservation.businessCaseId}/reservations`,
                  }),
              size: 'small',
            },
          ]}
        />
      </Show>

      <Show when={shouldShowVehicleSoldAlert}>
        <Alert
          title={i18n.t('entity.businessCase.labels.alreadySoldVehicle')}
          variant="error"
          data-testid={testIds.businessCase?.checkout('alreadySoldVehicle')}
          hyperlinks={[
            {
              title: i18n.t('page.businessCase.actions.changeVehicle'),
              onClick: () => toggleIsConfirmChangeVehicleOpen(),
            },
          ]}
        />

        <ChangeVehicleDialog
          isConfirmChangeVehicleOpen={isConfirmChangeVehicleOpen}
          toggleIsConfirmChangeVehicleOpen={toggleIsConfirmChangeVehicleOpen}
          onChangeVehicleConfirm={onChangeVehicleConfirm}
        />
      </Show>
    </>
  );
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import {ApiException} from '@dms/api';

import {FullRole} from '../../types/FullRole';
import {RoleIdType} from '../../types/GeneralSettings';
import {RoleForRoleManagement} from '../../types/RoleForRoleManagement';
import {ShortRole} from '../../types/ShortRole';
import {User} from '../../types/User';
import {
  DeleteRoleType,
  NewRoleStateType,
  TCreateUserRoleAction,
  TUpdateRolePermission,
  UserManagementSliceType,
} from '../../types/UserManagementTypes';

const STATE_NAME = 'generalSettings/UserManagement';

const initialState: UserManagementSliceType = {
  userList: {
    data: {},
    order: [],
    loading: false,
    error: null,
  },
  rolesListForUserManagement: {
    data: [],
    loading: false,
    error: null,
  },
  rolesListFull: {
    data: [],
    loading: false,
    error: null,
  },
  singleRole: {
    data: {
      id: '',
      title: '',
      permissions: {},
      system: true,
      licenses: [],
    },
    loading: false,
    error: null,

    deletion: {
      error: null,
    },
  },
  creatingUserRole: {
    loading: false,
    error: null,
    isSuccess: false,
  },
};

const UserManagementSlice = createSlice({
  name: STATE_NAME,
  initialState,
  reducers: {
    patchUserSuccess(state, {payload}: PayloadAction<User>) {
      state.userList.loading = false;
      state.userList.error = null;
      state.userList.data[payload.id] = payload;
    },
    blockUserSuccess(state, {payload}: PayloadAction<User>) {
      state.userList.loading = false;
      state.userList.error = null;
      state.userList.data[payload.id] = payload;
    },
    unblockUserSuccess(state, {payload}: PayloadAction<User>) {
      state.userList.loading = false;
      state.userList.error = null;
      state.userList.data[payload.id] = payload;
    },
    fetchRoleRequest(state) {
      if (state.rolesListForUserManagement) {
        state.rolesListForUserManagement.loading = true;
      }
    },
    fetchRoleSuccess(state, {payload}: PayloadAction<Array<RoleForRoleManagement>>) {
      if (state.rolesListForUserManagement) {
        state.rolesListForUserManagement.loading = false;
      }
      if (state.rolesListForUserManagement) {
        state.rolesListForUserManagement.data = payload;
      }
    },
    fetchRoleError(state, {payload}: PayloadAction<AxiosError<ApiException>>) {
      const {response} = payload;
      const message = response?.data?.error?.message;
      if (state.rolesListForUserManagement) {
        state.rolesListForUserManagement.loading = false;
      }
    },
    fetchFullRoleRequest(state) {
      if (state.rolesListFull) {
        state.rolesListFull.loading = true;
      }
    },
    fetchFullRoleSuccess(state, {payload}: PayloadAction<Array<ShortRole>>) {
      if (state.rolesListFull) {
        state.rolesListFull.loading = false;
      }
      if (state.rolesListFull) {
        state.rolesListFull.data = payload;
      }
    },
    fetchFullRoleError(state, {payload}: PayloadAction<AxiosError<ApiException>>) {
      const {response} = payload;
      const message = response?.data?.error?.message;
      if (state.rolesListFull) {
        state.rolesListFull.loading = false;
      }
    },
    deleteRoleRequest(_state, _action: PayloadAction<DeleteRoleType>) {},
    deleteRoleSuccess(state, {payload}: PayloadAction<RoleIdType>) {
      const {roleId} = payload;
      const newRoleList = state?.rolesListForUserManagement?.data?.filter(
        (roleObject) => roleObject.role?.id !== roleId
      );
      if (state.rolesListForUserManagement) {
        state.rolesListForUserManagement.data = newRoleList;
      }
    },
    deleteRoleError(state, {payload}: PayloadAction<AxiosError<ApiException>>) {
      const {response} = payload;
      const message = response?.data?.error?.message || '';
      state.singleRole.deletion.error = message;
    },
    deleteRoleClearError(state) {
      state.singleRole.deletion.error = null;
    },
    updateSingleRoleAction(
      state,
      {payload: {permission, action, value}}: PayloadAction<TUpdateRolePermission>
    ) {
      state.singleRole.data.permissions[permission][action] = value;
    },
    updateSingleRolePermissionListAction(state, {payload}: PayloadAction<FullRole>) {
      state.singleRole.data = payload;
    },

    createRoleRequest(state, _action: PayloadAction<TCreateUserRoleAction>) {
      if (state.creatingUserRole) {
        state.creatingUserRole.loading = true;
      }
    },
    createRoleSuccess(state, {payload}: PayloadAction<NewRoleStateType>) {
      const {isSuccess} = payload;
      if (state.creatingUserRole) {
        state.creatingUserRole.isSuccess = isSuccess;
      }
      if (state.creatingUserRole) {
        state.creatingUserRole.loading = false;
      }
    },
  },
});

const {reducer, actions} = UserManagementSlice;

export const {
  patchUserSuccess,
  blockUserSuccess,
  unblockUserSuccess,
  fetchRoleRequest,
  fetchRoleSuccess,
  fetchRoleError,
  fetchFullRoleRequest,
  fetchFullRoleSuccess,
  fetchFullRoleError,

  createRoleRequest,
  createRoleSuccess,

  deleteRoleRequest,
  deleteRoleSuccess,
  deleteRoleClearError,

  updateSingleRoleAction,
  updateSingleRolePermissionListAction,
} = actions;

export const UserManagementReducer = reducer;

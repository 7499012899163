import {z} from 'zod';

import {
  AssignedMechanicSchema,
  BasePriceSchema,
  MechanicSchema,
  PriceWithAndWithoutVatSchema,
} from './aftersalesCommon';
import {BaseIdSchema, BaseVoidResponseSchema} from './base';

/**
 * Base
 */
const DocumentInformationSchema = z.object({
  customerId: z.string(),
  vehicleId: z.string(),
  number: z.string(),
  type: z.string(),
  variant: z.string(),
  serviceOrderVariantId: z.string(),
});

const PrintoutSchema = z.object({
  url: z.string(),
  pdfUrl: z.string(),
  fileId: z.string(),
  remoteFileId: z.string(),
});

export const ServiceOrderIssueNoteMaterialItemSchema = z.object({
  id: z.string(),
  warehouse: z.object({
    warehouseId: z.string(),
    warehouseArticleId: z.string(),
    storageLocation: z.string(),
    discountGroup: z.string(),
    warehouseAction: z.string(),
  }),
  name: z.string(),
  number: z.string(),
  quantity: z.object({
    amount: z.number(),
    unit: z.string(),
  }),
  isUnitPriceWithVat: z.boolean(),
  sellingPricePerUnit: BasePriceSchema,
  sellingPriceVat: z.object({
    type: z.string(),
    freeCode: z.string(),
  }),
  assignMechanic: z.string(),
  isCustomPrice: z.boolean(),
  baseSellingPricePerUnit: BasePriceSchema,
  baseSellingPriceVat: z.object({
    type: z.string(),
  }),
  isCustomDiscount: z.boolean(),
  discountRate: z.number(),
  discountSource: z.string(),
  isAllowedItemEditing: z.boolean(),
  isAllowedQuantityEditing: z.boolean(),
  sellingTotalPrice: PriceWithAndWithoutVatSchema,
  purchaseTotalPrice: PriceWithAndWithoutVatSchema,
  marginTotal: z.object({
    marginPercentage: z.number(),
    withVat: BasePriceSchema,
    withoutVat: BasePriceSchema,
  }),
  discount: BasePriceSchema,
});
export type ServiceOrderIssueNoteMaterialItem = z.infer<
  typeof ServiceOrderIssueNoteMaterialItemSchema
>;

/**
 * GET
 */
export const GetServiceOrderIssueNoteRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
});
export type GetServiceOrderIssueNoteRequest = z.infer<typeof GetServiceOrderIssueNoteRequestSchema>;

export const GetServiceOrderIssueNoteResponseSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
  state: z.enum(['PENDING', 'COMPLETED']),
  number: z.string(),
  serviceOrderId: z.string(),
  serviceCaseId: z.string(),
  mechanic: MechanicSchema.optional(),
  partsReady: z.boolean(),
  createdAt: z.string(),
  createdBy: z.string(),
  updatedAt: z.string().optional(),
  updatedBy: z.string().optional(),
  sparePartsTotalPrice: z.object({
    totalSellingPrice: PriceWithAndWithoutVatSchema,
    totalPurchasePrice: PriceWithAndWithoutVatSchema,
  }),
  serviceOrder: DocumentInformationSchema,
});
export type GetServiceOrderIssueNoteResponse = z.infer<
  typeof GetServiceOrderIssueNoteResponseSchema
>;

/**
 * POST
 */
export const PostServiceOrderIssueNoteRequestSchema = z.object({
  serviceOrderId: z.string(),
});
export type PostServiceOrderIssueNoteRequest = z.infer<
  typeof PostServiceOrderIssueNoteRequestSchema
>;

export const PostServiceOrderIssueNoteResponseSchema = BaseIdSchema;
export type PostServiceOrderIssueNoteResponse = z.infer<
  typeof PostServiceOrderIssueNoteResponseSchema
>;

/**
 * PATCH
 */
export const PatchServiceOrderIssueNoteRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
  body: z.object({
    serviceOrderId: z.string(),
    mechanic: AssignedMechanicSchema.optional(),
  }),
});
export type PatchServiceOrderIssueNoteRequest = z.infer<
  typeof PatchServiceOrderIssueNoteRequestSchema
>;

export const PatchServiceOrderIssueNoteResponseSchema = BaseVoidResponseSchema;
export type PatchServiceOrderIssueNoteResponse = z.infer<
  typeof PatchServiceOrderIssueNoteResponseSchema
>;

export const PatchServiceOrderIssueNotePartsReadyRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
  body: z.object({
    partsReady: z.boolean(),
  }),
});
export type PatchServiceOrderIssueNotePartsReadyRequest = z.infer<
  typeof PatchServiceOrderIssueNotePartsReadyRequestSchema
>;

export const PatchServiceOrderIssueNotePartsReadyResponseSchema = BaseVoidResponseSchema;
export type PatchServiceOrderIssueNotePartsReadyResponse = z.infer<
  typeof PatchServiceOrderIssueNotePartsReadyResponseSchema
>;

export const PatchServiceOrderIssueNoteCompleteRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
});
export type PatchServiceOrderIssueNoteCompleteRequest = z.infer<
  typeof PatchServiceOrderIssueNoteCompleteRequestSchema
>;

const IssueNoteCompletedSchema = z.object({
  id: z.string(),
  printout: PrintoutSchema,
});

const ServiceOrderIssueNoteCompletedSchema = z.object({
  id: z.string(),
  printout: PrintoutSchema,
});

export const PatchServiceOrderIssueNoteCompleteResponseSchema = z.object({
  issueNotes: z.array(IssueNoteCompletedSchema),
  serviceOrderIssueNote: ServiceOrderIssueNoteCompletedSchema,
});
export type PatchServiceOrderIssueNoteCompleteResponse = z.infer<
  typeof PatchServiceOrderIssueNoteCompleteResponseSchema
>;

/**
 * DELETE
 */
export const DeleteServiceOrderIssueNoteRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
});
export type DeleteServiceOrderIssueNoteRequest = z.infer<
  typeof DeleteServiceOrderIssueNoteRequestSchema
>;

export const DeleteServiceOrderIssueNoteResponseSchema = BaseVoidResponseSchema;
export type DeleteServiceOrderIssueNoteResponse = z.infer<
  typeof DeleteServiceOrderIssueNoteResponseSchema
>;

export const BulkDeleteServiceOrderIssueNoteItemsRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
  body: z.object({
    serviceOrderIssueNoteItemIds: z.array(z.string()),
  }),
});
export type BulkDeleteServiceOrderIssueNoteItemsRequest = z.infer<
  typeof BulkDeleteServiceOrderIssueNoteItemsRequestSchema
>;

export const BulkDeleteServiceOrderIssueNoteItemsResponseSchema = BaseVoidResponseSchema;
export type BulkDeleteServiceOrderIssueNoteItemsResponse = z.infer<
  typeof BulkDeleteServiceOrderIssueNoteItemsResponseSchema
>;

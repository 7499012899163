import {Grid, Clickable, Box} from 'platform/foundation';

import {CSSProperties, FC, use, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {suffixTestId, TestIdProps} from 'shared';

import {selectAuditAssets} from '../../../store/carAudit/selectors';
import {AuditDataAssetsFilesBody} from '../../../types/AuditDataAssetsFilesBody';
import {FormContext} from '../../FinalForm/FormContext';
import {AuditImages} from './AuditImages';
import {UploadContext} from './UploadContext';

export type CardImageUploadProps = {
  multiple?: boolean;
  paramDefinitionId: string;
  categoryId: string;
  columns?: number;
  damageId?: string;
  onUpload?: (asset: AuditDataAssetsFilesBody) => void;
  onDelete?: (imageId: string) => void;
  ratio?: CSSProperties['aspectRatio'];
  mandatory?: boolean;
  softDelete?: boolean;
  isAlwaysShowUpload?: boolean;
};

export const CardImageUpload: FC<CardImageUploadProps & TestIdProps> = ({
  multiple,
  categoryId,
  paramDefinitionId,
  columns,
  damageId,
  onUpload,
  onDelete,
  ratio,
  mandatory,
  softDelete,
  isAlwaysShowUpload,
  ...rest
}) => {
  const assets = useSelector(selectAuditAssets(categoryId, paramDefinitionId));
  const {submitCount} = use(FormContext);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (mandatory && submitCount > 0) {
      setHasError(!assets || !assets.length);
    }
  }, [submitCount]);

  return (
    <UploadContext
      {...{
        categoryId,
        paramDefinitionId,
        damageId,
        onUpload,
        onDelete,
        ratio,
        softDelete,
        type: UPLOAD_TYPE,
      }}
    >
      <Clickable
        data-testid={suffixTestId('cardUpload-wrapper', rest)}
        onContextMenu={(e) => e.preventDefault()}
      >
        <Box
          border={hasError ? '1px dashed' : undefined}
          borderColor={hasError ? 'severity.active.danger' : undefined}
          padding={hasError ? 2 : 0}
        >
          <Grid columns={columns ?? (multiple ? 8 : 1)}>
            <AuditImages
              isAlwaysShowUpload={isAlwaysShowUpload}
              isMultiple={multiple}
              ratio={ratio ?? '4 / 3'}
              data-testid={suffixTestId('cardUpload', rest)}
            />
          </Grid>
        </Box>
      </Clickable>
    </UploadContext>
  );
};

const UPLOAD_TYPE = 'card';

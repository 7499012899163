import {Card} from 'platform/components';
import {Box, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {RefObject, useCallback} from 'react';

import {mergeAll, path} from 'ramda';
import {isArray, isNilOrEmpty} from 'ramda-adjunct';

import {RequestItem} from '@dms/api';
import {warehouseRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions} from '@dms/shared';

import {composePath, RequiredTestIdProps, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, DataGridRef, QueryFilterObject} from 'features/datagrid';

interface DirectSaleMaterialReturnListProps extends RequiredTestIdProps {
  directSaleId: string;
  dataGridRef: RefObject<DataGridRef | null>;
  onAddToBasket: (selectedItems: RequestItem[]) => Promise<void>;
}

type DirectSaleMaterialReturnItemRow = {
  id: string;
  articleId: {value: string};
  warehouseId: {value: string};
  availableForReturnQuantity: {value: number};
  handlingUnit: {value: string};
  unitPriceWithoutVat: {value: {amount: number}};
  unitPriceWithVat: {value: {amount: number}};
};

export function DirectSaleMaterialReturnList(props: DirectSaleMaterialReturnListProps) {
  const actionCallback: ActionCallback = ({actionKey, rowData, refreshData}) => {
    match(actionKey)
      .with('add', () => {
        const castedRowData = (
          isArray(rowData) ? rowData : [rowData]
        ) as DirectSaleMaterialReturnItemRow[];

        const requestPayload: RequestItem[] = [];

        castedRowData.forEach((row) => {
          requestPayload.push({
            originEntityId: row.id,
            articleId: row.articleId.value,
            quantity: row.availableForReturnQuantity.value,
            handlingUnit: row.handlingUnit.value,
            priceUnitWithoutVat: row.unitPriceWithoutVat.value.amount,
            priceUnitWithVat: row.unitPriceWithVat.value.amount,
          });
        });

        return props.onAddToBasket(requestPayload).then(refreshData);
      })
      .with('details', () => {
        const articleId = path(['articleId', 'value'], rowData) as string;
        const warehouseId = path(['warehouseId', 'value'], rowData) as string;

        if (isNilOrEmpty(articleId)) {
          throw new Error('articleId is not defined');
        }

        if (isNilOrEmpty(warehouseId)) {
          throw new Error('warehouseId is not defined');
        }

        return window.open(
          composePath(warehouseRoutes.articleDetailOverview, {
            params: {
              id: articleId,
              warehouseId,
            },
          }),
          '_blank'
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {directSaleId: props.directSaleId}]),
    [props.directSaleId]
  );

  return (
    <Card isFullHeight data-testid={props['data-testid']}>
      <VStack spacing={4} height="100%">
        <Box flex={1}>
          <DataGrid
            ref={props.dataGridRef}
            gridCode="direct-sale-item-return"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            data-testid={suffixTestId('returnItems', props)}
          />
        </Box>
      </VStack>
    </Card>
  );
}

import {FC, useRef, use, useEffect, MutableRefObject} from 'react';

import {droppableContext} from './droppableContext';
import {DroppableDropContext} from './droppableDropContext';
import {DroppableProps} from './types';

const DroppableContextProvider = droppableContext.Provider;

export const Droppable: FC<DroppableProps> = ({children, droppableId}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {draggedItem, activeDroppable, setActiveDroppable, isHovered} = use(DroppableDropContext);

  const handleMouseMove = () => {
    setActiveDroppable(droppableId);
  };

  const handleMouseLeave = () => {
    setActiveDroppable(null);
  };

  useEffect(() => {
    const _wrapper = wrapperRef.current;

    if (draggedItem) {
      _wrapper?.addEventListener('mousemove', handleMouseMove);
      _wrapper?.addEventListener('mouseleave', handleMouseLeave);
    } else {
      setActiveDroppable(null);
    }

    return () => {
      _wrapper?.removeEventListener('mousemove', handleMouseMove);
      _wrapper?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [draggedItem]);

  return (
    <DroppableContextProvider value={{droppableId}}>
      {children(
        {ref: wrapperRef as MutableRefObject<HTMLDivElement>},
        {
          droppableId,
          draggedItem: draggedItem && {
            from: draggedItem.dropId as string,
            currentlyIn: activeDroppable as string,
          },
          isHoveringOverEmpty: !isHovered && droppableId === activeDroppable,
        }
      )}
    </DroppableContextProvider>
  );
};

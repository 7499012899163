import {
  closeCurrentDialog,
  closeDialog,
  Dropdown,
  DropdownDivider,
  DropdownGroup,
  DropdownItem,
  DropdownSubmenu,
  openDialog,
} from 'platform/components';
import {Icon} from 'platform/foundation';

import {useRef} from 'react';
import {Helmet} from 'react-helmet-async';

import {isNotNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {WarehouseArticlesRow, useDeleteArticleMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {
  Main,
  handleApiError,
  useWarehouseDataGridTreeFolder,
  ArticleCreate,
  getSingleOrUndefined,
} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

import {ArticleMoveToFolder} from '../../components/ArticleMoveToFolder';
import {FullscreenDataGridWrapper} from '../../components/FullscreenDataGridWrapper';
import {ArticleListExternalFilters} from './components/ArticleListExtrenalFilters';

const ARTICLES_EXTERNAL_FILTER_ID = 'articles-external-filter';

export function ArticleList() {
  const navigate = useNavigate();

  const dataGridFilters = useRef<Record<string, unknown>>({});

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
  const [folderTreeRef, dataGridModifier, activeFolderId] = useWarehouseDataGridTreeFolder();
  const [deleteArticle] = useDeleteArticleMutation();

  const handleMoveToFolder = (itemIds: string | string[]) => {
    openDialog(
      <ArticleMoveToFolder
        itemIds={itemIds}
        onClose={closeCurrentDialog}
        data-testid={testIds.warehouse.articleList('articleMoveDialog')}
      />,
      {
        size: 'small',
        title: i18n.t('entity.addWork.labels.moveToFolder'),
      }
    );
  };

  const handleNewArticle = () => {
    openDialog(
      <ArticleCreate
        defaultValues={{
          catalogNumber:
            'catalogNumber' in dataGridFilters.current
              ? (dataGridFilters.current.catalogNumber as string)
              : undefined,
          warehouseId:
            'warehouseId' in dataGridFilters.current
              ? getSingleOrUndefined(dataGridFilters.current.warehouseId)
              : undefined,
          manufacturerId:
            'manufacturerId' in dataGridFilters.current
              ? getSingleOrUndefined(dataGridFilters.current.manufacturerId)
              : undefined,
          name:
            'name' in dataGridFilters.current
              ? (dataGridFilters.current.name as string)
              : undefined,
          activeFolderId,
        }}
        onArticleCreate={(createdArticleData) => {
          if (createdArticleData.submittedButton === 'CREATE_AND_EDIT') {
            return navigate(
              composePath(warehouseRoutes.articleDetailOverview, {
                params: {
                  warehouseId: createdArticleData.formValues.warehouseId,
                  id: createdArticleData.articleId,
                },
              })
            );
          }

          closeCurrentDialog();
          refreshDataGrid();
        }}
        onClose={() => closeDialog('createNewArticleDialog')}
        hasCreateAndEditButton
        data-testid={testIds.warehouse.articleList('createNewArticle')}
      />,
      {
        id: 'createNewArticleDialog',
        title: i18n.t('general.labels.createNew'),
        scrollBehavior: 'outside',
        withAdditionalFooter: true,
      }
    );
  };

  const handleDeleteArticle = (itemIds: string | string[]) => {
    deleteArticle({articleId: isArray(itemIds) ? itemIds : [itemIds]})
      .unwrap()
      .then(refreshDataGrid)
      .catch(handleApiError);
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, sourceSearchParams}) => {
    switch (actionKey) {
      case 'redirectDetail':
        const castedRowData = (isArray(rowData) ? rowData[0] : rowData) as WarehouseArticlesRow;
        if (isNotNil(castedRowData?.warehouseId) && isNotNil(castedRowData?.id)) {
          navigate(
            composePath(warehouseRoutes.articleDetailOverview, {
              queryParams: sourceSearchParams,
              params: {
                id: castedRowData?.id,
                warehouseId: castedRowData?.warehouseId?.value?.key,
              },
              isSearchParamsPreserved: false,
            })
          );
        }
        break;
      case 'moveToFolder':
        handleMoveToFolder(rowId);
        break;
      case 'delete':
        handleDeleteArticle(rowId);
        break;
    }
  };

  return (
    <>
      <Helmet title={i18n.t('module.warehouse.title')} />
      <Main
        isFullHeight
        heading={i18n.t('entity.warehouse.labels.articles')}
        actions={[
          {
            type: 'button',
            title: i18n.t('page.warehouse.labels.newArticle'),
            onClick: handleNewArticle,
            'data-testid': testIds.warehouse.articleList('newArticle'),
          },
        ]}
      >
        <FullscreenDataGridWrapper
          externalFilterId={ARTICLES_EXTERNAL_FILTER_ID}
          folderTreeRef={folderTreeRef}
          data-testid={testIds.warehouse.articleList('externalFilterWithFolderTree')}
        >
          <div>
            <Dropdown dropdownControl={<div>Test dropdown</div>}>
              <>
                <DropdownGroup label="group a">
                  <DropdownItem leftIcon="flags/CZE" suffix={<>Label</>} label="item 1a" />
                  <DropdownItem label="item 2a" />
                  <DropdownItem label="item 3a" />
                </DropdownGroup>
                <DropdownDivider />
                <DropdownGroup label="group b">
                  <DropdownItem label="item 1b" />
                  <DropdownItem label="item 2b" />
                  <DropdownItem label="item 3b" />
                </DropdownGroup>
                <DropdownSubmenu
                  label="Item with submenu 1"
                  suffix={<Icon value="navigation/arrow_right" />}
                >
                  <DropdownItem label="Submenu item" />
                  <DropdownDivider />
                  <DropdownGroup label="Submenu group A">
                    <DropdownItem label="Submenu item 1A" />
                    <DropdownItem label="Submenu item 2A" />
                    <DropdownItem label="Sumbenu item 3A" />
                  </DropdownGroup>
                </DropdownSubmenu>
                <DropdownSubmenu
                  label="Item with submenu 42"
                  suffix={<Icon value="navigation/arrow_right" />}
                >
                  <DropdownItem label="Submenu item" />
                  <DropdownDivider />
                  <DropdownGroup label="Submenu group A">
                    <DropdownItem label="Submenu item 1A" />
                    <DropdownItem label="Submenu item 2A" />
                    <DropdownSubmenu
                      label="Item with submenu 1"
                      suffix={<Icon value="navigation/arrow_right" />}
                    >
                      <DropdownItem label="Submenu item" />
                      <DropdownDivider />
                      <DropdownGroup label="Submenu group A">
                        <DropdownItem label="Submenu item 1A" />
                        <DropdownItem label="Submenu item 2A" />
                        <DropdownSubmenu
                          label="Item with submenu 1"
                          suffix={<Icon value="navigation/arrow_right" />}
                        >
                          <DropdownItem label="Submenu item" />
                          <DropdownDivider />
                          <DropdownGroup label="Submenu group A">
                            <DropdownItem label="Submenu item 1A" />
                            <DropdownItem label="Submenu item 2A" />
                            <DropdownSubmenu
                              label="Item with submenu 1"
                              suffix={<Icon value="navigation/arrow_right" />}
                            >
                              <DropdownItem label="Submenu item" />
                              <DropdownDivider />
                              <DropdownGroup label="Submenu group A">
                                <DropdownItem label="Submenu item 1A" />
                                <DropdownItem label="Submenu item 2A" />
                                <DropdownSubmenu
                                  label="Item with submenu 1"
                                  suffix={<Icon value="navigation/arrow_right" />}
                                >
                                  <DropdownItem label="Submenu item" />
                                  <DropdownDivider />
                                  <DropdownGroup label="Submenu group A">
                                    <DropdownItem label="Submenu item 1A" />
                                    <DropdownItem label="Submenu item 2A" />
                                    <DropdownItem label="Sumbenu item 3A" />
                                  </DropdownGroup>
                                </DropdownSubmenu>
                              </DropdownGroup>
                            </DropdownSubmenu>
                          </DropdownGroup>
                        </DropdownSubmenu>
                      </DropdownGroup>
                    </DropdownSubmenu>
                  </DropdownGroup>
                </DropdownSubmenu>
              </>
            </Dropdown>
          </div>
          <DataGrid
            // DG must be re-rendered on folder change to update the query modifier
            // eslint-disable-next-line no-restricted-syntax
            key={dataGridModifier?.key}
            ref={dataGridRef}
            gridCode="warehouse-articles"
            actionCallback={actionCallback}
            onFilterChanged={(filters) => {
              dataGridFilters.current = filters;
            }}
            emptyState={{
              headline: i18n.t('page.warehouseDetail.labels.emptyAssortment'),
            }}
            queryModifier={dataGridModifier?.queryModifier}
            _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'CUSTOM'}}
            externalFilterId={ARTICLES_EXTERNAL_FILTER_ID}
            filterComponent={ArticleListExternalFilters}
            data-testid="warehouse-articles"
          />
        </FullscreenDataGridWrapper>
      </Main>
    </>
  );
}

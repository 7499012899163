export const ORDER_TABS = {
  JOBS: 'jobs',
  DETAIL: 'detail',
  DISCOUNTS: 'discounts',
  PROFITABILITY: 'profitability',
  ALL_ITEMS: 'allItems',
  DOCUMENTS: 'documents',
  COMMENTS_NOTES: 'commentsNotes',
  ACTIVITY_LOG: 'activityLog',
  CHECKOUT: 'checkout',
  CHECKOUT_V2: 'checkoutV2',
};

import {openConfirmDialog, showNotification} from 'platform/components';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';

import {is} from 'ramda';

import {useDeleteCommissionRuleMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const isString = is(String);

export function ComissionFee() {
  const navigate = useNavigate();

  const [deleteCommissionRule] = useDeleteCommissionRuleMutation();

  const handleDeleteCommissionRule = useCallback(
    (id: string, refreshData: () => void) => {
      deleteCommissionRule({id})
        .unwrap()
        .then(() => showNotification.success())
        .then(refreshData)
        .catch(handleApiError);
    },
    [deleteCommissionRule]
  );

  const actionCallbackV4: ActionCallback = useCallback(
    ({actionKey, rowId, refreshData}) => {
      if (!isString(rowId)) {
        return;
      }

      switch (actionKey) {
        case 'edit':
          navigate(
            composePath(settingsRoutes.businessCaseEditCommissionFee, {
              params: {
                id: String(rowId),
              },
            })
          );
          break;
        case 'delete':
          openConfirmDialog({
            onConfirm: () => handleDeleteCommissionRule(rowId, refreshData),
            text: i18n.t('general.actions.confirmDeleteQuestion'),
          });
          break;

        default:
          // TODO: Create warn utils T20-17310
          console.warn(`Action callback was not specified for action ${actionKey}`);
          break;
      }
    },
    [handleDeleteCommissionRule, navigate]
  );

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.salesSettingsBusinessCase.labels.commissionFeeTitle'),
        breadcrumbs: [
          {
            label: i18n.t('page.businessCases.title'),
            href: settingsRoutes.businessCase,
          },
        ],
        actions: [
          {
            type: 'button',
            variant: 'primary',
            title: i18n.t('entity.branch.actions.newCalculation'),
            onClick: () => navigate(settingsRoutes.businessCaseCreateCommissionFee),
            'data-testid': testIds.settings.businessCaseCommissionFee('new-commission-fee'),
          },
        ],
      }}
      data-testid="businessCases-comission"
      description={i18n.t('page.salesSettingsBusinessCase.labels.commissionFeeDescription')}
    >
      <Box height={100} width="100%">
        <DataGrid
          gridCode="sales_commission"
          data-testid={testIds.settings.businessCaseCommissionFee('businessCases-comission')}
          actionCallback={actionCallbackV4}
          emptyState={{
            headline: i18n.t('page.salesSettingsBusinessCase.labels.commissionEmptyMessage'),
          }}
        />
      </Box>
    </SettingsTemplate>
  );
}

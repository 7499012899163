import {ButtonGroup, closeCurrentDialog, DialogFooter} from 'platform/components';
import {Box, Heading, HStack, Icon, Text, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {suffixTestId} from 'shared';

import {CancelTransactionButton} from '../../components/CancelTransactionButton';
import {StepContentProps} from '../../types/StepContentProps';

export function SendToDeviceStep(props: StepContentProps) {
  return (
    <VStack spacing={4} align="center" justify="center" height="100%">
      <Icon value="action/send_to_mobile" size={20} color="general.white" />
      <Heading size={3}>{i18n.t('entity.document.labels.sentToDevice')}</Heading>

      <Box width={140}>
        <Text size="small" color="secondary" align="center">
          {i18n.t('entity.document.labels.sentToDeviceDescription')}
        </Text>
      </Box>

      <DialogFooter>
        <HStack justify="space-between">
          <CancelTransactionButton
            transactionId={props.transaction?.transactionId}
            refreshData={props.refreshData}
            data-testid={suffixTestId('seller', props)}
          />
          <ButtonGroup
            align="right"
            buttons={[
              {
                title: i18n.t('entity.document.actions.useQrCodeInstead'),
                variant: 'secondary',
                onClick: () => props.setSigningStep('OnSite_qrCode'),
                'data-testid': suffixTestId('useQrCodeInstead', props),
              },
              {
                title: i18n.t('general.actions.finish'),
                onClick: closeCurrentDialog,
                'data-testid': suffixTestId('finish', props),
              },
            ]}
          />
        </HStack>
      </DialogFooter>
    </VStack>
  );
}

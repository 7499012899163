import {useState} from 'react';

import {EditTireSet} from '@dms/shared';

import {RequiredTestIdProps} from 'shared';

import {TireSetForm} from './TireSetForm';

interface CreateTireSetProps extends RequiredTestIdProps {
  tireOrderId: string;
  onClose: () => void;
  onCreate: () => void;
}

export function CreateTireSet(props: CreateTireSetProps) {
  const [newTireSetId, setTireSetId] = useState<string | undefined>();

  const handleOnCreate = (setId: string) => {
    setTireSetId(setId);
    props.onCreate();
  };

  return newTireSetId ? (
    <EditTireSet
      tireOrderId={props.tireOrderId}
      setId={newTireSetId}
      onClose={props.onClose}
      onEdit={props.onCreate}
      data-testid={props['data-testid']}
    />
  ) : (
    <TireSetForm
      onClose={props.onClose}
      onCreate={handleOnCreate}
      tireOrderId={props.tireOrderId}
      data-testid={props['data-testid']}
    />
  );
}

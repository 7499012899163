import {Box} from 'platform/foundation';

import {forwardRef, Ref} from 'react';

import {ICellRendererParams} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {MasterDetailRenderer} from '../types/MasterDetailRenderer';

const detailRenderer = {
  height: 'initial',
  background: 'white',
  padding: '4',
  cursor: 'auto',
};

export interface DetailRendererWrapperProps extends ICellRendererParams {
  gridProps: DataGridProps;
  component: MasterDetailRenderer;
}

export const DetailRendererWrapper = forwardRef<HTMLDivElement, DetailRendererWrapperProps>(
  DetailRendererWrapperComponent
);
function DetailRendererWrapperComponent(
  {node, gridProps, component: MasterDetailComponent, pinned}: DetailRendererWrapperProps,
  ref: Ref<HTMLDivElement>
) {
  const rowData = node.data;

  if (pinned) {
    return null;
  }

  return (
    <Box ref={ref} css={detailRenderer}>
      <div>
        <MasterDetailComponent rowData={rowData} gridProps={gridProps} />
      </div>
    </Box>
  );
}

import {motion} from 'framer-motion';
import {DataStatus} from 'platform/components';
import {HStack, Space, VStack} from 'platform/foundation';

import {useGetSignableDocumentListQuery} from '@dms/api';

import {DocumentLabel} from '../../components/DocumentLabel';
import {PreviewFileIcon} from '../../components/PreviewFileIcon';
import {useDocumentSelection} from '../../contexts/DocumentSelectionProvider';

export function OnSiteDocumentMenu() {
  const {signatureDocumentFileIds} = useDocumentSelection();

  const {
    data: activeTransactionFiles,
    isLoading: isActiveTransactionFilesLoading,
    isError: isActiveTransactionFilesError,
  } = useGetSignableDocumentListQuery(
    {
      files: signatureDocumentFileIds,
    },
    {refetchOnMountOrArgChange: true}
  );

  return (
    <DataStatus
      isLoading={isActiveTransactionFilesLoading}
      minHeight="100%"
      isError={isActiveTransactionFilesError}
    >
      <motion.div
        initial={{x: 200, opacity: 0}}
        animate={{x: 0, opacity: 1}}
        transition={{
          duration: 0.3,
        }}
      >
        <VStack>
          {activeTransactionFiles?.map((document) => (
            <HStack key={document.sourceFileId} align="center">
              <PreviewFileIcon fileId={document.sourceFileId} />

              <Space horizontal={2} />

              <DocumentLabel document={document} />
            </HStack>
          ))}
        </VStack>
      </motion.div>
    </DataStatus>
  );
}

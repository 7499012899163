import {CustomFilterProps} from 'ag-grid-react';
import {Checkboxes, Chips, Separator} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {intersection} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useChoiceFilter} from '../../hooks/useChoiceFilter';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {QuickFilters} from '../../types/Api';

type Option = {value: string; label: string};

export type CheckboxFilterProps = {
  options: Array<Option>;
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function CheckboxFilter(props: CheckboxFilterProps) {
  const [values, setValues] = useChoiceFilter(props);
  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue: setValues,
    defaultValue: null,
  });

  const isQuickFilterValue = intersection(isArray(values) ? values : [values], quickFiltersConst);

  return (
    <VStack>
      {props.quickFilters && props.quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={props.isDisabled}
              value={isQuickFilterValue}
              options={props.quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('checkbox-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Checkboxes
        options={props.options}
        value={isArray(values) ? values : null}
        onChange={setValues}
        isDisabled={props.isDisabled}
        checkedIcon="navigation/check"
        data-testid={suffixTestId('checkbox-filter', props)}
      />
    </VStack>
  );
}

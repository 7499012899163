import {Icon, Text} from 'platform/foundation';

import {
  FC,
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {useOutsideClick} from 'shared';

import {noop} from '../../utils/someTeasUtils';
import {Option} from './Option';
import {Popper} from './Popper';
import {IconWrapper, OptionsWrapper, Spacer, StyledPopper} from './styles';
import {AnchorContextMenuProps, ContextMenuProps, OptionRendererProps} from './types';

export const ContextMenu: FC<PropsWithChildren<ContextMenuProps>> = ({
  position,
  options,
  open,
  onClose = noop,
  children,
}) => {
  const childrenRef = useRef<HTMLDivElement>(null);
  const contextOptionsRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const ContextOption = Option;
  const ContextOptionsWrapper = OptionsWrapper;

  const handleClose = useCallback(() => {
    if (open) {
      onClose();
    }
  }, [onClose, open]);

  const handleCloseOnAction = () => {
    handleClose();
  };

  useEffect(() => {
    if (open && position) {
      const el = document.createElement('div');
      el.style.cssText = `position: fixed; top: ${position.top}px; left: ${position.left}px;`;

      document.body.append(el);

      setAnchorEl(el);

      return () => {
        el.remove();
        setAnchorEl(null);
      };
    } else if (isNotNil(children)) {
      if (open) {
        setAnchorEl(childrenRef.current);
      } else {
        setAnchorEl(null);
      }
    }

    return () => null;
    // anchorEl in dep causes infinite loop
  }, [children, open, position]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [open, handleClose]);

  useOutsideClick({
    ref: contextOptionsRef,
    handler: () => {
      handleClose();
    },
  });

  return (
    <>
      {Boolean(children) && <div ref={childrenRef}>{children}</div>}
      {Boolean(anchorEl) && (
        <StyledPopper
          data-testid="context-menu-popper"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onContextMenu={(e: any) => e.preventDefault()}
          open={Boolean(open)}
          disablePortal
          anchorEl={anchorEl}
          placement="bottom-start"
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport',
            },
          }}
        >
          {open && (
            <ContextOptionsWrapper
              ref={contextOptionsRef}
              data-testid="context-menu-options-wrapper"
            >
              <>
                {options.map((opt, index) => (
                  <AnchorContextMenu
                    key={index}
                    id={index}
                    onClose={handleCloseOnAction}
                    options={opt.children ?? undefined}
                    ContextOptionsWrapper={ContextOptionsWrapper}
                    ContextOption={ContextOption}
                  >
                    {(active) => (
                      <OptionRenderer
                        id={index}
                        active={Boolean(active)}
                        onClose={handleCloseOnAction}
                        option={opt}
                        ContextOption={ContextOption}
                      />
                    )}
                  </AnchorContextMenu>
                ))}
              </>
            </ContextOptionsWrapper>
          )}
        </StyledPopper>
      )}
    </>
  );
};

const AnchorContextMenu: FC<AnchorContextMenuProps> = ({
  preferredSide = 'right',
  children,
  options,
  onClose,
  ContextOptionsWrapper,
  ContextOption,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const mounted = useRef<boolean>(false);

  const [anchorPosition, setAnchorPosition] = useState<DOMRect>();
  const [contentWrapperPosition, setContentWrapperPosition] = useState<DOMRect>();

  const anchorRef = useRef<EventTarget & HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    anchorRef.current = null;

    setTimeout(() => {
      if (!anchorRef.current && mounted.current) {
        setIsOpen(false);
      }
    }, 150);
  };

  const handleOpen = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    if (options?.length) {
      anchorRef.current = e.currentTarget;
      setIsOpen(true);
    }
  };

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (anchorRef.current && isOpen) {
      setAnchorPosition(anchorRef.current.getBoundingClientRect());
    } else {
      setAnchorPosition(undefined);
    }

    if (contentWrapperRef.current && isOpen) {
      setContentWrapperPosition(contentWrapperRef.current.getBoundingClientRect());
    } else {
      setContentWrapperPosition(undefined);
    }
  }, [isOpen]);

  const placement = useMemo(() => {
    if (preferredSide === 'right') {
      return 'right-start';
    }
    return 'left-start';
  }, [preferredSide]);

  const getPreferredSide = useCallback((): AnchorContextMenuProps['preferredSide'] => {
    if (contentWrapperPosition && anchorPosition) {
      if (contentWrapperPosition?.left < anchorPosition.left) {
        return 'left';
      }
      return 'right';
    }

    return 'right';
  }, [anchorPosition, contentWrapperPosition]);

  return (
    <div onMouseLeave={handleClose} onMouseEnter={handleOpen}>
      {children(isOpen)}

      <Popper
        ref={contentWrapperRef}
        open={isOpen}
        disablePortal
        anchorEl={anchorRef.current}
        placement={placement}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
      >
        <ContextOptionsWrapper data-testid={`context-menu-options-wrapper-${rest.id}`}>
          {options?.map((opt, index) => (
            <AnchorContextMenu
              id={`${rest.id}-${index}`}
              key={`${rest.id}-${index}`}
              preferredSide={getPreferredSide()}
              onClose={onClose}
              options={opt.children ?? undefined}
              ContextOptionsWrapper={ContextOptionsWrapper}
              ContextOption={ContextOption}
            >
              {(active) => (
                <OptionRenderer
                  id={`${rest.id}-${index}`}
                  active={active}
                  onClose={onClose}
                  option={opt}
                  ContextOption={ContextOption}
                />
              )}
            </AnchorContextMenu>
          ))}
        </ContextOptionsWrapper>
      </Popper>
    </div>
  );
};

const OptionRenderer: FC<OptionRendererProps> = ({active, onClose, option, id, ContextOption}) => (
  <ContextOption
    data-testid={`context-menu-option-${id}`}
    active={active}
    onClick={(e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (option.action) {
        option.action();

        onClose();
      }
    }}
  >
    {isNotNil(option.Icon) && (
      <IconWrapper data-testid={`context-menu-option-${id}-icon`}>{option.Icon}</IconWrapper>
    )}
    <Text size="small" color="white" data-testid={`context-menu-option-${id}-content`}>
      {option.content}
    </Text>
    <Spacer />
    {!!option.children?.length && (
      <Icon value="navigation/chevron_right" data-testid={`context-menu-option-${id}-more-icon`} />
    )}
  </ContextOption>
);

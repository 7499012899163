import {setHours, setMinutes} from 'date-fns';
import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormSubmitHandler,
  FormField,
  DataStatus,
  getTimeFromDate,
  openConfirmDialog,
} from 'platform/components';
import {Box, Grid, HStack, Show, VStack} from 'platform/foundation';
import {object} from 'yup';

import {
  useGetCurrentUserInfoQuery,
  useGetTireSetRequestQuery,
  usePatchTireSetRequestMutation,
  usePostTireSetCancelRequestMutation,
  usePostTireSetRequestMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {
  getApiDateTimeString,
  parseDate,
  suffixTestId,
  TestIdProps,
  yupDate,
  yupString,
} from 'shared';

import {handleApiError} from '../../utils/handleApiError';

type RequestTireSetFormType = {
  requestedBranchId: string;
  neededAtDate: Date;
  neededAtTime: string;
  note: string;
};

interface RequestTireSetFormProps extends TestIdProps {
  setId: string;
  orderId: string;
  onSave?: VoidFunction;
  onClose: VoidFunction;
}

export function RequestTireSetForm(props: RequestTireSetFormProps) {
  const {data: currentUser, isLoading: isCurrentUserLoading} = useGetCurrentUserInfoQuery();
  const {data, isLoading, isError} = useGetTireSetRequestQuery({
    orderId: props.orderId,
    setId: props.setId,
  });
  const [postTireSetRequest] = usePostTireSetRequestMutation();
  const [patchTireSetRequest] = usePatchTireSetRequestMutation();
  const [postTireSetCancelRequest] = usePostTireSetCancelRequestMutation();

  const currentUserBranchesOptions = (currentUser?.branches ?? []).map((branch) => ({
    label: branch.name,
    value: branch.id,
  }));

  const handleSubmit: FormSubmitHandler<RequestTireSetFormType> = async (formData) => {
    const {neededAtDate, neededAtTime, ...rest} = formData;

    const [hours, minutes] = neededAtTime.split(':');

    const neededAtDateWithHours = setHours(neededAtDate, parseInt(hours));
    const neededAtDateTime = setMinutes(neededAtDateWithHours, parseInt(minutes));
    const neededAt = getApiDateTimeString(neededAtDateTime);

    const requestData = {
      orderId: props.orderId,
      setId: props.setId,
      body: {...rest, neededAt},
    };

    await (data ? patchTireSetRequest(requestData) : postTireSetRequest(requestData))
      .unwrap()
      .then(() => props.onClose())
      .then(() => props.onSave?.())
      .catch(handleApiError);
  };

  const handleCancelRequest = () => {
    openConfirmDialog({
      text: i18n.t('entity.tireSet.labels.cancelTireRequestQuestion'),
      onConfirm: () =>
        postTireSetCancelRequest({orderId: props.orderId, setId: props.setId})
          .unwrap()
          .then(() => props.onClose())
          .then(() => props.onSave?.())
          .catch(handleApiError),
    });
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<RequestTireSetFormType>
        schema={formSchema}
        onSubmit={handleSubmit}
        defaultValues={{
          requestedBranchId: data?.requestBranchId,
          note: data?.note,
          neededAtTime: data?.neededAt && getTimeFromDate(parseDate(data.neededAt)),
          neededAtDate: data?.neededAt && parseDate(data.neededAt),
        }}
      >
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              label={i18n.t('entity.tiresInventory.labels.branch')}
              name="requestedBranchId"
              type="choice"
              options={currentUserBranchesOptions}
              isLoading={isCurrentUserLoading}
              isRequired
              data-testid={suffixTestId('requestedBranchId', props)}
            />
            <Grid columns={2}>
              <FormField
                control={control}
                label={i18n.t('entity.warehouse.labels.neededAt')}
                name="neededAtDate"
                type="date"
                isRequired
                data-testid={suffixTestId('neededAtDate', props)}
              />
              <Box paddingTop={5}>
                <FormField
                  control={control}
                  name="neededAtTime"
                  type="time"
                  isRequired
                  data-testid={suffixTestId('neededAtTime', props)}
                />
              </Box>
            </Grid>
            <FormField
              control={control}
              label={i18n.t('general.labels.note')}
              name="note"
              type="textarea"
              data-testid={suffixTestId('note', props)}
            />
            <HStack justify={data ? 'space-between' : 'flex-end'}>
              <Show when={data}>
                <Button
                  title={i18n.t('entity.tireSet.actions.cancelRequest')}
                  variant="dangerLink"
                  leftIcon="navigation/cancel"
                  onClick={handleCancelRequest}
                  data-testid={suffixTestId('discardButton', props)}
                />
              </Show>
              <ButtonGroup>
                <Button
                  title={i18n.t('general.actions.discard')}
                  variant="secondary"
                  onClick={props.onClose}
                  data-testid={suffixTestId('discardButton', props)}
                />
                <FormButton
                  control={control}
                  type="submit"
                  title={data ? i18n.t('general.actions.save') : i18n.t('general.actions.confirm')}
                  data-testid={suffixTestId('saveButton', props)}
                />
              </ButtonGroup>
            </HStack>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  requestedBranchId: yupString.required(),
  neededAtDate: yupDate.required(),
  neededAtTime: yupString.required(),
  note: yupString,
});

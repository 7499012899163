import {useState} from 'react';

export const useBooleanByChance = (probability = 0.5) => {
  const [result] = useState(() => {
    const parsed = Number(probability);
    const clampedProbability = Math.min(1, Math.max(0, isNaN(parsed) ? 0.5 : parsed));
    const chance = Math.random();
    return chance < clampedProbability;
  });
  return result;
};

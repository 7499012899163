import {z} from 'zod';

import {UnionToSameValueObject} from 'shared';

import {AbortSignalSchema, BaseVoidResponseSchema, CreatedAndUpdatedBySchema} from './base';

/**
 * Common
 */
export const WarehouseArticlesRowSchema = z.object({
  id: z.string(),
  warehouseId: z.object({
    value: z.object({
      key: z.string(),
      label: z.string(),
    }),
  }),
});

export type WarehouseArticlesRow = z.infer<typeof WarehouseArticlesRowSchema>;

export const SalesPriceCalculationSchema = z.enum([
  'MARGIN_ON_BASE',
  'MARKUP_ON_BASE',
  'PROFIT_ON_BASE',
  'MANUAL',
  'RECOMMENDED_PRICE',
  'MARKETING_CODE',
]);

export type SalesPriceCalculation = z.infer<typeof SalesPriceCalculationSchema>;

export type SalesPriceCalculationObject = UnionToSameValueObject<SalesPriceCalculation>;

export const salesPriceCalculationObject: SalesPriceCalculationObject = {
  MARGIN_ON_BASE: 'MARGIN_ON_BASE',
  MARKUP_ON_BASE: 'MARKUP_ON_BASE',
  PROFIT_ON_BASE: 'PROFIT_ON_BASE',
  MANUAL: 'MANUAL',
  RECOMMENDED_PRICE: 'RECOMMENDED_PRICE',
  MARKETING_CODE: 'MARKETING_CODE',
};

export const BasePriceSourceSchema = z.enum([
  'AVG_PURCHASE',
  'LAST_PURCHASE',
  'RECOMMENDED',
  'MANUAL',
]);

export type BasePriceSource = z.infer<typeof BasePriceSourceSchema>;
export type BasePriceSourceObject = UnionToSameValueObject<BasePriceSource>;
export const basePriceSourceObject: BasePriceSourceObject = {
  AVG_PURCHASE: 'AVG_PURCHASE',
  LAST_PURCHASE: 'LAST_PURCHASE',
  RECOMMENDED: 'RECOMMENDED',
  MANUAL: 'MANUAL',
};

export const DispensingPricesSchema = z.object({
  basePriceWithoutVat: z.number().optional(),
  basePriceWithVat: z.number().optional(),
  saleBasePriceWithoutVat: z.number().optional(),
  saleBasePriceWithVat: z.number().optional(),
  saleBaseProfit: z.number().nullish(),
  saleBaseMarginPercent: z.number().nullish(),
  saleBaseMarkUpPercent: z.number().nullish(),
  warrantyPriceWithoutVat: z.number().optional(),
  warrantyPriceWithVat: z.number().optional(),
  warrantyProfit: z.number().optional(),
  warrantyMarginPercent: z.number().optional(),
  warrantyMarkUpPercent: z.number().optional(),
});
export type DispensingPrices = z.infer<typeof DispensingPricesSchema>;

export const PricesSettingsSchema = z.object({
  avgPurchasePriceWithoutVat: z.number().optional(),
  avgPurchasePriceWithVat: z.number().optional(),
  lastPurchasePriceWithoutVat: z.number().optional(),
  lastPurchasePriceWithVat: z.number().optional(),
  recommendedPurchasePriceWithoutVat: z.number().optional(),
  recommendedPurchasePriceWithVat: z.number().optional(),
  salesPriceCalculation: SalesPriceCalculationSchema,
  basePriceSource: BasePriceSourceSchema,
  unitSalesPriceWithVat: z.boolean(),
});
export type PricesSettings = z.infer<typeof PricesSettingsSchema>;

export const ArticleTreeFolderSchema = z.object({
  leafId: z.string().nullable(),
});
export type ArticleTreeFolder = z.infer<typeof ArticleTreeFolderSchema>;

export const BaseArticleSchema = z.object({
  id: z.string(),
  warehouseId: z.string(),
  supplierId: z.string().optional(),
  manufacturerId: z.string(),
  makeCode: z.string().optional(),
  name: z.string(),
  description: z.string().optional(),
  stockNumber: z.string().optional(),
  manufacturerNumber: z.string(),
  manufacturerName: z.string().optional(),
  handlingUnit: z.string(),
  dispensingUnit: z.number().nullable(),
  receivedHandlingQuantity: z.number().optional(),
  vatType: z.string().optional(),
  storageLocation: z.string().optional(),
  discountGroup: z.string().optional(),
  treeFolder: ArticleTreeFolderSchema,
  marketingCode: z.string().optional(),
  warehouseAccount: z.string().optional(),
  pricesSettings: PricesSettingsSchema.partial(),
  dispensingPrices: DispensingPricesSchema.partial(),
  isTemporary: z.boolean().optional(), // exception where bool could be undefined,
  isRemovable: z.boolean(),
});
export type BaseArticle = z.infer<typeof BaseArticleSchema>;

/**
 * GET
 */
export const GetArticleRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
});
export type GetArticleRequest = z.infer<typeof GetArticleRequestSchema>;

export const GetArticleResponseSchema = BaseArticleSchema.merge(CreatedAndUpdatedBySchema);
export type GetArticleResponse = z.infer<typeof GetArticleResponseSchema>;

export const GetArticleIdByManufacturerNumberAndManufacturerIdRequestSchema = z.object({
  warehouseId: z.string(),
  manufacturerNumber: z.string(),
  manufacturerId: z.string(),
});
export type GetArticleIdByManufacturerNumberAndManufacturerIdRequest = z.infer<
  typeof GetArticleIdByManufacturerNumberAndManufacturerIdRequestSchema
>;

export const GetArticleIdByManufacturerNumberAndManufacturerIdResponseSchema = z
  .object({
    articleId: z.string(),
  })
  .nullable();
export type GetArticleIdByManufacturerNumberAndManufacturerIdResponse = z.infer<
  typeof GetArticleIdByManufacturerNumberAndManufacturerIdResponseSchema
>;

export const GetSupplierArticleRequestSchema = z.object({
  supplierArticleId: z.string(),
});
export type GetSupplierArticleRequest = z.infer<typeof GetSupplierArticleRequestSchema>;

export const GetSupplierArticleResponseSchema = z.object({
  supplierArticleId: z.string(),
  supplierId: z.string(),
  supplierName: z.string().optional(),
  supplierOrderingNumber: z.string().optional(),
  articleId: z.string().optional(),
  articleName: z.string().optional(),
  manufacturerNumber: z.string().optional(),
  manufacturerName: z.string().optional(),
  supplierQuantity: z.number(),
  supplierUnitId: z.string(),
  warehouseQuantity: z.number(),
  warehouseUnitId: z.string(),
  recommendedPriceWithoutVat: z.number().optional(),
  purchasePriceWithoutVat: z.number().optional(),
  supplierBulkPackageQuantity: z.number().optional(),
  currency: z.string().optional(),
});
export type GetSupplierArticleResponse = z.infer<typeof GetSupplierArticleResponseSchema>;

/**
 * POST
 */
export const PostArticleRequestSchema = z.object({
  warehouseId: z.string(),
  body: z.object({
    supplierId: z.string().optional(),
    manufacturerId: z.string(),
    makeCode: z.string().optional(),
    name: z.string(),
    description: z.string().optional(),
    stockNumber: z.string().optional(),
    manufacturerNumber: z.string(),
    handlingUnit: z.string(),
    dispensingUnit: z.number().optional(),
    vatType: z.string().optional(),
    storageLocation: z.string().optional(),
    discountGroup: z.string().optional(),
    treeFolder: ArticleTreeFolderSchema,
    marketingCode: z.string().optional(),
    warehouseAccount: z.string().optional(),
    pricesSettings: PricesSettingsSchema.optional(),
    dispensingPrices: DispensingPricesSchema.optional(),
  }),
});
export type PostArticleRequest = z.infer<typeof PostArticleRequestSchema>;

export const PostArticleResponseSchema = z.object({
  articleId: z.string(),
});
export type PostArticleResponse = z.infer<typeof PostArticleResponseSchema>;

/**
 * PATCH
 */
export const PatchArticleRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
  body: z.object({
    warehouseId: z.string(),
    supplierId: z.string().optional(),
    manufacturerId: z.string(),
    makeCode: z.string().optional(),
    name: z.string(),
    description: z.string().optional(),
    stockNumber: z.string().optional(),
    manufacturerNumber: z.string(),
    handlingUnit: z.string(),
    dispensingUnit: z.number().nullable(),
    vatType: z.string().optional(),
    storageLocation: z.string().optional(),
    discountGroup: z.string().optional(),
    treeFolder: ArticleTreeFolderSchema,
    marketingCode: z.string().optional(),
    warehouseAccount: z.string().optional(),
    pricesSettings: PricesSettingsSchema.partial(),
    dispensingPrices: DispensingPricesSchema.partial(),
  }),
});
export type PatchArticleRequest = z.infer<typeof PatchArticleRequestSchema>;

export const PatchArticleResponseSchema = BaseVoidResponseSchema;
export type PatchArticleResponse = z.infer<typeof PatchArticleResponseSchema>;

export const PatchArticleDefaultSupplierRequestSchema = z.object({
  articleId: z.string(),
  body: z.object({
    supplierId: z.string(),
  }),
});
export type PatchArticleDefaultSupplierRequest = z.infer<
  typeof PatchArticleDefaultSupplierRequestSchema
>;

export const PatchArticleDefatilSupplierResponseSchema = BaseVoidResponseSchema;
export type PatchArticleDefaultSupplierResponse = z.infer<
  typeof PatchArticleDefatilSupplierResponseSchema
>;

export const PatchArticleInfoRequestSchema = z.object({
  articleId: z.string(),
  body: z.object({
    name: z.string(),
  }),
});
export type PatchArticleInfoRequest = z.infer<typeof PatchArticleInfoRequestSchema>;

export const PatchArticleInfoResponseSchema = BaseVoidResponseSchema;
export type PatchArticleInfoResponse = z.infer<typeof PatchArticleInfoResponseSchema>;

export const PatchSupplierArticleRequestSchema = z.object({
  supplierArticleId: z.string(),
  body: z.object({
    articleId: z.string(),
    manufacturerNumber: z.string(),
    supplierArticle: z.object({
      supplierQuantity: z.number(),
      supplierUnitId: z.string(),
      warehouseQuantity: z.number(),
      supplierOrderingNumber: z.string().optional(),
      supplierBulkPackageQuantity: z.number().optional(),
      supplierId: z.string(),
    }),
  }),
});
export type PatchSupplierArticleRequest = z.infer<typeof PatchSupplierArticleRequestSchema>;

export const PatchSupplierArticleResponseSchema = BaseVoidResponseSchema;
export type PatchSupplierArticleResponse = z.infer<typeof PatchSupplierArticleResponseSchema>;

/**
 * DELETE
 */
export const DeleteArticleRequestSchema = z.object({
  articleId: z.array(z.string()),
});
export type DeleteArticleRequest = z.infer<typeof DeleteArticleRequestSchema>;

export const DeleteArticleResponseSchema = BaseVoidResponseSchema;
export type DeleteArticleResponse = z.infer<typeof DeleteArticleResponseSchema>;

/**
 * PUT
 */
// TODO https://carvago.atlassian.net/browse/T20-57859
export const BaseRecalculationSchema = z.object({
  supplierId: z.string(),
  manufacturerId: z.string(),
  makeCode: z.string(),
  name: z.string(),
  description: z.string(),
  stockNumber: z.string(),
  manufacturerNumber: z.string(),
  manufacturerName: z.string(),
  handlingUnit: z.string(),
  dispensingUnit: z.number().nullable(),
  vatType: z.string(),
  storageLocation: z.string(),
  discountGroup: z.string(),
  marketingCode: z.string(),
  warehouseAccount: z.string(),
  treeFolder: ArticleTreeFolderSchema,
  pricesSettings: PricesSettingsSchema.partial(),
  dispensingPrices: DispensingPricesSchema.partial(),
});
export type BaseRecalculation = z.infer<typeof BaseRecalculationSchema>;

const ChangedFieldValueSchema = z.object({
  field: z.enum([
    'vatType',
    'supplierId',
    'warehouseAccount',
    'marketingCode',
    'recommendedPriceWithOutVat',
    'recommendedPriceWithVat',
    'saleBasePriceWithoutVat',
    'saleBasePriceWithVat',
    'saleBaseMarkUpPercent',
    'saleBaseProfit',
    'saleBaseMarginPercent',
    'warrantyPriceWithoutVat',
    'warrantyPriceWithVat',
    'warrantyMarkUpPercent',
    'warrantyProfit',
    'warrantyMarginPercent',
    'purchasePrice',
  ]),
  value: z.string(),
});
export type ChangedFieldValue = z.infer<typeof ChangedFieldValueSchema>;

export const PutArticleCalculationRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
  body: z.object({
    changedFieldValue: ChangedFieldValueSchema,
    currentFeFieldsValues: BaseRecalculationSchema.partial(),
  }),
  signal: AbortSignalSchema,
});
export type PutArticleCalculationRequest = z.infer<typeof PutArticleCalculationRequestSchema>;

export const PutArticleCalculationResponseSchema = z.object({
  currency: z.string(),
  pricesSettings: PricesSettingsSchema,
  dispensingPrices: DispensingPricesSchema,
});
export type PutArticleCalculationResponse = z.infer<typeof PutArticleCalculationResponseSchema>;

export const PutSupplierArticlePromptValidationRequestSchema = z.object({
  supplierId: z.string(),
  articleId: z.string(),
  manufacturerNumber: z.string(),
  supplierOrderingNumber: z.string().optional(),
  supplierToWarehouseMapping: z.object({
    supplierQuantity: z.number(),
    supplierUnitId: z.string(),
    supplierBulkPackageQuantity: z.number().optional(),
    warehouseQuantity: z.number(),
  }),
});
export type PutSupplierArticlePromptValidationRequest = z.infer<
  typeof PutSupplierArticlePromptValidationRequestSchema
>;

export const PutSupplierArticlePromptValidationResponseSchema = z.object({
  isChanged: z.boolean(),
});
export type PutSupplierArticlePromptValidationResponse = z.infer<
  typeof PutSupplierArticlePromptValidationResponseSchema
>;

import {openDialog} from 'platform/components';

import {SalePresetUpdateInfoDialog, ShowSalePresetSuccess} from './SalePresetUpdateInfoDialog';

interface OpenSalePresetUpdateInfoDialogProps extends ShowSalePresetSuccess {
  onCloseComplete?: () => void;
}

export const openSalePresetUpdateInfoDialog = (data: OpenSalePresetUpdateInfoDialogProps) => {
  openDialog(<SalePresetUpdateInfoDialog salePresetId={data.salePresetId} />, {
    id: 'salePresetUpdateInfoDialog',
    'data-testid': 'salePresetUpdateInfoDialog',
    onCloseComplete: data?.onCloseComplete,
  });
};

import {Checkbox, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {memo} from 'react';

import {isEmpty} from 'ramda';

import {Makes} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useMakeModelFilter} from '../../../hooks/useMakeModelFilter';

export function MakeCheckboxList(props: TestIdProps) {
  const {makes, allSelectedMakes, selectMake, unselectMake, isLoading} = useMakeModelFilter();

  const handleToggleMake = (make: string, isSelected: boolean) => {
    isSelected ? selectMake(make) : unselectMake(make);
  };

  return (
    <DataStatus
      isLoading={isLoading}
      isEmpty={isEmpty(makes)}
      emptyMessage={i18n.t('page.filters.labels.noMakes')}
    >
      <VStack spacing={2}>
        <MemoizedCheckboxList
          makes={makes}
          allSelectedMakes={allSelectedMakes}
          handleToggleMake={handleToggleMake}
          data-testid={props['data-testid']}
        />
      </VStack>
    </DataStatus>
  );
}

interface MemoizedCheckboxListProps extends TestIdProps {
  makes?: Makes;
  allSelectedMakes: string[];
  handleToggleMake: (make: string, isSelected: boolean) => void;
}

const MemoizedCheckboxList = memo(
  (props: MemoizedCheckboxListProps) =>
    Object.values(props.makes ?? {})
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({name, label}) => {
        const isSelected = props.allSelectedMakes.includes(name);

        return (
          <Checkbox
            data-testid={suffixTestId(`make-${name}`, props)}
            key={name}
            name={`make.${name}`}
            label={label}
            value={isSelected}
            onChange={(value) => props.handleToggleMake(name, value)}
          />
        );
      }),
  (prevProps, nextProps) =>
    prevProps.makes === nextProps.makes && prevProps.allSelectedMakes === nextProps.allSelectedMakes
);

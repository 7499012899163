import {useNavigate} from 'react-router-dom';

import {testIds, vehiclesRoutes} from '@dms/routes';
import {ServiceVehicleFormMode, ServiceVehicleForm, ServiceVehicleFormVariant} from '@dms/shared';

import {composePath, useRequiredParams} from 'shared';

export function NewServiceVehicleFromSale() {
  const navigate = useNavigate();
  const {id: vehicleId} = useRequiredParams();

  return (
    <ServiceVehicleForm
      mode={ServiceVehicleFormMode.NEW_FROM_SALE}
      variant={ServiceVehicleFormVariant.EXTENDED}
      onDiscard={() => navigate(-1)}
      vehicleId={vehicleId}
      onSuccess={(newVehicleId) =>
        navigate(composePath(vehiclesRoutes.detail, {params: {id: newVehicleId}}))
      }
      data-testid={testIds.vehicles.createServiceVehicleFromSale('create')}
    />
  );
}

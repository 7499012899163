import {AnimatePresence, motion} from 'framer-motion';
import {DataStatus, Separator} from 'platform/components';
import {Box, Clickable, Hide, HStack, Space, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {isNil} from 'ramda';

import {useGetSignableDocumentListQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {DocumentLabel} from '../../components/DocumentLabel';
import {PreviewFileIcon} from '../../components/PreviewFileIcon';
import {ValidationErrorIcon} from '../../components/ValidationErrorIcon';
import {useDocumentSelection} from '../../contexts/DocumentSelectionProvider';
import {useDocumentValidationState} from '../../contexts/DocumentStateProvider';
import {SignDocumentStep} from '../../types/SignDocumentStep';

type RemoteDocumentMenuProps = {
  signingStep: SignDocumentStep;
};

export function RemoteDocumentMenu(props: RemoteDocumentMenuProps) {
  const {signatureDocumentFileIds} = useDocumentSelection();
  const {
    activeDocumentId,
    setActiveDocumentId,
    documentValidationState,
    clearDocumentValidationState,
  } = useDocumentValidationState();

  const {
    data: activeTransactionFiles,
    isLoading: isActiveTransactionFilesLoading,
    isError: isActiveTransactionFilesError,
  } = useGetSignableDocumentListQuery({
    files: signatureDocumentFileIds,
  });

  return (
    <DataStatus
      isLoading={isActiveTransactionFilesLoading}
      minHeight="100%"
      isError={isActiveTransactionFilesError}
    >
      <VStack>
        <Hide when={props.signingStep === 'Remote_sentToSignatories'}>
          <BackgroundWrapper $isActive={isNil(activeDocumentId)}>
            <Clickable onClick={() => setActiveDocumentId(null)} width="100%">
              <VStack height={13} justify="center" width="100%">
                <Box padding={2}>
                  <Text size="small" noWrap>
                    {i18n.t('entity.document.labels.defaultSignatories')}
                  </Text>
                </Box>
              </VStack>
            </Clickable>
          </BackgroundWrapper>

          <Separator />
        </Hide>

        <motion.div
          initial={{x: 200, opacity: 0}}
          animate={{x: 0, opacity: 1}}
          transition={{
            duration: 0.4,
          }}
        >
          {activeTransactionFiles?.map((document) => (
            <HStack align="center" key={document.sourceFileId}>
              <AnimatePresence>
                {documentValidationState.includes(document.sourceFileId) ? (
                  <ValidationErrorIcon
                    onClick={() => {
                      clearDocumentValidationState(document.sourceFileId);
                      setActiveDocumentId(document.sourceFileId);
                    }}
                  />
                ) : (
                  <PreviewFileIcon fileId={document.sourceFileId} />
                )}
              </AnimatePresence>
              <Space horizontal={2} />
              <BackgroundWrapper $isActive={document.sourceFileId === activeDocumentId}>
                <DocumentLabel
                  document={document}
                  onClick={({fileId}) => {
                    clearDocumentValidationState(fileId);
                    setActiveDocumentId(fileId);
                  }}
                />
              </BackgroundWrapper>
            </HStack>
          ))}
        </motion.div>
      </VStack>
    </DataStatus>
  );
}

const BackgroundWrapper = styled.div<{$isActive: boolean}>`
  display: flex;
  overflow: hidden;
  border-radius: ${({theme}) => theme.radii.small};
  width: 100%;
  display: flex;
  background-color: ${({$isActive, theme}) =>
    $isActive ? theme.colors.palettes.blue[20][100] : 'transparent'};

  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${({theme, $isActive}) =>
      $isActive ? theme.colors.palettes.blue[20][100] : theme.colors.palettes.neutral[20][100]};
  }
`;

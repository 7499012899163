import {
  CheckoutIdArgSchema,
  CheckoutIdPaymentIdArgSchema,
  DeleteCheckoutAdditionalCustomerApiArg,
  DeleteCheckoutAdditionalCustomerApiArgSchema,
  DeleteCheckoutAdditionalCustomerApiResponse,
  DeleteCheckoutAdditionalCustomerApiResponseSchema,
  DeleteCheckoutDepositPaymentApiArg,
  DeleteCheckoutDepositPaymentApiResponse,
  DeleteCheckoutDepositPaymentApiResponseSchema,
  GetCheckoutAllowedCustomerApiArg,
  GetCheckoutAllowedCustomerApiResponse,
  GetCheckoutAllowedCustomerApiResponseSchema,
  GetCheckoutBillingInformationApiArg,
  GetCheckoutBillingInformationApiResponse,
  GetCheckoutBillingInformationApiResponseSchema,
  GetCheckoutByIdApiArg,
  GetCheckoutByIdApiResponse,
  GetCheckoutByIdApiResponseSchema,
  GetCheckoutLinkForServiceOrderApiArg,
  GetCheckoutLinkForServiceOrderApiArgSchema,
  GetCheckoutLinkForServiceOrderApiResponse,
  GetCheckoutLinkForServiceOrderApiResponseSchema,
  GetCheckoutPaymentByIdApiArg,
  GetCheckoutPaymentByIdApiResponse,
  GetCheckoutPaymentByIdApiResponseSchema,
  GetCheckoutPaymentIssueRestrictionApiArg,
  GetCheckoutPaymentIssueRestrictionApiResponse,
  GetCheckoutPaymentIssueRestrictionApiResponseSchema,
  GetCheckoutPaymentsApiArg,
  GetCheckoutPaymentsApiResponse,
  GetCheckoutPaymentsApiResponseSchema,
  PatchCheckoutAdditionalCustomerApiArg,
  PatchCheckoutAdditionalCustomerApiArgSchema,
  PatchCheckoutAdditionalCustomerApiResponse,
  PatchCheckoutAdditionalCustomerApiResponseSchema,
  PatchCheckoutForeignCurrencyPaymentDisallowApiArg,
  PatchCheckoutForeignCurrencyPaymentDisallowApiResponse,
  PatchCheckoutForeignCurrencyPaymentDisallowApiResponseSchema,
  PatchCheckoutPaymentApiArg,
  PatchCheckoutPaymentApiArgSchema,
  PatchCheckoutPaymentApiResponse,
  PatchCheckoutPaymentApiResponseSchema,
  PostCheckoutAdditionalCustomerApiArg,
  PostCheckoutAdditionalCustomerApiArgSchema,
  PostCheckoutAdditionalCustomerApiResponse,
  PostCheckoutAdditionalCustomerApiResponseSchema,
  PostCheckoutAllowAbroadSaleApiArg,
  PostCheckoutAllowAbroadSaleApiArgSchema,
  PostCheckoutAllowAbroadSaleApiResponse,
  PostCheckoutAllowAbroadSaleApiResponseSchema,
  PostCheckoutDepositPaymentCreateApiArg,
  PostCheckoutDepositPaymentCreateApiResponse,
  PostCheckoutDepositPaymentCreateApiResponseSchema,
  PostCheckoutDisallowAbroadSaleApiArg,
  PostCheckoutDisallowAbroadSaleApiArgSchema,
  PostCheckoutDisallowAbroadSaleApiResponse,
  PostCheckoutDisallowAbroadSaleApiResponseSchema,
  PostCheckoutForeignCurrencyPaymentAllowApiArg,
  PostCheckoutForeignCurrencyPaymentAllowApiArgSchema,
  PostCheckoutForeignCurrencyPaymentAllowApiResponse,
  PostCheckoutForeignCurrencyPaymentAllowApiResponseSchema,
  PostCheckoutPaymentCancelApiArg,
  PostCheckoutPaymentCancelApiResponse,
  PostCheckoutPaymentCancelApiResponseSchema,
  PostCheckoutPaymentIssueApiArg,
  PostCheckoutPaymentIssueApiArgSchema,
  PostCheckoutPaymentIssueApiResponse,
  PostCheckoutPaymentIssueApiResponseSchema,
  PostCheckoutRecordPaidApiArg,
  PostCheckoutRecordPaidApiArgSchema,
  PostCheckoutRecordPaidApiResponse,
  PostCheckoutRecordPaidApiResponseSchema,
  PutCheckoutDepositPaymentAllowApiArg,
  PutCheckoutDepositPaymentAllowApiResponse,
  PutCheckoutDepositPaymentAllowApiResponseSchema,
  PutCheckoutDepositPaymentDisallowApiArg,
  PutCheckoutDepositPaymentDisallowApiResponse,
  PutCheckoutDepositPaymentDisallowApiResponseSchema,
  PutCheckoutSetBillingInformationApiArg,
  PutCheckoutSetBillingInformationApiArgSchema,
  PutCheckoutSetBillingInformationApiResponse,
  PutCheckoutSetBillingInformationApiResponseSchema,
  PutCheckoutSetContractInformationApiArg,
  PutCheckoutSetContractInformationApiArgSchema,
  PutCheckoutSetContractInformationApiResponse,
  PutCheckoutSetContractInformationApiResponseSchema,
  PutCheckoutSetTypeOfSaleApiArg,
  PutCheckoutSetTypeOfSaleApiArgSchema,
  PutCheckoutSetTypeOfSaleApiResponse,
  PutCheckoutSetTypeOfSaleApiResponseSchema,
} from '../types/metadaAftersalesCheckout';
import {metadaApi} from './baseApi/metadaApi';

export const metadaAftersalesCheckoutApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getCheckoutById: build.query<GetCheckoutByIdApiResponse, GetCheckoutByIdApiArg>({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetCheckoutByIdApiResponseSchema,
        requestSchema: CheckoutIdArgSchema,
      },
      providesTags: (result, error, queryArg) => [
        'afsCheckout',
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    putCheckoutDepositPaymentAllow: build.mutation<
      PutCheckoutDepositPaymentAllowApiResponse,
      PutCheckoutDepositPaymentAllowApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/allow-deposit`,
        method: 'PUT',
        queryArg,
      }),
      extraOptions: {
        responseSchema: PutCheckoutDepositPaymentAllowApiResponseSchema,
        requestSchema: CheckoutIdArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        'afsPaymentByIdList',
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    postCheckoutForeignCurrencyPaymentAllow: build.mutation<
      PostCheckoutForeignCurrencyPaymentAllowApiResponse,
      PostCheckoutForeignCurrencyPaymentAllowApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/allow-foreign-currency-payment`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostCheckoutForeignCurrencyPaymentAllowApiResponseSchema,
        requestSchema: PostCheckoutForeignCurrencyPaymentAllowApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        'afsPaymentByIdList',
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    getCheckoutBillingInformation: build.query<
      GetCheckoutBillingInformationApiResponse,
      GetCheckoutBillingInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/billing-information`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetCheckoutBillingInformationApiResponseSchema,
        requestSchema: CheckoutIdArgSchema,
      },
      providesTags: (result, error, queryArg) => [
        {type: 'afsBillingInformation', id: queryArg.checkoutId},
      ],
    }),
    postCheckoutDepositPaymentCreate: build.mutation<
      PostCheckoutDepositPaymentCreateApiResponse,
      PostCheckoutDepositPaymentCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/deposit`,
        method: 'POST',
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostCheckoutDepositPaymentCreateApiResponseSchema,
        requestSchema: CheckoutIdArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        'afsPaymentByIdList',
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    deleteCheckoutDepositPayment: build.mutation<
      DeleteCheckoutDepositPaymentApiResponse,
      DeleteCheckoutDepositPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/deposit/${queryArg.paymentId}`,
        method: 'DELETE',
        queryArg,
      }),
      extraOptions: {
        responseSchema: DeleteCheckoutDepositPaymentApiResponseSchema,
        requestSchema: CheckoutIdPaymentIdArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        'afsPaymentByIdList',
        {type: 'afsPaymentById', id: queryArg.paymentId},
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    putCheckoutDepositPaymentDisallow: build.mutation<
      PutCheckoutDepositPaymentDisallowApiResponse,
      PutCheckoutDepositPaymentDisallowApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/disallow-deposit`,
        method: 'PUT',
        queryArg,
      }),
      extraOptions: {
        responseSchema: PutCheckoutDepositPaymentDisallowApiResponseSchema,
        requestSchema: CheckoutIdArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        'afsPaymentByIdList',
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    patchCheckoutForeignCurrencyPaymentDisallow: build.mutation<
      PatchCheckoutForeignCurrencyPaymentDisallowApiResponse,
      PatchCheckoutForeignCurrencyPaymentDisallowApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/disallow-foreign-currency-payment`,
        method: 'PATCH',
        queryArg,
      }),
      extraOptions: {
        responseSchema: PatchCheckoutForeignCurrencyPaymentDisallowApiResponseSchema,
        requestSchema: CheckoutIdArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        'afsPaymentByIdList',
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    getCheckoutPaymentById: build.query<
      GetCheckoutPaymentByIdApiResponse,
      GetCheckoutPaymentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/payment/${queryArg.paymentId}`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetCheckoutPaymentByIdApiResponseSchema,
        requestSchema: CheckoutIdPaymentIdArgSchema,
      },
      providesTags: (result, error, queryArg) => [
        'afsPaymentsList',
        'afsPaymentById',
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        {type: 'afsPaymentById', id: queryArg.paymentId},
      ],
    }),
    patchCheckoutPayment: build.mutation<
      PatchCheckoutPaymentApiResponse,
      PatchCheckoutPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/payment/${queryArg.paymentId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PatchCheckoutPaymentApiResponseSchema,
        requestSchema: PatchCheckoutPaymentApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsPaymentById', id: queryArg.paymentId},
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        {type: 'afsPaymentIssueRestriction', id: queryArg.paymentId},
      ],
    }),
    postCheckoutPaymentCancel: build.mutation<
      PostCheckoutPaymentCancelApiResponse,
      PostCheckoutPaymentCancelApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/payment/${queryArg.paymentId}/cancel`,
        method: 'POST',
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostCheckoutPaymentCancelApiResponseSchema,
        requestSchema: CheckoutIdPaymentIdArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsPaymentById', id: queryArg.paymentId},
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        {type: 'afsPaymentIssueRestriction', id: queryArg.paymentId},
      ],
    }),
    postCheckoutPaymentIssue: build.mutation<
      PostCheckoutPaymentIssueApiResponse,
      PostCheckoutPaymentIssueApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/payment/${queryArg.paymentId}/issue`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostCheckoutPaymentIssueApiResponseSchema,
        requestSchema: PostCheckoutPaymentIssueApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsPaymentIssueRestriction', id: queryArg.paymentId},
        {type: 'afsPaymentById', id: queryArg.paymentId},
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        'profitabilityHistory',
        'serviceJobActions',
        'orderActions',
        'serviceJobActions',
        'serviceActions',
        'serviceCase',
        'serviceCaseTotalAmount',
        'serviceOrders',
        'serviceOrder',
        'serviceOrderJobs',
      ],
    }),
    postCheckoutRecordPaid: build.mutation<
      PostCheckoutRecordPaidApiResponse,
      PostCheckoutRecordPaidApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/payment/${queryArg.paymentId}/record-paid`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostCheckoutRecordPaidApiResponseSchema,
        requestSchema: PostCheckoutRecordPaidApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsPaymentIssueRestriction', id: 'LIST'},
        {type: 'afsPaymentIssueRestriction', id: queryArg.paymentId},
        {type: 'afsPaymentById', id: queryArg.paymentId},
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        'profitabilityHistory',
        'serviceJobActions',
        'orderActions',
        'serviceJobActions',
        'serviceActions',
        'serviceCase',
        'serviceCaseTotalAmount',
        'serviceOrders',
        'serviceOrder',
        'serviceOrderJobs',
      ],
    }),
    getCheckoutPayments: build.query<GetCheckoutPaymentsApiResponse, GetCheckoutPaymentsApiArg>({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/payments`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetCheckoutPaymentsApiResponseSchema,
        requestSchema: CheckoutIdArgSchema,
      },
      providesTags: (result, error, queryArg) => [
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
      ],
    }),
    putCheckoutSetBillingInformation: build.mutation<
      PutCheckoutSetBillingInformationApiResponse,
      PutCheckoutSetBillingInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/set-billing-information`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PutCheckoutSetBillingInformationApiResponseSchema,
        requestSchema: PutCheckoutSetBillingInformationApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsBillingInformation', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    putCheckoutSetContractInformation: build.mutation<
      PutCheckoutSetContractInformationApiResponse,
      PutCheckoutSetContractInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/set-contract-information`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PutCheckoutSetContractInformationApiResponseSchema,
        requestSchema: PutCheckoutSetContractInformationApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsBillingInformation', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    postCheckoutAdditionalCustomer: build.mutation<
      PostCheckoutAdditionalCustomerApiResponse,
      PostCheckoutAdditionalCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/additional-customer`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostCheckoutAdditionalCustomerApiResponseSchema,
        requestSchema: PostCheckoutAdditionalCustomerApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsBillingInformation', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    deleteCheckoutAdditionalCustomer: build.mutation<
      DeleteCheckoutAdditionalCustomerApiResponse,
      DeleteCheckoutAdditionalCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/additional-customer/${queryArg.additionalCustomerId}`,
        method: 'DELETE',
        queryArg,
      }),
      extraOptions: {
        responseSchema: DeleteCheckoutAdditionalCustomerApiResponseSchema,
        requestSchema: DeleteCheckoutAdditionalCustomerApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsBillingInformation', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    getCheckoutAllowedCustomer: build.query<
      GetCheckoutAllowedCustomerApiResponse,
      GetCheckoutAllowedCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/allowed-customer`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetCheckoutAllowedCustomerApiResponseSchema,
        requestSchema: CheckoutIdArgSchema,
      },
    }),
    patchCheckoutAdditionalCustomer: build.mutation<
      PatchCheckoutAdditionalCustomerApiResponse,
      PatchCheckoutAdditionalCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/additional-customer/${queryArg.additionalCustomerId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PatchCheckoutAdditionalCustomerApiResponseSchema,
        requestSchema: PatchCheckoutAdditionalCustomerApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsBillingInformation', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    getCheckoutLinkForServiceOrder: build.query<
      GetCheckoutLinkForServiceOrderApiResponse,
      GetCheckoutLinkForServiceOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/service-order/${queryArg.serviceOrderId}/checkout-link`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetCheckoutLinkForServiceOrderApiResponseSchema,
        requestSchema: GetCheckoutLinkForServiceOrderApiArgSchema,
      },
    }),
    getCheckoutPaymentIssueRestriction: build.query<
      GetCheckoutPaymentIssueRestrictionApiResponse,
      GetCheckoutPaymentIssueRestrictionApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/payment/${queryArg.paymentId}/issue-restriction`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetCheckoutPaymentIssueRestrictionApiResponseSchema,
        requestSchema: CheckoutIdPaymentIdArgSchema,
      },
      providesTags: (result, error, queryArg) => [
        'afsPaymentIssueRestriction',
        {type: 'afsPaymentIssueRestriction', id: 'LIST'},
        {type: 'afsPaymentIssueRestriction', id: queryArg.paymentId},
      ],
    }),
    putCheckoutSetTypeOfSale: build.mutation<
      PutCheckoutSetTypeOfSaleApiResponse,
      PutCheckoutSetTypeOfSaleApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/set-type-of-sale`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PutCheckoutSetTypeOfSaleApiResponseSchema,
        requestSchema: PutCheckoutSetTypeOfSaleApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsCheckout', id: queryArg.checkoutId},
        'afsPaymentIssueRestriction',
        'afsPaymentById',
        {type: 'afsPaymentsList', id: queryArg.checkoutId},
        'profitabilityHistory',
        'serviceJobActions',
        'orderActions',
        'serviceJobActions',
        'serviceActions',
        'serviceCase',
        'serviceCaseTotalAmount',
        'serviceOrders',
        'serviceOrder',
        'serviceOrderJobs',
        'serviceOrderJobTotalAmount',
      ],
    }),
    postAllowAbroadSale: build.mutation<
      PostCheckoutAllowAbroadSaleApiResponse,
      PostCheckoutAllowAbroadSaleApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/allow-abroad-sale`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostCheckoutAllowAbroadSaleApiResponseSchema,
        requestSchema: PostCheckoutAllowAbroadSaleApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsBillingInformation', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
    postDisallowAbroadSale: build.mutation<
      PostCheckoutDisallowAbroadSaleApiResponse,
      PostCheckoutDisallowAbroadSaleApiArg
    >({
      query: (queryArg) => ({
        url: `/checkout/v1/checkout/${queryArg.checkoutId}/disallow-abroad-sale`,
        method: 'POST',
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostCheckoutDisallowAbroadSaleApiResponseSchema,
        requestSchema: PostCheckoutDisallowAbroadSaleApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'afsBillingInformation', id: queryArg.checkoutId},
        {type: 'afsCheckout', id: queryArg.checkoutId},
      ],
    }),
  }),
});

export const {
  useGetCheckoutByIdQuery,
  usePutCheckoutDepositPaymentAllowMutation,
  usePostCheckoutForeignCurrencyPaymentAllowMutation,
  useGetCheckoutBillingInformationQuery,
  usePostCheckoutDepositPaymentCreateMutation,
  useDeleteCheckoutDepositPaymentMutation,
  usePutCheckoutDepositPaymentDisallowMutation,
  usePatchCheckoutForeignCurrencyPaymentDisallowMutation,
  useGetCheckoutPaymentByIdQuery,
  usePatchCheckoutPaymentMutation,
  usePostCheckoutPaymentCancelMutation,
  usePostCheckoutPaymentIssueMutation,
  usePostCheckoutRecordPaidMutation,
  useGetCheckoutPaymentsQuery,
  usePutCheckoutSetBillingInformationMutation,
  usePutCheckoutSetContractInformationMutation,
  usePostCheckoutAdditionalCustomerMutation,
  useDeleteCheckoutAdditionalCustomerMutation,
  useGetCheckoutAllowedCustomerQuery,
  usePatchCheckoutAdditionalCustomerMutation,
  useGetCheckoutLinkForServiceOrderQuery,
  useGetCheckoutPaymentIssueRestrictionQuery,
  usePutCheckoutSetTypeOfSaleMutation,
  usePostAllowAbroadSaleMutation,
  usePostDisallowAbroadSaleMutation,
} = metadaAftersalesCheckoutApi;

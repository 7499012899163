import {
  Button,
  Card,
  EmailInput,
  FormControl,
  openConfirmDialog,
  PhoneInput,
  PhoneNumber as PlatformPhoneNumber,
  showNotification,
  TextInput,
} from 'platform/components';
import {Grid, GridItem, Heading, Right, Show, Text, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {map, pipe, uniqBy} from 'ramda';

import {GetSignableDocumentListResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {CustomerSelectChoice, usePhoneNumbers} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {BASE_PATH_SEPARATOR} from '../../../constants/BASE_PATH_SEPARATOR';
import {useDocumentValidationState} from '../../../contexts/DocumentStateProvider';
import {useSignatoryFormState} from '../hooks/useSignatoryFormState';
import {FormValues} from '../types/FormValues';
import {UserSelectChoice} from './UserSelectChoice';

interface DocumentSignatoriesFormProps extends TestIdProps {
  control: FormControl<FormValues>;
  formApi: UseFormReturn<FormValues>;
  documents: GetSignableDocumentListResponseBody[];
  customerId: string | Nullish;
}

export function DefaultSignatories(props: DocumentSignatoriesFormProps) {
  const [formData, setFormData, inputKey] = useSignatoryFormState(props.customerId);
  const {countriesOptions} = usePhoneNumbers();

  const {clearAllDocumentsValidationState, setActiveDocumentId} = useDocumentValidationState();

  const allPossibleSignatories = pipe(
    map((document: GetSignableDocumentListResponseBody) => document.metadata),
    (metadata) => metadata.flat(),
    uniqBy((signatory) => signatory.signatoryPerson)
  )(props.documents);

  const handleSubmit = () =>
    openConfirmDialog({
      onConfirm: () => {
        const formValues = props.documents.reduce((prev, document) => {
          const fileId = document.sourceFileId;
          const signatoriesValues = document.metadata.reduce(
            (prev, signatory) => ({
              ...prev,
              [`${fileId}${BASE_PATH_SEPARATOR}${signatory.id}`]:
                formData[signatory.signatoryPerson],
            }),
            {}
          );

          return {
            ...prev,
            ...signatoriesValues,
          };
        }, {});

        props.formApi.reset(formValues);
        showNotification.success(i18n.t('entity.document.labels.defaultSignatoriesApplied'));
        clearAllDocumentsValidationState();
        setActiveDocumentId(props.documents?.[0]?.sourceFileId ?? null);
      },
      text: i18n.t('entity.document.labels.defaultSignatoriesMessage'),
    });

  return (
    <VStack spacing={4}>
      <VStack spacing={2}>
        <Heading size={3}>{i18n.t('entity.document.labels.defaultSignatories')}</Heading>

        <Text size="small" color="secondary">
          {i18n.t('entity.document.labels.defaultSignatoriesDescription')}
        </Text>
      </VStack>

      <VStack spacing={4}>
        {allPossibleSignatories.map((signatory) => (
          <Card variant="inlineGrey" title={signatory.title} key={signatory.signatoryPerson}>
            <Grid columns={3}>
              <GridItem span={3}>
                <Show when={signatory.signatoryPerson === 'customer'}>
                  <CustomerSelectChoice
                    key={inputKey}
                    defaultSearchString={formData[signatory.signatoryPerson]?.name}
                    onCustomerSelect={(data) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        [signatory.signatoryPerson]: {
                          email: data.email,
                          name: data.name,
                          id: data.id,
                          phoneNumber: {
                            number: data.phoneNumber,
                            prefix: data.prefix,
                            countryCode: data.countryCode,
                          },
                        },
                      }));
                    }}
                    data-testid={suffixTestId('CustomerSelectChoice', props)}
                  />
                </Show>

                <Show when={signatory.signatoryPerson === 'tenant'}>
                  <UserSelectChoice
                    key={inputKey}
                    defaultUserId={formData[signatory.signatoryPerson]?.id}
                    onSelect={(userValues) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        [signatory.signatoryPerson]: {
                          email: userValues.email,
                          name: userValues.name,
                          id: userValues.id,
                          phoneNumber: userValues.phoneNumber,
                        },
                      }));
                    }}
                    data-testid={suffixTestId('UserSelectChoice', props)}
                  />
                </Show>
              </GridItem>

              <TextInput
                value={formData[signatory.signatoryPerson]?.name}
                onChange={(name) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    [signatory.signatoryPerson]: {...prevState[signatory.signatoryPerson], name},
                  }))
                }
              />

              <EmailInput
                value={formData[signatory.signatoryPerson]?.email}
                onChange={(email) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    [signatory.signatoryPerson]: {...prevState[signatory.signatoryPerson], email},
                  }))
                }
              />

              <PhoneInput
                value={formData[signatory.signatoryPerson]?.phoneNumber as PlatformPhoneNumber}
                isNational
                countries={countriesOptions}
                onChange={(phoneNumber) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    [signatory.signatoryPerson]: {
                      ...prevState[signatory.signatoryPerson],
                      phoneNumber,
                    },
                  }))
                }
              />
            </Grid>
          </Card>
        ))}

        <Right>
          <Button
            title={i18n.t('entity.document.labels.applySignatories')}
            onClick={handleSubmit}
          />
        </Right>
      </VStack>
    </VStack>
  );
}

import {Card, DataStatus} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isNil, isNotNil} from 'ramda';
import {isFalse} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
  usePutBusinessCaseContactPersonMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  CustomerInformationCard,
  handleApiError,
  useBusinessCaseActions,
  usePermissions,
} from '@dms/shared';

import {ContactPersonForm} from '../ContactPersonForm';

interface CustomerPanelProps {
  businessCaseId: string;
  openCustomerWidget: () => void;
}

export function CustomerPanel(props: CustomerPanelProps) {
  const {
    data: businessCase,
    isLoading: isLoadingBusinessCase,
    isError: isErrorBusinessCase,
  } = useGetBusinessCaseQuery({businessCaseId: props.businessCaseId});

  const {isActionEnabled} = useBusinessCaseActions(props.businessCaseId);
  const [putBusinessCaseContactPerson] = usePutBusinessCaseContactPersonMutation();

  const customerId = businessCase?.customerId;
  const isBusinessCaseBuyingOrBrokerage =
    businessCase?.businessCaseType === 'BUYING' ||
    businessCase?.businessCaseType === 'PURCHASE_BROKERAGE';

  const [showForm, setShowForm] = useState(false);

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: props.businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [
    hasChangeBusinessCasePurchaseSellerPermission,
    hasSelectBusinessCasePurchaseSellerPermission,
    hasChangeBusinessCaseSellingBuyerPermission,
    hasSelectBusinessCaseSellingBuyerPermission,
  ] = usePermissions({
    permissionKeys: [
      'changeBusinessCasePurchaseSeller',
      'selectBusinessCasePurchaseSellerContactPerson',
      'changeBusinessCaseSellingBuyer',
      'selectBusinessCaseSellingBuyerContactPerson',
    ],
    scopes: {
      changeBusinessCasePurchaseSeller: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      selectBusinessCasePurchaseSellerContactPerson: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      changeBusinessCaseSellingBuyer: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      selectBusinessCaseSellingBuyerContactPerson: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
    },
  });

  const canSelectContactPerson = isBusinessCaseBuyingOrBrokerage
    ? hasSelectBusinessCasePurchaseSellerPermission
    : hasSelectBusinessCaseSellingBuyerPermission;

  const canChangeCustomer = isBusinessCaseBuyingOrBrokerage
    ? hasChangeBusinessCasePurchaseSellerPermission
    : hasChangeBusinessCaseSellingBuyerPermission;

  const isSelectContractPersonDisabled =
    !canSelectContactPerson || !isActionEnabled('SELECT_CUSTOMER_CONTACT_PERSON');

  useEffect(() => {
    setShowForm(isNotNil(businessCase?.contactPersonId));
  }, [businessCase?.contactPersonId]);

  const toggleContactPerson = (value: boolean) => {
    putBusinessCaseContactPerson({
      businessCaseId: props.businessCaseId,
      putBusinessCaseContactPersonRequestBody: {
        // NOTE: with every toggle, we intentionally reset the contact person
        contactId: null,
      },
    })
      .unwrap()
      .then(() => setShowForm(value))
      .catch(handleApiError);
  };

  return (
    <DataStatus
      isLoading={isLoadingBusinessCase}
      isError={isErrorBusinessCase}
      isEmpty={isNil(customerId)}
    >
      <VStack spacing={4}>
        <Grid columns={2} align="flex-start">
          <CustomerInformationCard
            customerId={customerId!}
            onChange={props.openCustomerWidget}
            isDetailLinkVisible
            isCustomerChangeDisabled={businessCase?.businessCaseState === 'CLOSED'}
            isChangeCustomerForbidden={isFalse(canChangeCustomer)}
            data-testid={testIds.businessCase.customer('customerInformation')}
          />
          <Card
            data-testid={testIds.businessCase.customer('contactPerson')}
            variant="default"
            isExpanded={showForm}
            control={{
              type: 'switch',
              value: isNotNil(businessCase?.contactPersonId),
              isDisabled: isSelectContractPersonDisabled,
              onChange: toggleContactPerson,
              'data-testid': testIds.businessCase.customer('toggleContactPerson'),
            }}
            title={i18n.t('entity.businessCase.labels.contactPerson')}
          >
            <Card data-testid={testIds.businessCase.customer('contactPerson')} variant="inlineGrey">
              <ContactPersonForm
                customerId={customerId!}
                isDisabled={isSelectContractPersonDisabled}
                contactPersonId={businessCase?.contactPersonId}
              />
            </Card>
          </Card>
        </Grid>
      </VStack>
    </DataStatus>
  );
}

import {motion} from 'framer-motion';
import {Box} from 'platform/foundation';

import {useEffect, useRef, useState} from 'react';

import {isNumber} from 'ramda-adjunct';

import {STEPPER_MIN_HEIGHT} from '../constants/STEPPER_MIN_HEIGHT';

interface AnimateHeightObserverProps {
  children: React.ReactNode;
  className?: string;
}

export function AnimateHeightObserver(props: AnimateHeightObserverProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [calculatedHeight, setHeight] = useState<number | 'auto'>('auto');

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // We only have one entry, so we can use entries[0].
      const observedHeight = entries[0].contentRect.height;
      setHeight(observedHeight);
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      // Cleanup the observer when the component is unmounted
      resizeObserver?.disconnect();
    };
  }, []);

  const height =
    isNumber(calculatedHeight) && calculatedHeight < STEPPER_MIN_HEIGHT * 4
      ? STEPPER_MIN_HEIGHT * 4
      : calculatedHeight;

  return (
    <motion.div style={{height}} animate={{height}} transition={{duration: 0.2}}>
      <Box ref={containerRef}>{props.children}</Box>
    </motion.div>
  );
}

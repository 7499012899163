import {isEqual, startOfDay} from 'date-fns';
import {Avatar} from 'platform/components';
import {
  Box,
  Clickable,
  getColorByPath,
  Hide,
  HStack,
  Icon,
  Link,
  Show,
  Text,
  VStack,
} from 'platform/foundation';
import styled from 'styled-components';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {GetUserApiResponse, TaskPriorityEnum, TaskResponseBody, useGetUserQuery} from '@dms/api';
import {getUserName} from '@dms/shared';

import {parseDate, RequiredTestIdProps} from 'shared';

import {getColorByPriority} from '../../../../utils/getColorByPriority';
import {getIconKeyByPriority} from '../../../../utils/getIconKeyByPriority';
import {getIconKeyByType} from '../../../../utils/getIconKeyByType';
import {TaskCenterTaskDate} from '../TaskCenterTaskDate/TaskCenterTaskDate';

interface TaskCenterListItemProps extends RequiredTestIdProps {
  onClick: () => void;
  task: TaskResponseBody;
  onAssigneeClick?: (assignee: GetUserApiResponse) => void;
  hideAssignee?: boolean;
}

export function TaskCenterListItem(props: TaskCenterListItemProps) {
  const {data: assignedUser} = useGetUserQuery(
    {
      id: props.task?.assignedTo ?? '',
    },
    {
      skip: isNilOrEmpty(props.task?.assignedTo) || props.hideAssignee,
    }
  );

  const parsedDueTo = parseDate(props.task.dueTo);

  return (
    <Clickable onClick={props.onClick} data-testid={props['data-testid']}>
      <TaskCenterListItemWrapper $priority={props.task.priority}>
        <Box minHeight={14} padding={2} overflow="hidden">
          <HStack justify="space-between" align="center">
            <HStack justify="center" align="center" spacing={2}>
              <Box height={10} width={10}>
                <Avatar icon={getIconKeyByType(props.task.type)} />
              </Box>
              <VStack spacing={1} grow={1}>
                <HStack spacing={2} align="baseline" justify="space-between">
                  <Text size="xSmall" color="primary" inline alternative>
                    {props.task.title}
                  </Text>
                </HStack>
                <Show when={isNotNilOrEmpty(assignedUser) && !props.hideAssignee}>
                  <Hide when={isNilOrEmpty(props.onAssigneeClick)}>
                    <Link
                      size="xSmall"
                      isDisabled={isNilOrEmpty(props.onAssigneeClick)}
                      title={getUserName(assignedUser)}
                      onClick={() => {
                        if (assignedUser) {
                          props.onAssigneeClick?.(assignedUser);
                        }
                      }}
                    />
                  </Hide>
                  <Show when={isNilOrEmpty(props.onAssigneeClick)}>
                    <Text size="xSmall" color="secondary">
                      {getUserName(assignedUser)}
                    </Text>
                  </Show>
                </Show>
                <TaskCenterTaskDate
                  value={props.task.dueTo ?? props.task.createdAt}
                  data-testid={props['data-testid']}
                  hideTime={
                    isNilOrEmpty(props.task.dueTo) || isEqual(parsedDueTo, startOfDay(parsedDueTo))
                  }
                />
              </VStack>
            </HStack>
            <Icon
              size={6}
              value={getIconKeyByPriority(props.task.priority)}
              color={getColorByPriority(false, props.task.priority)}
            />
          </HStack>
        </Box>
      </TaskCenterListItemWrapper>
    </Clickable>
  );
}

const TaskCenterListItemWrapper = styled.div<{$priority: TaskPriorityEnum}>`
  background-color: ${({theme, $priority}) =>
    getColorByPath(getColorByPriority(true, $priority), theme)};
  border-radius: ${({theme}) => theme.radii.small};
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
  }
`;

import {DataStatus, Flag, FormControl} from 'platform/components';
import {Box, HStack, VStack, Text, Heading} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber} from 'platform/locale';

import {useEffect} from 'react';
import {useWatch} from 'react-hook-form';

import {defaultTo, isNil, isNotNil, not} from 'ramda';

import {usePostDirectSaleLabourItemCalculatePriceQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {
  EMPTY_PLACEHOLDER,
  Nullish,
  TestIdProps,
  isNotNilOrZero,
  suffixTestId,
  useDebouncedValue,
} from 'shared';

import {LabourMechanic, EditLabourItemForm} from '../../../../types/EditLabourItemForm';

interface LabourItemPriceProps extends TestIdProps {
  control: FormControl<EditLabourItemForm>;
  directSaleId: string;
  itemId: string;
  onPriceChange: (newPricePerUnit: number) => void;
}

const DEBOUNCE_DELAY = 500;
const PERCENTAGE_BASE = 100;

export function RequestTabLabourItemPrice(props: LabourItemPriceProps) {
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  const formValues = useWatch({control: props.control});

  const debouncedFormValues = useDebouncedValue(formValues, DEBOUNCE_DELAY);

  const isDiscountRateValid =
    isNil(debouncedFormValues.discount?.discountRate) ||
    (debouncedFormValues.discount.discountRate <= PERCENTAGE_BASE &&
      debouncedFormValues.discount.discountRate >= 0);

  const isQuantityValid = isNotNilOrZero(debouncedFormValues.quantity);

  const {data: recalculatedPrices, isLoading: isRecalculating} =
    usePostDirectSaleLabourItemCalculatePriceQuery(
      {
        directSaleId: props.directSaleId,
        itemId: props.itemId,
        body: {
          quantity: debouncedFormValues.quantity,
          workType: debouncedFormValues.workType,
          sellingPricePerUnit: debouncedFormValues.sellingPricePerUnit,
          isCustomPrice: debouncedFormValues.isCustomPrice,
          vatType: debouncedFormValues.vatType,
          assignMechanics: debouncedFormValues.assignMechanics
            ?.filter((mechanic: LabourMechanic) => isNotNil(mechanic.id))
            .map((mechanic: LabourMechanic, index: number) => ({
              id: mechanic.id,
              isDefault: index === 0,
              costCenterId: mechanic.costCenterId,
              amount: mechanic.amount,
            })),
          discount: {
            isDoNotApplyDiscount: debouncedFormValues.discount?.isDoNotApplyDiscount,
            isDiscountEnable: debouncedFormValues.discount?.isDiscountEnable,
            discountSourceType: debouncedFormValues.discount?.discountSourceType,
            discountRate: debouncedFormValues.discount?.isDiscountEnable
              ? defaultTo(0, debouncedFormValues.discount.discountRate) / PERCENTAGE_BASE
              : 0,
          },
          getCurrentPrice: debouncedFormValues.shouldGetCurrentPrice,
        },
      },
      {skip: isNil(debouncedFormValues) || not(isDiscountRateValid) || not(isQuantityValid)}
    );

  useEffect(() => {
    if (isNil(recalculatedPrices)) {
      return;
    }

    props.onPriceChange(recalculatedPrices.sellingPricePerUnit.amount);
    // We don't need to include the props here, because we always have the latest recalculated value
    // when calling props.onPriceChange
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recalculatedPrices]);

  const getPrice = (
    priceType: 'sellingTotalPrice' | 'purchaseTotalPrice' | 'marginTotal' | 'marginPerUnit',
    vatType: 'withVat' | 'withoutVat'
  ) => {
    const prices = recalculatedPrices?.[priceType];

    if (isNil(prices)) {
      return EMPTY_PLACEHOLDER;
    }

    return customCurrencyFormat(prices[vatType].amount, prices[vatType].currency);
  };

  const customCurrencyFormat = (amount: number | Nullish, currency: string | Nullish) => {
    if (isNil(amount) || isNil(currency)) {
      return EMPTY_PLACEHOLDER;
    }

    return formatCurrency(amount, currency, 2);
  };

  const purchasePriceWithoutVat = getPrice('purchaseTotalPrice', 'withoutVat');

  const purchasePriceWithVat = getPrice('purchaseTotalPrice', 'withVat');

  const sellingPriceWithoutVat = getPrice('sellingTotalPrice', 'withoutVat');

  const sellingPriceWithVat = getPrice('sellingTotalPrice', 'withVat');

  const marginTotalWithoutVat = getPrice('marginTotal', 'withoutVat');

  const marginTotalWithVat = getPrice('marginTotal', 'withVat');

  const marginPerUnitWithoutVat = getPrice('marginPerUnit', 'withoutVat');

  const marginPerUnitWithVat = getPrice('marginPerUnit', 'withVat');

  const marginTotalPercentage =
    defaultTo(0, recalculatedPrices?.marginTotal?.marginPercentage) * PERCENTAGE_BASE;

  const doesMarginExist = recalculatedPrices?.marginTotal?.isMarginExisting;

  const isMarginUnderMinimum = recalculatedPrices?.marginTotal?.isMarginUnderMinimum;

  return (
    <DataStatus isLoading={isRecalculating}>
      <VStack spacing={4}>
        <HStack spacing={4}>
          <Box flex={1}>
            <VStack spacing={1}>
              <Text color="secondary" size="xSmall">
                {i18n.t('general.labels.purchasePrice')}
              </Text>
              <Heading size={5} data-testid={suffixTestId('purchasePriceWithoutVat', props)}>
                {purchasePriceWithoutVat}
              </Heading>
              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('purchasePriceWithVat', props)}
              >
                {`${purchasePriceWithVat} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>

          <Box flex={1}>
            <VStack spacing={1} align="flex-end">
              <Text color="secondary" size="xSmall">
                {i18n.t('entity.warehouse.labels.marginTotalUnitPercent')}
              </Text>

              <HStack spacing={1} align="center">
                <Heading
                  size={5}
                  color={isMarginUnderMinimum ? 'danger' : 'success'}
                  data-testid={suffixTestId('marginWithoutVat', props)}
                >
                  {`${marginTotalWithoutVat}/${marginPerUnitWithoutVat}`}
                </Heading>
                <Flag
                  label={
                    doesMarginExist
                      ? `${formatNumber(marginTotalPercentage, 2)} %`
                      : EMPTY_PLACEHOLDER
                  }
                  colorScheme={isMarginUnderMinimum ? 'red' : 'green'}
                  isSubtle
                  size="small"
                  data-testid={suffixTestId('marginPercentage', props)}
                />
              </HStack>

              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('marginWithVat', props)}
              >
                {`${marginTotalWithVat}/${marginPerUnitWithVat} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>

          <Box flex={1}>
            <VStack spacing={1} align="flex-end">
              <Text color="secondary" size="xSmall">
                {i18n.t('general.labels.sellingPrice')}
              </Text>
              <Heading size={5} data-testid={suffixTestId('sellingPriceWithoutVat', props)}>
                {sellingPriceWithoutVat}
              </Heading>
              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('sellingPriceWithVat', props)}
              >
                {`${sellingPriceWithVat} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>
        </HStack>
      </VStack>
    </DataStatus>
  );
}

import {useMemo} from 'react';

import {FeGridSettings, TransformedDefinitionApiResponse} from '../types/Api';
import {LineHeightMappingToPx} from '../utils/lineHeightToPx';

export const dgIndent = {
  '.ag-ltr .ag-column-select-indent-1': {
    paddingLeft: '24px',
  },
  '.ag-rtl .ag-column-select-indent-1': {
    paddingRight: '24px',
  },
  '.ag-ltr .ag-column-select-indent-2': {
    paddingLeft: '32px',
  },
  '.ag-rtl .ag-column-select-indent-2': {
    paddingRight: '32px',
  },
  '.ag-ltr .ag-column-select-indent-3': {
    paddingLeft: '48px',
  },
  '.ag-rtl .ag-column-select-indent-3': {
    paddingRight: '48px',
  },
  '.ag-ltr .ag-column-select-indent-4': {
    paddingLeft: '64px',
  },
  '.ag-rtl .ag-column-select-indent-4': {
    paddingRight: '64px',
  },
  '.ag-ltr .ag-column-select-indent-5': {
    paddingLeft: '80px',
  },
  '.ag-rtl .ag-column-select-indent-5': {
    paddingRight: '80px',
  },
  '.ag-ltr .ag-column-select-indent-6': {
    paddingLeft: '96px',
  },
  '.ag-rtl .ag-column-select-indent-6': {
    paddingRight: '96px',
  },
  '.ag-ltr .ag-column-select-indent-7': {
    paddingLeft: '112px',
  },
  '.ag-rtl .ag-column-select-indent-7': {
    paddingRight: '112px',
  },
  '.ag-ltr .ag-column-select-indent-8': {
    paddingLeft: '128px',
  },
  '.ag-rtl .ag-column-select-indent-8': {
    paddingRight: '128px',
  },
  '.ag-ltr .ag-column-select-indent-9': {
    paddingLeft: '144px',
  },
  '.ag-rtl .ag-column-select-indent-9': {
    paddingRight: '144px',
  },

  '.ag-ltr .ag-column-select-add-group-indent': {
    marginLeft: '40px',
  },
  '.ag-rtl .ag-column-select-add-group-indent': {
    marginRight: '40px',
  },

  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-1': {
    paddingLeft: '46px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-1': {
    paddingRight: '46px',
  },
  '.ag-ltr .ag-row-group-indent-1': {paddingLeft: '28px'},
  '.ag-rtl .ag-row-group-indent-1': {paddingRight: '28px'},
  '.ag-ltr .ag-row-level-1 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-2': {
    paddingLeft: '74px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-2': {
    paddingRight: '74px',
  },
  '.ag-ltr .ag-row-group-indent-2': {paddingLeft: '56px'},
  '.ag-rtl .ag-row-group-indent-2': {paddingRight: '56px'},

  '.ag-column-select-virtual-list-viewport': {
    padding: '6px 0px',
  },
  '.ag-column-select-virtual-list-item': {margin: '0 12px'},
  '.ag-rtl': {textAlign: 'right'},
  '.ag-rtl .ag-row-level-1 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row-level-2 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-2 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-3': {
    paddingLeft: '102px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-3': {
    paddingRight: '102px',
  },
  '.ag-ltr .ag-row-group-indent-3': {paddingLeft: '84px'},
  '.ag-rtl .ag-row-group-indent-3': {paddingRight: '84px'},
  '.ag-ltr .ag-row-level-3 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-3 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-4': {
    paddingLeft: '130px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-4': {
    paddingRight: '130px',
  },
  '.ag-ltr .ag-row-group-indent-4': {paddingLeft: '112px'},
  '.ag-rtl .ag-row-group-indent-4': {paddingRight: '112px'},
  '.ag-ltr .ag-row-level-4 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-4 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-5': {
    paddingLeft: '158px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-5': {
    paddingRight: '158px',
  },
  '.ag-ltr .ag-row-group-indent-5': {paddingLeft: '140px'},
  '.ag-rtl .ag-row-group-indent-5': {paddingRight: '140px'},
  '.ag-ltr .ag-row-level-5 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-5 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-6': {
    paddingLeft: '186px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-6': {
    paddingRight: '186px',
  },
  '.ag-ltr .ag-row-group-indent-6': {paddingLeft: '168px'},
  '.ag-rtl .ag-row-group-indent-6': {paddingRight: '168px'},
  '.ag-ltr .ag-row-level-6 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-6 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-7': {
    paddingLeft: '214px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-7': {
    paddingRight: '214px',
  },
  '.ag-ltr .ag-row-group-indent-7': {paddingLeft: '196px'},
  '.ag-rtl .ag-row-group-indent-7': {paddingRight: '196px'},
  '.ag-ltr .ag-row-level-7 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-7 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-8': {
    paddingLeft: '242px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-8': {
    paddingRight: '242px',
  },
  '.ag-ltr .ag-row-group-indent-8': {paddingLeft: '224px'},
  '.ag-rtl .ag-row-group-indent-8': {paddingRight: '224px'},
  '.ag-ltr .ag-row-level-8 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-8 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-9': {
    paddingLeft: '270px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-9': {
    paddingRight: '270px',
  },
  '.ag-ltr .ag-row-group-indent-9': {paddingLeft: '252px'},
  '.ag-rtl .ag-row-group-indent-9': {paddingRight: '252px'},
  '.ag-ltr .ag-row-level-9 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-9 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-10': {
    paddingLeft: '298px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-10': {
    paddingRight: '298px',
  },
  '.ag-ltr .ag-row-group-indent-10': {paddingLeft: '280px'},
  '.ag-rtl .ag-row-group-indent-10': {paddingRight: '280px'},
  '.ag-ltr .ag-row-level-10 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-10 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-11': {
    paddingLeft: '326px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-11': {
    paddingRight: '326px',
  },
  '.ag-ltr .ag-row-group-indent-11': {paddingLeft: '308px'},
  '.ag-rtl .ag-row-group-indent-11': {paddingRight: '308px'},
  '.ag-ltr .ag-row-level-11 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-11 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-12': {
    paddingLeft: '354px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-12': {
    paddingRight: '354px',
  },
  '.ag-ltr .ag-row-group-indent-12': {paddingLeft: '336px'},
  '.ag-rtl .ag-row-group-indent-12': {paddingRight: '336px'},
  '.ag-ltr .ag-row-level-12 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-12 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-13': {
    paddingLeft: '382px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-13': {
    paddingRight: '382px',
  },
  '.ag-ltr .ag-row-group-indent-13': {paddingLeft: '364px'},
  '.ag-rtl .ag-row-group-indent-13': {paddingRight: '364px'},
  '.ag-ltr .ag-row-level-13 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-13 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-14': {
    paddingLeft: '410px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-14': {
    paddingRight: '410px',
  },
  '.ag-ltr .ag-row-group-indent-14': {paddingLeft: '392px'},
  '.ag-rtl .ag-row-group-indent-14': {paddingRight: '392px'},
  '.ag-ltr .ag-row-level-14 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-14 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-15': {
    paddingLeft: '438px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-15': {
    paddingRight: '438px',
  },
  '.ag-ltr .ag-row-group-indent-15': {paddingLeft: '420px'},
  '.ag-rtl .ag-row-group-indent-15': {paddingRight: '420px'},
  '.ag-ltr .ag-row-level-15 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-15 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-16': {
    paddingLeft: '466px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-16': {
    paddingRight: '466px',
  },
  '.ag-ltr .ag-row-group-indent-16': {paddingLeft: '448px'},
  '.ag-rtl .ag-row-group-indent-16': {paddingRight: '448px'},
  '.ag-ltr .ag-row-level-16 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-16 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-17': {
    paddingLeft: '494px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-17': {
    paddingRight: '494px',
  },
  '.ag-ltr .ag-row-group-indent-17': {paddingLeft: '476px'},
  '.ag-rtl .ag-row-group-indent-17': {paddingRight: '476px'},
  '.ag-ltr .ag-row-level-17 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-17 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-18': {
    paddingLeft: '522px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-18': {
    paddingRight: '522px',
  },
  '.ag-ltr .ag-row-group-indent-18': {paddingLeft: '504px'},
  '.ag-rtl .ag-row-group-indent-18': {paddingRight: '504px'},
  '.ag-ltr .ag-row-level-18 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-18 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-19': {
    paddingLeft: '550px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-19': {
    paddingRight: '550px',
  },
  '.ag-ltr .ag-row-group-indent-19': {paddingLeft: '532px'},
  '.ag-rtl .ag-row-group-indent-19': {paddingRight: '532px'},
  '.ag-ltr .ag-row-level-19 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-19 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-20': {
    paddingLeft: '578px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-20': {
    paddingRight: '578px',
  },
  '.ag-ltr .ag-row-group-indent-20': {paddingLeft: '560px'},
  '.ag-rtl .ag-row-group-indent-20': {paddingRight: '560px'},
  '.ag-ltr .ag-row-level-20 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-20 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-21': {
    paddingLeft: '606px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-21': {
    paddingRight: '606px',
  },
  '.ag-ltr .ag-row-group-indent-21': {paddingLeft: '588px'},
  '.ag-rtl .ag-row-group-indent-21': {paddingRight: '588px'},
  '.ag-ltr .ag-row-level-21 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-21 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-22': {
    paddingLeft: '634px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-22': {
    paddingRight: '634px',
  },
  '.ag-ltr .ag-row-group-indent-22': {paddingLeft: '616px'},
  '.ag-rtl .ag-row-group-indent-22': {paddingRight: '616px'},
  '.ag-ltr .ag-row-level-22 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-22 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-23': {
    paddingLeft: '662px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-23': {
    paddingRight: '662px',
  },
  '.ag-ltr .ag-row-group-indent-23': {paddingLeft: '644px'},
  '.ag-rtl .ag-row-group-indent-23': {paddingRight: '644px'},
  '.ag-ltr .ag-row-level-23 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-23 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-24': {
    paddingLeft: '690px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-24': {
    paddingRight: '690px',
  },
  '.ag-ltr .ag-row-group-indent-24': {paddingLeft: '672px'},
  '.ag-rtl .ag-row-group-indent-24': {paddingRight: '672px'},
  '.ag-ltr .ag-row-level-24 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-24 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-25': {
    paddingLeft: '718px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-25': {
    paddingRight: '718px',
  },
  '.ag-ltr .ag-row-group-indent-25': {paddingLeft: '700px'},
  '.ag-rtl .ag-row-group-indent-25': {paddingRight: '700px'},
  '.ag-ltr .ag-row-level-25 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-25 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-26': {
    paddingLeft: '746px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-26': {
    paddingRight: '746px',
  },
  '.ag-ltr .ag-row-group-indent-26': {paddingLeft: '728px'},
  '.ag-rtl .ag-row-group-indent-26': {paddingRight: '728px'},
  '.ag-ltr .ag-row-level-26 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-26 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-27': {
    paddingLeft: '774px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-27': {
    paddingRight: '774px',
  },
  '.ag-ltr .ag-row-group-indent-27': {paddingLeft: '756px'},
  '.ag-rtl .ag-row-group-indent-27': {paddingRight: '756px'},
  '.ag-ltr .ag-row-level-27 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-27 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-28': {
    paddingLeft: '802px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-28': {
    paddingRight: '802px',
  },
  '.ag-ltr .ag-row-group-indent-28': {paddingLeft: '784px'},
  '.ag-rtl .ag-row-group-indent-28': {paddingRight: '784px'},
  '.ag-ltr .ag-row-level-28 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-28 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-29': {
    paddingLeft: '830px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-29': {
    paddingRight: '830px',
  },
  '.ag-ltr .ag-row-group-indent-29': {paddingLeft: '812px'},
  '.ag-rtl .ag-row-group-indent-29': {paddingRight: '812px'},
  '.ag-ltr .ag-row-level-29 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-29 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-30': {
    paddingLeft: '858px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-30': {
    paddingRight: '858px',
  },
  '.ag-ltr .ag-row-group-indent-30': {paddingLeft: '840px'},
  '.ag-rtl .ag-row-group-indent-30': {paddingRight: '840px'},
  '.ag-ltr .ag-row-level-30 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-30 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-31': {
    paddingLeft: '886px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-31': {
    paddingRight: '886px',
  },
  '.ag-ltr .ag-row-group-indent-31': {paddingLeft: '868px'},
  '.ag-rtl .ag-row-group-indent-31': {paddingRight: '868px'},
  '.ag-ltr .ag-row-level-31 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-31 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-32': {
    paddingLeft: '914px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-32': {
    paddingRight: '914px',
  },
  '.ag-ltr .ag-row-group-indent-32': {paddingLeft: '896px'},
  '.ag-rtl .ag-row-group-indent-32': {paddingRight: '896px'},
  '.ag-ltr .ag-row-level-32 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-32 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-33': {
    paddingLeft: '942px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-33': {
    paddingRight: '942px',
  },
  '.ag-ltr .ag-row-group-indent-33': {paddingLeft: '924px'},
  '.ag-rtl .ag-row-group-indent-33': {paddingRight: '924px'},
  '.ag-ltr .ag-row-level-33 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-33 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-34': {
    paddingLeft: '970px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-34': {
    paddingRight: '970px',
  },
  '.ag-ltr .ag-row-group-indent-34': {paddingLeft: '952px'},
  '.ag-rtl .ag-row-group-indent-34': {paddingRight: '952px'},
  '.ag-ltr .ag-row-level-34 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-34 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-35': {
    paddingLeft: '998px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-35': {
    paddingRight: '998px',
  },
  '.ag-ltr .ag-row-group-indent-35': {paddingLeft: '980px'},
  '.ag-rtl .ag-row-group-indent-35': {paddingRight: '980px'},
  '.ag-ltr .ag-row-level-35 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-35 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-36': {
    paddingLeft: '1026px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-36': {
    paddingRight: '1026px',
  },
  '.ag-ltr .ag-row-group-indent-36': {paddingLeft: '1008px'},
  '.ag-rtl .ag-row-group-indent-36': {
    paddingRight: '1008px',
  },
  '.ag-ltr .ag-row-level-36 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-36 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-37': {
    paddingLeft: '1054px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-37': {
    paddingRight: '1054px',
  },
  '.ag-ltr .ag-row-group-indent-37': {paddingLeft: '1036px'},
  '.ag-rtl .ag-row-group-indent-37': {
    paddingRight: '1036px',
  },
  '.ag-ltr .ag-row-level-37 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-37 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-38': {
    paddingLeft: '1082px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-38': {
    paddingRight: '1082px',
  },
  '.ag-ltr .ag-row-group-indent-38': {paddingLeft: '1064px'},
  '.ag-rtl .ag-row-group-indent-38': {
    paddingRight: '1064px',
  },
  '.ag-ltr .ag-row-level-38 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-38 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-39': {
    paddingLeft: '1110px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-39': {
    paddingRight: '1110px',
  },
  '.ag-ltr .ag-row-group-indent-39': {paddingLeft: '1092px'},
  '.ag-rtl .ag-row-group-indent-39': {
    paddingRight: '1092px',
  },
  '.ag-ltr .ag-row-level-39 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-39 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-40': {
    paddingLeft: '1138px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-40': {
    paddingRight: '1138px',
  },
  '.ag-ltr .ag-row-group-indent-40': {paddingLeft: '1120px'},
  '.ag-rtl .ag-row-group-indent-40': {
    paddingRight: '1120px',
  },
  '.ag-ltr .ag-row-level-40 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-40 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-41': {
    paddingLeft: '1166px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-41': {
    paddingRight: '1166px',
  },
  '.ag-ltr .ag-row-group-indent-41': {paddingLeft: '1148px'},
  '.ag-rtl .ag-row-group-indent-41': {
    paddingRight: '1148px',
  },
  '.ag-ltr .ag-row-level-41 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-41 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-42': {
    paddingLeft: '1194px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-42': {
    paddingRight: '1194px',
  },
  '.ag-ltr .ag-row-group-indent-42': {paddingLeft: '1176px'},
  '.ag-rtl .ag-row-group-indent-42': {
    paddingRight: '1176px',
  },
  '.ag-ltr .ag-row-level-42 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-42 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-43': {
    paddingLeft: '1222px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-43': {
    paddingRight: '1222px',
  },
  '.ag-ltr .ag-row-group-indent-43': {paddingLeft: '1204px'},
  '.ag-rtl .ag-row-group-indent-43': {
    paddingRight: '1204px',
  },
  '.ag-ltr .ag-row-level-43 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-43 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-44': {
    paddingLeft: '1250px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-44': {
    paddingRight: '1250px',
  },
  '.ag-ltr .ag-row-group-indent-44': {paddingLeft: '1232px'},
  '.ag-rtl .ag-row-group-indent-44': {
    paddingRight: '1232px',
  },
  '.ag-ltr .ag-row-level-44 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-44 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-45': {
    paddingLeft: '1278px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-45': {
    paddingRight: '1278px',
  },
  '.ag-ltr .ag-row-group-indent-45': {paddingLeft: '1260px'},
  '.ag-rtl .ag-row-group-indent-45': {
    paddingRight: '1260px',
  },
  '.ag-ltr .ag-row-level-45 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-45 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-46': {
    paddingLeft: '1306px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-46': {
    paddingRight: '1306px',
  },
  '.ag-ltr .ag-row-group-indent-46': {paddingLeft: '1288px'},
  '.ag-rtl .ag-row-group-indent-46': {
    paddingRight: '1288px',
  },
  '.ag-ltr .ag-row-level-46 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-46 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-47': {
    paddingLeft: '1334px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-47': {
    paddingRight: '1334px',
  },
  '.ag-ltr .ag-row-group-indent-47': {paddingLeft: '1316px'},
  '.ag-rtl .ag-row-group-indent-47': {
    paddingRight: '1316px',
  },
  '.ag-ltr .ag-row-level-47 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-47 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-48': {
    paddingLeft: '1362px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-48': {
    paddingRight: '1362px',
  },
  '.ag-ltr .ag-row-group-indent-48': {paddingLeft: '1344px'},
  '.ag-rtl .ag-row-group-indent-48': {
    paddingRight: '1344px',
  },
  '.ag-ltr .ag-row-level-48 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-48 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-49': {
    paddingLeft: '1390px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-49': {
    paddingRight: '1390px',
  },
  '.ag-ltr .ag-row-group-indent-49': {paddingLeft: '1372px'},
  '.ag-rtl .ag-row-group-indent-49': {
    paddingRight: '1372px',
  },
  '.ag-ltr .ag-row-level-49 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-49 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-50': {
    paddingLeft: '1418px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-50': {
    paddingRight: '1418px',
  },
  '.ag-ltr .ag-row-group-indent-50': {paddingLeft: '1400px'},
  '.ag-rtl .ag-row-group-indent-50': {
    paddingRight: '1400px',
  },
  '.ag-ltr .ag-row-level-50 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-50 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-51': {
    paddingLeft: '1446px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-51': {
    paddingRight: '1446px',
  },
  '.ag-ltr .ag-row-group-indent-51': {paddingLeft: '1428px'},
  '.ag-rtl .ag-row-group-indent-51': {
    paddingRight: '1428px',
  },
  '.ag-ltr .ag-row-level-51 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-51 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-52': {
    paddingLeft: '1474px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-52': {
    paddingRight: '1474px',
  },
  '.ag-ltr .ag-row-group-indent-52': {paddingLeft: '1456px'},
  '.ag-rtl .ag-row-group-indent-52': {
    paddingRight: '1456px',
  },
  '.ag-ltr .ag-row-level-52 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-52 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-53': {
    paddingLeft: '1502px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-53': {
    paddingRight: '1502px',
  },
  '.ag-ltr .ag-row-group-indent-53': {paddingLeft: '1484px'},
  '.ag-rtl .ag-row-group-indent-53': {
    paddingRight: '1484px',
  },
  '.ag-ltr .ag-row-level-53 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-53 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-54': {
    paddingLeft: '1530px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-54': {
    paddingRight: '1530px',
  },
  '.ag-ltr .ag-row-group-indent-54': {paddingLeft: '1512px'},
  '.ag-rtl .ag-row-group-indent-54': {
    paddingRight: '1512px',
  },
  '.ag-ltr .ag-row-level-54 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-54 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-55': {
    paddingLeft: '1558px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-55': {
    paddingRight: '1558px',
  },
  '.ag-ltr .ag-row-group-indent-55': {paddingLeft: '1540px'},
  '.ag-rtl .ag-row-group-indent-55': {
    paddingRight: '1540px',
  },
  '.ag-ltr .ag-row-level-55 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-55 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-56': {
    paddingLeft: '1586px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-56': {
    paddingRight: '1586px',
  },
  '.ag-ltr .ag-row-group-indent-56': {paddingLeft: '1568px'},
  '.ag-rtl .ag-row-group-indent-56': {
    paddingRight: '1568px',
  },
  '.ag-ltr .ag-row-level-56 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-56 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-57': {
    paddingLeft: '1614px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-57': {
    paddingRight: '1614px',
  },
  '.ag-ltr .ag-row-group-indent-57': {paddingLeft: '1596px'},
  '.ag-rtl .ag-row-group-indent-57': {
    paddingRight: '1596px',
  },
  '.ag-ltr .ag-row-level-57 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-57 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-58': {
    paddingLeft: '1642px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-58': {
    paddingRight: '1642px',
  },
  '.ag-ltr .ag-row-group-indent-58': {paddingLeft: '1624px'},
  '.ag-rtl .ag-row-group-indent-58': {
    paddingRight: '1624px',
  },
  '.ag-ltr .ag-row-level-58 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-58 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-59': {
    paddingLeft: '1670px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-59': {
    paddingRight: '1670px',
  },
  '.ag-ltr .ag-row-group-indent-59': {paddingLeft: '1652px'},
  '.ag-rtl .ag-row-group-indent-59': {
    paddingRight: '1652px',
  },
  '.ag-ltr .ag-row-level-59 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-59 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-60': {
    paddingLeft: '1698px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-60': {
    paddingRight: '1698px',
  },
  '.ag-ltr .ag-row-group-indent-60': {paddingLeft: '1680px'},
  '.ag-rtl .ag-row-group-indent-60': {
    paddingRight: '1680px',
  },
  '.ag-ltr .ag-row-level-60 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-60 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-61': {
    paddingLeft: '1726px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-61': {
    paddingRight: '1726px',
  },
  '.ag-ltr .ag-row-group-indent-61': {paddingLeft: '1708px'},
  '.ag-rtl .ag-row-group-indent-61': {
    paddingRight: '1708px',
  },
  '.ag-ltr .ag-row-level-61 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-61 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-62': {
    paddingLeft: '1754px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-62': {
    paddingRight: '1754px',
  },
  '.ag-ltr .ag-row-group-indent-62': {paddingLeft: '1736px'},
  '.ag-rtl .ag-row-group-indent-62': {
    paddingRight: '1736px',
  },
  '.ag-ltr .ag-row-level-62 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-62 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-63': {
    paddingLeft: '1782px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-63': {
    paddingRight: '1782px',
  },
  '.ag-ltr .ag-row-group-indent-63': {paddingLeft: '1764px'},
  '.ag-rtl .ag-row-group-indent-63': {
    paddingRight: '1764px',
  },
  '.ag-ltr .ag-row-level-63 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-63 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-64': {
    paddingLeft: '1810px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-64': {
    paddingRight: '1810px',
  },
  '.ag-ltr .ag-row-group-indent-64': {paddingLeft: '1792px'},
  '.ag-rtl .ag-row-group-indent-64': {
    paddingRight: '1792px',
  },
  '.ag-ltr .ag-row-level-64 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-64 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-65': {
    paddingLeft: '1838px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-65': {
    paddingRight: '1838px',
  },
  '.ag-ltr .ag-row-group-indent-65': {paddingLeft: '1820px'},
  '.ag-rtl .ag-row-group-indent-65': {
    paddingRight: '1820px',
  },
  '.ag-ltr .ag-row-level-65 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-65 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-66': {
    paddingLeft: '1866px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-66': {
    paddingRight: '1866px',
  },
  '.ag-ltr .ag-row-group-indent-66': {paddingLeft: '1848px'},
  '.ag-rtl .ag-row-group-indent-66': {
    paddingRight: '1848px',
  },
  '.ag-ltr .ag-row-level-66 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-66 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-67': {
    paddingLeft: '1894px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-67': {
    paddingRight: '1894px',
  },
  '.ag-ltr .ag-row-group-indent-67': {paddingLeft: '1876px'},
  '.ag-rtl .ag-row-group-indent-67': {
    paddingRight: '1876px',
  },
  '.ag-ltr .ag-row-level-67 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-67 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-68': {
    paddingLeft: '1922px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-68': {
    paddingRight: '1922px',
  },
  '.ag-ltr .ag-row-group-indent-68': {paddingLeft: '1904px'},
  '.ag-rtl .ag-row-group-indent-68': {
    paddingRight: '1904px',
  },
  '.ag-ltr .ag-row-level-68 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-68 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-69': {
    paddingLeft: '1950px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-69': {
    paddingRight: '1950px',
  },
  '.ag-ltr .ag-row-group-indent-69': {paddingLeft: '1932px'},
  '.ag-rtl .ag-row-group-indent-69': {
    paddingRight: '1932px',
  },
  '.ag-ltr .ag-row-level-69 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-69 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-70': {
    paddingLeft: '1978px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-70': {
    paddingRight: '1978px',
  },
  '.ag-ltr .ag-row-group-indent-70': {paddingLeft: '1960px'},
  '.ag-rtl .ag-row-group-indent-70': {
    paddingRight: '1960px',
  },
  '.ag-ltr .ag-row-level-70 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-70 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-71': {
    paddingLeft: '2006px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-71': {
    paddingRight: '2006px',
  },
  '.ag-ltr .ag-row-group-indent-71': {paddingLeft: '1988px'},
  '.ag-rtl .ag-row-group-indent-71': {
    paddingRight: '1988px',
  },
  '.ag-ltr .ag-row-level-71 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-71 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-72': {
    paddingLeft: '2034px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-72': {
    paddingRight: '2034px',
  },
  '.ag-ltr .ag-row-group-indent-72': {paddingLeft: '2016px'},
  '.ag-rtl .ag-row-group-indent-72': {
    paddingRight: '2016px',
  },
  '.ag-ltr .ag-row-level-72 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-72 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-73': {
    paddingLeft: '2062px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-73': {
    paddingRight: '2062px',
  },
  '.ag-ltr .ag-row-group-indent-73': {paddingLeft: '2044px'},
  '.ag-rtl .ag-row-group-indent-73': {
    paddingRight: '2044px',
  },
  '.ag-ltr .ag-row-level-73 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-73 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-74': {
    paddingLeft: '2090px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-74': {
    paddingRight: '2090px',
  },
  '.ag-ltr .ag-row-group-indent-74': {paddingLeft: '2072px'},
  '.ag-rtl .ag-row-group-indent-74': {
    paddingRight: '2072px',
  },
  '.ag-ltr .ag-row-level-74 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-74 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-75': {
    paddingLeft: '2118px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-75': {
    paddingRight: '2118px',
  },
  '.ag-ltr .ag-row-group-indent-75': {paddingLeft: '2100px'},
  '.ag-rtl .ag-row-group-indent-75': {
    paddingRight: '2100px',
  },
  '.ag-ltr .ag-row-level-75 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-75 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-76': {
    paddingLeft: '2146px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-76': {
    paddingRight: '2146px',
  },
  '.ag-ltr .ag-row-group-indent-76': {paddingLeft: '2128px'},
  '.ag-rtl .ag-row-group-indent-76': {
    paddingRight: '2128px',
  },
  '.ag-ltr .ag-row-level-76 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-76 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-77': {
    paddingLeft: '2174px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-77': {
    paddingRight: '2174px',
  },
  '.ag-ltr .ag-row-group-indent-77': {paddingLeft: '2156px'},
  '.ag-rtl .ag-row-group-indent-77': {
    paddingRight: '2156px',
  },
  '.ag-ltr .ag-row-level-77 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-77 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-78': {
    paddingLeft: '2202px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-78': {
    paddingRight: '2202px',
  },
  '.ag-ltr .ag-row-group-indent-78': {paddingLeft: '2184px'},
  '.ag-rtl .ag-row-group-indent-78': {
    paddingRight: '2184px',
  },
  '.ag-ltr .ag-row-level-78 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-78 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-79': {
    paddingLeft: '2230px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-79': {
    paddingRight: '2230px',
  },
  '.ag-ltr .ag-row-group-indent-79': {paddingLeft: '2212px'},
  '.ag-rtl .ag-row-group-indent-79': {
    paddingRight: '2212px',
  },
  '.ag-ltr .ag-row-level-79 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-79 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-80': {
    paddingLeft: '2258px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-80': {
    paddingRight: '2258px',
  },
  '.ag-ltr .ag-row-group-indent-80': {paddingLeft: '2240px'},
  '.ag-rtl .ag-row-group-indent-80': {
    paddingRight: '2240px',
  },
  '.ag-ltr .ag-row-level-80 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-80 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-81': {
    paddingLeft: '2286px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-81': {
    paddingRight: '2286px',
  },
  '.ag-ltr .ag-row-group-indent-81': {paddingLeft: '2268px'},
  '.ag-rtl .ag-row-group-indent-81': {
    paddingRight: '2268px',
  },
  '.ag-ltr .ag-row-level-81 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-81 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-82': {
    paddingLeft: '2314px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-82': {
    paddingRight: '2314px',
  },
  '.ag-ltr .ag-row-group-indent-82': {paddingLeft: '2296px'},
  '.ag-rtl .ag-row-group-indent-82': {
    paddingRight: '2296px',
  },
  '.ag-ltr .ag-row-level-82 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-82 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-83': {
    paddingLeft: '2342px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-83': {
    paddingRight: '2342px',
  },
  '.ag-ltr .ag-row-group-indent-83': {paddingLeft: '2324px'},
  '.ag-rtl .ag-row-group-indent-83': {
    paddingRight: '2324px',
  },
  '.ag-ltr .ag-row-level-83 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-83 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-84': {
    paddingLeft: '2370px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-84': {
    paddingRight: '2370px',
  },
  '.ag-ltr .ag-row-group-indent-84': {paddingLeft: '2352px'},
  '.ag-rtl .ag-row-group-indent-84': {
    paddingRight: '2352px',
  },
  '.ag-ltr .ag-row-level-84 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-84 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-85': {
    paddingLeft: '2398px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-85': {
    paddingRight: '2398px',
  },
  '.ag-ltr .ag-row-group-indent-85': {paddingLeft: '2380px'},
  '.ag-rtl .ag-row-group-indent-85': {
    paddingRight: '2380px',
  },
  '.ag-ltr .ag-row-level-85 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-85 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-86': {
    paddingLeft: '2426px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-86': {
    paddingRight: '2426px',
  },
  '.ag-ltr .ag-row-group-indent-86': {paddingLeft: '2408px'},
  '.ag-rtl .ag-row-group-indent-86': {
    paddingRight: '2408px',
  },
  '.ag-ltr .ag-row-level-86 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-86 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-87': {
    paddingLeft: '2454px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-87': {
    paddingRight: '2454px',
  },
  '.ag-ltr .ag-row-group-indent-87': {paddingLeft: '2436px'},
  '.ag-rtl .ag-row-group-indent-87': {
    paddingRight: '2436px',
  },
  '.ag-ltr .ag-row-level-87 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-87 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-88': {
    paddingLeft: '2482px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-88': {
    paddingRight: '2482px',
  },
  '.ag-ltr .ag-row-group-indent-88': {paddingLeft: '2464px'},
  '.ag-rtl .ag-row-group-indent-88': {
    paddingRight: '2464px',
  },
  '.ag-ltr .ag-row-level-88 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-88 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-89': {
    paddingLeft: '2510px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-89': {
    paddingRight: '2510px',
  },
  '.ag-ltr .ag-row-group-indent-89': {paddingLeft: '2492px'},
  '.ag-rtl .ag-row-group-indent-89': {
    paddingRight: '2492px',
  },
  '.ag-ltr .ag-row-level-89 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-89 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-90': {
    paddingLeft: '2538px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-90': {
    paddingRight: '2538px',
  },
  '.ag-ltr .ag-row-group-indent-90': {paddingLeft: '2520px'},
  '.ag-rtl .ag-row-group-indent-90': {
    paddingRight: '2520px',
  },
  '.ag-ltr .ag-row-level-90 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-90 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-91': {
    paddingLeft: '2566px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-91': {
    paddingRight: '2566px',
  },
  '.ag-ltr .ag-row-group-indent-91': {paddingLeft: '2548px'},
  '.ag-rtl .ag-row-group-indent-91': {
    paddingRight: '2548px',
  },
  '.ag-ltr .ag-row-level-91 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-91 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-92': {
    paddingLeft: '2594px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-92': {
    paddingRight: '2594px',
  },
  '.ag-ltr .ag-row-group-indent-92': {paddingLeft: '2576px'},
  '.ag-rtl .ag-row-group-indent-92': {
    paddingRight: '2576px',
  },
  '.ag-ltr .ag-row-level-92 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-92 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-93': {
    paddingLeft: '2622px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-93': {
    paddingRight: '2622px',
  },
  '.ag-ltr .ag-row-group-indent-93': {paddingLeft: '2604px'},
  '.ag-rtl .ag-row-group-indent-93': {
    paddingRight: '2604px',
  },
  '.ag-ltr .ag-row-level-93 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-93 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-94': {
    paddingLeft: '2650px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-94': {
    paddingRight: '2650px',
  },
  '.ag-ltr .ag-row-group-indent-94': {paddingLeft: '2632px'},
  '.ag-rtl .ag-row-group-indent-94': {
    paddingRight: '2632px',
  },
  '.ag-ltr .ag-row-level-94 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-94 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-95': {
    paddingLeft: '2678px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-95': {
    paddingRight: '2678px',
  },
  '.ag-ltr .ag-row-group-indent-95': {paddingLeft: '2660px'},
  '.ag-rtl .ag-row-group-indent-95': {
    paddingRight: '2660px',
  },
  '.ag-ltr .ag-row-level-95 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-95 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-96': {
    paddingLeft: '2706px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-96': {
    paddingRight: '2706px',
  },
  '.ag-ltr .ag-row-group-indent-96': {paddingLeft: '2688px'},
  '.ag-rtl .ag-row-group-indent-96': {
    paddingRight: '2688px',
  },
  '.ag-ltr .ag-row-level-96 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-96 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-97': {
    paddingLeft: '2734px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-97': {
    paddingRight: '2734px',
  },
  '.ag-ltr .ag-row-group-indent-97': {paddingLeft: '2716px'},
  '.ag-rtl .ag-row-group-indent-97': {
    paddingRight: '2716px',
  },
  '.ag-ltr .ag-row-level-97 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-97 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-98': {
    paddingLeft: '2762px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-98': {
    paddingRight: '2762px',
  },
  '.ag-ltr .ag-row-group-indent-98': {paddingLeft: '2744px'},
  '.ag-rtl .ag-row-group-indent-98': {
    paddingRight: '2744px',
  },
  '.ag-ltr .ag-row-level-98 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-98 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-99': {
    paddingLeft: '2790px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-99': {
    paddingRight: '2790px',
  },
  '.ag-ltr .ag-row-group-indent-99': {paddingLeft: '2772px'},
  '.ag-rtl .ag-row-group-indent-99': {
    paddingRight: '2772px',
  },
  '.ag-ltr .ag-row-level-99 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-99 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row-group-leaf-indent': {marginLeft: '28px'},
  '.ag-rtl .ag-row-group-leaf-indent': {marginRight: '28px'},
};

export const dgInputs = {
  "input[class^='ag-']": {
    margin: '0',
    backgroundColor: ['#fff', 'var(--ag-background-color, #fff)'],
  },
  "textarea[class^='ag-'],\n  select[class^='ag-']": {
    backgroundColor: '#fff',
  },
  "input[class^='ag-']:not([type]),\n  input[class^='ag-'][type='text'],\n  input[class^='ag-'][type='number'],\n  input[class^='ag-'][type='tel'],\n  input[class^='ag-'][type='date'],\n  input[class^='ag-'][type='datetime-local'],\n  textarea[class^='ag-']":
    {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      color: 'inherit',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#babfc7',
      minHeight: '24px',
      borderRadius: '3px',
    },
  "input[class^='ag-']:not([type]):disabled,\n    input[class^='ag-'][type='text']:disabled,\n    input[class^='ag-'][type='number']:disabled,\n    input[class^='ag-'][type='tel']:disabled,\n    input[class^='ag-'][type='date']:disabled,\n    input[class^='ag-'][type='datetime-local']:disabled,\n    textarea[class^='ag-']:disabled":
    {
      color: 'rgba(24, 29, 31, 0.5)',

      backgroundColor: '#f1f2f4',
      borderColor: 'rgba(186, 191, 199, 0.3)',
    },
  "input[class^='ag-']:not([type]):focus,\n    input[class^='ag-'][type='text']:focus,\n    input[class^='ag-'][type='number']:focus,\n    input[class^='ag-'][type='tel']:focus,\n    input[class^='ag-'][type='date']:focus,\n    input[class^='ag-'][type='datetime-local']:focus,\n    textarea[class^='ag-']:focus":
    {
      outline: 'none',
      boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
      borderColor: 'rgba(33, 150, 243, 0.4)',
    },
  "input[class^='ag-'][type='number']": {
    MozAppearance: 'textfield',
  },
  "input[class^='ag-'][type='number']::-webkit-outer-spin-button, input[class^='ag-'][type='number']::-webkit-inner-spin-button":
    {
      WebkitAppearance: 'none',
      margin: '0',
    },
  "input[class^='ag-'][type='range']": {
    padding: '0',
    WebkitAppearance: 'none',
    width: '100%',
    height: '100%',
    background: 'none',
    overflow: 'visible',
  },
  "input[class^='ag-'][type='button']:focus, button[class^='ag-']:focus": {
    boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
  },
};

const gridTheme = {
  WebkitFontSmoothing: 'antialiased',
  color: '#091E42',
  background: 'white',
  fontSize: 'sm',
  lineHeight: 'normal',

  width: '100%',
  height: '100%',
  display: 'flex',

  // Three classes for storybook purpose
  '.ag-storybook-oldCar': {
    backgroundColor: '#FFA500 !important',
  },
  '.ag-storybook-veteran': {
    backgroundColor: '#FF0000 !important',
  },
  '.ag-storybook-model': {
    backgroundColor: '#00ff00 !important',
  },
  '.ag-react-container': {
    width: '100%',
  },
  '.ag-cell > span': {
    inlineSize: 'max-content',
    minWidth: '0',
    maxWidth: '100%',
  },
  '.ag-cell': {
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    '.ellipsis': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    a: {
      color: '#0065FF',
    },
    'a:hover': {
      textDeforation: 'underline',
    },
  },
  '.ag-pinned-right-header .ag-header-cell, .ag-pinned-right-cols-container .ag-cell': {
    borderRightColor: 'transparent',
  },

  '.ag-root-wrapper': {
    marginRight: '0px !important',
  },
  "[class^='ag-'], [class^='ag-']:focus, [class^='ag-']:after, [class^='ag-']:before": {
    boxSizing: 'border-box',
    outline: 'none',
  },
  "[class^='ag-']::-ms-clear": {display: 'none'},
  '.ag-checkbox .ag-input-wrapper,  .ag-radio-button .ag-input-wrapper': {
    overflow: 'visible',
  },
  '.ag-range-field .ag-input-wrapper': {height: '100%'},
  '.ag-toggle-button': {
    flex: 'none',
    width: 'unset',
    minWidth: 'unset',
  },
  '.ag-ltr .ag-label-align-right .ag-label': {
    marginLeft: '12px',
  },
  '.ag-rtl .ag-label-align-right .ag-label': {
    marginRight: '2',
  },

  ...dgInputs,

  '.ag-drag-handle': {
    color: '#A0AEC0',
  },
  '.ag-list-item, .ag-virtual-list-item': {
    height: '24px',
  },
  '.ag-keyboard-focus .ag-virtual-list-item:focus': {
    outline: 'none',
  },
  '.ag-keyboard-focus .ag-virtual-list-item:focus::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    top: '4px',
    left: '4px',
    display: 'block',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    border: '1px solid',
    borderColor: 'rgba(33, 150, 243, 0.4)',
  },
  '.ag-select-list': {
    backgroundColor: '#fff',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  '.ag-list-item': {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '.ag-list-item.ag-active-item': {
    backgroundColor: 'rgba(33, 150, 243, 0.1)',
  },
  '.ag-select-list-item': {
    paddingLeft: '4px',
    paddingRight: '4px',
    cursor: 'default',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
  '.ag-select-list-item span': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '.ag-select .ag-picker-field-wrapper': {
    backgroundColor: '#fff',
    minHeight: '24px',
    cursor: 'default',
  },
  '.ag-select.ag-disabled .ag-picker-field-wrapper:focus': {
    boxShadow: 'none',
  },
  '.ag-select .ag-picker-field-display': {
    margin: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '.ag-select .ag-picker-field-icon': {
    display: 'flex',
    alignItems: 'center',
  },
  '.ag-select.ag-disabled': {opacity: 0.5},
  '.ag-rich-select': {
    backgroundColor: '#f8f8f8',
  },
  '.ag-rich-select-list': {
    width: '100%',
    minWidth: '200px',
    height: '273px',
  },
  '.ag-rich-select-value': {
    padding: '0 6px 0 18px',
    borderBottom: 'solid 1px',
    borderBottomColor: '#dde2eb',
  },
  '.ag-rich-select-virtual-list-item': {
    cursor: 'default',
    height: '24px',
  },
  '.ag-rich-select-virtual-list-item:hover': {
    backgroundColor: 'rgba(33, 150, 243, 0.1)',
  },
  '.ag-rich-select-row': {paddingLeft: '18px'},
  '.ag-rich-select-row-selected': {
    backgroundColor: 'rgba(33, 150, 243, 0.3)',
  },
  '.ag-row-drag,  .ag-selection-checkbox,  .ag-group-expanded,  .ag-group-contracted': {
    color: '#181d1f',
  },
  '.ag-ltr .ag-row-drag, .ag-ltr .ag-selection-checkbox, .ag-ltr .ag-group-expanded, .ag-ltr .ag-group-contracted':
    {
      marginRight: '12px',
    },
  '.ag-rtl .ag-row-drag, .ag-rtl .ag-selection-checkbox, .ag-rtl .ag-group-expanded, .ag-rtl .ag-group-contracted':
    {
      marginLeft: '12px',
    },
  '.ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value)': {
    display: 'flex',
    alignItems: 'center',
    flex: 'none',
    maxWidth: '100%',
  },
  '.ag-group-expanded,  .ag-group-contracted': {
    cursor: 'pointer',
  },
  '.ag-group-title-bar-icon': {
    cursor: 'pointer',
    flex: 'none',
    color: '#181d1f',
  },
  '.ag-ltr .ag-group-child-count': {marginLeft: '2px'},
  '.ag-rtl .ag-group-child-count': {marginRight: '2px'},
  '.ag-group-title-bar': {
    backgroundColor: '#fff',
    padding: '12px 0px 12px 16px',
  },
  '.ag-group-toolbar': {padding: '6px'},
  '.ag-disabled-group-title-bar, .ag-disabled-group-container': {
    opacity: 0.5,
  },
  '.group-item': {margin: '3px 0'},
  '.ag-label': {whiteSpace: 'nowrap'},
  '.ag-rtl .ag-label': {marginLeft: '6px'},
  '.ag-label-align-top .ag-label': {marginBottom: '3px'},
  '.ag-ltr .ag-slider-field, .ag-ltr .ag-angle-select-field': {
    marginRight: '12px',
  },
  '.ag-rtl .ag-slider-field, .ag-rtl .ag-angle-select-field': {
    marginLeft: '12px',
  },
  '.ag-angle-select-parent-circle': {
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    border: 'solid 1px',
    borderColor: '#babfc7',
    backgroundColor: '#fff',
  },
  '.ag-angle-select-child-circle': {
    top: '4px',
    left: '12px',
    width: '6px',
    height: '6px',
    marginLeft: '-3px',
    marginTop: '-4px',
    borderRadius: '3px',
    backgroundColor: '#181d1f',
  },
  '.ag-picker-field-wrapper': {
    border: '1px solid',
    borderColor: '#babfc7',
    borderRadius: '5px',
  },
  '.ag-picker-field-wrapper:focus': {
    boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
  },
  '.ag-picker-field-button': {
    backgroundColor: '#fff',
    color: '#181d1f',
  },
  '.ag-dialog.ag-color-dialog': {borderRadius: '5px'},
  '.ag-color-picker .ag-picker-field-display': {
    height: '16px',
  },
  '.ag-color-panel': {padding: '6px'},
  '.ag-spectrum-color': {
    backgroundColor: 'red',
    borderRadius: '2px',
  },
  '.ag-spectrum-tools': {padding: '10px'},
  '.ag-spectrum-sat': {
    backgroundImage: 'linear-gradient(to right, white, rgba(204, 154, 129, 0))',
  },
  '.ag-spectrum-val': {
    backgroundImage: 'linear-gradient(to top, black, rgba(204, 154, 129, 0))',
  },
  '.ag-spectrum-dragger': {
    borderRadius: '12px',
    height: '12px',
    width: '12px',
    border: '1px solid white',
    background: 'black',
    boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.24)',
  },
  '.ag-spectrum-hue-background': {borderRadius: '2px'},
  '.ag-spectrum-alpha-background': {borderRadius: '2px'},
  '.ag-spectrum-tool': {
    marginBottom: '10px',
    height: '11px',
    borderRadius: '2px',
  },
  '.ag-spectrum-slider': {
    marginTop: '-12px',
    width: '13px',
    height: '13px',
    borderRadius: '13px',
    backgroundColor: '#f8f8f8',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
  },
  '.ag-recent-color': {margin: '0 3px'},
  '.ag-recent-color:first-of-type': {marginLeft: '0'},
  '.ag-recent-color:last-of-type': {marginRight: '0'},

  '.ag-dragging-range-handle .ag-dialog,  .ag-dragging-fill-handle .ag-dialog': {
    opacity: 0.7,
    pointerEvents: 'none',
  },
  '.ag-dialog': {
    borderRadius: '3px',
    border: 'solid 1px',
    borderColor: '#babfc7',
  },
  '.ag-panel': {
    backgroundColor: '#fff',
  },
  '.ag-panel-title-bar.ag-default-panel-title-bar.ag-unselectable': {
    backgroundColor: '#fff',
    borderColor: '#DFE1E6',
  },
  '.ag-column-select-column-drag-handle': {
    visibility: 'hidden',
  },
  '.ag-column-select-column-group-drag-handle': {
    visibility: 'hidden',
  },
  '.ag-panel-title-bar': {
    backgroundColor: '#f8f8f8',
    color: '#181d1f',

    height: '48px',
    padding: '6px 18px',
    borderBottom: 'solid 1px',
    borderBottomColor: '#babfc7',
  },
  '.ag-ltr .ag-panel-title-bar-button': {
    marginLeft: '12px',
    marginRight: '6px',
  },
  '.ag-rtl .ag-panel-title-bar-button': {
    marginRight: '12px',
    marginLeft: '6px',
  },
  '.ag-popup .ag-tooltip': {
    paddingInlineStart: '8px',
    paddingInlineEnd: '8px',
    paddingTop: '4px',
    paddingBottom: '4px',
    color: 'var(--eag-colors-palettes-white-10-100)',
    fontWeight: 'var(--eag-fontWeights-medium)',
    fontSize: 'var(--eag-fontSizes-sm)',
    boxShadow: 'var(--eag-shadows-md)',
    maxWidth: '200px',
    zIndex: 'var(--eag-zIndices-tooltip)',
    backgroundColor: 'var(--eag-colors-palettes-neutral-900-100)',
    borderRadius: 'var(--eag-radii-small)',
    transition: 'opacity 0.2s',
  },
  '.ag-tooltip.ag-tooltip-hiding': {opacity: 0},

  ...dgIndent,

  '.ag-column-select-header-icon': {cursor: 'pointer'},
  '.ag-keyboard-focus .ag-column-select-header-icon:focus': {
    outline: 'none',
  },
  '.ag-keyboard-focus .ag-column-select-header-icon:focus::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    top: '0px',
    left: '0px',
    display: 'block',
    width: 'calc(100% - 0px)',
    height: 'calc(100% - 0px)',
    border: '1px solid',
    borderColor: 'rgba(33, 150, 243, 0.4)',
  },
  '.ag-ltr .ag-column-group-icons:not(:last-of-type), .ag-ltr , .ag-ltr .ag-column-select-header-checkbox:not(:last-of-type), .ag-ltr .ag-column-select-header-filter-wrapper:not(:last-of-type), .ag-ltr .ag-column-select-checkbox:not(:last-of-type), .ag-ltr, .ag-ltr, .ag-ltr .ag-column-select-column-label:not(:last-of-type)':
    {
      marginRight: '16px',
    },
  '.ag-rtl .ag-column-group-icons:not(:last-of-type), .ag-rtl .ag-column-select-header-icon:not(:last-of-type), .ag-rtl .ag-column-select-header-checkbox:not(:last-of-type), .ag-rtl .ag-column-select-header-filter-wrapper:not(:last-of-type), .ag-rtl .ag-column-select-checkbox:not(:last-of-type), .ag-rtl, .ag-rtl, .ag-rtl .ag-column-select-column-label:not(:last-of-type)':
    {
      marginLeft: '12px',
    },
  '.ag-keyboard-focus .ag-column-select-virtual-list-item:focus': {
    outline: 'none',
  },
  '.ag-keyboard-focus .ag-column-select-virtual-list-item:focus::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    top: '1px',
    left: '1px',
    display: 'block',
    width: 'calc(100% - 2px)',
    height: 'calc(100% - 2px)',
    border: '1px solid',
    borderColor: 'rgba(33, 150, 243, 0.4)',
  },
  '.ag-column-select-column-group:not(:last-of-type),  .ag-column-select-column:not(:last-of-type)':
    {
      marginBottom: '9px',
    },
  '.ag-column-select-column-readonly,  .ag-column-select-column-group-readonly': {
    color: 'rgba(24, 29, 31, 0.5)',
    pointerEvents: 'none',
  },

  '.ag-value-change-delta': {paddingRight: '2px'},
  '.ag-value-change-delta-up': {
    color: '#43a047',
  },
  '.ag-value-change-delta-down': {
    color: '#e53935',
  },
  '.ag-value-change-value': {
    backgroundColor: 'transparent',
    borderRadius: '1px',
    paddingLeft: '1px',
    paddingRight: '1px',
    transition: 'backgroundColor 1s',
  },
  '.ag-value-change-value-highlight': {
    backgroundColor: 'rgba(22, 160, 133, 0.5)',

    transition: 'backgroundColor 0.1s',
  },
  '.ag-cell-data-changed': {
    backgroundColor: 'rgba(22, 160, 133, 0.5) !important',
  },
  '.ag-cell-data-changed-animation': {
    backgroundColor: 'transparent',
  },
  '.ag-cell-highlight': {
    backgroundColor: '#2196f3 !important',
  },
  '.ag-row': {
    borderColor: '#CBD5E0',
    borderBottomStyle: 'solid',
    backgroundColor: 'white',
  },
  '.ag-row[row-id*="detail_"] .ag-react-container': {
    height: 'auto',
  },
  '.ag-row-highlight-above::after, .ag-row-highlight-below::after': {
    content: "''",
    position: 'absolute',
    width: 'calc(100% - 1px)',
    height: '1px',
    backgroundColor: '#2196f3',
    left: '1px',
  },
  '.ag-row-highlight-above::after': {top: '-1px'},
  '.ag-row-highlight-above.ag-row-first::after': {top: '0'},
  '.ag-row-highlight-below::after': {bottom: '0px'},
  '.ag-row-hover': {
    backgroundColor: 'rgba(33, 150, 243, 0.1)',
  },
  '.ag-ltr .ag-right-aligned-cell': {textAlign: 'right'},
  '.ag-rtl .ag-right-aligned-cell': {textAlign: 'left'},
  '.ag-right-aligned-cell': {
    justifyContent: 'flex-end',
  },
  '.ag-ltr .ag-right-aligned-cell .ag-cell-value, .ag-ltr .ag-right-aligned-cell .ag-group-value': {
    marginLeft: 'auto',
  },
  '.ag-rtl .ag-right-aligned-cell .ag-cell-value, .ag-rtl .ag-right-aligned-cell .ag-group-value': {
    marginRight: 'auto',
  },
  '.ag-cell-wrapper': {
    height: 'auto',
  },
  '.ag-cell-wrapper.ag-row-group': {
    alignItems: 'center',
  },
  '.ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group': {
    border: '1px solid transparent',
    paddingLeft: '8px',
    paddingRight: '8px',
    WebkitFontSmoothing: 'subpixel-antialiased',
  },
  '.ag-row > .ag-cell-wrapper': {
    paddingLeft: '17px',
    paddingRight: '17px',
  },
  '.ag-row-dragging': {cursor: 'move', opacity: 0.5},
  '.ag-large-text-input': {height: 'auto', padding: '18px'},
  '.ag-details-row': {
    padding: '30px',
    backgroundColor: '#fff',
  },
  '.ag-layout-auto-height .ag-center-cols-clipper, .ag-layout-auto-height .ag-center-cols-container, .ag-layout-print .ag-center-cols-clipper, .ag-layout-print .ag-center-cols-container':
    {
      minHeight: '150px',
    },
  '.ag-floating-bottom-container, .ag-center-cols-container': {
    minWidth: '100%',
  },
  '.ag-overlay-loading-wrapper': {
    backgroundColor: 'rgba(255, 255, 255, 0.66)',
  },
  '.ag-overlay-loading-center': {
    border: 'solid 1px',
    borderColor: '#babfc7',
    background: '#fff',
    borderRadius: '3px',
    boxShadow: '0 1px 4px 1px rgba(186, 191, 199, 0.4)',
    padding: '6px',
  },
  '.ag-overlay-no-rows-wrapper.ag-layout-auto-height': {
    paddingTop: '60px',
  },
  '.ag-loading': {
    paddingLeft: '18px',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  '.ag-loading-icon': {paddingRight: '12px'},
  '.ag-icon-loading': {
    WebkitAnimationName: 'spin',
    animationName: 'spin',
    WebkitAnimationDuration: '1000ms',
    animationDuration: '1000ms',
    WebkitAnimationIterationCount: 'infinite',
    animationIterationCount: 'infinite',
    WebkitAnimationTimingFunction: 'linear',
    animationTimingFunction: 'linear',
  },
  '@-webkit-keyframes spin': {
    from: {
      WebkitTransform: 'rotate(0deg)',
      transform: 'rotate(0deg)',
    },
    to: {
      WebkitTransform: 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes spin': {
    from: {
      WebkitTransform: 'rotate(0deg)',
      transform: 'rotate(0deg)',
    },
    to: {
      WebkitTransform: 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },
  '.ag-floating-top': {
    borderBottom: 'solid 1px',
    borderBottomColor: '#babfc7',
  },
  '.ag-floating-bottom': {
    borderTop: 'solid 2px',
    borderBottom: '0px',
    borderTopColor: '#E2E8F0',
  },
  '.ag-row-selected': {
    backgroundColor: 'rgba(33, 150, 243, 0.3)',
  },
  '.ag-cell-range-selected:not(.ag-cell-focus),  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing)':
    {
      backgroundColor: 'rgba(33, 150, 243, 0.2)',
    },
  '.ag-cell-range-selected:not(.ag-cell-focus).ag-cell-range-chart,    .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing).ag-cell-range-chart':
    {
      backgroundColor: 'rgba(0, 88, 255, 0.1) !important',
    },
  '.ag-cell-range-selected:not(.ag-cell-focus).ag-cell-range-chart.ag-cell-range-chart-category,      .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing).ag-cell-range-chart.ag-cell-range-chart-category':
    {
      backgroundColor: 'rgba(0, 255, 132, 0.1) !important',
    },
  '.ag-cell-range-selected-1:not(.ag-cell-focus),  .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing)':
    {
      backgroundColor: 'rgba(33, 150, 243, 0.2)',
    },
  '.ag-cell-range-selected-2:not(.ag-cell-focus),  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-2':
    {
      backgroundColor: 'rgba(33, 150, 243, 0.36)',
    },
  '.ag-cell-range-selected-3:not(.ag-cell-focus),  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-3':
    {
      backgroundColor: 'rgba(33, 150, 243, 0.488)',
    },
  '.ag-cell-range-selected-4:not(.ag-cell-focus),  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-4':
    {
      backgroundColor: 'rgba(33, 150, 243, 0.5904)',
    },
  '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-top': {
    borderColor: 'transparent',
    borderTopColor: '#2196f3',
    borderImage: 'none',
    borderTop: '1px solid',
  },
  '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right': {
    borderColor: 'transparent',
    borderRightColor: '#2196f3',
    borderImage: 'none',
    borderRight: '1px solid',
  },
  '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom': {
    borderColor: 'transparent',
    borderBottomColor: '#2196f3',
    borderImage: 'none',
    borderBottom: '1px solid',
  },
  '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left': {
    borderColor: 'transparent',
    borderLeftColor: '#2196f3',
    borderImage: 'none',
    borderLeft: '1px solid',
  },
  '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left.ag-cell-range-top':
    {
      borderLeftColor: '#2196f3',
      borderTopColor: '#2196f3',
    },
  '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right.ag-cell-range-top':
    {
      borderRightColor: '#2196f3',
      borderTopColor: '#2196f3',
    },
  '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left.ag-cell-range-bottom':
    {
      borderLeftColor: '#2196f3',
      borderBottomColor: '#2196f3',
    },
  '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right.ag-cell-range-bottom':
    {
      borderRightColor: '#2196f3',
      borderBottomColor: '#2196f3',
    },
  '.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right.ag-cell-range-left':
    {
      borderRightColor: '#2196f3',
      borderLeftColor: '#2196f3',
    },
  '.ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),  .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),  .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,  .ag-ltr .ag-cell-range-single-cell,  .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),  .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),  .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,  .ag-rtl .ag-cell-range-single-cell,  .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle':
    {
      border: '1px solid',
      borderColor: '#2196f3',
      outline: 'initial',
    },
  '.ag-cell.ag-selection-fill-top,  .ag-cell.ag-selection-fill-top.ag-cell-range-selected': {
    borderTop: '1px dashed',
    borderTopColor: '#2196f3',
  },
  '.ag-ltr .ag-cell.ag-selection-fill-right, .ag-ltr .ag-cell.ag-selection-fill-right.ag-cell-range-selected':
    {
      borderRight: '1px dashed',
      borderRightColor: '#2196f3',
    },
  '.ag-rtl .ag-cell.ag-selection-fill-right, .ag-rtl .ag-cell.ag-selection-fill-right.ag-cell-range-selected':
    {
      borderLeft: '1px dashed',
      borderLeftColor: '#2196f3',
    },
  '.ag-cell.ag-selection-fill-bottom,  .ag-cell.ag-selection-fill-bottom.ag-cell-range-selected': {
    borderBottom: '1px dashed',
    borderBottomColor: '#2196f3',
  },
  '.ag-ltr .ag-cell.ag-selection-fill-left, .ag-ltr .ag-cell.ag-selection-fill-left.ag-cell-range-selected':
    {
      borderLeft: '1px dashed',
      borderLeftColor: '#2196f3',
    },
  '.ag-rtl .ag-cell.ag-selection-fill-left, .ag-rtl .ag-cell.ag-selection-fill-left.ag-cell-range-selected':
    {
      borderRight: '1px dashed',
      borderRightColor: '#2196f3',
    },
  '.ag-fill-handle, .ag-range-handle': {
    position: 'absolute',
    width: '6px',
    height: '6px',
    bottom: '-1px',
    backgroundColor: '#2196f3',
  },
  '.ag-ltr .ag-fill-handle, .ag-ltr .ag-range-handle': {
    right: '-1px',
  },
  '.ag-rtl .ag-fill-handle, .ag-rtl .ag-range-handle': {
    left: '-1px',
  },
  '.ag-fill-handle': {cursor: 'cell'},
  '.ag-range-handle': {cursor: 'nwse-resize'},
  '.ag-menu .ag-menu-body  .ag-filter': {
    padding: '16px',
  },
  '.ag-menu': {
    border: 'solid 1px',
    borderColor: '#E2E8F0',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.12)',
    overflow: 'visible',
    width: '400px',
  },

  '.ag-menu-separator': {
    height: '13px',
    display: 'flex',
    alignItems: 'center',
  },
  '.ag-menu-separator-part': {
    width: '100%',
  },
  '.ag-menu-separator-part::after': {
    content: '""',
    display: 'block',
    borderTop: 'solid 1px',
    borderTopColor: '#E2E8F0',
    width: '100%',
  },
  '.ag-menu-option-part, .ag-compact-menu-option-part': {
    lineHeight: '16px',
    padding: '8px 0',
  },
  '.ag-menu-option-disabled, .ag-compact-menu-option-disabled': {
    opacity: 0.5,
  },
  '.ag-menu-option-icon, .ag-compact-menu-option-icon': {
    width: '30px',
  },
  '.ag-ltr .ag-menu-option-icon, .ag-ltr .ag-compact-menu-option-icon': {
    paddingLeft: '12px',
  },
  '.ag-rtl .ag-menu-option-icon, .ag-rtl .ag-compact-menu-option-icon': {
    paddingRight: '12px',
  },
  '.ag-menu-option-text, .ag-compact-menu-option-text': {
    paddingLeft: '12px',
    paddingRight: '12px',
    width: '100%',
  },
  '.ag-ltr .ag-menu-option-shortcut, .ag-ltr .ag-compact-menu-option-shortcut': {
    paddingRight: '6px',
  },
  '.ag-rtl .ag-menu-option-shortcut, .ag-rtl .ag-compact-menu-option-shortcut': {
    paddingLeft: '6px',
  },
  '.ag-menu-option-popup-pointer, .ag-compact-menu-option-popup-pointer': {
    paddingRight: '6px',
  },
  '.ag-tabs': {
    transform: 'translate(10px, 25px)',
    borderRadius: '8px',
    borderColor: '#E2E8F0',
  },
  '.ag-tabs-header': {
    minWidth: '220px',
    width: '100%',
    display: 'flex',
    borderBottom: 'solid 1px',
    borderBottomColor: '#babfc7',
  },
  '.ag-tab': {
    borderBottom: '2px solid transparent',
    display: 'flex',
    flex: ['none', '1 1 auto'],
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'color 0.4s',
    padding: '9px',
  },
  '.ag-keyboard-focus .ag-tab:focus': {outline: 'none'},
  '.ag-keyboard-focus .ag-tab:focus::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    top: '4px',
    left: '4px',
    display: 'block',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    border: '1px solid',
    borderColor: 'rgba(33, 150, 243, 0.4)',
  },
  '.ag-tab-selected': {
    borderBottomColor: 'var(--eag-colors-general-accent)',
    color: '#2196f3',
  },
  '.ag-tab-selected .ag-icon': {
    color: 'var(--eag-colors-general-accent)',
  },
  '.ag-menu-header': {
    color: '#181d1f',
    backgroundColor: '#f8f8f8',
    paddingTop: '1px',
    minWidth: '240px',
  },
  '.ag-filter-separator': {
    borderTop: 'solid 1px',
    borderTopColor: '#babfc7',
  },
  '.ag-filter-condition-operator': {height: '17px'},
  '.ag-ltr .ag-filter-condition-operator-or': {
    marginLeft: '12px',
  },
  '.ag-rtl .ag-filter-condition-operator-or': {
    marginRight: '12px',
  },
  '.ag-set-filter-select-all': {paddingTop: '12px'},
  '.ag-set-filter-filter': {
    marginTop: '12px',
    marginLeft: '12px',
    marginRight: '12px',
  },
  '.ag-filter-to': {marginTop: '9px'},
  '.ag-mini-filter': {margin: '12px 12px'},
  '.ag-set-filter-item': {margin: '0px 12px'},
  '.ag-ltr .ag-set-filter-item-value': {marginLeft: '12px'},
  '.ag-rtl .ag-set-filter-item-value': {marginRight: '12px'},
  '.ag-filter-apply-panel': {
    paddingTop: '16px',
    borderTop: 'solid 1px',
    borderTopColor: '#dde2eb',
    paddingBottom: '5px',
    marginBottom: '-5px',
  },
  '.ag-filter-apply-panel-button': {lineHeight: 1.5},
  '.ag-ltr .ag-filter-apply-panel-button': {
    marginLeft: '12px',
  },
  '.ag-rtl .ag-filter-apply-panel-button': {
    marginRight: '12px',
  },
  '.ag-standard-button.ag-filter-apply-panel-button': {
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
  },
  '.ag-standard-button.ag-filter-apply-panel-button:first-of-type': {
    backgroundColor: '#EBECF0',
    borderColor: 'transparent',
    color: '#091E42',
    fontWeight: 'normal',
  },
  '.ag-standard-button.ag-filter-apply-panel-button:last-of-type': {
    backgroundColor: '#0065FF',
    color: 'white',
  },
  '.ag-group-container.ag-group-container-vertical.ag-filter-toolpanel-group-container': {
    paddingLeft: '0px',
    backgroundColor: '#F7FAFC',
  },
  '.ag-simple-filter-body-wrapper': {
    paddingBottom: '7px',
  },
  '.ag-simple-filter-body-wrapper > *': {
    marginBottom: '16px',
  },
  '.ag-filter-no-matches': {padding: '12px 12px'},
  '.ag-multi-filter-menu-item': {margin: '6px 0'},
  '.ag-multi-filter-group-title-bar': {
    padding: '12px 6px',
    backgroundColor: 'transparent',
  },
  '.ag-keyboard-focus .ag-multi-filter-group-title-bar:focus': {
    outline: 'none',
  },
  '.ag-keyboard-focus .ag-multi-filter-group-title-bar:focus::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    top: '4px',
    left: '4px',
    display: 'block',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    border: '1px solid',
    borderColor: 'rgba(33, 150, 243, 0.4)',
  },
  '.ag-side-bar': {position: 'relative'},
  '.ag-tool-panel-wrapper': {
    position: 'absolute',
    width: '400px',
    backgroundColor: 'white !important',
    border: '0',
    borderLeft: '1px solid #DFE1E6',
    zIndex: '1',
    boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.16)',
    top: '0',
    right: '0',
    bottom: '0',
    borderRadius: '0',
  },
  '.ag-side-buttons': {
    display: 'none',
  },
  'button.ag-side-button-button': {
    color: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    background: 'transparent',
    padding: '12px 0 12px 0',
    width: '100%',
    margin: '0',
    minHeight: '108px',
    backgroundPositionY: 'center',
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    border: 'none',
  },
  'button.ag-side-button-button:focus': {boxShadow: 'none'},
  '.ag-keyboard-focus .ag-side-button-button:focus': {
    outline: 'none',
  },
  '.ag-keyboard-focus .ag-side-button-button:focus::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    top: '4px',
    left: '4px',
    display: 'block',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    border: '1px solid',
    borderColor: 'rgba(33, 150, 243, 0.4)',
  },
  '.ag-side-button-icon-wrapper': {marginBottom: '3px'},
  '.ag-ltr .ag-side-bar-left,  .ag-rtl .ag-side-bar-right': {
    borderRight: 'solid 1px',
    borderRightColor: '#babfc7',
  },
  '.ag-ltr .ag-side-bar-left .ag-tool-panel-wrapper,    .ag-rtl .ag-side-bar-right .ag-tool-panel-wrapper':
    {
      borderLeft: 'solid 1px',
      borderLeftColor: '#babfc7',
    },
  '.ag-ltr .ag-side-bar-left .ag-side-button-button,    .ag-rtl .ag-side-bar-right .ag-side-button-button':
    {
      borderRight: '2px solid transparent',
      transition: 'border-right 0.3s',
    },
  '.ag-ltr .ag-side-bar-left .ag-selected .ag-side-button-button,    .ag-rtl .ag-side-bar-right .ag-selected .ag-side-button-button':
    {
      borderRightColor: '#2196f3',
    },
  '.ag-rtl .ag-side-bar-left .ag-side-button-button,    .ag-ltr .ag-side-bar-right .ag-side-button-button':
    {
      borderLeft: '2px solid transparent',
      transition: 'border-left 0.3s',
    },
  '.ag-rtl .ag-side-bar-left .ag-selected .ag-side-button-button,    .ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button':
    {
      borderLeftColor: '#2196f3',
    },
  '.ag-filter-toolpanel-header': {height: '36px'},
  '.ag-rtl .ag-filter-toolpanel-header, .ag-rtl .ag-filter-toolpanel-search': {
    paddingRight: '6px',
  },
  '.ag-keyboard-focus .ag-filter-toolpanel-header:focus': {
    outline: 'none',
  },
  '.ag-keyboard-focus .ag-filter-toolpanel-header:focus::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    top: '4px',
    left: '4px',
    display: 'block',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    border: '1px solid',
    borderColor: 'rgba(33, 150, 243, 0.4)',
  },
  '.ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after': {
    fontFamily: '"agGridIcons"',
    fontSize: '16px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal',
    textTransform: 'none',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    content: '"\\f114"',
    position: 'absolute',
  },
  '.ag-ltr .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after': {
    paddingLeft: '6px',
  },
  '.ag-rtl .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after': {
    paddingRight: '6px',
  },
  '.ag-filter-toolpanel-group-level-0-header': {
    height: '48px',
  },
  '.ag-filter-toolpanel-group-item': {
    marginTop: '3px',
    marginBottom: '3px',
  },
  '.ag-filter-toolpanel-search': {
    height: '57px',
  },
  '.ag-filter-toolpanel-search-input': {
    flexGrow: 1,
    height: '24px',
  },
  '.ag-ltr .ag-filter-toolpanel-search-input': {
    marginRight: '6px',
  },
  '.ag-rtl .ag-filter-toolpanel-search-input': {
    marginLeft: '6px',
  },
  '.ag-filter-toolpanel-group-level-0': {
    borderTop: 'solid 1px',
    borderTopColor: '#dde2eb',
  },
  '.ag-rtl .ag-filter-toolpanel-expand, .ag-rtl .ag-filter-toolpanel-group-title-bar-icon': {
    marginLeft: '6px',
  },
  '.ag-filter-toolpanel-group-level-1 .ag-filter-toolpanel-group-level-1-header.ag-filter-toolpanel-group-title-bar':
    {
      backgroundColor: 'transparent',
    },
  '.ag-ltr .ag-filter-toolpanel-group-level-1 .ag-filter-toolpanel-group-level-2-header': {
    paddingLeft: '22px',
  },
  '.ag-rtl .ag-filter-toolpanel-group-level-1 .ag-filter-toolpanel-group-level-2-header': {
    paddingRight: '22px',
  },
  '.ag-filter-toolpanel-group-level-2 .ag-filter-toolpanel-group-level-2-header.ag-filter-toolpanel-group-title-bar':
    {
      backgroundColor: 'transparent',
    },
  '.ag-ltr .ag-filter-toolpanel-group-level-2 .ag-filter-toolpanel-group-level-3-header': {
    paddingLeft: '38px',
  },
  '.ag-rtl .ag-filter-toolpanel-group-level-2 .ag-filter-toolpanel-group-level-3-header': {
    paddingRight: '38px',
  },
  '.ag-filter-toolpanel-group-level-3 .ag-filter-toolpanel-group-level-3-header.ag-filter-toolpanel-group-title-bar':
    {
      backgroundColor: 'transparent',
    },
  '.ag-ltr .ag-filter-toolpanel-group-level-3 .ag-filter-toolpanel-group-level-4-header': {
    paddingLeft: '54px',
  },
  '.ag-rtl .ag-filter-toolpanel-group-level-3 .ag-filter-toolpanel-group-level-4-header': {
    paddingRight: '54px',
  },
  '.ag-filter-toolpanel-group-level-4 .ag-filter-toolpanel-group-level-4-header.ag-filter-toolpanel-group-title-bar':
    {
      backgroundColor: 'transparent',
    },
  '.ag-ltr .ag-filter-toolpanel-group-level-4 .ag-filter-toolpanel-group-level-5-header': {
    paddingLeft: '70px',
  },
  '.ag-rtl .ag-filter-toolpanel-group-level-4 .ag-filter-toolpanel-group-level-5-header': {
    paddingRight: '70px',
  },
  '.ag-filter-toolpanel-group-level-5 .ag-filter-toolpanel-group-level-5-header.ag-filter-toolpanel-group-title-bar':
    {
      backgroundColor: 'transparent',
    },
  '.ag-ltr .ag-filter-toolpanel-group-level-5 .ag-filter-toolpanel-group-level-6-header': {
    paddingLeft: '86px',
  },
  '.ag-rtl .ag-filter-toolpanel-group-level-5 .ag-filter-toolpanel-group-level-6-header': {
    paddingRight: '86px',
  },
  '.ag-filter-toolpanel-group-level-6 .ag-filter-toolpanel-group-level-6-header.ag-filter-toolpanel-group-title-bar':
    {
      backgroundColor: 'transparent',
    },
  '.ag-ltr .ag-filter-toolpanel-group-level-6 .ag-filter-toolpanel-group-level-7-header': {
    paddingLeft: '102px',
  },
  '.ag-rtl .ag-filter-toolpanel-group-level-6 .ag-filter-toolpanel-group-level-7-header': {
    paddingRight: '102px',
  },
  '.ag-filter-toolpanel-group-level-7 .ag-filter-toolpanel-group-level-7-header.ag-filter-toolpanel-group-title-bar':
    {
      backgroundColor: 'transparent',
    },
  '.ag-ltr .ag-filter-toolpanel-group-level-7 .ag-filter-toolpanel-group-level-8-header': {
    paddingLeft: '118px',
  },
  '.ag-rtl .ag-filter-toolpanel-group-level-7 .ag-filter-toolpanel-group-level-8-header': {
    paddingRight: '118px',
  },
  '.ag-filter-toolpanel-group-level-8 .ag-filter-toolpanel-group-level-8-header.ag-filter-toolpanel-group-title-bar':
    {
      backgroundColor: 'transparent',
    },
  '.ag-ltr .ag-filter-toolpanel-group-level-8 .ag-filter-toolpanel-group-level-9-header': {
    paddingLeft: '134px',
  },
  '.ag-rtl .ag-filter-toolpanel-group-level-8 .ag-filter-toolpanel-group-level-9-header': {
    paddingRight: '134px',
  },
  '.ag-filter-toolpanel-group-level-9 .ag-filter-toolpanel-group-level-9-header.ag-filter-toolpanel-group-title-bar':
    {
      backgroundColor: 'transparent',
    },
  '.ag-ltr .ag-filter-toolpanel-group-level-9 .ag-filter-toolpanel-group-level-10-header': {
    paddingLeft: '150px',
  },
  '.ag-rtl .ag-filter-toolpanel-group-level-9 .ag-filter-toolpanel-group-level-10-header': {
    paddingRight: '150px',
  },
  '.ag-filter-toolpanel-group-level-10 .ag-filter-toolpanel-group-level-10-header.ag-filter-toolpanel-group-title-bar':
    {
      backgroundColor: 'transparent',
    },
  '.ag-ltr .ag-filter-toolpanel-group-level-10 .ag-filter-toolpanel-group-level-11-header': {
    paddingLeft: '166px',
  },
  '.ag-rtl .ag-filter-toolpanel-group-level-10 .ag-filter-toolpanel-group-level-11-header': {
    paddingRight: '166px',
  },
  '.ag-filter-toolpanel-instance-filter': {
    padding: '16px 16px 16px 56px',
    backgroundColor: '#FAFBFC',
    borderTop: 'solid 1px',
    borderTopColor: '#babfc7',
    borderBottom: 'solid 1px',
    borderBottomColor: '#babfc7',
    border: 'none',
    borderLeftColor: '#babfc7',
  },
  '.ag-ltr .ag-filter-toolpanel-instance-header-icon': {
    marginLeft: '6px',
  },
  '.ag-rtl .ag-filter-toolpanel-instance-header-icon': {
    marginRight: '6px',
  },
  '.ag-pivot-mode-panel': {height: '44px', display: 'flex'},
  '.ag-pivot-mode-select': {
    display: 'flex',
    alignItems: 'center',
  },
  '.ag-ltr .ag-pivot-mode-select': {marginLeft: '16px'},
  '.ag-rtl .ag-pivot-mode-select': {marginRight: '12px'},
  '.ag-keyboard-focus .ag-column-select-header:focus': {
    outline: 'none',
  },
  '.ag-keyboard-focus .ag-column-select-header:focus::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    top: '4px',
    left: '4px',
    display: 'block',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    border: '1px solid',
    borderColor: 'rgba(33, 150, 243, 0.4)',
  },
  '.ag-column-select-header': {
    height: '56px',
    alignItems: 'center',
    padding: '0 16px',
    borderBottom: 'solid 1px',
    borderBottomColor: '#dde2eb',
  },
  '.ag-column-panel-column-select': {
    borderBottom: 'solid 1px',
    borderBottomColor: '#E2E8F0',
  },
  '.ag-column-group-icons,  .ag-column-select-header-icon': {
    color: '#181d1f',
  },
  '.ag-header': {
    borderBottom: 'solid 1px',
    borderBottomColor: '#E2E8F0',
    backgroundColor: '#FAFBFC',
  },
  '.ag-header-cell-comp-wrapper': {
    paddingLeft: '8px',
  },
  '.ag-header-group-text, .ag-header-cell-text': {
    color: '#42526E',
    fontSize: '14px',
    fontWeight: '500',
    width: '100%',
  },
  '.ag-ltr .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon': {
    marginLeft: '6px',
  },
  '.ag-rtl .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon': {
    marginRight: '6px',
  },
  '.ag-ltr .ag-header-cell.ag-right-aligned-header .ag-header-label-icon': {
    marginRight: '6px',
  },
  '.ag-rtl .ag-header-cell.ag-right-aligned-header .ag-header-label-icon': {
    marginLeft: '6px',
  },
  '.ag-right-aligned-header .ag-header-cell-text': {
    textAlign: 'right',
  },
  '.ag-header-cell,  .ag-header-group-cell': {
    paddingLeft: 2,
    paddingRight: 2,
  },
  '.ag-header-cell.ag-header-cell-moving,    .ag-header-group-cell.ag-header-cell-moving': {
    bg: 'white',
  },
  '.ag-keyboard-focus .ag-header-cell:focus': {
    outline: 'none',
  },
  '.ag-keyboard-focus .ag-header-cell:focus::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    top: '4px',
    left: '4px',
    display: 'block',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    border: '1px solid',
    borderColor: 'rgba(33, 150, 243, 0.4)',
  },
  '.ag-keyboard-focus .ag-header-group-cell:focus': {
    outline: 'none',
  },
  '.ag-keyboard-focus .ag-header-group-cell:focus::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    top: '4px',
    left: '4px',
    display: 'block',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    border: '1px solid',
    borderColor: 'rgba(33, 150, 243, 0.4)',
  },
  '.ag-header-icon': {
    color: '#181d1f',
    minWidth: '24px',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.ag-header-expand-icon': {cursor: 'pointer'},
  '.ag-ltr .ag-header-expand-icon': {paddingLeft: '4px'},
  '.ag-rtl .ag-header-expand-icon': {paddingRight: '4px'},
  '.ag-header-row.ag-header-row-column-group': {
    boxSizing: 'content-box',
    borderBottom: 'solid 1px',
    borderBottomColor: '#E2E8F0',
  },
  '.ag-header-cell-resize': {
    display: 'flex',
    right: '-8px',
  },
  '.ag-header-cell .ag-header-cell-resize::after, .ag-header-group-cell .ag-header-cell-resize::after':
    {
      display: 'block',
      content: "''",
      height: '24px',
      width: '1px',
      backgroundColor: '#E2E8F0',
      position: 'absolute',
      right: '8px',
      transform: 'translate(0, -50%)',
      top: '50%',
    },
  '.ag-header-cell.ag-column-resizing .ag-header-cell-resize::after, .ag-header-group-cell.ag-column-resizing .ag-header-cell-resize::after':
    {
      backgroundColor: '#90cdf4',
    },
  '.ag-ltr .ag-header-select-all': {marginRight: '18px'},
  '.ag-rtl .ag-header-select-all': {marginLeft: '18px'},
  '.ag-ltr .ag-floating-filter-button': {marginLeft: '18px'},
  '.ag-rtl .ag-floating-filter-button': {
    marginRight: '18px',
  },
  '.ag-floating-filter-button-button': {
    color: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    height: '16px',
    padding: '0',
    width: '16px',
  },
  '.ag-filter-loading': {
    backgroundColor: '#f8f8f8',
    height: '100%',
    padding: '12px 12px',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
  '.ag-paging-panel': {
    display: 'none',
  },
  '.ag-status-bar': {
    borderTop: 'solid 1px',
    borderTopColor: '#E2E8F0',
    color: 'rgba(24, 29, 31, 0.5)',
    paddingRight: '24px',
    paddingLeft: '24px',
    lineHeight: '20px',
    fontWeight: 'normal',
  },
  '.ag-status-name-value-value': {
    color: '#181d1f',
    fontWeight: 700,
  },
  '.ag-status-bar-center': {textAlign: 'center'},
  '.ag-status-name-value': {
    marginLeft: '8px',
    marginRight: '8px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  '.ag-column-drop-cell': {
    background: 'rgba(24, 29, 31, 0.07)',
    borderRadius: '24px',
    height: '24px',
    padding: '0 3px',
  },
  '.ag-column-drop-cell-text': {margin: '0 6px'},
  '.ag-column-drop-cell-button': {
    minWidth: '24px',
    margin: '0 3px',
    color: '#181d1f',
    opacity: 0.5,
  },
  '.ag-column-drop-cell-drag-handle': {marginLeft: '12px'},
  '.ag-column-drop-horizontal': {
    backgroundColor: '#f8f8f8',
    color: '#181d1f',
    borderBottom: 'solid 1px',
    borderBottomColor: '#babfc7',
  },
  '.ag-ltr .ag-column-drop-horizontal': {
    paddingLeft: '18px',
  },
  '.ag-rtl .ag-column-drop-horizontal': {
    paddingRight: '18px',
  },
  '.ag-ltr .ag-column-drop-horizontal-half-width:not(:last-of-type)': {
    borderRight: 'solid 1px',
    borderRightColor: '#babfc7',
  },
  '.ag-rtl .ag-column-drop-horizontal-half-width:not(:last-of-type)': {
    borderLeft: 'solid 1px',
    borderLeftColor: '#babfc7',
  },
  '.ag-column-drop-horizontal-cell-separator': {
    margin: '0 6px',
    color: '#181d1f',
  },
  '.ag-column-drop-horizontal-empty-message': {
    color: 'rgba(24, 29, 31, 0.5)',
  },
  '.ag-ltr .ag-column-drop-horizontal-icon': {
    marginRight: '18px',
  },
  '.ag-rtl .ag-column-drop-horizontal-icon': {
    marginLeft: '18px',
  },
  '.ag-column-drop-list.ag-column-drop-vertical-list': {
    height: '44px',
    padding: '8px 16px 16px 16px',
  },
  '.ag-column-drop-vertical-cell': {marginTop: '6px'},
  '.ag-column-drop-vertical': {
    minHeight: '75px',
    maxHeight: '175px',
    borderBottom: 'solid 1px',
    borderBottomColor: '#dde2eb',
  },
  '.ag-column-drop-vertical.ag-last-column-drop': {
    borderBottom: 'none',
  },
  '.ag-column-drop-vertical-icon': {
    display: 'none',
  },
  '.ag-column-drop-vertical-empty-message': {
    backgroundColor: '#FAFBFC',
    overflow: 'hidden',
    color: 'rgba(24, 29, 31, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'dashed 1px',
    borderColor: '#babfc7',
    padding: '12px',
  },
  '.ag-select-agg-func-popup': {
    border: 'solid 1px',
    borderColor: '#babfc7',
    background: '#fff',
    borderRadius: '3px',
    boxShadow: '0 1px 4px 1px rgba(186, 191, 199, 0.4)',
    padding: ['6px', '0'],
    height: '105px',
  },
  '.ag-select-agg-func-virtual-list-item': {
    cursor: 'default',
    lineHeight: '30px',
    paddingLeft: '12px',
  },
  '.ag-select-agg-func-virtual-list-item:hover': {
    backgroundColor: 'rgba(33, 150, 243, 0.3)',
  },
  '.ag-chart-menu': {
    borderRadius: '3px',
    background: '#fff',
  },
  '.ag-chart-menu-icon': {
    opacity: 0.5,
    lineHeight: '24px',
    fontSize: '24px',
    width: '24px',
    height: '24px',
    margin: '2px 0',
    cursor: 'pointer',
    borderRadius: '3px',
    color: '#181d1f',
  },
  '.ag-chart-menu-icon:hover': {opacity: 1},
  '.ag-chart-mini-thumbnail': {
    border: '1px solid',
    borderColor: '#dde2eb',
    borderRadius: '5px',
    margin: '5px',
    backgroundColor: '#fff',
  },
  '.ag-chart-mini-thumbnail:nth-last-of-type(3),    .ag-chart-mini-thumbnail:nth-last-of-type(3) ~ .ag-chart-mini-thumbnail':
    {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  '.ag-ltr .ag-chart-mini-thumbnail:first-of-type': {
    marginLeft: '0',
  },
  '.ag-rtl .ag-chart-mini-thumbnail:first-of-type': {
    marginRight: '0',
  },
  '.ag-ltr .ag-chart-mini-thumbnail:last-of-type': {
    marginRight: '0',
  },
  '.ag-rtl .ag-chart-mini-thumbnail:last-of-type': {
    marginLeft: '0',
  },
  '.ag-chart-mini-thumbnail.ag-selected': {
    borderColor: '#2196f3',
  },
  '.ag-chart-settings-card-item': {
    background: '#181d1f',
    width: '8px',
    height: '8px',
    borderRadius: '4px',
  },
  '.ag-chart-settings-card-item.ag-selected': {
    backgroundColor: '#2196f3',
  },
  '.ag-chart-data-column-drag-handle': {marginLeft: '6px'},
  '.ag-charts-settings-group-title-bar,  .ag-charts-data-group-title-bar,  .ag-charts-format-top-level-group-title-bar':
    {
      borderTop: 'solid 1px',
      borderTopColor: '#dde2eb',
      padding: '6px 12px',
      lineHeight: '20px',
    },
  '.ag-charts-settings-group-container': {padding: '6px'},
  '.ag-charts-data-group-container': {
    padding: '12px 12px',
    paddingBottom: '3px',
  },
  '.ag-charts-data-group-container > *': {
    marginBottom: '9px',
  },
  '.ag-charts-format-top-level-group-container': {
    marginLeft: '12px',
    padding: '6px',
  },
  '.ag-charts-format-top-level-group-item': {
    margin: '6px 0',
  },
  '.ag-charts-format-sub-level-group-container': {
    padding: '12px 12px',
    paddingBottom: '0',
  },
  '.ag-charts-format-sub-level-group-container > *': {
    marginBottom: '9px',
  },
  '.ag-charts-group-container.ag-group-container-horizontal': {
    padding: '6px',
  },
  '.ag-chart-data-section,  .ag-chart-format-section': {
    display: 'flex',
    margin: '0',
  },
  '.ag-chart-menu-panel': {
    backgroundColor: '#f8f8f8',
  },
  '.ag-ltr .ag-chart-menu-panel': {
    borderLeft: 'solid 1px',
    borderLeftColor: '#babfc7',
  },
  '.ag-rtl .ag-chart-menu-panel': {
    borderRight: 'solid 1px',
    borderRightColor: '#babfc7',
  },
  '.ag-date-time-list-page-title': {
    flexGrow: 1,
    textAlign: 'center',
  },
  '.ag-date-time-list-page-column-label': {
    textAlign: 'center',
  },
  '.ag-date-time-list-page-entry': {textAlign: 'center'},
  '.ag-checkbox-input-wrapper': {
    fontFamily: '"agGridIcons"',
    fontSize: '16px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal',
    textTransform: 'none',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    width: '16px',
    height: '16px',
    backgroundColor: '#fff',
    borderRadius: '3px',
    display: 'inline-block',
    verticalAlign: 'middle',
    flex: 'none',
  },
  '.ag-checkbox-input-wrapper input, .ag-checkbox-input-wrapper input': {
    WebkitAppearance: 'none',
    opacity: 0,
    width: '100%',
    height: '100%',
  },
  '.ag-checkbox-input-wrapper:focus-within, .ag-checkbox-input-wrapper:active': {
    outline: 'none',
    boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
  },
  '.ag-checkbox-input-wrapper.ag-disabled': {opacity: 0.5},
  '.ag-checkbox-input-wrapper::after': {
    content: '"\\f108"',
    color: '#999',
    position: 'absolute',
    top: '0',
    left: '0',
    pointerEvents: 'none',
  },
  '.ag-checkbox-input-wrapper.ag-checked::after': {
    content: '"\\f106"',
    color: '#2196f3',
    position: 'absolute',
    top: '0',
    left: '0',
    pointerEvents: 'none',
  },
  '.ag-checkbox-input-wrapper.ag-indeterminate::after': {
    content: '"\\f107"',
    color: '#999',

    position: 'absolute',
    top: '0',
    left: '0',
    pointerEvents: 'none',
  },
  '.ag-toggle-button-input-wrapper': {
    boxSizing: 'border-box',
    width: '28px',
    height: '18px',
    backgroundColor: '#999',
    borderRadius: '9px',
    position: 'relative',
    flex: 'none',
    border: '1px solid',
    borderColor: '#999',
  },
  '.ag-toggle-button-input-wrapper input': {
    opacity: 0,
    height: '100%',
    width: '100%',
  },
  '.ag-toggle-button-input-wrapper:focus-within': {
    outline: 'none',
    boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
  },
  '.ag-toggle-button-input-wrapper.ag-disabled': {
    opacity: 0.5,
  },
  '.ag-toggle-button-input-wrapper.ag-checked': {
    backgroundColor: '#2196f3',
    borderColor: '#2196f3',
  },
  '.ag-toggle-button-input-wrapper::before': {
    content: "' '",
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    display: 'block',
    boxSizing: 'border-box',
    height: '18px',
    width: '18px',
    backgroundColor: '#fff',
    borderRadius: '9px',
    transition: 'left 100ms',
    border: '1px solid',
    borderColor: '#999',
  },
  '.ag-toggle-button-input-wrapper.ag-checked::before': {
    left: 'calc(100% - 18px)',
    borderColor: '#2196f3',
  },
  '.ag-radio-button-input-wrapper': {
    fontFamily: '"agGridIcons"',
    fontSize: '16px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal',
    textTransform: 'none',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    width: '16px',
    height: '16px',
    backgroundColor: '#fff',

    borderRadius: ['3px', '16px'],
    display: 'inline-block',
    verticalAlign: 'middle',
    flex: 'none',
  },
  '.ag-radio-button-input-wrapper input, .ag-radio-button-input-wrapper input': {
    WebkitAppearance: 'none',
    opacity: 0,
    width: '100%',
    height: '100%',
  },
  '.ag-radio-button-input-wrapper:focus-within, .ag-radio-button-input-wrapper:active': {
    outline: 'none',
    boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
  },
  '.ag-radio-button-input-wrapper.ag-disabled': {
    opacity: 0.5,
  },
  '.ag-radio-button-input-wrapper::after': {
    content: '"\\f126"',
    color: '#999',
    position: 'absolute',
    top: '0',
    left: '0',
    pointerEvents: 'none',
  },
  '.ag-radio-button-input-wrapper.ag-checked::after': {
    content: '"\\f127"',
    color: '#2196f3',
    position: 'absolute',
    top: '0',
    left: '0',
    pointerEvents: 'none',
  },
  "input[class^='ag-'][type='range']::-webkit-slider-runnable-track": {
    margin: '0',
    padding: '0',
    width: '100%',
    height: '3px',
    backgroundColor: '#babfc7',
    borderRadius: ['3px', '3px'],
  },
  "input[class^='ag-'][type='range']::-moz-range-track": {
    margin: '0',
    padding: '0',
    width: '100%',
    height: '3px',
    backgroundColor: '#babfc7',
    borderRadius: ['3px', '3px'],
  },
  "input[class^='ag-'][type='range']::-ms-track": {
    margin: '0',
    padding: '0',
    width: ['100%', 'calc(100% - 2px)'],
    height: '3px',
    backgroundColor: '#babfc7',
    borderRadius: ['3px', '3px'],
    color: 'transparent',
  },
  "input[class^='ag-'][type='range']::-webkit-slider-thumb": {
    margin: '0',
    padding: '0',
    WebkitAppearance: 'none',
    width: '16px',
    height: '16px',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: '#999',
    borderRadius: '16px',
    WebkitTransform: 'translateY(-6.5px)',
    transform: 'translateY(-6.5px)',
  },
  "input[class^='ag-'][type='range']::-ms-thumb": {
    margin: '0',
    padding: '0',
    WebkitAppearance: 'none',
    width: '16px',
    height: '16px',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: '#999',
    borderRadius: '16px',
  },
  "input[class^='ag-'][type='range']::-moz-ag-range-thumb": {
    margin: '0',
    padding: '0',
    WebkitAppearance: 'none',
    width: '16px',
    height: '16px',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: '#999',
    borderRadius: '16px',
  },
  "input[class^='ag-'][type='range']:focus": {
    outline: 'none',
  },
  "input[class^='ag-'][type='range']:focus::-webkit-slider-thumb": {
    boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
    borderColor: '#2196f3',
  },
  "input[class^='ag-'][type='range']:focus::-ms-thumb": {
    boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
    borderColor: '#2196f3',
  },
  "input[class^='ag-'][type='range']:focus::-moz-ag-range-thumb": {
    boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
    borderColor: '#2196f3',
  },
  "input[class^='ag-'][type='range']:active::-webkit-slider-runnable-track": {
    backgroundColor: 'rgba(33, 150, 243, 0.4)',
  },
  "input[class^='ag-'][type='range']:active::-moz-ag-range-track": {
    backgroundColor: 'rgba(33, 150, 243, 0.4)',
  },
  "input[class^='ag-'][type='range']:active::-ms-track": {
    backgroundColor: 'rgba(33, 150, 243, 0.4)',
  },
  "input[class^='ag-'][type='range']:disabled": {
    opacity: 0.5,
  },
  '.ag-filter-toolpanel-header,  .ag-filter-toolpanel-search,  .ag-status-bar,  .ag-header-row,  .ag-panel-title-bar-title,  .ag-multi-filter-group-title-bar':
    {
      fontWeight: 700,
      color: '#181d1f',
    },
  '.ag-rtl .ag-pinned-left-header .ag-header-row::before': {
    left: '0',
  },
  ".ag-ltr input[class^='ag-']:not([type]),    .ag-ltr input[class^='ag-'][type='text'],    .ag-ltr input[class^='ag-'][type='number'],    .ag-ltr input[class^='ag-'][type='tel'],    .ag-ltr input[class^='ag-'][type='date'],    .ag-ltr input[class^='ag-'][type='datetime-local'],    .ag-ltr textarea[class^='ag-']":
    {
      paddingLeft: '6px',
    },
  ".ag-rtl input[class^='ag-']:not([type]),    .ag-rtl input[class^='ag-'][type='text'],    .ag-rtl input[class^='ag-'][type='number'],    .ag-rtl input[class^='ag-'][type='tel'],    .ag-rtl input[class^='ag-'][type='date'],    .ag-rtl input[class^='ag-'][type='datetime-local'],    .ag-rtl textarea[class^='ag-']":
    {
      paddingRight: '6px',
    },
  '.ag-chart-settings-nav-bar': {
    borderTop: 'solid 1px',
    borderTopColor: '#dde2eb',
  },
  '.ag-ltr .ag-group-title-bar-icon': {
    margin: '0px 16px',
    height: '24px',
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.ag-rtl .ag-group-title-bar-icon': {marginLeft: '6px'},
  '.ag-charts-format-top-level-group-toolbar': {
    marginTop: '6px',
  },
  '.ag-ltr .ag-charts-format-top-level-group-toolbar': {
    paddingLeft: '20px',
  },
  '.ag-rtl .ag-charts-format-top-level-group-toolbar': {
    paddingRight: '20px',
  },
  '.ag-charts-format-sub-level-group': {
    borderLeft: 'dashed 1px',
    borderLeftColor: '#babfc7',
    paddingLeft: '6px',
    marginBottom: '12px',
  },
  '.ag-charts-format-sub-level-group-title-bar': {
    paddingTop: '0',
    paddingBottom: '0',
    background: 'none',
    fontWeight: 700,
  },
  '.ag-charts-format-sub-level-group-item:last-of-type': {
    marginBottom: '0',
  },
  '.ag-standard-button': {
    MozAppearance: 'none',
    appearance: 'none',
    WebkitAppearance: 'none',
    borderRadius: '3px',
    border: '1px solid',
    borderColor: '#2196f3',
    color: '#2196f3',
    backgroundColor: '#fff',
    fontWeight: 600,
    padding: '6px 12px',
  },
  '.ag-standard-button:hover': {
    borderColor: '#2196f3',
    backgroundColor: 'rgba(33, 150, 243, 0.1)',
  },
  '.ag-standard-button:active': {
    borderColor: '#2196f3',
    backgroundColor: '#2196f3',
    color: '#fff',
  },
  '.ag-standard-button:disabled': {
    color: 'rgba(24, 29, 31, 0.5)',
    backgroundColor: '#f1f2f4',
    borderColor: 'rgba(186, 191, 199, 0.3)',
  },
  '.ag-column-drop-vertical-title-bar': {
    padding: '12px',
    paddingBottom: '0px',
  },
  '.ag-column-drop-empty-message': {
    color: '#181d1f',
    opacity: 0.75,
  },
  '.ag-column-drop-cell-button:hover': {opacity: 0.75},
  '.ag-header-cell-menu-button:hover,  .ag-side-button-button:hover,  .ag-tab:hover,  .ag-panel-title-bar-button:hover,  .ag-header-expand-icon:hover, .ag-group-expanded .ag-icon:hover,  .ag-group-contracted .ag-icon:hover,  .ag-chart-settings-prev:hover,  .ag-chart-settings-next:hover, .ag-floating-filter-button-button:hover, .ag-chart-menu-icon:hover':
    {
      color: ['#2196f3', 'var(--ag-alpine-active-color, #2196f3)'],
    },
  '.ag-chart-settings-card-item.ag-not-selected:hover': {
    opacity: 0.35,
  },
  '.ag-filter-toolpanel-group-container': {
    paddingLeft: '6px',
  },
  '.ag-set-filter-list': {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  '.ag-date-time-list-page-entry-is-current': {
    backgroundColor: '#2196f3',
  },

  '.ag-pinned-left-header, .ag-pinned-left-cols-container': {
    borderRight: '1px solid',
    borderColor: '#E2E8F0',

    zIndex: 1,
  },

  '.ag-pinned-right-header, .ag-pinned-right-cols-container': {
    borderLeft: '1px solid',
    borderColor: '#E2E8F0',

    zIndex: 1,
  },

  '.ag-pinned-right-cols-container, .ag-pinned-left-cols-container': {
    backgroundColor: 'white',
    minHeight: '100%',
  },

  '.ag-filter-toolpanel': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.ag-custom-settings-toolpanel': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  '.ag-panel-header': {
    order: -1,
    width: '100%',
  },
  '.ag-default-panel-header': {
    height: '57px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    color: '#091E42',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: '500',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderBottom: 'solid 1px',
    borderBottomColor: '#E2E8F0',
  },
  '.ag-panel-content': {
    width: '100%',
    padding: '16px',
    borderBottom: '1px solid',
    borderColor: '#E2E8F0',
  },

  '.ag-custom-overlay-wrapper': {
    height: '100%',
  },

  '.ag-header-group-cell-label': {
    fontSize: '14px',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
  },

  '.ag-header-cell': {
    overflow: 'visible',
  },
  '.ag-pinned-right-header .ag-header-cell-resize': {
    left: '-15px',
  },
  '.ag-pinned-left-cols-container .ag-row-loading .ag-loading, .ag-pinned-right-cols-container .ag-row-loading .ag-loading':
    {
      display: 'none',
    },
  '.ag-suppress-movable-col': {
    backgroundColor: '#FFFDE8',
  },
  '.ag-locked-col': {
    backgroundColor: '#F4F5F7',
  },
  '.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child': {
    backgroundColor: '#fff',
  },
  '.ag-menu.ag-ltr.ag-popup-child': {
    backgroundColor: 'white',
    marginLeft: '10px',
    marginTop: '-8px',
  },
  '.ag-menu-list': {
    display: 'flex',
    flexDir: 'column',
  },
  '.ag-menu-list.ag-focus-managed': {
    padding: '8px',
  },
  '.ag-menu-option': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.ag-menu-option.ag-menu-option-active': {
    backgroundColor: '#ebf8ff',
    color: '#2a4365',
    borderRadius: '4px',
  },
  '.ag-filter-toolpanel-search .ag-input-field-input.ag-text-field-input,.ag-column-select-header .ag-input-field-input.ag-text-field-input':
    {
      height: '32px',
      backgroundColor: '#F4F5F7',
      borderRadius: '4px',
      border: 'none',
      paddingLeft: '8px !important',
    },
  '.ag-column-select-virtual-list-viewport': {
    padding: '14px 16px',
  },
  '.ag-virtual-list-item.ag-column-select-virtual-list-item': {
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  '.ag-column-drop-title-bar.ag-column-drop-vertical-title-bar': {
    padding: '12px 16px 0px 15px',
  },
  '.ag-column-drop-title.ag-column-drop-vertical-title': {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '16px',
    color: '#42526E',
  },
  '.ag-input-field-input.ag-text-field-input': {
    backgroundColor: '#fff',
    color: '#7A869A !important',
    borderRadius: '4px !important',
    height: '32px',
    padding: '6px 0px 6px 8px !important',
  },
  '.ag-wrapper.ag-picker-field-wrapper': {
    height: '32px',
  },
  '.ag-picker-field-icon': {
    padding: '8px',
  },
  '.ag-input-field-input.ag-number-field-input': {
    height: '32px',
    padding: '6px 0px 6px 8px',
  },
  '.ag-picker-field-display': {
    margin: '6px 0px 6px 8px',
  },
  '.ag-filter-toolpanel-search-input.ag-labeled.ag-label-align-left.ag-text-field.ag-input-field': {
    margin: '16px',
  },
  '.ag-group-title-bar.ag-filter-toolpanel-group-title-bar.ag-unselectable.ag-filter-toolpanel-group-level-0-header.ag-filter-toolpanel-header':
    {
      padding: '12px 0px',
      paddingRight: '12px',
    },
  '.ag-column-group-icons, .ag-column-select-header-icon, .ag-group-title-bar-icon, .ag-filter-toolpanel-expand':
    {
      height: '24px',
      width: '24px',
      marginLeft: '16px',
      marginRight: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      cursor: 'pointer',
      backgroundColor: '#EDF2F7',
      '&:hover': {},
      '&>*': {
        display: 'flex',
      },
    },
  '.ag-column-select-header-icon, .ag-column-group-icons': {
    marginLeft: 0,
  },
  '.ag-filter-toolpanel-search .ag-filter-toolpanel-expand': {
    marginRight: 0,
  },
  '.ag-row-action-hover': {
    cursor: 'pointer',
  },
  '.ag-tooltip-custom': {
    position: 'absolute',
    width: 'auto',
  },
  // hide horizontal scroll
  '.ag-root .ag-horizontal-left-spacer': {
    borderRight: '1px solid',
    borderColor: '#E2E8F0',
    overflow: 'hidden',
  },
  '.ag-root .ag-horizontal-right-spacer': {
    borderLeft: '1px solid',
    borderColor: '#E2E8F0',
    overflow: 'hidden',
  },
  '.ag-cell[col-id="eag-col-actions"], .ag-cell[col-id="eag-col-expander"]': {
    padding: '0px',
  },
};

const headerTheme = {
  minHeight: '56px',
  maxHeight: '112px',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  borderBottomWidth: '2px',
  borderBottomColor: '#E2E8F0',
  padding: '12px',
  display: 'flex',
  alignItems: 'center',

  '& > div': {
    display: 'flex',
    alignItems: 'center',
  },

  '.ag-custom-header-panel': {
    flexGrow: 1,
    padding: '5px 0',
    paddingLeft: '5px',
    marginLeft: '-5px',
    '& > *': {
      marginRight: '8px',

      '&.ag-custom-separator': {
        height: '24px',
        width: '1px',
        backgroundColor: '#E2E8F0',
      },

      '&>*': {
        flexShrink: 0,
      },
    },
  },
};

export const asideTheme = {
  width: '48px',
  borderLeft: '1px solid',
  borderColor: '#DFE1E6',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  paddingTop: '8px',
  background: 'white',
};

const zebraTheme = {
  '.ag-row-odd:not(.ag-row-hover):not(.ag-row-selected)': {
    backgroundColor: '#FAFBFC',
  },
};

const rowTheme = {
  '.ag-row:not(.ag-row-last)': {
    borderBottom: '1px solid',
    borderBottomColor: '#E2E8F0',
  },
};

const colTheme = {
  '.ag-pinned-right-header .ag-header-cell, .ag-pinned-right-cols-container .ag-cell, .ag-header-cell, .ag-header-group-cell, .ag-cell':
    {
      borderColor: 'transparent',
      borderRight: '1px solid',
      borderLeft: '0',
      borderImage:
        'linear-gradient(transparent 10%, #E2E8F0 10%,#E2E8F0 90%, transparent 90%) 0 100%;',
      marginRight: '1px',
    },
  '.ag-column-resizing': {
    borderColor: '#90cdf4',
  },
  '.ag-header-group-cell .ag-header-cell-resize::after ,.ag-header-cell .ag-header-cell-resize::after':
    {
      display: 'none',
      content: 'none',
    },
};

const footerTheme = {
  height: '56px',
  minHeight: '56px',
  boxSizing: 'border-box',
  alignItems: 'center',
  borderTopWidth: 1,
  borderTopColor: '#E2E8F0',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px',
};

const inlineWrapperTheme = {
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

const cardWrapperTheme = {
  overflow: 'hidden',
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
  borderWidth: 1,
  borderColor: '#E2E8F0',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.12)',
  borderRadius: 'base',
  height: '100%',
};

export const useGridTheme = (
  settings: FeGridSettings,
  definition: TransformedDefinitionApiResponse
): Record<string, any | undefined> => {
  const wrapperTheme = useMemo(() => {
    if (definition.behavior.wrapperStyle === 'INLINE') {
      return inlineWrapperTheme;
    }
    return cardWrapperTheme;
  }, [definition.behavior.wrapperStyle, cardWrapperTheme, inlineWrapperTheme]);

  const finalHeaderTheme = useMemo(() => {
    if (definition.behavior.wrapperStyle === 'INLINE') {
      return {
        ...headerTheme,
        paddingLeft: 0,
        paddingRight: 0,
      };
    }
    return headerTheme;
  }, [definition.behavior.wrapperStyle, headerTheme]);

  const finalFooterTheme = useMemo(() => {
    if (definition.behavior.wrapperStyle === 'INLINE') {
      return {
        ...footerTheme,
        paddingLeft: 0,
        paddingRight: 0,
      };
    }
    return footerTheme;
  }, [definition.behavior.wrapperStyle, footerTheme]);

  const finalGridTheme = useMemo(
    () =>
      Object.assign(
        {},
        gridTheme,
        settings.verticalSeparatorsVisible ? colTheme : {},
        settings.horizontalSeparatorsVisible ? rowTheme : {},
        settings.stripedRows ? zebraTheme : {},
        {
          '.ag-cell, .ag-row-loading, .ag-loading': {
            minH: `${LineHeightMappingToPx[settings.rowHeight]}px`,
            marginBottom: '1px',
          },
          '.ag-row-loading': {
            height: `${LineHeightMappingToPx[settings.rowHeight]}px`,
          },

          // is fixed exactly like this with ag-grid v31.1
          // see:
          // https://github.com/ag-grid/ag-grid/commit/1ddcca838ca0cb95e83022da45bdeb08054930fa
          '.ag-body-viewport::-webkit-scrollbar, .ag-center-cols-viewport::-webkit-scrollbar': {
            display: 'none',
          },
          '.ag-body-viewport, .ag-center-cols-viewport': {
            scrollbarWidth: 'none !important',
          },
        }
      ),
    [
      gridTheme,
      zebraTheme,
      colTheme,
      rowTheme,
      settings.rowHeight,
      settings.verticalSeparatorsVisible,
      settings.horizontalSeparatorsVisible,
      settings.stripedRows,
    ]
  );

  return {
    gridTheme: finalGridTheme,
    headerTheme: finalHeaderTheme,
    footerTheme: finalFooterTheme,
    wrapperTheme,
    asideTheme,
  };
};

import {Button, DataStatus, EmptyStatusAction} from 'platform/components';
import {getValueByDevice, Show, Space, useDevice, ValueByDevice, VStack} from 'platform/foundation';

import {GetUserApiResponse, TaskResponseBody} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {TaskCenterListItem} from './components/TaskCenterListItem/TaskCenterListItem';

interface TaskCenterListBaseProps extends RequiredTestIdProps {
  list: TaskResponseBody[];
  isLoading: boolean;
  isError: boolean;
  onItemClick: (task: TaskResponseBody) => void;
  maxNumberOfTasks?: ValueByDevice<number>;
  onAssigneeClick?: (assignee: GetUserApiResponse) => void;
  hideAssignee?: boolean;
  emptyStatus?: {
    message?: string;
    action?: EmptyStatusAction;
  };
}

type TaskCenterListItemProps = TaskCenterListBaseProps &
  (
    | {
        onShowAllClick?: () => void;
        shouldRenderShowAllButton?: false;
      }
    | {
        onShowAllClick: () => void;
        shouldRenderShowAllButton?: true;
      }
  );

const DEFAULT_MAX_TASKS_TO_RENDER = [4, 5, 6] as const satisfies ValueByDevice<number>;

export function TaskCenterList(props: TaskCenterListItemProps) {
  const device = useDevice();
  const numberOfTasks = props.maxNumberOfTasks ?? DEFAULT_MAX_TASKS_TO_RENDER;
  const tasks =
    props.list?.slice(
      0,
      getValueByDevice(device, ...(numberOfTasks as [number, number, number]))
    ) ?? [];

  return (
    <VStack spacing={1} data-testid={suffixTestId('task-center-list', props)}>
      <DataStatus
        isLoading={props.isLoading}
        isError={props.isError}
        minHeight={80}
        isEmpty={tasks.length < 1}
        emptyMessage={props.emptyStatus?.message ?? i18n.t('entity.task.labels.emptyTasks')}
        action={
          props.emptyStatus?.action ?? {
            title: i18n.t('entity.task.actions.showAll'),
            onClick: props.onShowAllClick,
            rightIcon: 'navigation/chevron_right',
          }
        }
      >
        {tasks.map((task, index) => (
          <TaskCenterListItem
            data-testid={`task-center-listItem-${index}`}
            key={task.id}
            task={task}
            onAssigneeClick={props.onAssigneeClick}
            onClick={() => props.onItemClick(task)}
            hideAssignee={props.hideAssignee}
          />
        ))}
      </DataStatus>
      <Show when={props.shouldRenderShowAllButton && tasks.length > 0}>
        <Space vertical={2} />
        <Button
          title={i18n.t('entity.task.actions.showAll')}
          variant="link"
          rightIcon="navigation/chevron_right"
          onClick={props.onShowAllClick}
        />
      </Show>
    </VStack>
  );
}

import {Alert, AlertProps} from 'platform/components';
import {match} from 'ts-pattern';

import {FC} from 'react';

import {always, isNil} from 'ramda';

import i18n from '@dms/i18n';

import {HintType, HintTypeEnum} from '../types/CommonVehicleTypes';

type HintsProp = {
  hintType: HintType;
  callback?: () => void;
  createMode?: boolean;
};

export const VehicleDuplicatesHints: FC<HintsProp> = ({hintType, callback, createMode}) => {
  const alertProps = match<HintType, AlertProps | null>(hintType)
    .with(
      HintTypeEnum.VinAlreadyExists,
      always({
        type: 'banner',
        variant: 'error',
        title: i18n.t('entity.vehicle.labels.VINExists'),
        message: createMode
          ? i18n.t('entity.vehicle.labels.correctVIN')
          : i18n.t('entity.vehicle.labels.cannotCreateSameVIN'),
      })
    )
    .with(
      HintTypeEnum.VinIsUsedInService,
      always({
        type: 'banner',
        variant: 'warning',
        title: i18n.t('entity.vehicle.labels.VINExistsInService'),
        message: i18n.t('entity.vehicle.labels.insertDataFromService'),
      })
    )
    .with(
      HintTypeEnum.InfoLicense,
      always({
        type: 'banner',
        variant: 'info',
        title: i18n.t('entity.vehicle.labels.licensePlateExists'),
        message: createMode ? i18n.t('entity.vehicle.labels.selectExistingVehicle') : undefined,
        onClose: callback,
      })
    )
    .with(
      HintTypeEnum.CreatingSaleForService,
      always({
        type: 'banner',
        variant: 'info',
        title: i18n.t('entity.vehicle.labels.creatingSaleForServiceVehicle'),
        message: createMode
          ? i18n.t('entity.vehicle.labels.creatingSaleForServiceVehicleMessage')
          : undefined,
        hyperlinks: [{title: i18n.t('general.actions.cancel'), onClick: callback}],
      })
    )
    .with(
      HintTypeEnum.SomeResultsCanCreateSale,
      always({
        type: 'banner',
        variant: 'info',
        title: i18n.t('entity.vehicle.labels.creatingSaleForSomeServiceVehicle'),
        message: createMode
          ? i18n.t('entity.vehicle.labels.creatingSaleForSomeServiceVehicleMessage')
          : undefined,
        onClose: callback,
      })
    )
    .otherwise(always(null));

  if (isNil(alertProps)) {
    return null;
  }

  return <Alert {...alertProps} />;
};

import {environment} from '@dms/environment';

export const API_URL = environment.API_URL;

export enum TRANSLATED_LANGUAGES {
  czech = 'cs-CZ',
  english = 'en-GB',
  slovak = 'sk-SK',
  france = 'fr-FR',
  poland = 'pl-PL',
  germany = 'de-DE',
  spain = 'es-ES',
  italy = 'it-IT',
}

export const DEFAULT_LANG = TRANSLATED_LANGUAGES.english;

export const CURRENCY_CZK = 'CZK';
export const CURRENCY_HUF = 'HUF';

export const DEFAULT_CURRENCY = CURRENCY_CZK;

export const COUNTRY_CZE = 'CZE';

export const DEFAULT_COUNTRY = COUNTRY_CZE;

// COOKIES
export const PREV_URL_COOKIE = 'PREV_URL';

// ADMIN
export const ADMINISTRATOR_ROLE = 'MANAGER';
export const CUSTOM_ROLE = 'Custom';

import {
  PostServiceOrderIssueNoteRequest,
  PostServiceOrderIssueNoteResponse,
  BulkDeleteServiceOrderIssueNoteItemsRequest,
  BulkDeleteServiceOrderIssueNoteItemsResponse,
  DeleteServiceOrderIssueNoteRequest,
  DeleteServiceOrderIssueNoteResponse,
  GetServiceOrderIssueNoteRequest,
  GetServiceOrderIssueNoteResponse,
  PatchServiceOrderIssueNoteCompleteRequest,
  PatchServiceOrderIssueNoteCompleteResponse,
  PatchServiceOrderIssueNotePartsReadyRequest,
  PatchServiceOrderIssueNotePartsReadyResponse,
  PatchServiceOrderIssueNoteRequest,
  PatchServiceOrderIssueNoteResponse,
  GetServiceOrderIssueNoteRequestSchema,
  GetServiceOrderIssueNoteResponseSchema,
  PostServiceOrderIssueNoteRequestSchema,
  PatchServiceOrderIssueNoteRequestSchema,
  DeleteServiceOrderIssueNoteRequestSchema,
  PatchServiceOrderIssueNotePartsReadyRequestSchema,
  PatchServiceOrderIssueNoteCompleteRequestSchema,
  PatchServiceOrderIssueNoteCompleteResponseSchema,
  BulkDeleteServiceOrderIssueNoteItemsRequestSchema,
  PostServiceOrderIssueNoteResponseSchema,
  PatchServiceOrderIssueNoteResponseSchema,
  DeleteServiceOrderIssueNoteResponseSchema,
  PatchServiceOrderIssueNotePartsReadyResponseSchema,
  BulkDeleteServiceOrderIssueNoteItemsResponseSchema,
} from '../types/metadaWarehouseServiceOrderIssueNote';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseServiceOrderIssueNoteApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrderIssueNote: build.query<
      GetServiceOrderIssueNoteResponse,
      GetServiceOrderIssueNoteRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v2/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['serviceOrderIssueNotes'],
      extraOptions: {
        requestSchema: GetServiceOrderIssueNoteRequestSchema,
        responseSchema: GetServiceOrderIssueNoteResponseSchema,
      },
    }),
    postServiceOrderIssueNote: build.mutation<
      PostServiceOrderIssueNoteResponse,
      PostServiceOrderIssueNoteRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note`,
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: ['serviceOrderIssueNotes'],
      extraOptions: {
        requestSchema: PostServiceOrderIssueNoteRequestSchema,
        responseSchema: PostServiceOrderIssueNoteResponseSchema,
      },
    }),
    patchServiceOrderIssueNote: build.mutation<
      PatchServiceOrderIssueNoteResponse,
      PatchServiceOrderIssueNoteRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['serviceOrderIssueNotes'],
      extraOptions: {
        requestSchema: PatchServiceOrderIssueNoteRequestSchema,
        responseSchema: PatchServiceOrderIssueNoteResponseSchema,
      },
    }),
    deleteServiceOrderIssueNote: build.mutation<
      DeleteServiceOrderIssueNoteResponse,
      DeleteServiceOrderIssueNoteRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'serviceOrderIssueNoteBasket',
        {type: 'serviceOrderIssueNotes', id: queryArg.serviceOrderIssueNoteId},
      ],
      extraOptions: {
        requestSchema: DeleteServiceOrderIssueNoteRequestSchema,
        responseSchema: DeleteServiceOrderIssueNoteResponseSchema,
      },
    }),
    patchServiceOrderIssueNotePartsReady: build.mutation<
      PatchServiceOrderIssueNotePartsReadyResponse,
      PatchServiceOrderIssueNotePartsReadyRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}/parts-ready`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['serviceOrderIssueNotes'],
      extraOptions: {
        requestSchema: PatchServiceOrderIssueNotePartsReadyRequestSchema,
        responseSchema: PatchServiceOrderIssueNotePartsReadyResponseSchema,
      },
    }),
    patchServiceOrderIssueNoteComplete: build.mutation<
      PatchServiceOrderIssueNoteCompleteResponse,
      PatchServiceOrderIssueNoteCompleteRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}/completed`,
        method: 'PATCH',
        queryArg,
      }),
      invalidatesTags: ['serviceOrderIssueNotes'],
      extraOptions: {
        requestSchema: PatchServiceOrderIssueNoteCompleteRequestSchema,
        responseSchema: PatchServiceOrderIssueNoteCompleteResponseSchema,
      },
    }),
    bulkDeleteServiceOrderIssueNoteItems: build.mutation<
      BulkDeleteServiceOrderIssueNoteItemsResponse,
      BulkDeleteServiceOrderIssueNoteItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}/delete-items`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: [
        'serviceOrderIssueNotes',
        'serviceOrderIssueNoteBasket',
        'headerDynamicActions',
      ],
      extraOptions: {
        requestSchema: BulkDeleteServiceOrderIssueNoteItemsRequestSchema,
        responseSchema: BulkDeleteServiceOrderIssueNoteItemsResponseSchema,
      },
    }),
  }),
});

export const {
  useGetServiceOrderIssueNoteQuery,
  usePostServiceOrderIssueNoteMutation,
  usePatchServiceOrderIssueNoteMutation,
  useDeleteServiceOrderIssueNoteMutation,
  usePatchServiceOrderIssueNotePartsReadyMutation,
  usePatchServiceOrderIssueNoteCompleteMutation,
  useBulkDeleteServiceOrderIssueNoteItemsMutation,
} = metadaWarehouseServiceOrderIssueNoteApi;

import {Tooltip} from 'platform/components';
import {Box, Clickable, HStack, Space, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil} from 'ramda';

import {GetSignableDocumentListResponseBody} from '@dms/api';
import i18n from '@dms/i18n';

import {parseDate} from 'shared';

import {DocumentItemWithIsSelected} from '../types/DocumentItemWithIsSelected';
import {formatDocumentStructure} from '../utils/formatDocumentStructure';

interface DocumentLabelProps {
  document: DocumentItemWithIsSelected | GetSignableDocumentListResponseBody;
  onClick?: (document: DocumentItemWithIsSelected) => void;
}

export function DocumentLabel(props: DocumentLabelProps) {
  const formatDate = useDateTimeFormatter();

  const document = formatDocumentStructure(props.document);

  const tooltipParameters = [
    {
      title: i18n.t('general.labels.createdAt'),
      value: formatDate('dateTimeShort', parseDate(props.document.createdAt)),
    },
    {
      title: i18n.t('general.labels.createdBy'),
      value: document.createdBy,
    },
  ];

  return (
    <Clickable
      onClick={() => props.onClick?.(document)}
      isDisabled={isNil(props.onClick)}
      width="100%"
      minWidth={0}
    >
      <Box overflow="hidden" width="100%" padding={2}>
        <Tooltip
          placement="top-start"
          offset={0}
          label={document.name}
          hasAutoWidth
          openDelay={500}
          description={
            <VStack>
              <Space vertical={2} />
              {tooltipParameters.map((item) => (
                <HStack key={item.title} align="baseline" spacing={1}>
                  <Text size="xSmall" color="white" alternative>
                    {item.title}:
                  </Text>
                  <Text size="xSmall" color="white">
                    {item.value}
                  </Text>
                </HStack>
              ))}
            </VStack>
          }
        >
          <VStack>
            <Text size="small" noWrap>
              {document.name}
            </Text>
            <Text size="xSmall" color="tertiary">
              {formatDate('dateTimeShort', parseDate(props.document.createdAt))}
            </Text>
          </VStack>
        </Tooltip>
      </Box>
    </Clickable>
  );
}

import {use} from 'react';
import {useForm, useFormState, UseFormStateParams} from 'react-final-form';

import {PossibleObject} from '../../../types/PossibleObject';
import {FormContext} from '../FormContext';
import {FormRenderProps} from '../types/FormRenderProps';

/**
 * @deprecated - use platform instead
 */
export const useFormRenderer = <
  FormValues extends PossibleObject = undefined,
  InitialFormValues extends PossibleObject = undefined,
  Context extends PossibleObject = undefined,
>(
  params?: UseFormStateParams
): FormRenderProps<FormValues, InitialFormValues, Context> => {
  const {Field, FieldArray, Condition, Subscribe} = use(FormContext);
  const formState = useFormState(
    params
      ? {...params, subscription: params.subscription || {initialValues: true}}
      : {subscription: {initialValues: true}}
  );
  const form = useForm();

  // eslint-disable-next-line no-restricted-syntax
  return {
    ...formState,
    form,
    Field,
    FieldArray,
    Condition,
    Subscribe,
  } as unknown as FormRenderProps<FormValues, InitialFormValues, Context>;
};

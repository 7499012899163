import {Box} from 'platform/foundation';

import {useCallback, useState} from 'react';

import {ICellRendererParams} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {DefaultRowSelectCheckboxRenderer} from './DefaultRowSelectCheckboxRenderer';

export interface RowSelectHeaderRendererProps extends ICellRendererParams {
  gridProps: DataGridProps;
}

export function RowSelectHeaderRenderer({api, context}: RowSelectHeaderRendererProps) {
  const getSelectedStateFromNode = () => {
    const selectionArray: Array<boolean | undefined> = [];
    api.forEachNode((node) => selectionArray.push(node.isSelected()));
    if (selectionArray.every((i) => i === true)) {
      return true;
    }
    if (selectionArray.every((i) => i === false)) {
      return false;
    }
    return undefined;
  };

  const [selected, setSelected] = useState<boolean | undefined>(getSelectedStateFromNode);

  const change = useCallback(() => {
    const newlySelected = !selected;
    setSelected(newlySelected);
    if (newlySelected) {
      api.forEachNode((node) => node.setSelected(true));
    } else {
      api.deselectAll();
    }
    api.refreshCells({force: true, columns: ['eag-col-select']});
  }, [api, selected]);

  return (
    <Box
      css={{
        display: 'inline-flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DefaultRowSelectCheckboxRenderer selected={selected} changeHandler={change} />
    </Box>
  );
}

import {z} from 'zod';

export interface ChecklistTask {
  description: string;
  isMandatory: boolean;
  fileRequired: boolean;
  id?: string;
}

export const checklistFormSchema = z.object({
  name: z.string(),
  resourceId: z.string(),
  description: z.string().nullish(),
  tasks: z.array(
    z.object({
      description: z.string(),
      isMandatory: z.boolean(),
      fileRequired: z.boolean(),
      id: z.string().nullish(),
    })
  ),
});

export type ChecklistFormValue = z.infer<typeof checklistFormSchema>;

import {isFeatureEnabled} from 'feature-flags';
import {showNotification} from 'platform/components';
import {useLocale} from 'platform/locale';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  getWorkspaceFromUri,
  urlBuilder,
  UrlBuilderConfig,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
  useInvalidateBusinessCaseOnlineOfferMutation,
  useSendBusinessCaseOnlineOfferMutation,
} from '@dms/api';
import {environment} from '@dms/environment';
import featureFlags from '@dms/feature-flags';
import {createSearchParamsString, handleApiError, usePermissions} from '@dms/shared';

const {workspace} = getWorkspaceFromUri();

export const useBcToOnlineOffer = (businessCaseId: string) => {
  const {language} = useLocale();

  const {data: businessCaseData} = useGetBusinessCaseQuery({businessCaseId});

  const {data: participation} = useGetParticipationQuery({
    recordId: businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [canSendOnlineOffer, canInvalidateOnlineOffer] = usePermissions({
    permissionKeys: ['sendOnlineOffer', 'invalidateOnlineOffer'],
    scopes: {
      sendOnlineOffer: {
        participation,
        branchId: businessCaseData?.branchId,
      },
      invalidateOnlineOffer: {
        participation,
        branchId: businessCaseData?.branchId,
      },
    },
  });

  const canSendOffer =
    businessCaseData?.businessCaseState !== 'CONCEPT' &&
    businessCaseData?.businessCaseState !== 'UNSUCCESSFUL' &&
    isNilOrEmpty(businessCaseData?.offerId) &&
    isFeatureEnabled(featureFlags.BUSINESS_CASE_ONLINE_OFFER) &&
    canSendOnlineOffer;

  const isOnlineOfferSent =
    isNotNilOrEmpty(businessCaseData?.offerId) &&
    isFeatureEnabled(featureFlags.BUSINESS_CASE_ONLINE_OFFER);

  const canResendOffer = isOnlineOfferSent && canSendOnlineOffer;
  const canInvalidateOffer = isOnlineOfferSent && canInvalidateOnlineOffer;

  const [sendOnlineOffer, {isLoading: isSendingOnlineOffer, isError: hasSendOnlineOfferError}] =
    useSendBusinessCaseOnlineOfferMutation();

  const [
    invalidateOnlineOffer,
    {isLoading: isInvalidatingOnlineOffer, isError: hasInvalidateOnlineOfferError},
  ] = useInvalidateBusinessCaseOnlineOfferMutation();

  const sendOnlineOfferToCustomer = () =>
    sendOnlineOffer({businessCaseId})
      .unwrap()
      .then(() => {
        showNotification.success();
      })
      .catch(handleApiError);

  const invalidateOnlineOfferToCustomer = () => {
    if (businessCaseData?.offerId) {
      return invalidateOnlineOffer({offerId: businessCaseData.offerId})
        .unwrap()
        .catch(handleApiError);
    }
  };

  const openOffer = () => {
    if (isOnlineOfferSent) {
      const builderConfig: UrlBuilderConfig = {
        workspace,
        protocol: window.location.protocol,
        domain: environment.ONLINE_OFFER_DOMAIN,
        route: `/${businessCaseData?.offerId}`,
        searchParam: createSearchParamsString({
          language,
        }),
      };

      const digitalCertificateUrl = urlBuilder(builderConfig);

      window.open(digitalCertificateUrl, '_blank')?.focus();
    }
  };

  const isLoading = isSendingOnlineOffer || isInvalidatingOnlineOffer;
  const hasError = hasSendOnlineOfferError || hasInvalidateOnlineOfferError;

  return {
    canSendOffer,
    canResendOffer,
    canInvalidateOffer,
    isOnlineOfferSent,
    isLoading,
    hasError,
    actions: {
      sendOnlineOfferToCustomer,
      invalidateOnlineOfferToCustomer,
      openOffer,
    },
  };
};

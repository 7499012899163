import {z} from 'zod';

import i18n from '@dms/i18n';

export const ParkingSectorFormSchema = z.object({
  branchId: z.string({
    required_error: i18n.t('general.validations.fieldIsRequired'),
  }),
  name: z
    .string({
      required_error: i18n.t('general.validations.fieldIsRequired'),
    })
    .min(1),
  prefix: z
    .string({
      required_error: i18n.t('general.validations.fieldIsRequired'),
    })
    .max(5),
  numberOfParkingSpaces: z
    .number({
      invalid_type_error: i18n.t('general.validations.mustBeNumber'),
      required_error: i18n.t('general.validations.fieldIsRequired'),
    })
    .min(1, i18n.t('general.errors.number.min', {min: 0}))
    .max(999),
});

export type ParkingSectorFormType = z.infer<typeof ParkingSectorFormSchema>;

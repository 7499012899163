import {Locale} from 'date-fns';

import {forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState} from 'react';

import {useOutsideClick} from 'shared';

import {noop} from '../../utils/someTeasUtils';
import {Calendar} from '../Calendar/Calendar';
import {DatePickerBase} from './DatePickerBase';
import {CalendarsWrapper, Wrapper} from './styles';
import {DatePickerBaseProps} from './types';
import {parseDateString} from './utils';

export interface DatePickerProps extends DatePickerBaseProps {
  onCalendarBlur?: (date: Date | null) => void;
  locale?: Locale;
  disablePast?: boolean;
  disableTo?: Date;
}
/**
 * @deprecated - use platform instead
 */
const DatePickerComponent: ForwardRefRenderFunction<HTMLInputElement, DatePickerProps> = (
  {
    value,
    onBlur = noop,
    onChange = noop,
    onCalendarBlur = noop,
    name,
    required,
    validation,
    locale,
    disabled,
    disablePast,
    disableTo,
    ...rest
  },
  ref
) => {
  const [innerValue, setInnerValue] = useState<Date | null>(value ? parseDateString(value) : null);
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setInnerValue(parseDateString(value));
  }, [value]);

  const toggle = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  const handleChange = (value: Date) => {
    setInnerValue(value);
    onChange(value);
  };

  const handleClickAway = () => {
    setOpen(false);
    onCalendarBlur(innerValue);
  };

  const testId = rest['data-testid'] ?? 'DatePicker';

  useOutsideClick({ref: wrapperRef, handler: handleClickAway});

  return (
    <Wrapper ref={wrapperRef}>
      <DatePickerBase
        value={innerValue ? innerValue.toISOString() : undefined}
        data-testid={testId}
        onIconClick={toggle}
        onChange={handleChange}
        onBlur={onBlur}
        name={name}
        required={required}
        validation={validation}
        disabled={disabled}
        disableTo={disableTo}
        {...rest}
        ref={ref}
      />
      {open && (
        <CalendarsWrapper>
          <Calendar
            value={innerValue}
            onChange={handleChange}
            locale={locale}
            disablePast={disablePast}
            disableTo={disableTo}
            data-testid={`${testId}-calendar`}
          />
        </CalendarsWrapper>
      )}
    </Wrapper>
  );
};

export const DatePicker = forwardRef(DatePickerComponent);

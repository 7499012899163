import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {DataStatus, FormSubmitHandler} from 'platform/components';

import {omit} from 'ramda';

import {
  ConsentSubject,
  EditConsentTypeRequestBody,
  EditTemplateRequestBody,
  useEditConsentTypeMutation,
  useGetConsentTypeQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {ConsentTypeFormType} from '../../../components/GdprConsentTypeForm/components/ConsentTypeFormTemplate';
import {GdprConsentTypeForm} from '../../../components/GdprConsentTypeForm/GdprConsentTypeForm';

interface AddGdprConsentTypeFormProps {
  id: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export function EditGdprConsentTypeForm(props: AddGdprConsentTypeFormProps) {
  const {data: consentType, isLoading, isError} = useGetConsentTypeQuery({consentTypeId: props.id});
  const [edit] = useEditConsentTypeMutation();

  const onSubmit: FormSubmitHandler<ConsentTypeFormType> = async (values, setErrors) => {
    const branchId = ConsentSubject.BRANCH === values.subject ? values.branchId : null;

    const templates = values.templates.map(
      omit(['fileUri', 'fileName'])
    ) as EditTemplateRequestBody[];

    const body: EditConsentTypeRequestBody = {...values, templates, branchId};

    await edit({consentTypeId: props.id, editConsentTypeRequestBody: body})
      .unwrap()
      .then(props.onSuccess)
      .catch((error: FetchBaseQueryError) =>
        handleApiError(error, {silentOnValidationError: true, setErrors})
      );
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <GdprConsentTypeForm
        onSubmit={onSubmit}
        defaultValues={consentType}
        onSuccess={props.onSuccess}
        onCancel={props.onCancel}
        submitTitle={i18n.t('general.actions.save')}
      />
    </DataStatus>
  );
}

import {useEffect, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useOnMount} from 'shared';

import {GetDataIdsByQuery} from '../types/Api';
import {getFormattedSourceSearchParams} from '../utils/url/getFormattedSourceSearchParams';
import {setSourceSearchParamsByGridCode} from '../utils/url/setSourceSearchParamsByGridCode';
import {useHttpCalls} from './useHttpCalls';

const DETAIL_PAGINATION_LIMIT = 50;
export function useDetailPagination(
  onChange: (entityIndex: string, sourceSearchParams: string) => void
) {
  const [searchParams] = useSearchParams();
  const ids = useRef<GetDataIdsByQuery[]>(null);

  const [page, setPage] = useState<number>(Number(getFormattedSourceSearchParams().rowIndex));
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const http = useHttpCalls({gridCode: getFormattedSourceSearchParams().gridCode});

  useOnMount(() => {
    fetchIds();
  });

  useEffect(() => {
    const newPage = getFormattedSourceSearchParams().rowIndex;
    setPage(Number(newPage));
  }, [searchParams]);

  const onPageChange = (newPage: number) => {
    const foundEntityId = ids.current?.find((id) => id.rowNumber === newPage)?.id;

    if (isNilOrEmpty(foundEntityId)) {
      setIsInvalid(true);
      return;
    }

    const sourceSearchParams = setSourceSearchParamsByGridCode(
      getFormattedSourceSearchParams().gridCode,
      String(newPage),
      true
    );

    onChange(foundEntityId as string, sourceSearchParams);

    setIsInvalid(false);
    setPage(newPage);
    fetchIds();
  };

  const fetchIds = () => {
    const offset = Number(page) - Math.round(DETAIL_PAGINATION_LIMIT / 2);

    http
      .getDataIdsByQuery(
        getFormattedSourceSearchParams().queryId,
        offset > 0 ? offset : 0,
        DETAIL_PAGINATION_LIMIT,
        false
      )
      .then((response) => {
        ids.current = response ?? [];
      });
  };

  return {
    rowIndex: page,
    ids,
    onPageChange,
    isInvalid,
  };
}

import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import {Nullish} from 'shared';

import {useConfig} from '../components/DynamicUiContext';
import {DependentOptionResponseBody} from '../types/modelSchema';
import {Option} from '../types/Option';
import {extractParamsFromString} from '../utils/extractParamsFromString';
import {handleDependentOptions} from '../utils/handleDependentOptions';
import {prepareUrl} from '../utils/prepareUrl';

/**
 *  Manage options,
 *  @param url - if provided, options will be fetched from that url, result will replace defaultOptions
 *  @param defaultOptions - array of options
 *  @param dependentOptions - configuration of dependent options
 *  @return {Option[]} - array of options
 */
export function useOptionsManager(
  defaultOptions: Option[] | Nullish,
  dependentOptions: DependentOptionResponseBody[] | Nullish,
  url: string | Nullish
): Option[] {
  const {httpClient} = useConfig();
  const {getValues} = useFormContext();
  const storedOptions = useRef<Option[]>([]);
  const [options, setOptions] = useState(defaultOptions || []);

  // get all dependent field names extracted from string
  const urlParams = useMemo(() => extractParamsFromString(url), [url]);
  const urlValues = useWatch({name: urlParams});

  // get all dependent field names extracted from dependentOptions
  const dependentOptionsParams = useMemo(
    () => dependentOptions?.map((dependentOption) => dependentOption.name) ?? [],
    [dependentOptions]
  );
  const dependentOptionsValues = useWatch({name: dependentOptionsParams});

  const handleOptionsChange = useCallback((nextOptions: Option[]) => {
    storedOptions.current = nextOptions;
    setOptions(nextOptions);
  }, []);

  useEffect(() => {
    if (!url) {
      return;
    }

    httpClient
      .get<any>(prepareUrl(url, getValues))
      .then(({data}) => {
        if (dependentOptions) {
          handleOptionsChange(handleDependentOptions(dependentOptions, getValues, data));
          return;
        }

        handleOptionsChange(data);
      })
      .catch((error) => {
        // There is no sentry on platform. Using console to get this error to sentry
        console.error(error);
      });
  }, [url, urlValues, getValues]);

  useEffect(() => {
    if (!dependentOptionsValues || !dependentOptions) {
      return;
    }

    const optionsForFiltering = url ? storedOptions.current : defaultOptions || [];

    handleOptionsChange(handleDependentOptions(dependentOptions, getValues, optionsForFiltering));
  }, [dependentOptionsValues, dependentOptions, getValues, url, storedOptions, defaultOptions]);

  return options;
}

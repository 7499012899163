import {Alert, Button, DataStatus, Dialog, IconButton, Tooltip} from 'platform/components';
import {Box, Right, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {ReactElement, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {
  SimpleTable,
  DataType,
  useRouter,
  deleteRoleClearError,
  deleteRoleRequest,
  fetchRoleRequest,
  loadingRoleList,
  rolesListForUserManagement,
  selectSingleRoleDeletionError,
  RoleForRoleManagement,
} from '@dms/teas';

import {composePath} from 'shared';

export const RolesTable = (props: any): ReactElement => {
  const router = useRouter();
  const dispatch = useDispatch();
  const roles = useSelector(rolesListForUserManagement);
  const isLoading = useSelector(loadingRoleList);
  const [errorModalOpened, setErrorModalOpened] = useState<boolean>(false);
  const deleteRoleError = useSelector(selectSingleRoleDeletionError);

  useEffect(() => {
    if (deleteRoleError) {
      setErrorModalOpened(true);
    }
  }, [deleteRoleError]);

  const roleType = (system: boolean): string =>
    !system ? i18n.t('general.labels.custom') : i18n.t('general.labels.default');

  const columns = [
    {
      Header: i18n.t('page.settings.labels.roleName'),
      id: 'title',
    },
    {
      Header: i18n.t('general.labels.type'),
      id: 'tenant',
    },
    {
      Header: i18n.t('page.settings.labels.userCount'),
      id: 'assignedUsers',
    },
    {
      Header: null,
      id: 'action',
    },
  ];

  const createRow = (item: RoleForRoleManagement): DataType => ({
    id: item.role?.id,
    title: item?.role?.system ? (
      <Tooltip label={i18n.t('page.settings.labels.roleCannotBeEdited')} placement="top-start">
        <Box width="100%">{item?.role?.title}</Box>
      </Tooltip>
    ) : (
      <TableCellLinkStyle
        onClick={() =>
          router.push(
            composePath(settingsRoutes.roleManagementDetail, {
              params: {
                roleId: item?.role?.id as string,
              },
            })
          )
        }
      >
        {item?.role?.title}
      </TableCellLinkStyle>
    ),
    tenant: roleType(item?.role?.system),
    assignedUsers: item?.assignedUsers,
    action: !item?.role?.system && (
      <IconButton
        size="small"
        icon="action/delete"
        onClick={() => {
          dispatch(
            deleteRoleRequest({
              roleId: item?.role?.id,
              successMessage: i18n.t('entity.person.actions.roleDeletedSuccess'),
            })
          );
        }}
      />
    ),
  });

  const onErrorModalClose = () => {
    setErrorModalOpened(false);
    dispatch(deleteRoleClearError());
  };

  useEffect(() => {
    dispatch(fetchRoleRequest());
  }, [dispatch]);

  return (
    <DataStatus isLoading={isLoading && !roles?.length} minHeight={24}>
      <SimpleTable
        noZebra
        showRowDivider
        highlightRowOnHover
        tdPadding="default"
        columns={columns}
        rows={[{data: roles?.map(createRow) || []}]}
        rowProps={(row: DataType) => ({
          className: row.tenant === roleType(true) ? 'disabled' : undefined,
        })}
        data-testid={props['data-testid']}
      />
      <Dialog
        size="small"
        isOpen={errorModalOpened}
        data-testid={testIds.settings.userManagement('rolesModal')}
        title={i18n.t('page.settings.labels.deleteRole')}
        onClose={onErrorModalClose}
      >
        <VStack spacing={4}>
          <Alert
            type="inline"
            data-testid={testIds.settings.userManagement('rolesAlert')}
            message={deleteRoleError}
          />
          <Right>
            <Button
              title={i18n.t('general.actions.cancel')}
              variant="secondary"
              onClick={onErrorModalClose}
            />
          </Right>
        </VStack>
      </Dialog>
    </DataStatus>
  );
};

const TableCellLinkStyle = styled.div`
  cursor: pointer;
`;

import {createAction} from '@reduxjs/toolkit';

import {handleApiError, printPDF} from '@dms/shared';

import {ThunkExtra} from '../../hooks/useThunkDispatch';
import {SalesDocumentService} from '../../services/SalesDocumentService';
import {TUpdatePlatformStatusRequest} from '../../types/UpdatePlatformStatusRequest';
import {createAsyncThunk} from '../../utils/createAsyncThunk';
import {openInNew} from '../../utils/someTeasUtils';
import {STATE_NAME} from './constants';

export const adPlatformsRequestQuiet = createAction<{vehicleId: string}>(
  `${STATE_NAME}/adPlatformRequestQuiet`
);

export const togglePlatformRequest = createAction<TUpdatePlatformStatusRequest>(
  `${STATE_NAME}/togglePlatformRequest`
);
export const getWindshieldTemplates = createAction(`${STATE_NAME}/windshieldTemplates`);

const getWindshieldTemplate = (vehicleId: string, templateId: string, extra: ThunkExtra) =>
  extra.callApi(SalesDocumentService.renderWindshieldPaperDocument, {
    requestBody: {
      vehicleId,
      templateId,
    },
  });

/**
 * Download windshield docx template
 */
export const downloadWindshieldTemplate = createAsyncThunk(
  `${STATE_NAME}/downloadWindshieldTemplate`,
  async ({vehicleId, templateId}: Record<string, string>, {extra, rejectWithValue}) => {
    try {
      const result = await getWindshieldTemplate(vehicleId, templateId, extra);
      openInNew(result?.url);

      return result;
    } catch (error: any) {
      handleApiError(error.response);

      return rejectWithValue(error);
    }
  }
);

/**
 * Download windshield pdf template
 */
export const printWindshieldTemplate = createAsyncThunk(
  `${STATE_NAME}/printWindshieldTemplate`,
  async ({vehicleId, templateId}: Record<string, string>, {extra, rejectWithValue}) => {
    try {
      const result = await getWindshieldTemplate(vehicleId, templateId, extra);
      // generate new document
      printPDF(result?.pdfUrl);

      return result;
    } catch (error: any) {
      handleApiError(error.response);

      return rejectWithValue(error);
    }
  }
);

import {Nullish} from 'shared';

import {BASE_PATH_SEPARATOR} from '../constants/BASE_PATH_SEPARATOR';

export type GetSignableDocumentListApiResponse = GetSignableDocumentListResponseBody[];

export type GetSignableDocumentListResponseBody = {
  sourceFileId: string;
  pdfFileId: string;
  documentType: string;
  createdAt: string;
  createdBy: string;
  filename: string;
  state: 'created' | 'signed' | 'failed';
  signedDocument: {
    signedDocumentFileId: string;
    auditDocumentFileId: string;
    signedAt: string;
  } | null;
  metadata: {
    id: string;
    title: string;
    signatoryPerson: string;
  }[];
};

export const getSignatoriesDefaultValues = (files: GetSignableDocumentListApiResponse | Nullish) =>
  (files ?? []).reduce((prev, document) => {
    const fileId = document.sourceFileId;
    const signatoriesValues = document.metadata.reduce((prev, signatory) => {
      const signatoryId = signatory.id;

      return {
        ...prev,
        [`${fileId}${BASE_PATH_SEPARATOR}${signatoryId}`]: null,
      };
    }, {});

    return {
      ...prev,
      ...signatoriesValues,
    };
  }, {});

import {
  Alert,
  Button,
  Choice,
  DataStatus,
  Dropdown,
  DropdownItem,
  Separator,
} from 'platform/components';
import {Box, HStack, Heading, Hide, Scroll, Show, VStack} from 'platform/foundation';

import {defaultTo} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useBasketItems} from '../../hooks/useBasketItems';
import {Basket} from '../../types/basket/Basket';
import {BasketMechanic} from '../../types/basket/BasketMechanic';
import {EitherQuantityOrError} from '../../types/basket/EitherQuantityOrError';
import {MappedBasketItem} from '../../types/basket/MappedBasketItem';
import {getOptionsFromMechanics} from '../../utils/getOptionsFromMechanics';
import {BasketSummary} from '../BasketSummary/BasketSummary';
import {AfterSalesLabourBasketItem} from './components/AfterSalesLabourBasketItem';
import {LabourBasketItem} from './types/LabourBasketItem';
import {LabourBasketItemEditingDetails} from './types/LabourBasketItemEditingDetails';

const BASKET_TITLE_HEIGHT_IN_REM = 3.5;

type BulkAction<T extends LabourBasketItem> = {
  label: string;
  action: (basketItems: MappedBasketItem<T>[]) => void;
};

export interface AfterSalesLabourBasketProps<T extends LabourBasketItem>
  extends RequiredTestIdProps {
  basket: Basket<T>;
  mechanic: BasketMechanic;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onEdit: (editingDetails: LabourBasketItemEditingDetails) => void;
  onDelete: (itemId: string) => Promise<void>;
  bulkActions?: BulkAction<T>[];
}

export function AfterSalesLabourBasket<T extends LabourBasketItem>(
  props: AfterSalesLabourBasketProps<T>
) {
  const {
    basketItems,
    basketItemsCount,
    hasSomeBasketItemsSelected,
    hasAllBasketItemsSelected,
    onSelectBasketItem,
    onSelectAllBasketItems,
  } = useBasketItems({basketItems: props.basket?.items});

  const basketTitle = `${i18n.t('general.labels.addedItems')} (${basketItemsCount})`;

  const basketTotalPrice = props.basket?.totalPrice;

  return (
    <Box
      borderRadius="small"
      boxShadow="elevation_1"
      backgroundColor="palettes.white.10.100"
      height="100%"
      data-testid={suffixTestId('wrapper', props)}
    >
      <VStack height="100%">
        <Box padding={4}>
          <Heading size={4} data-testid={suffixTestId('basketTitle', props)}>
            {basketTitle}
          </Heading>
        </Box>
        <Separator spacing={0} />
        <Box padding={4} height="100%" maxHeight={`calc(100% - ${BASKET_TITLE_HEIGHT_IN_REM}rem)`}>
          <VStack height="100%" spacing={3}>
            <Choice
              label={i18n.t('entity.order.labels.mechanic')}
              value={defaultTo('', props.mechanic?.selectedMechanicId)}
              options={defaultTo([], getOptionsFromMechanics(props.mechanic?.mechanics))}
              onChange={props.mechanic?.onMechanicChange}
              errorMessage={props.mechanic?.mechanicError}
              isRequired={props.mechanic?.isMechanicRequired}
              isLoading={props.mechanic?.areMechanicsLoading}
              data-testid={suffixTestId('assignedMechanic', props)}
            />

            <Show when={props.basket.hasInvalidItems}>
              <Alert
                type="inline"
                variant="error"
                title={i18n.t('general.notifications.basketHasInvalidItems')}
                data-testid={suffixTestId('alert.hasInvalidItems', props)}
              />
            </Show>

            <HStack justify="space-between">
              <Hide when={isNilOrEmpty(basketItems)}>
                <Button
                  type="button"
                  variant="link"
                  leftIcon="action/done_all"
                  title={
                    hasAllBasketItemsSelected
                      ? i18n.t('general.actions.unselectAll')
                      : i18n.t('general.actions.selectAll')
                  }
                  onClick={onSelectAllBasketItems}
                  data-testid={suffixTestId('actions.toggleSelect', props)}
                />
              </Hide>

              <Show when={hasSomeBasketItemsSelected && isNotNilOrEmpty(props.bulkActions)}>
                <Dropdown
                  closeOnBlur
                  closeOnSelect
                  dropdownControl={
                    <Button
                      type="button"
                      variant="link"
                      leftIcon="action/reorder"
                      title={i18n.t('general.actions.bulkActions')}
                      data-testid={suffixTestId('buttonSelectBulkAction', props)}
                    />
                  }
                  data-testid={suffixTestId('selectBulkAction', props)}
                >
                  {props.bulkActions?.map(({action, label}) => (
                    <DropdownItem
                      key={label}
                      label={label}
                      onClick={() =>
                        basketItems &&
                        action(basketItems.filter((basketItem) => basketItem.isSelected))
                      }
                    />
                  ))}
                </Dropdown>
              </Show>
            </HStack>

            <Scroll flex={1} auto>
              <VStack height="100%" spacing={4}>
                <DataStatus
                  isLoading={props.basket.isLoading}
                  isError={props.basket.hasError}
                  isEmpty={basketItemsCount === 0}
                  emptyMessage={i18n.t('general.notifications.noItemsSelected')}
                  minHeight="100%"
                >
                  {basketItems?.map((basketItem, index) => (
                    <AfterSalesLabourBasketItem
                      key={basketItem.id}
                      item={basketItem}
                      onSelect={onSelectBasketItem}
                      onQuantityChange={props.onQuantityChange}
                      onEdit={props.onEdit}
                      onDelete={props.onDelete}
                      data-testid={suffixTestId(`basketItem-[${index}]`, props)}
                    />
                  ))}
                </DataStatus>
              </VStack>
            </Scroll>

            <Separator />

            <BasketSummary
              totalPrice={basketTotalPrice}
              data-testid={suffixTestId('summary', props)}
            />
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
}

import {useSubscription} from '@apollo/client';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {showNotification} from 'platform/components';
import {platformLightboxQueryParams} from 'platform/lightbox';
import {RcFile} from 'rc-upload/lib/interface';

import {ReactNode, useState} from 'react';
import {BlockerFunction, matchPath} from 'react-router-dom';

import {isNil, isNotNil} from 'ramda';

import {
  SEND_NOTIFICATION_SUBSCRIPTION,
  useDeleteInspectionMutation,
  useDeleteVideoAssetMutation,
  useLazyGetSentBackgroundNotificationQuery,
  useLazyGetVideoAssetQuery,
  useSendMultipartUploadCompletedMutation,
  useSendMultipartUploadRequestMutation,
  useUploadVideoAssetMutation,
} from '@dms/api';
import {i18n} from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';
import {handleApiError, notificationTypes} from '@dms/shared';

import {randomLowerCaseUUID, useBoolean} from 'shared';

import {
  QueueVideoTask,
  RawFile,
  VideoUploadError,
  VideoUploadQueueProvider,
  useVideoUploadQueueContext,
} from 'features/video-upload-queue';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {
  addVideoAssets,
  deleteVideoAssets,
  updateAuditState,
  updateVideoAssetData,
} from '../../../store/carAudit/reducer';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {useBlockNavigating} from '../hooks/useBlockNavigating';
import {useConditionContext} from '../hooks/useConditionContext';
import {VideoUploadContextProvider} from '../hooks/useVideoUploadContext';
import {PendingVideo} from '../types/PendingVideo';
import {getVideoErrorDescription} from '../utils/getVideoErrorDescription';
import {getWebBase64} from '../utils/getWebBase64';
import {isFileVideo} from '../utils/isFileVideo';
import {PossibleUploadInterruptionDialog} from './PossibleUploadInterruptionDialog';

interface VideoUploadProviderProps {
  children: ReactNode;
  auditId?: string;
}

export function VideoUploadProvider(props: VideoUploadProviderProps) {
  const [sendMultiPartUploadRequest] = useSendMultipartUploadRequestMutation();
  const [sendMultipartUploadCompleted] = useSendMultipartUploadCompletedMutation();

  const handleVideoUploadError = (error: VideoUploadError) => {
    showNotification.error(getVideoErrorDescription(error));
  };

  return (
    <VideoUploadQueueProvider
      api={{sendMultipartUploadCompleted, sendMultiPartUploadRequest}}
      getBase64={getWebBase64}
      handleError={handleVideoUploadError}
    >
      <VideoUploadManager auditId={props.auditId}>{props.children}</VideoUploadManager>
    </VideoUploadQueueProvider>
  );
}

function VideoUploadManager(props: VideoUploadProviderProps) {
  const dispatch = useThunkDispatch();
  const {handleChangeCategory} = useConditionContext();
  const [, {isSuccess: isDeleteInspectionSuccess}] = useDeleteInspectionMutation({
    fixedCacheKey: 'deleteInspection_auditVideos',
  });
  const [shouldBlockNavigationWhenUploading, setBlockNavigationWhenUploading] =
    useState<boolean>(true);
  const [isUploadStarting, startUploadStarting, stopUploadStarting] = useBoolean();
  const {tasks, uploadProgress, handleMultiMultiPartUpload} = useVideoUploadQueueContext();
  const [pendingVideos, setPendingVideos] = useState<PendingVideo[]>([]);
  const [succeededVideos, setSucceededVideos] = useState<PendingVideo[]>([]);
  /**
   * Video upload phases – handled by:
   * 1. Upload is triggered (upload button is disabled) – isUploadStarting
   * 2. Multipart upload is in progress – tasks.length > 0
   * 3. Video is being processed by the backend – pendingVideos.length > 0
   * 4. Video is processed by BE and assigned to the audit
   */
  const isUploadInProgress = isUploadStarting || tasks.length + pendingVideos.length > 0;

  /**
   * Block navigation when isUploadInProgress
   *
   * Exceptions from blocking – exempt by:
   * - Opening the lightbox – platformLightboxQueryParams.LIGHTBOX_ID
   * - Closing the lightbox – !isGalleryOpen –> shouldBlockNavigationWhenUploading (unblockForCurrentLocationSearchParam would not block the navigation, but would still show dialog)
   * - Changing the tab – conditionTab/inspectionTab
   * - Deleting inspection – isDeleteInspectionSuccess
   */
  const blockerFunction: BlockerFunction = (args) => {
    // Opening the lightbox
    if (args.nextLocation.search.includes(platformLightboxQueryParams.LIGHTBOX_ID)) {
      return false;
    }

    // Changing the tab
    const allowedRoutes = [vehiclesRoutes.conditionDetailTab, vehiclesRoutes.inspectionDetailTab];
    for (const route of allowedRoutes) {
      const currentMatch = matchPath(route, args.currentLocation.pathname);
      const nextMatch = matchPath(route, args.nextLocation.pathname);
      if (currentMatch && nextMatch) {
        return currentMatch.params.id !== nextMatch.params.id; // Only conditionTab or inspectionTab changes
      }
    }

    // Blocking regardless of the location
    return isUploadInProgress && !isDeleteInspectionSuccess && shouldBlockNavigationWhenUploading;
  };
  const {blocker} = useBlockNavigating(blockerFunction);

  const [getBackgroundNotification] = useLazyGetSentBackgroundNotificationQuery();
  const [getVideoAsset] = useLazyGetVideoAssetQuery();
  const [uploadVideoAsset] = useUploadVideoAssetMutation();
  const [deleteVideoAsset] = useDeleteVideoAssetMutation();

  const uploadingVideos = [
    ...tasks.map((task) => ({
      id: task.id,
      progress: (uploadProgress.find((progress) => (progress.id = task.id))?.progress ?? 0) / 100,
    })),
    ...pendingVideos.map((video) => ({id: video.vehicleAuditVideoAssetId})),
  ];

  const addPendingVideo = (pendingVideo: PendingVideo) =>
    setPendingVideos((videos) => [...videos, pendingVideo]);
  const removePendingVideo = (pendingVideo: PendingVideo) =>
    setPendingVideos((videos) => videos.filter((video) => video.videoId !== pendingVideo.videoId));
  const movePendingVideoToSucceeded = (pendingVideo: PendingVideo) => {
    removePendingVideo(pendingVideo);
    setSucceededVideos((videos) => [...videos, pendingVideo]);
  };

  const handleStartVideoUpload = async (
    file: RcFile,
    categoryId: string,
    paramDefinitionId: string
  ) => {
    if (!isFileVideo(file)) {
      showNotification.error(i18n.t('entity.inspection.errors.invalidFileType'));
      return;
    }

    const rawFile: RawFile = {
      id: randomLowerCaseUUID(),
      uri: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      mimeType: file.type,
      connectionFileId: null,
    };

    startUploadStarting();
    await handleMultiMultiPartUpload(rawFile, (task) =>
      handleUploadFinished(task, categoryId, paramDefinitionId)
    );
    stopUploadStarting();
  };

  const handleUploadFinished = (
    task: QueueVideoTask,
    categoryId: string,
    paramDefinitionId: string
  ) => {
    const pendingVideo = {
      videoId: task.connectionFileId,
      vehicleAuditVideoAssetId: task.id,
      updatedAt: new Date().toISOString(),
      filename: task.name,
      categoryId,
      paramDefinitionId,
    };

    // We must wait for success notification from BE to be able to assign it to the audit
    addPendingVideo(pendingVideo);
  };

  const onVideoProcessed = (pendingVideo: PendingVideo, fileStatus: string, fileType: string) => {
    if (isNil(props.auditId)) {
      throw new Error('Audit ID is not defined');
    }

    if (!(fileStatus === 'success' && fileType === 'video')) {
      showNotification.error(
        i18n.t('entity.inspection.errors.processingFailed', {
          filename: pendingVideo.filename,
        })
      );
      removePendingVideo(pendingVideo);
      return;
    }

    const options = {
      auditId: props.auditId,
      categoryId: pendingVideo.categoryId,
      paramDefinitionId: pendingVideo.paramDefinitionId,
      updatedAt: pendingVideo.updatedAt,
      vehicleAuditVideoAssetId: pendingVideo.vehicleAuditVideoAssetId,
      videoId: pendingVideo.videoId,
    };

    // Assign video to the audit
    uploadVideoAsset({auditId: props.auditId, body: options})
      .unwrap()
      .then((response) => {
        // Update redux state
        dispatch(
          addVideoAssets({
            categoryId: pendingVideo.categoryId,
            paramDefinitionId: pendingVideo.paramDefinitionId,
            assets: [{id: response.id, videoId: pendingVideo.videoId}],
          })
        );
        onVideoAssignedOrDeleted();
        movePendingVideoToSucceeded(pendingVideo);
        showNotification.success(
          i18n.t('entity.inspection.labels.uploadCompleted', {
            filename: pendingVideo.filename,
          })
        );
      })
      .catch((error: FetchBaseQueryError) => {
        removePendingVideo(pendingVideo);
        handleApiError(error, {silent: true});
        showNotification.error(
          i18n.t('entity.inspection.errors.uploadFailed', {
            filename: pendingVideo.filename,
          })
        );
      });
  };

  const onVideoVariantGenerated = (succeededVideo: PendingVideo) => {
    if (isNil(props.auditId)) {
      throw new Error('Audit ID is not defined');
    }

    getVideoAsset({auditId: props.auditId, videoAssetId: succeededVideo.vehicleAuditVideoAssetId})
      .unwrap()
      .then((response) => {
        dispatch(
          updateVideoAssetData({
            categoryId: succeededVideo.categoryId,
            paramDefinitionId: succeededVideo.paramDefinitionId,
            videoId: response.videoId,
            asset: response,
          })
        );
      })
      .catch(handleApiError);
  };

  const handleDeleteVideo = (
    videoAsset: {id: string; videoId: string},
    categoryId: string,
    paramDefinitionId: string
  ) => {
    if (isNil(props.auditId)) {
      throw new Error('Audit ID is not defined');
    }

    deleteVideoAsset({auditId: props.auditId, videoAssetId: videoAsset.id})
      .unwrap()
      .then(() => {
        dispatch(
          deleteVideoAssets({
            categoryId,
            paramDefinitionId,
            videoIds: [videoAsset.videoId],
          })
        );
        onVideoAssignedOrDeleted();
        showNotification.success(i18n.t('entity.inspection.labels.deleteVideoAssetCompleted'));
      })
      .catch(handleApiError);
  };

  // Assigning or deleting the video the status of the audit should be changed to in progress
  const onVideoAssignedOrDeleted = () => {
    // This is not RTK Query – unwrap() is not available
    // eslint-disable-next-line no-restricted-syntax
    handleChangeCategory()
      .then(() => {
        dispatch(
          updateAuditState({
            state: LoadAuditDataResponseItemBody.state.IN_PROGRESS,
          })
        );
      })
      .catch(handleApiError);
  };

  // Subscribe to notifications to know when to assign videos to audits and fetch generated variants of uploaded videos
  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data: subscription}) => {
      const backgroundNotificationId: string | null =
        subscription.data?.onSendNotification?.backgroundNotificationId;

      if (isNotNil(backgroundNotificationId)) {
        getBackgroundNotification({id: backgroundNotificationId})
          .unwrap()
          .then((notification) => {
            // Detect video processed successfully, so we can assign it to the audit
            if (notification.type === notificationTypes.FILE_STORAGE_FILE_UPLOADED) {
              const {fileId, fileStatus, fileType} = notification.payload;
              const pendingVideo = pendingVideos.find(
                (pendingVideo) => pendingVideo.videoId === fileId
              );

              if (isNotNil(pendingVideo)) {
                onVideoProcessed(pendingVideo, fileStatus, fileType);
              }
            }

            // Detect a new video variant was generated, so we can update the cover and/or the preview
            if (notification.type === notificationTypes.FILE_STORAGE_FILE_VARIANT_UPLOADED) {
              const succeededVideo = succeededVideos.find(
                (video) => video.videoId === notification.payload.originalFileId
              );

              if (isNotNil(succeededVideo) && notification.payload.fileStatus === 'success') {
                onVideoVariantGenerated(succeededVideo);
              }
            }
          })
          .catch(handleApiError);
      }
    },
  });

  return (
    <VideoUploadContextProvider
      value={{
        isUploadInProgress,
        uploadingVideos,
        handleStartVideoUpload,
        handleDeleteVideo,
        setBlockNavigationWhenUploading,
      }}
    >
      {props.children}
      <PossibleUploadInterruptionDialog blocker={blocker} />
    </VideoUploadContextProvider>
  );
}

import styled from 'styled-components';
import styledMap from 'styled-map';

type ColorElementType = {
  $needBorder?: boolean;
  $color: string;
};

type ColorBorderElementType = {
  $isSelected?: boolean;
};

const borderOuter = styledMap`
	default: 2px solid transparent;
	$isSelected: 2px solid ${({theme}) => theme.colors.palettes.blue[60][100]};
`;

const borderInner = styledMap`
	default: transparent;
	$needBorder: 1px solid ${({theme}) => theme.colors.palettes.neutral[70][100]};
`;

export const ColorBorder = styled.div<ColorBorderElementType>`
  border: ${borderOuter};
  border-radius: 50%;
  margin-left: 1px;
  overflow: hidden;
  font-size: 0;

  &:hover {
    border-color: ${({theme}) => theme.colors.palettes.blue[20][100]};
  }
`;

export const StyledColorCircle = styled.div<ColorElementType>`
  border: ${borderInner};
  ${(props) => props.$needBorder && 'border-radius: 50%'};
  cursor: pointer;
  height: ${({theme}) => theme.getSize(6)};
  width: ${({theme}) => theme.getSize(6)};
  background: ${({$color}) => $color};
`;

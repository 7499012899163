import {
  BankAccountResponseBodyV2,
  ContractInformationResponseBodyV2,
  CustomerBankAccountRequestBody,
  CountryAlpha3CodeEnum,
} from '@dms/api';

import {ContractInformationFormType} from '../types/ContractInformationFormType';
import {getFormAdditionalField} from './additionalFields';

export const getContractDefaultValues = (
  isBankListDisabled?: boolean,
  countryCode: CountryAlpha3CodeEnum | undefined = 'CZE',
  contract?: ContractInformationResponseBodyV2
): ContractInformationFormType => {
  const emptyBank: BankAccountResponseBodyV2 = {
    id: '',
    name: null,
    ownerName: null,
    iban: null,
    swiftBic: null,
    currency: null,
    number: null,
    bankCode: null,
    isDeleted: false,
    countryCode,
  };

  const bankAccounts =
    isBankListDisabled || !contract?.bankAccounts?.length ? [emptyBank] : contract?.bankAccounts;

  const getBankAccounts = (
    bankAccounts: BankAccountResponseBodyV2[]
  ): CustomerBankAccountRequestBody[] =>
    bankAccounts
      .filter((bankAccount) => !bankAccount.isDeleted)
      .map((bankAccount) => ({
        id: bankAccount.id,
        bankAccountData: {
          name: bankAccount.name,
          countryCode: bankAccount.countryCode ?? countryCode,
          ownerName: bankAccount.ownerName,
          iban: bankAccount.iban,
          swiftBic: bankAccount.swiftBic,
          currency: bankAccount.currency,
          number: bankAccount.number,
          bankCode: bankAccount.bankCode,
          isDeleted: bankAccount.isDeleted,
        },
      }));

  return {
    legalForm: contract?.legalForm ?? 'NATURAL_PERSON',
    bankAccounts: !isBankListDisabled ? getBankAccounts(bankAccounts) : [],
    personId: contract?.person?.id ?? null,
    businessInfo: {
      businessInfoData: {
        countryOfRegistrationCode:
          contract?.businessInfo?.businessInfo?.countryOfRegistrationCode ?? null,
        registrationNumber: contract?.businessInfo?.businessInfo?.registrationNumber ?? null,
        vatNumber: contract?.businessInfo?.businessInfo?.vatNumber ?? null,
        tradeName: contract?.businessInfo?.businessInfo?.tradeName ?? null,
        fileNumber: contract?.businessInfo?.businessInfo?.fileNumber ?? null,
      },
      businessAddressId: contract?.businessInfo?.address?.id ?? null,
    },
    additionalFields: getFormAdditionalField(contract?.customFieldsPayload),
  };
};

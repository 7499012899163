import {ActionsCellRendererWrapper} from '../components/ActionsCellRendererWrapper';
import {EmptyHeaderCellRenderer} from '../components/EmptyHeaderCellRenderer';
import {QuickFilter} from '../components/QuickFilter';
import {RowSelectCellRenderer} from '../components/RowSelectCellRenderer';
import {RowSelectHeaderRenderer} from '../components/RowSelectHeaderRenderer';
import {ColDef, SuppressKeyboardEventParams} from '../types/AgGridTypes';
import {FeGridSettings, TransformedDefinitionApiResponse} from '../types/Api';
import {DataGridProps} from '../types/DataGridProps';

export const getActionsColDef = (props: DataGridProps): ColDef => ({
  colId: 'eag-col-actions',
  pinned: 'right',
  lockPinned: true,
  field: 'eag-col-actions',
  suppressHeaderMenuButton: true,
  sortable: false,
  suppressMovable: true,
  resizable: true,
  cellRenderer: ActionsCellRendererWrapper,
  headerComponent: EmptyHeaderCellRenderer,
  cellRendererParams: {gridProps: props},
  minWidth: 5,
  initialWidth: 30,
  lockVisible: true,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  cellStyle: {
    justifyContent: 'center',
  },
  chartDataType: 'excluded',
});

export const getCheckBoxColDef = (
  props: DataGridProps,
  settings: FeGridSettings,
  definition: TransformedDefinitionApiResponse
): ColDef => ({
  colId: 'eag-col-select',
  field: 'eag-col-select',
  lockPinned: true,
  headerComponent:
    definition.behavior.rowSelectMode === 'MULTIPLE'
      ? RowSelectHeaderRenderer
      : EmptyHeaderCellRenderer,
  cellRenderer: RowSelectCellRenderer,
  cellRendererParams: {gridProps: props},
  headerComponentParams: {gridProps: props},
  resizable: false,
  sortable: false,
  width: 50,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  lockPosition: true,
  lockVisible: true,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  chartDataType: 'excluded',
});

export const quickFilterColDef: ColDef = {
  colId: 'smartSearch',
  field: 'smartSearch',
  filter: QuickFilter,
  chartDataType: 'excluded',
  hide: true,
  suppressFiltersToolPanel: true,
  suppressColumnsToolPanel: true,
  lockVisible: true,
  width: 0,
  resizable: false,
  sortable: false,
  suppressHeaderMenuButton: true,
};

export const defaultColDefConst: ColDef = {
  resizable: true,
  sortable: true,
  minWidth: 50,
  menuTabs: ['filterMenuTab', 'generalMenuTab'],
  filter: false,
  suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => params.event.key === ' ',
};
